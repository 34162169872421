import graphql from 'babel-plugin-relay/macro';
import { set } from 'lodash';
import { IEnvironment, commitMutation } from 'relay-runtime';

import {
    UserIn,
    savingCreateUserMutation,
    savingCreateUserMutation$data,
} from './__generated__/savingCreateUserMutation.graphql';
import { savingDisableUserMutation } from './__generated__/savingDisableUserMutation.graphql';
import { UserUpdateIn, savingEditUserMutation } from './__generated__/savingEditUserMutation.graphql';
import { savingEnableUserMutation } from './__generated__/savingEnableUserMutation.graphql';

export async function addNewUser(user: UserIn, environment: IEnvironment): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<savingCreateUserMutation>(environment, {
            mutation: CreateUserMutation,
            variables: { user },
            onCompleted: (response, error) => {
                if (response.addUser?.username) {
                    resolve(response.addUser.username);
                }
                if (response.addUser?.problems) {
                    reject(response.addUser.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

export async function updateUser(id: string, user: UserUpdateIn, environment: IEnvironment): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<savingEditUserMutation>(environment, {
            mutation: EditUserMutation,
            variables: { id, user },
            onCompleted: (response, error) => {
                if (response.editUser?.username) {
                    resolve(response.editUser.username);
                }
                if (response.editUser?.problems) {
                    reject(response.editUser.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

export async function enableUser(id: string, environment: IEnvironment): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<savingEnableUserMutation>(environment, {
            mutation: EnableUserMutation,
            variables: { id },
            onCompleted: (response, error) => {
                if (response.enableUser?.username) {
                    resolve(response.enableUser.username);
                }
                if (response.enableUser?.problems) {
                    reject(response.enableUser.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

export async function disableUser(id: string, environment: IEnvironment): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<savingDisableUserMutation>(environment, {
            mutation: DisableUserMutation,
            variables: { id },
            onCompleted: (response, error) => {
                if (response.disableUser?.username) {
                    resolve(response.disableUser.username);
                }
                if (response.disableUser?.problems) {
                    reject(response.disableUser.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

type UserProblemResponse = NonNullable<NonNullable<savingCreateUserMutation$data['addUser']>['problems']>[number];

export function decodeUserApiErrors(problems: UserProblemResponse[]) {
    const errors = {};

    for (const problem of problems) {
        switch (problem.type) {
            case 'EmailAlreadyRegistered':
                set(errors, 'email', 'Email already registered');
                break;
            case 'MissingEmail':
                set(errors, 'email', 'Email must be provided');
                break;
            case 'InvalidUser':
                // Can not happen from the UI
                break;
        }
    }

    return errors;
}

const CreateUserMutation = graphql`
    mutation savingCreateUserMutation($user: UserIn!) {
        addUser(user: $user) {
            ... on User {
                username
            }
            ... on UserProblemResponse {
                problems {
                    type
                }
            }
        }
    }
`;

const EditUserMutation = graphql`
    mutation savingEditUserMutation($id: String!, $user: UserUpdateIn!) {
        editUser(username: $id, user: $user) {
            ... on User {
                username
            }
            ... on UserProblemResponse {
                problems {
                    type
                }
            }
        }
    }
`;

const EnableUserMutation = graphql`
    mutation savingEnableUserMutation($id: String!) {
        enableUser(username: $id) {
            ... on User {
                username
            }
            ... on UserProblemResponse {
                problems {
                    type
                }
            }
        }
    }
`;

const DisableUserMutation = graphql`
    mutation savingDisableUserMutation($id: String!) {
        disableUser(username: $id) {
            ... on User {
                username
            }
            ... on UserProblemResponse {
                problems {
                    type
                }
            }
        }
    }
`;
