import React, { FC } from 'react';

import { BigButton } from '@accesstel/pcm-ui';

import { isFeatureEnabled } from 'lib/featureFlag';
import { Paths } from 'lib/routes';

import { useDocumentTitle } from '../../components';
import { BigButtonLayout } from '../../layouts/BigButtonLayout';
import { DefaultTimeRange } from './ac-power/settings';

export const Reports: FC = () => {
    useDocumentTitle('Reports');

    return (
        <BigButtonLayout title='Reports'>
            <BigButton
                to={Paths.ReportAlerts}
                title='Insights'
                text='Explore a range of insights generated from devices across your network.'
            />
            {isFeatureEnabled('acpower_report') && (
                <BigButton
                    to={{ pathname: Paths.ReportACPower, search: { interval: DefaultTimeRange } }}
                    title='AC Power'
                    text='Access AC power reports for various sites collected by accata.'
                />
            )}
            <BigButton
                to={Paths.ReportBatteries}
                title='Batteries'
                text='Access battery reports for various sites collected by accata.'
            />
        </BigButtonLayout>
    );
};
