import { decodeDateRangeFilter } from 'filters/common';

import { AlertDomainOptions, AlertIsActiveOptions, AlertOriginatorOptions, AlertSeverityOptions } from './components';
import {
    AlertCategoryFilter,
    AlertClearTimeFilter,
    AlertDeviceNameFilter,
    AlertDomainFilter,
    AlertOriginatorFilter,
    AlertRaiseTimeFilter,
    AlertSeverityFilter,
    AlertSiteNameFilter,
    IsAlertActive,
} from './types';

export function decodeAlertSeverityFilter(value: string): AlertSeverityFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = AlertSeverityOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeAlertDomainFilter(value: string): AlertDomainFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = AlertDomainOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeAlertDeviceNameFilter(value: string): AlertDeviceNameFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return {
        type: 'result',
        name: decodedValue,
    };
}

export function decodeAlertSiteNameFilter(value: string): AlertSiteNameFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return {
        type: 'result',
        name: decodedValue,
    };
}

export function decodeAlertRaiseDateFilter(value: string): AlertRaiseTimeFilter | null {
    return decodeDateRangeFilter(value);
}

export function decodeAlertClearDateFilter(value: string): AlertClearTimeFilter | null {
    return decodeDateRangeFilter(value);
}

export function decodeAlertCategoryFilter(value: string): AlertCategoryFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return {
        type: 'result',
        value: decodedValue,
    };
}

export function decodeAlertOriginatorFilter(value: string): AlertOriginatorFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = AlertOriginatorOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeAlertIsActiveFilter(value: string): IsAlertActive | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = AlertIsActiveOptions.find(option => option === decodedValue);

    if (!match) {
        return null;
    }

    return match;
}
