export const Paths = {
    Dashboard: '/',
    // Powercontrollers
    PowerControllers: '/sites',
    // Assets
    Assets: '/manage',
    Sites: '/manage/sites',
    Devices: '/manage/devices',
    BatteryTypes: '/manage/batterytypes',
    // Add Assets
    AddSite: '/manage/sites/add',
    AddBatteryType: '/manage/batterytypes/add',
    AddDevice: '/manage/devices/add',
    // Edit Assets
    EditSite: '/manage/sites/:id',
    EditBatteryType: '/manage/batterytypes/:id',
    EditDevice: '/manage/devices/:id',

    // TASKS
    Tasks: '/tasks',
    // Tests
    TestsOverview: '/tasks/batteryhealth',
    TestsScheduleTest: '/tasks/batteryhealth/schedule',
    TestsCancelledAborted: '/tasks/batteryhealth/cancelled',
    TestsCompleted: '/tasks/batteryhealth/completed',
    TestsScheduled: '/tasks/batteryhealth/scheduled',
    TestsUnplanned: '/tasks/batteryhealth/external',
    TestsCompare: '/tasks/batteryhealth/compare',
    ViewExternalTestResults: '/tasks/batteryhealth/external/:id',
    TestsDetailsView: '/tasks/batteryhealth/view',
    ViewTaskDetails: '/tasks/batteryhealth/view/:id',
    ViewTaskDeviceResults: '/tasks/batteryhealth/view/:taskId/:deviceId',
    // Generator Runs
    TasksGeneratorsOverview: '/tasks/generators',
    TasksGeneratorsDetails: '/tasks/generators/view/:id',
    TasksGeneratorsList: '/tasks/generators/all',

    // REPORTS
    ReportsOverview: '/reports',
    // AC Power
    ReportACPower: '/reports/acpower',
    ReportACPowerIncidentList: '/reports/acpower/incidents',
    ReportACPowerSiteList: '/reports/acpower/sites',
    ReportACPowerSiteView: '/reports/acpower/sites/:siteId',
    ReportACPowerSiteViewDevice: '/reports/acpower/sites/:siteId/:deviceId',
    ReportACPowerDeviceIncidentList: '/reports/acpower/sites/:siteId/:deviceId/incidents',
    ReportACPowerDeviceList: '/reports/acpower/devices',
    ReportACPowerSiteIncidentList: '/reports/acpower/sites/:siteId/incidents',
    // Batteries
    ReportBatteries: '/reports/batteries',
    ReportBatterySiteList: '/reports/batteries/sites',
    ReportBatteriesViewSite: '/reports/batteries/sites/:siteId',
    ReportBatteriesViewSiteDevice: '/reports/batteries/sites/:siteId/:deviceId',
    ReportBatteriesByTestDevice: '/reports/batteries/test',
    ReportBatteryTestsByDevice: '/reports/batteries/sites/:siteId/tests/:deviceId',
    ViewTestResultFromReport: '/reports/batteries/test/:id',
    // Alerts
    ReportAlerts: '/reports/alerts',

    // Admin Settings
    Administration: '/administration',
    AdministrationConfiguration: '/administration/configuration',
    AdministrationUsers: '/administration/users',
    AdministrationUsersAdd: '/administration/users/add',
    AdministrationUsersEdit: '/administration/users/:id',
    AdministrationAudit: '/administration/audit',

    // SiteView
    SiteViewViewSite: '/sites/:siteId',
    SiteViewViewSiteOverview: '/sites/:siteId/overview',
    SiteViewViewSiteDevice: '/sites/:siteId/:deviceId',
    SiteViewViewSiteDevicePage: '/sites/:siteId/:deviceId/:page',
    SiteViewRelativeDevicePage: ':deviceId/:page',

    // Explore
    Explore: '/explore',
    ExploreMetrics: '/explore/metrics',

    // Account
    Account: '/account',
    AccountSettings: '/account/settings',
};
