/**
 * @generated SignedSource<<b8559b13f2f88edfb5406c5a933f2f66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type apiSearchQuery$variables = {
  search: string;
};
export type apiSearchQuery$data = {
  readonly search: ReadonlyArray<{
    readonly __typename: string;
    readonly address?: {
      readonly address: string | null;
      readonly state: string;
    };
    readonly connectionSettings?: {
      readonly addresses: ReadonlyArray<string>;
    };
    readonly device?: {
      readonly id: string;
    };
    readonly id?: string;
    readonly manufacturer?: string;
    readonly model?: string;
    readonly name?: string;
    readonly optName?: string | null;
    readonly site?: {
      readonly id: string;
      readonly name: string;
    };
    readonly startTime?: string | null;
    readonly task?: {
      readonly id: string;
    } | null;
  }>;
};
export type apiSearchQuery = {
  response: apiSearchQuery$data;
  variables: apiSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "search"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ConnectionSettings",
      "kind": "LinkedField",
      "name": "connectionSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addresses",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    }
  ],
  "type": "BatteryType",
  "abstractKey": null
},
v8 = {
  "alias": "optName",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    (v8/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v9/*: any*/)
      ],
      "type": "BatteryTest",
      "abstractKey": null
    }
  ],
  "type": "Task",
  "abstractKey": "__isTask"
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "type": "BatteryTest",
  "abstractKey": null
},
v12 = [
  (v3/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "BatteryTest",
  "kind": "LinkedField",
  "name": "task",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "device",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": "startTime",
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    (v8/*: any*/)
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "apiSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "search",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v8/*: any*/),
              (v15/*: any*/)
            ],
            "type": "DeviceBatteryTestResults",
            "abstractKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "apiSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "search",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v8/*: any*/),
              (v15/*: any*/),
              (v3/*: any*/)
            ],
            "type": "DeviceBatteryTestResults",
            "abstractKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "142559f33c190af4ae67eaa2cb8c4a15",
    "id": null,
    "metadata": {},
    "name": "apiSearchQuery",
    "operationKind": "query",
    "text": "query apiSearchQuery(\n  $search: String!\n) {\n  search(input: $search) {\n    __typename\n    ... on Device {\n      id\n      name\n      connectionSettings {\n        addresses\n      }\n      site {\n        id\n        name\n      }\n    }\n    ... on Site {\n      id\n      name\n      address {\n        address\n        state\n      }\n    }\n    ... on BatteryType {\n      id\n      model\n      manufacturer\n    }\n    ... on Task {\n      __isTask: __typename\n      id\n      optName: name\n      ... on BatteryTest {\n        startTime\n      }\n    }\n    ... on BatteryTest {\n      id\n      optName: name\n      startTime\n    }\n    ... on DeviceBatteryTestResults {\n      task {\n        id\n      }\n      device {\n        id\n      }\n      optName: name\n      startTime: commencedTime\n      id\n    }\n    ... on GeneratorRunReport {\n      id\n      optName: name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74e464e2483fd7d90d694f00f3c73ab8";

export default node;
