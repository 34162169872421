/**
 * @generated SignedSource<<516c978c19cf09d6f23251a00d836250>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActiveDevicesCount$data = {
  readonly devices: {
    readonly total: number;
  };
  readonly " $fragmentType": "ActiveDevicesCount";
};
export type ActiveDevicesCount$key = {
  readonly " $data"?: ActiveDevicesCount$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveDevicesCount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveDevicesCount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "cc4fb24b7486ca94d6547fec882d0ecf";

export default node;
