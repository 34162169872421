import React, { FC } from 'react';

import { FormikTextField } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

export interface SettingsV1V2cProps {
    namespace?: string;
}

export const SettingsV1V2c: FC<SettingsV1V2cProps> = ({ namespace }) => {
    return (
        <>
            <div className='col-start-1'>
                <FormikTextField
                    name={getQualifiedName('readOnlyCommunity', namespace)}
                    placeHolder='Read Only community*'
                    light
                    required
                    variant='outlined'
                    testId='read-only-community'
                />
            </div>
            <div className='col-start-1'>
                <FormikTextField
                    name={getQualifiedName('readWriteCommunity', namespace)}
                    placeHolder='Read Write community*'
                    light
                    required
                    variant='outlined'
                    testId='read-write-community'
                />
            </div>
            <div className='col-start-1'>
                <FormikTextField
                    name={getQualifiedName('port', namespace)}
                    placeHolder='Port*'
                    maxLength={5}
                    light
                    required
                    variant='outlined'
                    testId='snmp-port'
                />
            </div>
        </>
    );
};
