import React, { FC, useEffect, useRef, useState } from 'react';

import { SkeletonCard, SkeletonText } from '@accesstel/pcm-ui';

import { DCPowerStatus } from './SiteOverviewContent';
import { getACPowerSVGPath, getDCPowerSVGPath } from './common';
import style from './style.module.css';

export const SiteOverviewSkeleton: FC = () => {
    const gridRef = useRef<HTMLDivElement>(null);
    const [colWidth, setColWidth] = useState<number>(0);
    const [colHeight, setColHeight] = useState<number>(0);

    useEffect(() => {
        const handleResize = () => {
            if (gridRef.current) {
                const gridBoxSize = gridRef.current.querySelector('.row-start-1.col-start-1');

                if (gridBoxSize) {
                    const { width, height } = gridBoxSize.getBoundingClientRect();
                    setColHeight(height);
                    setColWidth(width);
                }
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <div className='px-4 pb-1'>
                <div className='flex flex-row justify-between items-center'>
                    <div className='font-bold text-3xl truncate'>
                        <SkeletonText characters={10} />
                    </div>
                    <SkeletonText characters={10} />
                </div>

                <div className='flex flex-row justify-between items-center'>
                    <div className='font-light text-sm whitespace-nowrap'>
                        <SkeletonText characters={20} />
                    </div>
                    <div className='flex flex-row space-x-2 items-center'>
                        <div className='text-xs text-eggplantLight'>
                            <SkeletonText characters={25} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.container} ref={gridRef}>
                <div className='row-start-1 col-start-1'>
                    <SkeletonCard height='100%' />
                </div>
                <div className='row-start-3 col-start-1'>
                    <SkeletonCard height='100%' />
                </div>

                {getACPowerSVGPath(null, colWidth, colHeight, true)}

                <div className='row-start-2 col-start-3'>
                    <SkeletonCard height='100%' />
                </div>
                <div className='row-start-3 col-start-4'>
                    <SkeletonCard height='100%' />
                </div>

                {getDCPowerSVGPath(DCPowerStatus.BatteryCharging, colWidth, colHeight, true)}

                <div className='row-start-2 col-start-5'>
                    <SkeletonCard height='100%' />
                </div>
            </div>
        </div>
    );
};
