import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowLeftIcon, ColumnSlider, FilterContainer } from '@accesstel/pcm-ui';

import { RangeFilter } from 'filters/common';
import { roundDown, roundUp } from 'lib/number';
import { numberToLocaleString } from 'lib/numberFormatters';

export interface DistributionBucket {
    readonly key: number;
    readonly value: number;
}

export interface Distribution {
    distribution: readonly DistributionBucket[];
}

export interface FilterRangeProps {
    title: string;
    distribution?: Distribution;
    countLabel?: [string, string];
    limit?: [number, number];
    current: RangeFilter | null | undefined;
    discrete?: boolean;
    showBack?: boolean;
    onClearOrBack: () => void;
    onClose: () => void;
    onSubmit: (items: RangeFilter | null) => void;
    formatForDisplay?: (value: number) => string;
    parseFromInput?: (input: string) => number;
    distributionRange: [number, number] | null;
    hideDistribution?: boolean;
}

export function FilterRange({
    title,
    distribution,
    countLabel = ['Min', 'Max'],
    limit,
    current,
    discrete,
    showBack,
    onClearOrBack,
    onClose,
    onSubmit,
    formatForDisplay = numberToLocaleString,
    parseFromInput = defaultParseFromInput,
    distributionRange,
    hideDistribution = false,
}: FilterRangeProps): ReactElement {
    const [inputMinimum, setInputMinimum] = useState<string | null>(null);
    const [inputMaximum, setInputMaximum] = useState<string | null>(null);

    const { calculatedDistributionRange, step, precision } = useMemo(() => {
        let calculatedDistributionRange: [number, number] = [Infinity, -Infinity];
        let step = 1;
        let precision = 0;

        if (distributionRange) {
            calculatedDistributionRange = distributionRange;
        }

        if (distribution && distribution.distribution.length > 0) {
            if (!distributionRange) {
                calculatedDistributionRange = [
                    distribution!.distribution[0].key,
                    distribution!.distribution[distribution!.distribution.length - 1].key,
                ];
            }

            if (!discrete) {
                const interval =
                    (calculatedDistributionRange[1] - calculatedDistributionRange[0]) /
                    distribution.distribution.length;

                if (interval > 1) {
                    step = 1;
                    precision = 0;
                } else if (interval > 0.1) {
                    step = 0.1;
                    precision = 1;
                } else {
                    step = 0.01;
                    precision = 2;
                }
            }

            calculatedDistributionRange[0] = roundDown(calculatedDistributionRange[0], precision);
            calculatedDistributionRange[1] = roundUp(calculatedDistributionRange[1], precision);
        }

        return {
            calculatedDistributionRange,
            step,
            precision,
        };
    }, [discrete, distribution, distributionRange]);

    const [range, setRange] = useState<RangeFilter | null>(() => {
        if (current) {
            return current;
        }

        if (distributionRange) {
            return {
                min: roundDown(distributionRange[0], precision),
                max: roundUp(distributionRange[1], precision),
            };
        }

        if (distribution && distribution.distribution.length > 0) {
            return {
                min: roundDown(distribution.distribution[0].key, precision),
                max: roundUp(distribution.distribution[distribution.distribution.length - 1].key, precision),
            };
        }

        if (hideDistribution) {
            return {
                min: 0,
                max: 0,
            };
        }

        return null;
    });

    // Ensure that range is populated once the distribution loads if not already populated
    useEffect(() => {
        setRange(existingRange => {
            if (existingRange != null || !distribution || distribution.distribution.length === 0) {
                return existingRange;
            }

            if (distributionRange) {
                return {
                    min: roundDown(distributionRange[0], precision),
                    max: roundUp(distributionRange[1], precision),
                };
            }

            return {
                min: roundDown(distribution.distribution[0].key, precision),
                max: roundUp(distribution.distribution[distribution.distribution.length - 1].key, precision),
            };
        });
    }, [distribution, distributionRange, precision]);

    // Same for current value
    useEffect(() => {
        setRange(existingRange => {
            if (existingRange != null || !current) {
                return existingRange;
            }

            return current;
        });
    }, [current]);

    const handleBeginEditMin = useCallback(
        (event: React.FocusEvent<HTMLInputElement, Element>) => {
            event.target.select();
            setInputMinimum(range ? numberToLocaleString(range.min, precision) : '');
        },
        [precision, range]
    );

    const handleBeginEditMax = useCallback(
        (event: React.FocusEvent<HTMLInputElement, Element>) => {
            event.target.select();
            setInputMaximum(range ? numberToLocaleString(range.max, precision) : '');
        },
        [precision, range]
    );

    const handleEndEditMin = useCallback(() => {
        const result = finaliseRange(inputMinimum, inputMaximum, range, {
            parseFromInput,
            limit,
        });

        setInputMinimum(null);

        if (result) {
            setRange(result);
        }
    }, [inputMaximum, inputMinimum, limit, parseFromInput, range]);

    const handleEndEditMax = useCallback(() => {
        const result = finaliseRange(inputMinimum, inputMaximum, range, {
            parseFromInput,
            limit,
        });

        setInputMaximum(null);

        if (result) {
            setRange(result);
        }
    }, [inputMaximum, inputMinimum, limit, parseFromInput, range]);

    const handleSubmit = useCallback(() => {
        if (!onSubmit) {
            return;
        }

        const finalRange = finaliseRange(inputMinimum, inputMaximum, range, {
            parseFromInput,
            limit,
            ensureOrder: true,
        });
        onSubmit(finalRange);
    }, [inputMaximum, inputMinimum, limit, onSubmit, parseFromInput, range]);

    let customClearButton: ReactNode | undefined;
    if (showBack) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-coralRegular'>
                <ArrowLeftIcon />
            </div>
        );
    }

    return (
        <FilterContainer
            title={title}
            onClearClick={onClearOrBack}
            customButton={customClearButton}
            onConfirmClick={handleSubmit}
            onClose={onClose}
            primaryContent={
                <div className='m-4'>
                    {!hideDistribution && (
                        <div className='h-32'>
                            <ColumnSlider
                                data={distribution?.distribution}
                                range={range ? [range.min, range.max] : calculatedDistributionRange}
                                minMax={calculatedDistributionRange}
                                rangeOnChange={range => {
                                    setRange({ min: range[0], max: range[1] });
                                }}
                                step={step}
                            />
                        </div>
                    )}
                    <div className='flex flex-row justify-between mt-4'>
                        <div>{countLabel[0]}</div>
                        <div>{countLabel[1]}</div>
                    </div>
                    <div className='flex flex-row justify-between'>
                        <input
                            className='w-24 bg-transparent'
                            value={inputMinimum ?? (range ? formatForDisplay(range.min) : '')}
                            onChange={e => setInputMinimum(e.target.value)}
                            onFocus={handleBeginEditMin}
                            onBlur={handleEndEditMin}
                        />
                        <input
                            className='w-24 bg-transparent text-right'
                            value={inputMaximum ?? (range ? formatForDisplay(range.max) : '')}
                            onChange={e => setInputMaximum(e.target.value)}
                            onFocus={handleBeginEditMax}
                            onBlur={handleEndEditMax}
                        />
                    </div>
                </div>
            }
        />
    );
}

function defaultParseFromInput(input: string): number {
    return Number(input);
}

interface FinaliseRangeOptions {
    parseFromInput: (input: string) => number;
    limit?: [number, number];
    ensureOrder?: boolean;
}

function finaliseRange(
    inputMinimum: string | null,
    inputMaximum: string | null,
    current: RangeFilter | null,
    options: FinaliseRangeOptions
): RangeFilter | null {
    // Ensure that input values are valid
    let validMinimum: number | null = current ? current.min : null;
    let validMaximum: number | null = current ? current.max : null;

    if (inputMinimum) {
        const parsedMinimum = options.parseFromInput(inputMinimum);

        if (isFinite(parsedMinimum)) {
            validMinimum = parsedMinimum;
        }
    }

    if (inputMaximum) {
        const parsedMaximum = options.parseFromInput(inputMaximum);

        if (isFinite(parsedMaximum)) {
            validMaximum = parsedMaximum;
        }
    }

    if (validMinimum == null || validMaximum == null) {
        // Not ready to change
        return current;
    }

    // Ensure proper order
    if (options.ensureOrder && validMaximum < validMinimum) {
        const temp = validMaximum;
        validMaximum = validMinimum;
        validMinimum = temp;
    }

    // Restrict to valid values
    if (options.limit) {
        validMinimum = Math.max(validMinimum, options.limit[0]);
        validMaximum = Math.min(validMaximum, options.limit[1]);
    }

    return { min: validMinimum, max: validMaximum };
}
