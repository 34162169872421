/**
 * @generated SignedSource<<f01beabc8e8618adeac10bd54697b5fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExternalDischarges_tasks$data = {
  readonly data: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BatteryTestResultCard_test">;
  }>;
  readonly total: number;
  readonly " $fragmentType": "ExternalDischarges_tasks";
};
export type ExternalDischarges_tasks$key = {
  readonly " $data"?: ExternalDischarges_tasks$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExternalDischarges_tasks">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalDischarges_tasks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryTestResults",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BatteryTestResultCard_test"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PaginatedDeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "f29ab1b55f044ad8a119ca82df50544a";

export default node;
