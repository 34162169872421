import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import { createColumnHelper } from '@tanstack/react-table';
import graphql from 'babel-plugin-relay/macro';
import { SiteTableColumn, SiteTableColumnId } from 'filters/site';
import humanizeDuration from 'humanize-duration';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { formatMinutesAsHoursAndMinutes } from 'lib/dateFormatter';
import { numberToLocaleString } from 'lib/numberFormatters';
import { EmptyCell, renderDeviceHealthCell, renderUserConfigCell } from 'lib/table-columns';
import { formatValueWithString } from 'lib/units';
import { Duration } from 'luxon';

import { ColumnWithId } from '../../../layouts';
import { TableDeviceHeader } from './TableDeviceHeader';
import { columns_attributeColumnsQuery } from './__generated__/columns_attributeColumnsQuery.graphql';
import { DeviceHealth } from './__generated__/requestSitesQuery.graphql';
import { SiteDevice, SiteHealth, SiteWithDevices } from './type';

const columnHelper = createColumnHelper<SiteWithDevices | SiteDevice>();

const NameColumn = columnHelper.accessor('name', {
    id: SiteTableColumn.Name,
    header: 'Site',
    exportHeader: 'Site',
    cell: ({ cell, row }) => {
        if (row.depth === 0) {
            return cell.getValue();
        }

        return <TableDeviceHeader name={cell.getValue()!} />;
    },
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '16rem',
    },
});

const NameColumnAlt = columnHelper.accessor('name', {
    id: SiteTableColumn.Name,
    header: 'Name',
    exportHeader: 'Site',
    cell: ({ cell, row }) => {
        if (row.depth === 0) {
            return cell.getValue();
        }

        return <TableDeviceHeader name={cell.getValue()!} />;
    },
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '16rem',
    },
});

const ColumnDefinitionsExcludingName = [
    columnHelper.accessor('address.state', {
        id: SiteTableColumn.State,
        header: 'State',
        exportHeader: 'State',
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '8.5rem',
        },
    }),

    columnHelper.accessor('type', {
        id: SiteTableColumn.Type,
        header: 'Type',
        exportHeader: 'Type',
        meta: {
            filterable: true,
        },
        cell: ({ row }) => {
            if (row.original.__typename === 'Site') {
                const site = row.original;
                return site.type;
            }

            return '';
        },
    }),

    columnHelper.accessor('devices.total', {
        id: SiteTableColumn.DeviceCount,
        header: 'Device Count',
        exportHeader: 'Device Count',
        meta: {
            filterable: true,
            maxWidth: '12rem',
        },
    }),

    columnHelper.accessor('address.postcode', {
        id: SiteTableColumn.Postcode,
        header: 'Postcode',
        exportHeader: 'Postcode',
        cell: ({ cell, row }) => {
            if (row.original.__typename === 'Site') {
                renderUserConfigCell(() => cell.getValue());
            }

            return '';
        },
        meta: {
            filterable: true,
        },
    }),

    columnHelper.display({
        id: SiteTableColumn.BatteryStatus,
        header: 'Battery Status',
        exportHeader: 'Battery Status',

        cell: ({ row }) => {
            if (row.original.__typename === 'Site') {
                const site = row.original;
                if (site.devices?.data) {
                    const noBatteriesOnSite = site.devices.data.every(device => device?.battery?.installed === false);

                    if (noBatteriesOnSite) {
                        return <div>No batteries configured</div>;
                    }
                }

                if (site.batteryMetrics?.multipleStatuses) {
                    return 'Various';
                }

                return site.batteryMetrics?.commonStatus
                    ? batteryStatusToString(site.batteryMetrics?.commonStatus)
                    : 'Unknown';
            } else {
                if (row.original.battery?.installed) {
                    return batteryStatusToString(row.original.battery.metrics?.latestStatus ?? 'Unknown');
                } else {
                    return <div>No batteries configured</div>;
                }
            }
        },
        exportValue: ({ row }) => {
            if (row.__typename === 'Site') {
                const site = row;
                if (site.devices?.data) {
                    const noBatteriesOnSite = site.devices.data.every(device => device?.battery?.installed === false);

                    if (noBatteriesOnSite) {
                        return '';
                    }
                }

                if (site.batteryMetrics?.multipleStatuses) {
                    return 'Various';
                }

                return site.batteryMetrics?.commonStatus
                    ? batteryStatusToString(site.batteryMetrics?.commonStatus)
                    : 'Unknown';
            } else {
                if (row.battery?.installed) {
                    return row.battery.metrics?.latestStatus ?? 'Unknown';
                } else {
                    return '';
                }
            }
        },
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '13rem',
        },
    }),

    columnHelper.accessor('batteryMetrics.totalCapacity', {
        id: SiteTableColumn.BatteryCapacity,
        header: 'Capacity',
        exportHeader: 'Capacity (Ah)',
        cell: ({ cell, row }) => {
            if (row.depth !== 0) {
                return '';
            }

            const value = cell.getValue();

            if (value == null) {
                return '';
            }

            return formatValueWithString(numberToLocaleString(value), 'Ah');
        },
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '7.5rem',
        },
    }),

    columnHelper.accessor('batteryMetrics.totalTimeRemaining', {
        id: SiteTableColumn.BatteryTotalTimeRemaining,
        header: 'Run Time',
        exportHeader: 'Battery Time Remaining (min)',
        cell: ({ cell, row }) => {
            if (row.depth !== 0) {
                return '';
            }

            return cell.getValue()
                ? formatMinutesAsHoursAndMinutes(Duration.fromObject({ hours: cell.getValue()! }).as('minutes'))
                : 'Unknown';
        },
        meta: {
            filterable: true,
            sortable: true,
        },
    }),

    columnHelper.accessor('acPower.reliability.mtbf.current', {
        id: SiteTableColumn.Mtbf,
        header: 'MTBF',
        exportHeader: 'MTBF (min)',
        cell: ({ cell }) => {
            const value = cell.getValue();

            if (value == null) {
                return <EmptyCell text='No Incidents' />;
            }

            return humanizeDuration(Duration.fromObject({ minutes: value }).as('milliseconds'), {
                largest: 2,
                round: true,
            });
        },
        meta: {
            sortable: true,
            filterable: true,
        },
    }),

    columnHelper.accessor('acPower.reliability.mttr.current', {
        id: SiteTableColumn.Mttr,
        header: 'MTTR',
        exportHeader: 'MTTR (min)',
        cell: ({ cell }) => {
            const value = cell.getValue();

            if (value == null) {
                return <EmptyCell text='No Incidents' />;
            }

            return humanizeDuration(Duration.fromObject({ minutes: value }).as('milliseconds'), {
                largest: 2,
                round: true,
            });
        },
        meta: {
            sortable: true,
            filterable: true,
        },
    }),

    columnHelper.accessor('acPower.reliability.percentile.current', {
        id: SiteTableColumn.ACReliability,
        header: 'Reliability Rank',
        exportHeader: 'AC Reliability Rank',
        cell: ({ cell }) => {
            const value = cell.getValue();

            if (value == null) {
                return <EmptyCell text='Not graded' />;
            }

            return `${value}`;
        },
        meta: {
            sortable: true,
            filterable: true,
            maxWidth: '12rem',
        },
    }),

    columnHelper.accessor('acPower.reliability.riskRank', {
        id: SiteTableColumn.ACRisk,
        header: 'Risk Rank',
        exportHeader: 'AC Risk Rank',
        cell: ({ cell }) => {
            const value = cell.getValue();

            if (!value) {
                return <EmptyCell text='No Incidents' />;
            }

            return `${value}`;
        },
        meta: {
            sortable: true,
            filterable: false,
        },
    }),

    columnHelper.accessor('acPower.reliability.incidentCount', {
        id: SiteTableColumn.IncidentCount,
        header: 'Incident Count',
        exportHeader: 'Incident Count',
        cell: ({ cell }) => {
            const value = cell.getValue();

            if (value === 0) {
                return <EmptyCell text='0' />;
            }

            return `${value}`;
        },
        meta: {
            sortable: true,
            filterable: true,
        },
    }),

    columnHelper.accessor('acPower.reliability.durationSum', {
        id: SiteTableColumn.OutageDurationSum,
        header: 'Total Time Offline',
        exportHeader: 'Total Time Offline (min)',
        cell: ({ cell }) => {
            const value = cell.getValue();

            if (value === 0) {
                return <EmptyCell text='No outages' />;
            }

            return humanizeDuration(Duration.fromObject({ minutes: value }).as('milliseconds'), {
                largest: 2,
                round: true,
            });
        },
        meta: {
            sortable: true,
            filterable: true,
        },
    }),

    columnHelper.accessor('health', {
        id: SiteTableColumn.DeviceStatus,
        header: 'Status',
        exportHeader: 'Device Status',
        cell: ({ cell }) => {
            if (!cell.getValue()) {
                return renderStatusCell('Unknown');
            }

            if (cell.row.depth > 0) {
                return renderStatusCell(cell.getValue() as DeviceHealth);
            }

            const status = cell.getValue() as SiteHealth;

            if (status.multipleStatuses) {
                return renderStatusCell(
                    'Various',
                    status.status === 'Offline' || status.status === 'Critical' ? 'text-coralRegular' : undefined
                );
            } else if (status.commonStatus) {
                return renderStatusCell(status.commonStatus);
            } else if (status.status) {
                return renderStatusCell(status.status);
            }

            return renderStatusCell('Unknown');
        },
        exportValue: ({ value }) => {
            if (!value) {
                return 'Unknown';
            }

            if (typeof value === 'string') {
                return value;
            }

            const status = value as SiteHealth;

            if (status.multipleStatuses) {
                return 'Various';
            } else if (status.commonStatus) {
                return status.commonStatus;
            } else if (status.status) {
                return status.status;
            }

            return 'Unknown';
        },
        meta: {
            filterable: true,
            sortable: false,
        },
    }),
];

function renderStatusCell(value: DeviceHealth | 'Various', colorOverride?: string) {
    return renderDeviceHealthCell(value, colorOverride);
}

export interface SiteColumnsOptions {
    /**
     * If true, the site name column will be named "Name" instead of "Site".
     */
    siteNameAsName?: boolean;

    /**
     * If provided, the available columns will be limited to the provided list.
     */
    columns?: SiteTableColumnId[];

    /**
     * If true, the custom attributes will not be available as columns.
     */
    disableAttributeColumns?: boolean;
}

const DefaultTableColumns = [
    SiteTableColumn.Name,
    SiteTableColumn.State,
    SiteTableColumn.Type,
    SiteTableColumn.DeviceCount,
    SiteTableColumn.DeviceStatus,
    SiteTableColumn.Postcode,
    SiteTableColumn.BatteryStatus,
    SiteTableColumn.BatteryCapacity,
    SiteTableColumn.BatteryTotalTimeRemaining,
];

export function useSiteColumnDefinitions(
    options: SiteColumnsOptions = {}
): ColumnWithId<SiteTableColumnId, SiteWithDevices | SiteDevice>[] {
    const attributeColumns = useLazyLoadQuery<columns_attributeColumnsQuery>(
        graphql`
            query columns_attributeColumnsQuery($disableAttributeColumns: Boolean!) {
                attributeNames(search: "", only: Site, limit: 1000, status: Active) @skip(if: $disableAttributeColumns)
            }
        `,
        {
            disableAttributeColumns: options.disableAttributeColumns ?? false,
        },
        {
            fetchPolicy: 'store-or-network',
        }
    );

    let attributeColumnDefinitions: ColumnWithId<SiteTableColumnId, SiteWithDevices | SiteDevice>[] = [];

    if (!options.disableAttributeColumns && attributeColumns.attributeNames) {
        attributeColumnDefinitions = attributeColumns.attributeNames.map(name => {
            return columnHelper.display({
                id: `tag_${name}`,
                header: `Tag ${name}`,
                exportHeader: `Tag ${name}`,
                cell: ({ row }) => {
                    if (row.depth !== 0) {
                        return '';
                    }

                    const site = row.original as SiteWithDevices;
                    return site.attributes?.find(attribute => attribute.name === name)?.value;
                },
                meta: {
                    filterable: true,
                    sortable: false,
                },
            });
        }) as ColumnWithId<SiteTableColumnId, SiteWithDevices | SiteDevice>[];
    }

    const availableColumnIds = options.columns ?? DefaultTableColumns;

    const columns = availableColumnIds.map(columnId => {
        if (columnId === SiteTableColumn.Name) {
            return options.siteNameAsName ? NameColumnAlt : NameColumn;
        }

        const column = ColumnDefinitionsExcludingName.find(column => column.id === columnId);

        console.assert(column, `No column definition found for column ID ${columnId}`);

        return column;
    }) as ColumnWithId<SiteTableColumnId, SiteWithDevices | SiteDevice>[];

    return columns.concat(attributeColumnDefinitions);
}
