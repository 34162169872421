import {
    SiteDeviceBatteries,
    SiteDeviceStatusFilter,
    SiteNameFilter,
    SitePostcodeFilter,
    SiteStateFilter,
    SiteTypeFilter,
} from './types';

export function encodeSiteTypeFilter(type: SiteTypeFilter): string {
    return encodeURIComponent(type.id);
}

export function encodeSiteNameFilter(name: SiteNameFilter): string {
    return encodeURIComponent(name.name);
}

export function encodeSiteStateFilter(state: SiteStateFilter): string {
    return encodeURIComponent(state.state);
}

export function encodeSitePostcodeFilter(postcode: SitePostcodeFilter): string {
    return encodeURIComponent(postcode.value);
}

export function encodeSiteDeviceBatteries(batteries: SiteDeviceBatteries): string {
    return encodeURIComponent(batteries);
}

export function encodeSiteDeviceStatusFilter(status: SiteDeviceStatusFilter): string {
    return encodeURIComponent(status.id);
}
