/**
 * @generated SignedSource<<39193127fbaa44a9aa71160a247cdd06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneratorOverview_settings$data = {
  readonly generator: {
    readonly lowFuelLevelThresholdPercent: number;
    readonly timeSinceLastRunThreshold: string;
  };
  readonly " $fragmentType": "GeneratorOverview_settings";
};
export type GeneratorOverview_settings$key = {
  readonly " $data"?: GeneratorOverview_settings$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneratorOverview_settings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneratorOverview_settings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneratorSettings",
      "kind": "LinkedField",
      "name": "generator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lowFuelLevelThresholdPercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timeSinceLastRunThreshold",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Settings",
  "abstractKey": null
};

(node as any).hash = "fecbf3143ef635b5808990e68f94e3db";

export default node;
