import React, { FC, useState } from 'react';

import { FilterContainer } from '@accesstel/pcm-ui';

import { SingleSelectionList } from 'components/FilterSingleSelect/SingleSelectionList';
import { CommonFilterProps } from 'filters/common';

import { ActivityLogType } from '../__generated__/HistoryContentQuery.graphql';
import { BatteryTestPane } from './BatteryTestPane';
import { GridEventStatusPane } from './GridEventStatusPane';
import { InsightSeverityPane } from './InsightSeverityPane';
import { InventoryEventPane } from './InventoryEventPane';

export interface SelectCategoriesPaneProps extends Omit<CommonFilterProps<string[]>, 'onSubmit'> {
    onSubmit: (types: ActivityLogType[], category: HistoryCategory) => void;
    clearButton: React.ReactNode;
}

export enum HistoryCategory {
    Inventory = 'Inventory',
    Insight = 'Insight',
    GridEvent = 'Grid Event',
    BatteryTest = 'Battery Test',
}

export const InventoryTypes: ActivityLogType[] = ['DeviceAdd', 'DeviceEdit'];
export const InsightTypes: ActivityLogType[] = [
    'AlertSeverityCritical',
    'AlertSeverityMajor',
    'AlertSeverityMinor',
    'AlertSeverityWarning',
    'AlertSeverityIndeterminate',
];
export const GridEventTypes: ActivityLogType[] = [
    'GridEventStatusHigh',
    'GridEventStatusLow',
    'GridEventStatusOffline',
];
export const BatteryTestTypes: ActivityLogType[] = ['BatteryTest'];

enum Mode {
    ChooseType,
    BatteryTest,
    GridEvent,
    Insight,
    Inventory,
}

export const SelectCategoriesPane: FC<SelectCategoriesPaneProps> = ({ ...rest }) => {
    const [mode, setMode] = useState<Mode>(Mode.ChooseType);

    const getCount = (category: HistoryCategory): number => {
        const { current } = rest;

        if (!current) {
            return 0;
        }

        if (category === HistoryCategory.BatteryTest) {
            return current.filter(filter => (BatteryTestTypes as string[]).includes(filter)).length; // TODO
        } else if (category === HistoryCategory.GridEvent) {
            return current.filter(filter => (GridEventTypes as string[]).includes(filter)).length;
        } else if (category === HistoryCategory.Insight) {
            return current.filter(filter => (InsightTypes as string[]).includes(filter)).length;
        } else if (category === HistoryCategory.Inventory) {
            return current.filter(filter => (InventoryTypes as string[]).includes(filter)).length;
        }

        return 0;
    };

    const backToChooseType = () => {
        setMode(Mode.ChooseType);
    };

    let content: React.ReactNode = <></>;

    if (mode === Mode.ChooseType) {
        content = (
            <FilterContainer
                title='Add Filter'
                primaryContent={
                    <SingleSelectionList
                        items={[
                            HistoryCategory.BatteryTest,
                            HistoryCategory.GridEvent,
                            HistoryCategory.Insight,
                            HistoryCategory.Inventory,
                        ]}
                        onSelect={category => {
                            if (category === HistoryCategory.BatteryTest) {
                                setMode(Mode.BatteryTest);
                            } else if (category === HistoryCategory.GridEvent) {
                                setMode(Mode.GridEvent);
                            } else if (category === HistoryCategory.Insight) {
                                setMode(Mode.Insight);
                            } else if (category === HistoryCategory.Inventory) {
                                setMode(Mode.Inventory);
                            }
                        }}
                        renderItem={category => `${category} (${getCount(category)})`}
                        selectedItem={undefined}
                    />
                }
                hideClearButton
                hideConfirmButton
                {...rest}
            />
        );
    } else if (mode === Mode.BatteryTest) {
        content = <BatteryTestPane {...rest} onClearOrBack={backToChooseType} hasPrevious={true} />;
    } else if (mode === Mode.GridEvent) {
        content = <GridEventStatusPane {...rest} onClearOrBack={backToChooseType} hasPrevious={true} />;
    } else if (mode === Mode.Insight) {
        content = <InsightSeverityPane {...rest} onClearOrBack={backToChooseType} hasPrevious={true} />;
    } else if (mode === Mode.Inventory) {
        content = <InventoryEventPane {...rest} onClearOrBack={backToChooseType} hasPrevious={true} />;
    }

    return content;
};
