import React, { FC } from 'react';

import { SkeletonCard, SkeletonPadding, SkeletonText } from '@accesstel/pcm-ui';

export const DeviceRectifiersSkeleton: FC = () => {
    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-bold text-xl'>
                    <SkeletonText characters={10} />
                </div>
                <div className='flex flex-col justify-start items-end flex-grow font-bold text-xl'>
                    <div>
                        <SkeletonText characters={4} />
                    </div>
                    <div className='text-xs font-normal'>
                        <SkeletonText characters={15} />
                    </div>
                </div>
            </div>
            <SkeletonPadding spacing={38} />

            <div className='flex flex-row'>
                <SkeletonText characters={5} size='subheading' />
                <SkeletonPadding spacing={1} />
                <SkeletonText characters={10} size='subheading' />
            </div>
            <SkeletonCard height={384} />

            <SkeletonPadding spacing={38} />

            <div className='flex flex-row justify-between'>
                <div>
                    <SkeletonText characters={8} size='subheading' />
                    <SkeletonText characters={3} size='normal' />
                </div>
                <div>
                    <SkeletonText characters={8} size='subheading' />
                    <SkeletonText characters={3} size='normal' />
                </div>
                <div>
                    <SkeletonText characters={8} size='subheading' />
                    <SkeletonText characters={3} size='normal' />
                </div>
                <div>
                    <SkeletonText characters={8} size='subheading' />
                    <SkeletonText characters={3} size='normal' />
                </div>
            </div>
        </div>
    );
};
