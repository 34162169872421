import React from 'react';

import { BookmarkIcon, ResultItem, createHighlightContext, isRelevant } from '@accesstel/pcm-ui';

import { ReadableRoute } from 'lib/hook';

export function getRelevantRouteItems(
    query: string,
    currentPath: string,
    visibleRoutes: ReadableRoute[]
): ResultItem[] {
    if (query.trim() === '') {
        return [];
    }

    const context = createHighlightContext(query);
    const results: ResultItem[] = [];

    for (const route of visibleRoutes) {
        // Don't offer the current page as a suggestion
        const normalisedPath = normalizePath(currentPath);
        const routePath = normalizePath(route.path);

        if (normalisedPath === routePath) {
            continue;
        }

        if (isRelevant(route.title, context)) {
            results.push({
                primaryText: route.title,
                secondaryText: route.description ?? 'Navigate to this page',
                to: route.path,
                icon: <BookmarkIcon />,
            });
        }
    }

    return results;
}

function normalizePath(path: string) {
    return path.replace(/\/+$/, '');
}
