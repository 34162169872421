import React, { FC } from 'react';

import { BatteryTypeManModelFilter } from '../types';

export interface ManModelFilterResultProps {
    filter: BatteryTypeManModelFilter;
}

export const ManModelFilterResult: FC<ManModelFilterResultProps> = ({ filter }) => {
    if (filter.wildcard) {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span className='text-eggplantLight'>Matching</span>
                <span>{filter.name}</span>
            </div>
        );
    } else {
        return <div>{filter.name}</div>;
    }
};
