import React, { FC } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';

import { PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { getGlobalEnvironment } from '../../../lib/environment';
import { PersonalSettingsContentQuery } from './__generated__/PersonalSettingsContentQuery.graphql';
import { TimezoneSettings } from './sections/TimezoneSettings';
import { UnitSettings } from './sections/UnitSettings';

export interface PersonalSettingsContentProps {
    queryRef: PreloadedQuery<PersonalSettingsContentQuery>;
}

export const PersonalSettingsContent: FC<PersonalSettingsContentProps> = ({ queryRef }) => {
    const data = usePreloadedQuery(LoadQuery, queryRef);

    return (
        <div className='space-y-6 mb-32 text-eggplantRegular'>
            <PageHeading value='Personal Settings' />

            <div className='space-y-6'>
                <UnitSettings settings={data.currentUser.preferences} />
                <TimezoneSettings settings={data.currentUser.preferences} />
            </div>
        </div>
    );
};

export async function loadPersonalSettingsContent() {
    return loadQuery(getGlobalEnvironment(), LoadQuery, {});
}

const LoadQuery = graphql`
    query PersonalSettingsContentQuery {
        currentUser {
            preferences {
                ...UnitSettingsFragment
                ...TimezoneSettingsFragment
            }
        }
    }
`;
