/**
 * @generated SignedSource<<cb4484a6e1226aa90e4bafc4d6d997d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type TaskDeviceSortField = "Name" | "Site" | "State" | "Status" | "%future added value";
export type DeviceFilter = {
  acIncidentCount?: IntRangeFilter | null;
  acMtbf?: IntRangeFilter | null;
  acMttr?: IntRangeFilter | null;
  acOutageDurationSum?: IntRangeFilter | null;
  acReliabilityPercentile?: IntRangeFilter | null;
  attributes?: ReadonlyArray<AttributeFilters> | null;
  batteryCapacityRemaining?: FloatRangeFilter | null;
  batteryCapacityTotal?: FloatRangeFilter | null;
  batteryEnergyTotal?: FloatRangeFilter | null;
  batteryInstallDate?: DateRangeFilter | null;
  batteryManufactureDate?: DateRangeFilter | null;
  batteryReserveTime?: FloatRangeFilter | null;
  batteryState?: ReadonlyArray<BatteryStatusFilter> | null;
  batteryStateOfHealth?: FloatRangeFilter | null;
  batteryStringCount?: IntRangeFilter | null;
  batteryTemperature?: FloatRangeFilter | null;
  category?: ReadonlyArray<DeviceCategory> | null;
  health?: ReadonlyArray<StringFilter> | null;
  ids?: ReadonlyArray<string> | null;
  lastTestedDate?: DateRangeFilter | null;
  monitorOnly?: BooleanFilter | null;
  name?: ReadonlyArray<StringFilter> | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  snmpVersion?: ReadonlyArray<StringFilter> | null;
  supportsBatteryTesting?: BooleanFilter | null;
  type?: ReadonlyArray<StringFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type BatteryStatusFilter = {
  value: BatteryStatus;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type TaskDeviceFilter = {
  deviceStatus?: ReadonlyArray<StringFilter> | null;
};
export type TaskDeviceOrdering = {
  dir?: SortDirection | null;
  field: TaskDeviceSortField;
};
export type StatusListContainerUpdateTableQuery$variables = {
  deviceFilters?: TaskDeviceFilter | null;
  filters?: DeviceFilter | null;
  id: string;
  orderBy?: TaskDeviceOrdering | null;
  page?: number | null;
};
export type StatusListContainerUpdateTableQuery$data = {
  readonly task: {
    readonly " $fragmentSpreads": FragmentRefs<"StatusList_task">;
  } | null;
};
export type StatusListContainerUpdateTableQuery = {
  response: StatusListContainerUpdateTableQuery$data;
  variables: StatusListContainerUpdateTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceFilters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StatusListContainerUpdateTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StatusList_task"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "StatusListContainerUpdateTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overallState",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "testState",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "abortedTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cancelledTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "deviceFilters",
                    "variableName": "deviceFilters"
                  },
                  {
                    "kind": "Variable",
                    "name": "filters",
                    "variableName": "filters"
                  },
                  {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "orderBy"
                  },
                  {
                    "kind": "Variable",
                    "name": "page",
                    "variableName": "page"
                  }
                ],
                "concreteType": "PaginatedDevices",
                "kind": "LinkedField",
                "name": "devices",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "page",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "size",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNext",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPrevious",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Device",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "site",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Address",
                            "kind": "LinkedField",
                            "name": "address",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": (v5/*: any*/),
                        "concreteType": "DeviceBatteryTestResults",
                        "kind": "LinkedField",
                        "name": "test",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "failReason",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "finalSoH",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DualPlaneCompanion",
                        "kind": "LinkedField",
                        "name": "dualPlaneCompanion",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Device",
                            "kind": "LinkedField",
                            "name": "device",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "BatteryTest",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad3e0c27eb3176a217ed96b62f819f6a",
    "id": null,
    "metadata": {},
    "name": "StatusListContainerUpdateTableQuery",
    "operationKind": "query",
    "text": "query StatusListContainerUpdateTableQuery(\n  $id: ID!\n  $filters: DeviceFilter\n  $deviceFilters: TaskDeviceFilter\n  $orderBy: TaskDeviceOrdering\n  $page: Int\n) {\n  task(id: $id) {\n    __typename\n    ...StatusList_task\n    id\n  }\n}\n\nfragment StatusList_task on BatteryTest {\n  id\n  overallState\n  testState\n  completedTime\n  abortedTime\n  cancelledTime\n  devices(filters: $filters, deviceFilters: $deviceFilters, orderBy: $orderBy, page: $page) {\n    total\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n    data {\n      id\n      site {\n        name\n        address {\n          state\n        }\n        id\n      }\n      name\n      test(id: $id) {\n        id\n        state\n        failReason\n        finalSoH\n      }\n      dualPlaneCompanion {\n        device {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "deca3c861eac17c05876630d18a7152a";

export default node;
