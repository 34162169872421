import React from 'react';

import { Tooltip } from '@accesstel/pcm-ui';

import { createColumnHelper } from '@tanstack/react-table';
import { IncidentTableColumn } from 'filters/incidents';
import { SiteTableColumn } from 'filters/site';
import { SiteIncidentTableColumn } from 'filters/site-incidents';
import humanizeDuration from 'humanize-duration';
import { ColumnWithId } from 'layouts';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { renderDeviceIncidentStatusCell, renderFeedIncidentStatusCell } from 'lib/table-columns';
import { Duration } from 'luxon';

import { DeviceTableColumn, DeviceTableColumnId } from '../../../filters/device/types';
import {
    ACPowerDeviceIncidentListTableQuery$data,
    ACPowerEventStatus,
} from './device-incident-list/__generated__/ACPowerDeviceIncidentListTableQuery.graphql';
import { ACPowerIncidentListQuery$data } from './incident-list/__generated__/ACPowerIncidentListQuery.graphql';
import { ACPowerSiteIncidentListTableQuery$data } from './site-incident-list/__generated__/ACPowerSiteIncidentListTableQuery.graphql';

export enum TimeRange {
    LastMonth = 'LastMonth',
    LastQuarter = 'LastQuarter',
    Last6Months = 'Last6Months',
    LastYear = 'LastYear',
    Lifetime = 'Lifetime',
}

export const DefaultTimeRange: TimeRange = TimeRange.Last6Months;

export const DefaultTimeRanges = [
    TimeRange.LastMonth,
    TimeRange.LastQuarter,
    TimeRange.Last6Months,
    TimeRange.LastYear,
    TimeRange.Lifetime,
];

export const TimeRangeSearchParameter = 'interval';

export const TimeRangeDurations: Record<TimeRange, Duration | null> = {
    LastMonth: Duration.fromObject({ months: 1 }),
    LastQuarter: Duration.fromObject({ months: 3 }),
    Last6Months: Duration.fromObject({ months: 6 }),
    LastYear: Duration.fromObject({ years: 1 }),
    Lifetime: null,
};

export const TimeRangeDescriptions: Record<TimeRange, string | null> = {
    LastMonth: 'month',
    LastQuarter: 'quarter',
    Last6Months: '6 months',
    LastYear: 'year',
    Lifetime: null,
};

// Sites table
export const SiteTableBaseColumns = [
    SiteTableColumn.Name,
    SiteTableColumn.State,
    SiteTableColumn.ACReliability,
    SiteTableColumn.Mtbf,
    SiteTableColumn.Mttr,
] as SiteTableColumn[];

export const SiteTableAllColumns = [
    ...SiteTableBaseColumns,
    SiteTableColumn.Type,
    SiteTableColumn.IncidentCount,
    SiteTableColumn.OutageDurationSum,
    SiteTableColumn.ACRisk,
] as SiteTableColumn[];

// Devices table
export const DeviceTableBaseColumns: DeviceTableColumnId[] = [
    DeviceTableColumn.Name,
    DeviceTableColumn.Type,
    DeviceTableColumn.IncidentCount,
    DeviceTableColumn.Mttr,
    DeviceTableColumn.Mtbf,
    DeviceTableColumn.OutageDurationSum,
    DeviceTableColumn.ACReliability,
];

export const DeviceTableAllColumns: DeviceTableColumnId[] = [...DeviceTableBaseColumns, DeviceTableColumn.Site];

// Device Incidents table
function renderStatusCell(value: ACPowerEventStatus | null) {
    if (value === null) {
        return '';
    }
    if (value) {
        return renderDeviceIncidentStatusCell(value);
    }
}

function renderFeedCell(label: string, status: ACPowerEventStatus, voltage: number | null) {
    return renderFeedIncidentStatusCell(label, status, voltage);
}

// Device Incidents table
export type DeviceIncidents = ACPowerDeviceIncidentListTableQuery$data['acEvents']['data'][number];
const deviceIncidentColumnHelper = createColumnHelper<DeviceIncidents>();
const DeviceNameIncidentColumn = deviceIncidentColumnHelper.accessor('device.name', {
    id: IncidentTableColumn.DeviceName,
    header: 'Device Name',
    cell: ({ cell }) => {
        const value = cell.getValue();
        return value;
    },
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '16rem',
    },
});
const SiteNameIncidentColumn = deviceIncidentColumnHelper.accessor('device.site.name', {
    id: SiteTableColumn.Name,
    header: 'Site Name',
    cell: ({ cell }) => {
        const value = cell.getValue();
        return value;
    },
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '30rem',
    },
});
const DeviceIncidentDateColumn = deviceIncidentColumnHelper.accessor('startTime', {
    id: IncidentTableColumn.IncidentStartTime,
    header: 'Date',
    cell: ({ cell }) => {
        const value = getDateTimeFormat(cell.getValue());
        return value;
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});
const DeviceIncidentTypeColumn = deviceIncidentColumnHelper.accessor('worstStatus', {
    id: IncidentTableColumn.IncidentType,
    header: 'Type',
    cell: ({ cell }) => renderStatusCell(cell.getValue()),
    meta: {
        filterable: true,
        sortable: true,
    },
});
const DeviceIncidentDurationColumn = deviceIncidentColumnHelper.accessor('duration', {
    id: IncidentTableColumn.IncidentDuration,
    header: 'Duration',
    cell: ({ cell }) => {
        let duration;
        const value = cell.getValue();
        if (value) {
            duration = Duration.fromISO(value);
            duration = humanizeDuration(duration.as('milliseconds'), { largest: 1, round: true, units: ['h', 'm'] });
        }

        return duration;
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});
const DeviceAffectedFeedsColumn = deviceIncidentColumnHelper.accessor('affectedFeeds', {
    id: IncidentTableColumn.AffectedFeeds,
    header: 'Affected Feeds',
    cell: ({ cell }) => {
        const { affectedFeeds } = cell.row.original;
        return affectedFeeds.map(feed => renderFeedCell(feed.label, feed.status, feed.voltage));
    },
    meta: {
        filterable: true,
    },
});

export const DeviceIncidentTableBaseColumns = [
    DeviceIncidentDateColumn,
    DeviceIncidentTypeColumn,
    DeviceIncidentDurationColumn,
    DeviceNameIncidentColumn,
    DeviceAffectedFeedsColumn,
] as ColumnWithId<IncidentTableColumn, DeviceIncidents>[];

export const DeviceIncidentTableAllColumns = [...DeviceIncidentTableBaseColumns] as ColumnWithId<
    IncidentTableColumn,
    DeviceIncidents
>[];

// Site Incidents table
export type SiteIncidents = NonNullable<
    ACPowerSiteIncidentListTableQuery$data['site']
>['acPower']['events']['data'][number];
const siteColumnHelper = createColumnHelper<SiteIncidents>();

function renderDeviceNames(data: SiteIncidents) {
    if (data.affectedAllDevices) {
        return (
            <div className='flex flex-row items-center'>
                <Tooltip content={`Affected Devices: ${data.affectedDevices.map(device => device.name).join(', ')}`}>
                    <span className='cursor italic'>Site-Wide</span>
                </Tooltip>
            </div>
        );
    } else if (data.affectedDevices.length >= 2) {
        return (
            <div className='flex flex-row items-center'>
                <Tooltip content={`Affected Devices: ${data.affectedDevices.map(device => device.name).join(', ')}`}>
                    <span className='cursor'>{data.affectedDevices.length} Devices</span>
                </Tooltip>
            </div>
        );
    } else {
        return data.affectedDevices.map(device => device.name);
    }
}

export const SiteIncidentDeviceNameColumn = siteColumnHelper.display({
    id: SiteIncidentTableColumn.DeviceName,
    header: 'Affected Devices',
    cell: ({ cell }) => renderDeviceNames(cell.row.original),
    meta: {
        filterable: true,
        sortable: true,
    },
});
const SiteIncidentDurationColumn = siteColumnHelper.accessor('duration', {
    id: SiteIncidentTableColumn.IncidentDuration,
    header: 'Duration',
    cell: ({ cell }) => {
        let duration;
        const value = cell.getValue();

        duration = Duration.fromISO(value);
        duration = humanizeDuration(duration.as('milliseconds'), { largest: 1, round: true, units: ['h', 'm'] });
        if (value === null) {
            return '';
        }

        return duration;
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});
const SiteIncidentTypeColumn = siteColumnHelper.accessor('worstStatus', {
    id: SiteIncidentTableColumn.IncidentType,
    header: 'Type',
    cell: ({ cell }) => renderStatusCell(cell.getValue()),
    meta: {
        filterable: true,
        sortable: true,
    },
});
const SiteIncidentDateColumn = siteColumnHelper.accessor('startTime', {
    id: SiteIncidentTableColumn.IncidentStartTime,
    header: 'Date',
    cell: ({ cell }) => {
        const value = getDateTimeFormat(cell.getValue());
        if (value === null) {
            return '';
        }
        return value;
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});
const SiteAffectedDeviceFeedsColumn = siteColumnHelper.accessor('affectedFeeds', {
    id: SiteIncidentTableColumn.AffectedFeeds,
    header: 'Affected Device Feeds',
    cell: ({ cell }) => {
        const { affectedFeeds } = cell.row.original;
        return affectedFeeds.map(feed => renderFeedCell(feed.label, feed.status, feed.voltage));
    },
    meta: {
        filterable: true,
    },
});
export const SiteIncidentTableBaseColumns = [
    SiteIncidentDateColumn,
    SiteIncidentTypeColumn,
    SiteIncidentDurationColumn,
    SiteIncidentDeviceNameColumn,
    SiteAffectedDeviceFeedsColumn,
] as ColumnWithId<SiteIncidentTableColumn, SiteIncidents>[];
export const SiteIncidentTableAllColumns = [...SiteIncidentTableBaseColumns] as ColumnWithId<
    SiteIncidentTableColumn,
    SiteIncidents
>[];

// Incidents table
export type Incidents = ACPowerIncidentListQuery$data['acEvents']['data'][number];

export const IncidentTableBaseColumns = [
    DeviceIncidentDateColumn,
    DeviceNameIncidentColumn,
    SiteNameIncidentColumn,
    DeviceIncidentTypeColumn,
    DeviceIncidentDurationColumn,
    DeviceAffectedFeedsColumn,
] as ColumnWithId<IncidentTableColumn, Incidents>[];

export const IncidentTableAllColumns = [...IncidentTableBaseColumns] as ColumnWithId<IncidentTableColumn, Incidents>[];
