import React, { FC } from 'react';

import { FilterDateSelect } from 'components/FilterDateSelect';
import { DateRangeFilter, PredefinedRange } from 'components/FilterDateSelect/common';
import { LastMonth, LastWeek, LastYear, Today, Yesterday } from 'components/FilterDateSelect/presets';

import { CommonFilterProps } from '../../common/types';

export type ACPowerIncidentStartTimeFilterUIProps = CommonFilterProps<DateRangeFilter>;

const FilterPresets: PredefinedRange[] = [Today, Yesterday, LastWeek, LastMonth, LastYear];

export const ACPowerIncidentStartTimeFilterUI: FC<ACPowerIncidentStartTimeFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterDateSelect
            title='Filter by date'
            current={current ?? null}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
            showBack={hasPrevious}
            presets={FilterPresets}
        />
    );
};
