import React, { useMemo, useState } from 'react';

import { ActiveFilterActionButton, Button, IconButton, NavigateTo, PageHeading } from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';

import { useDocumentTitle } from '../../components';
import { Breakpoint, useBreakpoint } from '../../lib/breakpoints';
import { CompareBody } from './CompareBody';
import { CompareEmpty } from './CompareEmpty';
import { findDifferences } from './lib/compare';
import style from './style.module.css';
import { CommonCompareLayoutProps, SectionExpandedState } from './types';

const classNames = classNamesBind.bind(style);

export interface CompareLayoutProps<T> extends CommonCompareLayoutProps<T> {
    /**
     * The page title
     */
    title: string;

    /**
     * A subtitle displayed under the page title.
     *
     * If not provided, it defaults to "Comparing {} items" where "{}" is the number
     * of elements in the data array
     */
    subtitle?: string;

    /**
     * The primary action button text
     */
    primaryAction?: string;

    /**
     * The primary action button nagivation settings.
     *
     * If this is set, the primary action button will be a link.
     * Either this or `primaryActionOnClick` must be set if `primaryAction` is set.
     */
    primaryActionLink?: NavigateTo;

    /**
     * The primary action button click handler.
     *
     * If this is set, the primary action button will be a button.
     * Either this or `primaryActionLink` must be set if `primaryAction` is set.
     */
    primaryActionOnClick?: () => void;

    /**
     * The title for the empty message.
     * Displayed if the data is empty.
     */
    emptyMessageTitle?: string;

    /**
     * The description for the empty message. Positioned below the title.
     * Displayed if the data is empty.
     */
    emptyMessageDescription?: string;

    /**
     * The action for the empty message. Positioned below the description.
     * Displayed if the data is empty.
     */
    emptyMessageAction?: string;

    /**
     * The navigation settings for the empty message action.
     * Displayed if the data is empty.
     */
    emptyMessageActionLink?: NavigateTo;

    /**
     * The click handler for the empty message action.
     * Displayed if the data is empty.
     */
    emptyMessageActionOnClick?: () => void;

    /**
     * Additional actions displayed above the compare layout.
     *
     * These are displayed as icon buttons.
     */
    secondaryActions?: ActiveFilterActionButton[];
}

/**
 * The compare layout displays data in a column-based layout. It is similar to shopping comparison websites.
 *
 * @see CompareLayoutProps
 */
export function CompareLayout<T>({
    title,
    subtitle,
    primaryAction,
    primaryActionLink,
    primaryActionOnClick,
    secondaryActions,
    ...remainingProps
}: CompareLayoutProps<T>) {
    const breakpoint = useBreakpoint();

    useDocumentTitle(title);

    const { data, sections } = remainingProps;

    subtitle ??= `Comparing ${data.length} items`;

    const [isComparing, setIsComparing] = useState(true);
    const [sectionExpandedState, setSectionExpandedState] = useState<SectionExpandedState>(() => {
        const state: Record<string, boolean> = {};
        for (const section of sections) {
            state[section.title] = true;
        }
        return state;
    });

    const differences = useMemo(() => findDifferences(data, sections), [data, sections]);

    let maximumColumnsWithoutScrolling = 2;
    switch (breakpoint) {
        case Breakpoint.ExtraSmall:
        case Breakpoint.Small:
            // small and xs are unsupported breakpoints
            maximumColumnsWithoutScrolling = 2;
            break;
        case Breakpoint.Medium:
            maximumColumnsWithoutScrolling = 2;
            break;
        case Breakpoint.Large:
        case Breakpoint.ExtraLarge:
            maximumColumnsWithoutScrolling = 3;
            break;
        case Breakpoint.XXLarge:
            maximumColumnsWithoutScrolling = 4;
            break;
        case Breakpoint.XXXLarge:
            maximumColumnsWithoutScrolling = 6;
            break;
    }

    return (
        <>
            <div className='flex justify-between items-center'>
                <PageHeading value={title} />
                {primaryAction && (primaryActionLink || primaryActionOnClick) && (
                    <Button
                        buttonText={primaryAction!}
                        size='small'
                        to={primaryActionLink}
                        onClick={primaryActionOnClick}
                    />
                )}
            </div>
            <PageHeading value={subtitle} secondary subheading />
            <div className='flex justify-end gap-2 mb-2 h-7'>
                {secondaryActions?.map(action => (
                    <div key={action.buttonText} className='relative'>
                        <IconButton
                            title={action.buttonText}
                            icon={action.buttonIcon}
                            onClick={action.onClick}
                            circleColor={action.embeddedComponent ? 'eggplant' : 'white'}
                            iconStroke={action.embeddedComponent ? 'white' : 'coral'}
                            fontFamily='light'
                            size='small-medium'
                        />
                        {action.embeddedComponent && (
                            <div className={classNames('action_embedded_area')}>{action.embeddedComponent}</div>
                        )}
                    </div>
                ))}
            </div>
            <div className={classNames('compare_layout')}>
                {data.length === 0 && (
                    <CompareEmpty
                        {...remainingProps}
                        columnFitCount={maximumColumnsWithoutScrolling}
                        sectionExpandedState={sectionExpandedState}
                        setSectionExpandedState={setSectionExpandedState}
                    />
                )}
                {data.length > 0 && (
                    <CompareBody
                        {...remainingProps}
                        columnFitCount={maximumColumnsWithoutScrolling}
                        isComparing={isComparing}
                        setIsComparing={setIsComparing}
                        sectionExpandedState={sectionExpandedState}
                        setSectionExpandedState={setSectionExpandedState}
                        differences={differences}
                    />
                )}
            </div>
        </>
    );
}
