import { NavigateTo } from '@accesstel/pcm-ui';

import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { Paths } from 'lib/routes';

import { DeviceIdParam, MetricParam, TimeRangeParam } from '../settings';
import { SelectedMetric } from '../types';
import { encodeDeviceIds, encodeMetricTimeRange, encodeMetrics } from './encode';

export function makeLinkToMetric(
    devices: string[] | string,
    metrics: SelectedMetric[] | SelectedMetric,
    timeRange?: DateRangeFilter
): NavigateTo {
    const devicesAsArray = Array.isArray(devices) ? devices : [devices];
    const metricsAsArray = Array.isArray(metrics) ? metrics : [metrics];

    const search: { devices: string; metrics: string; timeRange?: string } = {
        [DeviceIdParam]: encodeDeviceIds(devicesAsArray),
        [MetricParam]: encodeMetrics(metricsAsArray),
    };

    if (timeRange) {
        search[TimeRangeParam] = encodeMetricTimeRange(timeRange);
    }

    return {
        pathname: Paths.ExploreMetrics,
        search: search,
    };
}
