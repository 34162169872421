import { Environment, commitMutation } from 'react-relay';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';

import { taskCancelMutation } from './__generated__/taskCancelMutation.graphql';

export enum CancelTaskResult {
    Success,
    Failure,
}

export function cancelTask(environment: Environment, taskId: string): Promise<CancelTaskResult> {
    return new Promise((resolve, reject) => {
        commitMutation<taskCancelMutation>(environment, {
            mutation: CancelTaskMutation,
            variables: {
                id: taskId,
            },
            onCompleted: (response, errors) => {
                if (errors) {
                    reject(errors);
                } else if (response.cancelTask === true) {
                    resolve(CancelTaskResult.Success);
                } else {
                    resolve(CancelTaskResult.Failure);
                }
            },
            onError: error => {
                captureException(error, scope => {
                    scope.setTag('task_id', taskId);
                    scope.setTag('Function', 'Cancel Task');
                    return scope;
                });
            },
        });
    });
}

const CancelTaskMutation = graphql`
    mutation taskCancelMutation($id: ID!) {
        cancelTask(id: $id)
    }
`;
