import { FilterDefinition, FilterValueMap } from '../common';
import { UserExtraFilters, UserTableColumn } from './types';

export type UserColumnFilterMap = {
    [UserTableColumn.Name]: null;
    [UserTableColumn.Email]: null;
    [UserTableColumn.Roles]: null;
    [UserTableColumn.CreatedAt]: null;
    [UserTableColumn.UpdatedAt]: null;
    [UserTableColumn.Enabled]: null;
    [UserTableColumn.LastLogin]: null;
    [UserTableColumn.Status]: null;
};

export const DefaultValues: FilterValueMap<UserColumnFilterMap> = {
    [UserTableColumn.Name]: null,
    [UserTableColumn.Email]: null,
    [UserTableColumn.Roles]: null,
    [UserTableColumn.CreatedAt]: null,
    [UserTableColumn.UpdatedAt]: null,
    [UserTableColumn.Enabled]: null,
    [UserTableColumn.LastLogin]: null,
    [UserTableColumn.Status]: null,
};

export const StaticUserFilterDefinitions: FilterDefinition<UserTableColumn>[] = [];

export const DefaultExtraFilters: Record<UserExtraFilters, unknown> = {};
