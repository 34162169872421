/**
 * @generated SignedSource<<7695623cf654f2ef59a53f8ce0b9d5e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
export type ProtocolType = "Basic" | "Gateway" | "Snmp" | "%future added value";
export type deviceTypesQuery$variables = Record<PropertyKey, never>;
export type deviceTypesQuery$data = {
  readonly deviceTypes: {
    readonly data: ReadonlyArray<{
      readonly category: DeviceCategory;
      readonly connectivity: {
        readonly ipEnabled: boolean;
        readonly protocols: ReadonlyArray<{
          readonly displayName: string | null;
          readonly id: string;
          readonly type: ProtocolType;
        }>;
      };
      readonly displayName: string;
      readonly id: string;
    }>;
  };
};
export type deviceTypesQuery = {
  response: deviceTypesQuery$data;
  variables: deviceTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PaginatedDeviceTypes",
    "kind": "LinkedField",
    "name": "deviceTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceType",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceTypeConnectivity",
            "kind": "LinkedField",
            "name": "connectivity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ipEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProtocolDefinition",
                "kind": "LinkedField",
                "name": "protocols",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "deviceTypesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "deviceTypesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a23006976c55eede63a8495cdacd6546",
    "id": null,
    "metadata": {},
    "name": "deviceTypesQuery",
    "operationKind": "query",
    "text": "query deviceTypesQuery {\n  deviceTypes {\n    data {\n      id\n      category\n      displayName\n      connectivity {\n        ipEnabled\n        protocols {\n          id\n          type\n          displayName\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4bfabf58ddb181492c57cdf1cd7db702";

export default node;
