/**
 * @generated SignedSource<<8bc9bfb034373c55c8edbf560262c54f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type GeneratorOperationMode = "Auto" | "Manual" | "Off" | "Test" | "User" | "%future added value";
export type GeneratorState = "CoolingDown" | "Idle" | "Running" | "RunningOnLoad" | "WarmingUp" | "%future added value";
export type SiteOverviewContentQuery$variables = {
  id: string;
};
export type SiteOverviewContentQuery$data = {
  readonly site: {
    readonly batteryMetrics: {
      readonly commonStatus: BatteryStatus | null;
      readonly multipleStatuses: boolean;
    };
    readonly devices: {
      readonly data: ReadonlyArray<{
        readonly acPower: {
          readonly feeds: {
            readonly data: ReadonlyArray<{
              readonly metrics: {
                readonly latestVoltage: number | null;
              };
              readonly status: DeviceHealth;
            }>;
          };
        };
        readonly battery: {
          readonly installed: boolean;
          readonly metrics: {
            readonly latestRemainingCapacity: number | null;
            readonly latestStatus: BatteryStatus;
            readonly latestUsedCapacity: number | null;
          };
        };
        readonly fuelTankMetrics: {
          readonly latestLevel: number | null;
        };
        readonly generatorMetrics: {
          readonly latestOperationMode: GeneratorOperationMode | null;
          readonly latestState: GeneratorState | null;
        };
        readonly health: DeviceHealth | null;
        readonly id: string;
        readonly lastOnline: string | null;
        readonly lastUpdate: string | null;
        readonly load: {
          readonly metrics: {
            readonly isOnline: boolean | null;
            readonly latestPower: number | null;
          };
        };
        readonly rectifier: {
          readonly metrics: {
            readonly latestOutputPower: number | null;
          };
        } | null;
        readonly type: {
          readonly category: DeviceCategory;
        };
      }>;
    };
    readonly health: {
      readonly commonStatus: DeviceHealth | null;
      readonly multipleStatuses: boolean;
      readonly status: DeviceHealth;
    } | null;
    readonly name: string;
  } | null;
};
export type SiteOverviewContentQuery = {
  response: SiteOverviewContentQuery$data;
  variables: SiteOverviewContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commonStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "multipleStatuses",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteDeviceHealth",
  "kind": "LinkedField",
  "name": "health",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteBatteryMetrics",
  "kind": "LinkedField",
  "name": "batteryMetrics",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "health",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastOnline",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "KiloWatt"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceLoad",
  "kind": "LinkedField",
  "name": "load",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceLoadMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOnline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v13/*: any*/),
          "kind": "ScalarField",
          "name": "latestPower",
          "storageKey": "latestPower(unit:\"KiloWatt\")"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceRectifier",
  "kind": "LinkedField",
  "name": "rectifier",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceRectifierMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v13/*: any*/),
          "kind": "ScalarField",
          "name": "latestOutputPower",
          "storageKey": "latestOutputPower(unit:\"KiloWatt\")"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ACFeedMetrics",
  "kind": "LinkedField",
  "name": "metrics",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVoltage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceBattery",
  "kind": "LinkedField",
  "name": "battery",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "installed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestRemainingCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestUsedCapacity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceGeneratorMetrics",
  "kind": "LinkedField",
  "name": "generatorMetrics",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestOperationMode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceFuelTankMetrics",
  "kind": "LinkedField",
  "name": "fuelTankMetrics",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestLevel",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOverviewContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceACPower",
                    "kind": "LinkedField",
                    "name": "acPower",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaginatedACFeeds",
                        "kind": "LinkedField",
                        "name": "feeds",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ACFeed",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteOverviewContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceACPower",
                    "kind": "LinkedField",
                    "name": "acPower",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaginatedACFeeds",
                        "kind": "LinkedField",
                        "name": "feeds",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ACFeed",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v16/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5a7c03a1b1b332cfa6cf990bf27a575",
    "id": null,
    "metadata": {},
    "name": "SiteOverviewContentQuery",
    "operationKind": "query",
    "text": "query SiteOverviewContentQuery(\n  $id: ID!\n) {\n  site(id: $id) {\n    name\n    health {\n      status\n      commonStatus\n      multipleStatuses\n    }\n    batteryMetrics {\n      commonStatus\n      multipleStatuses\n    }\n    devices {\n      data {\n        id\n        health\n        lastUpdate\n        lastOnline\n        type {\n          category\n          id\n        }\n        load {\n          metrics {\n            isOnline\n            latestPower(unit: KiloWatt)\n          }\n        }\n        rectifier {\n          metrics {\n            latestOutputPower(unit: KiloWatt)\n          }\n        }\n        acPower {\n          feeds {\n            data {\n              status\n              metrics {\n                latestVoltage\n              }\n              id\n            }\n          }\n        }\n        battery {\n          installed\n          metrics {\n            latestStatus\n            latestRemainingCapacity\n            latestUsedCapacity\n          }\n        }\n        generatorMetrics {\n          latestState\n          latestOperationMode\n        }\n        fuelTankMetrics {\n          latestLevel\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b50570656a23ad71f0d0dd6cc8acbcc8";

export default node;
