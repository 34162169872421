/**
 * @generated SignedSource<<49b37313d5cfd222e7c4d9ca6c5dd293>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type siteTypeSuggestQuery$variables = {
  limit?: number | null;
  name: string;
};
export type siteTypeSuggestQuery$data = {
  readonly siteTypes: ReadonlyArray<string>;
};
export type siteTypeSuggestQuery = {
  response: siteTypeSuggestQuery$data;
  variables: siteTypeSuggestQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "name"
      }
    ],
    "kind": "ScalarField",
    "name": "siteTypes",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "siteTypeSuggestQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "siteTypeSuggestQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f95ec5e974bf7639bdaaedbc83324054",
    "id": null,
    "metadata": {},
    "name": "siteTypeSuggestQuery",
    "operationKind": "query",
    "text": "query siteTypeSuggestQuery(\n  $name: String!\n  $limit: Int\n) {\n  siteTypes(search: $name, limit: $limit)\n}\n"
  }
};
})();

(node as any).hash = "d1b935dc41cf6126ab8a9a5861ba921e";

export default node;
