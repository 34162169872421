import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { MetricDisplay } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { Duration } from 'luxon';

import { FeedIncidentIndicator } from './FeedIncidentIndicator';
import { DeviceFeedCard$key } from './__generated__/DeviceFeedCard.graphql';

export interface DeviceFeedCardProps {
    fragmentRef: DeviceFeedCard$key;
}

export const DeviceFeedCard: FC<DeviceFeedCardProps> = ({ fragmentRef }) => {
    const { label, mtbf, mttr, outage, overVoltage, underVoltage, standaloneCount } = useFragment(
        Fragment,
        fragmentRef
    );

    let mtbfFormatted;
    if (mtbf != null) {
        const mtbfDuration = Duration.fromObject({ minutes: mtbf });

        mtbfFormatted = humanizeDuration(mtbfDuration.as('milliseconds'), {
            units: ['y', 'mo', 'd', 'h', 'm'],
            round: true,
            largest: 1,
        });
    } else {
        mtbfFormatted = 'No failures';
    }

    let mttrFormatted;
    if (mttr != null && mtbf != null) {
        const mttrDuration = Duration.fromObject({ minutes: mttr });

        mttrFormatted = humanizeDuration(mttrDuration.as('milliseconds'), {
            units: ['y', 'mo', 'd', 'h', 'm'],
            round: true,
            largest: 1,
        });
    } else {
        mttrFormatted = 'No failures';
    }

    let standaloneCountFormatted;
    if (standaloneCount === 1) {
        standaloneCountFormatted = '1 incident';
    } else {
        standaloneCountFormatted = `${standaloneCount} incidents`;
    }

    return (
        <div className='bg-grayRegular p-4 col-span-12 lg:col-span-4 mb-4 lg:mb-0 grid grid-cols-2'>
            <div className='col-span-2 mb-2'>
                <div className='font-bold'>Feed {label}</div>
                <div className='flex flex-row justify-start gap-2'>
                    <FeedIncidentIndicator count={outage} type='outage' />
                    <FeedIncidentIndicator count={underVoltage} type='undervoltage' />
                    <FeedIncidentIndicator count={overVoltage} type='overvoltage' />
                </div>
            </div>
            <div className='col-span-2 sm:col-span-1 lg:col-span-2'>
                <MetricDisplay label='Mean Time Between Failures' value={mtbfFormatted} size='small' />
            </div>
            <div className='col-span-2 sm:col-span-1 lg:col-span-2'>
                <MetricDisplay label='Mean Time To Restoration' value={mttrFormatted} size='small' />
            </div>
            <div className='col-span-2'>
                <MetricDisplay label='Standalone incidents' value={standaloneCountFormatted} size='small' />
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceFeedCard on DeviceFeedACEventMetrics {
        label
        outage
        underVoltage
        overVoltage
        standaloneCount
        mttr
        mtbf
    }
`;
