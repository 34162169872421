/**
 * @generated SignedSource<<dc71102727a4929b444b60aed1fba717>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceIncidentList_acPower$data = {
  readonly events: {
    readonly data: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"DeviceIncidentRow_event">;
    }>;
  };
  readonly " $fragmentType": "DeviceIncidentList_acPower";
};
export type DeviceIncidentList_acPower$key = {
  readonly " $data"?: DeviceIncidentList_acPower$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceIncidentList_acPower">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showSignificant"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceIncidentList_acPower",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "isSignificant",
              "variableName": "showSignificant"
            },
            {
              "kind": "Variable",
              "name": "startTime",
              "variableName": "startRange"
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "dir": "Desc",
            "field": "StartTime"
          }
        },
        {
          "kind": "Literal",
          "name": "pageSize",
          "value": 5
        }
      ],
      "concreteType": "PaginatedACPowerEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ACPowerEvent",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DeviceIncidentRow_event"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceACPower",
  "abstractKey": null
};

(node as any).hash = "1e9ee82a080230e055a1548c84993e6e";

export default node;
