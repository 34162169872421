import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { SiteIncidentTypeFilter } from '../types';

export type SiteACPowerIncidentTypeFilterUIProps = CommonFilterProps<SiteIncidentTypeFilter[]>;
export const SiteACPowerIncidentTypeFilterUI: FC<SiteACPowerIncidentTypeFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const options: SiteIncidentTypeFilter[] = [
        { id: 'Outage', name: 'Outage' },
        { id: 'UnderVoltage', name: 'Undervoltage' },
        { id: 'OverVoltage', name: 'Overvoltage' },
    ];
    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by incident type'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
