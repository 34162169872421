import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { ActivityLogType } from 'components/PagedActivityLog/__generated__/PagedActivityLogQuery.graphql';

import { HistoryCategory, SelectCategoriesPaneProps } from './SelectCategoryPane';

type InventoryEventPaneProps = SelectCategoriesPaneProps;

enum InventoryEvent {
    DeviceAdd = 'Add Device',
    DeviceEdit = 'Edit Device',
}

export const InventoryEventPane: FC<InventoryEventPaneProps> = ({
    current,
    onClearOrBack,
    onSubmit,
    hasPrevious,
    onClose,
    clearButton,
}) => {
    return (
        <MultipleSelect<InventoryEvent>
            title='Inventory Action'
            variant='standard'
            options={[InventoryEvent.DeviceAdd, InventoryEvent.DeviceEdit]}
            active={
                current
                    ? ((current as ActivityLogType[])
                          .map(humanizeInventoryEventType)
                          .filter(type => type != null) as InventoryEvent[])
                    : []
            }
            renderItem={event => event}
            renderItemSimple={event => event}
            onSubmit={events =>
                onSubmit(
                    events.map(convertInventoryEventType).filter(event => event != null) as ActivityLogType[],
                    HistoryCategory.Inventory
                )
            }
            onClear={() => {
                onClearOrBack();
            }}
            showBack={hasPrevious}
            onClose={onClose}
            clearButton={clearButton}
        />
    );
};

function convertInventoryEventType(event: InventoryEvent): ActivityLogType | null {
    if (event === InventoryEvent.DeviceAdd) {
        return 'DeviceAdd';
    }
    if (event === InventoryEvent.DeviceEdit) {
        return 'DeviceEdit';
    }

    return null;
}

export function humanizeInventoryEventType(event: ActivityLogType): InventoryEvent | null {
    if (event === 'DeviceAdd') {
        return InventoryEvent.DeviceAdd;
    }
    if (event === 'DeviceEdit') {
        return InventoryEvent.DeviceEdit;
    }

    return null;
}
