export enum ReadAccess {
    None = 'None',
    ReadOnly = 'ReadOnly',
}

export enum ReadWriteAccess {
    None = 'None',
    ReadOnly = 'ReadOnly',
    ReadWrite = 'ReadWrite',
}

export enum AdminAccess {
    None = 'None',
    Admin = 'Admin',
}

export enum AuditAccess {
    None = 'None',
    Audit = 'Audit',
}

export interface UserPermissions {
    general: ReadAccess;
    assets: ReadWriteAccess;
    batteryHealthTasks: ReadWriteAccess;
    administration: AdminAccess;
    audit: AuditAccess;
}

export interface UserFormValues {
    name: string;
    email: string;
    permissions: UserPermissions;
}

export function createDefaultUserFormValues(): UserFormValues {
    return {
        name: '',
        email: '',
        permissions: {
            general: ReadAccess.ReadOnly,
            assets: ReadWriteAccess.None,
            batteryHealthTasks: ReadWriteAccess.None,
            administration: AdminAccess.None,
            audit: AuditAccess.None,
        },
    };
}
