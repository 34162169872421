import React, { FC } from 'react';

import { DevicePaneSkeleton } from '../../components/DevicePaneSkeleton';
import { DeviceRectifiersSkeleton } from './DeviceRectifiersSkeleton';

export const RectifiersSkeleton: FC = () => {
    return (
        <div className='grid grid-cols-2 gap-4'>
            <DevicePaneSkeleton>
                <DeviceRectifiersSkeleton />
            </DevicePaneSkeleton>
            <DevicePaneSkeleton>
                <DeviceRectifiersSkeleton />
            </DevicePaneSkeleton>
        </div>
    );
};
