import React, { ReactNode } from 'react';

import { Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';

import { CompareSectionDefinition, FieldDifferences } from '../types';

export interface DataRowProps<T> {
    section: CompareSectionDefinition<T>;
    collapsed?: boolean;
    data: T[];
    differences?: FieldDifferences;
}

export function DataRow<T>({ section, collapsed, data, differences = {} }: DataRowProps<T>) {
    return (
        <>
            {data.map((_, index) => (
                <div
                    key={index}
                    className={classNames('pt-4 bg-white bg-opacity-40 px-4 text-xs font-light italic leading-6', {
                        'col-start-1': index === 0,
                    })}
                >
                    {!collapsed && section.hint}
                </div>
            ))}
            {!collapsed &&
                section.fields.map((field, index) =>
                    data.map((item, columnIndex) => {
                        const fieldDifferences = differences[`${section.title}.${field.title}`];

                        let columnIsDifferent = false;
                        if (fieldDifferences && fieldDifferences.length > columnIndex) {
                            columnIsDifferent = fieldDifferences[columnIndex];
                        }

                        let content: ReactNode;
                        const value = field.render(item);

                        if (value === '' || value == null) {
                            content = <div className='text-eggplantExtraLight'>-</div>;
                        } else if (typeof value === 'string') {
                            content = (
                                <Tooltip content={value} overflowOnly>
                                    <div className='truncate'>{value}</div>
                                </Tooltip>
                            );
                        } else {
                            content = value;
                        }

                        return (
                            <div
                                key={`${index}-${columnIndex}`}
                                className={classNames('bg-white bg-opacity-40', {
                                    'col-start-1': columnIndex === 0,
                                    'cursor-zoom-in': field.onFieldClick,
                                })}
                                onClick={() => field.onFieldClick?.(item)}
                            >
                                <div
                                    className={classNames('px-4', {
                                        'bg-coralLight': columnIsDifferent,
                                    })}
                                >
                                    {content}
                                </div>
                            </div>
                        );
                    })
                )}
        </>
    );
}
