/**
 * @generated SignedSource<<10d5975a5d58e3d201717f1d2ee23269>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteErrorType = "DuplicateName" | "EditNotAllowed" | "InvalidAddress" | "InvalidAttributeName" | "InvalidLatitude" | "InvalidLongitude" | "InvalidPostcode" | "InvalidSite" | "InvalidState" | "MissingAddress" | "MissingName" | "MissingState" | "MissingType" | "Noop" | "Skipped" | "%future added value";
export type SiteUpdateIn = {
  address?: AddressUpdateIn | null;
  attributes?: ReadonlyArray<AttributeIn> | null;
  name?: string | null;
  type?: string | null;
};
export type AddressUpdateIn = {
  address?: string | null;
  coordinates?: GpsCoordinatesUpdateIn | null;
  postcode?: string | null;
  state?: string | null;
};
export type GpsCoordinatesUpdateIn = {
  latitude?: string | null;
  longitude?: string | null;
};
export type AttributeIn = {
  name: string;
  value: string;
};
export type savingEditSiteMutation$variables = {
  id: string;
  site: SiteUpdateIn;
};
export type savingEditSiteMutation$data = {
  readonly editSite: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<SiteErrorType>;
  } | null;
};
export type savingEditSiteMutation = {
  response: savingEditSiteMutation$data;
  variables: savingEditSiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "site"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "site",
    "variableName": "site"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problems",
      "storageKey": null
    }
  ],
  "type": "SiteProblem",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "savingEditSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editSite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "savingEditSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e511cda4a20b8771ce9f976fff688828",
    "id": null,
    "metadata": {},
    "name": "savingEditSiteMutation",
    "operationKind": "mutation",
    "text": "mutation savingEditSiteMutation(\n  $id: ID!\n  $site: SiteUpdateIn!\n) {\n  editSite(id: $id, site: $site) {\n    __typename\n    ... on Site {\n      id\n    }\n    ... on SiteProblem {\n      problems\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "76f476505d6fdba1a34e6e8b151cc3a3";

export default node;
