import {
    DeviceBatteryStatusFilter,
    DeviceNameFilter,
    DeviceSNMPVersionFilter,
    DeviceStatusFilter,
    DeviceTypeFilter,
    MonitorOnlyStatus,
} from './types';

export function encodeDeviceNameFilter(name: DeviceNameFilter): string {
    return encodeURIComponent(name.name);
}

export function encodeDeviceTypeFilter(type: DeviceTypeFilter): string {
    const separator = '+';
    return encodeURIComponent(`${type.id}${separator}${type.name}`);
}

export function encodeDeviceBatteryStatusFilter(status: DeviceBatteryStatusFilter): string {
    return encodeURIComponent(status.id);
}

export function encodeDeviceMonitorOnlyStatusFilter(status: MonitorOnlyStatus): string {
    return encodeURIComponent(status);
}

export function encodeDeviceSNMPVersionFilter(version: DeviceSNMPVersionFilter): string {
    return encodeURIComponent(version.id);
}

export function encodeDeviceStatusFilter(status: DeviceStatusFilter): string {
    return encodeURIComponent(status.id);
}
