import React, { Dispatch, FC, useCallback, useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { CrossIcon, FormikDropdownWithSearch, IconButton, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import classNames from 'classnames';
import { SUGGESTIONS_LIMIT } from 'lib/provision';
import { queries_GetBatteryMakeModelQuery } from 'views/manage/__generated__/queries_GetBatteryMakeModelQuery.graphql';
import { getBatteryMakeModel } from 'views/manage/queries';

import { FormControlAction, FormControlActionTypes, FormControlParameters } from '../../../lib/reducer';
import style from '../../../style.module.css';
import { BatteryTypeReference } from '../schema';

export interface BatteryTypeSelectorProps {
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const BatteryTypeSelector: FC<BatteryTypeSelectorProps> = ({ formControlState, formControlDispatch }) => {
    const { show } = useToast();

    const [batteryTypeReferences, setBatteryTypeReferences] = useState<BatteryTypeReference[]>([]);
    const [batteryTypeListError, setBatteryTypeListError] = useState<string | null>(null);

    // FIXME: Move to lib/suggestors
    const environment = useRelayEnvironment();

    const handleSearch = useCallback(
        (search: string, force = false) => {
            if (search.length === 0 && !force) {
                return;
            }

            fetchQuery<queries_GetBatteryMakeModelQuery>(environment, getBatteryMakeModel, {
                search,
                limit: SUGGESTIONS_LIMIT,
            }).subscribe({
                next(value) {
                    const batteryTypeReferences = value.batteryTypes.data.map<BatteryTypeReference>(batteryType => ({
                        id: batteryType.id,
                        manufacturer: batteryType.manufacturer,
                        model: batteryType.model,
                        displayName: `${batteryType.manufacturer} ${batteryType.model}`,
                    }));
                    setBatteryTypeReferences(batteryTypeReferences);
                    setBatteryTypeListError(null);
                },
                error(error: unknown) {
                    captureException(error);
                    show({ variant: 'error', text: 'Failed to get battery types' });
                    setBatteryTypeReferences([]);
                    setBatteryTypeListError('Unable to search at this time');
                },
            });
        },
        [environment, show]
    );

    useEffect(() => {
        // Pre-populate search results, so there is something to select by default.
        // TODO: it would be better to show the most recently selected options instead of just any
        handleSearch('', true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNewMakeModelClick = () => {
        if (formControlState.addNewBatteryType) {
            formControlDispatch({ type: FormControlActionTypes.AddNewBatteryType, payload: false });
        } else {
            formControlDispatch({ type: FormControlActionTypes.AddNewBatteryType, payload: true });
        }
    };

    return (
        <>
            <div
                className={classNames(
                    'col-start-1',
                    formControlState.addNewBatteryType ? style.page_item_disabled : ''
                )}
            >
                <FormikDropdownWithSearch
                    name='type'
                    placeHolder='Battery Make and Model*'
                    light
                    disabledText='Adding New Battery Type'
                    disabled={formControlState.addNewBatteryType}
                    required={!formControlState.addNewBatteryType}
                    renderItemAsString={batteryType => batteryType.displayName}
                    items={batteryTypeReferences}
                    onSearch={handleSearch}
                    variant='outlined'
                    testId='battery-make-and-model'
                    noResultsText={search => {
                        if (batteryTypeListError) {
                            return batteryTypeListError;
                        }

                        if (search.length > 0) {
                            return 'No matching battery types';
                        }

                        return 'No battery types available';
                    }}
                />
            </div>

            <div className='flex items-end col-start-2'>
                <IconButton
                    title={
                        formControlState.addNewBatteryType
                            ? 'Use Existing Make and Model'
                            : 'New Battery Make and Model'
                    }
                    size='small'
                    onClick={onNewMakeModelClick}
                    icon={formControlState.addNewBatteryType ? <CrossIcon /> : undefined}
                />
            </div>
        </>
    );
};
