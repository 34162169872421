import React from 'react';
import { RouteObject, generatePath } from 'react-router-dom';

import { getDeviceBreadcrumb, getSiteBreadcrumb } from 'lib/breadcrumb';
import { isFeatureEnabled } from 'lib/featureFlag';
import { Paths } from 'lib/routes';

import { RouteExtraData } from '../../../lib/route-helpers';
import { ACPowerDeviceIncidentList } from './device-incident-list/ACPowerDeviceIncidentList';
import { ACPowerDeviceList } from './device-list';
import { ACPowerIncidentList } from './incident-list';
import { ACPowerOverview, loadACPowerOverviewPageData } from './overview';
import { ACPowerSiteIncidentList } from './site-incident-list/ACPowerSiteIncidentList';
import { ACPowerSiteList } from './site-list';
import { ACSiteReportLayout } from './site-view/ACSiteReportLayout';
import { loadACSiteReportLayoutData } from './site-view/ACSiteReportLayoutContent';
import { loadACDeviceViewContentQuery } from './site-view/device-view/ACDeviceViewContent';
import { loadACSiteOverviewContentQuery } from './site-view/overview/ACSiteOverviewContent';

const Routes: RouteObject[] = [];
if (isFeatureEnabled('acpower_report')) {
    Routes.push({
        path: 'acpower',
        handle: {
            title: 'AC Power',
            altTitle: 'AC Power Report',
        } as RouteExtraData,
        children: [
            {
                index: true,
                element: <ACPowerOverview />,
                loader: ({ request }) => loadACPowerOverviewPageData(new URL(request.url)),
            },
            {
                path: 'sites',
                element: <ACPowerSiteList />,
                handle: {
                    title: 'Sites',
                    altTitle: 'AC Power by Site',
                } as RouteExtraData,
            },
            {
                path: 'devices',
                element: <ACPowerDeviceList />,
                handle: {
                    title: 'Devices',
                    altTitle: 'AC Power by Device',
                } as RouteExtraData,
            },
            {
                path: 'sites/:siteId/:deviceId/incidents',
                element: <ACPowerDeviceIncidentList />,
                handle: {
                    title: 'Incidents',
                    altTitle: 'AC Power Device Incidents',
                    prependBreadcrumbItems: [
                        // prepend '/sites/:siteId/:deviceId' to breadcrumb
                        { to: Paths.ReportACPowerSiteList, title: 'Sites' },
                        {
                            to: params =>
                                generatePath(Paths.ReportACPowerSiteView, {
                                    siteId: params.siteId,
                                }),
                            title: params => getSiteBreadcrumb(params.siteId!),
                        },
                        {
                            to: params =>
                                generatePath(Paths.ReportACPowerSiteViewDevice, {
                                    siteId: params.siteId,
                                    deviceId: params.deviceId,
                                }),
                            title: params => getDeviceBreadcrumb(params.deviceId!),
                        },
                    ],
                } as RouteExtraData<{ siteId: string; deviceId: string }>,
            },
            {
                path: 'sites/:siteId',
                element: <ACSiteReportLayout />,
                loader: ({ params }) => loadACSiteReportLayoutData(params.siteId!),
                children: [
                    {
                        index: true,
                        loader: ({ params, request }) =>
                            loadACSiteOverviewContentQuery(params.siteId!, new URL(request.url)),
                        lazy: async () => {
                            const { ACSiteOverview } = await import('./site-view/overview/ACSiteOverview');
                            return { Component: ACSiteOverview };
                        },
                    },
                    {
                        path: ':deviceId',
                        loader: ({ params, request }) =>
                            loadACDeviceViewContentQuery(params.deviceId!, new URL(request.url)),
                        lazy: async () => {
                            const { ACDeviceView } = await import('./site-view/device-view/ACDeviceView');
                            return { Component: ACDeviceView };
                        },
                        handle: {
                            title: params => getDeviceBreadcrumb(params.deviceId!),
                            altTitle: 'AC Power Device View',
                        } as RouteExtraData<{ deviceId: string }>,
                    },
                ],
                handle: {
                    title: params => {
                        return getSiteBreadcrumb(params.siteId!);
                    },
                    altTitle: 'AC Power Site View',
                    // prepend '/sites' to breadcrumb
                    prependBreadcrumbItems: [{ to: Paths.ReportACPowerSiteList, title: 'Sites' }],
                } as RouteExtraData,
            },
            {
                path: 'sites/:siteId/incidents',
                element: <ACPowerSiteIncidentList />,
                handle: {
                    title: 'Incidents',
                    altTitle: 'AC Power Site Incidents',
                    prependBreadcrumbItems: [
                        // prepend '/sites/:siteId' to breadcrumb
                        { to: Paths.ReportACPowerSiteList, title: 'Sites' },
                        {
                            to: params => generatePath(Paths.ReportACPowerSiteView, { siteId: params.siteId }),
                            title: params => getSiteBreadcrumb(params.siteId!),
                        },
                    ],
                } as RouteExtraData<{ siteId: string }>,
            },
            {
                path: 'incidents',
                element: <ACPowerIncidentList />,
                handle: {
                    title: 'AC Power Incidents',
                    altTitle: 'AC Power Incidents',
                },
            },
        ],
    });
}
export default Routes;
