import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';

import { formatGeneratorRunReportCause } from '../../../lib/textFormatters';
import { GeneratorRunReportCause, GeneratorRunReportCauseFilter } from '../types';

export type CauseFilterUIProps = CommonFilterProps<GeneratorRunReportCauseFilter[]>;

export const CauseOptions: GeneratorRunReportCauseFilter[] = (
    ['LocalOtherStart', 'PowerFailure', 'RemoteStart', 'Unknown'] as GeneratorRunReportCause[]
).map(state => ({ id: state, name: formatGeneratorRunReportCause(state) }));

export const CauseFilterUI: FC<CauseFilterUIProps> = ({ current, onClearOrBack, onClose, onSubmit, hasPrevious }) => {
    return (
        <MultipleSelect
            options={CauseOptions}
            active={current}
            title='Filter by cause'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
