import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import { DeviceNameFilterUI, decodeDeviceNameFilter, encodeDeviceNameFilter } from 'filters/device';
import { decodeBatteryTestRuntimeFilter, encodeBatteryTestRuntimeFilter } from 'filters/task';

import { FilterDefinition, FilterValueMap, decodeDateRangeFilter, encodeDateRangeFilter } from '../common';
import { ACPowerIncidentIsSignificantOnlyFilterUI } from './components';
import { ACPowerActiveWithinFilterUI } from './components/ACPowerActiveWithinFilterUI';
import { ACPowerIncidentDurationFilterUI } from './components/ACPowerIncidentDurationFilterUI';
import { ACPowerFeedIncidentFilterUI } from './components/ACPowerIncidentFeedFilterUI';
import { ACPowerIncidentStartTimeFilterUI } from './components/ACPowerIncidentStartTimeFilterUI';
import { ACPowerIncidentTypeFilterUI } from './components/ACPowerIncidentTypeFilterUI';
import {
    decodeIncidentAffectedFeedFilter,
    decodeIncidentIsSignificantOnlyFilter,
    decodeIncidentTypeFilter,
} from './decode';
import {
    encodeIncidentAffectedFeedFilter,
    encodeIncidentIsSignificantOnlyFilter,
    encodeIncidentTypeFilter,
} from './encode';
import {
    AffectedFeedFilter,
    IncidentDateTimeFilter,
    IncidentDeviceNameFilter,
    IncidentDurationFilter,
    IncidentExtraFilters,
    IncidentIsSignificantOnlyFilter,
    IncidentTableColumn,
    IncidentTypeFilter,
} from './types';

export type IncidentColumnFilterMap = {
    [IncidentTableColumn.DeviceName]: IncidentDeviceNameFilter[];
    [IncidentTableColumn.IncidentType]: IncidentTypeFilter[];
    [IncidentTableColumn.AffectedFeeds]: AffectedFeedFilter[];
    [IncidentTableColumn.IncidentDuration]: IncidentDurationFilter | null;
    [IncidentTableColumn.IncidentStartTime]: DateRangeFilter | null;
};

export type IncidentExtraFilterColumnMap = {
    [IncidentExtraFilters.IsSignificant]: IncidentIsSignificantOnlyFilter | null;
    [IncidentExtraFilters.ActiveWithin]: DateRangeFilter | null;
};

export type IncidentAllFilterMap = IncidentColumnFilterMap & IncidentExtraFilterColumnMap;

export const DefaultValues: FilterValueMap<IncidentColumnFilterMap> = {
    [IncidentTableColumn.DeviceName]: [],
    [IncidentTableColumn.IncidentType]: [],
    [IncidentTableColumn.AffectedFeeds]: [],
    [IncidentTableColumn.IncidentDuration]: null,
    [IncidentTableColumn.IncidentStartTime]: null,
};

export const DefaultExtraFilters: FilterValueMap<IncidentExtraFilterColumnMap> = {
    [IncidentExtraFilters.IsSignificant]: null,
    [IncidentExtraFilters.ActiveWithin]: null,
};

export const StaticIncidentFilterDefinitions: FilterDefinition<IncidentTableColumn>[] = [
    {
        id: IncidentTableColumn.DeviceName,
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        column: IncidentTableColumn.DeviceName,
        component: () => DeviceNameFilterUI,
        describeValue: (value: IncidentDeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: IncidentTableColumn.IncidentStartTime,
        type: 'single',
        name: 'Incident Date',
        category: 'Incident Date',
        column: IncidentTableColumn.IncidentStartTime,
        component: () => ACPowerIncidentStartTimeFilterUI,
        describeValue: (value: IncidentDateTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: IncidentTableColumn.IncidentType,
        type: 'multi',
        name: 'Incident Type',
        category: 'Incident Type',
        column: IncidentTableColumn.IncidentType,
        component: () => ACPowerIncidentTypeFilterUI,
        describeValue: (value: IncidentTypeFilter) => value.name,
        encodeValue: encodeIncidentTypeFilter,
        decodeValue: decodeIncidentTypeFilter,
    },
    {
        id: IncidentTableColumn.IncidentDuration,
        type: 'single',
        name: 'Incident Duration',
        category: 'Incident Duration',
        column: IncidentTableColumn.IncidentDuration,
        component: () => ACPowerIncidentDurationFilterUI,
        describeValue: (value: IncidentDurationFilter) => formatDurationFilter(value),
        encodeValue: encodeBatteryTestRuntimeFilter,
        decodeValue: decodeBatteryTestRuntimeFilter,
    },
    {
        id: IncidentTableColumn.AffectedFeeds,
        type: 'multi',
        name: 'Affected Feeds',
        category: 'Affected Feeds',
        column: IncidentTableColumn.AffectedFeeds,
        component: () => ACPowerFeedIncidentFilterUI,
        describeValue: (value: AffectedFeedFilter) => value.label,
        encodeValue: encodeIncidentAffectedFeedFilter,
        decodeValue: decodeIncidentAffectedFeedFilter,
    },
    {
        id: IncidentExtraFilters.IsSignificant,
        type: 'single',
        name: 'Is Significant',
        category: 'Is Significant',
        component: () => ACPowerIncidentIsSignificantOnlyFilterUI,
        describeValue: (value: IncidentIsSignificantOnlyFilter) => value.label,
        encodeValue: encodeIncidentIsSignificantOnlyFilter,
        decodeValue: decodeIncidentIsSignificantOnlyFilter,
    },
    {
        id: IncidentExtraFilters.ActiveWithin,
        type: 'single',
        name: 'Active Within',
        category: 'Active Within',
        component: () => ACPowerActiveWithinFilterUI,
        describeValue: (value: DateRangeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
];
