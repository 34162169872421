import React, { FC } from 'react';

import { SkeletonText } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'lib/query-helpers';
import { BatteryTestResultCard } from 'views/tasks/battery-health/overview/components/BatteryTestResultCard';

import { PreviousTestsQuery } from './__generated__/PreviousTestsQuery.graphql';

interface PreviousTestsProps {
    deviceIds: string[];
    commencedTime?: string | null;
}

export const PreviousTests: FC<PreviousTestsProps> = ({ deviceIds, commencedTime }) => {
    const { data: props, error } = useQuery<PreviousTestsQuery>(
        graphql`
            query PreviousTestsQuery($deviceIds: [StringFilter!]!, $beforeTime: Timestamp) {
                batteryTestResults(
                    filters: { deviceId: $deviceIds }
                    to: $beforeTime
                    pageSize: 3
                    orderBy: { field: StartTime, dir: Desc }
                ) {
                    data {
                        ...BatteryTestResultCard_test
                    }
                }
            }
        `,
        {
            deviceIds: deviceIds.map(id => ({ value: id })),
            beforeTime: commencedTime,
        },
        {
            skip: !commencedTime,
        }
    );

    if ((!props && commencedTime !== null) || error) {
        return (
            <div>
                <SkeletonText characters={20} className='font-bold' />
                <div className='grid grid-cols-3 gap-4'>
                    <div className='bg-white' style={{ height: 180 }} />
                    <div className='bg-white' style={{ height: 180 }} />
                    <div className='bg-white' style={{ height: 180 }} />
                </div>
            </div>
        );
    } else if (props?.batteryTestResults.data.length === 0) {
        return null;
    } else {
        const cards = (props?.batteryTestResults.data ?? []).map((test, index) => (
            <BatteryTestResultCard key={index} test={test} />
        ));

        return (
            <div>
                <div className='font-bold'>Previous tests</div>
                <div className='mt-4 gap-4 grid grid-cols-3'>{cards}</div>
            </div>
        );
    }
};
