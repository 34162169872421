import React, { FC } from 'react';

import { FilterSingleSelect } from 'components/FilterSingleSelect';
import { CommonFilterProps } from 'filters/common';

import { IsAlertActive } from '../types';

export type AlertIsActiveFilterUIProps = CommonFilterProps<IsAlertActive | null>;

export const AlertIsActiveOptions: IsAlertActive[] = [IsAlertActive.Yes, IsAlertActive.No];

export const AlertIsActiveFilterUI: FC<AlertIsActiveFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => (
    <FilterSingleSelect
        options={AlertIsActiveOptions}
        active={current ?? undefined}
        title='Filter by alerts that are active'
        renderItem={item => item}
        onClear={onClearOrBack}
        onSubmit={item => onSubmit(item)}
        onClose={onClose}
        showBack={true}
    />
);
