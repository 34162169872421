import React, { Dispatch, useCallback, useState } from 'react';
import { PreloadedQuery, loadQuery, useRelayEnvironment } from 'react-relay';

import { CalendarIcon, ChartAreaAction, DescriptionIcon, DeviceIcon } from '@accesstel/pcm-ui';

import { FilterDateSelect } from 'components/FilterDateSelect';
import { LastMonth, LastWeek, LastYear, Today, Yesterday } from 'components/FilterDateSelect/presets';

import { SelectDevicesPane } from '../components/device-selection';
import SelectDevicesPaneContentQuery, {
    SelectDevicesPaneContentQuery as SelectDevicesPaneContentQueryType,
} from '../components/device-selection/__generated__/SelectDevicesPaneContentQuery.graphql';
import { SelectMetricsPane } from '../components/metric-selection/SelectMetricsPane';
import { SelectMetricsPaneFragment$key } from '../components/metric-selection/__generated__/SelectMetricsPaneFragment.graphql';
import { Action, ActionType } from '../reducer';
import { ViewState } from '../types';

const DefaultTimeRangePresets = [Today, Yesterday, LastWeek, LastMonth, LastYear];

export function useChartActions(
    viewState: ViewState,
    dispatchViewState: Dispatch<Action>,
    data: SelectMetricsPaneFragment$key,
    onSelectedDevicesChange: (devices: string[]) => void
): ChartAreaAction[] {
    const environment = useRelayEnvironment();

    const { timeRange, selectedDevices, selectedMetrics } = viewState;

    // Time range
    const [isTimeRangeDropdownOpen, setTimeRangeDropdownOpen] = useState(false);

    // Metric selection
    const [isMetricDropdownOpen, setMetricDropdownOpen] = useState(false);

    // Device selection
    const [isDeviceDropdownOpen, setDeviceDropdownOpen] = useState(false);
    const [deviceDropdownMetadataQuery, setDeviceDropdownMetadataQuery] =
        useState<PreloadedQuery<SelectDevicesPaneContentQueryType> | null>(null);

    const handleOpenDeviceDropdown = useCallback(() => {
        // Request metadata for the device selection dropdown
        setDeviceDropdownMetadataQuery(
            loadQuery(
                environment,
                SelectDevicesPaneContentQuery,
                {
                    deviceIds: selectedDevices,
                },
                {
                    fetchPolicy: 'store-or-network',
                }
            )
        );
        setDeviceDropdownOpen(true);
    }, [environment, selectedDevices]);

    return [
        {
            buttonText: 'Time range',
            buttonIcon: <CalendarIcon />,
            onClick: () => setTimeRangeDropdownOpen(true),
            variant: 'dark',
            embeddedComponent: isTimeRangeDropdownOpen && (
                <FilterDateSelect
                    title='Select time range'
                    current={timeRange}
                    presets={DefaultTimeRangePresets}
                    onClear={() => {
                        // No-op
                    }}
                    preventClear
                    onClose={() => setTimeRangeDropdownOpen(false)}
                    onSubmit={newRange => {
                        setTimeRangeDropdownOpen(false);
                        dispatchViewState({
                            type: ActionType.SetTimeRange,
                            range: newRange,
                        });
                    }}
                />
            ),
        },
        {
            buttonText: `Devices (${selectedDevices.length})`,
            buttonIcon: <DeviceIcon />,
            variant: 'dark',
            onClick: handleOpenDeviceDropdown,
            embeddedComponent: isDeviceDropdownOpen && (
                <SelectDevicesPane
                    onShowSelectionModal={() => {
                        setDeviceDropdownOpen(false);
                        dispatchViewState({
                            type: ActionType.ShowDeviceSelectionModal,
                        });
                    }}
                    current={selectedDevices}
                    onClose={() => setDeviceDropdownOpen(false)}
                    onSubmit={devices => {
                        setDeviceDropdownOpen(false);
                        dispatchViewState({
                            type: ActionType.SetSelectedDevices,
                            devices,
                        });

                        onSelectedDevicesChange(devices);
                    }}
                    metadataQueryRef={deviceDropdownMetadataQuery!}
                />
            ),
        },
        {
            buttonText: `Metrics (${selectedMetrics.length})`,
            buttonIcon: <DescriptionIcon />,
            variant: 'dark',
            onClick: () => {
                setMetricDropdownOpen(true);
            },
            embeddedComponent: isMetricDropdownOpen && (
                <SelectMetricsPane
                    current={selectedMetrics}
                    query={data}
                    onClose={() => setMetricDropdownOpen(false)}
                    onSelectMetric={metric => {
                        dispatchViewState({
                            type: ActionType.SetSelectedMetrics,
                            metrics: [...viewState.selectedMetrics, metric],
                        });
                    }}
                    onDeselectMetric={metric => {
                        dispatchViewState({
                            type: ActionType.SetSelectedMetrics,
                            metrics: viewState.selectedMetrics.filter(other => other !== metric),
                        });
                    }}
                />
            ),
        },
    ];
}
