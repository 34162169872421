import React, { FC, useMemo } from 'react';

import graphql from 'babel-plugin-relay/macro';
import {
    BatteryTestTableColumn,
    batteryTestDeviceToFilterObject,
    batteryTestToFilterObject,
    useBatteryTestFilter,
} from 'filters/battery-test';
import { BaseColumns } from 'filters/battery-test/settings';
import { SortDirection, useTableReducer } from 'layouts';
import { useQuery } from 'lib/query-helpers';

import { StatusList } from './StatusList';
import {
    StatusListContainerUpdateTableQuery,
    TaskDeviceOrdering,
} from './__generated__/StatusListContainerUpdateTableQuery.graphql';
import { StatusList_task$key } from './__generated__/StatusList_task.graphql';

export interface StatusListContainerProps {
    id: string;
    task: StatusList_task$key;
}

export const StatusListContainer: FC<StatusListContainerProps> = ({ id, task }) => {
    const [tableState, dispatchTableState] = useTableReducer<BatteryTestTableColumn>({
        defaultSortColumn: BatteryTestTableColumn.Name,
        allColumns: BaseColumns,
        defaultVisibleColumns: BaseColumns.map(column => column.id),
        storageKeyPrefix: 'task-status-list-table',
    });

    const [filters, dispatchFilters] = useBatteryTestFilter(id);
    const filterObject = useMemo(() => batteryTestToFilterObject(filters), [filters]);
    const filterDeviceObject = useMemo(() => batteryTestDeviceToFilterObject(filters), [filters]);

    const sortObject: TaskDeviceOrdering = useMemo(
        () => ({
            field: tableState.sortColumn,
            dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
        }),
        [tableState.sortColumn, tableState.sortDirection]
    );

    const {
        data: props,
        error: hasError,
        retry,
        isFetching,
    } = useQuery<StatusListContainerUpdateTableQuery>(UpdateTableQuery, {
        id,
        deviceFilters: filterDeviceObject,
        filters: filterObject,
        orderBy: sortObject,
        page: tableState.page,
    });

    return (
        <StatusList
            task={props?.task ?? task}
            hasError={!!hasError}
            retry={retry}
            filters={filters}
            dispatchFilters={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            isFetching={isFetching}
        />
    );
};

const UpdateTableQuery = graphql`
    query StatusListContainerUpdateTableQuery(
        $id: ID!
        $filters: DeviceFilter
        $deviceFilters: TaskDeviceFilter
        $orderBy: TaskDeviceOrdering
        $page: Int
    ) {
        task(id: $id) {
            ...StatusList_task
        }
    }
`;
