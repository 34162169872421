import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, LogoStackedReversedExtended } from '@accesstel/pcm-ui';

import { useDocumentTitle } from '../../../components';
import { SignOutReason } from '../../../lib/auth';

export const LoggedOut: FC = () => {
    useDocumentTitle('You have been signed out');

    const location = useLocation();

    const reason = location.state?.reason;
    const returnTo = location.state?.referrer;

    let message: string;

    if (reason === SignOutReason.SessionExpired) {
        message = 'Your session has expired. Please sign in again.';
    } else {
        message = 'You have been signed out from accata.';
    }

    return (
        <>
            <div className='login-view'>
                <div className='login-layout'>
                    <div className='login-layout-col-logo'>
                        <img src={LogoStackedReversedExtended} alt='accesstel logo' width={280} height={280} />
                    </div>
                    <div className='login-layout-col-form'>
                        <div className='space-y-8'>
                            <div>
                                <h1 className='text-3xl font-bold'>Signed out</h1>
                                <p>{message}</p>
                            </div>
                            <div className='flex justify-between space-x-6'>
                                <Button
                                    buttonClasses='flex-grow'
                                    type='submit'
                                    buttonText='Login again'
                                    variant='primary'
                                    size='medium'
                                    to={{
                                        pathname: '/login',
                                        search: {
                                            continue: returnTo,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
