import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { BarChart, Theme, ThemedBarSeriesType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { numberToLocaleString } from 'lib/numberFormatters';
import { formatRangeWithString, formatValueWithString } from 'lib/units';

import { ReliabilityChart_query$key } from './__generated__/ReliabilityChart_query.graphql';

export interface ReliabilityChartProps {
    queryRef: ReliabilityChart_query$key;

    sitePercentile: number | null;
}

export const ReliabilityChart: FC<ReliabilityChartProps> = ({ queryRef, sitePercentile }) => {
    const { siteACReliabilityDistribution } = useFragment(Fragment, queryRef);

    const chartData = processChartData(siteACReliabilityDistribution?.distribution ?? [], sitePercentile);

    let alertType: 'empty-good' | undefined;
    let alertMessage;
    let alertTitle;

    if (siteACReliabilityDistribution?.distribution.length === 0) {
        alertType = 'empty-good';
        alertMessage = '';
        alertTitle = 'No incidents found';
    }

    return (
        <div className='h-full relative'>
            <div className='absolute inset-0'>
                <BarChart
                    series={chartData}
                    // FIXME: This should be number, but the chart is not evenly spaced
                    // xAxisType='number'
                    formatStartXTick={() => 'Least reliable'}
                    formatEndXTick={() => 'Most reliable'}
                    formatXTick={() => ''}
                    formatTooltipContent={values => {
                        const totalSites = values.reduce((previous, current) => previous + (current.value ?? 0), 0);

                        if (totalSites === 1) {
                            return '1 site';
                        } else {
                            return `${totalSites} sites`;
                        }
                    }}
                    alertType={alertType}
                    alertMessage={alertMessage}
                    alertTitle={alertTitle}
                    variant='histogram'
                    allStacked
                />
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment ReliabilityChart_query on Query {
        siteACReliabilityDistribution(timeRange: Last6Months, buckets: 11) {
            distribution {
                key
                count
                percentileStart
                percentileEnd
            }
        }
    }
`;

interface ChartData {
    readonly key: number;
    readonly count: number;
    readonly percentileStart: number;
    readonly percentileEnd: number;
}

function processChartData(data: readonly ChartData[], sitePercentile: number | null): ThemedBarSeriesType<number>[] {
    return [
        {
            id: 'rel',
            name: 'Reliability',
            color: Theme.eggplantLight,
            data: data.map(({ key, count, percentileStart, percentileEnd }) => {
                let tooltip: string;
                if (percentileStart === percentileEnd) {
                    tooltip = `Ranked ${formatValueWithString(numberToLocaleString(percentileStart, 0), '%')}`;
                } else {
                    tooltip = `Ranked ${formatRangeWithString(
                        numberToLocaleString(percentileStart, 0),
                        numberToLocaleString(percentileEnd, 0),
                        '%'
                    )}`;
                }

                if (sitePercentile != null && sitePercentile >= percentileStart && sitePercentile <= percentileEnd) {
                    return {
                        key,
                        value: 0,
                        tooltip,
                    };
                }

                return {
                    key,
                    value: count,
                    tooltip,
                };
            }),
        },
        {
            id: 'selected',
            name: 'Selected',
            color: Theme.coralRegular,
            data: data.map(({ key, count, percentileStart, percentileEnd }) => {
                let tooltip: string;
                if (percentileStart === percentileEnd) {
                    tooltip = `Ranked ${formatValueWithString(numberToLocaleString(percentileStart, 0), '%')}`;
                } else {
                    tooltip = `Ranked ${formatRangeWithString(
                        numberToLocaleString(percentileStart, 0),
                        numberToLocaleString(percentileEnd, 0),
                        '%'
                    )}`;
                }

                if (sitePercentile != null && sitePercentile >= percentileStart && sitePercentile <= percentileEnd) {
                    return {
                        key,
                        value: count,
                        tooltip,
                    };
                }

                return {
                    key,
                    value: 0,
                    tooltip,
                };
            }),
        },
    ];
}
