import { useCallback, useRef, useState } from 'react';
import { GraphQLTaggedNode, useRelayEnvironment } from 'react-relay';

import { captureException } from '@sentry/react';
import { OperationType, Subscription, fetchQuery } from 'relay-runtime';

import { useDebouncer } from './debounce';

type VariableResolver<T extends OperationType> = (query: string | null) => T['variables'];

type ResultDecoder<T extends OperationType, U> = (data: T['response']) => U[];

/**
 * A hook to search for data using a GraphQL query and a search term, with debouncing
 *
 * @param searchQuery The GraphQL query used for searching
 * @param variableResolver a function to create the variables for GraphQL
 * @param resultDecoder A function which decodes the GraphQL result
 * @param debounceTimeMS the number of milliseconds to wait for use a input before searching
 * @returns a tuple containing the search results, the search function and a boolean indicating if an error occurred
 */
export function useSearcher<T extends OperationType, U>(
    searchQuery: GraphQLTaggedNode,
    variableResolver: VariableResolver<T>,
    resultDecoder: ResultDecoder<T, U>,
    debounceTimeMS = 150
) {
    const environment = useRelayEnvironment();
    const currentSubscription = useRef<Subscription | null>(null);

    const [searchResults, setSearchResults] = useState<U[] | null>(null);
    const [isError, setIsError] = useState<boolean>(false);

    const handleSearch = useCallback(
        (search: string) => {
            if (search.length === 0) {
                setSearchResults(null);
                return;
            }

            if (currentSubscription.current) {
                currentSubscription.current.unsubscribe();
            }

            const subscription = fetchQuery<T>(environment, searchQuery, variableResolver(search)).subscribe({
                next(value) {
                    const decodedResults = resultDecoder(value);
                    setSearchResults(decodedResults);
                    setIsError(false);
                },
                complete() {
                    currentSubscription.current = null;
                },
                error(error: unknown) {
                    captureException(error);
                    setSearchResults([]);
                    setIsError(true);
                },
            });

            currentSubscription.current = subscription;
        },
        [environment, resultDecoder, searchQuery, variableResolver]
    );

    const handleSearchWithDebounce = useDebouncer(handleSearch, debounceTimeMS);

    return [searchResults, handleSearchWithDebounce, isError] as const;
}
