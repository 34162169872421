import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';

import { BatteryTestDeviceStatusFilter } from '..';

export type BatteryTestDeviceStatusFilterUIProps = CommonFilterProps<BatteryTestDeviceStatusFilter[]>;

export const BatteryTestDeviceStatusOptions: BatteryTestDeviceStatusFilter[] = [
    { id: 'aborted', name: 'Aborted' },
    { id: 'completed', name: 'Completed' },
    { id: 'failed', name: 'Failed' },
    { id: 'in-progress', name: 'In Progress' },
    { id: 'inconclusive', name: 'Inconclusive' },
    { id: 'passed', name: 'Passed' },
    { id: 'scheduled', name: 'Scheduled' },
    { id: 'smart-start', name: 'Smart Start' },
    { id: 'waiting', name: 'Waiting' },
    { id: 'finalizing', name: 'Finalizing' },
    { id: 'analyzing', name: 'Analyzing' },
];

export const DeviceStatusFilterUI: FC<BatteryTestDeviceStatusFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    return (
        <MultipleSelect
            options={BatteryTestDeviceStatusOptions}
            active={current}
            title='Filter by status'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
