import React, { FC } from 'react';

import { FilterDurationSelect } from 'components/FilterDurationSelect';
import { PredefinedDuration, RunTimeFilter } from 'components/FilterDurationSelect/common';
import { Less1Hour, Less30Min, More1Hour, More5Hour } from 'components/FilterDurationSelect/presets';
import { CommonFilterProps } from 'filters/common';

const FilterPresets: PredefinedDuration[] = [Less30Min, Less1Hour, More1Hour, More5Hour];

export type RunTimeFilterUIProps = CommonFilterProps<RunTimeFilter>;

export const RunTimeFilterUI: FC<RunTimeFilterUIProps> = props => {
    return <FilterDurationSelect title='Filter by run time' presets={FilterPresets} {...props} />;
};
