import React from 'react';
import { useFragment } from 'react-relay';

import { useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { handleKiloUnitMetricsDisplay, numberToLocaleString } from 'lib/numberFormatters';
import { formatValueWithString } from 'lib/units';
import { makeLinkToMetric } from 'views/explore/lib/link';
import { Operation } from 'views/explore/types';

import { InlineLabelWithMetric } from '../../site-overview/components/InlineLabelWithMetric';
import { GeneratorLineCard_metrics$key } from './__generated__/GeneratorLineCard_metrics.graphql';

interface GeneratorLineCardProps {
    deviceId: string;
    lineNumber: number;
    fragmentRef: GeneratorLineCard_metrics$key;
}

const op = Operation.Average;

export const GeneratorLineCard: React.FC<GeneratorLineCardProps> = ({ deviceId, lineNumber, fragmentRef }) => {
    const metrics = useFragment(Fragment, fragmentRef);
    const navigate = useExtendedNavigate();

    const powerText = handleKiloUnitMetricsDisplay(metrics.latestPower, 'W');
    const apparentPowerText = handleKiloUnitMetricsDisplay(metrics.latestApparentPower, 'VA');
    const reactivePowerText = handleKiloUnitMetricsDisplay(metrics.latestReactivePower, 'VAR');
    const powerFactorText = handleKiloUnitMetricsDisplay(metrics.latestPowerFactor, '');
    const frequencyText = handleKiloUnitMetricsDisplay(metrics.latestFrequency, 'Hz');

    const navigateToMetrics = (metric: string) => {
        const to = makeLinkToMetric(deviceId, { metric, op });
        navigate(to);
    };

    return (
        <div className='bg-grayRegular flex flex-col space-y-1 p-2 z-10'>
            <div className='text-md font-bold'>Line {lineNumber}</div>
            <div className='pb-4'>
                <div className='flex flex-row justify-between font-light text-sm'>
                    <p>Voltage</p>
                    <p>Current</p>
                </div>
                <div className='flex flex-row justify-between text-3xl font-bold'>
                    <p
                        className='hover:underline cursor-pointer'
                        onClick={() => navigateToMetrics(`GeneratorOutputPhase${lineNumber}Voltage`)}
                    >
                        {formatValueWithString(
                            metrics.latestVoltage !== null ? numberToLocaleString(metrics.latestVoltage, 0) : '-',
                            'V'
                        )}
                    </p>
                    <p
                        className='hover:underline cursor-pointer'
                        onClick={() => navigateToMetrics(`GeneratorOutputPhase${lineNumber}Current`)}
                    >
                        {formatValueWithString(
                            metrics.latestCurrent !== null ? numberToLocaleString(metrics.latestCurrent, 1) : '-',
                            'A'
                        )}
                    </p>
                </div>
            </div>
            <InlineLabelWithMetric
                label='Power'
                metric={powerText}
                link={makeLinkToMetric(deviceId, {
                    metric: `GeneratorOutputPhase${lineNumber}Power`,
                    op,
                })}
            />
            <InlineLabelWithMetric
                label='Apparent Power'
                metric={apparentPowerText}
                link={makeLinkToMetric(deviceId, {
                    metric: `GeneratorOutputPhase${lineNumber}ApparentPower`,
                    op,
                })}
            />
            <InlineLabelWithMetric
                label='Reactive Power'
                metric={reactivePowerText}
                link={makeLinkToMetric(deviceId, {
                    metric: `GeneratorOutputPhase${lineNumber}ReactivePower`,
                    op,
                })}
            />
            <InlineLabelWithMetric
                label='Power Factor'
                metric={powerFactorText}
                link={makeLinkToMetric(deviceId, {
                    metric: `GeneratorOutputPhase${lineNumber}PowerFactor`,
                    op,
                })}
            />
            <InlineLabelWithMetric
                label='Frequency'
                metric={frequencyText}
                link={makeLinkToMetric(deviceId, {
                    metric: `GeneratorOutputPhase${lineNumber}Frequency`,
                    op,
                })}
            />
        </div>
    );
};

const Fragment = graphql`
    fragment GeneratorLineCard_metrics on DeviceGeneratorPhaseMetrics {
        latestVoltage
        latestCurrent
        latestPower
        latestApparentPower
        latestReactivePower
        latestPowerFactor
        latestFrequency
    }
`;
