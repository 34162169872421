import React, { FC } from 'react';

import { SkeletonCard, SkeletonPadding, SkeletonText } from '@accesstel/pcm-ui';

import { DevicePaneSkeleton } from '../../components/DevicePaneSkeleton';

export const HistorySkeleton: FC = () => (
    <DevicePaneSkeleton combinedView>
        <div className='font-bold text-xl'>
            <div className='flex justify-between'>
                <SkeletonText characters={12} />
                <SkeletonText characters={8} />
            </div>

            <SkeletonPadding spacing={38} />

            <div className='flex flex-row gap-4'>
                <div>
                    <SkeletonCard height={375} />
                </div>
                <div>
                    <SkeletonText characters={40} />
                    <SkeletonText characters={32} />
                    <SkeletonText characters={48} />
                    <SkeletonPadding spacing={22} />

                    <SkeletonText characters={5} size='subheading' />
                    <SkeletonText characters={30} />
                    <SkeletonText characters={40} />
                    <SkeletonText characters={42} />
                    <SkeletonPadding spacing={22} />

                    <SkeletonText characters={5} size='subheading' />
                    <SkeletonText characters={60} />
                    <SkeletonText characters={50} />
                    <SkeletonText characters={42} />
                    <SkeletonText characters={42} />
                </div>
            </div>
        </div>
    </DevicePaneSkeleton>
);
