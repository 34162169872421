/**
 * @generated SignedSource<<4abcf33a918c17f1161bc471c4babee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GeneratorRunReportEventType = "Refuel" | "StateChange" | "%future added value";
export type GeneratorState = "CoolingDown" | "Idle" | "Running" | "RunningOnLoad" | "WarmingUp" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Events_data$data = {
  readonly events: ReadonlyArray<{
    readonly amount?: number;
    readonly levelAfterPercent?: number;
    readonly levelBeforePercent?: number;
    readonly state?: GeneratorState;
    readonly timestamp: string;
    readonly type: GeneratorRunReportEventType;
  }>;
  readonly generatorStartTime: string;
  readonly generatorStopTime: string | null;
  readonly totalRunTime: string | null;
  readonly " $fragmentType": "Events_data";
};
export type Events_data$key = {
  readonly " $data"?: Events_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Events_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitVolume"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Events_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generatorStartTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generatorStopTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalRunTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "events",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "unit",
                  "variableName": "unitVolume"
                }
              ],
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "levelBeforePercent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "levelAfterPercent",
              "storageKey": null
            }
          ],
          "type": "GeneratorRunReportRefuelEvent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            }
          ],
          "type": "GeneratorRunReportStateChangeEvent",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};

(node as any).hash = "587602187f53d45ca027fafd7c14df52";

export default node;
