import React from 'react';
import { RouteObject } from 'react-router-dom';

import { AuthenticatedSection } from 'components/AuthenticatedSection';
import { getUsernameBreadcrumb } from 'lib/breadcrumb';
import { RouteExtraData } from 'lib/route-helpers';

import { Settings } from './Settings';
import { ManageUsers } from './users/ManageUsers';
import { AddUser } from './users/edit/AddUser';
import { EditUser } from './users/edit/EditUser';

const Routes: RouteObject[] = [
    {
        path: 'administration',
        element: <AuthenticatedSection administrator auditor />,
        handle: {
            title: 'Admin',
            altTitle: 'Administration',
            description: 'Navigate to the administration settings',
            permissions: { administrator: true, auditor: true },
        } as RouteExtraData,
        children: [
            {
                index: true,
                element: <Settings />,
            },
            {
                path: 'users',
                element: <AuthenticatedSection administrator />,
                handle: {
                    title: 'Users',
                    altTitle: 'Manage Users',
                    description: 'Manage users and their permissions',
                    permissions: { administrator: true },
                } as RouteExtraData,
                children: [
                    {
                        index: true,
                        element: <ManageUsers />,
                        handle: {
                            title: 'Users',
                            altTitle: 'Manage Users',
                            description: 'Manage users and their permissions',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'add',
                        element: <AddUser />,
                        handle: {
                            title: 'Add User',
                            altTitle: 'Add User',
                            description: 'Add a new user to accata',
                        } as RouteExtraData,
                    },
                    {
                        path: ':id',
                        element: <EditUser />,
                        handle: {
                            title: ({ id }) => getUsernameBreadcrumb(id!),
                            altTitle: 'Edit User',
                        } as RouteExtraData<{ id: string }>,
                    },
                ],
            },
            {
                path: 'configuration',
                element: <AuthenticatedSection administrator />,
                handle: {
                    title: 'System Configuration',
                    altTitle: 'System Configuration',
                    description: 'View and manage system configuration settings',
                    permissions: { administrator: true },
                } as RouteExtraData,
                lazy: async () => {
                    const { SystemConfiguration, loadSystemConfigurationView } = await import('./configuration');
                    return { Component: SystemConfiguration, loader: loadSystemConfigurationView };
                },
            },
            {
                path: 'audit',
                element: <AuthenticatedSection administrator auditor />,
                handle: {
                    title: 'Audit',
                    altTitle: 'Audit',
                    description: 'View the user audit log',
                    permissions: { administrator: true, auditor: true },
                } as RouteExtraData,
                lazy: async () => {
                    const { Audit } = await import('./audit');
                    return { Component: Audit };
                },
            },
        ],
    },
];

export default Routes;
