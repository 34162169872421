import React, { ReactNode } from 'react';

import {
    ArrowDownCircleIcon,
    ArrowRightCircleIcon,
    ArrowUpCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronsDownIcon,
    ChevronsUpIcon,
    CircleCheckIcon,
    CircleCrossIcon,
    CircleHelpIcon,
    CircleMinusIcon,
    CirclePauseIcon,
    CirclePlayIcon,
    CircleStopIcon,
    MinusIcon,
} from '@accesstel/pcm-ui';

import { AlertSeverity } from 'filters/alert';
import { BatteryTestState } from 'views/reports/batteries/__generated__/queries_batteryTestCardsQuery.graphql';
import { GeneratorRunReportState } from 'views/tasks/generator-run/run-report/__generated__/GeneratorRunReportContentQuery.graphql';

import { ACPowerEventStatus } from '../views/reports/ac-power/device-incident-list/__generated__/ACPowerDeviceIncidentListTableQuery.graphql';

type statusLabelConfig = {
    icon: ReactNode;
    bgClass?: string;
    textClass?: string;
};

export function batteryTestStateToStatusLabelConfig(state: BatteryTestState): statusLabelConfig {
    const config: statusLabelConfig = {
        icon: <CircleMinusIcon />,
        bgClass: 'bg-eggplantRegular',
        textClass: 'text-white',
    };

    switch (state) {
        case 'Aborted':
            config.icon = <CircleStopIcon />;
            config.bgClass = 'bg-eggplantExtraLight';
            config.textClass = 'text-eggplantRegular';
            break;
        case 'Failed':
            config.icon = <CircleCrossIcon />;
            config.bgClass = 'bg-coralRegular';
            break;
        case 'InProgress':
            config.icon = <CirclePlayIcon />;
            break;
        case 'Inconclusive':
            config.icon = <CircleHelpIcon />;
            config.bgClass = 'bg-grayRegular';
            config.textClass = 'text-eggplantRegular';
            break;
        case 'Passed':
            config.icon = <CircleCheckIcon />;
            config.bgClass = 'bg-pineLight';
            config.textClass = 'text-eggplantRegular';
            break;
        case 'Scheduled':
            config.icon = <ArrowRightCircleIcon />;
            config.bgClass = 'bg-coralLight';
            config.textClass = 'text-eggplantRegular';
            break;
        case 'SmartStart':
            config.icon = <CirclePauseIcon />;
            break;
        case 'Analyzing':
        case 'Finalizing':
        case 'Waiting':
            break;
    }

    return config;
}

export function alertSeverityToStatusLabelConfig(severity: AlertSeverity, isActive: boolean): statusLabelConfig {
    const config: statusLabelConfig = {
        icon: <CircleCheckIcon />,
        bgClass: 'bg-grayRegular',
        textClass: 'text-eggplantExtraLight',
    };

    if (!isActive) {
        // default config is the cleared config
        return config;
    }

    switch (severity) {
        case AlertSeverity.Critical: {
            config.icon = <ChevronsUpIcon />;
            config.bgClass = 'bg-coralRegular';
            config.textClass = 'text-white';
            break;
        }
        case AlertSeverity.Major: {
            config.icon = <ChevronUpIcon />;
            config.bgClass = 'bg-coralLight';
            config.textClass = 'text-eggplantRegular';
            break;
        }
        case AlertSeverity.Minor: {
            config.icon = <ChevronDownIcon />;
            config.bgClass = 'bg-eggplantExtraLight';
            config.textClass = 'text-eggplantRegular';
            break;
        }
        case AlertSeverity.Warning: {
            config.icon = <ChevronsDownIcon />;
            config.bgClass = 'bg-eggplantRegular';
            config.textClass = 'text-white';
            break;
        }
        case AlertSeverity.Indeterminate: {
            config.icon = <MinusIcon />;
            config.bgClass = 'bg-grayRegular';
            config.textClass = 'text-eggplantRegular';
            break;
        }
    }

    return config;
}

export function incidentStatusToStatusLabelConfig(status: ACPowerEventStatus): statusLabelConfig {
    switch (status) {
        case 'OverVoltage':
            return {
                icon: <ArrowUpCircleIcon />,
                bgClass: 'bg-mustardRegular',
                textClass: 'text-white',
            };
        case 'UnderVoltage':
            return {
                icon: <ArrowDownCircleIcon />,
                bgClass: 'bg-eggplantLight',
                textClass: 'text-white',
            };
        case 'Outage':
            return {
                icon: <CircleCrossIcon />,
                bgClass: 'bg-coralRegular',
                textClass: 'text-white',
            };
        case 'Normal':
            return {
                icon: <CircleCheckIcon />,
                bgClass: 'bg-pineRegular',
                textClass: 'text-white',
            };
        default:
            return {
                icon: <CircleHelpIcon />,
                bgClass: 'bg-grayRegular',
                textClass: 'text-eggplantExtraLight',
            };
    }
}

export function generatorRunStatusToStatusLabelConfig(status: GeneratorRunReportState): statusLabelConfig {
    const config: statusLabelConfig = {
        icon: <CircleMinusIcon />,
        bgClass: 'bg-eggplantRegular',
        textClass: 'text-white',
    };

    switch (status) {
        case 'Completed':
            config.icon = <CircleCheckIcon />;
            config.bgClass = 'bg-pineLight';
            config.textClass = 'text-eggplantRegular';
            break;
        case 'Error':
            config.icon = <CircleCrossIcon />;
            config.bgClass = 'bg-coralRegular';
            break;
        case 'Finishing':
        case 'Running':
        case 'Analysing':
        default:
            break;
    }
    return config;
}
