/**
 * @generated SignedSource<<edd338dec1d3be5b5209fac3e5f5f225>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceErrorType = "DuplicateName" | "EditNotAllowed" | "InvalidAttributeName" | "InvalidBatteryId" | "InvalidBatteryType" | "InvalidBatteryVoltage" | "InvalidCellCount" | "InvalidDevice" | "InvalidDeviceType" | "InvalidDualPlaneDevice" | "InvalidFirmware" | "InvalidManufactureDate" | "InvalidQuickTestCheckPercent" | "InvalidQuickTestThreshold" | "InvalidReserveTime" | "InvalidSite" | "MissingName" | "Noop" | "NotPossibleRightNow" | "Skipped" | "%future added value";
export type ProtocolErrorField = "BasicPassword" | "BasicUsername" | "GatewayNotFound" | "InvalidGateway" | "MissingProtocol" | "SnmpPort" | "SnmpV1ReadCommunity" | "SnmpV1WriteCommunity" | "SnmpV3AuthPassphrase" | "SnmpV3EngineId" | "SnmpV3PrivPassphrase" | "SnmpV3User" | "UnknownProtocol" | "%future added value";
export type DeviceBatteryUpdateIn = {
  maximumAllowedVoltage?: number | null;
  minimumAllowedVoltage?: number | null;
  quickTestCheckPercent?: number | null;
  quickTestFailThreshold?: number | null;
  reserveTime?: number | null;
  strings?: DeviceBatteryStringUpdates | null;
};
export type DeviceBatteryStringUpdates = {
  add?: ReadonlyArray<DeviceBatteryStringIn> | null;
  remove?: ReadonlyArray<string> | null;
  update?: ReadonlyArray<DeviceBatteryStringUpdateIn> | null;
};
export type DeviceBatteryStringIn = {
  cellsPerString: number;
  installDate?: string | null;
  manufactureDate?: string | null;
  type: string;
};
export type DeviceBatteryStringUpdateIn = {
  cellsPerString?: number | null;
  id: string;
  installDate?: string | null;
  manufactureDate?: string | null;
  type?: string | null;
};
export type mutations_AddBatteryToDeviceMutation$variables = {
  battery?: DeviceBatteryUpdateIn | null;
  deviceId: string;
};
export type mutations_AddBatteryToDeviceMutation$data = {
  readonly editDevice: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<{
      readonly field?: ProtocolErrorField;
      readonly protocolId?: string;
      readonly string?: number | null;
      readonly type?: DeviceErrorType;
    }>;
  } | null;
};
export type mutations_AddBatteryToDeviceMutation = {
  response: mutations_AddBatteryToDeviceMutation$data;
  variables: mutations_AddBatteryToDeviceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "battery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "battery",
        "variableName": "battery"
      }
    ],
    "kind": "ObjectValue",
    "name": "device"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deviceId"
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "string",
      "storageKey": null
    }
  ],
  "type": "DeviceGeneralProblem",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protocolId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    }
  ],
  "type": "DeviceProtocolProblem",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mutations_AddBatteryToDeviceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editDevice",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "problems",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeviceProblemResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "mutations_AddBatteryToDeviceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editDevice",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "problems",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeviceProblemResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89b5b82610f961047f3adf976b3f4d3b",
    "id": null,
    "metadata": {},
    "name": "mutations_AddBatteryToDeviceMutation",
    "operationKind": "mutation",
    "text": "mutation mutations_AddBatteryToDeviceMutation(\n  $deviceId: ID!\n  $battery: DeviceBatteryUpdateIn\n) {\n  editDevice(id: $deviceId, device: {battery: $battery}) {\n    __typename\n    ... on Device {\n      id\n    }\n    ... on DeviceProblemResponse {\n      problems {\n        __typename\n        ... on DeviceGeneralProblem {\n          type\n          string\n        }\n        ... on DeviceProtocolProblem {\n          protocolId\n          field\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c18962b75da16d6d93c57a54ee5420d4";

export default node;
