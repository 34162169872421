/**
 * @generated SignedSource<<fcb507bb8b9247304d3dfbd5b0650d43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ModuleRow_data$data = {
  readonly id: string;
  readonly label: string;
  readonly metrics: {
    readonly latestOutputCurrent: number | null;
    readonly latestOutputPower: number | null;
    readonly latestTemperature: number | null;
    readonly status: DeviceHealth;
  };
  readonly serialNumber: string | null;
  readonly specs: {
    readonly manufacturer: string;
    readonly model: string;
    readonly output: {
      readonly maximumPower: number;
    };
  } | null;
  readonly " $fragmentType": "ModuleRow_data";
};
export type ModuleRow_data$key = {
  readonly " $data"?: ModuleRow_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModuleRow_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Watt"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitTemperature"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModuleRow_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RectifierModuleSpecs",
      "kind": "LinkedField",
      "name": "specs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manufacturer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "model",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RectifierModuleOutputSpecs",
          "kind": "LinkedField",
          "name": "output",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v0/*: any*/),
              "kind": "ScalarField",
              "name": "maximumPower",
              "storageKey": "maximumPower(unit:\"Watt\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RectifierModuleMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "unit",
              "variableName": "unitTemperature"
            }
          ],
          "kind": "ScalarField",
          "name": "latestTemperature",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestOutputCurrent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "latestOutputPower",
          "storageKey": "latestOutputPower(unit:\"Watt\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RectifierModule",
  "abstractKey": null
};
})();

(node as any).hash = "d4a8395fa6614922f0c480834bffc0bf";

export default node;
