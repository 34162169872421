import React, { FC } from 'react';

import { InfoTip, LoadableContentArea, PageHeading } from '@accesstel/pcm-ui';

import style from './style.module.css';

export interface ActiveCountProps {
    hasError?: boolean;
    retry?: () => void;
    count?: number;
    unitSingular: string;
    unitPlural: string;
    resultCount?: number;
    clarificationText?: string;
}

export const ActiveCount: FC<ActiveCountProps> = ({
    hasError,
    retry,
    count,
    unitSingular,
    unitPlural,
    resultCount,
    clarificationText,
}) => {
    return (
        <LoadableContentArea
            className={style.active_count}
            variant='framed'
            data={count}
            error={hasError}
            onRetry={retry}
            render={result => {
                const suffix = result === 1 ? unitSingular : unitPlural;

                let headingText: string;
                if (resultCount !== result) {
                    headingText = `${resultCount} out of ${result} ${suffix}`;
                } else {
                    headingText = `${result} ${suffix}`;
                }

                return (
                    <div className='flex flex-row'>
                        <PageHeading subheading secondary value={headingText} />
                        {clarificationText && <InfoTip message={clarificationText} />}
                    </div>
                );
            }}
        />
    );
};
