/**
 * @generated SignedSource<<cc9f1721879611c25b8eaeb4b085acf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceRemoveResponse = "PartialSuccess" | "Success" | "UnknownDevice" | "%future added value";
export type deleteDevicesMutation$variables = {
  ids: ReadonlyArray<string>;
};
export type deleteDevicesMutation$data = {
  readonly removeDevices: DeviceRemoveResponse;
};
export type deleteDevicesMutation = {
  response: deleteDevicesMutation$data;
  variables: deleteDevicesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "kind": "ScalarField",
    "name": "removeDevices",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteDevicesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteDevicesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "415acac7e737dc34a1c62537f829b637",
    "id": null,
    "metadata": {},
    "name": "deleteDevicesMutation",
    "operationKind": "mutation",
    "text": "mutation deleteDevicesMutation(\n  $ids: [ID!]!\n) {\n  removeDevices(ids: $ids)\n}\n"
  }
};
})();

(node as any).hash = "956f8fe00dd0d73c5ebedae1d0636154";

export default node;
