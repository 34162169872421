import React from 'react';

import { ChevronDownIcon, ChevronUpIcon, ChevronsDownIcon, ChevronsUpIcon, MinusIcon } from '@accesstel/pcm-ui';

import { createColumnHelper } from '@tanstack/react-table';
import { IconWithStatus } from 'components';
import { AlertSeverity, AlertTableColumn } from 'filters/alert';
import { ColumnWithId } from 'layouts';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { renderAlertDateCell } from 'lib/table-columns';
import { formatAlertDomain } from 'lib/textFormatters';

import { AlertListTableQuery$data } from './__generated__/AlertListTableQuery.graphql';

type Alert = AlertListTableQuery$data['alerts']['data'][number];

const columnHelper = createColumnHelper<Alert>();

export const SeverityColumn = columnHelper.accessor('severity', {
    id: AlertTableColumn.Severity,
    header: 'Severity',
    exportHeader: 'Severity',
    cell: ({ row }) => severityToComponent(row.original.severity, row.original.isActive),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const DomainColumn = columnHelper.accessor('domain', {
    id: AlertTableColumn.Domain,
    header: 'Domain',
    exportHeader: 'Domain',
    cell: ({ row }) => formatAlertDomain(row.original.domain),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const MessageColumn = columnHelper.accessor('message', {
    id: AlertTableColumn.Message,
    header: 'Message',
    exportHeader: 'Message',
    meta: {
        filterable: false,
        sortable: true,
        maxWidth: '17.5rem',
    },
});

export const DeviceNameColumn = columnHelper.accessor('device.name', {
    id: AlertTableColumn.DeviceName,
    header: 'Device Name',
    exportHeader: 'Device Name',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '12.5rem',
    },
});

export const SiteNameColumn = columnHelper.accessor('site.name', {
    id: AlertTableColumn.SiteName,
    header: 'Site Name',
    exportHeader: 'Site Name',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '12.5rem',
    },
});

export const RaiseDateColumn = columnHelper.accessor('raiseDate', {
    id: AlertTableColumn.RaiseDate,
    header: 'Raised Date',
    exportHeader: 'Raised Date',
    cell: ({ row }) => getDateTimeFormat(row.original.raiseDate),
    exportValue: ({ value }) => new Date(value as string),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const CategoryColumn = columnHelper.accessor('category', {
    id: AlertTableColumn.Category,
    header: 'Category',
    exportHeader: 'Category',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '16rem',
    },
});

export const OriginatorColumn = columnHelper.accessor('originator', {
    id: AlertTableColumn.Originator,
    header: 'Originator',
    exportHeader: 'Originator',
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const ClearDateColumn = columnHelper.accessor('clearDate', {
    id: AlertTableColumn.ClearDate,
    header: 'Cleared Date',
    exportHeader: 'Cleared Date',
    cell: ({ cell }) =>
        renderAlertDateCell(() => {
            if (!cell.getValue()) {
                return;
            }

            return getDateTimeFormat(cell.getValue() as string);
        }),
    exportValue: ({ value }) => new Date(value as string),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const IsActiveColumn = columnHelper.accessor('isActive', {
    id: AlertTableColumn.IsActive,
    header: 'Is Active',
    exportHeader: 'Is Active',
    cell: ({ row }) => (row.original.isActive ? 'Yes' : 'No'),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const BaseTableColumns = [
    RaiseDateColumn,
    SeverityColumn,
    DomainColumn,
    DeviceNameColumn,
    MessageColumn,
] as ColumnWithId<AlertTableColumn, Alert>[];

export const AllTableColumns = [
    ...BaseTableColumns,
    SiteNameColumn,
    CategoryColumn,
    OriginatorColumn,
    ClearDateColumn,
    IsActiveColumn,
] as ColumnWithId<AlertTableColumn, Alert>[];

const severityToComponent = (severity: string, isActive: boolean) => {
    const containerClasses = isActive ? 'text-coralRegular' : 'text-eggplantRegular';

    switch (severity as AlertSeverity) {
        case AlertSeverity.Critical:
            return <IconWithStatus icon={<ChevronsUpIcon />} label={severity} containerClasses={containerClasses} />;
        case AlertSeverity.Major:
            return <IconWithStatus icon={<ChevronUpIcon />} label={severity} containerClasses={containerClasses} />;
        case AlertSeverity.Minor:
            return <IconWithStatus icon={<ChevronDownIcon />} label={severity} containerClasses={containerClasses} />;
        case AlertSeverity.Warning:
            return <IconWithStatus icon={<ChevronsDownIcon />} label={severity} containerClasses={containerClasses} />;
        case AlertSeverity.Indeterminate:
            return <IconWithStatus icon={<MinusIcon />} label={severity} containerClasses={containerClasses} />;
    }
};
