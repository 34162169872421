import React, { FC, useCallback, useState } from 'react';
import { commitMutation, fetchQuery, useRelayEnvironment } from 'react-relay';

import { Button, PageHeading, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { logError } from 'lib/log';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { IEnvironment } from 'relay-runtime';

import { RepeatTaskModalQuery } from './__generated__/RepeatTaskModalQuery.graphql';
import { RepeatTaskModal_RepeatTaskMutation } from './__generated__/RepeatTaskModal_RepeatTaskMutation.graphql';
import { RepeatTaskModal_TaskDetailsQuery } from './__generated__/RepeatTaskModal_TaskDetailsQuery.graphql';
import style from './style.module.css';

interface RepeatTaskModalProps {
    taskId: string;
    devices: string[];
    onSetVisible: (visible: boolean) => void;
}

export const RepeatTaskModal: FC<RepeatTaskModalProps> = ({ taskId, devices, onSetVisible }) => {
    const environment = useRelayEnvironment();
    const navigate = useExtendedNavigate();
    const [hasError, setHasError] = useState<boolean>();

    const onClose = useCallback(() => {
        onSetVisible(false);
    }, [onSetVisible]);

    const repeatTaskNoChanges = useCallback(() => {
        doRepeatTask(taskId, environment)
            .then(id => {
                if (!id) {
                    // eslint-disable-next-line no-console
                    console.error('Failed to repeat task, id is null');
                    setHasError(true);
                    return;
                }
                navigate(`${Paths.TestsDetailsView}/${id}`);
                onClose(); // only close if everything is ok
            })
            .catch(error => {
                logError('Failed to repeat task', error);
                setHasError(true);
            });
    }, [taskId, environment, navigate, onClose]);

    // TODO: Check every x seconds so that the button enable/disable intuitively
    const { data: props } = useQuery<RepeatTaskModalQuery>(
        graphql`
            query RepeatTaskModalQuery($devices: [ID!]) {
                devices(filters: { ids: $devices }) {
                    data {
                        isBusy
                    }
                }
            }
        `,
        {
            devices,
        }
    );

    const canRepeatNow = props ? props.devices.data.every(dev => !dev.isBusy) : false;

    return (
        <div className={style.cancel_abort_modal}>
            {!hasError && (
                <>
                    <PageHeading value='Repeat task?' />
                    <div className={`text-eggplantRegular font-normal text-base pb-12`}>
                        <p>The task can be repeated with the same or modified settings.</p>
                        <p>
                            If you choose to repeat the task now, it will schedule the task to start immediately with
                            the same settings.
                        </p>
                    </div>

                    <div className='space-x-2'>
                        <Button disabled={!canRepeatNow} onClick={repeatTaskNoChanges} buttonText='Repeat now' />
                        <Button
                            onClick={onClose}
                            buttonText='Change settings'
                            variant='gray'
                            to={`${Paths.TestsScheduleTest}?copy-task=${encodeURIComponent(taskId)}`}
                        />
                        <Button onClick={onClose} buttonText='Close' variant='gray' buttonClasses='w-24' />
                    </div>
                </>
            )}
            {hasError && (
                <>
                    <div className={`${style.confirmation_heading} text-coralRegular font-bold`}>
                        An error occurred.
                    </div>
                    <div className='text-eggplantRegular font-normal text-base pb-12'>
                        We were unable to schedule the task. Please try again later.
                    </div>
                    <Button onClick={onClose} buttonText='Close' variant='gray' buttonClasses='w-24' />
                </>
            )}
        </div>
    );
};

async function doRepeatTask(taskId: string, environment: IEnvironment): Promise<string | null> {
    const props = await fetchQuery<RepeatTaskModal_TaskDetailsQuery>(
        environment,
        graphql`
            query RepeatTaskModal_TaskDetailsQuery($id: ID!) {
                task(id: $id) {
                    ... on Task {
                        name
                        devices {
                            data {
                                id
                            }
                        }
                    }
                    ... on BatteryTest {
                        usingSmartStart
                        type
                        settings {
                            ... on BatteryTestTypeCapacity {
                                targets {
                                    endOfDischargeVoltage
                                    batteryType {
                                        id
                                    }
                                }
                            }
                            ... on BatteryTestTypeQuick {
                                reserveTime
                                threshold
                            }
                            ... on BatteryTestTypeCustom {
                                endOfDischargeVoltage
                                maxDuration
                                maxDischarged
                            }
                        }
                    }
                }
            }
        `,
        {
            id: taskId,
        }
    ).toPromise();

    const task = props?.task;

    if (!task || !task.settings) {
        return null;
    }

    let name: string;
    if (task.name) {
        name = task.name;
    } else {
        name = 'Repeated task';
    }

    const { endOfDischargeVoltage, maxDuration, maxDischarged, reserveTime, threshold, targets } = task.settings;
    const { type } = task;

    if (!type) {
        return null;
    }

    if (type === 'Quick' && reserveTime === null) {
        return null;
    }

    if (type === 'Capacity' && !targets) {
        return null;
    }

    return new Promise((resolve, reject) => {
        commitMutation<RepeatTaskModal_RepeatTaskMutation>(environment, {
            mutation: graphql`
                mutation RepeatTaskModal_RepeatTaskMutation($task: BatteryTestSettingsInput!) {
                    addBatteryTest(settings: $task) {
                        ... on BatteryTest {
                            id
                        }
                        ... on BatteryTestScheduleProblemResponse {
                            problems
                        }
                    }
                }
            `,
            variables: {
                task: {
                    name,
                    devices: task.devices.data.map(device => device.id),
                    smartStart: task.usingSmartStart,
                    type: type,
                    // only include test settings based on type
                    quickSettings:
                        type === 'Quick'
                            ? {
                                  reserveTime: reserveTime as number,
                                  threshold: threshold,
                              }
                            : undefined,
                    customSettings:
                        type === 'Custom'
                            ? {
                                  minVoltage: endOfDischargeVoltage,
                                  maxDischarged: maxDischarged,
                                  maxDuration: maxDuration,
                              }
                            : undefined,
                    capacitySettings:
                        type === 'Capacity'
                            ? {
                                  targets: targets
                                      ? targets.map(target => {
                                            return {
                                                eodv: target.endOfDischargeVoltage,
                                                batteryType: target.batteryType ? target.batteryType.id : '',
                                            };
                                        })
                                      : [],
                              }
                            : undefined,
                },
            },
            onCompleted: (response, errors) => {
                if (errors) {
                    reject(errors);
                    return;
                }
                if (response.addBatteryTest && response.addBatteryTest.id) {
                    resolve(response.addBatteryTest.id);
                } else {
                    resolve(null);
                }
            },
            onError: reject,
        });
    });
}
