import { captureMessage } from '@sentry/react';
import { BatteryTechnology } from 'filters/battery-type/types';
import { BatteryTechnologyType } from 'views/manage/__generated__/mutations_CreateBatteryTypeMutation.graphql';
import { AlertDomain } from 'views/reports/alerts/alert-list/__generated__/AlertListTableQuery.graphql';
import {
    BatteryTestReason,
    BatteryTestState,
} from 'views/reports/batteries/__generated__/queries_batteryTestCardsQuery.graphql';
import { DeviceCapability } from 'views/reports/batteries/site-view/device-section/components/__generated__/Details_device.graphql';
import { EventType } from 'views/settings/audit/__generated__/AuditTableQuery.graphql';
import { GeneratorRunMode } from 'views/sites/view/sub-views/generator/__generated__/GeneratorContentQuery.graphql';
import {
    GeneratorOperationMode,
    GeneratorState,
} from 'views/sites/view/sub-views/site-overview/__generated__/SiteOverviewContentQuery.graphql';
import { StatusCellValue } from 'views/tasks/battery-health/task-view/subviews/StatusList';
import { BatteryTaskState } from 'views/tasks/battery-health/task-view/subviews/__generated__/StatusList_task.graphql';
import { BatteryTestFailReason } from 'views/tasks/battery-health/test-result-view/components/__generated__/ResultView_test.graphql';
import {
    GeneratorRunReportCause,
    GeneratorRunReportState,
} from 'views/tasks/generator-run/overview/sections/__generated__/CompletedGeneratorRuns_tasks.graphql';

import { logError } from './log';
import { numberToLocaleString } from './numberFormatters';

export const NonBreakingSpace = '\u00A0';

export function prettyFailMessage(reason: BatteryTestFailReason): string {
    switch (reason) {
        case 'BatteryHealth':
            return 'Battery health low';
        case 'BatteryHealthUnknown':
            return 'Unable to determine capacity';
        case 'Busy':
            return 'Device was busy with another task.';
        case 'DidNotStart':
            return 'Did not start test when requested';
        case 'SmartStartTimeout':
            return 'Smart start condition was not met';
        case 'DeviceRemoved':
            return 'Device has been removed from the system';
        case 'SystemAbort':
            return 'The system has aborted the battery test due to internal error.';
        case 'TestLockout':
            return 'Battery test lockout active';
        case 'InsufficientReserveTime':
            return 'Insufficient reserve time';
        case 'InternalError':
            return 'Internal error';
        case 'NoDischarge':
            return 'No discharge detected';
        case 'NotEnoughData':
            return 'Not enough data';
        case 'ThresholdReached':
            return 'Integrity test threshold reached';
        case 'TooShort':
            return 'Not long enough';
        case '%future added value':
            return 'Unknown';
        default:
            return reason;
    }
}

export function formatBatteryType(type: BatteryTechnologyType | null): string {
    switch (type) {
        case BatteryTechnology.LeadAcidAGM:
            return 'Lead Acid - AGM';
        case BatteryTechnology.LeadAcidFlooded:
            return 'Lead Acid - Flooded';
        case BatteryTechnology.LeadAcidGel:
            return 'Lead Acid - Gel';
        case BatteryTechnology.LeadAcidOther:
            return 'Lead Acid';
        case BatteryTechnology.LeadAcidVRLA:
            return 'Lead Acid - VRLA';
        case BatteryTechnology.LeadAcidTPPL:
            return 'Lead Acid - TPPL';
        case BatteryTechnology.LithiumIon:
            return 'Lithium Ion';
        case BatteryTechnology.LithiumIronPhosphate:
            return 'Lithium Iron Phosphate';
        case BatteryTechnology.LithiumPolymer:
            return 'Lithium Polymer';
        case BatteryTechnology.NickelCadmium:
            return 'Nickel Cadmium';
        case BatteryTechnology.NickelMetalHydride:
            return 'Nickel Metal Hydride';
        case BatteryTechnology.Other:
            return 'Other';
        case BatteryTechnology.Supercapacitor:
            return 'Supercapacitor';
        default:
            return 'Unknown';
    }
}

/**
 *
 * @param status
 * @param isExternalDischarge if true, returned value will mention 'discharge' rather than 'test'. Set to false by default
 * @returns human-friendly text of the test result status
 */
export function getTestStatus(status: BatteryTestState, isExternalDischarge = false): string {
    let testStatus = '';
    switch (status) {
        case 'Aborted':
            testStatus = 'Test aborted';
            break;
        case 'InProgress':
            testStatus = 'Test in progress';
            break;
        case 'Failed':
        case 'Passed':
        case 'Inconclusive':
            testStatus = 'Test complete';
            break;
        case 'Scheduled':
            testStatus = 'Test scheduled';
            break;
        case 'SmartStart':
            testStatus = 'Test waiting to start';
            break;
        case 'Waiting':
            testStatus = 'Test starting';
            break;
        case 'Finalizing':
            testStatus = 'Test finishing';
            break;
        case 'Analyzing':
            testStatus = 'Test analyzing';
            break;
        default:
            testStatus = 'Unknown';
    }

    if (isExternalDischarge) {
        const dischargeStatus = testStatus.replace('Test', 'Discharge');
        return dischargeStatus;
    }

    return testStatus;
}

export function getNiceStatusName(status: BatteryTestState | BatteryTaskState | 'Cancelled'): string {
    switch (status) {
        case 'Aborted':
            return 'Aborted';
        case 'Failed':
            return 'Failed';
        case 'InProgress':
            return 'In Progress';
        case 'Passed':
            return 'Passed';
        case 'Inconclusive':
            return 'Inconclusive';
        case 'Scheduled':
            return 'Scheduled';
        case 'SmartStart':
            return 'Smart Start';
        case 'Waiting':
            return 'Starting';
        case 'Finalizing':
            return 'Finishing';
        case 'Analyzing':
            return 'Analyzing';
        case 'PartialFail':
            return 'Partial Fail';
        case 'PartialPass':
            return 'Partial Pass';
        case 'Cancelled':
            return 'Cancelled';
        default:
            return 'Unknown';
    }
}

export function getNiceFailReason(test: NonNullable<StatusCellValue>): string {
    if (!test.failReason) {
        return 'Unknown';
    }

    switch (test.failReason) {
        case 'BatteryHealth':
            if (test.finalSoH !== null) {
                return `${numberToLocaleString(test.finalSoH, 0)}% Battery capacity`;
            } else {
                return 'Battery Capacity Low';
            }
        default:
            return prettyFailMessage(test.failReason);
    }
}

export function convertStatusForCSSClass(status: string): string {
    let convertedStatus: string;

    switch (status) {
        case 'Aborted':
        case 'Failed':
        case 'Passed':
        case 'Scheduled':
        case 'Waiting':
        case 'Inconclusive':
        case 'Finalizing':
        case 'Analyzing':
            convertedStatus = status.toLowerCase();
            break;
        case 'InProgress':
            convertedStatus = 'in-progress';
            break;
        case 'SmartStart':
            convertedStatus = 'smart-start';
            break;
        default:
            convertedStatus = 'failed';
            break;
    }

    return convertedStatus;
}

export function formatDataForTestProgressBar(status: string | null): string {
    switch (status) {
        case 'Aborted':
            return 'TEST ABORTED';
        case 'InProgress':
            return 'IN PROGRESS';
        case 'Failed':
            return 'FAILED';
        case 'Passed':
            return 'PASSED';
        case 'Scheduled':
            return 'SCHEDULED TO START';
        case 'SmartStart':
            return 'WAITING ON SMART START';
        case 'Waiting':
            return 'STARTING';
        case 'Inconclusive':
            return 'INCONCLUSIVE';
        case 'Finalizing':
            return 'FINISHING';
        case 'Analyzing':
            return 'ANALYZING';
        default:
            return 'UNKNOWN';
    }
}

export function getBatteryName(manufacturer: string, model: string, technology?: BatteryTechnologyType): string {
    if (!technology) {
        return `${manufacturer} ${model}`;
    }
    return `${manufacturer} ${model} (${formatBatteryType(technology)})`;
}

export function getNiceCapability(capability: DeviceCapability): string {
    switch (capability) {
        case 'BlocLevelData':
            return 'Extended Battery Data';
        default:
            return 'Unknown';
    }
}

export function getNiceDualPlaneConfigurationType(
    type: 'Single' | 'NPlusOne' | 'TwoN' | '%future added value'
): string {
    switch (type) {
        case 'Single':
            return 'Single';
        case 'NPlusOne':
            return 'Dual Plane (N+1)';
        case 'TwoN':
            return 'Dual Plane (N+2)';
        default:
            return 'Unknown';
    }
}

export function getNiceSNMPVersion(version: 'V1' | 'V2c' | 'V3' | '%future added value'): string {
    switch (version) {
        case 'V1':
            return 'Version 1';
        case 'V2c':
            return 'Version 2c';
        case 'V3':
            return 'Version 3';
        default:
            return 'Unknown';
    }
}

export const StateLabelMap: Record<string, string> = {
    'smart-start': 'Smart Start',
    aborted: 'Aborted',
    failed: 'Failed',
    passed: 'Passed',
    'in-progress': 'In progress',
    waiting: 'Waiting',
    scheduled: 'Scheduled',
    completed: 'Completed',
    inconclusive: 'Inconclusive',
    finalizing: 'Finishing',
    analyzing: 'Analyzing',
};

export const StateLabelMap2: Record<string, string> = {
    SmartStart: 'Smart Start',
    Aborted: 'Aborted',
    Failed: 'Failed',
    Passed: 'Passed',
    InProgress: 'In progress',
    Waiting: 'Waiting',
    Scheduled: 'Scheduled',
    Completed: 'Completed',
    Inconclusive: 'Inconclusive',
    Finalizing: 'Finishing',
    Analyzing: 'Analyzing',
};

export const StateTitle: Record<string, string> = {
    Cancelled: 'Cancelled Test',
    Completed: 'Completed Test',
    InProgress: 'Test in Progress',
    Scheduled: 'Scheduled Test',
    Passed: 'Passed Test',
    Failed: 'Failed Test',
    PartialFail: 'Partially Failed Test',
    PartialPass: 'Partially Passed Test',
    Inconclusive: 'Inconclusive Test',
    Aborted: 'Aborted Test',
};

export const capitalize = (text: string): string => {
    const words = text.split(' ');
    const capitalizedWords = words.map(word => {
        if (word.length === 0) {
            return '';
        }
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    });

    return capitalizedWords.join(' ');
};

export const BatteryTestCauseMap: Record<BatteryTestReason, string> = {
    Accata: 'accata',
    AcFail: 'Grid Power Loss',
    ExternalTest: 'External BCM Test',
    LowReferenceVoltage: 'Float Voltage Lowered',
    CompanionDischarge: 'Other Plane Discharging',
    Unknown: 'Unknown',
    '%future added value': 'Unknown',
};

export function formatBatteryTestName(cause: BatteryTestReason): string {
    switch (cause) {
        case 'AcFail':
            return 'Power Loss Discharge';
        case 'Accata':
            // This should not happen
            return 'Planned Discharge';
        case 'ExternalTest':
            return 'External Test';
        case 'CompanionDischarge':
            return "Monitoring Other Plane's Discharge";
        case 'LowReferenceVoltage':
        default:
            return 'Unplanned Discharge';
    }
}

export function formatAsCauseSentence(cause: BatteryTestReason): string {
    switch (cause) {
        case 'AcFail':
            return 'AC failure';
        case 'Accata':
            // This should not happen
            return 'a planned discharge';
        case 'ExternalTest':
            return 'an external BCM test';
        case 'CompanionDischarge':
            return 'the other plane discharging';
        case 'LowReferenceVoltage':
        default:
            return 'an unplanned discharge';
    }
}

export const FailReasonMap: Record<Exclude<BatteryTestFailReason, '%future added value'>, string> = {
    BatteryHealth: 'Battery Health',
    BatteryHealthUnknown: 'Battery Health Unknown',
    Busy: 'Busy',
    DeviceRemoved: 'Device Removed',
    DidNotStart: 'Did Not Start',
    SmartStartTimeout: 'Smart Start Timeout',
    SystemAbort: 'System Abort',
    TestLockout: 'Test Lockout',
    ThresholdReached: 'Threshold Reached',
    InsufficientReserveTime: 'Insufficient reserve time',
    InternalError: 'Internal error',
    NoDischarge: 'No discharge detected',
    NotEnoughData: 'Not enough data',
    TooShort: 'Not long enough',
};

export function formatAlertDomain(domain: AlertDomain): string {
    switch (domain) {
        case 'AcPower':
            return 'AC Power';
        case 'Battery':
            return 'Batteries';
        case 'Rectifier':
            return 'Rectifiers';
        default:
            return domain;
    }
}

export function formatGeneratorState(state: GeneratorState | null) {
    switch (state) {
        case 'CoolingDown':
            return 'Cooling down';
        case 'Idle':
            return 'Idle';
        case 'Running':
            return 'Running';
        case 'RunningOnLoad':
            return 'Running on load';
        case 'WarmingUp':
            return 'Warming up';
        case '%future added value':
        default:
            return 'Offline';
    }
}

export function formatGeneratorOperationMode(mode: GeneratorOperationMode | null) {
    switch (mode) {
        case 'Auto':
        case 'Manual':
        case 'Off':
        case 'Test':
        case 'User':
            return mode;
        case '%future added value':
        default:
            return 'Offline';
    }
}

export function formatGeneratorRunModeToString(runMode: GeneratorRunMode | null): string {
    switch (runMode) {
        case 'EmergencyRun':
            return 'Emergency Run';
        case 'IndeterminateRun':
            return 'Indeterminate Run';
        case 'MaintenanceRun':
            return 'Maintenance Run';
        case 'MalfunctionRun':
            return 'Malfunction Run';
        case 'NotRunning':
            return 'Not Running';
        case 'TestRun':
            return 'Test Run';
        case '%future added value':
        default:
            return 'Offline';
    }
}

export function formatGeneratorRunReportState(state: GeneratorRunReportState | null): string {
    switch (state) {
        case 'Completed':
            return state;
        case 'Running':
            return 'In Progress';
        case 'Analysing':
            return 'Analysing';
        case 'Finishing':
            return 'Finalising';
        case 'Error':
            return 'Errored';
        case '%future added value':
        default:
            return 'Unknown';
    }
}

export function formatGeneratorRunReportCause(cause: GeneratorRunReportCause | null): string {
    switch (cause) {
        case 'LocalOtherStart':
            return 'Local Start';
        case 'PowerFailure':
            return 'Power Failure';
        case 'RemoteStart':
            return 'Remote Start';
        case 'Unknown':
        case '%future added value':
        default:
            return 'Unknown';
    }
}

export function formatSiteName(siteName: string, siteState?: string | null, withState = true): string {
    if (withState && siteState) {
        return `${siteName}, ${siteState}`;
    }

    return siteName;
}

export function formatEventType(eventType: EventType): string {
    switch (eventType) {
        case 'AuthIdpAdded':
            return 'Identity provider added';
        case 'AuthIdpRemoved':
            return 'Identity provider removed';
        case 'AuthIdpEdited':
            return 'Identity provider edited';
        case 'Authentication':
            return 'Authentication';
        case 'BatteryTypeAdded':
            return 'Battery type added';
        case 'BatteryTypeEdited':
            return 'Battery type edited';
        case 'BatteryTypeRemoved':
            return 'Battery type removed';
        case 'DeviceAdded':
            return 'Device added';
        case 'DeviceEdited':
            return 'Device edited';
        case 'DeviceRemoved':
            return 'Device removed';
        case 'FirmwareApply':
            return 'Firmware apply';
        case 'FirmwareApplyFail':
            return 'Firmware apply fail';
        case 'FirmwareApplySuccess':
            return 'Firmware apply success';
        case 'FirmwareTargetChange':
            return 'Firmware target change';
        case 'GeneratorRunReportNameEdited':
            return 'Generator run report name edited';
        case 'GeneratorRunReportNotesEdited':
            return 'Generator run report notes edited';
        case 'GlobalSettingEdited':
            return 'Global setting edited';
        case 'SecClientCertificateAdded':
            return 'Client certificate added';
        case 'SecClientCertificateRemoved':
            return 'Client certificate removed';
        case 'SecServerCertificateAdded':
            return 'Server certificate added';
        case 'SecServerCertificateRemoved':
            return 'Server certificate removed';
        case 'SiteAdded':
            return 'Site added';
        case 'SiteEdited':
            return 'Site edited';
        case 'SiteRemoved':
            return 'Site removed';
        case 'TaskAbort':
            return 'Task aborted';
        case 'TaskCancel':
            return 'Task cancelled';
        case 'TaskCreate':
            return 'Task created';
        case 'TaskComplete':
            return 'Task completed';
        case 'TaskNameChange':
            return 'Task name edited';
        case 'TestAbort':
            return 'Test aborted';
        case 'TestCancel':
            return 'Test cancelled';
        case 'TestFail':
            return 'Test failed';
        case 'TestInconclusive':
            return 'Test inconclusive';
        case 'TestInitiate':
            return 'Test initiated';
        case 'TestNameChange':
            return 'Test name edited';
        case 'TestNoteEdit':
            return 'Test notes edited';
        case 'TestPass':
            return 'Test passed';
        case 'UserAdded':
            return 'User added';
        case 'UserDeleted':
            return 'User deleted';
        case 'UserEdited':
            return 'User edited';
        case 'UserDisabled':
            return 'User disabled';
        case 'UserEnabled':
            return 'User enabled';
        case '%future added value':
        default:
            logError('Unhandled event type', eventType);
            captureMessage(`Unhandled event type: ${eventType}`, scope => {
                scope.setTag('EventType', eventType);
                scope.setTag('Function', 'formatEventType');
                return scope;
            });
            return 'Unknown';
    }
}
