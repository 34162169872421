import React, { FC, useCallback, useState } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';
import { generatePath } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { ArrowDownIcon, Modal, RepeatIcon, useExtendedNavigate, useToast } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { TestSelectModalContent } from 'components';
import { SelectionWithTestIds } from 'components/SelectTestPane';
import { CompareLayout } from 'layouts/CompareLayout';
import { getCurrentUserUnitsPref, useCurrentUserUnitsPref } from 'lib/auth';
import { useConfirmationModal } from 'lib/confirmation';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { getGlobalEnvironment } from 'lib/environment';
import { Paths } from 'lib/routes';
import { MetricsLineType } from 'lib/units';
import { DateTime, Duration } from 'luxon';

import { CompareTestExportError, exportTestResults } from '../export-results';
import { MaxTestDisplay } from '../settings';
import {
    CompareBatteryTestResultsContentQuery,
    CompareBatteryTestResultsContentQuery$data,
} from './__generated__/CompareBatteryTestResultsContentQuery.graphql';
import { ExportPane } from './components/ExportPane';
import { MetricChartModalContent } from './components/MetricChartModalContent';
import { CompareURLParamTests, getSections } from './settings';

export interface CompareBatteryTestResultsContentProps {
    queryRef: PreloadedQuery<CompareBatteryTestResultsContentQuery>;
}

export type SectionType = CompareBatteryTestResultsContentQuery$data['batteryTestResults']['data'][number];

export const CompareBatteryTestResultsContent: FC<CompareBatteryTestResultsContentProps> = ({ queryRef }) => {
    const unitPreferences = useCurrentUserUnitsPref();
    const data = usePreloadedQuery(CompareBatteryTestResultsQuery, queryRef);
    const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
    const [isMetricChartModalOpen, setIsMetricChartModalOpen] = useState(false);
    const [showExportPane, setShowExportPane] = useState(false);
    const [currentlySelectedChartMetricType, setCurrentlySelectedChartMetricType] = useState<MetricsLineType | null>(
        null
    );
    const [currentlySelectedChartTestId, setCurrentlySelectedChartTestId] = useState<string | null>(null);

    const [showResetModal, resetModalComponent] = useConfirmationModal();
    const { show: showToast } = useToast();
    const navigate = useExtendedNavigate();
    const [testsSearchParams, setSearchParams] = useSearchParams();
    const selectedTestIds = testsSearchParams.get(CompareURLParamTests)?.split(',');

    const onChartClick = (metricType: MetricsLineType, testId: string) => {
        setIsMetricChartModalOpen(true);
        setCurrentlySelectedChartMetricType(metricType);
        setCurrentlySelectedChartTestId(testId);
    };
    const sections = getSections(unitPreferences, onChartClick);

    const setSelectedTestsCompare = (selectionWithTestIds: SelectionWithTestIds | null) => {
        if (selectionWithTestIds) {
            navigate(
                {
                    pathname: Paths.TestsCompare,
                    search: { tests: selectionWithTestIds.ids.join(',') },
                },
                { replace: true }
            );
        }
    };

    const longestTestDuration = data.batteryTestResults.data
        .map(test => {
            if (!test.commencedTime || !test.completedTime) {
                return Duration.fromMillis(0);
            }

            return DateTime.fromISO(test.completedTime).diff(DateTime.fromISO(test.commencedTime));
        })
        .sort((a, b) => (a > b ? -1 : 1))[0];

    const handleExport = async (filename: string) => {
        try {
            await exportTestResults(
                data.batteryTestResults.data.map(test => test.id),
                filename
            );

            showToast({
                text: 'Test results exported',
                variant: 'info',
            });
        } catch (error) {
            if (error instanceof Error && error.message === CompareTestExportError.NoTestIds) {
                showToast({
                    text: 'No tests selected for export',
                    variant: 'error',
                });
                return;
            } else if (error instanceof Error && error.message === CompareTestExportError.NoTestResults) {
                showToast({
                    text: 'No test results found',
                    variant: 'error',
                });
                return;
            }

            showToast({
                text: 'Failed to export test results',
                variant: 'error',
            });
        }
    };

    const handleReset = useCallback(() => {
        showResetModal({
            title: 'Reset Selected Tests',
            content: 'Are you sure you want to clear the currently selected tests?',
            buttons: [
                {
                    id: 'reset',
                    label: 'Reset',
                    variant: 'primary',
                },
                {
                    id: 'cancel',
                    label: 'Cancel',
                    variant: 'white',
                },
            ],
            onResult: async result => {
                if (result === 'cancel') {
                    return;
                }

                navigate(Paths.TestsCompare, { replace: true });
            },
        });
    }, [navigate, showResetModal]);

    return (
        <>
            <CompareLayout
                title={'Compare tests'}
                subtitle={getLayoutSubtitle(data.batteryTestResults.data.length)}
                emptyMessageTitle='No tests selected for comparison'
                emptyMessageDescription='Please select some tests to begin'
                emptyMessageAction='Select tests'
                addLabel='Add test'
                data={
                    selectedTestIds ? getSortedData(data.batteryTestResults.data as SectionType[], selectedTestIds) : []
                }
                sections={sections}
                addEnabled={true}
                addButtonEnabled={data.batteryTestResults.data.length < MaxTestDisplay ? true : false}
                addButtonDisabledMessage={`A maximum of ${MaxTestDisplay} tests can be compared`}
                onRequestAdd={() => setIsCompareModalOpen(true)}
                primaryAction='Select tests'
                primaryActionOnClick={() => setIsCompareModalOpen(true)}
                secondaryActions={
                    selectedTestIds && selectedTestIds.length > 0
                        ? [
                              {
                                  buttonIcon: <RepeatIcon />,
                                  buttonText: 'Reset',
                                  onClick: () => handleReset(),
                              },
                              {
                                  buttonIcon: <ArrowDownIcon />,
                                  buttonText: 'Export',
                                  onClick: () => setShowExportPane(!showExportPane),
                                  embeddedComponent: showExportPane && (
                                      <ExportPane
                                          onExport={handleExport}
                                          onClose={() => setShowExportPane(false)}
                                          defaultFilename={`${
                                              data.batteryTestResults.data.length
                                          }-tests-results-${DateTime.now().toFormat('yyyy-MM-dd')}.csv`}
                                      />
                                  ),
                              },
                          ]
                        : []
                }
                emptyMessageActionOnClick={() => setIsCompareModalOpen(true)}
                renderColumnHeader={data => ({
                    title: data.name ?? `Results for ${data.device.name}`,
                    subtitle: data.commencedTime ? getDateTimeFormat(data.commencedTime) : 'Unknown',
                    link: data.task?.id
                        ? `${generatePath(Paths.ViewTaskDeviceResults, {
                              taskId: data.task.id,
                              deviceId: data.device.id,
                          })}?direct`
                        : `${generatePath(Paths.ViewExternalTestResults, { id: data.id })}?direct`,
                    linkName: 'View test',
                })}
                removeEnabled
                removeLabel='Remove test'
                onRequestRemove={(_, column) => {
                    const existingTestIds = selectedTestIds ?? [];

                    setSearchParams((params: URLSearchParams) => {
                        const newTestIds = existingTestIds.filter(id => id !== column.id);
                        params.set(CompareURLParamTests, newTestIds.join(','));
                        return params;
                    });
                }}
            />
            {resetModalComponent}
            <Modal
                bgColor='bg-white'
                open={isCompareModalOpen || isMetricChartModalOpen}
                onHide={() => {
                    setIsCompareModalOpen(false);
                    setIsMetricChartModalOpen(false);
                }}
                closeButton={true}
            >
                {isCompareModalOpen && (
                    <TestSelectModalContent
                        initialSelection={selectedTestIds}
                        includeDeviceColumn
                        tableStorageKeyPrefix='test-compare-selection-table'
                        testIds={[]}
                        limit={MaxTestDisplay}
                        isTestSelectModalOpen={isCompareModalOpen}
                        setIsCompareModalOpen={setIsCompareModalOpen}
                        setSelectedTestsCompare={setSelectedTestsCompare}
                    />
                )}
                {isMetricChartModalOpen && currentlySelectedChartTestId && currentlySelectedChartMetricType && (
                    <MetricChartModalContent
                        currentTestId={currentlySelectedChartTestId}
                        otherTestIds={data.batteryTestResults.data
                            .filter(test => test.id !== currentlySelectedChartTestId)
                            .map(test => test.id)}
                        metricType={currentlySelectedChartMetricType}
                        onClose={() => setIsMetricChartModalOpen(false)}
                        duration={longestTestDuration}
                        startTime={
                            data.batteryTestResults.data.find(test => test.id === currentlySelectedChartTestId)
                                ?.commencedTime ?? null
                        }
                        endTime={
                            data.batteryTestResults.data.find(test => test.id === currentlySelectedChartTestId)
                                ?.completedTime ?? null
                        }
                    />
                )}
            </Modal>
        </>
    );
};

function getSortedData(data: SectionType[], selectedTestIds: string[]): SectionType[] {
    return selectedTestIds.reduce((result, id) => {
        const found = data.find(d => d.id === id);
        if (found) {
            result.push(found);
        }
        return result;
    }, [] as SectionType[]);
}

function getLayoutSubtitle(count: number) {
    if (count === 0) {
        return 'Select tests to compare';
    }
    return `Comparing ${count === 1 ? '1 test' : `${count} tests`}`;
}

export const CompareBatteryTestResultsQuery = graphql`
    query CompareBatteryTestResultsContentQuery($ids: [String!], $unitTemperature: UnitTemperature) {
        batteryTestResults(ids: $ids) {
            data {
                id
                name
                commencedTime
                completedTime
                state
                cause
                finalVoltage
                averageCurrent
                averageTemperature(unit: $unitTemperature)
                depthOfDischarge
                discharged
                averagePower
                task {
                    id
                    name
                }
                device {
                    id
                    name
                    type {
                        displayName
                    }
                    site {
                        id
                        name
                        address {
                            state
                        }
                    }
                    battery {
                        reserveTime
                        averageAge
                        metrics {
                            originalCapacity
                        }
                        strings {
                            strings {
                                installDate
                            }
                        }
                    }
                }
                batteryStrings {
                    string
                    type {
                        id
                        manufacturer
                        model
                    }
                }
                estimatedStateOfHealth {
                    ... on EstimatedValue {
                        value
                        type
                        confidenceInterval {
                            confidence
                            lower
                            upper
                        }
                    }
                }
                estimatedReserveTime {
                    ... on EstimatedValue {
                        value
                        type
                        confidenceInterval {
                            confidence
                            lower
                            upper
                        }
                    }
                }
                estimatedCapacity {
                    ... on EstimatedValue {
                        value
                        type
                        confidenceInterval {
                            confidence
                            lower
                            upper
                        }
                    }
                }
                ...VoltageChart_metrics
                ...CurrentChart_metrics
                ...PowerChart_metrics
                ...TemperatureChart_metrics @arguments(unitTemperature: $unitTemperature)
            }
        }
    }
`;

export async function loadCompareBatteryTestResultsPageData(url: URL) {
    const testIds = url.searchParams.getAll(CompareURLParamTests).flatMap(id => id.split(','));
    const unitPreferences = await getCurrentUserUnitsPref();

    return loadQuery(
        getGlobalEnvironment(),
        CompareBatteryTestResultsQuery,
        {
            ids: testIds.length === 0 ? [''] : testIds,
            unitTemperature: unitPreferences.temperature,
        },
        {
            fetchPolicy: 'network-only',
        }
    );
}
