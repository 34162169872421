import React, { FC, ReactNode, useState } from 'react';

import { SearchBox } from '@accesstel/pcm-ui';

import { FilterDefinition } from 'filters/common';

export interface FilterOptionsProps {
    definitions: FilterDefinition<never>[];
    onSelect: (definition: FilterDefinition<never>) => void;
}

export const FilterOptions: FC<FilterOptionsProps> = ({ definitions, onSelect }) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const searchMatch = searchValue.toLowerCase();

    const matchingDefinitions = definitions
        .filter(definition => definition.name.toLowerCase().includes(searchMatch))
        .sort((definitionA, definitionB) => definitionA.name.localeCompare(definitionB.name));

    const handleSearch = () => {
        if (matchingDefinitions.length > 0) {
            onSelect(matchingDefinitions[0]);
        }
    };

    return (
        <SearchBox
            id='filterSelect'
            variant='filter'
            renderResult={renderFilterDefinition}
            searchResults={matchingDefinitions}
            value={searchValue}
            onChange={setSearchValue}
            onSubmit={handleSearch}
            onResultClick={onSelect}
            placeHolder='Search'
            autoFocus={true}
        />
    );
};

function renderFilterDefinition(definition: FilterDefinition<never>): ReactNode {
    return definition.name;
}
