import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { RunTimeFilter } from 'components/FilterDurationSelect/common';
import { decodeBatteryTypeManModFilter, encodeBatteryTypeManModFilter } from 'filters/battery-type';
import { BatteryTypeManufacturerFilterUI, BatteryTypeModelFilterUI } from 'filters/battery-type/components';
import { BatteryTypeManModelFilter } from 'filters/battery-type/types';
import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import { DeviceBatteryDateFilterUI } from 'filters/device/components/DeviceBatteryDateFilterUI';

import {
    FilterDefinition,
    FilterValueMap,
    RangeFilter,
    decodeDateRangeFilter,
    decodeRangeFilter,
    encodeDateRangeFilter,
    encodeRangeFilter,
} from '../common';
import {
    TaskBatteryTestNameFilterUI,
    TaskBatteryTestStatusFilterUI,
    TaskBatteryTestTypeFilterUI,
    TaskDateFilterUI,
    TaskRunTimeFilterUI,
    TaskScheduleRepeatFilterUI,
    TaskUsingSmartStartFilterUI,
} from './components';
import { TaskDeviceCountFilterUI } from './components/TaskDeviceCountFilterUI';
import {
    decodeBatteryTestNameFilter,
    decodeBatteryTestRuntimeFilter,
    decodeBatteryTestScheduleRepeatFilter,
    decodeBatteryTestStatusFilter,
    decodeBatteryTestTypeFilter,
    decodeBatteryTestUsingSmartStartFilter,
} from './decode';
import {
    encodeBatteryTestNameFilter,
    encodeBatteryTestRuntimeFilter,
    encodeBatteryTestScheduleRepeatFilter,
    encodeBatteryTestStatusFilter,
    encodeBatteryTestTypeFilter,
    encodeBatteryTestUsingSmartStartFilter,
} from './encode';
import {
    BatteryTestNameFilter,
    BatteryTestStatusFilter,
    BatteryTestTypeFilter,
    NotInProgTestsTableColumn,
    ScheduleRepeatFilter,
    SmartStartStatus,
    TaskExtraFilters,
} from './types';

export type TaskColumnFilterMap = {
    [NotInProgTestsTableColumn.Name]: BatteryTestNameFilter[];
    [NotInProgTestsTableColumn.Date]: DateRangeFilter | null;
    [NotInProgTestsTableColumn.Devices]: RangeFilter | null;
    [NotInProgTestsTableColumn.Status]: BatteryTestStatusFilter[];
    [NotInProgTestsTableColumn.Type]: BatteryTestTypeFilter[];
    [NotInProgTestsTableColumn.ScheduleRepeat]: ScheduleRepeatFilter[];
    [NotInProgTestsTableColumn.UsingSmartStart]: SmartStartStatus | null;
    [NotInProgTestsTableColumn.RunTime]: RunTimeFilter | null;
};

export type TaskExtraFilterMap = {
    [TaskExtraFilters.DateCreated]: DateRangeFilter | null;
    [TaskExtraFilters.DateCommenced]: DateRangeFilter | null;
    [TaskExtraFilters.DateCompleted]: DateRangeFilter | null;
    [TaskExtraFilters.DateCancelled]: DateRangeFilter | null;
    [TaskExtraFilters.DateAborted]: DateRangeFilter | null;
    [TaskExtraFilters.DateScheduled]: DateRangeFilter | null;
    [TaskExtraFilters.BatteryStringManufacturer]: BatteryTypeManModelFilter[];
    [TaskExtraFilters.BatteryStringModel]: BatteryTypeManModelFilter[];
};

export type TaskAllFilterMap = TaskColumnFilterMap & TaskExtraFilterMap;

export const DefaultValues: FilterValueMap<TaskColumnFilterMap> = {
    [NotInProgTestsTableColumn.Name]: [],
    [NotInProgTestsTableColumn.Date]: null,
    [NotInProgTestsTableColumn.Devices]: null,
    [NotInProgTestsTableColumn.Status]: [],
    [NotInProgTestsTableColumn.Type]: [],
    [NotInProgTestsTableColumn.ScheduleRepeat]: [],
    [NotInProgTestsTableColumn.UsingSmartStart]: null,
    [NotInProgTestsTableColumn.RunTime]: null,
};

export const DefaultExtraFilters: FilterValueMap<TaskExtraFilterMap> = {
    [TaskExtraFilters.DateCreated]: null,
    [TaskExtraFilters.DateCommenced]: null,
    [TaskExtraFilters.DateCompleted]: null,
    [TaskExtraFilters.DateCancelled]: null,
    [TaskExtraFilters.DateAborted]: null,
    [TaskExtraFilters.DateScheduled]: null,
    [TaskExtraFilters.BatteryStringManufacturer]: [],
    [TaskExtraFilters.BatteryStringModel]: [],
};

export const StaticTaskFilterDefinitions: FilterDefinition<NotInProgTestsTableColumn>[] = [
    {
        id: NotInProgTestsTableColumn.Name,
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: NotInProgTestsTableColumn.Name,
        component: () => TaskBatteryTestNameFilterUI,
        describeValue: (value: BatteryTestNameFilter) => value.name,
        encodeValue: encodeBatteryTestNameFilter,
        decodeValue: decodeBatteryTestNameFilter,
    },
    {
        id: NotInProgTestsTableColumn.Type,
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: NotInProgTestsTableColumn.Type,
        component: () => TaskBatteryTestTypeFilterUI,
        describeValue: (value: BatteryTestTypeFilter) => value.name,
        encodeValue: encodeBatteryTestTypeFilter,
        decodeValue: decodeBatteryTestTypeFilter,
    },
    {
        id: NotInProgTestsTableColumn.Devices,
        type: 'single',
        name: 'Device Count',
        category: NotInProgTestsTableColumn.Devices,
        column: NotInProgTestsTableColumn.Devices,
        component: () => TaskDeviceCountFilterUI,
        describeValue: (value: RangeFilter) => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: NotInProgTestsTableColumn.Status,
        type: 'multi',
        name: 'Result',
        category: 'Result',
        column: NotInProgTestsTableColumn.Status,
        component: () => TaskBatteryTestStatusFilterUI,
        describeValue: (value: BatteryTestStatusFilter) => `${value.name}`,
        encodeValue: encodeBatteryTestStatusFilter,
        decodeValue: decodeBatteryTestStatusFilter,
    },
    {
        id: NotInProgTestsTableColumn.Date,
        type: 'single',
        name: 'Date',
        category: 'Date',
        column: NotInProgTestsTableColumn.Date,
        component: () => TaskDateFilterUI,
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TaskExtraFilters.DateCreated,
        type: 'single',
        name: 'Date Created',
        category: 'Date Created',
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Created' }),
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TaskExtraFilters.DateCommenced,
        type: 'single',
        name: 'Date Commenced',
        category: 'Date Commenced',
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Commenced' }),
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TaskExtraFilters.DateCompleted,
        type: 'single',
        name: 'Date Completed',
        category: 'Date Completed',
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Completed' }),
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TaskExtraFilters.DateCancelled,
        type: 'single',
        name: 'Date Cancelled',
        category: 'Date Cancelled',
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Cancelled' }),
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TaskExtraFilters.DateAborted,
        type: 'single',
        name: 'Date Aborted',
        category: 'Date Aborted',
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Aborted' }),
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TaskExtraFilters.DateScheduled,
        type: 'single',
        name: 'Date Scheduled',
        category: 'Date Scheduled',
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Scheduled' }),
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: NotInProgTestsTableColumn.UsingSmartStart,
        type: 'single',
        name: 'Using Smart Start',
        category: 'Using Smart Start',
        column: NotInProgTestsTableColumn.UsingSmartStart,
        component: () => TaskUsingSmartStartFilterUI,
        describeValue: (value: string) => value,
        encodeValue: encodeBatteryTestUsingSmartStartFilter,
        decodeValue: decodeBatteryTestUsingSmartStartFilter,
    },
    {
        id: NotInProgTestsTableColumn.ScheduleRepeat,
        type: 'multi',
        name: 'Repeat Cadence',
        category: 'Repeat Cadence',
        column: NotInProgTestsTableColumn.ScheduleRepeat,
        component: () => TaskScheduleRepeatFilterUI,
        describeValue: (value: ScheduleRepeatFilter) => `${value.name}`,
        encodeValue: encodeBatteryTestScheduleRepeatFilter,
        decodeValue: decodeBatteryTestScheduleRepeatFilter,
    },
    {
        id: TaskExtraFilters.BatteryStringManufacturer,
        type: 'multi',
        name: 'Battery String Manufacturer',
        category: 'Battery String Manufacturer',
        component: () => BatteryTypeManufacturerFilterUI,
        describeValue: (value: BatteryTypeManModelFilter) => value.name,
        encodeValue: encodeBatteryTypeManModFilter,
        decodeValue: decodeBatteryTypeManModFilter,
    },
    {
        id: TaskExtraFilters.BatteryStringModel,
        type: 'multi',
        name: 'Battery String Model',
        category: 'Battery String Model',
        component: () => BatteryTypeModelFilterUI,
        describeValue: (value: BatteryTypeManModelFilter) => value.name,
        encodeValue: encodeBatteryTypeManModFilter,
        decodeValue: decodeBatteryTypeManModFilter,
    },
    {
        id: NotInProgTestsTableColumn.RunTime,
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: NotInProgTestsTableColumn.RunTime,
        component: () => TaskRunTimeFilterUI,
        describeValue: formatDurationFilter,
        encodeValue: encodeBatteryTestRuntimeFilter,
        decodeValue: decodeBatteryTestRuntimeFilter,
    },
];
