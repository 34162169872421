import React, { FC } from 'react';

import { SkeletonCard, SkeletonChart, SkeletonPadding, SkeletonText } from '@accesstel/pcm-ui';

export const DeviceLoadSkeleton: FC = () => {
    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-bold text-xl'>
                    <SkeletonText characters={10} />
                </div>
                <div className='flex flex-col justify-start items-end flex-grow font-bold text-xl'>
                    <div>
                        <SkeletonText characters={4} />
                    </div>
                    <div className='text-xs font-normal'>
                        <SkeletonText characters={15} />
                    </div>
                </div>
            </div>
            <SkeletonPadding spacing={38} />
            <SkeletonText characters={30} size='subheading' />

            <SkeletonChart aspectRatio='2.2' stretch />
            <SkeletonText characters={5} size='subheading' />
            <SkeletonCard height={93} />
            <SkeletonText characters={5} size='subheading' />
            <SkeletonCard height={93} />
        </div>
    );
};
