import React from 'react';
import { useLocation } from 'react-router-dom';

import { Button, useReferrer } from '@accesstel/pcm-ui';

import { captureMessage } from '@sentry/react';
import { useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';

export function ErrorNotFound(): JSX.Element {
    const referer = useReferrer();
    const permissions = useUserPermissions();
    const location = useLocation();

    if (referer) {
        // this is a possibly a dead link within the application. Send this to Sentry so that developers can investigate
        captureMessage('Link refers to a 404 page', {
            extra: {
                path: location.pathname,
                referer,
                permissions,
            },
        });
    }

    return (
        <div className='error-page'>
            <div className='error-text'>404 Error</div>
            <div className='page-not-found'>Page not found</div>
            <Button buttonClasses='overview-button text-lg' buttonText='Go to dashboard' to={Paths.Dashboard} />
        </div>
    );
}
