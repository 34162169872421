import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { BarDataType, LabelFormatterType, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { FilterActionType } from 'filters/common';
import { SiteExtraFilters, SiteTableColumn, SiteTableColumnId, siteToFilterObject, useSiteFilter } from 'filters/site';
import { TableActionType, TableLayout, useTableReducer } from 'layouts';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { IEnvironment } from 'relay-runtime';
import { DefaultTimeRange, TimeRange } from 'views/reports/ac-power/settings';

import { ConfirmationModalButton, useConfirmationModal } from '../../../lib/confirmation';
import { useSiteColumnDefinitions } from '../../../lib/tables/site/columns';
import { useSiteTableQuery } from '../../../lib/tables/site/request';
import { SiteDevice, SiteWithDevices } from '../../../lib/tables/site/type';
import { colorPalette } from '../AssetManagement';
import { queries_GetAssetsDistributionQuery } from '../__generated__/queries_GetAssetsDistributionQuery.graphql';
import { getAssetsDistribution } from '../queries';
import { SitesAllIdsQuery } from './__generated__/SitesAllIdsQuery.graphql';
import { SitesSearchQuery, SitesSearchQuery$data } from './__generated__/SitesSearchQuery.graphql';
import { deleteSites, validateSitesForDelete } from './lib/delete';

type SiteSearchResult = SitesSearchQuery$data['sites']['data'][number];

const TableStorageKeyPrefix = 'site-table';

export const ManageSites: FC = () => {
    const { hasAssetsWrite } = useUserPermissions();
    const environment = useRelayEnvironment();

    const [showModal, modalComponent] = useConfirmationModal();
    const { show: showToast } = useToast();

    const columnDefinitions = useSiteColumnDefinitions({ siteNameAsName: true });

    const [tableState, dispatchTableState] = useTableReducer<SiteTableColumnId>({
        defaultSortColumn: SiteTableColumn.Name,
        allColumns: columnDefinitions,
        defaultVisibleColumns: [
            SiteTableColumn.Name,
            SiteTableColumn.State,
            SiteTableColumn.DeviceCount,
            SiteTableColumn.DeviceStatus,
        ],
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useSiteFilter({
        extraFilters: {
            [SiteExtraFilters.ACReliabilityTimeRange]: DefaultTimeRange,
        },
    });
    const filterObject = useMemo(() => siteToFilterObject(filters), [filters]);

    const acReliabilityTimeRange: TimeRange =
        (filters.extraFilters[SiteExtraFilters.ACReliabilityTimeRange] as TimeRange | undefined) ?? DefaultTimeRange;

    const { data: props, error, retry, isFetching, fetchTable } = useSiteTableQuery(tableState, filters);

    const { data: distributionProps } = useQuery<queries_GetAssetsDistributionQuery>(
        getAssetsDistribution,
        { type: 'SiteType' },
        { fetchPolicy: 'network-only' }
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<SitesSearchQuery>(
                environment,
                graphql`
                    query SitesSearchQuery($name: String = "", $pageSize: Int!) {
                        sites(filters: { name: { value: $name } }, pageSize: $pageSize) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                { name: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => (result?.sites.data as SiteSearchResult[]) ?? []);
        },
        [environment]
    );

    const distributionData: BarDataType[] =
        distributionProps?.assetDistribution?.distribution.map((group, i) => {
            let bgColor = undefined;
            if (colorPalette.length > i) {
                bgColor = colorPalette[i];
            }

            return {
                id: group.key,
                label: group.key,
                value: group.value,
                bgClass: bgColor,
            };
        }) ?? [];

    const labelFormatter: LabelFormatterType<BarDataType> = useCallback((label, data) => {
        if (label === null) {
            return '';
        }
        return `${data.value} ${label.toUpperCase()}`;
    }, []);

    const onSegmentClick = (id: string) => {
        dispatchFilters({ type: FilterActionType.Apply, column: SiteTableColumn.Type, value: [{ name: id, id }] });
    };

    const handleDelete = useCallback(() => {
        async function requestDelete() {
            const validationResults = await validateSitesForDelete(tableState.selectedItems, environment);

            const nonEmptySites = validationResults.filter(result => !result.empty);

            let title: string;
            let content: ReactNode;
            let buttons: ConfirmationModalButton[] = [];
            let successMessage: string;

            if (tableState.selectedItems.length === 1) {
                title = 'Delete Site?';
                content = 'Are you sure you want to delete the selected site?';
                successMessage = 'Deleted site';
            } else {
                title = 'Delete Sites?';
                content = 'Are you sure you want to delete the selected sites?';
                successMessage = `Deleted ${tableState.selectedItems.length} sites`;
            }

            buttons = [
                {
                    id: 'confirm',
                    label: 'Delete',
                    variant: 'primary',
                },
                {
                    id: 'cancel',
                    label: 'Cancel',
                    variant: 'white',
                },
            ];

            if (nonEmptySites.length > 0) {
                const canDeleteAnySites = nonEmptySites.length < tableState.selectedItems.length;

                let secondaryContent: string;
                if (canDeleteAnySites) {
                    secondaryContent = 'The following sites contain devices and will not be deleted:';
                } else {
                    title = 'Unable to delete sites';
                    content = '';
                    secondaryContent = 'The following sites contain devices and cannot be deleted:';
                }

                if (nonEmptySites.length < 5) {
                    content = (
                        <div>
                            <div>{content}</div>
                            <div>{secondaryContent}</div>
                            <ul className='ml-4 mt-2'>
                                {nonEmptySites.map(result => (
                                    <li key={result.name}>{result.name}</li>
                                ))}
                            </ul>
                        </div>
                    );
                } else {
                    const firstSites = nonEmptySites.slice(0, 3);
                    content = (
                        <div>
                            <div>{content}</div>
                            <div>{secondaryContent}</div>
                            <ul className='ml-4 mt-2'>
                                {firstSites.map(result => (
                                    <li key={result.name}>{result.name}</li>
                                ))}
                                <li className='text-mauveRegular'>
                                    And {nonEmptySites.length - firstSites.length} more
                                </li>
                            </ul>
                        </div>
                    );
                }

                if (!canDeleteAnySites) {
                    buttons = [
                        {
                            id: 'cancel',
                            label: 'Close',
                            variant: 'primary',
                        },
                    ];
                }
            }

            showModal({
                title,
                content,
                buttons,
                onResult: async result => {
                    if (result === 'cancel') {
                        return;
                    }

                    let ok = false;
                    try {
                        const result = await deleteSites(tableState.selectedItems, environment);
                        switch (result) {
                            case 'Success':
                                showToast({
                                    text: successMessage,
                                    variant: 'info',
                                });
                                ok = true;
                                break;
                            case 'PartialSuccess':
                                showToast({
                                    text: `Unable to delete some sites`,
                                    variant: 'error',
                                });
                                ok = true;
                                break;
                            case 'NotEmpty':
                                showToast({
                                    text: `Unable to delete sites that contain devices`,
                                    variant: 'error',
                                });
                                break;
                            default:
                            case 'UnknownSite':
                                showToast({
                                    text: `Unknown site`,
                                    variant: 'error',
                                });
                                break;
                        }
                    } catch (error) {
                        captureException(error, scope => {
                            scope.setTag('Function', 'Delete sites');
                            scope.setExtra('site IDs', tableState.selectedItems);
                            return scope;
                        });
                        showToast({
                            text: `Error deleting sites`,
                            variant: 'error',
                        });
                    }

                    if (ok) {
                        dispatchTableState({ type: TableActionType.SetSelection, selection: [] });
                    }

                    // Refresh the table
                    setTimeout(() => {
                        // FIXME: this delay is present as elasticsearch isn't waiting for the refresh on delete
                        retry();
                    }, 1000);
                },
            });
        }

        requestDelete().catch(error => {
            captureException(error, scope => {
                scope.setTag('Function', 'Validate sites for delete');
                scope.setExtra('site IDs', tableState.selectedItems);
                return scope;
            });
        });
    }, [dispatchTableState, environment, retry, showModal, showToast, tableState.selectedItems]);

    return (
        <>
            <TableLayout
                title='Site Management'
                columns={columnDefinitions}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={props?.sites.data ?? null}
                getRowId={(row: SiteWithDevices | SiteDevice) => row.id}
                isProcessing={!!props && isFetching}
                page={props?.sites.pageInfo.page}
                pageCount={props?.sites.pageInfo.total}
                overallCount={props?.overallSites.total}
                resultCount={props?.sites.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Site'
                onSearch={handleSearch}
                renderSearchResultAsString={(item: SiteSearchResult) => item.name}
                emptyMessage='There are no sites present'
                unit='Site'
                primaryAction={hasAssetsWrite ? 'Add new site' : undefined}
                primaryActionLink={hasAssetsWrite ? Paths.AddSite : undefined}
                selection={hasAssetsWrite}
                onRequestAllIds={() => getAllSiteIds(environment, filterObject, acReliabilityTimeRange)}
                getItemLink={getSiteLink}
                selectionFooterActions={[
                    {
                        buttonText: `Delete selected ${tableState.selectedItems.length > 1 ? 'sites' : 'site'}`,
                        onClick: handleDelete,
                    },
                ]}
                barChartData={{
                    data: distributionData,
                    emptyLabel: 'No sites added',
                    labelFormatter,
                    onSegmentClick,
                }}
                exportEnabled
                exportFilename='sites'
                exportFetchData={options =>
                    fetchTable(options).then(results => results.flatMap(result => result.sites.data))
                }
            />
            {modalComponent}
        </>
    );
};

function getAllSiteIds(
    environment: IEnvironment,
    filters: Record<string, unknown>,
    acTimeRange: TimeRange
): Promise<string[]> {
    const getAllSiteIdsQuery = graphql`
        query SitesAllIdsQuery($filters: SiteFilter, $acTimeRange: ACTimeRange!) {
            sites(
                onlyProvisioningStatuses: Active
                pageSize: 10000
                filters: $filters
                acReliabilityTimeRange: $acTimeRange
            ) {
                data {
                    id
                }
            }
        }
    `;

    return fetchQuery<SitesAllIdsQuery>(environment, getAllSiteIdsQuery, { filters, acTimeRange })
        .toPromise()
        .then(data => data?.sites.data.map(site => site.id) ?? []);
}

function getSiteLink(site: SiteWithDevices | SiteDevice): string {
    return generatePath(Paths.EditSite, { id: site.id });
}
