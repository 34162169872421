import React, { FC } from 'react';

import { Button, LoadableContentArea } from '@accesstel/pcm-ui';

import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';

import { queries_batteryTestCardsQuery } from '../../../__generated__/queries_batteryTestCardsQuery.graphql';
import { batteryTestCardsQuery } from '../../../queries';
import { DeviceBatteryTestsCardsComponent } from '../../tests/components/DeviceBatteryTestsCardsComponent';

export interface DeviceBatteryTestsProps {
    device: string;
}

export const DeviceBatteryTests: FC<DeviceBatteryTestsProps> = ({ device }) => {
    const { hasTasksWrite } = useUserPermissions();
    const {
        data: props,
        error,
        retry: retryMain,
    } = useQuery<queries_batteryTestCardsQuery>(batteryTestCardsQuery, {
        id: device,
        status: ['Passed', 'Failed', 'Aborted', 'InProgress', 'Waiting', 'Inconclusive', 'Finalizing', 'Analyzing'],
        page: 1,
    });

    const scheduleTestLink = `/tasks/batteryhealth/schedule?device=${device}`;
    const lastTestsLink = `/reports/batteries/sites/${props?.device?.site.id}/tests/${device}`;

    return (
        <LoadableContentArea
            data={props}
            error={!!error}
            onRetry={retryMain}
            render={props => {
                const supportBatteryTesting = props.device?.type.features.battery.testing;

                return (
                    <div>
                        {props.device!.tests.data.length > 0 ? (
                            <DeviceBatteryTestsCardsComponent props={props} device={device} />
                        ) : (
                            <div className='text-eggplantRegular font-light text-base'>
                                No tests have been run for this device.
                            </div>
                        )}
                        <div className='flex flex-row-reverse justify-between mt-6 w-full'>
                            <Button
                                buttonText='View all tests'
                                buttonClasses='text-xs'
                                variant='gray'
                                size='small'
                                to={lastTestsLink}
                            />
                            {hasTasksWrite && supportBatteryTesting && !props.device.monitorOnly && (
                                <Button
                                    buttonText='Schedule Battery Test'
                                    buttonClasses='text-xs'
                                    size='small'
                                    to={scheduleTestLink}
                                />
                            )}
                        </div>
                    </div>
                );
            }}
        />
    );
};
