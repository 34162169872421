import React, { FC } from 'react';

import { BarDataType, LoadableContentArea, StackedHorizontalBar } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'lib/query-helpers';

import { FirmwareStatusHorizontalBarQuery } from './__generated__/FirmwareStatusHorizontalBarQuery.graphql';
import style from './style.module.css';

export const FirmwareStatusHorizontalBar: FC = () => {
    const query = graphql`
        query FirmwareStatusHorizontalBarQuery {
            globalFirmwareStatus {
                name
                count
            }
        }
    `;

    const { data: props, error, retry: retryMain } = useQuery<FirmwareStatusHorizontalBarQuery>(query, {});

    const data: BarDataType[] = [];

    if (props) {
        props.globalFirmwareStatus.forEach((status: { count: number; name: string }) => {
            if (status.count !== 0) {
                switch (status.name) {
                    case 'FirmwareUptoDate':
                        data.push({
                            label: 'FIRMWARE UP TO DATE',
                            value: status.count,
                            bgClass: 'bg-mauveRegular',
                        });
                        break;
                    case 'UpdatesAvailable':
                        data.push({
                            label: 'UPDATES AVAILABLE',
                            value: status.count,
                            bgClass: 'bg-coralRegular',
                        });
                        break;
                    case 'Other':
                        data.push({
                            label: 'OTHER',
                            value: status.count,
                            bgClass: 'bg-coralLight',
                        });
                        break;
                    default:
                        break;
                }
            }
        });
    }

    return (
        <LoadableContentArea
            className={style.firmware_bar}
            variant='framed'
            data={props}
            error={!!error}
            onRetry={retryMain}
            render={() => {
                return (
                    <StackedHorizontalBar
                        data={data}
                        valueFormatter={valueFormatter}
                        emptyLabel='NO POWER CONTROLLERS ADDED'
                    />
                );
            }}
        />
    );
};

function valueFormatter(value: number | null): string {
    if (value === null) {
        return '';
    }
    if (value === 1) {
        return `${value.toLocaleString()} Device`;
    } else {
        return `${value.toLocaleString()} Devices`;
    }
}
