import React, { FC } from 'react';

import { BigButton } from '@accesstel/pcm-ui';

import { useDocumentTitle } from 'components';
import { BigButtonLayout } from 'layouts/BigButtonLayout';
import { useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';

export const Settings: FC = () => {
    useDocumentTitle('Admin');

    const { hasAdministration, hasAuditRead } = useUserPermissions();

    return (
        <BigButtonLayout title='Admin'>
            {hasAdministration && (
                <>
                    <BigButton
                        to={Paths.AdministrationConfiguration}
                        title='System Configuration'
                        text='Manage the system settings'
                    />
                    <BigButton to={Paths.AdministrationUsers} title='Users' text='Manage the users' />
                </>
            )}
            {(hasAuditRead || hasAdministration) && (
                <BigButton to={Paths.AdministrationAudit} title='Audit' text='View all the user activity logs' />
            )}
        </BigButtonLayout>
    );
};
