import React, { FC, ReactNode, useCallback, useState } from 'react';

import { Button, ButtonVariants, Modal, PageHeading } from '@accesstel/pcm-ui';

export type ConfirmationModalCallback = (buttonId: string) => Promise<void>;

export interface ConfirmationModalButton {
    id: string;
    label: string;
    variant: ButtonVariants;
}

export interface ConfirmationModalOptions {
    /**
     * The title to display in the modal.
     */
    title: string;
    /**
     * The content to display in the modal.
     */
    content: ReactNode;
    /**
     * The buttons to display in the modal. If not provided, the default buttons will be used.
     *
     * Default buttons:
     *
     * - Yes (id: confirm)
     * - No (id: cancel)
     *
     * @default DefaultButtons
     */
    buttons?: ConfirmationModalButton[];

    /**
     * The callback to invoke when a button is clicked.
     */
    onResult: ConfirmationModalCallback;

    /**
     * The callback to invoke when the modal is closed.
     */
    onClose?: () => void;
}

export type ConfirmationModalTriggerFunction = (options: ConfirmationModalOptions) => void;

const DefaultButtons: ConfirmationModalButton[] = [
    {
        id: 'confirm',
        label: 'Yes',
        variant: 'primary',
    },
    {
        id: 'cancel',
        label: 'No',
        variant: 'white',
    },
];

/**
 * Use this hook to display a confirmation modal.
 *
 * @returns A tuple containing the trigger function and the modal component.
 */
export function useConfirmationModal(): [ConfirmationModalTriggerFunction, ReactNode] {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<Required<ConfirmationModalOptions> | null>(null);

    const triggerFunction = useCallback<ConfirmationModalTriggerFunction>(options => {
        // Delay the opening incase we open a replacement modal in the same render cycle.
        setTimeout(() => {
            setIsOpen(true);
            setOptions({
                ...options,
                buttons: options.buttons ?? DefaultButtons,
                onClose: () => {
                    setIsOpen(false);
                    setOptions(null);
                    options.onClose?.();
                },
            });
        }, 0);
    }, []);

    const modal = isOpen && options ? <ConfirmationModal {...options} /> : null;

    return [triggerFunction, modal];
}

const ConfirmationModal: FC<Required<ConfirmationModalOptions>> = ({ title, content, buttons, onResult, onClose }) => {
    const [isProcessing, setProcessing] = useState(false);

    return (
        <Modal open onHide={onClose} closeButton={false}>
            <div className='w-dialog-md flex flex-col items-center justify-center'>
                <PageHeading value={title} />
                <div className='text-eggplantRegular font-normal text-base pb-12'>{content}</div>
                <div className='flex flex-row justify-evenly gap-2'>
                    {buttons?.map(button => (
                        <Button
                            key={button.id}
                            buttonClasses='w-min-24'
                            onClick={() => {
                                setProcessing(true);
                                onResult(button.id).finally(() => {
                                    setProcessing(false);
                                    onClose();
                                });
                            }}
                            variant={button.variant}
                            buttonText={button.label}
                            processing={isProcessing}
                        />
                    ))}
                </div>
            </div>
        </Modal>
    );
};
