import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export function useHistoryState<T>(name: string, initial: T): [T, (value: T) => void] {
    const location = useLocation();
    const current = { ...initial, ...(location.state?.stored?.[name] ?? initial) } as T;

    const setState = useCallback(
        (newValue: T) => {
            // FIXME: This is a workaround for react-router's overly aggressive rerendering. ANY time navigate is called,
            //        the entire app rerenders regardless of whether the URL changed. Preferably, we would use the
            //        navigate function.
            //        See https://github.com/remix-run/react-router/discussions/9851 for a discussion on this
            const existingState = window.history.state;
            const existingStored = existingState?.usr?.stored ?? {};
            window.history.replaceState(
                {
                    ...existingState,
                    usr: {
                        ...existingState?.usr,
                        stored: {
                            ...existingStored,
                            [name]: newValue,
                        },
                    },
                },
                ''
            );
        },
        [name]
    );

    return [current, setState];
}
