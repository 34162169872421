/**
 * @generated SignedSource<<b6d8b3db8ca0094a5bbf1b3d7502780d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetricsView_test$data = {
  readonly averageCurrent: number | null;
  readonly averageTemperature: number | null;
  readonly discharged: number | null;
  readonly finalVoltage: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"ResultView_test">;
  readonly " $fragmentType": "MetricsView_test";
};
export type MetricsView_test$key = {
  readonly " $data"?: MetricsView_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricsView_test">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitTemperature"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricsView_test",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Amps"
        }
      ],
      "kind": "ScalarField",
      "name": "averageCurrent",
      "storageKey": "averageCurrent(unit:\"Amps\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "unit",
          "variableName": "unitTemperature"
        }
      ],
      "kind": "ScalarField",
      "name": "averageTemperature",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "AmpHour"
        }
      ],
      "kind": "ScalarField",
      "name": "discharged",
      "storageKey": "discharged(unit:\"AmpHour\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Volts"
        }
      ],
      "kind": "ScalarField",
      "name": "finalVoltage",
      "storageKey": "finalVoltage(unit:\"Volts\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResultView_test"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "97078a656787381f35c220808fbe04ab";

export default node;
