import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { convertConnectivitySettingsFormStateToApi } from '../../lib/convert';
import { DeviceType } from '../../lib/device-types';
import {
    useConnectionTestMutation,
    useConnectionTestMutation$data,
} from './__generated__/useConnectionTestMutation.graphql';
import { ConnectivitySettingsFormValues, getConnectionSettingsValidationSchema } from './schema';

export type ConnectionTestCallback = () => void;

export interface ConnectionTester {
    testConnection: ConnectionTestCallback;
    canCheckConnection: boolean;
    isTestingConnection: boolean;
    isConnectionOk: boolean;
    connectionStatus: string | null;
}

export function useConnectionTest(settings: ConnectivitySettingsFormValues, type: DeviceType): ConnectionTester {
    const [isConnectionOk, setConnectionOk] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState<string | null>(null);

    const [checkConnection, isTestingConnection] = useMutation<useConnectionTestMutation>(graphql`
        mutation useConnectionTestMutation($deviceType: ID!, $settings: ConnectionSettingsIn!) {
            checkConnectivity(settings: $settings, deviceType: $deviceType) {
                addresses {
                    address
                    reachable
                    protocols {
                        protocolId
                        ... on SNMPProtocolConnectivityResult {
                            snmpStatus: status
                        }
                        ... on GatewayProtocolConnectivityResult {
                            gatewayStatus: status
                        }
                        ... on WebProtocolConnectivityResult {
                            webStatus: status
                        }
                    }
                }
            }
        }
    `);

    const testConnection = useCallback(() => {
        setConnectionStatus(null);
        setConnectionOk(true);

        const connectionSettings = convertConnectivitySettingsFormStateToApi(settings, type);

        checkConnection({
            variables: {
                deviceType: type.id,
                settings: connectionSettings,
            },
            onCompleted(response) {
                const [isOk, message] = decodeResponse(response);
                setConnectionOk(isOk);
                setConnectionStatus(message);
            },
            onError() {
                setConnectionStatus('An error occurred. Please try again.');
                setConnectionOk(false);
            },
        });
    }, [checkConnection, settings, type]);

    const canCheckConnection = useMemo(() => {
        if (settings.addresses.length === 0 && type.category !== 'Generator') {
            return false;
        }

        const schema = getConnectionSettingsValidationSchema(type.id, [type]);
        if (!schema.isValidSync(settings)) {
            return false;
        }

        return true;
    }, [settings, type]);

    return {
        testConnection,
        canCheckConnection,
        connectionStatus,
        isConnectionOk,
        isTestingConnection,
    };
}

function decodeResponse(response: useConnectionTestMutation$data): [boolean, string] {
    if (!response.checkConnectivity) {
        return [false, 'Unable to check'];
    }

    for (const addressResult of response.checkConnectivity.addresses) {
        if (!addressResult.reachable) {
            return [false, `Unable to reach ${addressResult.address}`];
        }

        for (const protocol of addressResult.protocols) {
            if (protocol.snmpStatus) {
                const snmpStatus = protocol.snmpStatus;
                switch (snmpStatus) {
                    case 'Unreachable':
                        return [false, `Unable to reach ${addressResult.address} using SNMP`];
                    case 'BadCredentials':
                        return [false, `Bad credentials for ${addressResult.address}`];
                    case 'ReadOnly':
                        return [false, `Bad write credentials for ${addressResult.address}`];
                    case 'WriteOnly':
                        return [false, `Bad read credentials for ${addressResult.address}`];
                    case 'Ok':
                        break;
                }
            } else if (protocol.webStatus) {
                const webStatus = protocol.webStatus;
                switch (webStatus) {
                    case 'Unreachable':
                        return [false, `Unable to reach ${addressResult.address} using web protocol`];
                    case 'Reachable':
                        return [false, `Unable to authenticate with ${addressResult.address} using web protocol`];
                    case 'BadCredentials':
                        return [false, `Bad credentials for ${addressResult.address}`];
                    case 'Ok':
                        break;
                }
            } else if (protocol.gatewayStatus) {
                const gatewayStatus = protocol.gatewayStatus;
                switch (gatewayStatus) {
                    case 'GatewayUnreachable':
                        return [false, 'Unable to reach gateway'];
                    case 'GatewayBadCredentials':
                        return [false, 'Bad credentials for gateway'];
                    case 'NeNotFound':
                        return [false, 'Network element not found within gateway'];
                    case 'NeOffline':
                        return [false, 'Network element is offline'];
                    case 'Ok':
                        break;
                }
            }
        }
    }

    return [true, 'Connection successful'];
}
