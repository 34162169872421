import React, { FC } from 'react';

import { Button, useExtendedNavigate } from '@accesstel/pcm-ui';

import { useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';

import { DeviceBatteries_device$data } from '../__generated__/DeviceBatteries_device.graphql';
import { StringRow } from './StringRow';

interface BatteryStringDisplayProps {
    stringRows: NonNullable<DeviceBatteries_device$data['battery']>['strings']['strings'];
    deviceId: string;
}

export const BatteryStringDisplay: FC<BatteryStringDisplayProps> = ({ stringRows, deviceId }) => {
    const navigate = useExtendedNavigate();
    const { hasAssetsWrite } = useUserPermissions();

    return (
        <>
            {stringRows.map(stringRow => (
                <StringRow key={stringRow.id} stringRow={stringRow} />
            ))}

            {stringRows.length === 0 && (
                <>
                    <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    <div className='col-span-4 grid place-items-center space-y-4'>
                        <span>No battery strings configured</span>
                        <Button
                            buttonText={'Edit device'}
                            onClick={() => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } })}
                            size='small'
                            variant={'primary'}
                            disabled={!hasAssetsWrite}
                            disabledMessage={'You do not have permission to edit devices'}
                        />
                    </div>
                    <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                </>
            )}
        </>
    );
};
