/**
 * @generated SignedSource<<47b2070745ff9ca187a87d4e0a7ae7c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type queries_AverageCurrentQuery$variables = {
  begin: string;
  end: string;
  interval: string;
};
export type queries_AverageCurrentQuery$data = {
  readonly batteryMetrics: {
    readonly current: {
      readonly values: ReadonlyArray<{
        readonly timestamp: string;
        readonly value: number | null;
      }>;
    } | null;
  };
};
export type queries_AverageCurrentQuery = {
  response: queries_AverageCurrentQuery$data;
  variables: queries_AverageCurrentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "begin"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "end"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "interval"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GlobalBatteryMetrics",
    "kind": "LinkedField",
    "name": "batteryMetrics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "begin",
            "variableName": "begin"
          },
          {
            "kind": "Variable",
            "name": "end",
            "variableName": "end"
          },
          {
            "kind": "Variable",
            "name": "interval",
            "variableName": "interval"
          },
          {
            "kind": "Literal",
            "name": "unit",
            "value": "Amps"
          }
        ],
        "concreteType": "TimeSeriesMetrics",
        "kind": "LinkedField",
        "name": "current",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimestampMetric",
            "kind": "LinkedField",
            "name": "values",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_AverageCurrentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queries_AverageCurrentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "03cae9530e27f0d9e61c986b4b9a4e81",
    "id": null,
    "metadata": {},
    "name": "queries_AverageCurrentQuery",
    "operationKind": "query",
    "text": "query queries_AverageCurrentQuery(\n  $begin: Timestamp!\n  $end: Timestamp!\n  $interval: Duration!\n) {\n  batteryMetrics {\n    current(unit: Amps, begin: $begin, end: $end, interval: $interval) {\n      values {\n        timestamp\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d83419e1af14a0389aa1c983cda29cf";

export default node;
