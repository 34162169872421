/**
 * @generated SignedSource<<667ab55ab550aeb228a6a28e13ac29b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteSelectorSuggestQuery$variables = {
  query?: string | null;
};
export type SiteSelectorSuggestQuery$data = {
  readonly suggestSite: {
    readonly all: ReadonlyArray<{
      readonly relatedId: string;
      readonly title: string;
    }> | null;
    readonly recent: ReadonlyArray<{
      readonly relatedId: string;
      readonly title: string;
    }> | null;
  };
};
export type SiteSelectorSuggestQuery = {
  response: SiteSelectorSuggestQuery$data;
  variables: SiteSelectorSuggestQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "relatedId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      },
      {
        "kind": "Literal",
        "name": "settings",
        "value": {
          "allLimit": 5,
          "includeAll": true,
          "includeRecent": true,
          "recentLimit": 5
        }
      }
    ],
    "concreteType": "SiteSuggestions",
    "kind": "LinkedField",
    "name": "suggestSite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SiteSuggestion",
        "kind": "LinkedField",
        "name": "recent",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SiteSuggestion",
        "kind": "LinkedField",
        "name": "all",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteSelectorSuggestQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteSelectorSuggestQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "521c22edc5a0e09715f1e94ab2062cf2",
    "id": null,
    "metadata": {},
    "name": "SiteSelectorSuggestQuery",
    "operationKind": "query",
    "text": "query SiteSelectorSuggestQuery(\n  $query: String\n) {\n  suggestSite(query: $query, settings: {includeRecent: true, includeAll: true, recentLimit: 5, allLimit: 5}) {\n    recent {\n      relatedId\n      title\n    }\n    all {\n      relatedId\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3000a968bb48643cba4633ff923b7079";

export default node;
