import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { FormikDropdownWithSearch, FormikGeneralSelect, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useFormikContext } from 'formik';
import { SUGGESTIONS_LIMIT } from 'lib/provision';
import { useQuery } from 'lib/query-helpers';

import {
    DeviceCategory,
    queries_GetDeviceTypesQuery,
} from '../../../__generated__/queries_GetDeviceTypesQuery.graphql';
import { getDeviceTypesQuery } from '../../../queries';
import { DeviceFormValues } from '../schema';
import {
    DeviceTypeSelector_SearchQuery,
    DeviceTypeSelector_SearchQuery$data,
} from './__generated__/DeviceTypeSelector_SearchQuery.graphql';

type SearchResultType = DeviceTypeSelector_SearchQuery$data['deviceTypes']['data'];
export interface DeviceTypeSelectorProps {
    name: string;
}

export const DeviceTypeSelector: FC<DeviceTypeSelectorProps> = ({ name }) => {
    const environment = useRelayEnvironment();
    const { show } = useToast();

    const { typeIdToNameMap, deviceCategories, typeIdToCategoryMap } = useDeviceTypes();
    const { values, touched, setFieldValue } = useFormikContext<DeviceFormValues>();
    const [searchResults, setSearchResults] = useState<SearchResultType>([]);
    const [deviceTypeListError, setDeviceTypeListError] = useState<string | null>(null);

    useEffect(() => {
        // Pre-populate search results, so there is something to select by default.
        // TODO: it would be better to show the most recently selected options instead of just any
        handleSearch('', true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = useCallback(
        (search: string, force = false) => {
            if (search.length === 0 && !force) {
                return;
            }

            fetchQuery<DeviceTypeSelector_SearchQuery>(environment, deviceTypeSearchQuery, { search }).subscribe({
                next(value) {
                    setSearchResults(value.deviceTypes.data);
                    setDeviceTypeListError(null);
                },
                error(error: unknown) {
                    captureException(error);
                    show({ variant: 'error', text: 'Failed to get device types' });
                    setSearchResults([]);
                    setDeviceTypeListError('Unable to search at this time');
                },
            });
        },
        [environment, show]
    );

    useEffect(() => {
        if (touched.category && values.category !== null) {
            setFieldValue(name, '', false);
            handleSearch('', true);
        }
    }, [handleSearch, name, setFieldValue, touched.category, values.category]);

    return (
        <>
            <div className='col-start-1'>
                <FormikGeneralSelect<DeviceCategory>
                    name='category'
                    variant='outlined'
                    placeHolder='Device Category*'
                    items={deviceCategories.sort()}
                    renderItem={category => formatDeviceCategory(category)}
                    light
                    testId='device-category'
                />
            </div>
            <div className='col-start-1'>
                <FormikDropdownWithSearch<string>
                    key={`type-${values.category}`}
                    name={name}
                    variant='outlined'
                    placeHolder='Device Type*'
                    items={searchResults
                        .map(result => result.id)
                        .filter((typeId: string) => typeIdToCategoryMap[typeId as DeviceCategory] === values.category)
                        .slice(0, SUGGESTIONS_LIMIT)}
                    renderItemAsString={item => typeIdToNameMap[item]}
                    renderItem={typeId => typeIdToNameMap[typeId]}
                    onSearch={handleSearch}
                    noResultsText={search => {
                        if (deviceTypeListError) {
                            return deviceTypeListError;
                        }

                        if (values.category === '') {
                            return 'Select a category first';
                        }

                        if (search.length > 0) {
                            return 'No matching device types';
                        }

                        return 'No device types available';
                    }}
                    light
                    testId='device-type'
                />
            </div>
        </>
    );
};

function useDeviceTypes() {
    // FIXME: Use useLazyLoadQuery, but that suspends
    const { data } = useQuery<queries_GetDeviceTypesQuery>(
        getDeviceTypesQuery,
        {},
        { fetchPolicy: 'store-or-network' }
    );

    const typeIdToNameMap = useMemo(() => {
        const deviceTypes = data?.deviceTypes.data ?? [];
        const map: Record<string, string> = {};

        for (const deviceType of deviceTypes) {
            map[deviceType.id] = deviceType.displayName;
        }
        return map;
    }, [data?.deviceTypes.data]);

    const deviceCategories = useMemo(() => {
        const deviceTypes = data?.deviceTypes.data ?? [];
        const categories = new Set<DeviceCategory>();

        for (const deviceType of deviceTypes) {
            categories.add(deviceType.category);
        }

        return Array.from(categories);
    }, [data?.deviceTypes.data]);

    const typeIdToCategoryMap = useMemo(() => {
        const deviceTypes = data?.deviceTypes.data ?? [];
        const map: Record<string, DeviceCategory> = {};

        for (const deviceType of deviceTypes) {
            map[deviceType.id] = deviceType.category;
        }

        return map;
    }, [data?.deviceTypes.data]);

    return { typeIdToNameMap, deviceCategories, typeIdToCategoryMap };
}

function formatDeviceCategory(category: DeviceCategory): string {
    switch (category) {
        case 'PowerController':
            return 'Power Controller';
        case 'Gateway':
        case 'Generator':
        default:
            return category;
    }
}

const deviceTypeSearchQuery = graphql`
    query DeviceTypeSelector_SearchQuery($search: String) {
        deviceTypes(search: $search) {
            data {
                id
                category
                displayName
            }
        }
    }
`;
