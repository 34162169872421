import React, { FC, SetStateAction } from 'react';

import { FieldError, TextFieldList } from '@accesstel/pcm-ui';

import { useField } from 'formik';
import { getQualifiedName } from 'lib/namespaces';

export interface AddressesProps {
    namespace?: string;
}

export const Addresses: FC<AddressesProps> = ({ namespace }) => {
    const [, meta, helpers] = useField<string[]>(getQualifiedName('addresses', namespace));

    const onRemove = (idx: number) => {
        helpers.setValue(meta.value.filter((_, i) => i !== idx));
    };

    const onSet = (items: SetStateAction<string[]>) => {
        if (typeof items === 'function') {
            helpers.setValue(items(meta.value));
        } else {
            helpers.setValue(items);
        }
    };

    return (
        <>
            <TextFieldList
                items={meta.value}
                setItems={onSet}
                onRemoveItem={onRemove}
                placeholder='IP Address(es) / Hostname(s)*'
                required={meta.value.length === 0}
                variant='outlined'
                testId='ip-address'
            />
            <FieldError name={getQualifiedName('addresses', namespace)} />
        </>
    );
};
