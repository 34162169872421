import { useLazyLoadQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { DeviceCategory, ProtocolType, deviceTypesQuery } from './__generated__/deviceTypesQuery.graphql';

export interface DeviceTypeProtocol {
    id: string;
    type: ProtocolType;
    displayName: string;
}

interface DeviceTypeConnectivity {
    ipEnabled: boolean;
    protocols: DeviceTypeProtocol[];
}

export interface DeviceType {
    id: string;
    category: DeviceCategory;
    displayName: string;
    connectivity: DeviceTypeConnectivity;
}

export function useDeviceTypes(): DeviceType[] {
    const data = useLazyLoadQuery<deviceTypesQuery>(
        DeviceTypesQuery,
        {},
        {
            fetchPolicy: 'store-or-network',
        }
    );

    return data.deviceTypes.data as DeviceType[];
}

const DeviceTypesQuery = graphql`
    query deviceTypesQuery {
        deviceTypes {
            data {
                id
                category
                displayName
                connectivity {
                    ipEnabled
                    protocols {
                        id
                        type
                        displayName
                    }
                }
            }
        }
    }
`;
