import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import {
    ArrowDownIcon,
    ArrowLeftIcon,
    FilterIcon,
    HistoryItem,
    History as HistoryLine,
    Pill,
    useToast,
} from '@accesstel/pcm-ui';

import { captureException, captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useUserPermissions } from 'lib/auth';
import { exportAsCsv } from 'lib/csv-export';
import { getHumanizedTextFromDuration } from 'lib/dateFormatter';
import { getGlobalEnvironment } from 'lib/environment';
import { HistoryExportItem, createHistoryItem, humanizeActivityLogSource } from 'lib/history';
import { useHistoryState } from 'lib/saved-state';
import { DateTime } from 'luxon';

import { useConfirmationModal } from '../../../../../lib/confirmation';
import { DevicePane } from '../../components/DevicePane';
import { DefaultHistorySize, DefaultHistoryStep, DefaultHistoryTypes } from '../../settings';
import { HistorySkeleton } from './HistorySkeleton';
import {
    ActivityLogType,
    HistoryContentQuery,
    HistoryContentQuery$variables,
} from './__generated__/HistoryContentQuery.graphql';
import { humanizeBatteryTestEvent } from './components/BatteryTestPane';
import { humanizeGridEventStatusEvent } from './components/GridEventStatusPane';
import { humanizeInsightEvent } from './components/InsightSeverityPane';
import { humanizeInventoryEventType } from './components/InventoryEventPane';
import {
    BatteryTestTypes,
    GridEventTypes,
    HistoryCategory,
    InsightTypes,
    InventoryTypes,
    SelectCategoriesPane,
} from './components/SelectCategoryPane';
import style from './style.module.css';

export interface HistoryContentProps {
    queryRef: PreloadedQuery<HistoryContentQuery>;
    deviceId: string;
}

const CsvHeader = ['Source', 'Type', 'Timestamp', 'UserId', 'UserName', 'Action', 'DeviceId', 'DeviceName'];

const clearButton = (
    <div className={style.back_button}>
        <ArrowLeftIcon />
    </div>
);

// NOTE: No live data as history do not change as frequently (alert, tests, outages might be useful to live refresh)
// NOTE: There will never be an empty history as "device_added" event always exists
export const HistoryContent: React.FC<HistoryContentProps> = ({ deviceId, queryRef }) => {
    const environment = useRelayEnvironment();
    const { show } = useToast();
    const { hasTasksRead, hasAssetsRead } = useUserPermissions();
    const [isFetching, setIsFetching] = useState(false);
    const [storedInitialState, setStoredInitialState] = useHistoryState('history-line', {
        filters: DefaultHistoryTypes,
    });
    const initialData = usePreloadedQuery(HistoryQuery, queryRef);

    const [limit, setLimit] = useState(DefaultHistorySize);
    const [isHistoryFilterPaneOpen, setIsHistoryFilterPaneOpen] = useState(false);
    const [data, setData] = useState(initialData);

    const [showModal, modalComponent] = useConfirmationModal();

    // This is only to handle the case where the preloadedQuery has a different filter than the saved state
    // FIXME: PCM-1975 - Once this has been implemented, remove this useEffect and let the loader peek the filters in the URL and invoke the request accordingly
    useEffect(() => {
        if (
            storedInitialState.filters.length === DefaultHistoryTypes.length &&
            storedInitialState.filters.every(filter => DefaultHistoryTypes.includes(filter))
        ) {
            // Do nothing
        } else {
            setIsFetching(true);
            const refetchVariables: HistoryContentQuery$variables = {
                id: deviceId,
                limit: DefaultHistorySize,
                types: storedInitialState.filters,
            };
            fetchQuery<HistoryContentQuery>(environment, HistoryQuery, refetchVariables).subscribe({
                next(value) {
                    setData(value);
                    setIsFetching(false);
                },
                error(error: unknown) {
                    captureException(error, scope => {
                        scope.setExtra('variables', refetchVariables);
                        scope.setTag('Component', 'HistoryContent');
                        return scope;
                    });
                },
            });
        }
        // Only run this effect once on mount (to handle filters mismatch between preloadedQuery vs saved state)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFilterPills = useCallback(() => {
        const filterPills: { category: HistoryCategory; type: string; rawType: string }[] = [];

        if (!storedInitialState.filters) {
            return filterPills;
        }

        storedInitialState.filters.forEach(type => {
            const category = getCategoryFromType(type);
            if (category) {
                const humanizedType = humanizeHistoryType(type, category);
                filterPills.push({ category, type: humanizedType, rawType: type });
            }
        });

        return filterPills;
    }, [storedInitialState.filters]);

    if (!data.activityLogs) {
        return null;
    }

    if (!data.device) {
        // This should never happen as the DeviceLayout validates devices
        captureMessage('Assertion failed: device is null in HistoryContent', scope => {
            scope.setExtra('data', data);
            scope.setTag('Component', 'HistoryContent');
            return scope;
        });
        return null;
    }

    const primaryName = data.device.name;
    let secondaryName: string;
    let title: string;
    const isDualPlaneSetup = !!data.device.dualPlaneCompanion?.device;
    if (isDualPlaneSetup) {
        secondaryName = data.device.dualPlaneCompanion.device.name;

        if (secondaryName < primaryName) {
            title = `${secondaryName} & ${primaryName}`;
        } else {
            title = `${primaryName} & ${secondaryName}`;
        }
    } else {
        title = primaryName;
    }

    const items: HistoryItem[] = data.activityLogs.data.map(log => {
        const item = createHistoryItem(log, hasAssetsRead, hasTasksRead);

        return {
            ...item,
            device: isDualPlaneSetup ? item.device : undefined, // No need to show device name if it is not a dual plane device (reduce clutter)
        };
    });

    const itemsWithGroupName = items.map(item => {
        const groupName = getHumanizedTextFromDuration(
            DateTime.fromJSDate(item.timestamp).diffNow().as('milliseconds')
        );

        return { ...item, group: groupName ?? undefined };
    });

    const itemGroups: { label?: string; items: HistoryItem[] }[] = [];
    const uniqueGroupNames = new Set(itemsWithGroupName.map(item => item.group));
    uniqueGroupNames.forEach(groupName => {
        const items = itemsWithGroupName.filter(item => item.group === groupName);
        itemGroups.push({ label: groupName, items });
    });

    const exportActivityLogs = async () => {
        const exportVariables: HistoryContentQuery$variables = {
            id: deviceId,
            limit: 10_000,
            types: storedInitialState.filters.length > 0 ? storedInitialState.filters : DefaultHistoryTypes,
        };

        try {
            const results = await fetchQuery<HistoryContentQuery>(
                environment,
                HistoryQuery,
                exportVariables
            ).toPromise();

            if (!results) {
                show({ text: 'Failed to export activity logs', variant: 'error' });
            }

            const data: HistoryExportItem[] = results!.activityLogs.data.map(log => {
                const historyItem = createHistoryItem(log, hasAssetsRead, hasTasksRead);

                const userName =
                    log.source === 'Alert' || log.source === 'GridEvent' ? 'accata' : historyItem.user?.name;
                const userId =
                    log.source === 'Alert' || log.source === 'GridEvent' ? 'accata' : historyItem.user?.username;

                return {
                    source: humanizeActivityLogSource(log.source),
                    type: log.type,
                    timestamp: log.timestamp,
                    user: { name: userName ?? '', id: userId },
                    action: historyItem.actionMessage,
                    device: { id: historyItem.device?.id ?? '', name: historyItem.device?.name ?? '' },
                };
            });

            const fileName = `activity-logs-${title.replaceAll(' ', '')}.csv`;
            const csvEntry = data.map(item => [
                item.source,
                item.type,
                item.timestamp,
                item.user.id,
                item.user.name,
                item.action,
                item.device.id,
                item.device.name,
            ]);

            exportAsCsv(fileName, CsvHeader, csvEntry);
        } catch (error) {
            captureException(error, scope => {
                scope.setExtra('variables', exportVariables);
                scope.setTag('Component', 'HistoryContent');
                return scope;
            });
            show({ text: 'Failed to export activity logs', variant: 'error' });
        }
    };

    const loadMore = (rawTypes: ActivityLogType[], resetLimit = false) => {
        const newLimit = resetLimit ? DefaultHistorySize : limit + DefaultHistoryStep;
        const types = rawTypes.length > 0 ? rawTypes : DefaultHistoryTypes; // Never load empty history

        const refetchVariables: HistoryContentQuery$variables = {
            id: deviceId,
            limit: newLimit,
            types: types,
        };

        setLimit(newLimit);
        setStoredInitialState({ filters: types });

        fetchQuery<HistoryContentQuery>(environment, HistoryQuery, refetchVariables).subscribe({
            next(value) {
                setData(value);
            },
            error(error: unknown) {
                captureException(error, scope => {
                    scope.setExtra('variables', refetchVariables);
                    scope.setTag('Component', 'HistoryContent');
                    return scope;
                });
            },
        });
    };

    const filterPillsArea: ReactNode = (
        <div>
            <span
                role='button'
                className={style.reset_filters_button}
                onClick={() => {
                    showModal({
                        title: 'Reset Filters',
                        content: 'Are you sure you want to reset the filters?',
                        onResult: async () => {
                            loadMore(DefaultHistoryTypes, true);
                        },
                    });
                }}
            >
                Reset Filters
            </span>
            <div className={style.pills_container}>
                {getFilterPills().map(({ category, type, rawType }) => (
                    <Pill
                        key={`filter-pill-${rawType}`}
                        iconBackgroundColor='gray'
                        customIconColor='text-eggplantLight'
                        value={type}
                        prefix={category}
                        valueRole='text'
                        onIconClick={() => {
                            if (storedInitialState.filters) {
                                const newFilters = storedInitialState.filters.filter(filter => filter !== rawType);
                                loadMore(newFilters, true);
                            }
                        }}
                    />
                ))}
            </div>
        </div>
    );

    if (isFetching) {
        return <HistorySkeleton />;
    }

    return (
        <DevicePane title={title} combinedView>
            <HistoryLine
                title='History'
                actions={[
                    {
                        buttonText: 'Add Filter',
                        buttonIcon: <FilterIcon />,
                        onClick: () => setIsHistoryFilterPaneOpen(true),
                        embeddedComponent: isHistoryFilterPaneOpen && (
                            <SelectCategoriesPane
                                current={storedInitialState.filters ?? []}
                                onClearOrBack={() => {
                                    loadMore([], true);
                                    setIsHistoryFilterPaneOpen(false);
                                }}
                                onSubmit={(submittedTypes, category) => {
                                    const currentFilters = storedInitialState.filters;
                                    const updatedFilters = currentFilters.filter(
                                        filter => getCategoryFromType(filter) !== category
                                    );
                                    loadMore([...updatedFilters, ...submittedTypes], true);
                                    setIsHistoryFilterPaneOpen(false);
                                }}
                                onClose={() => setIsHistoryFilterPaneOpen(false)}
                                clearButton={clearButton}
                            />
                        ),
                    },
                    {
                        buttonText: 'Export',
                        buttonIcon: <ArrowDownIcon />,
                        onClick: exportActivityLogs,
                    },
                ]}
                lineStyle='long'
                itemGroups={itemGroups}
                moreItems={data.activityLogs.hasMore}
                loadMoreCallback={() => loadMore(storedInitialState.filters)}
                headerArea={filterPillsArea}
            />
            {modalComponent}
        </DevicePane>
    );
};

export const HistoryQuery = graphql`
    query HistoryContentQuery($id: ID!, $limit: Int!, $types: [ActivityLogType!]!) {
        activityLogs(device: $id, includeCompanionDevices: true, types: $types, limit: $limit) {
            hasMore
            total
            data {
                source
                type
                timestamp
                user {
                    username
                    name
                }
                changes {
                    field
                    oldValue
                    newValue
                }
                link {
                    __typename
                    ... on Device {
                        id
                        name
                    }
                    ... on Alert {
                        severity
                        category
                        message
                        isActive
                        device {
                            id
                            name
                        }
                    }
                    ... on ACPowerEvent {
                        worstStatus
                        duration
                        affectedAllFeeds
                        affectedFeeds {
                            id
                            status
                        }
                        device {
                            id
                            name
                        }
                    }
                    ... on DeviceBatteryTestResults {
                        id
                        commencedTime
                        state
                        task {
                            id
                            name
                            type
                            testState
                            abortedUser {
                                username
                                name
                            }
                        }
                        device {
                            id
                            name
                        }
                    }
                    ... on BatteryTest {
                        id
                        commencedTime
                        taskName: name
                    }
                }
            }
        }
        device(id: $id) {
            name
            dualPlaneCompanion {
                device {
                    name
                }
            }
        }
    }
`;

export function loadHistoryPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        HistoryQuery,
        {
            id,
            limit: DefaultHistorySize,
            types: DefaultHistoryTypes,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}

function getCategoryFromType(type: ActivityLogType): HistoryCategory | null {
    if (InventoryTypes.includes(type)) {
        return HistoryCategory.Inventory;
    } else if (BatteryTestTypes.includes(type)) {
        return HistoryCategory.BatteryTest;
    } else if (InsightTypes.includes(type)) {
        return HistoryCategory.Insight;
    } else if (GridEventTypes.includes(type)) {
        return HistoryCategory.GridEvent;
    } else {
        return null;
    }
}

function humanizeHistoryType(type: ActivityLogType, category: HistoryCategory): string {
    let humanizedType: string | null = null;
    if (category === HistoryCategory.BatteryTest) {
        humanizedType = humanizeBatteryTestEvent(type);
    } else if (category === HistoryCategory.GridEvent) {
        humanizedType = humanizeGridEventStatusEvent(type);
    } else if (category === HistoryCategory.Insight) {
        humanizedType = humanizeInsightEvent(type);
    } else if (category === HistoryCategory.Inventory) {
        humanizedType = humanizeInventoryEventType(type);
    }

    return humanizedType ?? type;
}
