import { AttributeTableColumn } from 'filters/common';

// IMPORTANT: Value has to be unique and cannot have spaces
export enum SiteTableColumn {
    Name = 'Name',
    Type = 'Type',
    State = 'State',
    Postcode = 'Postcode',
    DeviceCount = 'DeviceCount',
    BatteryStatus = 'BatteryStatus',
    BatteryCapacity = 'BatteryCapacity',
    BatteryTotalTimeRemaining = 'BatteryTotalTimeRemaining',
    DeviceStatus = 'DeviceStatus',

    Mtbf = 'ACMtbf',
    Mttr = 'ACMttr',
    ACReliability = 'ACReliability',
    IncidentCount = 'ACIncidentCount',
    OutageDurationSum = 'ACOutageDurationSum',
    ACRisk = 'ACRisk',
}

export type SiteTableColumnId = SiteTableColumn | AttributeTableColumn;

// IMPORTANT: Value has to be unique and cannot have spaces
export enum SiteExtraFilters {
    DeviceName = 'DeviceName',
    DeviceWithBatteries = 'DevicesWithBatteries',
    ACReliabilityTimeRange = 'ACReliabilityTimeRange',
}

export enum DeviceHealthStatus {
    Critical = 'Critical',
    Degraded = 'Degraded',
    Healthy = 'Healthy',
    Offline = 'Offline',
    Unknown = 'Unknown',
}

export interface SiteNameFilter {
    name: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    id?: string;
    state?: string;
}

export interface SiteTypeFilter {
    name: string;
    id: string;
}
export interface SiteStateFilter {
    state: string;
}

export enum SiteDeviceBatteries {
    All = 'All',
    Partial = 'Partial',
    None = 'None',
}

export interface SitePostcodeFilter {
    id: string;
    value: string;
}

export interface SiteDeviceStatusFilter {
    id: DeviceHealthStatus;
    displayText: string;
}

export const DeviceHealthStatusNames: Record<DeviceHealthStatus, string> = {
    [DeviceHealthStatus.Critical]: 'Critical',
    [DeviceHealthStatus.Degraded]: 'Degraded',
    [DeviceHealthStatus.Healthy]: 'Healthy',
    [DeviceHealthStatus.Offline]: 'Offline',
    [DeviceHealthStatus.Unknown]: 'Unknown',
};
