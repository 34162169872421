import React, { FC, useCallback } from 'react';
import { useFragment, useMutation } from 'react-relay';

import { CircleStopIcon, InlineTextField, Menu, MenuItem, StatusLabel, UserIcon2, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import { batteryTestStateToStatusLabelConfig } from 'lib/statusLabelFormatter';
import { formatAsCauseSentence, getNiceStatusName } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import { useUserPermissions } from '../../../../../lib/auth';
import { logError } from '../../../../../lib/log';
import { formatAPIDate } from '../common';
import { DateDisplay } from './DateDisplay';
import { TestResultHeaderUpdateNameMutation } from './__generated__/TestResultHeaderUpdateNameMutation.graphql';
import { TestResultHeader_test$data, TestResultHeader_test$key } from './__generated__/TestResultHeader_test.graphql';

interface TestResultHeaderProps {
    test: TestResultHeader_test$key;
    otherTest?: TestResultHeader_test$key | null;
    menuItems: MenuItem[];
}

export const TestResultHeader: FC<TestResultHeaderProps> = ({ test, otherTest, menuItems }) => {
    const { hasTasksWrite } = useUserPermissions();

    const primaryResult = useFragment<TestResultHeader_test$key>(TestResultHeaderFragment, test);
    const secondaryResult = useFragment<TestResultHeader_test$key>(TestResultHeaderFragment, otherTest ?? null);

    const [updateBatteryTestName] = useMutation<TestResultHeaderUpdateNameMutation>(UpdateNameMutation);

    const { show } = useToast();

    const handleSaveTestName = useCallback(
        (newName: string | undefined) => {
            const sanitisedName = newName && newName.trim().length !== 0 ? newName.trim() : undefined;

            updateBatteryTestName({
                variables: {
                    id: primaryResult.id,
                    name: sanitisedName,
                },
                optimisticUpdater(store) {
                    // Preemptively update the store so that the new name is reflected immediately

                    const testResultRecord = store.get(primaryResult.id);
                    if (testResultRecord) {
                        if (sanitisedName) {
                            testResultRecord.setValue(sanitisedName, 'name');
                        } else {
                            testResultRecord.setValue(`Results for ${primaryResult.device.name}`, 'name');
                        }
                    }
                },
                updater(store) {
                    // FIXME: Only update the store if the response was success
                    // Cant seem to get the response value. getRootField("editBatteryTestName") errors because the field 'doesnt exist'.
                    // All docs and the error message itself point back to this way, so no idea why that doesnt work here.
                    // Alternatively trying to get the response using store.getRoot().getValue('editBatteryTestName') just returns undefined.

                    const testResultRecord = store.get(primaryResult.id);
                    if (testResultRecord) {
                        if (sanitisedName) {
                            testResultRecord.setValue(sanitisedName, 'name');
                        } else {
                            testResultRecord.setValue(`Results for ${primaryResult.device.name}`, 'name');
                        }
                    }
                },
                onError(error) {
                    logError('Error updating test name', error);
                    captureException(error, scope => {
                        scope.setTag('Component', 'TestResultHeader');
                        scope.setTag('Function', 'updateBatteryTestName');
                        scope.setExtra('id', primaryResult.id);
                        scope.setExtra('name', newName);
                        return scope;
                    });
                    show({
                        text: 'Failed to update test name',
                        variant: 'error',
                    });
                },
                onCompleted(result) {
                    if (result.editBatteryTestName === 'InvalidValue') {
                        show({
                            text: 'Invalid test name',
                            variant: 'error',
                        });
                    } else if (result.editBatteryTestName !== 'Success') {
                        show({
                            text: 'Failed to update test name',
                            variant: 'error',
                        });
                    }
                },
            });
        },
        [primaryResult.device.name, primaryResult.id, show, updateBatteryTestName]
    );

    const isAccataTest = primaryResult.task != null;

    let startTime: DateTime;
    let endTime: DateTime | undefined;

    [startTime, endTime] = getStartAndEndTimes(primaryResult);

    if (secondaryResult) {
        const [secondaryStartTime, secondaryEndTime] = getStartAndEndTimes(secondaryResult);

        startTime = startTime < secondaryStartTime ? startTime : secondaryStartTime;
        endTime = endTime && secondaryEndTime && endTime > secondaryEndTime ? endTime : secondaryEndTime;
    }

    let bestCause = primaryResult.cause;
    if (secondaryResult) {
        if (bestCause === 'CompanionDischarge') {
            bestCause = secondaryResult.cause;
        }
    }

    let bestState = primaryResult.state;
    if (secondaryResult) {
        const secondaryState = secondaryResult.state;

        // Display in-progress if one of the tests is in progress
        if (secondaryState === 'InProgress') {
            bestState = secondaryState;
        }

        // Display failed if one of the tests is failed
        if (secondaryState === 'Failed') {
            bestState = secondaryState;
        }

        // Display passed if one of the tests is passed
        if (secondaryState === 'Passed') {
            bestState = secondaryState;
        }

        // Otherwise display the primary state
    }

    let creatorName = primaryResult.task?.creator.name ?? primaryResult.task?.creator.email;
    if (secondaryResult && !creatorName) {
        creatorName = secondaryResult.task?.creator.name ?? secondaryResult.task?.creator.email;
    }

    let title: string;
    let subtitle: string;
    let initialEditValue: string;

    if (primaryResult.name && !secondaryResult) {
        title = primaryResult.name;
        initialEditValue = title;
        subtitle = `Caused by ${formatAsCauseSentence(bestCause)}`;
    } else {
        if (isAccataTest) {
            title = primaryResult.task?.name ?? '';
            initialEditValue = title;
            subtitle = `Results for ${primaryResult.device.name}`;
        } else {
            if (secondaryResult) {
                title = `Combined results for both planes`;
            } else {
                title = `Results for ${primaryResult.device.name}`;
            }
            subtitle = `Caused by ${formatAsCauseSentence(bestCause)}`;
            initialEditValue = '';
        }
    }

    const uniqueMenuGroups = new Set<string>(menuItems.filter(item => item.group).map(item => item.group as string));
    const groupDefinition = Array.from(uniqueMenuGroups).map(group => ({
        key: group,
        title: group,
    }));
    const canEditTitle = hasTasksWrite && !secondaryResult && !isAccataTest;

    return (
        <div className='px-8'>
            <div className='flex flex-row justify-between items-start relative gap-4'>
                <div className='flex-grow min-w-0'>
                    {!canEditTitle && <div className='text-3xl truncate'>{title}</div>}
                    {canEditTitle && (
                        <InlineTextField
                            value={title}
                            initialEditValue={initialEditValue}
                            className='text-3xl'
                            onEdit={handleSaveTestName}
                            noWrap
                            maxLength={50}
                        />
                    )}
                    <div className='font-light'>{subtitle}</div>
                </div>
                <div className='flex flex-row space-x-2'>
                    <StatusLabel
                        label={getNiceStatusName(bestState).toUpperCase()}
                        {...batteryTestStateToStatusLabelConfig(bestState)}
                    />
                    <Menu id='test-props-menu' menuItems={menuItems} groups={groupDefinition ?? undefined} />
                </div>
            </div>
            <div className='flex gap-6 mt-4'>
                <DateDisplay
                    startTime={startTime.toJSDate()}
                    endTime={endTime ? endTime.toJSDate() : endTime}
                    future={primaryResult.state === 'Scheduled'}
                />
                {creatorName && (
                    <IconWithStatus
                        icon={<UserIcon2 />}
                        // TODO: Put link here to see what else the user has done
                        label={`Scheduled by ${creatorName ?? 'Unknown'}`}
                    />
                )}
                {!creatorName && <IconWithStatus icon={<UserIcon2 />} label={`Scheduled automatically`} />}
            </div>
            {primaryResult.abortedBy && primaryResult.abortedTime && (
                <div className='flex gap-6 text-coralRegular'>
                    <IconWithStatus
                        icon={<CircleStopIcon />}
                        label={`Aborted at ${formatAPIDate(primaryResult.abortedTime)} by ${
                            primaryResult.abortedBy.name ?? primaryResult.abortedBy.email ?? 'Unknown'
                        }`}
                    />
                </div>
            )}
        </div>
    );
};

function getStartAndEndTimes(test: TestResultHeader_test$data): [DateTime, DateTime | undefined] {
    let startTime: DateTime;
    let endTime: DateTime | undefined;

    switch (test.state) {
        case 'Scheduled':
            startTime = DateTime.fromISO(test.task!.schedule!.time as string);
            endTime = DateTime.now();
            break;
        case 'Aborted':
        case 'Failed':
        case 'Inconclusive':
        case 'Finalizing':
        case 'Analyzing':
        case 'Passed':
            startTime = DateTime.fromISO(test.commencedTime as string);
            endTime = DateTime.fromISO(test.completedTime as string);
            break;
        case 'InProgress':
        case 'Waiting':
            startTime = DateTime.fromISO(test.commencedTime as string);
            break;
        default:
            if (test.task?.createdTime) {
                startTime = DateTime.fromISO(test.task?.createdTime as string);
            } else {
                // This is should not happen unless we forgot a state
                startTime = DateTime.now();
            }
            endTime = DateTime.now();
            break;
    }

    return [startTime, endTime];
}

const TestResultHeaderFragment = graphql`
    fragment TestResultHeader_test on DeviceBatteryTestResults {
        id
        state
        name
        task {
            id
            name
            createdTime
            creator {
                name
                email
            }
            schedule {
                time
            }
        }
        commencedTime
        completedTime
        cause
        abortedTime
        abortedBy {
            name
            email
        }
        device {
            name
        }
    }
`;

const UpdateNameMutation = graphql`
    mutation TestResultHeaderUpdateNameMutation($id: ID!, $name: String) {
        editBatteryTestName(id: $id, name: $name)
    }
`;
