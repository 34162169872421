import React, { FC, useCallback, useState } from 'react';

import { Link, ListView, PowerIcon, useToast } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterValueMap } from 'filters/common';
import { SiteIncidentExtraFilters, SiteIncidentTableColumn } from 'filters/site-incidents';
import { SiteIncidentAllFilterMap, StaticSiteIncidentFilterDefinitions } from 'filters/site-incidents/settings';
import { Paths } from 'lib/routes';
import { encodeFilterParameters } from 'lib/table-filter';
import { DateTime } from 'luxon';
import { useTimeRangeFromSearch } from 'views/reports/ac-power/common';
import { TimeRangeDurations } from 'views/reports/ac-power/settings';

import { logError } from '../../../../../../lib/log';
import { useReloadableFragment } from '../../../../../../lib/query-helpers';
import { IncidentRow } from './IncidentRow';
import { IncidentListQuery } from './__generated__/IncidentListQuery.graphql';
import { IncidentList_acPower$key } from './__generated__/IncidentList_acPower.graphql';

export interface IncidentListProps {
    siteId: string;
    fragmentRef: IncidentList_acPower$key;
}

export const IncidentList: FC<IncidentListProps> = ({ siteId, fragmentRef }) => {
    const { show } = useToast();
    const timeRange = useTimeRangeFromSearch();

    const [{ events }, reload] = useReloadableFragment<IncidentList_acPower$key, IncidentListQuery>(
        Fragment,
        fragmentRef,
        LoadQuery,
        data => data.site?.acPower
    );

    const [showAllIncidents, setShowIncidents] = useState(false);

    const handleToggleShowIncidents = useCallback(() => {
        setShowIncidents(value => !value);

        const duration = TimeRangeDurations[timeRange];
        let startRange = undefined;

        if (duration != null) {
            const endDate = DateTime.local().startOf('day');
            const startDate = endDate.minus(duration);

            startRange = {
                min: startDate.toISO(),
                max: endDate.toISO(),
            };
        }

        reload({
            siteId: siteId,
            showSignificant: showAllIncidents ? { value: true } : null, // This is inverted because we want to show the opposite of what we currently have
            startRange: startRange,
        }).catch(error => {
            show({
                text: 'Unable to load incidents. Please try again later',
                variant: 'error',
            });
            logError(error);
        });
    }, [timeRange, reload, siteId, showAllIncidents, show]);

    return (
        <>
            <ListView
                title={showAllIncidents ? 'All incidents' : 'Significant incidents'}
                view='list'
                listDisplay='grid'
                contentClassName='grid-rows-5'
                actions={[
                    {
                        buttonIcon: <PowerIcon />,
                        buttonText: showAllIncidents ? 'Show only significant' : 'Show all incidents',
                        onClick: handleToggleShowIncidents,
                    },
                ]}
            >
                {events.data.map((event, index) => (
                    <IncidentRow key={index} event={event} />
                ))}
                {events.data.length === 0 && (
                    <div className='grid place-items-center' style={{ height: '60px' }}>
                        There are no incidents present
                    </div>
                )}
            </ListView>
            <Link
                to={{
                    pathname: Paths.ReportACPowerSiteIncidentList,
                    params: {
                        siteId,
                    },
                    search: {
                        ...encodeFilterParameters<SiteIncidentTableColumn, FilterValueMap<SiteIncidentAllFilterMap>>(
                            {
                                [SiteIncidentExtraFilters.IsSignificant]: !showAllIncidents
                                    ? {
                                          value: true,
                                          label: 'Yes',
                                      }
                                    : null,
                            },
                            StaticSiteIncidentFilterDefinitions
                        ),
                    },
                }}
                className='text-coralRegular float-right mt-2'
            >
                See more
            </Link>
        </>
    );
};

const Fragment = graphql`
    fragment IncidentList_acPower on SiteACPower
    @argumentDefinitions(showSignificant: { type: "BooleanFilter" }, startRange: { type: "DateRangeFilter" }) {
        events(
            filters: { isSignificant: $showSignificant, startTime: $startRange }
            orderBy: { field: StartTime, dir: Desc }
            pageSize: 5
        ) {
            data {
                ...IncidentRow_event
            }
        }
    }
`;

const LoadQuery = graphql`
    query IncidentListQuery($siteId: ID!, $showSignificant: BooleanFilter, $startRange: DateRangeFilter) {
        site(id: $siteId) {
            acPower {
                ...IncidentList_acPower @arguments(showSignificant: $showSignificant, startRange: $startRange)
            }
        }
    }
`;
