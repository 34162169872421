/**
 * @generated SignedSource<<c3491ae22658af98ed1589579ad1e9a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GeneratorRunReportState = "Analysing" | "Completed" | "Error" | "Finishing" | "Running" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RunReportHeader_data$data = {
  readonly generator: {
    readonly id: string;
    readonly site: {
      readonly id: string;
    };
  };
  readonly generatorStartTime: string;
  readonly generatorStopTime: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly state: GeneratorRunReportState;
  readonly " $fragmentType": "RunReportHeader_data";
};
export type RunReportHeader_data$key = {
  readonly " $data"?: RunReportHeader_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunReportHeader_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RunReportHeader_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "generator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "site",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generatorStartTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generatorStopTime",
      "storageKey": null
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};
})();

(node as any).hash = "f450bd3d084c8da46f9865f20e417ab9";

export default node;
