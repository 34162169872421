import React, { FC, useCallback } from 'react';
import { fetchQuery } from 'react-relay';
import { generatePath } from 'react-router-dom';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { DeviceTableColumn, DeviceTableColumnId, useDeviceFilter } from 'filters/device';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { getGlobalEnvironment } from 'lib/environment';
import { Paths } from 'lib/routes';

import { Device, useDeviceColumnDefinitions } from '../../../../lib/tables/device';
import { useDeviceTableQuery } from '../../../../lib/tables/device/request';
import { DeviceTableAllColumns, DeviceTableBaseColumns } from '../settings';
import {
    ACPowerDeviceListSearchQuery,
    ACPowerDeviceListSearchQuery$data,
} from './__generated__/ACPowerDeviceListSearchQuery.graphql';

type DeviceSearchResult = ACPowerDeviceListSearchQuery$data['devices']['data'][number];
const TableStorageKeyPrefix = 'ac-power-devices-table';

export const ACPowerDeviceList: FC = () => {
    useDocumentTitle('Reports - AC Power by device');

    const environment = getGlobalEnvironment();

    const columnDefinitions = useDeviceColumnDefinitions({
        columns: DeviceTableAllColumns,
    });

    const [tableState, dispatchTableState] = useTableReducer<DeviceTableColumnId>({
        defaultSortColumn: DeviceTableColumn.Name,
        defaultSortDirection: SortDirection.Ascending,
        allColumns: columnDefinitions,
        defaultVisibleColumns: DeviceTableBaseColumns,
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useDeviceFilter();
    const { data: props, error, retry, isFetching, fetchTable } = useDeviceTableQuery(tableState, filters);

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<ACPowerDeviceListSearchQuery>(
                environment,
                graphql`
                    query ACPowerDeviceListSearchQuery($name: String = "", $pageSize: Int!) {
                        devices(
                            filters: { name: { value: $name } }
                            pageSize: $pageSize
                            onlyProvisioningStatuses: Active
                        ) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                { name: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => (result?.devices.data as DeviceSearchResult[]) ?? []);
        },
        [environment]
    );

    return (
        <TableLayout
            title='AC Power by Device'
            columns={columnDefinitions}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={props?.devices.data ?? null}
            isProcessing={!!props && isFetching}
            getRowId={(row: Device) => row.id}
            page={props?.devices.pageInfo.page}
            pageCount={props?.devices.pageInfo.total}
            overallCount={props?.overallDevices.total}
            resultCount={props?.devices.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder='Search by device name'
            onSearch={handleSearch}
            renderSearchResultAsString={(item: DeviceSearchResult) => item.name}
            emptyMessage='There are no devices present'
            unit='Device'
            getItemLink={(item: Device) =>
                generatePath(Paths.ReportACPowerSiteViewDevice, { siteId: item.site.id, deviceId: item.id })
            }
            exportEnabled
            exportFilename='devices'
            exportFetchData={options =>
                fetchTable(options).then(results => results.flatMap(result => result.devices.data))
            }
        />
    );
};
