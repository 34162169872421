import React, { FC } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import { MenuItem, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { MenuItemGroup } from 'lib/menu';
import { DateTime, Duration } from 'luxon';

import { useDocumentTitle } from '../../../../../components';
import { AlertBanner } from '../../../../../components/AlertBanner';
import { ExpandableCardHeader } from '../../../../../components/ExpandableCardHeader';
import { useUserPermissions } from '../../../../../lib/auth';
import { getGlobalEnvironment } from '../../../../../lib/environment';
import { Paths } from '../../../../../lib/routes';
import { intervalToHuman, sanitizeTimeRange, useTimeRangeFromSearch } from '../../common';
import { DefaultTimeRange, TimeRangeDurations, TimeRangeSearchParameter } from '../../settings';
import { ACDeviceViewContentQuery } from './__generated__/ACDeviceViewContentQuery.graphql';
import { HeaderDetailContent } from './components/HeaderDetailContent';
import { ACDeviceFeedReliability } from './sections/ACDeviceFeedReliability';
import { ACDeviceIncidents } from './sections/ACDeviceIncidents';
import { ACDeviceReliability } from './sections/ACDeviceReliability';
import { ACDeviceSummary } from './sections/ACDeviceSummary';

export interface ACDeviceViewContentProps {
    queryRef: PreloadedQuery<ACDeviceViewContentQuery>;
}

export const ACDeviceViewContent: FC<ACDeviceViewContentProps> = ({ queryRef }) => {
    const { hasAssetsRead, hasAssetsWrite } = useUserPermissions();
    const { siteId, deviceId } = useParams<{ siteId: string; deviceId: string }>();

    const timeRange = useTimeRangeFromSearch();

    const navigate = useExtendedNavigate();

    const data = usePreloadedQuery(ContentQuery, queryRef);

    useDocumentTitle(data.device ? `AC reliability report for ${data.device.name}` : 'AC Reliability Report');

    if (!data.device) {
        return (
            <div className='col-span-12 h-96'>
                <AlertBanner title='Device not found' message='The device you are looking for does not exist.' />
            </div>
        );
    }

    const { device, settings } = data;

    const timeRangeDescription = intervalToHuman(timeRange, {
        lifetimeOverride: 'Since the device was created',
        prefix: 'For the last ',
        suffix: ' up to today',
    });

    const timeRangeDuration = TimeRangeDurations[timeRange];
    let dateRangeDescription: string;

    if (timeRangeDuration != null) {
        const endDate = DateTime.local().startOf('day');
        const startDate = endDate.minus(timeRangeDuration);

        dateRangeDescription = `Showing from ${startDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} until today`;
    } else {
        dateRangeDescription = 'Showing from when the device was created until today';
    }

    const deviceLevelMenuItems: MenuItem[] = [
        {
            name: hasAssetsWrite ? 'Edit device' : 'View device',
            onClick: () => {
                navigate({ pathname: Paths.EditDevice, params: { id: deviceId! } });
            },
            disabled: !hasAssetsRead,
            group: MenuItemGroup.Assets,
        },
        {
            name: 'View current state',
            onClick: () => {
                navigate({ pathname: Paths.SiteViewViewSiteDevice, params: { siteId: siteId!, deviceId: deviceId! } });
            },
        },
    ];

    const significantIncidentThreshold = Duration.fromISO(settings.acEvents.significanceThreshold);

    return (
        <>
            <ExpandableCardHeader
                className='col-span-12 mb-6'
                title={`AC reliability report for ${device.name}`}
                subtitle={timeRangeDescription}
                detailContent={
                    <div>
                        <div className='font-light'>{dateRangeDescription}</div>
                        <div className='grid grid-cols-2 mt-2'>
                            <HeaderDetailContent fragmentRef={device} timeRange={timeRange} />
                        </div>
                    </div>
                }
                menuItems={deviceLevelMenuItems}
            />
            <ACDeviceSummary acPowerRef={device.acPower} />
            <ACDeviceReliability queryRef={data} reliabilityRef={device.acPower.reliability} timeRange={timeRange} />
            <ACDeviceFeedReliability fragmentRef={device.acPower} timeRange={timeRange} />
            <ACDeviceIncidents
                deviceId={deviceId!}
                siteId={siteId!}
                fragmentRef={device.acPower}
                timeRange={timeRange}
                significantIncidentThreshold={significantIncidentThreshold}
            />
        </>
    );
};

const ContentQuery = graphql`
    query ACDeviceViewContentQuery(
        $deviceId: ID!
        $timeRange: ACTimeRange!
        $startRange: DateRangeFilter
        $begin: Timestamp
        $end: Timestamp
    ) {
        ...DeviceReliabilityChart_query
        device(id: $deviceId) {
            id
            name
            acPower {
                reliability(timeRange: $timeRange) {
                    ...ACDeviceReliability_reliability
                }
                ...ACDeviceSummary_acPower @arguments(startRange: $startRange)
                ...ACDeviceIncidents_acPower @arguments(startRange: $startRange)
                ...ACDeviceFeedReliability_acPower
            }
            ...HeaderDetailContent_device
        }
        settings {
            acEvents {
                significanceThreshold
            }
        }
    }
`;

export function loadACDeviceViewContentQuery(deviceId: string, url: URL) {
    const timeRange = sanitizeTimeRange(url.searchParams.get(TimeRangeSearchParameter) ?? DefaultTimeRange);

    const duration = TimeRangeDurations[timeRange];

    let filter = null;
    let start: string | null = null;
    let end: string | null = null;

    if (duration != null) {
        const endDate = DateTime.local().startOf('day');
        const startDate = endDate.minus(duration);

        filter = {
            min: startDate.toISO(),
            max: endDate.toISO(),
        };

        start = startDate.toISO();
        end = endDate.toISO();
    }

    return loadQuery(getGlobalEnvironment(), ContentQuery, {
        deviceId,
        timeRange,
        startRange: filter,
        begin: start,
        end,
    });
}
