/**
 * @generated SignedSource<<daf7196deac13e2a44b6cad4e8229017>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LoadHistoryTilesQuery$variables = {
  deviceId: string;
  lifetimeEnd: string;
  lifetimeStart: string;
  recentEnd: string;
  recentStart: string;
};
export type LoadHistoryTilesQuery$data = {
  readonly device: {
    readonly load: {
      readonly metrics: {
        readonly lifetimePower: {
          readonly values: ReadonlyArray<{
            readonly timestamp: string;
            readonly value: number | null;
          }>;
        } | null;
        readonly recentPower: {
          readonly values: ReadonlyArray<{
            readonly timestamp: string;
            readonly value: number | null;
          }>;
        } | null;
      };
    };
  } | null;
};
export type LoadHistoryTilesQuery = {
  response: LoadHistoryTilesQuery$data;
  variables: LoadHistoryTilesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lifetimeEnd"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lifetimeStart"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recentEnd"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recentStart"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deviceId"
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TimestampMetric",
    "kind": "LinkedField",
    "name": "values",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timestamp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceLoad",
  "kind": "LinkedField",
  "name": "load",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceLoadMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": "recentPower",
          "args": [
            {
              "kind": "Variable",
              "name": "begin",
              "variableName": "recentStart"
            },
            {
              "kind": "Literal",
              "name": "calendarInterval",
              "value": "Month"
            },
            {
              "kind": "Variable",
              "name": "end",
              "variableName": "recentEnd"
            }
          ],
          "concreteType": "TimeSeriesMetrics",
          "kind": "LinkedField",
          "name": "power",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": "lifetimePower",
          "args": [
            {
              "kind": "Variable",
              "name": "begin",
              "variableName": "lifetimeStart"
            },
            {
              "kind": "Literal",
              "name": "calendarInterval",
              "value": "Year"
            },
            {
              "kind": "Variable",
              "name": "end",
              "variableName": "lifetimeEnd"
            }
          ],
          "concreteType": "TimeSeriesMetrics",
          "kind": "LinkedField",
          "name": "power",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadHistoryTilesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadHistoryTilesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "347bc08f89cdb232c7bd1df872d92ea0",
    "id": null,
    "metadata": {},
    "name": "LoadHistoryTilesQuery",
    "operationKind": "query",
    "text": "query LoadHistoryTilesQuery(\n  $deviceId: ID!\n  $recentStart: Timestamp!\n  $recentEnd: Timestamp!\n  $lifetimeStart: Timestamp!\n  $lifetimeEnd: Timestamp!\n) {\n  device(id: $deviceId) {\n    load {\n      metrics {\n        recentPower: power(begin: $recentStart, end: $recentEnd, calendarInterval: Month) {\n          values {\n            timestamp\n            value\n          }\n        }\n        lifetimePower: power(begin: $lifetimeStart, end: $lifetimeEnd, calendarInterval: Year) {\n          values {\n            timestamp\n            value\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "21c18473f0af599cf9f8ba877b742d31";

export default node;
