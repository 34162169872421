import React, { FC, ReactNode } from 'react';

import { Link, NavigateTo, Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';

const DEFAULT_VARIANT = 'base';
const VARIANTS: Record<string, Variant> = {
    base: 'base',
    lg: 'lg',
};

export type LabelSizes = 'base' | 'lg';
export type LabelFonts = 'regular' | 'semibold';
export type Variant = 'base' | 'lg';

interface IconWithStatusProps {
    icon: ReactNode;
    label: string;
    variant?: Variant;
    containerClasses?: string;
    iconClasses?: string;
    labelClasses?: string;
    title?: string;
    link?: NavigateTo;
}

export const IconWithStatus: FC<IconWithStatusProps> = ({
    icon,
    label,
    variant = DEFAULT_VARIANT,
    containerClasses = '',
    iconClasses = '',
    labelClasses = '',
    title,
    link,
}) => {
    const componentVariant = VARIANTS[variant];

    const labelSize = componentVariant === 'base' ? 'text-base' : 'text-lg';
    // the negative margin class below is so that the text and icon line up properly with the SemiBold text
    const labelFont = componentVariant === 'base' ? 'font-normal' : 'font-bold -mb-1';

    let content: ReactNode;
    if (link) {
        content = (
            <Link className={classNames(labelSize, labelFont, labelClasses, { 'hover:underline': link })} to={link}>
                {label}
            </Link>
        );
    } else {
        content = <div className={classNames(labelSize, labelFont, labelClasses)}>{label}</div>;
    }

    return (
        <Tooltip content={title}>
            <div className={`flex flex-row space-x-2 items-center ${containerClasses}`}>
                <div className={`h-5 w-5 ${iconClasses}`}>{icon}</div>
                {content}
            </div>
        </Tooltip>
    );
};
