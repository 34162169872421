import { handleKiloUnitMetricsDisplay } from 'lib/numberFormatters';
import { formatRangeWithString, formatRangeWithUnit } from 'lib/units';

import {
    FilterDefinition,
    FilterValueMap,
    decodeDateRangeFilter,
    decodeRangeFilter,
    encodeDateRangeFilter,
    encodeRangeFilter,
} from '../common';
import { formatDateFilter, formatDurationFilter } from '../common/util';
import { decodeDeviceNameFilter, encodeDeviceNameFilter } from '../device';
import { CauseFilterUI } from './components/CauseFilterUI';
import { GeneratorReportDeviceNameFilterUI } from './components/GeneratorReportDeviceNameFilterUI';
import { FuelCapacityFilterUI } from './components/GeneratorReportFuelCapacityFilterUI';
import { GeneratorReportModelFilterUI } from './components/GeneratorReportModelFilterUI';
import { GeneratorReportNameFilterUI } from './components/GeneratorReportNameFilterUI';
import { GeneratorReportSerialNumberFilterUI } from './components/GeneratorReportSerialNumberFilterUI';
import {
    GeneratorReportCoolantPressureAvgFilterUI,
    GeneratorReportCoolantPressureMaxFilterUI,
    GeneratorReportCoolantPressureMinFilterUI,
    GeneratorReportCoolantTemperatureAvgFilterUI,
    GeneratorReportCoolantTemperatureMaxFilterUI,
    GeneratorReportCoolantTemperatureMinFilterUI,
    GeneratorReportFuelConsumedTotalFilterUI,
    GeneratorReportFuelConsumptionRateAvgFilterUI,
    GeneratorReportFuelConsumptionRateMaxFilterUI,
    GeneratorReportFuelConsumptionRateMinFilterUI,
    GeneratorReportFuelLevelFinishFilterUI,
    GeneratorReportFuelLevelMaxFilterUI,
    GeneratorReportFuelLevelMinFilterUI,
    GeneratorReportFuelRefuelledTotalFilterUI,
    GeneratorReportOilPressureAvgFilterUI,
    GeneratorReportOilPressureMaxFilterUI,
    GeneratorReportOilPressureMinFilterUI,
    GeneratorReportOilTemperatureAvgFilterUI,
    GeneratorReportOilTemperatureMaxFilterUI,
    GeneratorReportOilTemperatureMinFilterUI,
    GeneratorReportOutputEnergyTotalFilterUI,
    GeneratorReportOutputPowerAvgFilterUI,
    GeneratorReportRpmAvgFilterUI,
    GeneratorReportRpmMaxFilterUI,
    GeneratorReportRpmMinFilterUI,
} from './components/GeneratorReportStatsFilterUI';
import { RunTimeFilterUI } from './components/RunTimeFilterUI';
import { StateFilterUI } from './components/StateFilterUI';
import { TimeFilterUI } from './components/TimeFilterUI';
import { decodeCauseFilter, decodeRuntimeFilter, decodeStateFilter, decodeTextFilter } from './decode';
import { encodeCauseFilter, encodeRuntimeFilter, encodeStateFilter, encodeTextFilter } from './encode';
import {
    GeneratorRunReportCauseFilter,
    GeneratorRunReportDeviceNameFilter,
    GeneratorRunReportFuelCapacityFilter,
    GeneratorRunReportModelFilter,
    GeneratorRunReportNameFilter,
    GeneratorRunReportSerialNumberFilter,
    GeneratorRunReportStateFilter,
    GeneratorRunReportStatsFilter,
    GeneratorRunReportTableColumn,
    GeneratorRunReportTimeFilter,
    GeneratorRunReportTotalRunTimeFilter,
} from './types';

export type GeneratorRunReportColumnFilterMap = {
    [GeneratorRunReportTableColumn.DeviceName]: GeneratorRunReportDeviceNameFilter[];
    [GeneratorRunReportTableColumn.CreatedTime]: GeneratorRunReportTimeFilter | null;
    [GeneratorRunReportTableColumn.FinalisedTime]: GeneratorRunReportTimeFilter | null;
    [GeneratorRunReportTableColumn.GeneratorStartTime]: GeneratorRunReportTimeFilter | null;
    [GeneratorRunReportTableColumn.GeneratorStopTime]: GeneratorRunReportTimeFilter | null;
    [GeneratorRunReportTableColumn.TotalRunTime]: GeneratorRunReportTotalRunTimeFilter | null;
    [GeneratorRunReportTableColumn.State]: GeneratorRunReportStateFilter[];
    [GeneratorRunReportTableColumn.Cause]: GeneratorRunReportCauseFilter[];
    [GeneratorRunReportTableColumn.ModelName]: GeneratorRunReportModelFilter[];
    [GeneratorRunReportTableColumn.SerialNumber]: GeneratorRunReportSerialNumberFilter[];
    [GeneratorRunReportTableColumn.FuelCapacity]: GeneratorRunReportFuelCapacityFilter | null;
    [GeneratorRunReportTableColumn.ReportName]: GeneratorRunReportNameFilter[];
    [GeneratorRunReportTableColumn.RpmAvg]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.RpmMin]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.RpmMax]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.CoolantTemperatureAvg]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.CoolantTemperatureMin]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.CoolantTemperatureMax]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.CoolantPressureAvg]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.CoolantPressureMin]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.CoolantPressureMax]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.OilTemperatureAvg]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.OilTemperatureMin]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.OilTemperatureMax]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.OilPressureAvg]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.OilPressureMin]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.OilPressureMax]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.OutputPowerAvg]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.OutputEnergyTotal]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.FuelConsumptionTotal]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.FuelRefuelledTotal]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.FuelConsumptionAvg]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.FuelConsumptionMax]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.FuelConsumptionMin]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.FuelLevelFinish]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.FuelLevelMin]: GeneratorRunReportStatsFilter | null;
    [GeneratorRunReportTableColumn.FuelLevelMax]: GeneratorRunReportStatsFilter | null;
};

export type GeneratorRunReportAllFilterMap = GeneratorRunReportColumnFilterMap;

export const DefaultValues: FilterValueMap<GeneratorRunReportColumnFilterMap> = {
    [GeneratorRunReportTableColumn.DeviceName]: [],
    [GeneratorRunReportTableColumn.CreatedTime]: null,
    [GeneratorRunReportTableColumn.FinalisedTime]: null,
    [GeneratorRunReportTableColumn.GeneratorStartTime]: null,
    [GeneratorRunReportTableColumn.GeneratorStopTime]: null,
    [GeneratorRunReportTableColumn.TotalRunTime]: null,
    [GeneratorRunReportTableColumn.State]: [],
    [GeneratorRunReportTableColumn.Cause]: [],
    [GeneratorRunReportTableColumn.ModelName]: [],
    [GeneratorRunReportTableColumn.SerialNumber]: [],
    [GeneratorRunReportTableColumn.FuelCapacity]: null,
    [GeneratorRunReportTableColumn.ReportName]: [],
    [GeneratorRunReportTableColumn.RpmAvg]: null,
    [GeneratorRunReportTableColumn.RpmMin]: null,
    [GeneratorRunReportTableColumn.RpmMax]: null,
    [GeneratorRunReportTableColumn.CoolantTemperatureAvg]: null,
    [GeneratorRunReportTableColumn.CoolantTemperatureMin]: null,
    [GeneratorRunReportTableColumn.CoolantTemperatureMax]: null,
    [GeneratorRunReportTableColumn.CoolantPressureAvg]: null,
    [GeneratorRunReportTableColumn.CoolantPressureMin]: null,
    [GeneratorRunReportTableColumn.CoolantPressureMax]: null,
    [GeneratorRunReportTableColumn.OilTemperatureAvg]: null,
    [GeneratorRunReportTableColumn.OilTemperatureMin]: null,
    [GeneratorRunReportTableColumn.OilTemperatureMax]: null,
    [GeneratorRunReportTableColumn.OilPressureAvg]: null,
    [GeneratorRunReportTableColumn.OilPressureMin]: null,
    [GeneratorRunReportTableColumn.OilPressureMax]: null,
    [GeneratorRunReportTableColumn.OutputPowerAvg]: null,
    [GeneratorRunReportTableColumn.OutputEnergyTotal]: null,
    [GeneratorRunReportTableColumn.FuelConsumptionTotal]: null,
    [GeneratorRunReportTableColumn.FuelRefuelledTotal]: null,
    [GeneratorRunReportTableColumn.FuelConsumptionAvg]: null,
    [GeneratorRunReportTableColumn.FuelConsumptionMax]: null,
    [GeneratorRunReportTableColumn.FuelConsumptionMin]: null,
    [GeneratorRunReportTableColumn.FuelLevelFinish]: null,
    [GeneratorRunReportTableColumn.FuelLevelMin]: null,
    [GeneratorRunReportTableColumn.FuelLevelMax]: null,
};

export const StaticGeneratorRunReportFilterDefinitions: FilterDefinition<GeneratorRunReportTableColumn>[] = [
    {
        id: GeneratorRunReportTableColumn.DeviceName,
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        column: GeneratorRunReportTableColumn.DeviceName,
        component: () => GeneratorReportDeviceNameFilterUI,
        describeValue: (value: GeneratorRunReportDeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: GeneratorRunReportTableColumn.State,
        type: 'multi',
        name: 'State',
        category: 'State',
        column: GeneratorRunReportTableColumn.State,
        component: () => StateFilterUI,
        describeValue: (value: GeneratorRunReportStateFilter) => value.name,
        encodeValue: encodeStateFilter,
        decodeValue: decodeStateFilter,
    },
    {
        id: GeneratorRunReportTableColumn.Cause,
        type: 'multi',
        name: 'Cause',
        category: 'Cause',
        column: GeneratorRunReportTableColumn.Cause,
        component: () => CauseFilterUI,
        describeValue: (value: GeneratorRunReportCauseFilter) => value.name,
        encodeValue: encodeCauseFilter,
        decodeValue: decodeCauseFilter,
    },
    {
        id: GeneratorRunReportTableColumn.TotalRunTime,
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: GeneratorRunReportTableColumn.TotalRunTime,
        component: () => RunTimeFilterUI,
        describeValue: formatDurationFilter,
        encodeValue: encodeRuntimeFilter,
        decodeValue: decodeRuntimeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.GeneratorStartTime,
        type: 'single',
        name: 'Start Time',
        category: 'Start Time',
        column: GeneratorRunReportTableColumn.GeneratorStartTime,
        component: () => TimeFilterUI,
        describeValue: (value: GeneratorRunReportTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.CreatedTime,
        type: 'single',
        name: 'Created Time',
        category: 'Created Time',
        column: GeneratorRunReportTableColumn.CreatedTime,
        component: () => TimeFilterUI,
        describeValue: (value: GeneratorRunReportTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FinalisedTime,
        type: 'single',
        name: 'Finalised Time',
        category: 'Finalised Time',
        column: GeneratorRunReportTableColumn.FinalisedTime,
        component: () => TimeFilterUI,
        describeValue: (value: GeneratorRunReportTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.GeneratorStopTime,
        type: 'single',
        name: 'Stop Time',
        category: 'Stop Time',
        column: GeneratorRunReportTableColumn.GeneratorStopTime,
        component: () => TimeFilterUI,
        describeValue: (value: GeneratorRunReportTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.ReportName,
        type: 'multi',
        name: 'Report Name',
        category: 'Report Name',
        column: GeneratorRunReportTableColumn.ReportName,
        component: () => GeneratorReportNameFilterUI,
        describeValue: (value: GeneratorRunReportNameFilter) => value.value,
        encodeValue: encodeTextFilter,
        decodeValue: decodeTextFilter,
    },
    {
        id: GeneratorRunReportTableColumn.ModelName,
        type: 'multi',
        name: 'Model Name',
        category: 'Model Name',
        column: GeneratorRunReportTableColumn.ModelName,
        component: () => GeneratorReportModelFilterUI,
        describeValue: (value: GeneratorRunReportModelFilter) => value.value,
        encodeValue: encodeTextFilter,
        decodeValue: decodeTextFilter,
    },
    {
        id: GeneratorRunReportTableColumn.SerialNumber,
        type: 'multi',
        name: 'Serial Number',
        category: 'Serial Number',
        column: GeneratorRunReportTableColumn.SerialNumber,
        component: () => GeneratorReportSerialNumberFilterUI,
        describeValue: (value: GeneratorRunReportSerialNumberFilter) => value.value,
        encodeValue: encodeTextFilter,
        decodeValue: decodeTextFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelCapacity,
        type: 'single',
        name: 'Fuel Capacity',
        category: 'Fuel Capacity',
        column: GeneratorRunReportTableColumn.FuelCapacity,
        component: () => FuelCapacityFilterUI,
        describeValue: (value: GeneratorRunReportFuelCapacityFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.volume),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.RpmAvg,
        type: 'single',
        name: 'Average RPM',
        category: 'Average RPM',
        column: GeneratorRunReportTableColumn.RpmAvg,
        component: () => GeneratorReportRpmAvgFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter) => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.RpmMin,
        type: 'single',
        name: 'Minimum RPM',
        category: 'Minimum RPM',
        column: GeneratorRunReportTableColumn.RpmMin,
        component: () => GeneratorReportRpmMinFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter) => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.RpmMax,
        type: 'single',
        name: 'Maximum RPM',
        category: 'Maximum RPM',
        column: GeneratorRunReportTableColumn.RpmMax,
        component: () => GeneratorReportRpmMaxFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter) => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.CoolantTemperatureAvg,
        type: 'single',
        name: 'Average Coolant Temperature',
        category: 'Average Coolant Temperature',
        column: GeneratorRunReportTableColumn.CoolantTemperatureAvg,
        component: () => GeneratorReportCoolantTemperatureAvgFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.CoolantTemperatureMin,
        type: 'single',
        name: 'Minimum Coolant Temperature',
        category: 'Minimum Coolant Temperature',
        column: GeneratorRunReportTableColumn.CoolantTemperatureMin,
        component: () => GeneratorReportCoolantTemperatureMinFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.CoolantTemperatureMax,
        type: 'single',
        name: 'Maximum Coolant Temperature',
        category: 'Maximum Coolant Temperature',
        column: GeneratorRunReportTableColumn.CoolantTemperatureMax,
        component: () => GeneratorReportCoolantTemperatureMaxFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.CoolantPressureAvg,
        type: 'single',
        name: 'Average Coolant Pressure',
        category: 'Average Coolant Pressure',
        column: GeneratorRunReportTableColumn.CoolantPressureAvg,
        component: () => GeneratorReportCoolantPressureAvgFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.pressure),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.CoolantPressureMin,
        type: 'single',
        name: 'Minimum Coolant Pressure',
        category: 'Minimum Coolant Pressure',
        column: GeneratorRunReportTableColumn.CoolantPressureMin,
        component: () => GeneratorReportCoolantPressureMinFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.pressure),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.CoolantPressureMax,
        type: 'single',
        name: 'Maximum Coolant Pressure',
        category: 'Maximum Coolant Pressure',
        column: GeneratorRunReportTableColumn.CoolantPressureMax,
        component: () => GeneratorReportCoolantPressureMaxFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.pressure),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.OilTemperatureAvg,
        type: 'single',
        name: 'Average Oil Temperature',
        category: 'Average Oil Temperature',
        column: GeneratorRunReportTableColumn.OilTemperatureAvg,
        component: () => GeneratorReportOilTemperatureAvgFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.OilTemperatureMin,
        type: 'single',
        name: 'Minimum Oil Temperature',
        category: 'Minimum Oil Temperature',
        column: GeneratorRunReportTableColumn.OilTemperatureMin,
        component: () => GeneratorReportOilTemperatureMinFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.OilTemperatureMax,
        type: 'single',
        name: 'Maximum Oil Temperature',
        category: 'Maximum Oil Temperature',
        column: GeneratorRunReportTableColumn.OilTemperatureMax,
        component: () => GeneratorReportOilTemperatureMaxFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.OilPressureAvg,
        type: 'single',
        name: 'Average Oil Pressure',
        category: 'Average Oil Pressure',
        column: GeneratorRunReportTableColumn.OilPressureAvg,
        component: () => GeneratorReportOilPressureAvgFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.pressure),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.OilPressureMin,
        type: 'single',
        name: 'Minimum Oil Pressure',
        category: 'Minimum Oil Pressure',
        column: GeneratorRunReportTableColumn.OilPressureMin,
        component: () => GeneratorReportOilPressureMinFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.pressure),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.OilPressureMax,
        type: 'single',
        name: 'Maximum Oil Pressure',
        category: 'Maximum Oil Pressure',
        column: GeneratorRunReportTableColumn.OilPressureMax,
        component: () => GeneratorReportOilPressureMaxFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.pressure),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.OutputPowerAvg,
        type: 'single',
        name: 'Average Output Power',
        category: 'Average Output Power',
        column: GeneratorRunReportTableColumn.OutputPowerAvg,
        component: () => GeneratorReportOutputPowerAvgFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter) =>
            `${handleKiloUnitMetricsDisplay(value.min, 'W')} to ${handleKiloUnitMetricsDisplay(value.max, 'W')}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.OutputEnergyTotal,
        type: 'single',
        name: 'Total Output Energy',
        category: 'Total Output Energy',
        column: GeneratorRunReportTableColumn.OutputEnergyTotal,
        component: () => GeneratorReportOutputEnergyTotalFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter) =>
            `${handleKiloUnitMetricsDisplay(value.min, 'Wh')} to ${handleKiloUnitMetricsDisplay(value.max, 'Wh')}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelConsumptionTotal,
        type: 'single',
        name: 'Total Fuel Consumption',
        category: 'Total Fuel Consumption',
        column: GeneratorRunReportTableColumn.FuelConsumptionTotal,
        component: () => GeneratorReportFuelConsumedTotalFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.volume),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelRefuelledTotal,
        type: 'single',
        name: 'Total Fuel Refuelled',
        category: 'Total Fuel Refuelled',
        column: GeneratorRunReportTableColumn.FuelRefuelledTotal,
        component: () => GeneratorReportFuelRefuelledTotalFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.volume),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelConsumptionAvg,
        type: 'single',
        name: 'Average Fuel Consumption Rate',
        category: 'Average Fuel Consumption Rate',
        column: GeneratorRunReportTableColumn.FuelConsumptionAvg,
        component: () => GeneratorReportFuelConsumptionRateAvgFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.volumetricFlowRate),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelConsumptionMax,
        type: 'single',
        name: 'Maximum Fuel Consumption Rate',
        category: 'Maximum Fuel Consumption Rate',
        column: GeneratorRunReportTableColumn.FuelConsumptionMax,
        component: () => GeneratorReportFuelConsumptionRateMaxFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.volumetricFlowRate),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelConsumptionMin,
        type: 'single',
        name: 'Minimum Fuel Consumption Rate',
        category: 'Minimum Fuel Consumption Rate',
        column: GeneratorRunReportTableColumn.FuelConsumptionMin,
        component: () => GeneratorReportFuelConsumptionRateMinFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.volumetricFlowRate),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelLevelFinish,
        type: 'single',
        name: 'Fuel Level Finish',
        category: 'Fuel Level Finish',
        column: GeneratorRunReportTableColumn.FuelLevelFinish,
        component: () => GeneratorReportFuelLevelFinishFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter) => formatRangeWithString(value.min, value.max, '%'),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelLevelMin,
        type: 'single',
        name: 'Minimum Fuel Level',
        category: 'Minimum Fuel Level',
        column: GeneratorRunReportTableColumn.FuelLevelMin,
        component: () => GeneratorReportFuelLevelMinFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter) => formatRangeWithString(value.min, value.max, '%'),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelLevelMax,
        type: 'single',
        name: 'Maximum Fuel Level',
        category: 'Maximum Fuel Level',
        column: GeneratorRunReportTableColumn.FuelLevelMax,
        component: () => GeneratorReportFuelLevelMaxFilterUI,
        describeValue: (value: GeneratorRunReportStatsFilter) => formatRangeWithString(value.min, value.max, '%'),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
];
