/**
 * @generated SignedSource<<a497971f7bdf260e7801a9bc373963bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type dynamic_loadSiteAttributeNamesQuery$variables = Record<PropertyKey, never>;
export type dynamic_loadSiteAttributeNamesQuery$data = {
  readonly attributeNames: ReadonlyArray<string>;
};
export type dynamic_loadSiteAttributeNamesQuery = {
  response: dynamic_loadSiteAttributeNamesQuery$data;
  variables: dynamic_loadSiteAttributeNamesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1000
      },
      {
        "kind": "Literal",
        "name": "only",
        "value": "Site"
      },
      {
        "kind": "Literal",
        "name": "search",
        "value": ""
      },
      {
        "kind": "Literal",
        "name": "status",
        "value": "Active"
      }
    ],
    "kind": "ScalarField",
    "name": "attributeNames",
    "storageKey": "attributeNames(limit:1000,only:\"Site\",search:\"\",status:\"Active\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dynamic_loadSiteAttributeNamesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dynamic_loadSiteAttributeNamesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b45adc51cb8cbfa5093e36072c1a533c",
    "id": null,
    "metadata": {},
    "name": "dynamic_loadSiteAttributeNamesQuery",
    "operationKind": "query",
    "text": "query dynamic_loadSiteAttributeNamesQuery {\n  attributeNames(search: \"\", only: Site, limit: 1000, status: Active)\n}\n"
  }
};
})();

(node as any).hash = "ad5ab301acebb343b920104584136acd";

export default node;
