// List the features that needs to be hidden
// Possible values:
// - firmware
// - generator_run
const DisabledFeatures = ['firmware'];

export const isFeatureEnabled = (feature: string): boolean => {
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
        return true;
    }

    if (DisabledFeatures.includes(feature)) {
        return false;
    }

    return true;
};
