import { Change } from '@accesstel/pcm-ui';

import { BlocId } from 'views/tasks/battery-health/test-result-view/components/common';

import { TimeRange } from '../../../common';
import { DeviceReport_ReportQuery$data } from './__generated__/DeviceReport_ReportQuery.graphql';

export interface CommonPropsWithParentQuery {
    metrics: NonNullable<DeviceReport_ReportQuery$data['device']>['battery']['metrics'] | null;
}

export interface CommonPropsWithTime {
    device: string;
    timeRange: TimeRange;
    bloc?: BlocId | null;
    short?: boolean;
    coarseTicks?: boolean;
}

export type CommonPropsWithParentQueryAndTime = CommonPropsWithParentQuery & CommonPropsWithTime;

export function asChange(trend: string): Change {
    switch (trend) {
        case 'Up':
            return Change.Up;
        case 'Down':
            return Change.Down;
        case 'Flat':
            return Change.Flat;
        default:
            throw new Error(`Unknown trend ${trend}`);
    }
}
