import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { StatusMetric } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { handleKiloUnitMetricsDisplay, numberToLocaleString } from 'lib/numberFormatters';
import { formatGeneratorRunReportCause } from 'lib/textFormatters';
import { formatValueWithUnit } from 'lib/units';

import { MetricsView_data$key } from './__generated__/MetricsView_data.graphql';

export interface MetricsViewProps {
    runReport: MetricsView_data$key;
}

export const MetricsView: FC<MetricsViewProps> = ({ runReport }) => {
    const data = useFragment<MetricsView_data$key>(MetricsViewFragment, runReport);
    const unitPreferences = useCurrentUserUnitsPref();

    let cause;
    if (data && data.cause != null) {
        cause = formatGeneratorRunReportCause(data.cause);
    } else {
        cause = '-';
    }

    let fuelConsumed;
    if (data && data.fuelConsumed != null) {
        fuelConsumed = formatValueWithUnit(numberToLocaleString(data.fuelConsumed, 2), unitPreferences.volume);
    } else {
        fuelConsumed = '-';
    }

    let totalEnergyOutput;
    if (data && data.totalEnergyOutput != null) {
        totalEnergyOutput = handleKiloUnitMetricsDisplay(data.totalEnergyOutput, 'Wh');
    } else {
        totalEnergyOutput = '-';
    }

    let averageOutputPower;
    if (data && data.averageOutputPower != null) {
        averageOutputPower = handleKiloUnitMetricsDisplay(data.averageOutputPower, 'W');
    } else {
        averageOutputPower = '-';
    }

    return (
        <div className='grid grid-cols-4' data-testid='metrics-view'>
            <StatusMetric title='Cause' content={cause} status={'neutral'} showIcon={false} />
            <StatusMetric title='Fuel consumed' content={fuelConsumed} status={'neutral'} showIcon={false} />
            <StatusMetric title='Energy produced' content={totalEnergyOutput} status={'neutral'} showIcon={false} />
            <StatusMetric title='Average output' content={averageOutputPower} status={'neutral'} showIcon={false} />
        </div>
    );
};

const MetricsViewFragment = graphql`
    fragment MetricsView_data on GeneratorRunReport @argumentDefinitions(unitVolume: { type: "UnitVolume" }) {
        cause
        fuelConsumed(unit: $unitVolume)
        totalEnergyOutput(unit: WattHour)
        averageOutputPower(unit: Watt)
    }
`;
