import React, { FC } from 'react';

import { getQualifiedName } from '../../../../../../../lib/namespaces';
import { GatewaySelector } from './GatewaySelector';
import { NeSelector } from './NeSelector';

export interface GatewaySettingsProps {
    namespace?: string;
}

export const GatewaySettings: FC<GatewaySettingsProps> = ({ namespace }) => {
    return (
        <>
            <GatewaySelector name={getQualifiedName('gateway', namespace)} />
            <div className='col-start-1'>
                <NeSelector name={getQualifiedName('localId', namespace)} />
            </div>
        </>
    );
};
