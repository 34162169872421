import React, { FC } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';
import { Outlet, useMatch, useParams } from 'react-router-dom';

import { Button, NavigateTo, PageHeading, SiteIcon, Tab, Tabs, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { getGlobalEnvironment } from '../../../../lib/environment';
import { Paths } from '../../../../lib/routes';
import { ErrorNotFound } from '../../../ErrorPage/ErrorNotFound';
import { useTimeRangeFromSearch } from '../common';
import { ReportIntervalSelection } from '../components';
import { TimeRangeSearchParameter } from '../settings';
import { ACSiteReportLayoutContentQuery } from './__generated__/ACSiteReportLayoutContentQuery.graphql';

export interface ACSiteReportLayoutContentProps {
    preloadedQuery: PreloadedQuery<ACSiteReportLayoutContentQuery>;
}

export const ACSiteReportLayoutContent: FC<ACSiteReportLayoutContentProps> = ({ preloadedQuery }) => {
    const { siteId } = useParams();
    const deviceViewMatch = useMatch(Paths.ReportACPowerSiteViewDevice);

    const { site } = usePreloadedQuery(ContentQuery, preloadedQuery);
    const navigate = useExtendedNavigate();

    const timeRange = useTimeRangeFromSearch();

    if (!site) {
        return <ErrorNotFound />;
    }

    const displayTabs: Tab[] = [
        {
            id: 'site',
            tooltip: site.name,
            label: site.name,
            icon: (
                <div className='p-1'>
                    <SiteIcon />
                </div>
            ),
            style: 'icon',
        },
    ];

    const devices = [...site.devices.data].sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name));

    const singlePlaneDevices = devices.filter(device => !device.dualPlaneCompanion?.device);
    for (const device of singlePlaneDevices) {
        displayTabs.push({
            id: device.id,
            label: device.name,
            subtitle: device.type.displayName,
        });
    }

    const groups: string[] = [];
    const dualPlaneDevices = devices.filter(device => device.dualPlaneCompanion?.device);
    for (const device of dualPlaneDevices) {
        const ids = [device.id, device.dualPlaneCompanion!.device!.id];
        const groupId = ids.sort().join('-');
        if (!groups.includes(groupId)) {
            groups.push(groupId);
        }

        const groupIndex = groups.indexOf(groupId) + 1;

        displayTabs.push({
            id: device.id,
            label: device.name,
            subtitle: device.type.displayName,
            group: `Plane ${groupIndex}`,
        });
    }

    let activeTab: Tab | null | undefined;
    if (deviceViewMatch) {
        activeTab = displayTabs.find(tab => tab.id === deviceViewMatch.params.deviceId);
    }

    activeTab ??= displayTabs[0];

    const handleTabChange = (tab: Tab) => {
        if (tab.id === 'site') {
            navigate({
                pathname: Paths.ReportACPowerSiteView,
                params: { siteId: siteId! },
                search: {
                    [TimeRangeSearchParameter]: timeRange,
                },
            });
        } else {
            navigate({
                pathname: Paths.ReportACPowerSiteViewDevice,
                params: { siteId: siteId!, deviceId: tab.id },
                search: {
                    [TimeRangeSearchParameter]: timeRange,
                },
            });
        }
    };

    let currentStateLink: NavigateTo;
    let batteryReportLink: NavigateTo;

    if (deviceViewMatch) {
        currentStateLink = {
            pathname: Paths.SiteViewViewSiteDevice,
            params: { siteId: siteId!, deviceId: deviceViewMatch.params.deviceId! },
        };
        batteryReportLink = {
            pathname: Paths.ReportBatteriesViewSiteDevice,
            params: { siteId: siteId!, deviceId: deviceViewMatch.params.deviceId! },
        };
    } else {
        currentStateLink = { pathname: Paths.SiteViewViewSite, params: { siteId: siteId! } };
        batteryReportLink = { pathname: Paths.ReportBatteriesViewSite, params: { siteId: siteId! } };
    }

    return (
        <div className='space-y-6'>
            <div>
                <PageHeading value={site.name} />
                <div className='flex flex-row justify-between items-baseline'>
                    <div>
                        <span className='text-coralRegular'>Site Location: </span>
                        <span>
                            {site.address.address}, {site.address.state}
                        </span>
                    </div>
                    <ReportIntervalSelection />
                </div>
            </div>

            <div>
                <Tabs activeItem={activeTab} setActiveItem={handleTabChange} tabs={displayTabs} variant='card' />
                <div className='bg-white p-8 grid grid-cols-12 gap-x-4'>
                    <Outlet />
                </div>
                <div className='mt-6 flex flex-row gap-4'>
                    <Button buttonText='View current state' size='small' to={currentStateLink} variant='primary' />
                    <Button buttonText='View battery report' size='small' to={batteryReportLink} variant='white' />
                </div>
            </div>
        </div>
    );
};

const ContentQuery = graphql`
    query ACSiteReportLayoutContentQuery($id: ID!) {
        site(id: $id) {
            name
            address {
                address
                state
            }
            devices {
                data {
                    id
                    name
                    type {
                        displayName
                    }
                    dualPlaneCompanion {
                        device {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export function loadACSiteReportLayoutData(id: string) {
    return loadQuery(getGlobalEnvironment(), ContentQuery, { id });
}
