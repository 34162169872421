import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { FormikDropdownWithSearch, IconButton, RefreshIcon, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useFormikContext } from 'formik';
import { SUGGESTIONS_LIMIT } from 'lib/provision';

import { Paths } from '../../../../../../../lib/routes';
import { DeviceFormValues, DeviceReference } from '../../../schema';
import { GatewaySelectorSearchQuery } from './__generated__/GatewaySelectorSearchQuery.graphql';

export interface DeviceSelectorProps {
    name: string;
}

export const GatewaySelector: FC<DeviceSelectorProps> = ({ name }) => {
    const { show } = useToast();

    const environment = useRelayEnvironment();
    const [deviceReferences, setDeviceReferences] = useState<DeviceReference[]>([]);
    const [deviceListError, setDeviceListError] = useState<string | null>(null);
    const { values } = useFormikContext<DeviceFormValues>();

    const siteId = values.site?.id ?? '';
    const prevSiteIdRef = useRef(siteId);

    const handleSearch = useCallback(
        (search: string, force = false) => {
            if (!siteId) {
                return;
            }

            if (search.length === 0 && !force) {
                return;
            }

            fetchQuery<GatewaySelectorSearchQuery>(environment, SearchQuery, {
                search,
                siteId: [siteId],
                limit: SUGGESTIONS_LIMIT,
            }).subscribe({
                next(value) {
                    const deviceReferences = value.devices.data.map<DeviceReference>(device => ({
                        id: device.id,
                        displayName: device.name,
                        siteName: device.site.name,
                    }));
                    setDeviceReferences(deviceReferences);
                    setDeviceListError(null);
                },
                error(error: unknown) {
                    captureException(error);
                    show({ variant: 'error', text: 'Failed to get devices' });
                    setDeviceReferences([]);
                    setDeviceListError('Unable to search at this time');
                },
            });
        },
        [environment, show, siteId]
    );

    useEffect(() => {
        const currentSiteId = siteId;
        if (prevSiteIdRef.current === currentSiteId) {
            return;
        }

        // TODO: it would be better to show the most recently selected options instead of just any
        handleSearch('', true);
        prevSiteIdRef.current = currentSiteId;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId]);

    return (
        <>
            <div className='col-start-1'>
                <FormikDropdownWithSearch<DeviceReference>
                    name={name}
                    placeHolder='Gateway*'
                    light
                    required
                    disabled={!siteId}
                    disabledText='Please select a site first'
                    renderItemAsString={item => item.displayName}
                    renderItem={renderReference}
                    items={deviceReferences}
                    onSearch={handleSearch}
                    variant='outlined'
                    testId='device-name'
                    noResultsText={search => {
                        if (!siteId) {
                            return 'Please select a site';
                        }

                        if (deviceListError) {
                            return deviceListError;
                        }

                        if (search.length > 0) {
                            return 'No matching gateways';
                        }

                        return 'No gateways available';
                    }}
                />
            </div>

            <div className='flex items-end col-start-2 space-x-4'>
                <IconButton
                    title='Add new gateway'
                    size='small'
                    to={{
                        pathname: Paths.AddDevice,
                        search: {
                            site: siteId,
                        },
                    }}
                    target='_blank'
                    disabled={!siteId}
                />
                <IconButton
                    title='Refresh gateway list'
                    size='small'
                    onClick={() => handleSearch('', true)}
                    icon={<RefreshIcon />}
                    disabled={!siteId}
                />
            </div>
        </>
    );
};

function renderReference(reference: DeviceReference): ReactNode {
    return (
        <div className='flex flex-row items-baseline space-x-2'>
            <span>{reference.displayName}</span>
            <span className='font-light text-xs'>{reference.siteName}</span>
        </div>
    );
}

const SearchQuery = graphql`
    query GatewaySelectorSearchQuery($search: String!, $siteId: [ID!]!, $limit: Int!) {
        devices(search: $search, page: 1, pageSize: $limit, filters: { siteId: $siteId, category: [Gateway] }) {
            data {
                id
                name
                site {
                    name
                }
            }
        }
    }
`;
