import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { decodeDateRangeFilter } from 'filters/common';

import { SelectedMetric } from '../types';
import { parseMetricDefinition } from './metric-definition';

export function decodeDeviceIds(value: string): string[] | null {
    const values = value
        .split(',')
        .map(decodeURIComponent)
        .filter(val => val !== '');

    if (values.length === 0) {
        return null;
    }

    return values;
}

export function decodeMetrics(value: string): SelectedMetric[] | null {
    const values = value
        .split(',')
        .map(decodeURIComponent)
        .filter(val => val !== '');

    if (values.length === 0) {
        return null;
    }

    const metrics = values.map(parseMetricDefinition).filter(metric => metric !== null) as SelectedMetric[];

    if (metrics.length === 0) {
        return null;
    }

    return metrics;
}

export function decodeMetricTimeRange(value: string): DateRangeFilter | null {
    const range = decodeDateRangeFilter(value);

    if (!range) {
        return null;
    }

    return {
        type: 'custom',
        range: range.range,
    };
}
