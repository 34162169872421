import React, { FC } from 'react';

import humanizeDuration from 'humanize-duration';

export interface DeviceRowProps {
    name: string;
    mtbfInSeconds: number | null;
    mttrInSeconds: number | null;
}

export const DeviceRow: FC<DeviceRowProps> = ({ name, mtbfInSeconds, mttrInSeconds }) => {
    let mtbf: string;

    if (mtbfInSeconds != null) {
        mtbf = humanizeDuration(mtbfInSeconds * 1000, {
            largest: 1,
            round: true,
        });
    } else {
        mtbf = '-';
    }

    let mttr: string;

    if (mttrInSeconds != null) {
        mttr = humanizeDuration(mttrInSeconds * 1000, {
            largest: 1,
            round: true,
        });
    } else {
        mttr = '-';
    }

    return (
        <div className='max-w-full'>
            <div className='font-bold truncate'>{name}</div>
            <div className='font-light flex flex-row justify-start gap-4'>
                <div>
                    MTBF <span className='font-bold'>{mtbf}</span>
                </div>
                <div>
                    MTTR <span className='font-bold'>{mttr}</span>
                </div>
            </div>
        </div>
    );
};
