/**
 * @generated SignedSource<<de269c5484ae790ca08e4355a5d8bbc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceDistributionType = "BatteryCapacityRemaining" | "BatteryCapacityTotal" | "BatteryReserveTime" | "BatteryStateOfHealth" | "BatteryString" | "BatteryTemperature" | "batteryEnergyTotal" | "%future added value";
export type UnitApparentPower = "KiloVoltAmp" | "VoltAmp" | "%future added value";
export type UnitCharge = "AmpHour" | "MilliAmpHour" | "%future added value";
export type UnitCurrent = "Amps" | "Milliamps" | "%future added value";
export type UnitEnergy = "KiloWattHour" | "WattHour" | "%future added value";
export type UnitFlowRate = "LiterPerHour" | "USGallonPerHour" | "%future added value";
export type UnitPower = "KiloWatt" | "Watt" | "%future added value";
export type UnitPressure = "Bar" | "KiloPascal" | "PSI" | "Pascal" | "%future added value";
export type UnitReactivePower = "KiloVoltAmpReactive" | "VoltAmpReactive" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type UnitTime = "Hours" | "Minutes" | "Seconds" | "%future added value";
export type UnitVoltage = "Millivolts" | "Volts" | "%future added value";
export type UnitVolume = "Liter" | "USGallon" | "%future added value";
export type UnitSettings = {
  apparentPower?: UnitApparentPower | null;
  charge?: UnitCharge | null;
  current?: UnitCurrent | null;
  energy?: UnitEnergy | null;
  power?: UnitPower | null;
  pressure?: UnitPressure | null;
  reactivePower?: UnitReactivePower | null;
  temperature?: UnitTemperature | null;
  time?: UnitTime | null;
  voltage?: UnitVoltage | null;
  volume?: UnitVolume | null;
  volumetricFlowRate?: UnitFlowRate | null;
};
export type DeviceFilterRange_deviceDistributionQuery$variables = {
  type: DeviceDistributionType;
  units: UnitSettings;
};
export type DeviceFilterRange_deviceDistributionQuery$data = {
  readonly deviceDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly key: number;
      readonly value: number;
    }>;
    readonly maximum: number | null;
    readonly minimum: number | null;
  };
};
export type DeviceFilterRange_deviceDistributionQuery = {
  response: DeviceFilterRange_deviceDistributionQuery$data;
  variables: DeviceFilterRange_deviceDistributionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "units"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      },
      {
        "kind": "Variable",
        "name": "units",
        "variableName": "units"
      }
    ],
    "concreteType": "DeviceDistribution",
    "kind": "LinkedField",
    "name": "deviceDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceDistributionCount",
        "kind": "LinkedField",
        "name": "distribution",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximum",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceFilterRange_deviceDistributionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceFilterRange_deviceDistributionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a89b27a4673c69d11f31960c7a3b7704",
    "id": null,
    "metadata": {},
    "name": "DeviceFilterRange_deviceDistributionQuery",
    "operationKind": "query",
    "text": "query DeviceFilterRange_deviceDistributionQuery(\n  $type: DeviceDistributionType!\n  $units: UnitSettings!\n) {\n  deviceDistribution(type: $type, units: $units) {\n    distribution {\n      key\n      value\n    }\n    minimum\n    maximum\n  }\n}\n"
  }
};
})();

(node as any).hash = "65147cf2bf8462bb0dd3a86ed66e95fb";

export default node;
