/**
 * @generated SignedSource<<391f793ef482cb9dfc9ed6b415ef07b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GatewayOverview_connectedDevices$data = {
  readonly devices: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly type: {
      readonly category: DeviceCategory;
      readonly displayName: string;
    };
  }>;
  readonly " $fragmentType": "GatewayOverview_connectedDevices";
};
export type GatewayOverview_connectedDevices$key = {
  readonly " $data"?: GatewayOverview_connectedDevices$data;
  readonly " $fragmentSpreads": FragmentRefs<"GatewayOverview_connectedDevices">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GatewayOverview_connectedDevices",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "devices",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceType",
          "kind": "LinkedField",
          "name": "type",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GatewayConnectedDevice",
  "abstractKey": null
};

(node as any).hash = "708f8fcba76e3e30b3690e0610ebf2f1";

export default node;
