import React, { FC, Suspense, useCallback, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { ViewBatteryTestResultContent } from './ViewBatteryTestResultContent';
import { ViewBatteryTestResultSkeleton } from './ViewBatteryTestResultSkeleton';
import { ViewBatteryTestResultByTaskQuery } from './__generated__/ViewBatteryTestResultByTaskQuery.graphql';

const LoadTestByTaskAndDeviceQuery = graphql`
    query ViewBatteryTestResultByTaskQuery($taskId: ID!, $deviceId: ID!, $unitTemperature: UnitTemperature) {
        device(id: $deviceId) {
            test(id: $taskId) {
                id
                ...ViewBatteryTestResultContent_test @arguments(unitTemperature: $unitTemperature)
            }
        }
    }
`;

export interface RouteParams extends Record<string, string | undefined> {
    taskId?: string;
    deviceId?: string;
}

export const ViewBatteryTestResultByTask: FC = () => {
    const { taskId, deviceId } = useParams<RouteParams>();
    const userPreferences = useCurrentUserUnitsPref();

    useDocumentTitle('Battery test result');

    const [queryRef, loadQuery, dispose] =
        useQueryLoader<ViewBatteryTestResultByTaskQuery>(LoadTestByTaskAndDeviceQuery);

    const load = useCallback(() => {
        loadQuery(
            {
                taskId: taskId ?? '',
                deviceId: deviceId ?? '',
                unitTemperature: userPreferences.temperature,
            },
            { fetchPolicy: 'store-and-network' }
        );
    }, [deviceId, loadQuery, taskId, userPreferences.temperature]);

    useEffect(() => {
        load();
        return () => {
            dispose();
        };
    }, [dispose, load]);

    return (
        <>
            {!queryRef && <ViewBatteryTestResultSkeleton />}
            {queryRef && (
                <Suspense fallback={<ViewBatteryTestResultSkeleton />}>
                    <ContentWrapper queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};

interface ContentWrapperProps {
    queryRef: PreloadedQuery<ViewBatteryTestResultByTaskQuery>;
}

const ContentWrapper: FC<ContentWrapperProps> = ({ queryRef }) => {
    const props = usePreloadedQuery<ViewBatteryTestResultByTaskQuery>(LoadTestByTaskAndDeviceQuery, queryRef);

    if (!props.device?.test) {
        // TODO: Show error
        return <ErrorNotFound />;
    }

    const testId = props.device.test.id;

    return <ViewBatteryTestResultContent key={testId} testId={testId} test={props.device.test} view='task' />;
};
