import React, { FC } from 'react';

import { FormikTextField } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

export interface GeneralSettingsProps {
    namespace?: string;
}

export const GeneralSettings: FC<GeneralSettingsProps> = ({ namespace }) => {
    // FIXME: How do I do this in Formik?
    // const onBlur = (event: FocusEvent<HTMLInputElement>, setting: ChangeEvents) => {
    //     const value = event.target.value;

    //      if (value !== '' && !isNaN(Number(value)) && value !== '0') {
    //         let newValue = Number(value);

    //          const precisionFactor = Math.pow(10, -2);
    //         newValue = Math.round(newValue / precisionFactor) * precisionFactor;

    //          if (isFinite(newValue)) {
    //             deviceDispatch({
    //                 type: DeviceActionTypes.Batteries,
    //                 payload: {
    //                     ...deviceState.batteries,
    //                     [setting]: newValue,
    //                 },
    //             });
    //         }
    //     } else if (value === '') {
    //         deviceDispatch({
    //             type: DeviceActionTypes.Batteries,
    //             payload: {
    //                 ...deviceState.batteries,
    //                 [setting]: null,
    //             },
    //         });
    //     }
    // };

    return (
        <>
            <div className='col-start-1 pt-4'>
                <FormikTextField
                    name={getQualifiedName('minimumAllowedVoltage', namespace)}
                    placeHolder='Minimum Allowed Voltage'
                    light
                    variant='outlined'
                />
            </div>
            <div className='col-start-2 pt-4'>
                <FormikTextField
                    name={getQualifiedName('maximumAllowedVoltage', namespace)}
                    placeHolder='Maximum Allowed Voltage'
                    light
                    variant='outlined'
                />
            </div>
            <div className='col-start-1'>
                <FormikTextField
                    name={getQualifiedName('reserveTime', namespace)}
                    placeHolder='Reserve Time Minutes'
                    light
                    variant='outlined'
                />
            </div>
            <div className='col-start-1'>
                <FormikTextField
                    name={getQualifiedName('quickTestFailThreshold', namespace)}
                    placeHolder='Quick Test Fail Threshold Voltage'
                    light
                    variant='outlined'
                />
            </div>
            <div className='col-start-2'>
                <FormikTextField
                    name={getQualifiedName('quickTestCheckPercent', namespace)}
                    placeHolder='Quick Test Percentage of Reserve Time'
                    light
                    variant='outlined'
                />
            </div>
        </>
    );
};
