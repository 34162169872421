import React, { FC, useMemo } from 'react';

import { FormikGeneralSelect } from '@accesstel/pcm-ui';

import { useQuery } from 'lib/query-helpers';

import { queries_GetFirmwareVersionsQuery } from '../../../__generated__/queries_GetFirmwareVersionsQuery.graphql';
import { getFirmwareVersionsQuery } from '../../../queries';

export interface TargetFirmwareSelectorProps {
    name: string;
}

export const TargetFirmwareSelector: FC<TargetFirmwareSelectorProps> = ({ name }) => {
    const { firmwareVersionIds, firmwareVersionIdToNameMap } = useFirmwareVersions();

    return (
        <FormikGeneralSelect<string>
            name={name}
            placeHolder='Target Firmware Version'
            items={firmwareVersionIds}
            renderItem={versionId => firmwareVersionIdToNameMap[versionId]}
            light
            variant='outlined'
            testId='target-firmware-version'
        />
    );
};

function useFirmwareVersions() {
    // FIXME: Use useLazyLoadQuery, but that suspends
    const { data } = useQuery<queries_GetFirmwareVersionsQuery>(getFirmwareVersionsQuery, {});

    const firmwareVersionIds = useMemo(() => {
        const firmwareVersions = data?.firmwareVersions.data ?? [];
        return firmwareVersions.map(firmwareVersion => firmwareVersion.id);
    }, [data?.firmwareVersions.data]);

    const firmwareVersionIdToNameMap = useMemo(() => {
        const firmwareVersions = data?.firmwareVersions.data ?? [];
        const map: Record<string, string> = {};

        for (const firmwareVersion of firmwareVersions) {
            map[firmwareVersion.id] = firmwareVersion.name;
        }
        return map;
    }, [data?.firmwareVersions.data]);

    return { firmwareVersionIds, firmwareVersionIdToNameMap };
}
