import React, { ReactNode } from 'react';

import { BatteryChargingIcon, BatteryEmptyIcon, BatteryOfflineIcon } from '@accesstel/pcm-ui';

import { BatteryStatus } from '../sub-views/batteries/__generated__/DeviceBatteries_device.graphql';

export function batteryStatusToColor(status: BatteryStatus | 'Offline' | null): 'coral' | 'pine' | 'eggplant' {
    switch (status) {
        case 'BoostCharging':
        case 'Charging':
        case 'FloatCharging':
        case 'Idle':
            return 'pine';
        case 'Discharging':
        case 'Disconnected':
        case 'Missing':
        case 'Offline':
            return 'coral';
        default:
        case 'Unknown':
            return 'eggplant';
    }
}

export function batteryStatusToTextColor(
    status: BatteryStatus | 'Offline' | null
): 'text-coralRegular' | 'text-pineRegular' | 'text-eggplantRegular' {
    switch (status) {
        case 'BoostCharging':
        case 'Charging':
        case 'FloatCharging':
        case 'Idle':
            return 'text-pineRegular';
        case 'Discharging':
        case 'Disconnected':
        case 'Missing':
        case 'Offline':
            return 'text-coralRegular';
        default:
        case 'Unknown':
            return 'text-eggplantRegular';
    }
}

export function batteryStatusToIcon(status: BatteryStatus | 'Offline' | null): ReactNode {
    switch (status) {
        case 'BoostCharging':
        case 'Charging':
        case 'FloatCharging':
        case 'Discharging':
            return <BatteryChargingIcon />;
        case 'Disconnected':
        case 'Missing':
        case 'Unknown':
        case 'Offline':
            return <BatteryOfflineIcon />;
        case 'Idle':
        default:
            return <BatteryEmptyIcon />;
    }
}
