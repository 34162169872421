import React, { FC, useCallback, useEffect, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { BarDataType, LabelFormatterType, MinusIcon, PlusIcon } from '@accesstel/pcm-ui';

import { Row } from '@tanstack/react-table';
import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { AlertFilterAction, AlertTableColumn, IsAlertActive, alertToFilterObject, useAlertFilter } from 'filters/alert';
import { FilterActionType } from 'filters/common';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { useQuery } from 'lib/query-helpers';
import { colorPalette } from 'views/manage';
import { ReportSearchResults, renderSearchResult } from 'views/reports/batteries/search';

import { useTableQuery } from '../../../../lib/tables/table-query';
import { AlertListInsightsDistributionQuery } from './__generated__/AlertListInsightsDistributionQuery.graphql';
import { AlertListSearchQuery } from './__generated__/AlertListSearchQuery.graphql';
import {
    AlertListTableQuery,
    AlertListTableQuery$data,
    AlertOrdering,
    AlertSortField,
} from './__generated__/AlertListTableQuery.graphql';
import { AlertListExpandedRow } from './components';
import { AllTableColumns, BaseTableColumns } from './settings';

export type Alert = AlertListTableQuery$data['alerts']['data'][number];

const TableStorageKeyPrefix = 'alert-table';

export const AlertList: FC = () => {
    const [onlyActiveAlerts, setOnlyActiveAlerts] = useState<boolean>(true);

    const environment = useRelayEnvironment();

    useDocumentTitle('Reports - Insights');

    const [tableState, dispatchTableState] = useTableReducer<AlertTableColumn>({
        defaultSortColumn: AlertTableColumn.RaiseDate,
        defaultSortDirection: SortDirection.Descending,
        allColumns: AllTableColumns,
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useAlertFilter();

    useEffect(() => {
        if (filters.columnValues.IsActive === IsAlertActive.No) {
            // auto trigger to include inactive alerts
            setOnlyActiveAlerts(false);
        }
    }, [filters.columnValues]);

    const {
        data: props,
        error,
        retry,
        isFetching,
        fetchTable,
    } = useTableQuery<AlertTableColumn, AlertListTableQuery>(
        graphql`
            query AlertListTableQuery(
                $page: Int = 1
                $pageSize: Int = 50
                $pageCount: Int
                $search: String = ""
                $orderBy: AlertOrdering
                $filter: AlertFilter
                $onlyActiveAlerts: Boolean
            ) {
                alerts(
                    onlyActiveAlerts: $onlyActiveAlerts
                    page: $page
                    pageSize: $pageSize
                    pageCount: $pageCount
                    search: $search
                    orderBy: $orderBy
                    filters: $filter
                ) {
                    total
                    data {
                        id
                        type
                        severity
                        category
                        domain
                        message
                        originator
                        raiseDate
                        clearDate
                        isActive
                        suggestions
                        device {
                            id
                            name
                        }
                        site {
                            id
                            name
                            address {
                                state
                            }
                        }
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                overallAlerts: alerts(onlyActiveAlerts: $onlyActiveAlerts) {
                    total
                }
            }
        `,
        options => {
            const sortObject: AlertOrdering = {
                field: options.orderBy as AlertSortField,
                dir: options.orderDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
            };

            return {
                page: options.page,
                pageSize: options.pageSize,
                search: options.search,
                orderBy: sortObject,
                filter: alertToFilterObject(filters),
                onlyActiveAlerts: onlyActiveAlerts,
            };
        },
        tableState
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<AlertListSearchQuery>(
                environment,
                graphql`
                    query AlertListSearchQuery($search: String!, $pageSize: Int!) {
                        alerts(onlyActiveAlerts: false, search: $search, pageSize: $pageSize) {
                            data {
                                device {
                                    id
                                    name
                                    site {
                                        id
                                        name
                                        address {
                                            state
                                        }
                                    }
                                }
                                site {
                                    id
                                    name
                                    address {
                                        state
                                    }
                                }
                            }
                        }
                    }
                `,
                {
                    search: input,
                    pageSize: 10,
                }
            )
                .toPromise()
                .then(results => {
                    const deviceResults: ReportSearchResults[] =
                        results?.alerts.data.map(result => ({
                            id: result.device.id,
                            name: result.device.name,
                            site: result.site.id,
                            siteName: result.site.name,
                            state: result.site.address.state,
                            type: 'device',
                        })) ?? [];

                    const siteResults: ReportSearchResults[] =
                        results?.alerts.data.map(result => ({
                            id: result.site.id,
                            name: result.site.name,
                            site: result.site.id,
                            siteName: result.site.name,
                            state: result.site.address.state,
                            type: 'site',
                        })) ?? [];

                    const uniqueDevices = deviceResults.filter(
                        (item, index, self) => index === self.findIndex(t => t.id === item.id)
                    );

                    const uniqueSites = siteResults.filter(
                        (item, index, self) => index === self.findIndex(t => t.id === item.id)
                    );

                    return uniqueDevices.concat(uniqueSites);
                });
        },
        [environment]
    );

    const { data: distributionProps } = useQuery<AlertListInsightsDistributionQuery>(
        getInsightsDistribution,
        { includeCleared: !onlyActiveAlerts },
        { fetchPolicy: 'network-only' }
    );

    const distributionData: BarDataType[] =
        distributionProps?.insightDistribution?.distribution.map((group, i) => {
            let bgColor = undefined;
            if (colorPalette.length > i) {
                bgColor = colorPalette[i];
            }

            return {
                id: group.key,
                label: group.displayName ?? group.key,
                value: group.value,
                bgClass: bgColor,
            };
        }) ?? [];
    const labelFormatter: LabelFormatterType<BarDataType> = useCallback((label, data) => {
        if (label === null) {
            return '';
        }
        return `${data.value} ${label.toUpperCase()}`;
    }, []);

    const onSegmentClick = (id: string) => {
        dispatchFilters({
            type: FilterActionType.Apply,
            column: AlertTableColumn.Severity,
            value: [
                {
                    id,
                    value: distributionData.find(d => d.id === id)!.label,
                },
            ],
        } as AlertFilterAction);
    };

    return (
        <>
            <TableLayout
                title='Insights'
                columns={AllTableColumns}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={props?.alerts.data ?? null}
                isProcessing={!!props && isFetching}
                getRowId={(row: Alert) => row.id}
                page={props?.alerts.pageInfo.page}
                pageCount={props?.alerts.pageInfo.total}
                overallCount={props?.overallAlerts.total}
                resultCount={props?.alerts.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Device or Site name'
                onSearch={handleSearch}
                renderSearchResult={(items, context) => renderSearchResult(items, context)}
                renderSearchResultAsString={(item: ReportSearchResults) => item.name}
                emptyMessage='There are no matching alerts'
                unit={onlyActiveAlerts ? 'Active alert' : 'Alert'}
                rowExpansionComponent={(row: Row<Alert>) => <AlertListExpandedRow row={row} />}
                barChartData={{
                    data: distributionData,
                    emptyLabel: 'No alerts',
                    labelFormatter,
                    onSegmentClick,
                }}
                additionalActions={[
                    {
                        buttonIcon: onlyActiveAlerts ? <PlusIcon /> : <MinusIcon />,
                        buttonText: onlyActiveAlerts ? 'Show all alerts' : 'Hide cleared alerts',
                        onClick: () =>
                            setOnlyActiveAlerts(prevValue => {
                                if (filters.columnValues.IsActive === IsAlertActive.No && prevValue === false) {
                                    dispatchFilters({
                                        type: FilterActionType.Clear,
                                        column: AlertTableColumn.IsActive,
                                    });
                                }
                                return !prevValue;
                            }),
                    },
                ]}
                searchBoxProperties={{
                    groups: SearchGroups,
                    groupKey: 'type',
                }}
                exportEnabled
                exportFilename='insights'
                exportFetchData={options =>
                    fetchTable(options).then(results => results.flatMap(result => result.alerts.data))
                }
            />
        </>
    );
};

const SearchGroups = [
    {
        key: 'device',
        title: <span className='text-mauveRegular'>DEVICE</span>,
    },
    {
        key: 'site',
        title: <span className='text-eggplantRegular'>SITE</span>,
    },
];

const getInsightsDistribution = graphql`
    query AlertListInsightsDistributionQuery($includeCleared: Boolean) {
        insightDistribution(type: Severity, includeCleared: $includeCleared) {
            distribution {
                displayName
                key
                value
            }
        }
    }
`;
