export enum SiteViewPagePath {
    Overview = 'overview',
    Generator = 'generators',
    AcPower = 'ac-power',
    Rectifiers = 'rectifiers',
    Load = 'load',
    Insights = 'insights',
    Batteries = 'batteries',
    History = 'history',
}
