import React, { FC, useCallback, useMemo, useState } from 'react';

import { SearchBox } from '@accesstel/pcm-ui';

import { PredefinedTimeRange } from './common';

interface Option {
    type: 'predefined' | 'duration';
    title: string;
    range?: PredefinedTimeRange;
}

export interface PredefinedSelectionsProps {
    ranges: PredefinedTimeRange[];
    selected: PredefinedTimeRange | null;
    onSelect: (range: PredefinedTimeRange) => void;
    onCustomTimeRangeClick: () => void;
}

export const PredefinedSelections: FC<PredefinedSelectionsProps> = ({
    ranges,
    selected,
    onSelect,
    onCustomTimeRangeClick,
}) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const options = useMemo<Option[]>(
        () => [
            ...ranges.map<Option>(range => ({
                type: 'predefined',
                title: range.title,
                range,
            })),
            { type: 'duration', title: 'Custom time range...' },
        ],
        [ranges]
    );

    const searchMatch = searchValue.toLowerCase();

    const matchingOptions = options.filter(option => option.title.toLowerCase().includes(searchMatch));

    const handleResultClick = useCallback(
        (option: Option) => {
            if (option.type === 'predefined') {
                onSelect(option.range!);
            } else if (option.type === 'duration') {
                onCustomTimeRangeClick();
            }
        },
        [onCustomTimeRangeClick, onSelect]
    );

    const handleSearch = () => {
        if (matchingOptions.length > 0) {
            handleResultClick(matchingOptions[0]);
            setSearchValue('');
        }
    };

    return (
        <SearchBox
            id='filterSelect'
            variant='filter'
            renderResult={option => {
                if (selected === option.range) {
                    return <span className='text-coralRegular'>{option.title}</span>;
                } else {
                    return option.title;
                }
            }}
            searchResults={matchingOptions}
            value={searchValue}
            onChange={setSearchValue}
            onSubmit={handleSearch}
            onResultClick={handleResultClick}
            placeHolder='Search'
            autoFocus={true}
        />
    );
};
