import { useSearchParams } from 'react-router-dom';

import { SortDirection } from 'layouts';

export const SortColumnURLParamName = 'sort-column';
export const SortDirectionURLParamName = 'sort-direction';

const isSortDirectionURLParam = (value: string | undefined | null): value is SortDirection => {
    if (!value) {
        return false;
    }

    return value === SortDirection.Ascending || value === SortDirection.Descending;
};

/**
 *
 * @param columns Columns that the table can handle sorting by
 * @returns The sort column and direction if they are present in the URL, otherwise undefined
 *
 */
export function useSearchParamSortBy<ColumnType extends string>(
    columns: ColumnType[]
): { sortColumn: ColumnType; sortDirection: SortDirection } | undefined {
    const [params] = useSearchParams();

    const sortColumnParam = params.get(SortColumnURLParamName);
    const sortDirectionParam: SortDirection = isSortDirectionURLParam(params.get(SortDirectionURLParamName))
        ? (params.get(SortDirectionURLParamName) as SortDirection)
        : SortDirection.Ascending;

    if (sortColumnParam) {
        const sortColumn = columns.find(column => column.toLowerCase() === sortColumnParam);

        if (sortColumn) {
            return {
                sortColumn: sortColumn,
                sortDirection: sortDirectionParam,
            };
        }
    }

    return undefined;
}

export function createSortParams<ColumnType extends string>(
    column: ColumnType,
    sortDirection: SortDirection
): Record<string, string> {
    return {
        [SortColumnURLParamName]: column.toLowerCase(),
        [SortDirectionURLParamName]: sortDirection,
    };
}
