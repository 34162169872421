/**
 * @generated SignedSource<<f80f5477a035c1c148826a4f8fb2db95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BooleanFilter = {
  value: boolean;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type DeviceIncidentListQuery$variables = {
  deviceId: string;
  showSignificant?: BooleanFilter | null;
  startRange?: DateRangeFilter | null;
};
export type DeviceIncidentListQuery$data = {
  readonly device: {
    readonly acPower: {
      readonly " $fragmentSpreads": FragmentRefs<"DeviceIncidentList_acPower">;
    };
  } | null;
};
export type DeviceIncidentListQuery = {
  response: DeviceIncidentListQuery$data;
  variables: DeviceIncidentListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showSignificant"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startRange"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deviceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceIncidentListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceACPower",
            "kind": "LinkedField",
            "name": "acPower",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "showSignificant",
                    "variableName": "showSignificant"
                  },
                  {
                    "kind": "Variable",
                    "name": "startRange",
                    "variableName": "startRange"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "DeviceIncidentList_acPower"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceIncidentListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceACPower",
            "kind": "LinkedField",
            "name": "acPower",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "isSignificant",
                        "variableName": "showSignificant"
                      },
                      {
                        "kind": "Variable",
                        "name": "startTime",
                        "variableName": "startRange"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "filters"
                  },
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": {
                      "dir": "Desc",
                      "field": "StartTime"
                    }
                  },
                  {
                    "kind": "Literal",
                    "name": "pageSize",
                    "value": 5
                  }
                ],
                "concreteType": "PaginatedACPowerEvent",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ACPowerEvent",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "worstStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "voltage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "affectedAllFeeds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ACPowerEventFeed",
                        "kind": "LinkedField",
                        "name": "affectedFeeds",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3df938cbd95bcb4ca28fd764e015ae76",
    "id": null,
    "metadata": {},
    "name": "DeviceIncidentListQuery",
    "operationKind": "query",
    "text": "query DeviceIncidentListQuery(\n  $deviceId: ID!\n  $showSignificant: BooleanFilter\n  $startRange: DateRangeFilter\n) {\n  device(id: $deviceId) {\n    acPower {\n      ...DeviceIncidentList_acPower_2g0nl5\n    }\n    id\n  }\n}\n\nfragment DeviceIncidentList_acPower_2g0nl5 on DeviceACPower {\n  events(filters: {isSignificant: $showSignificant, startTime: $startRange}, orderBy: {field: StartTime, dir: Desc}, pageSize: 5) {\n    data {\n      ...DeviceIncidentRow_event\n      id\n    }\n  }\n}\n\nfragment DeviceIncidentRow_event on ACPowerEvent {\n  startTime\n  duration\n  worstStatus\n  voltage\n  affectedAllFeeds\n  affectedFeeds {\n    id\n    label\n  }\n}\n"
  }
};
})();

(node as any).hash = "b0be90c2c8fd854317e26b721043628d";

export default node;
