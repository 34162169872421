/**
 * @generated SignedSource<<a94b7245ace13452dabb6a297c89cf71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type reducerCurrentUserTablePreferenceQuery$variables = {
  tableId: string;
};
export type reducerCurrentUserTablePreferenceQuery$data = {
  readonly currentUser: {
    readonly tablePreferences: {
      readonly columns: ReadonlyArray<string> | null;
      readonly id: string;
    } | null;
  };
};
export type reducerCurrentUserTablePreferenceQuery = {
  response: reducerCurrentUserTablePreferenceQuery$data;
  variables: reducerCurrentUserTablePreferenceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tableId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "tableId",
      "variableName": "tableId"
    }
  ],
  "concreteType": "UserTablePreferences",
  "kind": "LinkedField",
  "name": "tablePreferences",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "columns",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reducerCurrentUserTablePreferenceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reducerCurrentUserTablePreferenceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dbf4826ca9944b554349d9feaab8eb8b",
    "id": null,
    "metadata": {},
    "name": "reducerCurrentUserTablePreferenceQuery",
    "operationKind": "query",
    "text": "query reducerCurrentUserTablePreferenceQuery(\n  $tableId: ID!\n) {\n  currentUser {\n    tablePreferences(tableId: $tableId) {\n      id\n      columns\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "404a17c87f0463067db1efd77020eda0";

export default node;
