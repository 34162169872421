/**
 * @generated SignedSource<<d48127824878733c814c3be15fceceea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceFeedCard$data = {
  readonly label: string;
  readonly mtbf: number | null;
  readonly mttr: number | null;
  readonly outage: number;
  readonly overVoltage: number;
  readonly standaloneCount: number;
  readonly underVoltage: number;
  readonly " $fragmentType": "DeviceFeedCard";
};
export type DeviceFeedCard$key = {
  readonly " $data"?: DeviceFeedCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceFeedCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceFeedCard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "underVoltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overVoltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "standaloneCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mttr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mtbf",
      "storageKey": null
    }
  ],
  "type": "DeviceFeedACEventMetrics",
  "abstractKey": null
};

(node as any).hash = "7fdc0eba7a62c2cb9992e758e5e5c8c0";

export default node;
