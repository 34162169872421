import { SiteTableColumn } from 'filters/site';

export const BaseTableColumns: SiteTableColumn[] = [
    SiteTableColumn.Name,
    SiteTableColumn.State,
    SiteTableColumn.BatteryStatus,
    SiteTableColumn.BatteryCapacity,
    SiteTableColumn.BatteryTotalTimeRemaining,
];

export const AllTableColumns: SiteTableColumn[] = [
    ...BaseTableColumns,
    SiteTableColumn.Type,
    SiteTableColumn.DeviceCount,
    SiteTableColumn.Postcode,
];
