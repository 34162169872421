import React, { FC } from 'react';

interface GraySVGPathProps {
    path: string;
}

export const GraySVGPath: FC<GraySVGPathProps> = ({ path }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' className='w-full h-full'>
            <path d={path} fill='none' stroke='currentColor' className='text-grayRegular' strokeWidth={4} />
        </svg>
    );
};
