import React, { useState } from 'react';

import { PageHeading } from '@accesstel/pcm-ui';

import { useDocumentTitle } from '../../components';
import { FirmwareStatusHorizontalBar } from './FirmwareStatusHorizontalBar';
import { FirmwarePageTableQuery } from './FirmwareTableQuery';
import { SiteNameSearch } from './SiteNameSearchQuery';

export function FirmwareStatus(): JSX.Element {
    const [siteNameFilter, setSiteNameFilter] = useState<string>('');
    useDocumentTitle('Firmware Status');

    return (
        <div className='space-y-6'>
            <div>
                <PageHeading value={'Firmware Status'} />
                {/* FIXME: ActiveDevicesCount now uses fragments, this page does not support this */}
                {/* <ActiveDevicesCount valueSuffix='Active Devices' /> */}
                <div className='firmware-status'>
                    <FirmwareStatusHorizontalBar />
                </div>
                <div className='firmware-status-search'>
                    <SiteNameSearch iconClick={setSiteNameFilter} resultClick={setSiteNameFilter} />
                </div>
                <FirmwarePageTableQuery siteNameFilter={siteNameFilter} />
            </div>
        </div>
    );
}
