import React, { FC, useEffect } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import { NavigateTarget, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { getCurrentUserUnitsPref, useCurrentUserUnitsPref } from 'lib/auth';
import { getGlobalEnvironment } from 'lib/environment';
import { Paths } from 'lib/routes';
import { DateTime, Duration } from 'luxon';
import { Subscription } from 'relay-runtime';
import { SiteViewPagePath } from 'views/sites/paths';

import { DevicePane } from '../../components/DevicePane';
import { WrappedDevicePlaceholder } from '../../components/WrappedDevicePlaceholder';
import { RectifierPageDeviceCategories } from '../../lib';
import { DefaultTimeRange, LiveDataRefreshInterval } from '../../settings';
import { getCompanionDevicePlaneParameters } from '../overview/OverviewContent';
import { DeviceRectifiers } from './DeviceRectifiers';
import { RectifiersContentQuery } from './__generated__/RectifiersContentQuery.graphql';

type Device = NonNullable<
    NonNullable<NonNullable<RectifiersContentQuery['response']['device']>['dualPlaneCompanion']>['device']
>;

export interface RectifiersContentProps {
    queryRef: PreloadedQuery<RectifiersContentQuery>;
    timeRange: Duration;
    deviceId: string;
}

export const RectifiersContent: FC<RectifiersContentProps> = ({ queryRef, timeRange, deviceId }) => {
    const data = usePreloadedQuery(ContentQuery, queryRef);
    const environment = useRelayEnvironment();
    const navigate = useExtendedNavigate();
    const unitPreferences = useCurrentUserUnitsPref();

    // Live refresh the data
    useEffect(() => {
        // NOTE: The built-in poll mechanism does not support changing the variables
        let timeout: NodeJS.Timeout;
        let currentSubscription: Subscription | undefined;

        function poll() {
            currentSubscription = fetchQuery<RectifiersContentQuery>(
                environment,
                ContentQuery,
                {
                    id: deviceId,
                    begin: DateTime.local().minus(DefaultTimeRange).toISO(),
                    end: DateTime.local().toISO(),
                    unitTemperature: unitPreferences.temperature,
                },
                { fetchPolicy: 'network-only' }
            ).subscribe({
                complete: () => {
                    timeout = setTimeout(poll, LiveDataRefreshInterval);
                },
            });
        }

        timeout = setTimeout(poll, LiveDataRefreshInterval);

        return () => {
            clearTimeout(timeout);
            currentSubscription?.unsubscribe();
        };
    }, [deviceId, environment, timeRange, unitPreferences.temperature]);

    useEffect(() => {
        if (!data.device) {
            return;
        }

        if (
            data.device.type.category !== '%future added value' &&
            !RectifierPageDeviceCategories.includes(data.device.type.category)
        ) {
            // try to navigate to a power controller on site
            const powerControllers = data.device.site.powerControllers.data;
            if (powerControllers.length > 0) {
                navigate(
                    {
                        pathname: Paths.SiteViewViewSiteDevicePage,
                        params: {
                            siteId: data.device.site.id,
                            deviceId: powerControllers[0].id,
                            page: SiteViewPagePath.Rectifiers,
                        },
                    },
                    { replace: true }
                );
            }
        }
        // NOTE: This effect should only run once at the start to renavigate if the device is not a power controller
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data.device) {
        // NOTE: This should never happen as the device is validated by the DeviceLayout
        return null;
    }

    if (
        data.device.type.category !== '%future added value' &&
        !RectifierPageDeviceCategories.includes(data.device.type.category)
    ) {
        // site has no power controller, show a placeholder to add a power controller
        const url: NavigateTarget = {
            pathname: Paths.AddDevice,
            search: {
                site: data.device.site.id,
            },
        };

        return (
            <div className='grid grid-cols-1 gap-4 min-h-96'>
                <WrappedDevicePlaceholder
                    message='No power controller on this site'
                    buttonText='Add Power Controller'
                    disabledButtonMessage='You do not have permission to add a device'
                    navigateTo={url}
                />
            </div>
        );
    }

    let primaryDevice: Device = data.device;
    let secondaryDevice: Device | undefined | null = data.device.dualPlaneCompanion?.device;

    // Order alphabetically
    if (secondaryDevice && secondaryDevice.name < primaryDevice.name) {
        [primaryDevice, secondaryDevice] = [secondaryDevice, primaryDevice];
    }

    return (
        <div className='grid grid-cols-2 gap-4'>
            {primaryDevice && (
                <DevicePane
                    title={primaryDevice.name}
                    subtitle={primaryDevice.type.displayName}
                    health={primaryDevice.health}
                    lastUpdate={primaryDevice.lastUpdate}
                    lastOnline={primaryDevice.lastOnline}
                >
                    <DeviceRectifiers device={primaryDevice} timeRange={timeRange} deviceId={primaryDevice.id} />
                </DevicePane>
            )}
            {(secondaryDevice && (
                <DevicePane
                    title={secondaryDevice.name}
                    subtitle={secondaryDevice.type.displayName}
                    health={secondaryDevice.health}
                    lastUpdate={secondaryDevice.lastUpdate}
                    lastOnline={secondaryDevice.lastOnline}
                >
                    <DeviceRectifiers device={secondaryDevice} timeRange={timeRange} deviceId={secondaryDevice.id} />
                </DevicePane>
            )) || <WrappedDevicePlaceholder {...getCompanionDevicePlaneParameters(primaryDevice.id)} />}
        </div>
    );
};

export const ContentQuery = graphql`
    query RectifiersContentQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!, $unitTemperature: UnitTemperature) {
        device(id: $id) {
            id
            name
            health
            lastUpdate
            lastOnline
            type {
                displayName
                category
            }
            site {
                id
                powerControllers: devices(filters: { category: [PowerController] }) {
                    data {
                        id
                    }
                }
            }
            ...DeviceRectifiers_device @arguments(unitTemperature: $unitTemperature)

            dualPlaneCompanion {
                device {
                    id
                    name
                    health
                    lastUpdate
                    lastOnline
                    type {
                        displayName
                    }
                    ...DeviceRectifiers_device @arguments(unitTemperature: $unitTemperature)
                }
            }
        }
    }
`;

export async function loadRectifiersPageData(id: string) {
    const unitPreferences = await getCurrentUserUnitsPref();

    return loadQuery(
        getGlobalEnvironment(),
        ContentQuery,
        {
            id,
            begin: DateTime.local().minus(DefaultTimeRange).toISO(),
            end: DateTime.local().toISO(),
            unitTemperature: unitPreferences.temperature,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
