/**
 * @generated SignedSource<<c950bea7c83f0cbc82eac307806b93a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteRemoveResponse = "NotEmpty" | "PartialSuccess" | "Success" | "UnknownSite" | "%future added value";
export type deleteSitesMutation$variables = {
  ids: ReadonlyArray<string>;
};
export type deleteSitesMutation$data = {
  readonly removeSites: SiteRemoveResponse;
};
export type deleteSitesMutation = {
  response: deleteSitesMutation$data;
  variables: deleteSitesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "kind": "ScalarField",
    "name": "removeSites",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteSitesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteSitesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aacefaf6b4366f93e9dfdcba0f76bb0e",
    "id": null,
    "metadata": {},
    "name": "deleteSitesMutation",
    "operationKind": "mutation",
    "text": "mutation deleteSitesMutation(\n  $ids: [ID!]!\n) {\n  removeSites(ids: $ids)\n}\n"
  }
};
})();

(node as any).hash = "200f16d22351ef7e1b57342d497bb70a";

export default node;
