import { TimeRangeFilter } from 'components/FilterTimeRangeSelect/common';
import {
    ACPowerIncidentCountRangeFilterUI,
    ACPowerMTBFRangeFilterUI,
    ACPowerMTTRRangeFilterUI,
    ACPowerReliabilityRankRangeFilterUI,
    ACPowerReliabilityTimeRangeFilterUI,
    ACPowerTotalOutageDurationRangeFilterUI,
    decodeTimeRange,
    encodeTimeRange,
} from 'filters/ac-power';
import {
    DeviceBatteryCapacityTotalFilterUI,
    DeviceBatteryStatusFilter,
    DeviceBatteryStatusFilterUI,
    DeviceNameFilter,
} from 'filters/device';
import { decodeDeviceBatteryStatusFilter, decodeDeviceNameFilter } from 'filters/device/decode';
import { encodeDeviceBatteryStatusFilter, encodeDeviceNameFilter } from 'filters/device/encode';
import { formatRangeWithString } from 'lib/units';
import { intervalToHuman } from 'views/reports/ac-power/common';
import { TimeRange } from 'views/reports/ac-power/settings';

import {
    AttributeTypeMap,
    FilterDefinition,
    FilterValueMap,
    RangeFilter,
    decodeRangeFilter,
    decodeTimeRangeFilter,
    encodeRangeFilter,
    encodeTimeRangeFilter,
} from '../common';
import {
    SiteBatteryTotalTimeRemainingFilterUI,
    SiteDeviceCountFilterUI,
    SiteDeviceNameFilterUI,
    SiteDeviceWithBatteriesFilterUI,
    SiteNameFilterUI,
    SitePostcodeFilterUI,
    SiteStateFilterUI,
    SiteTypeFilterUI,
} from './components';
import { SiteDeviceStatusFilterUI } from './components/SiteDeviceStatusFilterUI';
import {
    decodeSiteDeviceBatteries,
    decodeSiteDeviceStatusFilter,
    decodeSiteNameFilter,
    decodeSitePostcodeFilter,
    decodeSiteStateFilter,
    decodeSiteTypeFilter,
} from './decode';
import {
    encodeSiteDeviceBatteries,
    encodeSiteDeviceStatusFilter,
    encodeSiteNameFilter,
    encodeSitePostcodeFilter,
    encodeSiteStateFilter,
    encodeSiteTypeFilter,
} from './encode';
import {
    SiteDeviceBatteries,
    SiteDeviceStatusFilter,
    SiteExtraFilters,
    SiteNameFilter,
    SitePostcodeFilter,
    SiteStateFilter,
    SiteTableColumn,
    SiteTableColumnId,
    SiteTypeFilter,
} from './types';

export type SiteColumnFilterMap = {
    [SiteTableColumn.Name]: SiteNameFilter[];
    [SiteTableColumn.Type]: SiteTypeFilter[];
    [SiteTableColumn.State]: SiteStateFilter[];
    [SiteTableColumn.Postcode]: SitePostcodeFilter[];
    [SiteTableColumn.DeviceCount]: RangeFilter | null;
    [SiteTableColumn.BatteryCapacity]: RangeFilter | null;
    [SiteTableColumn.BatteryTotalTimeRemaining]: RangeFilter | null;
    [SiteTableColumn.BatteryStatus]: DeviceBatteryStatusFilter[];
    [SiteTableColumn.DeviceStatus]: SiteDeviceStatusFilter[];
    [SiteTableColumn.Mtbf]: TimeRangeFilter | null;
    [SiteTableColumn.Mttr]: TimeRangeFilter | null;
    [SiteTableColumn.ACReliability]: RangeFilter | null;
    [SiteTableColumn.IncidentCount]: RangeFilter | null;
    [SiteTableColumn.OutageDurationSum]: TimeRangeFilter | null;
    [SiteTableColumn.ACRisk]: null;
} & AttributeTypeMap;

export type SiteExtraFilterMap = {
    [SiteExtraFilters.DeviceName]: DeviceNameFilter[];
    [SiteExtraFilters.DeviceWithBatteries]: SiteDeviceBatteries | null;
    [SiteExtraFilters.ACReliabilityTimeRange]: TimeRange | null;
};

export type SiteAllFilterMap = SiteColumnFilterMap & SiteExtraFilterMap;

export const DefaultValues: FilterValueMap<SiteColumnFilterMap> = {
    [SiteTableColumn.Name]: [],
    [SiteTableColumn.Type]: [],
    [SiteTableColumn.State]: [],
    [SiteTableColumn.Postcode]: [],
    [SiteTableColumn.DeviceCount]: null,
    [SiteTableColumn.BatteryCapacity]: null,
    [SiteTableColumn.BatteryTotalTimeRemaining]: null,
    [SiteTableColumn.BatteryStatus]: [],
    [SiteTableColumn.DeviceStatus]: [],
    [SiteTableColumn.Mtbf]: null,
    [SiteTableColumn.Mttr]: null,
    [SiteTableColumn.ACReliability]: null,
    [SiteTableColumn.IncidentCount]: null,
    [SiteTableColumn.OutageDurationSum]: null,
    [SiteTableColumn.ACRisk]: null,
};

export const DefaultExtraFilters: FilterValueMap<SiteExtraFilterMap> = {
    [SiteExtraFilters.DeviceName]: [],
    [SiteExtraFilters.DeviceWithBatteries]: null,
    [SiteExtraFilters.ACReliabilityTimeRange]: null,
};

export const StaticSiteFilterDefinitions: FilterDefinition<SiteTableColumnId>[] = [
    {
        id: SiteTableColumn.Type,
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: SiteTableColumn.Type,
        component: () => SiteTypeFilterUI,
        describeValue: (value: SiteTypeFilter) => value.name,
        encodeValue: encodeSiteTypeFilter,
        decodeValue: decodeSiteTypeFilter,
    },
    {
        id: SiteTableColumn.Name,
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: SiteTableColumn.Name,
        component: () => SiteNameFilterUI,
        describeValue: (value: SiteNameFilter) => value.name,
        encodeValue: encodeSiteNameFilter,
        decodeValue: decodeSiteNameFilter,
    },
    {
        id: SiteTableColumn.State,
        type: 'multi',
        name: 'State',
        category: 'State',
        column: SiteTableColumn.State,
        component: () => SiteStateFilterUI,
        describeValue: (value: SiteStateFilter) => value.state,
        encodeValue: encodeSiteStateFilter,
        decodeValue: decodeSiteStateFilter,
    },
    {
        id: SiteTableColumn.DeviceCount,
        type: 'single',
        name: 'Device Count',
        category: 'Device Count',
        column: SiteTableColumn.DeviceCount,
        component: () => SiteDeviceCountFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: SiteTableColumn.Postcode,
        type: 'multi',
        name: 'Postcode',
        category: 'Postcode',
        column: SiteTableColumn.Postcode,
        component: () => SitePostcodeFilterUI,
        describeValue: (value: SitePostcodeFilter) => value.value,
        encodeValue: encodeSitePostcodeFilter,
        decodeValue: decodeSitePostcodeFilter,
    },
    {
        id: SiteExtraFilters.DeviceName,
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        component: () => SiteDeviceNameFilterUI,
        describeValue: (value: DeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: SiteExtraFilters.DeviceWithBatteries,
        type: 'single',
        name: 'Device With Batteries',
        category: 'Device With Batteries',
        component: () => SiteDeviceWithBatteriesFilterUI,
        describeValue: (value: SiteDeviceBatteries) => value,
        encodeValue: encodeSiteDeviceBatteries,
        decodeValue: decodeSiteDeviceBatteries,
    },
    {
        id: SiteExtraFilters.ACReliabilityTimeRange,
        type: 'single',
        name: 'AC Reliability Time Range',
        category: 'AC Reliability Time Range',
        component: () => ACPowerReliabilityTimeRangeFilterUI,
        describeValue: (value: TimeRange): string => intervalToHuman(value, { prefix: 'Last ' }),
        encodeValue: encodeTimeRange,
        decodeValue: decodeTimeRange,
    },
    {
        id: SiteTableColumn.BatteryStatus,
        type: 'multi',
        name: 'Battery Status',
        category: 'Battery Status',
        column: SiteTableColumn.BatteryStatus,
        component: () => DeviceBatteryStatusFilterUI,
        describeValue: (value: DeviceBatteryStatusFilter) => value.name,
        encodeValue: encodeDeviceBatteryStatusFilter,
        decodeValue: decodeDeviceBatteryStatusFilter,
    },
    {
        id: SiteTableColumn.BatteryCapacity,
        type: 'single',
        name: 'Site Battery Capacity',
        category: 'Site Battery Capacity',
        column: SiteTableColumn.BatteryCapacity,
        component: () => DeviceBatteryCapacityTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: SiteTableColumn.BatteryTotalTimeRemaining,
        type: 'single',
        name: 'Site Battery Total Time Remaining',
        category: 'Site Battery Total Time Remaining',
        column: SiteTableColumn.BatteryTotalTimeRemaining,
        component: () => SiteBatteryTotalTimeRemainingFilterUI,
        describeValue: (value: RangeFilter): string =>
            `${(value.min / 60).toPrecision(2)} to ${(value.max / 60).toPrecision(2)}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: SiteTableColumn.DeviceStatus,
        type: 'multi',
        name: 'Device Status',
        category: 'Device Status',
        column: SiteTableColumn.DeviceStatus,
        component: () => SiteDeviceStatusFilterUI,
        describeValue: (value: SiteDeviceStatusFilter) => value.displayText,
        encodeValue: encodeSiteDeviceStatusFilter,
        decodeValue: decodeSiteDeviceStatusFilter,
    },
    {
        id: SiteTableColumn.ACReliability,
        type: 'single',
        name: 'Reliability Rank',
        category: 'Reliability Rank',
        column: SiteTableColumn.ACReliability,
        component: () => ACPowerReliabilityRankRangeFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: SiteTableColumn.IncidentCount,
        type: 'single',
        name: 'Incident Count',
        category: 'Incident Count',
        column: SiteTableColumn.IncidentCount,
        component: () => ACPowerIncidentCountRangeFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: SiteTableColumn.Mttr,
        type: 'single',
        name: 'MTTR',
        category: 'MTTR',
        column: SiteTableColumn.Mttr,
        component: () => ACPowerMTTRRangeFilterUI,
        describeValue: (value: TimeRangeFilter): string => {
            if (value.type === 'predefined') {
                return value.preset;
            }

            // TODO: Make this better by showing hours/days when appropriate. Be mindful of readability, such as 3.2 hours vs 3 hours 12 minutes
            return formatRangeWithString(value.range.min, value.range.max, 'min');
        },
        encodeValue: encodeTimeRangeFilter,
        decodeValue: decodeTimeRangeFilter,
    },
    {
        id: SiteTableColumn.Mtbf,
        type: 'single',
        name: 'MTBF',
        category: 'MTBF',
        column: SiteTableColumn.Mtbf,
        component: () => ACPowerMTBFRangeFilterUI,
        describeValue: (value: TimeRangeFilter): string => {
            if (value.type === 'predefined') {
                return value.preset;
            }

            // TODO: Make this better by showing hours/days when appropriate. Be mindful of readability, such as 3.2 hours vs 3 hours 12 minutes
            return formatRangeWithString(value.range.min, value.range.max, 'min');
        },
        encodeValue: encodeTimeRangeFilter,
        decodeValue: decodeTimeRangeFilter,
    },
    {
        id: SiteTableColumn.OutageDurationSum,
        type: 'single',
        name: 'Total Time Offline',
        category: 'Total Time Offline',
        column: SiteTableColumn.OutageDurationSum,
        component: () => ACPowerTotalOutageDurationRangeFilterUI,
        describeValue: (value: TimeRangeFilter): string => {
            if (value.type === 'predefined') {
                return value.preset;
            }

            // TODO: Make this better by showing hours/days when appropriate. Be mindful of readability, such as 3.2 hours vs 3 hours 12 minutes
            return formatRangeWithString(value.range.min, value.range.max, 'min');
        },
        encodeValue: encodeTimeRangeFilter,
        decodeValue: decodeTimeRangeFilter,
    },
];
