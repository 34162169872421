import React, { FC, useMemo } from 'react';
import { fetchQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import { ArrowDownIcon, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { SiteIncidentTableColumn, siteIncidentToFilterObject, useSiteIncidentFilter } from 'filters/site-incidents';
import humanizeDuration from 'humanize-duration';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { exportAsCsv } from 'lib/csv-export';
import { getGlobalEnvironment } from 'lib/environment';
import { useQuery } from 'lib/query-helpers';
import { Duration } from 'luxon';

import { SiteIncidentTableAllColumns, SiteIncidentTableBaseColumns } from '../settings';
import { SiteIncidentExpandedRow } from './SiteIncidentExpandedRow';
import { ACPowerSiteIncidentListTableExportQuery } from './__generated__/ACPowerSiteIncidentListTableExportQuery.graphql';
import {
    ACPowerEventOrdering,
    ACPowerEventSortField,
    ACPowerEventStatus,
    ACPowerSiteIncidentListTableQuery,
    ACPowerSiteIncidentListTableQuery$data,
    ACPowerSiteIncidentListTableQuery$variables,
} from './__generated__/ACPowerSiteIncidentListTableQuery.graphql';

export type SiteIncidents = NonNullable<
    ACPowerSiteIncidentListTableQuery$data['site']
>['acPower']['events']['data'][number];

const TableStorageKeyPrefix = 'ac-power-sites-incident-table';

export const ACPowerSiteIncidentList: FC = () => {
    const environment = getGlobalEnvironment();
    const { show } = useToast();

    const { siteId } = useParams() as { siteId: string };
    useDocumentTitle('Reports - AC Power - Sites - Incident List');
    const [filters, dispatchFilters] = useSiteIncidentFilter();

    const filterObject = useMemo(() => siteIncidentToFilterObject(filters), [filters]);

    const [tableState, dispatchTableState] = useTableReducer<SiteIncidentTableColumn>({
        defaultSortColumn: SiteIncidentTableColumn.IncidentStartTime,
        defaultSortDirection: SortDirection.Ascending,
        allColumns: SiteIncidentTableAllColumns,
        defaultVisibleColumns: SiteIncidentTableBaseColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const sortObject: ACPowerEventOrdering = {
        field: tableState.sortColumn as ACPowerEventSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: ACPowerSiteIncidentListTableQuery$variables = {
        page: tableState.page,
        siteId: siteId,
        filter: filterObject,
        orderBy: sortObject,
    };

    const {
        data: props,
        error,
        retry,
        isFetching,
    } = useQuery<ACPowerSiteIncidentListTableQuery>(
        graphql`
            query ACPowerSiteIncidentListTableQuery(
                $page: Int!
                $siteId: ID!
                $filter: ACPowerEventFilter
                $orderBy: ACPowerEventOrdering
            ) {
                site(id: $siteId) {
                    acPower {
                        events(page: $page, filters: $filter, orderBy: $orderBy) {
                            total
                            pageInfo {
                                page
                                total
                                hasNext
                                hasPrevious
                            }
                            data {
                                id
                                affectedAllDevices
                                duration
                                startTime
                                worstStatus
                                site {
                                    id
                                    name
                                }
                                affectedFeeds {
                                    id
                                    label
                                    status
                                    voltage
                                }
                                affectedDevices {
                                    id
                                    name
                                }
                                ...SiteIncidentExpandedRow_event
                            }
                        }
                    }
                }
                overallIncidents: site(id: $siteId) {
                    acPower {
                        events(page: $page) {
                            total
                        }
                    }
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const exportSiteIncidents = async () => {
        show({ text: 'Exporting...', variant: 'info' });
        const exportVariables = {
            siteId: siteId,
        };

        try {
            const results = await fetchQuery<ACPowerSiteIncidentListTableExportQuery>(
                environment,
                SiteIncidentsQuery,
                exportVariables
            ).toPromise();

            if (!results) {
                show({ text: 'Failed to export', variant: 'error' });
                return;
            }

            const fileName = `ac-power-site-incidents-${siteId}.csv`;

            const customHeader = [
                'Start Time',
                'Site ID',
                'Site Name',
                `Device IDs`,
                `Device Names`,
                'Duration (Minutes)',
                'Incident Type',
                `Affected Feed IDs`,
                `Affected Feed Statuses`,
                `Affected Feed Voltages`,
            ];

            const rows = results!.site!.acPower.events.data.map(incident => {
                const startTime = incident.startTime;
                const siteId = incident.site.id;
                const siteName = incident.site.name;
                const deviceId = incident.affectedDevices.map(device => device.id);
                const deviceName = incident.affectedDevices.map(device => device.name);
                const duration = humanizeDuration(Duration.fromISO(incident.duration).as('milliseconds'), {
                    largest: 1,
                    round: true,
                    units: ['h', 'm'],
                });
                const incidentType = getSiteACPowerStatus(incident.worstStatus);
                const affectedFeedName = incident.affectedFeeds.map(feed => feed.label);
                const affectedFeedStatus = incident.affectedFeeds.map(feed => {
                    if (feed.status === 'Outage') {
                        return 'Outage';
                    } else if (feed.status === 'OverVoltage') {
                        return 'Overvoltage';
                    } else if (feed.status === 'UnderVoltage') {
                        return 'Undervoltage';
                    } else {
                        return 'Normal';
                    }
                });
                const affectedFeedVoltage = incident.affectedFeeds.map(feed => feed.voltage);
                const row = [
                    `"${startTime}"`,
                    `"${siteId}"`,
                    `"${siteName}"`,
                    `"${deviceId}"`,
                    `"${deviceName}"`,
                    `"${duration}"`,
                    `"${incidentType}"`,
                    `"${affectedFeedName}"`,
                    `"${affectedFeedStatus}"`,
                    `"${affectedFeedVoltage ?? ''}"`,
                ];

                return row;
            });

            exportAsCsv(fileName, customHeader, rows);

            show({ text: 'Exported successfully', variant: 'info' });
        } catch (error) {
            captureException(error, scope => {
                scope.setExtra('variables', exportVariables);
                scope.setTag('Component', 'ACPowerSiteIncidentList');
                scope.setTag('Functionality', 'Exporting');
                return scope;
            });
            show({ text: 'Failed to export', variant: 'error' });
        }
    };

    return (
        <TableLayout
            title='AC Power Site Incidents'
            columns={SiteIncidentTableAllColumns}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={props?.site?.acPower.events.data ?? null}
            isProcessing={!!props && isFetching}
            getRowId={(row: SiteIncidents) => row.id}
            page={props?.site?.acPower.events.pageInfo?.page}
            pageCount={props?.site?.acPower.events.pageInfo?.total}
            overallCount={props?.overallIncidents?.acPower.events.total}
            resultCount={props?.site?.acPower.events.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder=''
            renderSearchResultAsString={() => ''}
            emptyMessage='There are no site incidents to display.'
            unit='Incident'
            additionalActions={[
                {
                    buttonText: 'Export',
                    buttonIcon: <ArrowDownIcon />,
                    onClick: () => exportSiteIncidents(),
                },
            ]}
            rowExpansionComponent={row => <SiteIncidentExpandedRow key={row.id} row={row.original} />}
        />
    );
};

const SiteIncidentsQuery = graphql`
    query ACPowerSiteIncidentListTableExportQuery($siteId: ID!) {
        site(id: $siteId) {
            acPower {
                events(pageSize: 10000) {
                    data {
                        duration
                        startTime
                        worstStatus
                        site {
                            id
                            name
                        }
                        affectedFeeds {
                            label
                            status
                            voltage
                        }
                        affectedDevices {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

function getSiteACPowerStatus(itemStatus: ACPowerEventStatus): string {
    switch (itemStatus) {
        case 'Outage': {
            return 'Outage';
        }
        case 'OverVoltage': {
            return 'Overvoltage';
        }
        case 'UnderVoltage': {
            return 'Undervoltage';
        }
        default:
            return 'Normal';
    }
}
