import React, { FC } from 'react';

import { Checkbox } from '@accesstel/pcm-ui';

export interface HeaderColumnHeaderProps {
    isHighlightingDifferences?: boolean;
    onHighlightDifferencesChange?: (isHighlightingDifferences: boolean) => void;
}

export const HeaderColumnHeader: FC<HeaderColumnHeaderProps> = ({
    isHighlightingDifferences,
    onHighlightDifferencesChange,
}) => {
    return (
        <div className='row-start-1 col-start-1 flex flex-col justify-end px-2 pb-2 lg:px-4'>
            <Checkbox
                onChange={event => onHighlightDifferencesChange?.(event.target.checked)}
                checked={isHighlightingDifferences}
                placeHolder='Highlight Differences'
            />
        </div>
    );
};
