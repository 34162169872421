import React from 'react';
import { RouteObject } from 'react-router-dom';

import { RouteExtraData } from 'lib/route-helpers';

import { Dashboard } from './Dashboard';

const Routes: RouteObject[] = [
    {
        index: true,
        element: <Dashboard />,
        handle: {
            title: 'Overview',
            altTitle: 'Overview',
            description: 'The overview of the system.',
        } as RouteExtraData,
    },
];

export default Routes;
