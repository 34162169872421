import { Dispatch } from 'react';

import { FilterAction, FilterState, useFilterReducer } from 'filters/common';

import {
    BatteryTestResultColumnFilterMap,
    DefaultExtraValues,
    DefaultValues,
    StaticBatteryTestResultFilterDefinitions,
} from './settings';
import { BatteryTestResultsColumn } from './types';

export type BatteryTestResultFilterState = FilterState<BatteryTestResultsColumn, BatteryTestResultColumnFilterMap>;
export type BatteryTestResultFilterAction = FilterAction<BatteryTestResultColumnFilterMap, BatteryTestResultsColumn>;

export function useTestResultFilter(
    testIds?: string[]
): [BatteryTestResultFilterState, Dispatch<BatteryTestResultFilterAction>] {
    const [state, updateState] = useFilterReducer<
        BatteryTestResultsColumn,
        BatteryTestResultColumnFilterMap,
        BatteryTestResultFilterState,
        BatteryTestResultFilterAction
    >('test-result-filter', DefaultValues, StaticBatteryTestResultFilterDefinitions, DefaultExtraValues, { testIds });

    return [state, updateState];
}

export function batteryTestResultToFilterObject(filters: BatteryTestResultFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        TaskName: taskName,
        Type: types,
        State: states,
        RunTime: runTime,
        StartTime: startTime,
        DeviceName: deviceName,
    } = filters.columnValues;

    if (taskName.length > 0) {
        output.taskName = taskName.map(filter => ({ value: filter.name }));
    }

    if (types.length > 0) {
        output.taskType = types;
    }

    if (states.length > 0) {
        output.state = states.map(filter => filter.id);
    }

    if (runTime) {
        output.runTime = {
            min: runTime.range[0],
            max: runTime.range[1],
        };
    }

    if (startTime) {
        output.startTime = {
            min: startTime.range[0].toISOString(),
            max: startTime.range[1].toISOString(),
        };
    }

    if (deviceName.length > 0) {
        output.deviceName = deviceName.map(filter => ({ value: filter.name }));
    }

    return output;
}
