import { useSearchParams } from 'react-router-dom';

import {
    AttributeFilter,
    FilterDefinition,
    decodeCustomAttributeFilter,
    encodeCustomAttributeFilter,
} from 'filters/common';

type FilterByResponse = {
    columnFilters: Record<string, unknown>;
    extraFilters: Record<string, unknown>;
};

export const URLFilterPrefix = 'filter-';
const URLCustomAttributeFilterPrefix = 'filter-custom-';

/**
 *
 * @param definitions The definition of available filter. This is used to decode the filter value from the URL
 * @returns The column filters and extra filters decoded from the URL
 */
export function useSearchParamFilterBy<ColumnType extends string>(
    definitions: FilterDefinition<ColumnType>[]
): FilterByResponse {
    const [searchParams] = useSearchParams();
    const columnFilters: Record<string, unknown> = {};
    const extraFilters: Record<string, unknown> = {};

    for (const [key, value] of Array.from(searchParams)) {
        if (key.startsWith(URLCustomAttributeFilterPrefix)) {
            const attributeName = key.replace(URLCustomAttributeFilterPrefix, '');
            const decodedValues = decodeFilter('multi', decodeCustomAttributeFilter, value);

            if (!decodedValues) {
                // eslint-disable-next-line no-console
                console.warn('Failed to decode custom attributes', attributeName, value);
                continue;
            }

            if (!Array.isArray(decodedValues)) {
                // eslint-disable-next-line no-console
                console.warn('Decoded custom attributes is not an array', attributeName, value);
                continue;
            }

            extraFilters[`tag_${attributeName}`] = decodedValues;
        } else if (key.startsWith(URLFilterPrefix)) {
            const filterName = key.replace(URLFilterPrefix, '');
            const definition = definitions.find(
                definition => definition.id.toLocaleLowerCase() === filterName.toLocaleLowerCase()
            ); // NOTE: definition comparison is case-insensitive

            if (!definition) {
                // eslint-disable-next-line no-console
                console.warn('Definition not found for', filterName);
                continue;
            }

            const decodedValue = decodeFilter(definition.type, definition.decodeValue, value);

            if (!decodedValue) {
                // eslint-disable-next-line no-console
                console.warn('Failed to decode', filterName, value);
                continue;
            }

            if (definition.column) {
                columnFilters[definition.id] = decodedValue;
            } else if (definition.name) {
                extraFilters[definition.id] = decodedValue;
            }
        }
    }

    return { columnFilters, extraFilters };
}

function decodeFilter(type: 'single' | 'multi', decodeFn: (value: string) => unknown, value: string): unknown | null {
    if (type === 'single') {
        return decodeSingleFilter(decodeFn, value);
    }

    if (type === 'multi') {
        return decodeMultiFilter(decodeFn, value);
    }
}

function decodeSingleFilter(decodeFn: (value: string) => unknown, value: string): unknown | null {
    const decodedValue = decodeFn(value);
    if (decodedValue) {
        return decodedValue;
    }

    return null;
}

function decodeMultiFilter(decodeFn: (value: string) => unknown, rawValues: string): unknown | null {
    const Pattern = /^([^,]+)(?:,([^,]+))*$/;

    if (Pattern.test(rawValues)) {
        const values = rawValues.split(',');
        const decodedValues = values.map(decodeFn).filter(decoded => decoded !== null);
        if (decodedValues.length > 0) {
            return decodedValues;
        }
    }

    return null;
}

/**
 *
 * @param filters The filter object to encode
 * @param definitions The static definition of the available filter. This is used to encode the filter value to the URL
 * @param customAttributes The custom attributes to encode
 * @returns an object that can be spread into the URL search params
 */
export function encodeFilterParameters<ColumnType extends string, FilterValueMap>(
    filters: Partial<FilterValueMap>,
    definitions: FilterDefinition<ColumnType>[],
    customAttributes?: Record<string, AttributeFilter[]>
): Record<string, string> {
    const params = {} as Record<string, string>;

    for (const [key, value] of Object.entries(filters)) {
        if (value == null) {
            continue;
        }

        const definition = definitions.find(
            definition => definition.id.toLocaleLowerCase() === key.toLocaleLowerCase()
        ); // NOTE: definition comparison is case-insensitive

        if (!definition) {
            // eslint-disable-next-line no-console
            console.warn('Definition not found for', key);
            continue;
        }

        const encodedValue = encodeFilter(definition.type, definition.encodeValue, value);

        if (!encodedValue) {
            // eslint-disable-next-line no-console
            console.warn('Failed to encode', key, value);
            continue;
        }

        params[`${URLFilterPrefix}${definition.id.toLocaleLowerCase()}`] = encodedValue;
    }

    // NOTE: Custom Attributes are encoded directly using the encoder function (Does not rely on definition as it can be dynamic)
    for (const [name, values] of Object.entries(customAttributes || {})) {
        const encodedValues = encodeFilter('multi', encodeCustomAttributeFilter as (value: unknown) => string, values);

        if (!encodedValues) {
            // eslint-disable-next-line no-console
            console.warn('Failed to encode custom attributes', name, values);
            continue;
        }

        params[`${URLCustomAttributeFilterPrefix}${name.toLocaleLowerCase()}`] = encodedValues;
    }

    return params;
}

function encodeFilter(type: 'single' | 'multi', encodeFn: (value: unknown) => string, value: unknown) {
    if (type === 'single') {
        const encodedValue = encodeFn(value);
        if (encodedValue) {
            return encodedValue;
        }
    }

    if (type === 'multi' && Array.isArray(value)) {
        const encodedValues = value.map(encodeFn).filter(encoded => encoded !== null);
        if (encodedValues.length > 0) {
            return encodedValues.join(',');
        }
    }

    return null;
}
