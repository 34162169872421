import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { UserPermissions, useUserPermissions } from 'lib/auth';
import { Permissions } from 'lib/route-helpers';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

export type AuthenticatedSectionProps = Permissions;

/**
 * A component that renders its children only if the user has the required permissions.
 * When multiple permissions are set, the user must have at least one of them.
 *
 */
export const AuthenticatedSection: FC<AuthenticatedSectionProps> = requiredPermissions => {
    const permissions = useUserPermissions();

    const allowed = isAllowedAccess(requiredPermissions, permissions);

    if (!allowed) {
        return <ErrorNotFound />;
    }

    return <Outlet />;
};

export function isAllowedAccess(requiredPermissions: Permissions, userPermissions: UserPermissions) {
    let allowed = false;

    if (requiredPermissions.administrator && userPermissions.hasAdministration) {
        allowed = true;
    }

    if (requiredPermissions.auditor && userPermissions.hasAuditRead) {
        allowed = true;
    }

    if (requiredPermissions.assetsRead && userPermissions.hasAssetsRead) {
        allowed = true;
    }

    if (requiredPermissions.assetsWrite && userPermissions.hasAssetsWrite) {
        allowed = true;
    }

    if (requiredPermissions.tasksRead && userPermissions.hasTasksRead) {
        allowed = true;
    }

    if (requiredPermissions.tasksWrite && userPermissions.hasTasksWrite) {
        allowed = true;
    }

    return allowed;
}
