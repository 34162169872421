/**
 * @generated SignedSource<<e48770c0258ee8c50fa892466c5b96b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentCapacityDoughnut$data = {
  readonly batteryMetrics: {
    readonly remainingCapacityBreakdown: ReadonlyArray<{
      readonly deviceCount: number;
      readonly rangeHigh: number;
      readonly rangeLow: number;
    }> | null;
  };
  readonly " $fragmentType": "CurrentCapacityDoughnut";
};
export type CurrentCapacityDoughnut$key = {
  readonly " $data"?: CurrentCapacityDoughnut$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentCapacityDoughnut">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentCapacityDoughnut",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalBatteryMetrics",
      "kind": "LinkedField",
      "name": "batteryMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CapacityBreakdown",
          "kind": "LinkedField",
          "name": "remainingCapacityBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rangeLow",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rangeHigh",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "23547b37b5afbc8a56004f560c6b4a87";

export default node;
