import React, { FC, useEffect } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { getGlobalEnvironment } from 'lib/environment';
import { DateTime, Duration } from 'luxon';
import { Subscription } from 'relay-runtime';

import { DevicePane } from '../../components/DevicePane';
import { WrappedDevicePlaceholder } from '../../components/WrappedDevicePlaceholder';
import { DefaultTimeRange, LiveDataRefreshInterval } from '../../settings';
import { getCompanionDevicePlaneParameters } from '../overview/OverviewContent';
import { DeviceACPower } from './DeviceACPower';
import { ACPowerContentQuery } from './__generated__/ACPowerContentQuery.graphql';

type Device = NonNullable<
    NonNullable<NonNullable<ACPowerContentQuery['response']['device']>['dualPlaneCompanion']>['device']
>;

export interface ACPowerContentProps {
    queryRef: PreloadedQuery<ACPowerContentQuery>;
    timeRange: Duration;
    deviceId: string;
}

export const ACPowerContent: FC<ACPowerContentProps> = ({ queryRef, timeRange, deviceId }) => {
    const data = usePreloadedQuery(ContentQuery, queryRef);
    const environment = useRelayEnvironment();

    // Live refresh the data
    useEffect(() => {
        // NOTE: The built-in poll mechanism does not support changing the variables
        let timeout: NodeJS.Timeout;
        let currentSubscription: Subscription | undefined;

        function poll() {
            currentSubscription = fetchQuery<ACPowerContentQuery>(
                environment,
                ContentQuery,
                {
                    id: deviceId,
                    begin: DateTime.local().minus(DefaultTimeRange).toISO(),
                    end: DateTime.local().toISO(),
                },
                { fetchPolicy: 'network-only' }
            ).subscribe({
                complete: () => {
                    timeout = setTimeout(poll, LiveDataRefreshInterval);
                },
            });
        }

        timeout = setTimeout(poll, LiveDataRefreshInterval);

        return () => {
            clearTimeout(timeout);
            currentSubscription?.unsubscribe();
        };
    }, [deviceId, environment, timeRange]);

    if (!data.device) {
        // NOTE: This should never happen as the device is validated by the DeviceLayout
        return null;
    }

    let primaryDevice: Device = data.device;
    let secondaryDevice: Device | undefined | null = data.device.dualPlaneCompanion?.device;

    // Order alphabetically
    if (secondaryDevice && secondaryDevice.name < primaryDevice.name) {
        [primaryDevice, secondaryDevice] = [secondaryDevice, primaryDevice];
    }

    return (
        <div className='grid grid-cols-2 gap-4'>
            {primaryDevice && (
                <DevicePane
                    title={primaryDevice.name}
                    subtitle={primaryDevice.type.displayName}
                    health={primaryDevice.health}
                    lastUpdate={primaryDevice.lastUpdate}
                    lastOnline={primaryDevice.lastOnline}
                >
                    <DeviceACPower device={primaryDevice} timeRange={timeRange} deviceId={primaryDevice.id} />
                </DevicePane>
            )}

            {(secondaryDevice && (
                <DevicePane
                    title={secondaryDevice.name}
                    subtitle={secondaryDevice.type.displayName}
                    health={secondaryDevice.health}
                    lastUpdate={secondaryDevice.lastUpdate}
                    lastOnline={secondaryDevice.lastOnline}
                >
                    <DeviceACPower device={secondaryDevice} timeRange={timeRange} deviceId={secondaryDevice.id} />
                </DevicePane>
            )) || <WrappedDevicePlaceholder {...getCompanionDevicePlaneParameters(primaryDevice.id)} />}
        </div>
    );
};

export const ContentQuery = graphql`
    query ACPowerContentQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!) {
        device(id: $id) {
            id
            name
            health
            lastUpdate
            lastOnline
            type {
                displayName
            }
            ...DeviceACPower_device

            dualPlaneCompanion {
                device {
                    id
                    name
                    health
                    lastUpdate
                    lastOnline
                    type {
                        displayName
                    }
                    ...DeviceACPower_device
                }
            }
        }
    }
`;

export function loadACPowerPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        ContentQuery,
        {
            id,
            begin: DateTime.local().minus(DefaultTimeRange).toISO(),
            end: DateTime.local().toISO(),
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
