import { useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DomainAbsolute } from '@accesstel/pcm-ui';

import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { LastWeek } from 'components/FilterDateSelect/presets';
import isEqual from 'lodash.isequal';
import { DateTime, Duration } from 'luxon';

import { MetricsExploreContentQuery$data } from './__generated__/MetricsExploreContentQuery.graphql';
import { decodeDeviceIds, decodeMetricTimeRange, decodeMetrics } from './lib/decode';
import { DeviceIdParam, MetricParam, MinimumZoomLevel, TimeRangeParam } from './settings';
import { SelectedMetric, ViewState } from './types';

export enum ActionType {
    SetSelectedMetrics,
    SetSelectedDevices,
    SetTimeRange,
    ShowDeviceSelectionModal,
    HideDeviceSelectionModal,
    ShowMetricSelectionModal,
    HideMetricSelectionModal,
    ZoomTo,
    ResetZoom,
}

export type Action =
    | { type: ActionType.SetSelectedMetrics; metrics: SelectedMetric[] }
    | { type: ActionType.SetSelectedDevices; devices: string[] }
    | { type: ActionType.SetTimeRange; range: DateRangeFilter }
    | { type: ActionType.ShowDeviceSelectionModal }
    | { type: ActionType.HideDeviceSelectionModal }
    | { type: ActionType.ShowMetricSelectionModal }
    | { type: ActionType.HideMetricSelectionModal }
    | { type: ActionType.ZoomTo; range: DomainAbsolute<Date> }
    | { type: ActionType.ResetZoom };

function reducer(state: ViewState, action: Action): ViewState {
    switch (action.type) {
        case ActionType.SetSelectedMetrics:
            if (isEqual(state.selectedMetrics, action.metrics)) {
                return state;
            }

            return {
                ...state,
                selectedMetrics: action.metrics,
            };
        case ActionType.SetSelectedDevices:
            if (isEqual(state.selectedDevices, action.devices)) {
                return state;
            }

            return {
                ...state,
                selectedDevices: action.devices,
            };
        case ActionType.SetTimeRange:
            if (isEqual(state.timeRange, action.range)) {
                return state;
            }

            return {
                ...state,
                timeRange: action.range,
                zoomedTimeRange: action.range.range,
            };
        case ActionType.ShowDeviceSelectionModal:
            if (state.showDeviceSelectionModal) {
                return state;
            }

            return {
                ...state,
                showDeviceSelectionModal: true,
            };
        case ActionType.HideDeviceSelectionModal:
            if (!state.showDeviceSelectionModal) {
                return state;
            }

            return {
                ...state,
                showDeviceSelectionModal: false,
            };
        case ActionType.ShowMetricSelectionModal:
            if (state.showMetricSelectionModal) {
                return state;
            }

            return {
                ...state,
                showMetricSelectionModal: true,
            };
        case ActionType.HideMetricSelectionModal:
            if (!state.showMetricSelectionModal) {
                return state;
            }

            return {
                ...state,
                showMetricSelectionModal: false,
            };
        case ActionType.ZoomTo: {
            const totalDuration = Duration.fromMillis(action.range[1].getTime() - action.range[0].getTime());

            let isAtMaxZoom = false;
            // Don't allow zooming in too far
            if (totalDuration <= MinimumZoomLevel) {
                action.range = [
                    action.range[0],
                    DateTime.fromJSDate(action.range[0]).plus(MinimumZoomLevel).toJSDate(),
                ];
                isAtMaxZoom = true;
            }

            if (isEqual(state.zoomedTimeRange, action.range) && state.isAtMaxZoom === isAtMaxZoom) {
                return state;
            }

            return {
                ...state,
                zoomedTimeRange: action.range,
                isZoomed: true,
                isAtMaxZoom,
            };
        }
        case ActionType.ResetZoom:
            if (isEqual(state.zoomedTimeRange, state.timeRange.range) && !state.isAtMaxZoom && !state.isZoomed) {
                return state;
            }

            return {
                ...state,
                zoomedTimeRange: state.timeRange.range,
                isZoomed: false,
                isAtMaxZoom: false,
            };
        default:
            return state;
    }
}

export function useViewReducer(metadata: MetricsExploreContentQuery$data) {
    const [searchParams] = useSearchParams();

    return useReducer(reducer, undefined, () => {
        // Load the selected devices from the URL
        let selectedDevices: string[] | undefined;
        if (searchParams.has(DeviceIdParam)) {
            const rawDeviceIds = searchParams.get(DeviceIdParam);
            const decodedDeviceIds = rawDeviceIds ? decodeDeviceIds(rawDeviceIds) : null;

            if (decodedDeviceIds) {
                selectedDevices = decodedDeviceIds.filter(deviceId =>
                    metadata.devices.data.find(device => device.id === deviceId)
                );
            }
        }

        // Load the selected metrics from the URL
        let selectedMetrics: SelectedMetric[] | undefined;
        if (searchParams.has(MetricParam)) {
            const rawMetrics = searchParams.get(MetricParam);
            const decodedMetrics = rawMetrics ? decodeMetrics(rawMetrics) : null;

            if (decodedMetrics) {
                selectedMetrics = decodedMetrics;
            }
        }

        // Load the time range from the URL
        let timeRange: DateRangeFilter | undefined;
        if (searchParams.has('timeRange')) {
            const rawTimeRange = searchParams.get(TimeRangeParam);
            const decodedTimeRange = rawTimeRange ? decodeMetricTimeRange(rawTimeRange) : null;

            if (decodedTimeRange) {
                timeRange = decodedTimeRange;
            }
        }

        const initialState: ViewState = {
            selectedMetrics: selectedMetrics ?? [],
            selectedDevices: selectedDevices ?? [],
            timeRange: timeRange ?? {
                type: 'predefined',
                preset: LastWeek.title,
                range: LastWeek.asDateRange(),
            },
            zoomedTimeRange: timeRange ? [timeRange.range[0], timeRange.range[1]] : LastWeek.asDateRange(),
            isZoomed: false,
            isAtMaxZoom: false,
            showDeviceSelectionModal: false,
            showMetricSelectionModal: false,
        };

        return initialState;
    });
}
