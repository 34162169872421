/**
 * @generated SignedSource<<320a6224fce8a3888b6d3b948b975895>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type TestResultListSearchQuery$variables = {
  pageSize: number;
  search?: string | null;
};
export type TestResultListSearchQuery$data = {
  readonly unplannedTests: {
    readonly data: ReadonlyArray<{
      readonly cause: BatteryTestReason;
      readonly commencedTime: string | null;
      readonly device: {
        readonly name: string;
      };
      readonly id: string;
      readonly name: string | null;
    }>;
  };
};
export type TestResultListSearchQuery = {
  response: TestResultListSearchQuery$data;
  variables: TestResultListSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v2 = [
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cause",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TestResultListSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "unplannedTests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TestResultListSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "unplannedTests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2efe23e2523e64beb79fd8500d44e818",
    "id": null,
    "metadata": {},
    "name": "TestResultListSearchQuery",
    "operationKind": "query",
    "text": "query TestResultListSearchQuery(\n  $search: String = \"\"\n  $pageSize: Int!\n) {\n  unplannedTests(search: $search, pageSize: $pageSize) {\n    data {\n      id\n      device {\n        name\n        id\n      }\n      name\n      cause\n      commencedTime\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "60e053f4f9dfbeb4558726cfb5cc9d7a";

export default node;
