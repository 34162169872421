import React, { Dispatch, FC, Fragment, SetStateAction } from 'react';

import { Button, NavigateTo } from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';

import { HeaderSection } from './components/HeaderSection';
import style from './style.module.css';
import { CompareSectionDefinition, SectionExpandedState } from './types';

const classNames = classNamesBind.bind(style);

export interface CompareEmptyProps {
    /**
     * Sections define how the data is extracted and displayed in the layout.
     * Each section defines a group of fields in the compare layout.
     *
     * @see CompareSectionDefinition
     */
    sections: CompareSectionDefinition<never>[];

    /**
     * The title for the empty message.
     * Displayed if the data is empty.
     */
    emptyMessageTitle?: string;

    /**
     * The description for the empty message. Positioned below the title.
     * Displayed if the data is empty.
     */
    emptyMessageDescription?: string;

    /**
     * The action for the empty message. Positioned below the description.
     * Displayed if the data is empty.
     */
    emptyMessageAction?: string;

    /**
     * The navigation settings for the empty message action.
     * Displayed if the data is empty.
     */
    emptyMessageActionLink?: NavigateTo;

    /**
     * The click handler for the empty message action.
     * Displayed if the data is empty.
     */
    emptyMessageActionOnClick?: () => void;

    columnFitCount: number;

    sectionExpandedState: SectionExpandedState;
    setSectionExpandedState: Dispatch<SetStateAction<SectionExpandedState>>;
}

export const CompareEmpty: FC<CompareEmptyProps> = ({
    sections,
    emptyMessageTitle = 'No data to compare',
    emptyMessageDescription = 'Add items to compare and see the differences here.',
    emptyMessageAction = 'Add items',
    emptyMessageActionLink,
    emptyMessageActionOnClick,
    columnFitCount,
    sectionExpandedState,
    setSectionExpandedState,
}) => {
    const mainSection = sections[0];
    const remainingSections = sections.slice(1);

    return (
        <div
            className={classNames('empty_grid_container')}
            style={{
                gridTemplateColumns: `var(--header-width) repeat(${columnFitCount}, var(--column-width)) 1fr`,
            }}
        >
            {/* Header row */}
            <div></div>
            {Array.from({ length: columnFitCount }).map((_, index) => (
                <div
                    key={index}
                    className='bg-white bg-opacity-40'
                    style={{
                        gridColumn: index + 2,
                    }}
                ></div>
            ))}
            {/* Main row */}
            <div
                className={classNames('empty_grid_main_row')}
                style={{
                    gridColumnEnd: `span ${columnFitCount + 2}`,
                }}
            >
                <div className='grid'>
                    <HeaderSection section={mainSection} prominent />
                </div>
                <div className='p-4 grid place-content-center'>
                    <div className='flex flex-col items-center'>
                        <div className='text-2xl text-coralRegular'>{emptyMessageTitle}</div>
                        <div className='mb-4'>{emptyMessageDescription}</div>
                        <Button
                            buttonText={emptyMessageAction}
                            size='small'
                            onClick={emptyMessageActionOnClick}
                            to={emptyMessageActionLink}
                        />
                    </div>
                </div>
            </div>
            {/* Remaining */}
            {remainingSections.map((section, index) => (
                <Fragment key={index}>
                    <div className='grid auto-rows-auto col-start-1'>
                        <HeaderSection
                            section={section}
                            collapsed={!sectionExpandedState[section.title]}
                            onToggleCollapsed={collapsed =>
                                setSectionExpandedState(state => ({ ...state, [section.title]: !collapsed }))
                            }
                        />
                    </div>
                    {Array.from({ length: columnFitCount }).map((_, index) => (
                        <div
                            key={index}
                            className='bg-white opacity-40'
                            style={{
                                gridColumn: index + 2,
                            }}
                        ></div>
                    ))}
                </Fragment>
            ))}
        </div>
    );
};
