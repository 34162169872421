import React, { FC } from 'react';

import { FilterDurationSelect } from 'components/FilterDurationSelect';
import { PredefinedDuration } from 'components/FilterDurationSelect/common';
import { CommonFilterProps } from 'filters/common';
import { Duration } from 'luxon';

import { IncidentDurationFilter } from '../types';

const DurationPresets: PredefinedDuration[] = [
    {
        title: 'Less than 20 minutes',
        asDurationRange: () => [
            Duration.fromObject({ seconds: 0 }).toISO(),
            Duration.fromObject({ minutes: 20 }).toISO(),
        ],
    },
    {
        title: 'Less than 1 hour',
        asDurationRange: () => [Duration.fromObject({ seconds: 0 }).toISO(), Duration.fromObject({ hour: 1 }).toISO()],
    },
    {
        title: 'Less than 6 hours',
        asDurationRange: () => [Duration.fromObject({ seconds: 0 }).toISO(), Duration.fromObject({ hours: 6 }).toISO()],
    },
    {
        title: 'More than 6 hours',
        asDurationRange: () => [Duration.fromObject({ hours: 6 }).toISO(), Duration.fromObject({ years: 1 }).toISO()],
    },
];

export type ACPowerIncidentDurationFilterUIProps = CommonFilterProps<IncidentDurationFilter>;

export const ACPowerIncidentDurationFilterUI: FC<ACPowerIncidentDurationFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <FilterDurationSelect
        title='Filter by incident duration'
        presets={DurationPresets}
        onClearOrBack={onClearOrBack}
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        hasPrevious={hasPrevious}
    />
);
