import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { useDocumentTitle } from 'components';

import { BatterySingleSiteViewContent } from './SingleSiteContent';
import { SingleSiteSkeleton } from './SingleSiteSkeleton';
import { SingleSiteContent_SiteViewQuery } from './__generated__/SingleSiteContent_SiteViewQuery.graphql';

export const BatterySingleSiteView: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<SingleSiteContent_SiteViewQuery>;

    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { siteId } = useParams() as { siteId: string };

    useDocumentTitle('Reports - Batteries by site');

    return (
        <>
            {!queryRef && <SingleSiteSkeleton />}
            {queryRef && (
                <Suspense fallback={<SingleSiteSkeleton />}>
                    <BatterySingleSiteViewContent key={siteId} queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};
