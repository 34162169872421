import React, { FC } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';

import { Button, PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterValueMap } from 'filters/common';
import { SiteExtraFilters, SiteTableColumnId } from 'filters/site';
import { SiteAllFilterMap, StaticSiteFilterDefinitions } from 'filters/site/settings';
import { getGlobalEnvironment } from 'lib/environment';
import { Paths } from 'lib/routes';
import { encodeFilterParameters } from 'lib/table-filter';
import { DateTime, Duration } from 'luxon';

import { ExpandableCardHeader } from '../../../../components/ExpandableCardHeader';
import { intervalToHuman, intervalToIncidentCountChart, sanitizeTimeRange, useTimeRangeFromSearch } from '../common';
import { ReportIntervalSelection } from '../components';
import { DefaultTimeRange, TimeRangeDurations, TimeRangeSearchParameter } from '../settings';
import { ACPowerOverviewContentQuery } from './__generated__/ACPowerOverviewContentQuery.graphql';
import {
    CountsSection,
    IncidentCountsChart,
    LeastReliableSites,
    ReliabilityChart,
    ReliabilitySection,
    Search,
    SitesAtRisk,
} from './components';

export interface ACPowerOverviewContentProps {
    queryRef: PreloadedQuery<ACPowerOverviewContentQuery>;
}

export const ACPowerOverviewContent: FC<ACPowerOverviewContentProps> = ({ queryRef }) => {
    const data = usePreloadedQuery(ACPowerOverviewQuery, queryRef);
    const interval = useTimeRangeFromSearch();

    return (
        <div className='space-y-6'>
            <div>
                <div className='flex justify-between items-center'>
                    <PageHeading value='AC Power' />
                    <div className='flex'>
                        <div className='w-64 pr-4'>
                            <Search />
                        </div>
                        <Button
                            buttonText='View all Sites'
                            size='small'
                            to={{
                                pathname: Paths.ReportACPowerSiteList,
                                search: {
                                    ...encodeFilterParameters<SiteTableColumnId, FilterValueMap<SiteAllFilterMap>>(
                                        {
                                            [SiteExtraFilters.ACReliabilityTimeRange]: interval,
                                        },
                                        StaticSiteFilterDefinitions
                                    ),
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='flex justify-end'>
                <ReportIntervalSelection />
            </div>
            <div className='bg-white p-8 grid grid-cols-12 gap-x-4 space-y-6'>
                <ExpandableCardHeader
                    className='col-span-12'
                    title='AC reliability across network'
                    subtitle={intervalToHuman(interval, {
                        lifetimeOverride: 'For all time up to today',
                        prefix: 'For the last ',
                        suffix: ' up to today',
                    })}
                />
                <CountsSection fragmentRef={data} />
                <LeastReliableSites fragmentRef={data.sites} interval={interval} />
                <IncidentCountsChart fragmentRef={data} interval={interval} />
                <ReliabilityChart fragmentRef={data} interval={interval} />
                <ReliabilitySection fragmentRef={data} interval={interval} />
                <SitesAtRisk fragmentRef={data} interval={interval} />
            </div>
        </div>
    );
};

export const ACPowerOverviewQuery = graphql`
    query ACPowerOverviewContentQuery(
        $begin: Timestamp!
        $end: Timestamp!
        $timeRange: ACTimeRange!
        $incidentBegin: Timestamp!
        $interval: Duration!
    ) {
        ...CountsSection
        sites(orderBy: { field: ACReliability, dir: Asc }, acReliabilityTimeRange: $timeRange, pageSize: 5) {
            ...LeastReliableSites
        }
        ...ReliabilitySection
        ...IncidentCountsChart
        ...ReliabilityChart
        ...SitesAtRisk
    }
`;

export function loadACPowerOverviewPageData(url: URL) {
    const timeRange = sanitizeTimeRange(url.searchParams.get(TimeRangeSearchParameter) ?? DefaultTimeRange);

    const duration = TimeRangeDurations[timeRange];
    const interval = intervalToIncidentCountChart(timeRange);

    let startDate: DateTime;
    const endDate = DateTime.local().startOf('day');

    if (duration != null) {
        startDate = endDate.minus(duration);
    } else {
        startDate = DateTime.fromObject({ year: 2020, month: 1, day: 1 });
    }

    let incidentBegin: DateTime | undefined;
    if (interval >= Duration.fromObject({ month: 1 })) {
        incidentBegin = startDate.startOf('month').plus(interval);
    } else if (interval >= Duration.fromObject({ day: 1 })) {
        incidentBegin = startDate.startOf('day').plus(interval);
    } else {
        // interval not supported
    }

    return loadQuery(
        getGlobalEnvironment(),
        ACPowerOverviewQuery,
        {
            begin: startDate.toISO(),
            end: endDate.toISO(),
            timeRange,

            incidentBegin: incidentBegin?.toISO(),
            interval: interval.toISO(),
        },
        {
            fetchPolicy: 'store-or-network',
        }
    );
}
