import { useCallback, useMemo } from 'react';
import { GraphQLTaggedNode, fetchQuery, useRelayEnvironment } from 'react-relay';

import { SortDirection, TableState } from 'layouts';
import { FetchOptions, RenderProps, useQuery } from 'lib/query-helpers';
import { OperationType } from 'relay-runtime';

export interface TableStateForVariables<ColumnType extends string> {
    page: number;
    pageSize: number;
    pageCount: number;
    search: string;
    orderBy: ColumnType;
    orderDirection: SortDirection;
    visibleColumns: ColumnType[];
}

type VariableResolver<ColumnType extends string, TOperationType extends OperationType = OperationType> = (
    options: TableStateForVariables<ColumnType>
) => TOperationType['variables'];

export interface TableRenderProps<ColumnType extends string, T extends OperationType> extends RenderProps<T> {
    fetchTable: (options: TableStateForVariables<ColumnType>) => Promise<T['response'][]>;
}

export function useTableQuery<ColumnType extends string, TOperationType extends OperationType = OperationType>(
    gqlQuery: GraphQLTaggedNode,
    variableResolver: VariableResolver<ColumnType, TOperationType>,
    tableState: TableState<ColumnType>,
    fetchOptions: FetchOptions = {}
): TableRenderProps<ColumnType, TOperationType> {
    const environment = useRelayEnvironment();

    const fetch = useCallback(
        async (options: TableStateForVariables<ColumnType>) => {
            const data = [];
            for (let i = 0; i < options.pageCount; i++) {
                const variables = variableResolver({
                    ...options,
                    page: options.page + i,
                    pageCount: 1,
                });
                const batchData = await fetchQuery(environment, gqlQuery, variables).toPromise();
                if (!batchData) {
                    continue;
                }

                data.push(batchData);
            }

            return data;
        },
        [environment, gqlQuery, variableResolver]
    );

    const variables: TOperationType['variables'] = useMemo(() => {
        const { page, pageSize, search, sortColumn, sortDirection, visibleColumnsInOrder } = tableState;

        return variableResolver({
            page,
            pageSize,
            pageCount: 1,
            search,
            orderBy: sortColumn,
            orderDirection: sortDirection,
            visibleColumns: visibleColumnsInOrder,
        });
    }, [tableState, variableResolver]);

    const { data, error, retry, isFetching } = useQuery<TOperationType>(gqlQuery, variables, {
        fetchPolicy: 'network-only',
        ...fetchOptions,
    });

    return {
        data,
        error,
        retry,
        isFetching,
        fetchTable: fetch,
    };
}
