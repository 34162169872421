import React, { FC } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { IEnvironment } from 'relay-runtime';

import { AttributeFilter, CommonFilterProps } from '../types';
import { AttributeFilterResult } from './AttributeFilterResult';
import {
    AttributeFilterUI_attributeValueSearchQuery,
    AttributeLocation,
} from './__generated__/AttributeFilterUI_attributeValueSearchQuery.graphql';

export interface AttributeFilterUIProps extends CommonFilterProps<AttributeFilter[]> {
    type: AttributeLocation;
    attribute: string;
}

export const AttributeFilterUI: FC<AttributeFilterUIProps> = ({
    attribute,
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
    type,
}) => {
    const environment = useRelayEnvironment();
    return (
        <FilterSearchSelect
            active={current}
            title={`Filter by Tag "${attribute}"`}
            placeholder='Enter value'
            renderItem={item => <AttributeFilterResult filter={item} />}
            renderItemSimple={item => item.value}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, attribute, input, 5, type)}
            searchOnLoad
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

export async function doSearch(
    environment: IEnvironment,
    attribute: string,
    input: string,
    maxResults: number,
    type: AttributeLocation
): Promise<AttributeFilter[]> {
    try {
        const results = await fetchQuery<AttributeFilterUI_attributeValueSearchQuery>(
            environment,
            graphql`
                query AttributeFilterUI_attributeValueSearchQuery(
                    $attribute: String!
                    $input: String!
                    $maxResults: Int!
                    $type: AttributeLocation!
                ) {
                    attributeSearch(
                        name: $attribute
                        search: $input
                        limit: $maxResults
                        only: [$type]
                        status: [Active]
                    )
                }
            `,
            {
                attribute,
                input,
                maxResults: maxResults - 1,
                type,
            }
        ).toPromise();

        const output =
            results?.attributeSearch.map<AttributeFilter>(value => ({
                value,
            })) ?? [];

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch {
        return [];
    }
}

function createWildcardFilter(input: string): AttributeFilter {
    return {
        value: input.trim(),
        wildcard: true,
    };
}
