/**
 * @generated SignedSource<<07d0f497da3c8530607de8e6c3b150f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PowerControllersHorizontalBar$data = {
  readonly devices: {
    readonly total: number;
  };
  readonly globalDeviceHealth: ReadonlyArray<{
    readonly count: number;
    readonly status: DeviceHealth | null;
  }>;
  readonly " $fragmentType": "PowerControllersHorizontalBar";
};
export type PowerControllersHorizontalBar$key = {
  readonly " $data"?: PowerControllersHorizontalBar$data;
  readonly " $fragmentSpreads": FragmentRefs<"PowerControllersHorizontalBar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PowerControllersHorizontalBar",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalDeviceHealth",
      "kind": "LinkedField",
      "name": "globalDeviceHealth",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2daa874468b27633375b88f06782d6f9";

export default node;
