import React, { FC, Fragment } from 'react';

import { SkeletonText } from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';

import { Breakpoint, useBreakpoint } from '../../lib/breakpoints';
import style from './style.module.css';
import { CompareSectionDefinition } from './types';

const classNames = classNamesBind.bind(style);

export interface CompareSkeletonProps {
    /**
     * The sections must be provided to determine the appropriate display size
     *
     * @see CompareSectionDefinition
     */
    sections: CompareSectionDefinition<never>[];
}

export const CompareSkeleton: FC<CompareSkeletonProps> = ({ sections }) => {
    const breakpoint = useBreakpoint();

    let maximumColumnsWithoutScrolling = 2;
    switch (breakpoint) {
        case Breakpoint.ExtraSmall:
        case Breakpoint.Small:
            // small and xs are unsupported breakpoints
            maximumColumnsWithoutScrolling = 2;
            break;
        case Breakpoint.Medium:
            maximumColumnsWithoutScrolling = 2;
            break;
        case Breakpoint.Large:
        case Breakpoint.ExtraLarge:
            maximumColumnsWithoutScrolling = 3;
            break;
        case Breakpoint.XXLarge:
        case Breakpoint.XXXLarge:
            maximumColumnsWithoutScrolling = 4;
            break;
    }

    const mainSection = sections[0];
    const remainingSections = sections.slice(1);

    return (
        <>
            <div className='flex justify-between items-center'>
                <SkeletonText characters={15} size='heading' />
            </div>
            <SkeletonText characters={25} size='subheading' />
            <div className='mb-2 h-7'></div>
            <div className={classNames('compare_layout')}>
                <div
                    className={classNames('empty_grid_container', 'pt-8')}
                    style={{
                        gridTemplateColumns: `var(--header-width) repeat(${maximumColumnsWithoutScrolling}, var(--column-width)) 1fr`,
                    }}
                >
                    {/* Header row */}
                    <div></div>
                    {Array.from({ length: maximumColumnsWithoutScrolling }).map((_, index) => (
                        <div
                            key={index}
                            className='bg-white bg-opacity-40'
                            style={{
                                gridColumn: index + 2,
                            }}
                        ></div>
                    ))}
                    {/* Main row */}
                    <div
                        className={classNames('empty_grid_main_row')}
                        style={{
                            gridColumnEnd: `span ${maximumColumnsWithoutScrolling + 2}`,
                        }}
                    >
                        <div className='grid p-4'>
                            <SkeletonText characters={10} />

                            {mainSection.fields.map((_, index) => (
                                <SkeletonText key={index} characters={15} />
                            ))}
                        </div>
                    </div>
                    {/* Remaining */}
                    {remainingSections.map((section, index) => (
                        <Fragment key={index}>
                            <div className='grid auto-rows-auto col-start-1 p-4'>
                                <SkeletonText characters={10} />

                                {section.fields.map((_, index) => (
                                    <SkeletonText key={index} characters={15} />
                                ))}
                            </div>
                            {Array.from({ length: maximumColumnsWithoutScrolling }).map((_, index) => (
                                <div
                                    key={index}
                                    className='bg-white opacity-40'
                                    style={{
                                        gridColumn: index + 2,
                                    }}
                                ></div>
                            ))}
                        </Fragment>
                    ))}
                </div>
            </div>
        </>
    );
};
