import React, { FC } from 'react';

import { NavigateTo, PlaceholderArea, useExtendedNavigate } from '@accesstel/pcm-ui';

import { useUserPermissions } from 'lib/auth';

export interface WrappedDevicePlaceholderProps {
    navigateTo: NavigateTo;
    message: string;
    buttonText: string;
    disabledButtonMessage: string;
}

export const WrappedDevicePlaceholder: FC<WrappedDevicePlaceholderProps> = ({
    message,
    buttonText,
    disabledButtonMessage,
    navigateTo,
}) => {
    const { hasAssetsWrite } = useUserPermissions();

    const navigate = useExtendedNavigate();

    return (
        <div className='flex flex-col items-stretch'>
            <div className='flex flex-col gap-2'>
                <div className='font-bold text-3xl'>&nbsp;</div>
                <div className='font-bold text-xs'>&nbsp;</div>
            </div>
            <PlaceholderArea
                onClick={() => navigate(navigateTo)}
                // TODO: Would it be a better UX to just not show the button if the user doesn't have the right permissions?
                disableButton={!hasAssetsWrite}
                message={message}
                buttonText={buttonText}
                disableButtonMessage={disabledButtonMessage}
            />
        </div>
    );
};
