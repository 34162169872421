import React, { FC, ReactNode } from 'react';

import { ArrowDownCircleIcon, ArrowUpCircleIcon, CircleCrossIcon, Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';

type FeedIncidentIndicatorType = 'outage' | 'undervoltage' | 'overvoltage';

const Icons: Record<FeedIncidentIndicatorType, ReactNode> = {
    outage: <CircleCrossIcon />,
    undervoltage: <ArrowDownCircleIcon />,
    overvoltage: <ArrowUpCircleIcon />,
};

const Colors: Record<FeedIncidentIndicatorType, string> = {
    outage: 'text-coralRegular',
    undervoltage: 'text-eggplantRegular',
    overvoltage: 'text-mustardRegular',
};

const DescriptionsPlural: Record<FeedIncidentIndicatorType, string> = {
    outage: '%d outages',
    undervoltage: '%d undervoltage incidents',
    overvoltage: '%d overvoltage incidents',
};

const DescriptionsSingular: Record<FeedIncidentIndicatorType, string> = {
    outage: '%d outage',
    undervoltage: '%d undervoltage incident',
    overvoltage: '%d overvoltage incident',
};

export interface FeedIncidentIndicatorProps {
    count: number;
    type: FeedIncidentIndicatorType;
}

export const FeedIncidentIndicator: FC<FeedIncidentIndicatorProps> = ({ count, type }) => {
    let description: string;
    if (count === 1) {
        description = DescriptionsSingular[type];
    } else {
        description = DescriptionsPlural[type];
    }

    description = description.replace('%d', count.toString());

    return (
        <Tooltip content={`${description} on this feed`}>
            <div className='flex gap-1' data-testid={`fdind-${type}`}>
                <div className={classNames('w-4', Colors[type])}>{Icons[type]}</div>
                <span className='font-bold text-sm'>{count}</span>
            </div>
        </Tooltip>
    );
};
