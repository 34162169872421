import React, { FC } from 'react';

import classNamesBind from 'classnames/bind';

import style from '../style.module.css';
import { CompareSectionDefinition, FieldDifferences } from '../types';
import { SectionTitle } from './SectionTitle';

const classNames = classNamesBind.bind(style);

export interface HeaderSectionProps {
    section: CompareSectionDefinition<never>;
    collapsed?: boolean;
    prominent?: boolean;
    differences?: FieldDifferences;

    onToggleCollapsed?: (collapsed: boolean) => void;
}

export const HeaderSection: FC<HeaderSectionProps> = ({
    section,
    collapsed,
    prominent,
    onToggleCollapsed,
    differences = {},
}) => {
    return (
        <>
            <SectionTitle
                title={section.title}
                noCollapse={prominent}
                collapsed={collapsed}
                onToggle={onToggleCollapsed}
            />

            {!collapsed &&
                section.fields.map((field, index) => (
                    <div
                        key={index}
                        className={classNames({
                            'font-normal px-4': !field.isSubfield,
                            'font-light pl-8 pr-4': field.isSubfield,
                            'mb-4': index === section.fields.length - 1,
                            'bg-coralLight': differences[`${section.title}.${field.title}`],
                        })}
                    >
                        {field.title}
                    </div>
                ))}
        </>
    );
};
