import { round } from 'lib/number';

import { formatValueWithString } from './units';

/**
 * Converts a number to a localized string representation up to the maximum fraction digits.
 * If the input is null, returns a dash ('-').
 * If you want to always display a fixed number of fraction digits, use `numberToLocaleStringFixed` instead
 *
 * @param input - The number to be converted. If null, returns a dash ('-').
 * @param maximumFractionDigits - The maximum number of fraction digits to display. Defaults to 2.
 * @returns The localized string representation of the number, up to the maximum fraction digits.
 *
 * @example
 * numberToLocaleString(1.80, 1) => '1.8'
 * numberToLocaleString(1.80, 2) => '1.8'
 * numberToLocaleString(1.80) => '1.8' // (default maximumFractionDigits is 2)
 *
 * numberToLocaleString(1.89, 1) => '1.9'
 * numberToLocaleString(1.89, 2) => '1.89'
 * numberToLocaleString(1.89) => '1.89' // (default maximumFractionDigits is 2)
 */
export function numberToLocaleString(input: number | null, maximumFractionDigits = 2): string {
    if (input === null) {
        return '-';
    } else {
        return input.toLocaleString(undefined, {
            maximumFractionDigits: maximumFractionDigits,
            minimumFractionDigits: 0,
        });
    }
}

/**
 * Formats a number for form fields.
 *
 * This will display between 0 and the specified number of decimal places, with no grouping separators.
 * The value produced by this function is suitable to be parsed by `parseFloat`.
 *
 * @param value the value to format
 * @param maximumFractionDigits The maximum number of fractional digits to display
 * @returns the formatted number or an empty string if the value is null
 */
export function formatFormNumber(value: number | null, maximumFractionDigits = 2): string {
    if (value === null) {
        return '';
    }
    return value.toLocaleString('en-US', {
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: 0,
        useGrouping: false,
    });
}

/**
 * Converts a number to a localized string representation with a fixed number of fraction digits.
 * If the input is null, returns a dash ('-'). Consider this function a replacement for `toFixed` that respects the user's locale.
 *
 * @param input - The number to be converted. If null, returns a dash ('-').
 * @param fractionDigits - The number of fraction digits to display.
 * @returns The localized string representation of the number, with a fixed number of fraction digits.
 *
 * @example
 * numberToLocaleStringFixed(1.80, 1) => '1.8'
 * numberToLocaleStringFixed(1.80, 2) => '1.80'
 * numberToLocaleStringFixed(1.80, 3) => '1.800'
 */
export function numberToLocaleStringFixed(input: number | null, fractionDigits: number): string {
    if (input === null) {
        return '-';
    } else {
        return input.toLocaleString(undefined, {
            maximumFractionDigits: fractionDigits,
            minimumFractionDigits: fractionDigits,
        });
    }
}

export function percentageToStringCapped(input: number | null): string {
    if (input == null) {
        return formatValueWithString('-', '%');
    }

    if (input >= 100) {
        return formatValueWithString('100', '%');
    } else if (input > 99) {
        return formatValueWithString('>99', '%');
    } else if (input === 0) {
        return formatValueWithString('0', '%');
    } else if (input < 1) {
        return formatValueWithString('<1', '%');
    } else {
        // eslint-disable-next-line no-restricted-syntax
        return formatValueWithString(input.toFixed(0), '%');
    }
}

export function wattToKillowattString(watt: number | null | undefined): string {
    if (watt && watt !== 0) {
        if (watt >= 1000) {
            return formatValueWithString(round(watt / 1000, 1), 'kW');
        } else {
            return formatValueWithString(Math.round(watt), 'W');
        }
    } else {
        return formatValueWithString('-', 'W');
    }
}

/**
 *
 * @param value The value (in base unit). null value will be displayed as '-'
 * @param unit the unit of the value
 *
 * @example
 * displayKiloUnitMetrics(1000, 'W') => '1kW'
 * displayKiloUnitMetrics(100, 'W') => '100W'
 * displayKiloUnitMetrics(null, 'W') => '-W'
 */
export function handleKiloUnitMetricsDisplay(value: number | null, unit: string) {
    if (value === null) {
        return formatValueWithString('-', unit);
    }

    if (value >= 1000) {
        return formatValueWithString(round(value / 1000, 1), `k${unit}`);
    } else {
        return formatValueWithString(Math.round(value), unit);
    }
}
