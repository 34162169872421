import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    Environment,
    PreloadedQuery,
    fetchQuery,
    loadQuery,
    usePreloadedQuery,
    useRelayEnvironment,
} from 'react-relay';
import { useLocation } from 'react-router-dom';

import { Menu, PageHeading, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { DeviceSelectionModal } from 'components/DeviceSelectionModal';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { getGlobalEnvironment } from 'lib/environment';

import {
    MetricsExploreContentQuery,
    MetricsExploreContentQuery$data,
} from './__generated__/MetricsExploreContentQuery.graphql';
import { Chart } from './components/Chart';
import { useChartActions } from './lib/actions';
import { encodeDeviceIds, encodeMetricTimeRange, encodeMetrics } from './lib/encode';
import { useExportMetrics } from './lib/export';
import { useMetricData } from './lib/metric-loader';
import { ActionType, useViewReducer } from './reducer';
import { DeviceIdParam, MetricParam, TimeRangeParam } from './settings';

export interface MetricsExploreContentProps {
    queryRef: PreloadedQuery<MetricsExploreContentQuery>;
}

export const MetricsExploreContent: FC<MetricsExploreContentProps> = ({ queryRef }) => {
    const initialData = usePreloadedQuery(PagePreloadQuery, queryRef);
    const environment = useRelayEnvironment();
    const { pathname } = useLocation();
    const navigate = useExtendedNavigate();

    // We have to store the data in state because the page data is not replaced
    // when the query is refetched since there are no id's for relay to map to.
    const [data, setPageData] = useState<MetricsExploreContentQuery$data>(initialData);

    const [viewState, dispatchViewState] = useViewReducer(data);
    const {
        timeRange,
        zoomedTimeRange,
        isZoomed,
        isAtMaxZoom,
        selectedDevices,
        selectedMetrics,
        showDeviceSelectionModal,
    } = viewState;

    const [metricData] = useMetricData(zoomedTimeRange[0], zoomedTimeRange[1], selectedMetrics, selectedDevices);
    const [exportMetrics, isExportingMetrics] = useExportMetrics(viewState);

    const setDevices = useCallback(
        (devices: string[]) => {
            dispatchViewState({
                type: ActionType.SetSelectedDevices,
                devices,
            });

            refetchPageData(environment, devices, setPageData);
        },
        [dispatchViewState, environment]
    );

    const actions = useChartActions(viewState, dispatchViewState, data, devices => {
        refetchPageData(environment, devices, setPageData);
    });

    let pageTitle: string;

    if (selectedDevices.length === 0) {
        pageTitle = 'Explore metrics';
    } else if (selectedDevices.length <= 2) {
        const deviceNames = data.devices.data
            .filter(device => selectedDevices.includes(device.id))
            .map(device => device.name)
            .join(' & ');

        pageTitle = deviceNames;
    } else {
        pageTitle = `${selectedDevices.length} devices`;
    }

    let pageSubtitle: string;
    if (selectedMetrics.length === 0) {
        pageSubtitle = `Please select a metric to view`;
    } else if (selectedMetrics.length === 1) {
        const metric = selectedMetrics[0];
        const metricDefinition = data.metricTypes.find(metricDefinition => metricDefinition.metric === metric.metric);
        pageSubtitle = `Showing ${metricDefinition?.displayName ?? metric.metric} (${
            metric.op
        }) between ${getDateTimeFormat(timeRange.range[0])} and ${getDateTimeFormat(timeRange.range[1])}`;
    } else {
        pageSubtitle = `Showing ${selectedMetrics.length} metrics between ${getDateTimeFormat(
            timeRange.range[0]
        )} and ${getDateTimeFormat(timeRange.range[1])}`;
    }

    let chartTitle: string;

    if (selectedMetrics.length === 1) {
        const metric = selectedMetrics[0];
        const metricDefinition = data.metricTypes.find(metricDefinition => metricDefinition.metric === metric.metric);
        chartTitle = `${metricDefinition?.displayName ?? metric.metric} (${metric.op})`;
    } else if (selectedMetrics.length === 0) {
        chartTitle = 'No metrics selected';
    } else {
        chartTitle = 'Multiple metrics';
    }

    useEffect(() => {
        if (selectedMetrics.length === 0 || selectedDevices.length === 0) {
            return;
        }

        navigate(
            {
                pathname,
                search: {
                    [DeviceIdParam]: encodeDeviceIds(selectedDevices),
                    [MetricParam]: encodeMetrics(selectedMetrics),
                    [TimeRangeParam]: encodeMetricTimeRange(timeRange),
                },
            },
            { replace: true }
        );
        // NOTE: including pathname will cause infinite loop since this useEffect will change the pathname via redirect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDevices, selectedMetrics, timeRange]);

    return (
        <div className='space-y-6 mb-32'>
            <div>
                <div className='float-right'>
                    <Menu
                        id='site-view-menu'
                        menuItems={[
                            {
                                name: 'Export metrics',
                                disabled: isExportingMetrics,
                                onClick: exportMetrics,
                            },
                        ]}
                    />
                </div>
                <PageHeading value={pageTitle} />
                <PageHeading value={pageSubtitle} secondary subheading />
            </div>
            <div className='bg-white p-5'>
                <div>
                    <Chart
                        title={chartTitle}
                        xDomain={zoomedTimeRange}
                        series={metricData.series}
                        metricDefinitions={data.metricTypes}
                        actions={actions}
                        emptySuggestion={
                            selectedDevices.length === 0
                                ? 'device'
                                : selectedMetrics.length === 0
                                  ? 'metric'
                                  : undefined
                        }
                        onZoom={domain => dispatchViewState({ type: ActionType.ZoomTo, range: domain })}
                        onResetZoom={() => dispatchViewState({ type: ActionType.ResetZoom })}
                        isZoomed={isZoomed}
                        isAtMaxZoom={isAtMaxZoom}
                    />
                </div>
            </div>
            {showDeviceSelectionModal && (
                <DeviceSelectionModal
                    open={showDeviceSelectionModal}
                    selectedDevices={selectedDevices}
                    onConfirm={setDevices}
                    onClose={() => dispatchViewState({ type: ActionType.HideDeviceSelectionModal })}
                />
            )}
        </div>
    );
};

export const PagePreloadQuery = graphql`
    query MetricsExploreContentQuery($deviceIds: [ID!]) {
        metricTypes(aggregateable: true, devices: $deviceIds) {
            metric
            displayName
            unit
        }
        devices(filters: { ids: $deviceIds }) {
            data {
                id
                name
            }
        }
        ...SelectMetricsPaneFragment
    }
`;

export function loadMetricsExplorePageData(url: URL) {
    const deviceIds = url.searchParams.getAll(DeviceIdParam).flatMap(deviceId => deviceId.split(','));

    return loadQuery(
        getGlobalEnvironment(),
        PagePreloadQuery,
        {
            deviceIds,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}

function refetchPageData(
    environment: Environment,
    deviceIds: string[],
    onComplete: (data: MetricsExploreContentQuery$data) => void
) {
    fetchQuery<MetricsExploreContentQuery>(environment, PagePreloadQuery, {
        deviceIds,
    }).subscribe({
        next: onComplete,
    });
}
