import {
    GeneratorRunReportCauseFilter,
    GeneratorRunReportStateFilter,
    GeneratorRunReportTotalRunTimeFilter,
    TextFilterFilterBase,
} from './types';

export function encodeStateFilter(filter: GeneratorRunReportStateFilter): string {
    return encodeURIComponent(filter.id);
}

export function encodeCauseFilter(filter: GeneratorRunReportCauseFilter): string {
    return encodeURIComponent(filter.id);
}

export function encodeRuntimeFilter(filter: GeneratorRunReportTotalRunTimeFilter): string {
    const separator = '-';

    const { range } = filter;

    return encodeURIComponent(range.join(separator));
}

export function encodeTextFilter(filter: TextFilterFilterBase): string {
    if (filter.wildcard) {
        return encodeURIComponent(filter.value + '*');
    }

    return encodeURIComponent(filter.value);
}
