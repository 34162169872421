import { Dispatch, useEffect } from 'react';

import { FilterAction, FilterActionType, FilterState, useFilterReducer } from '../common';
import { AlertColumnFilterMap, DefaultExtraFilters, DefaultValues, StaticAlertFilterDefinitions } from './settings';
import { AlertTableColumn, IsAlertActive } from './types';

export type AlertFilterState = FilterState<AlertTableColumn, AlertColumnFilterMap>;
export type AlertFilterAction = FilterAction<AlertColumnFilterMap, AlertTableColumn>;

export function useAlertFilter(): [AlertFilterState, Dispatch<AlertFilterAction>] {
    const [state, updateState] = useFilterReducer<
        AlertTableColumn,
        AlertColumnFilterMap,
        AlertFilterState,
        AlertFilterAction
    >('alert-filter', DefaultValues, StaticAlertFilterDefinitions, DefaultExtraFilters);

    useEffect(() => {
        updateState({
            type: FilterActionType.UpdateFilterTypes,
            definitions: [...StaticAlertFilterDefinitions],
        });
    }, [updateState]);

    return [state, updateState];
}

export function alertToFilterObject(filters: AlertFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        DeviceName: deviceName,
        Domain: domain,
        RaiseDate: raiseDate,
        Severity: severity,
        SiteName: siteName,
        ClearDate: clearDate,
        Category: category,
        Originator: originator,
        IsActive: isActive,
    } = filters.columnValues;

    if (severity.length > 0) {
        output.severity = severity.map(filter => ({ value: filter.value }));
    }

    if (domain.length > 0) {
        output.domain = domain.map(filter => ({ value: filter.id }));
    }

    if (deviceName.length > 0) {
        output.deviceName = deviceName.map(filter => ({ value: filter.name }));
    }

    if (siteName.length > 0) {
        output.siteName = siteName.map(filter => ({ value: filter.name }));
    }

    if (raiseDate) {
        output.raiseDate = {
            min: raiseDate.range[0],
            max: raiseDate.range[1],
        };
    }

    if (clearDate) {
        output.clearDate = {
            min: clearDate.range[0],
            max: clearDate.range[1],
        };
    }

    if (category.length > 0) {
        output.category = category.map(filter => ({ value: filter.value }));
    }

    if (originator.length > 0) {
        output.originator = originator.map(filter => ({ value: filter.value }));
    }

    if (isActive) {
        output.isActive = { value: isActive === IsAlertActive.Yes };
    }

    // This will be used when extra filters are added
    for (const [definitionId] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition) {
            continue;
        }

        // TODO: Extra filters yet to be handled
    }

    return output;
}
