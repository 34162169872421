import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CalendarIcon, FilterContainer, IconButton } from '@accesstel/pcm-ui';

import { SingleSelectionList } from 'components/FilterSingleSelect/SingleSelectionList';

import { intervalToHuman, useTimeRangeFromSearch } from '../common';
import { DefaultTimeRanges, TimeRangeSearchParameter } from '../settings';

export const ReportIntervalSelection: FC = () => {
    const [open, setOpen] = useState(false);
    const interval = useTimeRangeFromSearch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selection, setSelection] = useState(interval);

    const onConfirmClick = () => {
        setOpen(false);
        setSearchParams({
            ...searchParams,
            [TimeRangeSearchParameter]: selection,
        });
    };

    return (
        <div className='relative'>
            <IconButton
                circleColor='white'
                iconStroke='coral'
                icon={<CalendarIcon />}
                title={intervalToHuman(interval, { prefix: 'Last ' })}
                fontFamily='light'
                size='small-medium'
                onClick={() => setOpen(prev => !prev)}
            />
            {open && (
                <div className='absolute z-50 right-0 mt-1 shadow-md'>
                    <FilterContainer
                        title='Select Interval'
                        hideClearButton
                        onClose={() => setOpen(false)}
                        onConfirmClick={onConfirmClick}
                        primaryContent={
                            <SingleSelectionList
                                items={DefaultTimeRanges}
                                onSelect={item => setSelection(item)}
                                renderItem={item => intervalToHuman(item, { prefix: 'Last ' })}
                                selectedItem={selection}
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
};
