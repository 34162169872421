/**
 * @generated SignedSource<<bd5d6bfe87c1cd17667d8896bafd2bfb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type prefillLoadQuery$variables = {
  companionDeviceId: string;
  hasCompanion: boolean;
  hasSite: boolean;
  siteId: string;
};
export type prefillLoadQuery$data = {
  readonly companionDevice?: {
    readonly dualPlaneCompanion: {
      readonly device: {
        readonly id: string;
      } | null;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly site: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
  readonly site?: {
    readonly id: string;
    readonly name: string;
  } | null;
};
export type prefillLoadQuery = {
  response: prefillLoadQuery$data;
  variables: prefillLoadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companionDeviceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasCompanion"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSite"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  {
    "condition": "hasSite",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "siteId"
          }
        ],
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      }
    ]
  },
  {
    "condition": "hasCompanion",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": "companionDevice",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companionDeviceId"
          }
        ],
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "prefillLoadQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "prefillLoadQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "1f8873415fb74c03ac0429d728c5a8b6",
    "id": null,
    "metadata": {},
    "name": "prefillLoadQuery",
    "operationKind": "query",
    "text": "query prefillLoadQuery(\n  $siteId: ID!\n  $hasSite: Boolean!\n  $companionDeviceId: ID!\n  $hasCompanion: Boolean!\n) {\n  site(id: $siteId) @include(if: $hasSite) {\n    id\n    name\n  }\n  companionDevice: device(id: $companionDeviceId) @include(if: $hasCompanion) {\n    id\n    name\n    site {\n      id\n      name\n    }\n    dualPlaneCompanion {\n      device {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc4389c3159db07f9ce34ff2b8a8710e";

export default node;
