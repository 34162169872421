/**
 * @generated SignedSource<<e4c9a6b219690fc33c091089a9018ac6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type queries_deviceTestSearchQuery$variables = {
  id: string;
  pageSize: number;
  testName?: string | null;
};
export type queries_deviceTestSearchQuery$data = {
  readonly device: {
    readonly tests: {
      readonly data: ReadonlyArray<{
        readonly task: {
          readonly name: string | null;
        } | null;
      }>;
    };
  } | null;
};
export type queries_deviceTestSearchQuery = {
  response: queries_deviceTestSearchQuery$data;
  variables: queries_deviceTestSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "testName"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "testName"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_deviceTestSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PaginatedDeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "tests",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceBatteryTestResults",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BatteryTest",
                    "kind": "LinkedField",
                    "name": "task",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "queries_deviceTestSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PaginatedDeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "tests",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceBatteryTestResults",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BatteryTest",
                    "kind": "LinkedField",
                    "name": "task",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3c2f122b07850733122c6c78fc5d2cca",
    "id": null,
    "metadata": {},
    "name": "queries_deviceTestSearchQuery",
    "operationKind": "query",
    "text": "query queries_deviceTestSearchQuery(\n  $id: ID!\n  $testName: String = \"\"\n  $pageSize: Int!\n) {\n  device(id: $id) {\n    tests(search: $testName, pageSize: $pageSize) {\n      data {\n        task {\n          name\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "999ee74c9f170be13d87a8c0d64ac6dc";

export default node;
