import { useEffect, useState } from 'react';

export enum Breakpoint {
    ExtraSmall = 0,
    Small = 1,
    Medium = 2,
    Large = 3,
    ExtraLarge = 4,
    XXLarge = 5,
    XXXLarge = 6,
}

/**
 * Returns the current breakpoint based on the window width.
 * This can be used to do some responsive logic in your components.
 *
 * DO NOT USE THIS if you are just changing the style. Use CSS / Tailwind instead.
 * This is for logic that cannot be done with CSS.
 *
 * @returns The current breakpoint.
 */
export function useBreakpoint(): Breakpoint {
    const [breakpoint, setBreakpoint] = useState<Breakpoint>(getBreakpointFromWidth(window.innerWidth));

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            const newBreakpoint = getBreakpointFromWidth(width);

            setBreakpoint(newBreakpoint);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return breakpoint;
}

function getBreakpointFromWidth(width: number): Breakpoint {
    // NOTE: This must be kept in sync with the values in tailwind.config.js
    if (width < 640) {
        return Breakpoint.ExtraSmall;
    }

    if (width < 768) {
        return Breakpoint.Small;
    }

    if (width < 1024) {
        return Breakpoint.Medium;
    }

    if (width < 1280) {
        return Breakpoint.Large;
    }

    if (width < 1540) {
        return Breakpoint.ExtraLarge;
    }

    if (width < 1920) {
        return Breakpoint.XXLarge;
    }

    return Breakpoint.XXXLarge;
}
