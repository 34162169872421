import React, { FC } from 'react';

import { BigButton } from '@accesstel/pcm-ui';

import { isFeatureEnabled } from 'lib/featureFlag';
import { Paths } from 'lib/routes';

import { useDocumentTitle } from '../../components';
import { BigButtonLayout } from '../../layouts/BigButtonLayout';

export const TaskManagement: FC = () => {
    useDocumentTitle('Tasks');

    return (
        <BigButtonLayout title='Tasks'>
            <BigButton
                to={Paths.TestsOverview}
                title='Battery Health Tests'
                text='Monitor and evaluate the battery health tests'
            />
            {isFeatureEnabled('generator_run') && (
                <BigButton
                    to={Paths.TasksGeneratorsOverview}
                    title='Generator Runs'
                    text='Monitor and assess the generator runs'
                />
            )}
        </BigButtonLayout>
    );
};
