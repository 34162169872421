/**
 * @generated SignedSource<<468686719f65dd7bcb52fe9ecf96b1ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneratorRunTaskOverviewContentQuery$variables = {
  largeSectionSize: number;
  smallSectionSize: number;
};
export type GeneratorRunTaskOverviewContentQuery$data = {
  readonly completedGeneratorRuns: {
    readonly " $fragmentSpreads": FragmentRefs<"CompletedGeneratorRuns_tasks">;
  };
  readonly inProgressGeneratorRuns: {
    readonly " $fragmentSpreads": FragmentRefs<"InProgressGeneratorRuns_tasks">;
  };
};
export type GeneratorRunTaskOverviewContentQuery = {
  response: GeneratorRunTaskOverviewContentQuery$data;
  variables: GeneratorRunTaskOverviewContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "largeSectionSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "smallSectionSize"
},
v2 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "dir": "Desc",
    "field": "GeneratorStartTime"
  }
},
v3 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "state": [
        "Running",
        "Finishing",
        "Analysing"
      ]
    }
  },
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "smallSectionSize"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "state": [
        "Completed",
        "Error"
      ]
    }
  },
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "largeSectionSize"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GeneratorRunReport",
    "kind": "LinkedField",
    "name": "data",
    "plural": true,
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cause",
        "storageKey": null
      },
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "generator",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "generatorStartTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "generatorStopTime",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneratorRunTaskOverviewContentQuery",
    "selections": [
      {
        "alias": "inProgressGeneratorRuns",
        "args": (v3/*: any*/),
        "concreteType": "PaginatedGeneratorRunReport",
        "kind": "LinkedField",
        "name": "generatorRunReports",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InProgressGeneratorRuns_tasks"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "completedGeneratorRuns",
        "args": (v4/*: any*/),
        "concreteType": "PaginatedGeneratorRunReport",
        "kind": "LinkedField",
        "name": "generatorRunReports",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompletedGeneratorRuns_tasks"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GeneratorRunTaskOverviewContentQuery",
    "selections": [
      {
        "alias": "inProgressGeneratorRuns",
        "args": (v3/*: any*/),
        "concreteType": "PaginatedGeneratorRunReport",
        "kind": "LinkedField",
        "name": "generatorRunReports",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": "completedGeneratorRuns",
        "args": (v4/*: any*/),
        "concreteType": "PaginatedGeneratorRunReport",
        "kind": "LinkedField",
        "name": "generatorRunReports",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a2e823fe0ae3d9a82d732faba1dd61f4",
    "id": null,
    "metadata": {},
    "name": "GeneratorRunTaskOverviewContentQuery",
    "operationKind": "query",
    "text": "query GeneratorRunTaskOverviewContentQuery(\n  $smallSectionSize: Int!\n  $largeSectionSize: Int!\n) {\n  inProgressGeneratorRuns: generatorRunReports(pageSize: $smallSectionSize, filters: {state: [Running, Finishing, Analysing]}, orderBy: {field: GeneratorStartTime, dir: Desc}) {\n    ...InProgressGeneratorRuns_tasks\n  }\n  completedGeneratorRuns: generatorRunReports(pageSize: $largeSectionSize, filters: {state: [Completed, Error]}, orderBy: {field: GeneratorStartTime, dir: Desc}) {\n    ...CompletedGeneratorRuns_tasks\n  }\n}\n\nfragment CompletedGeneratorRuns_tasks on PaginatedGeneratorRunReport {\n  data {\n    id\n    name\n    cause\n    state\n    generator {\n      id\n      name\n      site {\n        id\n        name\n        address {\n          state\n        }\n      }\n    }\n    generatorStartTime\n    generatorStopTime\n  }\n  total\n}\n\nfragment InProgressGeneratorRuns_tasks on PaginatedGeneratorRunReport {\n  data {\n    id\n    name\n    cause\n    state\n    generator {\n      id\n      name\n      site {\n        id\n        name\n        address {\n          state\n        }\n      }\n    }\n    generatorStartTime\n    generatorStopTime\n  }\n  total\n}\n"
  }
};
})();

(node as any).hash = "1a29c4022026e04924c1afd812295ccb";

export default node;
