/**
 * @generated SignedSource<<9a2715433330bb138339c018c00e8ed1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentChart_metrics$data = {
  readonly aggregatedCurrent: ReadonlyArray<{
    readonly average: number | null;
    readonly timestamp: string;
  }> | null;
  readonly " $fragmentType": "CurrentChart_metrics";
};
export type CurrentChart_metrics$key = {
  readonly " $data"?: CurrentChart_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentChart_metrics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentChart_metrics",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "points",
          "value": 20
        }
      ],
      "concreteType": "AggregatedTimestampMetric",
      "kind": "LinkedField",
      "name": "aggregatedCurrent",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "average",
          "storageKey": null
        }
      ],
      "storageKey": "aggregatedCurrent(points:20)"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "bee2871541c6b1dbfea1462c1bbe7321";

export default node;
