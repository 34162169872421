import React, { FC } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import { MenuItem, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { MenuItemGroup } from 'lib/menu';
import { DateTime, Duration } from 'luxon';

import { useDocumentTitle } from '../../../../../components';
import { AlertBanner } from '../../../../../components/AlertBanner';
import { ExpandableCardHeader } from '../../../../../components/ExpandableCardHeader';
import { useUserPermissions } from '../../../../../lib/auth';
import { getGlobalEnvironment } from '../../../../../lib/environment';
import { Paths } from '../../../../../lib/routes';
import { intervalToHuman, sanitizeTimeRange, useTimeRangeFromSearch } from '../../common';
import { DefaultTimeRange, TimeRangeDurations, TimeRangeSearchParameter } from '../../settings';
import { ACSiteOverviewContentQuery } from './__generated__/ACSiteOverviewContentQuery.graphql';
import { ACSiteIncidents } from './sections/ACSiteIncidents';
import { ACSiteInternalReliability } from './sections/ACSiteInternalReliability';
import { ACSiteReliability } from './sections/ACSiteReliability';
import { ACSiteSummary } from './sections/ACSiteSummary';

export interface ACSiteOverviewContentProps {
    queryRef: PreloadedQuery<ACSiteOverviewContentQuery>;
}

export const ACSiteOverviewContent: FC<ACSiteOverviewContentProps> = ({ queryRef }) => {
    const { hasAssetsRead, hasAssetsWrite } = useUserPermissions();
    const { siteId } = useParams<{ siteId: string }>();

    const timeRange = useTimeRangeFromSearch();

    const navigate = useExtendedNavigate();

    const data = usePreloadedQuery(ContentQuery, queryRef);

    useDocumentTitle(data.site ? `AC reliability report for ${data.site.name}` : 'AC Reliability Report');

    if (!data.site) {
        // This case should not happen as the parent component handles this
        return (
            <div className='col-span-12 h-96'>
                <AlertBanner title='Site not found' message='The site you are looking for does not exist.' />
            </div>
        );
    }

    const { site, settings } = data;

    const timeRangeDescription = intervalToHuman(timeRange, {
        lifetimeOverride: 'Since the site was created',
        prefix: 'For the last ',
        suffix: ' up to today',
    });

    const timeRangeDuration = TimeRangeDurations[timeRange];
    let dateRangeDescription: string;

    if (timeRangeDuration != null) {
        const endDate = DateTime.local().startOf('day');
        const startDate = endDate.minus(timeRangeDuration);

        dateRangeDescription = `Showing from ${startDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} until today`;
    } else {
        dateRangeDescription = 'Showing from when the site was created until today';
    }

    const siteLevelMenuItems: MenuItem[] = [
        {
            name: hasAssetsWrite ? 'Edit site' : 'View site',
            onClick: () => {
                navigate({ pathname: Paths.EditSite, params: { id: siteId! } });
            },
            disabled: !hasAssetsRead,
            group: MenuItemGroup.Assets,
        },
        {
            name: 'View current state',
            onClick: () => {
                navigate({ pathname: Paths.SiteViewViewSite, params: { siteId: siteId! } });
            },
        },
    ];

    const significantIncidentThreshold = Duration.fromISO(settings.acEvents.significanceThreshold);

    return (
        <>
            <ExpandableCardHeader
                className='col-span-12 mb-6'
                title={`AC reliability report for ${site.name}`}
                subtitle={timeRangeDescription}
                detailContent={<div>{dateRangeDescription}</div>}
                menuItems={siteLevelMenuItems}
            />
            <ACSiteSummary acPowerRef={site.acPower} />
            <ACSiteReliability reliabilityRef={site.acPower.reliability} queryRef={data} timeRange={timeRange} />
            <ACSiteInternalReliability fragmentRef={site} timeRange={timeRange} />
            <ACSiteIncidents
                fragmentRef={site.acPower}
                siteId={siteId!}
                timeRange={timeRange}
                significantIncidentThreshold={significantIncidentThreshold}
            />
        </>
    );
};

const ContentQuery = graphql`
    query ACSiteOverviewContentQuery($siteId: ID!, $timeRange: ACTimeRange!, $startRange: DateRangeFilter) {
        ...ReliabilityChart_query
        site(id: $siteId) {
            id
            name
            acPower {
                reliability(timeRange: $timeRange) {
                    ...ACSiteReliability_reliability
                }
                ...ACSiteSummary_acPower @arguments(startRange: $startRange)
                ...ACSiteIncidents_acPower @arguments(startRange: $startRange)
            }
            ...ACSiteInternalReliability_site
        }
        settings {
            acEvents {
                significanceThreshold
            }
        }
    }
`;

export function loadACSiteOverviewContentQuery(siteId: string, url: URL) {
    const timeRange = sanitizeTimeRange(url.searchParams.get(TimeRangeSearchParameter) ?? DefaultTimeRange);

    const duration = TimeRangeDurations[timeRange];

    let filter = null;
    if (duration != null) {
        const endDate = DateTime.local().startOf('day');
        const startDate = endDate.minus(duration);

        filter = {
            min: startDate.toISO(),
            max: endDate.toISO(),
        };
    }

    return loadQuery(getGlobalEnvironment(), ContentQuery, {
        siteId,
        timeRange,
        startRange: filter,
    });
}
