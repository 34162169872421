import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { ActivityLogType } from 'components/PagedActivityLog/__generated__/PagedActivityLogQuery.graphql';

import { HistoryCategory, SelectCategoriesPaneProps } from './SelectCategoryPane';

type BatteryTestPaneProps = SelectCategoriesPaneProps;

enum BatteryTestEvent {
    Start = 'Start',
}

export const BatteryTestPane: FC<BatteryTestPaneProps> = ({
    current,
    onClearOrBack,
    onSubmit,
    hasPrevious,
    onClose,
    clearButton,
}) => {
    return (
        <MultipleSelect
            title='Battery Test'
            variant='standard'
            options={[BatteryTestEvent.Start]}
            active={
                current
                    ? ((current as ActivityLogType[])
                          .map(humanizeBatteryTestEvent)
                          .filter(type => type != null) as BatteryTestEvent[])
                    : []
            }
            renderItem={event => event}
            renderItemSimple={event => event}
            onSubmit={events =>
                onSubmit(
                    events.map(convertBatteryTestEvent).filter(event => event != null) as ActivityLogType[],
                    HistoryCategory.BatteryTest
                )
            }
            onClear={() => {
                onClearOrBack();
            }}
            showBack={hasPrevious}
            onClose={onClose}
            clearButton={clearButton}
        />
    );
};

function convertBatteryTestEvent(event: BatteryTestEvent): ActivityLogType | null {
    if (event === BatteryTestEvent.Start) {
        return 'BatteryTest';
    }

    return null;
}

export function humanizeBatteryTestEvent(event: ActivityLogType): BatteryTestEvent | null {
    if (event === 'BatteryTest') {
        return BatteryTestEvent.Start;
    }

    return null;
}
