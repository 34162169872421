import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { AttributeTableColumn } from 'filters/common';
import { DeviceHealth } from 'lib/tables/device';

// IMPORTANT: Value has to be unique and cannot have spaces
export enum DeviceTableColumn {
    Name = 'Name',
    Type = 'Type',
    Site = 'Site',
    State = 'State',
    LastTested = 'LastTested',
    SnmpVersion = 'SnmpVersion',
    DeviceStatus = 'DeviceStatus',
    BatteryStatus = 'BatteryStatus',
    MonitorOnly = 'MonitorOnly',
    BatteryStringCount = 'BatteryStringCount',
    BatteryTemperature = 'BatteryTemperature',
    BatteryCapacityRemaining = 'BatteryCapacityRemaining',
    BatteryEnergyTotal = 'BatteryEnergyTotal',
    BatteryReserveTime = 'BatteryReserveTime',
    BatteryStateOfHealth = 'BatteryStateOfHealth',

    ACReliability = 'ACReliability',
    Mttr = 'ACMttr',
    Mtbf = 'ACMtbf',
    IncidentCount = 'ACIncidentCount',
    OutageDurationSum = 'ACOutageDurationSum',
}

export type DeviceTableColumnId = DeviceTableColumn | AttributeTableColumn;

// IMPORTANT: Value has to be unique and cannot have spaces
export enum DeviceExtraFilters {
    BatteryCapacityTotal = 'BatteryCapacityTotal',
    BatteryInstallDate = 'BatteryInstallDate',
    BatteryManufactureDate = 'BatteryManufactureDate',
    ACReliabilityTimeRange = 'ACReliabilityTimeRange',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum TestDeviceTableColumn {
    Name = 'Name',
    Site = 'Site',
    State = 'State',
    LastTested = 'LastTested',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum TestDeviceExtraFilters {
    Type = 'Type',
    SnmpVersion = 'SNMPVersion',
    DeviceStatus = 'DeviceStatus',
    BatteryStatus = 'BatteryStatus',
    BatteryStringCount = 'BatteryStringCount',
    BatteryTemperature = 'BatteryTemperature',
    BatteryCapacityRemaining = 'BatteryCapacityRemaining',
    BatteryEnergyTotal = 'BatteryEnergyTotal',
    BatteryReserveTime = 'BatteryReserveTime',
    BatteryStateOfHealth = 'BatteryStateOfHealth',
    BatteryCapacityTotal = 'BatteryCapacityTotal',
    BatteryInstallDate = 'BatteryInstallDate',
    BatteryManufactureDate = 'BatteryManufactureDate',
}

export interface DeviceNameFilter {
    name: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    id?: string;
    siteName?: string;
    state?: string;
}

export interface DeviceTypeFilter {
    name: string;
    id: string;
}

export interface DeviceAttributeFilter {
    value: string;
    wildcard?: boolean;
}

export interface DeviceSNMPVersionFilter {
    name: string;
    id: '1' | '2c' | '3';
}

export interface DeviceStatusFilter {
    name: string;
    id: DeviceHealth;
}

export interface DeviceBatteryStatusFilter {
    name: string;
    id: 'BoostCharging' | 'Discharging' | 'Disconnected' | 'FloatCharging' | 'Idle' | 'Missing' | 'Unknown';
}

export type DeviceBatteryDateFilter = DateRangeFilter;
export type DeviceLastTestedDateFilter = DateRangeFilter;

export enum MonitorOnlyStatus {
    Yes = 'Yes',
    No = 'No',
}
