/**
 * @generated SignedSource<<423b650bffec98ca6a3df1762d3099c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TrendState = "Down" | "Flat" | "Up" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TemperatureSparkline_battery$data = {
  readonly latestTemperature: number | null;
  readonly temperature: {
    readonly trend: {
      readonly state: TrendState;
    } | null;
    readonly values: ReadonlyArray<{
      readonly timestamp: string;
      readonly value: number | null;
    }>;
  } | null;
  readonly " $fragmentType": "TemperatureSparkline_battery";
};
export type TemperatureSparkline_battery$key = {
  readonly " $data"?: TemperatureSparkline_battery$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemperatureSparkline_battery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "unit",
    "variableName": "unitTemperature"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitTemperature"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemperatureSparkline_battery",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "latestTemperature",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "TimeSeriesMetrics",
      "kind": "LinkedField",
      "name": "temperature",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimestampMetric",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Trend",
          "kind": "LinkedField",
          "name": "trend",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryMetrics",
  "abstractKey": null
};
})();

(node as any).hash = "7e1437de08eb76a479d85c53505aeb3a";

export default node;
