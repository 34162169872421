import { DischargeTableType } from '@accesstel/pcm-ui';

import { formatFormNumber } from 'lib/numberFormatters';
import { BatteryTechnologyType } from 'lib/provision';

import { EditBatteryTypeFormQuery } from '../__generated__/EditBatteryTypeFormQuery.graphql';
import { BatteryTypeFormValues, createDefaultBatteryTypeFormValues } from '../schema';
import { BatteryTypeIn } from './saving';

type BatteryType = EditBatteryTypeFormQuery['response']['batteryType'];

export function convertBatteryTypeFormStateToApi(values: BatteryTypeFormValues): BatteryTypeIn {
    return {
        manufacturer: values.manufacturer,
        model: values.model,
        technology: values.technology as BatteryTechnologyType,

        cellsPerBloc: values.cellsPerBloc ? Number(values.cellsPerBloc) : undefined,
        cells: {
            capacity: Number(values.cells.capacity),
            capacityRating: Number(values.cells.capacityRating),
            referenceTemperature: Number(values.cells.referenceTemperature),

            maximumAllowedVoltage: values.cells.maximumAllowedVoltage
                ? Number(values.cells.maximumAllowedVoltage)
                : undefined,
            maximumBoostVoltage: values.cells.maximumBoostVoltage
                ? Number(values.cells.maximumBoostVoltage)
                : undefined,
            maximumFloatVoltage: values.cells.maximumFloatVoltage
                ? Number(values.cells.maximumFloatVoltage)
                : undefined,
            minimumAllowedVoltage: values.cells.minimumAllowedVoltage
                ? Number(values.cells.minimumAllowedVoltage)
                : undefined,
            minimumBoostVoltage: values.cells.minimumBoostVoltage
                ? Number(values.cells.minimumBoostVoltage)
                : undefined,
            minimumFloatVoltage: values.cells.minimumFloatVoltage
                ? Number(values.cells.minimumFloatVoltage)
                : undefined,

            typicalVoltage: values.cells.typicalVoltage ? Number(values.cells.typicalVoltage) : undefined,
        },

        peukertsConstant: Number(values.peukertsConstant),
        dischargeTables: values.dischargeTables.map(table => ({
            type: table.type,
            endOfDischargeVoltage: Number(table.endOfDischargeVoltage),
            rows: table.rows,
        })),
        attributes: values.attributes,
    };
}

export function convertBatteryTypeToFormState(batteryType: BatteryType): BatteryTypeFormValues {
    if (!batteryType) {
        return createDefaultBatteryTypeFormValues();
    }

    const values: BatteryTypeFormValues = createDefaultBatteryTypeFormValues();

    values.manufacturer = batteryType.manufacturer;
    values.model = batteryType.model;

    if (batteryType.technology) {
        values.technology = batteryType.technology as BatteryTechnologyType;
    }

    if (batteryType.cellsPerBloc) {
        values.cellsPerBloc = formatFormNumber(batteryType.cellsPerBloc, 0);
    }

    values.cells.capacity = formatFormNumber(batteryType.cells.capacity);
    values.cells.capacityRating = formatFormNumber(batteryType.cells.capacityRating);
    values.cells.referenceTemperature = formatFormNumber(batteryType.cells.referenceTemperature);

    if (batteryType.cells.allowedVoltage) {
        if (batteryType.cells.allowedVoltage.minimum) {
            values.cells.minimumAllowedVoltage = formatFormNumber(batteryType.cells.allowedVoltage.minimum);
        }
        if (batteryType.cells.allowedVoltage.maximum) {
            values.cells.maximumAllowedVoltage = formatFormNumber(batteryType.cells.allowedVoltage.maximum);
        }
    }

    if (batteryType.cells.floatVoltage) {
        if (batteryType.cells.floatVoltage.minimum) {
            values.cells.minimumFloatVoltage = formatFormNumber(batteryType.cells.floatVoltage.minimum);
        }
        if (batteryType.cells.floatVoltage.maximum) {
            values.cells.maximumFloatVoltage = formatFormNumber(batteryType.cells.floatVoltage.maximum);
        }
    }

    if (batteryType.cells.boostVoltage) {
        if (batteryType.cells.boostVoltage.minimum) {
            values.cells.minimumBoostVoltage = formatFormNumber(batteryType.cells.boostVoltage.minimum);
        }
        if (batteryType.cells.boostVoltage.maximum) {
            values.cells.maximumBoostVoltage = formatFormNumber(batteryType.cells.boostVoltage.maximum);
        }
    }

    if (batteryType.cells.typicalVoltage) {
        values.cells.typicalVoltage = formatFormNumber(batteryType.cells.typicalVoltage);
    }

    if (batteryType.peukertsConstant) {
        values.peukertsConstant = formatFormNumber(batteryType.peukertsConstant);
    }

    if (batteryType.dischargeTables) {
        // FIXME: This map is just to eliminate the readonly properties set and not changing the structure. Find a better way to do this
        values.dischargeTables = batteryType.dischargeTables.map(({ type, endOfDischargeVoltage, rows }) => {
            return {
                type: type as DischargeTableType,
                endOfDischargeVoltage,
                rows: rows.map(row => ({ time: row.time, value: row.value })),
            };
        });
    }

    if (batteryType.attributes) {
        values.attributes = batteryType.attributes.map(attribute => ({ ...attribute }));
    }

    return values;
}
