import React, { Dispatch, FC, ReactNode, useCallback, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { CrossIcon, FormikDropdownWithSearch, IconButton, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { SUGGESTIONS_LIMIT } from 'lib/provision';
import { queries_GetDevicesByNameQuery } from 'views/manage/__generated__/queries_GetDevicesByNameQuery.graphql';
import { getDevicesByNameQuery } from 'views/manage/queries';

import { FormControlAction, FormControlActionTypes, FormControlParameters } from '../../../lib/reducer';
import { DeviceFormValues, DeviceReference } from '../../../schema';
import style from '../../../style.module.css';

export interface CompanionSystemSelectorProps {
    editingDeviceId?: string;
    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const CompanionSystemSelector: FC<CompanionSystemSelectorProps> = ({
    editingDeviceId,
    formControlState,
    formControlDispatch,
}) => {
    const { show } = useToast();
    const { values } = useFormikContext<DeviceFormValues>();

    const currentSiteId = values.site?.id;

    const environment = useRelayEnvironment();
    const [deviceReferences, setDeviceReferences] = useState<DeviceReference[]>([]);
    const [deviceListError, setDeviceListError] = useState<string | null>(null);

    // FIXME: Need to pre-populate some items because otherwise it shows that there are no devices
    const handleSearch = useCallback(
        (search: string) => {
            if (currentSiteId == null || search.length === 0) {
                return;
            }

            fetchQuery<queries_GetDevicesByNameQuery>(environment, getDevicesByNameQuery, {
                name: search,
                siteId: [currentSiteId],
                limit: SUGGESTIONS_LIMIT,
            }).subscribe({
                next(value) {
                    const deviceReferences = value.devices.data.map<DeviceReference>(device => ({
                        id: device.id,
                        displayName: device.name,
                        siteName: device.site.name,
                    }));

                    if (editingDeviceId != null) {
                        setDeviceReferences(deviceReferences.filter(device => device.id !== editingDeviceId));
                    } else {
                        setDeviceReferences(deviceReferences);
                    }
                    setDeviceListError(null);
                },
                error(error: unknown) {
                    captureException(error);
                    show({ variant: 'error', text: 'Failed to get devices' });
                    setDeviceReferences([]);
                    setDeviceListError('Unable to search at this time');
                },
            });
        },
        [currentSiteId, editingDeviceId, environment, show]
    );

    return (
        <>
            <div
                className={classNames(
                    'col-start-1',
                    formControlState.addNewDualPlaneCompanionDevice || formControlState.addNewSite
                        ? style.page_item_disabled
                        : ''
                )}
            >
                <FormikDropdownWithSearch<DeviceReference>
                    name='companionReference'
                    placeHolder={'Companion Power System Name*'}
                    light
                    disabledText='Adding New Companion Power System'
                    disabled={formControlState.addNewDualPlaneCompanionDevice}
                    required={!formControlState.addNewDualPlaneCompanionDevice}
                    renderItemAsString={item => item.displayName}
                    renderItem={item => getRenderedSuggestion(item.displayName, item.siteName)}
                    items={deviceReferences}
                    onSearch={handleSearch}
                    variant='outlined'
                    testId='companion-power-system-name'
                    noResultsText={search => {
                        if (deviceListError) {
                            return deviceListError;
                        }

                        if (search.length > 0) {
                            return 'No matching devices';
                        }

                        return 'No devices available';
                    }}
                />
            </div>
            <div className='flex items-end col-start-2'>
                <IconButton
                    title={formControlState.addNewDualPlaneCompanionDevice ? 'Use Existing Device' : 'New Device'}
                    size='small'
                    onClick={() =>
                        formControlDispatch({
                            type: FormControlActionTypes.AddNewDualPlaneCompanionDevice,
                            payload: !formControlState.addNewDualPlaneCompanionDevice,
                        })
                    }
                    icon={formControlState.addNewDualPlaneCompanionDevice ? <CrossIcon /> : undefined}
                />
            </div>
        </>
    );
};

function getRenderedSuggestion(name: string, site: string): ReactNode {
    return (
        <div className='flex flex-row items-baseline space-x-2'>
            <span>{name}</span>
            <span className='font-light text-xs'>{site}</span>
        </div>
    );
}
