import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { BatteryTestTypeIncludingUnplanned } from 'components/TestSelectModalContent/__generated__/TestSelectModalContentAllIdsQuery.graphql';

import { CommonFilterProps } from '../../common/types';
import { describeBatteryTestType } from '../helpers';
import { DeviceTestResultTypeFilter } from '../types';

export type DeviceTestResultTypeFilterUIProps = CommonFilterProps<DeviceTestResultTypeFilter[]>;

export const DeviceTestResultTypeOptions: BatteryTestTypeIncludingUnplanned[] = [
    'Capacity',
    'Custom',
    'Quick',
    'Unplanned',
];

export const DeviceTestResultTypeFilterUI: FC<DeviceTestResultTypeFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <MultipleSelect
            options={DeviceTestResultTypeOptions}
            active={current}
            title='Filter by Battery Test Type'
            renderItem={describeBatteryTestType}
            renderItemSimple={describeBatteryTestType}
            areItemsEqual={(itemA, itemB) => itemA === itemB}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
