import React, { FC, ReactNode, useCallback, useState } from 'react';

import { ArrowLeftIcon, FilterContainer } from '@accesstel/pcm-ui';

import { CommonFilterProps } from 'filters/common';

import { CustomTimeRange } from './CustomTimeRange';
import { PredefinedSelections } from './PredefinedSelections';
import { PredefinedTimeRange, TimeRangeFilter } from './common';

enum Mode {
    ChooseType,
    TimeRanges,
}

export interface FilterTimeRangeSelectProps extends CommonFilterProps<TimeRangeFilter> {
    title: string;
    presets: PredefinedTimeRange[];
}

export const FilterTimeRangeSelect: FC<FilterTimeRangeSelectProps> = ({
    title,
    current,
    onClearOrBack,
    onSubmit,
    onClose,
    hasPrevious,
    presets,
}) => {
    const [range, setRange] = useState<TimeRangeFilter | null>(current ?? null);
    const [mode, setMode] = useState<Mode>(() => {
        if (current) {
            switch (current.type) {
                case 'predefined':
                    return Mode.ChooseType;
                case 'custom':
                    return Mode.TimeRanges;
            }
        }
        return Mode.ChooseType;
    });

    const handlePredefinedRange = useCallback((newRange: PredefinedTimeRange) => {
        setRange({
            type: 'predefined',
            preset: newRange.title,
            range: newRange.asTimeRange(),
        });
    }, []);

    const handleConfirmClick = useCallback(() => {
        if (!onSubmit || !range) {
            return;
        }

        onSubmit(range);
    }, [onSubmit, range]);

    const handleClearClick = useCallback(() => {
        if (mode !== Mode.ChooseType) {
            setMode(Mode.ChooseType);
            return;
        }
        onClearOrBack();
    }, [mode, onClearOrBack]);

    let customClearButton: ReactNode;
    if (hasPrevious && current == null) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-coralRegular'>
                <ArrowLeftIcon />
            </div>
        );
    }

    let content: ReactNode;
    switch (mode) {
        case Mode.ChooseType: {
            let selected: PredefinedTimeRange | null = null;
            if (range && range.type === 'predefined') {
                selected = presets.find(preset => preset.title === range.preset) ?? null;
            }

            content = (
                <PredefinedSelections
                    ranges={presets}
                    selected={selected}
                    onSelect={handlePredefinedRange}
                    onCustomTimeRangeClick={() => setMode(Mode.TimeRanges)}
                />
            );
            break;
        }
        case Mode.TimeRanges: {
            content = <CustomTimeRange value={range} onChange={setRange} />;
            break;
        }
    }

    return (
        <FilterContainer
            title={title}
            customButton={customClearButton}
            onClearClick={handleClearClick}
            onConfirmClick={handleConfirmClick}
            onClose={onClose}
            primaryContent={content}
            hideClearButton={!range && !hasPrevious}
            hideConfirmButton={!range && !current}
        />
    );
};
