/**
 * @generated SignedSource<<0f70d3aa9438f392ff9984d97dcf54a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonalSettingsContentQuery$variables = Record<PropertyKey, never>;
export type PersonalSettingsContentQuery$data = {
  readonly currentUser: {
    readonly preferences: {
      readonly " $fragmentSpreads": FragmentRefs<"TimezoneSettingsFragment" | "UnitSettingsFragment">;
    };
  };
};
export type PersonalSettingsContentQuery = {
  response: PersonalSettingsContentQuery$data;
  variables: PersonalSettingsContentQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonalSettingsContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UnitSettingsFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TimezoneSettingsFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PersonalSettingsContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserUnitPreferences",
                "kind": "LinkedField",
                "name": "units",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "temperature",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pressure",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "volume",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "volumetricFlowRate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezone",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2fc8802f0671a1fee9f65cc3b242318",
    "id": null,
    "metadata": {},
    "name": "PersonalSettingsContentQuery",
    "operationKind": "query",
    "text": "query PersonalSettingsContentQuery {\n  currentUser {\n    preferences {\n      ...UnitSettingsFragment\n      ...TimezoneSettingsFragment\n    }\n    id\n  }\n}\n\nfragment TimezoneSettingsFragment on UserPreferences {\n  timezone\n}\n\nfragment UnitSettingsFragment on UserPreferences {\n  units {\n    temperature\n    pressure\n    volume\n    volumetricFlowRate\n  }\n}\n"
  }
};

(node as any).hash = "2295295498c2e32b0335f0f88c2bc20c";

export default node;
