import { RouteObject } from 'react-router-dom';

import { isFeatureEnabled } from 'lib/featureFlag';

import { RouteExtraData } from '../../lib/route-helpers';

const Routes: RouteObject[] = [];

if (isFeatureEnabled('firmware')) {
    Routes.push({
        path: 'status/firmware',
        handle: {
            title: 'Firmware Status',
            altTitle: 'Firmware Status',
            description: 'View the current status of your devices firmware',
            fullWidth: true,
        } as RouteExtraData,
        lazy: async () => {
            const { FirmwareStatus } = await import('./FirmwareStatus');
            return { Component: FirmwareStatus };
        },
    });
}

export default Routes;
