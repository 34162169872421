import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { SiteDeviceAffectedFeedFilter } from '../types';

// FIXME: Hard coded to be up to 6 feeds. Real data may contain less or more
export const ACPowerIncidentAffectedFeedOptions: SiteDeviceAffectedFeedFilter[] = [
    { id: 1, label: 'Feed 1' },
    { id: 2, label: 'Feed 2' },
    { id: 3, label: 'Feed 3' },
    { id: 4, label: 'Feed 4' },
    { id: 5, label: 'Feed 5' },
    { id: 6, label: 'Feed 6' },
];

export type SiteACPowerDeviceAffectedFeedFilterUIProps = CommonFilterProps<SiteDeviceAffectedFeedFilter[]>;
export const SiteACPowerDeviceAffectedFeedFilterUI: FC<SiteACPowerDeviceAffectedFeedFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <MultipleSelect
            options={ACPowerIncidentAffectedFeedOptions}
            active={current}
            title='Filter by affected feed'
            renderItem={item => item.label}
            renderItemSimple={item => item.label}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
