import React, { FC } from 'react';

import { CrossIcon, IconButton, Tooltip } from '@accesstel/pcm-ui';

import classnames from 'classnames';
import { useField } from 'formik';
import { getQualifiedName } from 'lib/namespaces';

import { DeviceBatteryString } from '../schema';
import style from './style.module.css';

interface BatteryStringDisplayProps {
    namespace?: string;
    stringIndex: number;
    name: string;
    cells: number;
    onClick: () => void;
    onDuplicate: () => void;
    onRemove: () => void;
}

export const BatteryStringDisplay: FC<BatteryStringDisplayProps> = ({
    namespace,
    stringIndex,
    name,
    cells,
    onClick,
    onDuplicate,
    onRemove,
}) => {
    const [, batteryStringField] = useField<DeviceBatteryString>(getQualifiedName(`strings.${stringIndex}`, namespace));
    const cellsPerStringRepresentation = ` - ${cells} cells`;

    return (
        <>
            <div
                className={classnames(
                    'flex flex-row text-eggplantRegular font-normal text-base justify-between col-start-1',
                    { 'text-coralRegular': !!batteryStringField.error && batteryStringField.touched }
                )}
            >
                <span className={style.string_wrap}>
                    <Tooltip content={name} overflowOnly>
                        <div className='underline cursor-pointer truncate' onClick={onClick}>
                            {name}
                        </div>
                    </Tooltip>
                </span>
                <span className='whitespace-pre pr-2 mr-auto'>{cellsPerStringRepresentation}</span>
            </div>
            <div className='flex flex-row items-center space-x-2 col-start-2'>
                <Tooltip content='Duplicate Battery String'>
                    <span className='col-start-2'>
                        <IconButton size='small' onClick={onDuplicate} data-testid='battstring-duplicate' />
                    </span>
                </Tooltip>
                <Tooltip content='Remove Battery String'>
                    <span className='col-start-3'>
                        <IconButton
                            icon={<CrossIcon />}
                            size='small'
                            onClick={onRemove}
                            data-testid='battstring-remove'
                        />
                    </span>
                </Tooltip>
            </div>
        </>
    );
};
