import { object, string } from 'yup';

export const ResetFormValidationSchema = object({
    username: string().required('Must provide username'),
    code: string().trim().required('Must provide verifcation code'),
    newPassword: string().required('Must provide password').min(8, 'Must have at least 8 characters'),
    confirmPassword: string()
        .required('Must provide password')
        .test({
            name: 'Matches',
            message: 'Password does not match',
            test(value, context) {
                return value === context.parent.newPassword;
            },
        }),
});

export interface ResetFormValues {
    username: string;
    code: string;
    newPassword: string;
    confirmPassword: string;

    // Not a form value, but a way to display a common error message
    commonError?: string;
}

export const DefaultResetFormValues: ResetFormValues = {
    username: '',
    code: '',
    newPassword: '',
    confirmPassword: '',
};
