import { CustomDurationRangeFilter, PredefinedDurationRangeFilter } from 'components/FilterDurationSelect/common';
import {
    AffectedFeedFilter,
    IncidentDeviceNameFilter,
    IncidentIsSignificantOnlyFilter,
    IncidentTypeFilter,
} from 'filters/incidents';

export enum SiteIncidentTableColumn {
    DeviceName = 'DeviceName',
    IncidentType = 'WorstStatus',
    IncidentDuration = 'Duration',
    AffectedFeeds = 'AffectedFeeds',
    IncidentStartTime = 'StartTime',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum SiteIncidentExtraFilters {
    IsSignificant = 'IsSignificant',
}

export type SiteIncidentDeviceNameFilter = IncidentDeviceNameFilter;
export type SiteIncidentTypeFilter = IncidentTypeFilter;
export type SiteDeviceAffectedFeedFilter = AffectedFeedFilter;
export type SiteIncidentDurationFilter = PredefinedDurationRangeFilter | CustomDurationRangeFilter;
export type SiteIncidentIsSignificantOnlyFilter = IncidentIsSignificantOnlyFilter;
