import React, { FC, useState } from 'react';

import { BarChart, ChartArea, LoadableContentArea, TimeseriesPoint } from '@accesstel/pcm-ui';

import { NBSP } from 'constants/';
import { asColumnData } from 'lib/dateChart';
import { createMenuAction } from 'views/tasks/battery-health/components/MetricsLine';

import { TimeRange, useTimeRange, useTimeRangeQuery } from '../../../common';
import { queries_AverageEnergyQuery } from '../../__generated__/queries_AverageEnergyQuery.graphql';
import { averageEnergyQuery } from '../../queries';
import { ChartAspectRatio } from '../../settings';
import style from './common.module.css';

export interface AverageEnergyColumnProps {
    timeRange: TimeRange;
}

export const AverageEnergyColumn: FC<AverageEnergyColumnProps> = ({ timeRange }) => {
    const [currentTimeRange, menuItems] = useTimeRange(timeRange);
    const [isZoomPaneOpen, setIsZoomPaneOpen] = useState(false);

    const {
        data: props,
        error,
        retry,
    } = useTimeRangeQuery<queries_AverageEnergyQuery>(averageEnergyQuery, currentTimeRange);

    const zoomAction = createMenuAction({
        menuItems,
        menuSelected: currentTimeRange.id,
        isOpen: isZoomPaneOpen,
        setIsOpen: setIsZoomPaneOpen,
        menuPlaceholder: 'Zoom',
    });

    return (
        <ChartArea title='Average energy (kWh)' actions={[zoomAction]} className={style.chart}>
            <LoadableContentArea
                data={props?.batteryMetrics.energy}
                error={!!error}
                onRetry={retry}
                className={style.chart_loading}
                render={result => {
                    const data: TimeseriesPoint[] = result.values.map(point => ({
                        date: new Date(point.timestamp as string),
                        value: point.value,
                    }));

                    return (
                        <BarChart
                            series={[asColumnData(data, currentTimeRange.interval, `${NBSP}kWh`)]}
                            yTicks={11}
                            axisUnits
                            aspectRatio={ChartAspectRatio}
                        />
                    );
                }}
            />
        </ChartArea>
    );
};
