import { Environment, FetchFunction, Network, RecordSource, Store } from 'relay-runtime';

import { SignOutReason, getAuthToken, logout } from './auth';
import { logError } from './log';

const DefaultTimeout = 8000;

const fetchQuery: FetchFunction = async (operation, variables, cacheConfig) => {
    console.assert(process.env.NODE_ENV !== 'test', 'Trying to do a real fetch in unit tests');

    const token = await getAuthToken();

    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    // This implements a short time out since the defaults are 90 seconds or 300 seconds depending
    // on the browser. You can override it per request by setting the `timeout` value in the query
    // metadata. The value should be the number of milliseconds to wait for the entire request to
    // come through
    const requestedTimeout = (cacheConfig?.metadata?.timeout as number | undefined) ?? DefaultTimeout;

    let signal: AbortSignal;
    let timer: NodeJS.Timeout | null = null;
    if ('timeout' in AbortSignal) {
        // This is only supported in recent browsers
        signal = AbortSignal.timeout(requestedTimeout);
    } else {
        const controller = new AbortController();
        timer = setTimeout(() => controller.abort(), requestedTimeout);
        signal = controller.signal;
    }

    const response = await fetch(process.env.REACT_APP_GRAPHQL_SERVER as string, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query: operation.text,
            variables,
        }),
        signal,
    });

    if (timer) {
        clearTimeout(timer);
    }

    const output = await response.json();
    if (output.errors) {
        // Workaround for https://github.com/facebook/relay/issues/1913
        // NOTE: This means that you cannot retrieve the partial data that graphql might return.
        // I dont think this should be an issue for our use however.
        output.data = null;
    }

    if (response.status === 401) {
        // Unauthorized
        logError('Server returned 401, logging out');
        await logout(SignOutReason.SessionExpired);
    }

    return output;
};

const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
});

/**
 * **DO NOT USE THIS OUTSIDE OF THE APPLICATION ROOT.**
 *
 * This is used for providing the initial value to the RelayEnvironmentProvider.
 * If you need a reference to the environment, use `useRelayEnvironment()`
 *
 * If you do not, tests will not be able to mock out the API calls.
 *
 * @returns The global environment object.
 */
export function getGlobalEnvironment(): Environment {
    return environment;
}
