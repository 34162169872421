import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import {
    BatteryChargingIcon,
    CheckCircledOpenIcon,
    CircleCrossIcon,
    ClockIcon,
    Link,
    ListRow,
    ListView,
    Tooltip,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import { FilterValueMap } from 'filters/common';
import { SiteAllFilterMap, StaticSiteFilterDefinitions } from 'filters/site/settings';
import humanizeDuration from 'humanize-duration';
import { Paths } from 'lib/routes';
import { encodeFilterParameters } from 'lib/table-filter';

import { SiteExtraFilters, SiteTableColumn, SiteTableColumnId } from '../../../../../filters/site';
import { SortDirection } from '../../../../../layouts';
import { createSortParams } from '../../../../../lib/table-sort';
import { TimeRange, TimeRangeSearchParameter } from '../../settings';
import { SitesAtRisk$data, SitesAtRisk$key } from './__generated__/SitesAtRisk.graphql';

export interface SitesAtRiskProps {
    fragmentRef: SitesAtRisk$key;
    interval: TimeRange;
}

export const SitesAtRisk: FC<SitesAtRiskProps> = ({ fragmentRef, interval }) => {
    const data = useFragment(Fragment, fragmentRef);

    return (
        <div className='col-span-full'>
            <ListView title='Sites at risk' view='list' listDisplay='grid' contentClassName='auto-rows-fr grid-rows-5'>
                {data.sitesAtRisk.data.map((rowData, index) => (
                    <SiteAtRiskRow
                        index={index}
                        rowData={rowData}
                        key={`site-at-risk-row-${index}`}
                        interval={interval}
                    />
                ))}

                {data.sitesAtRisk.data.length === 0 && (
                    <div className='h-16 row-start-3 flex justify-center items-center'>
                        <IconWithStatus
                            icon={<CheckCircledOpenIcon />}
                            label='No incidents found'
                            iconClasses='text-pineRegular'
                        />
                    </div>
                )}
            </ListView>
            <Link
                to={{
                    pathname: Paths.ReportACPowerSiteList,
                    search: {
                        ...createSortParams(SiteTableColumn.ACRisk, SortDirection.Descending),
                        ...encodeFilterParameters<SiteTableColumnId, FilterValueMap<SiteAllFilterMap>>(
                            {
                                [SiteExtraFilters.ACReliabilityTimeRange]: interval,
                            },
                            StaticSiteFilterDefinitions
                        ),
                    },
                }}
                className='text-coralRegular float-right'
            >
                See more
            </Link>
        </div>
    );
};

const Fragment = graphql`
    fragment SitesAtRisk on Query {
        sitesAtRisk: sites(orderBy: { field: ACRisk, dir: Desc }, acReliabilityTimeRange: $timeRange, pageSize: 5) {
            data {
                id
                name
                devices {
                    data {
                        battery {
                            estimatedReserveTime(unit: Seconds)
                            reserveTime(unit: Seconds)
                        }
                    }
                }
                acPower {
                    events(filters: { isSignificant: { value: true } }) {
                        total
                    }
                    reliability(timeRange: $timeRange, unit: Seconds) {
                        durationSum
                    }
                }
            }
        }
    }
`;

interface SiteAtRiskRowProps {
    index: number;
    rowData: SitesAtRisk$data['sitesAtRisk']['data'][number];
    interval: TimeRange;
}

const SiteAtRiskRow: FC<SiteAtRiskRowProps> = ({ index, rowData, interval }) => {
    const designReserveTime = getDesignReserveTime(rowData.devices.data);
    const lowestReserveTime = getLowestReserveTime(rowData.devices.data);

    const contentBody = (
        <div className='grid grid-cols-12 grid-rows-4 lg:grid-rows-2 gap-x-4 w-full' data-testid='site-at-risk-row'>
            <Tooltip content={rowData.name} overflowOnly>
                <div className='col-span-full font-bold text-xl truncate pr-2'>{rowData.name}</div>
            </Tooltip>

            <div className='col-span-full lg:col-span-4 xl:col-span-3'>
                <IconWithStatus
                    icon={<CircleCrossIcon />}
                    label={
                        rowData.acPower.events.total === 1
                            ? '1 significant outage'
                            : `${rowData.acPower.events.total} significant outages`
                    }
                />
            </div>
            <div className='col-span-full lg:col-span-5 xl:col-span-4'>
                <IconWithStatus
                    icon={<ClockIcon />}
                    label={`${humanizeDuration(rowData.acPower.reliability.durationSum * 1000, {
                        units: ['y', 'mo', 'd', 'h', 'm'],
                        round: true,
                        largest: 1,
                    })} average duration`}
                />
            </div>

            <div className='col-span-full lg:col-span-5'>
                {rowData.devices.data.every(device => device.battery.estimatedReserveTime === null) ? (
                    <Tooltip content={`No recent battery tests have generated an estimation`}>
                        <div>
                            <IconWithStatus
                                icon={<BatteryChargingIcon />}
                                label={
                                    designReserveTime
                                        ? humanizeNumberHelper(designReserveTime, 'configured')
                                        : 'No configuration'
                                }
                                labelClasses={`text-coralRegular`}
                            />
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip
                        content={`Estimated from recent battery tests. ${
                            designReserveTime
                                ? humanizeNumberHelper(designReserveTime, 'configured')
                                : 'No configuration'
                        }`}
                    >
                        <div>
                            <IconWithStatus
                                icon={<BatteryChargingIcon />}
                                label={humanizeNumberHelper(lowestReserveTime!, 'estimated')}
                                labelClasses={getTextColor(designReserveTime, lowestReserveTime)}
                            />
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    );

    return (
        <ListRow
            label={`${index + 1}`}
            content={contentBody}
            link={{
                pathname: Paths.ReportACPowerSiteView,
                params: { siteId: rowData.id },
                search: {
                    [TimeRangeSearchParameter]: interval,
                },
            }}
        />
    );
};

function humanizeNumberHelper(number: number, type: 'estimated' | 'configured'): string {
    return `${humanizeDuration(number * 1000, {
        units: ['y', 'mo', 'd', 'h', 'm'],
        round: true,
        largest: 2,
    })} ${type} reserve time`;
}

function getTextColor(designReserveTime: number | undefined, lowestReserveTime: number | undefined) {
    if (designReserveTime && lowestReserveTime) {
        if (lowestReserveTime < designReserveTime) {
            return 'text-coralRegular';
        }
    }

    return 'text-eggplantRegular';
}

function getLowestReserveTime(
    devices: SitesAtRisk$data['sitesAtRisk']['data'][number]['devices']['data']
): number | undefined {
    let returnValue: number | undefined;

    if (devices.length !== 0) {
        devices.forEach(device => {
            const reserveTimeEstimate = device.battery.estimatedReserveTime;
            if (returnValue === undefined && reserveTimeEstimate !== null) {
                returnValue = reserveTimeEstimate;
            }

            if (returnValue && reserveTimeEstimate !== null) {
                if (reserveTimeEstimate < returnValue) {
                    returnValue = reserveTimeEstimate;
                }
            }
        });
    }

    return returnValue;
}

function getDesignReserveTime(
    devices: SitesAtRisk$data['sitesAtRisk']['data'][number]['devices']['data']
): number | undefined {
    let returnValue: number | undefined;

    if (devices.length !== 0) {
        devices.forEach(device => {
            const reserveTime = device.battery.reserveTime;

            if (returnValue === undefined && reserveTime !== null) {
                returnValue = reserveTime;
            }

            if (returnValue && reserveTime !== null) {
                if (reserveTime < returnValue) {
                    returnValue = reserveTime;
                }
            }
        });
    }

    return returnValue;
}
