import { Dispatch, useEffect } from 'react';

import { DateRangeFilter } from 'components/FilterDateSelect/common';

import { FilterAction, FilterActionType, FilterState, FilterValueMap, useFilterReducer } from '../common';
import {
    DefaultExtraFilters,
    DefaultValues,
    IncidentColumnFilterMap,
    StaticIncidentFilterDefinitions,
} from './settings';
import { IncidentExtraFilters, IncidentIsSignificantOnlyFilter, IncidentTableColumn } from './types';

export type IncidentFilterState = FilterState<IncidentTableColumn, IncidentColumnFilterMap>;
export type IncidentFilterAction = FilterAction<IncidentColumnFilterMap, IncidentTableColumn>;

export function useIncidentFilter(options?: {
    defaultFilters?: Partial<FilterValueMap<IncidentColumnFilterMap>>;
    extraFilters?: Partial<Record<IncidentExtraFilters, unknown>>;
}): [IncidentFilterState, Dispatch<IncidentFilterAction>] {
    const defaultFilters = {
        ...DefaultValues,
        ...(options?.defaultFilters ?? {}),
    } as FilterValueMap<IncidentColumnFilterMap>;

    const extraFilters = {
        ...DefaultExtraFilters,
        ...(options?.extraFilters ?? {}),
    };

    const [state, updateState] = useFilterReducer<
        IncidentTableColumn,
        IncidentColumnFilterMap,
        IncidentFilterState,
        IncidentFilterAction
    >('incident-filter', defaultFilters, StaticIncidentFilterDefinitions, extraFilters);

    useEffect(() => {
        updateState({
            type: FilterActionType.UpdateFilterTypes,
            definitions: [...StaticIncidentFilterDefinitions],
        });
    }, [updateState]);

    return [state, updateState];
}

export function incidentToFilterObject(filters: IncidentFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        DeviceName: deviceName,
        WorstStatus: incidentType,
        Duration: incidentDuration,
        AffectedFeeds: affectedFeeds,
        StartTime: incidentStartTime,
    } = filters.columnValues;

    if (deviceName.length > 0) {
        output.deviceName = deviceName.map(filter => ({ value: filter.name }));
    }

    if (incidentType.length > 0) {
        output.worstStatus = incidentType.map(filter => ({ value: filter.id }));
    }

    if (incidentDuration) {
        output.duration = {
            min: incidentDuration.range[0],
            max: incidentDuration.range[1],
        };
    }

    if (affectedFeeds.length > 0) {
        output.affectedFeeds = affectedFeeds.map(filter => ({ value: `${filter.id}` }));
    }

    if (incidentStartTime) {
        output.startTime = {
            min: incidentStartTime.range[0],
            max: incidentStartTime.range[1],
        };
    }

    for (const [definitionId, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition) {
            continue;
        }

        switch (definition.id) {
            case IncidentExtraFilters.IsSignificant: {
                const isSignificantFilter = values as IncidentIsSignificantOnlyFilter;
                if (isSignificantFilter) {
                    output.isSignificant = {
                        value: isSignificantFilter.value,
                    };
                }
                break;
            }
            case IncidentExtraFilters.ActiveWithin: {
                const activeWithinFilter = values as DateRangeFilter;
                if (activeWithinFilter) {
                    output.activeWithin = {
                        min: activeWithinFilter.range[0],
                        max: activeWithinFilter.range[1],
                    };
                }
                break;
            }
        }
    }

    return output;
}
