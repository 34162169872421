/**
 * @generated SignedSource<<c63d157893e54f9c101a2616188a554a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BatteryTaskState = "Aborted" | "Failed" | "InProgress" | "Inconclusive" | "PartialFail" | "PartialPass" | "Passed" | "Scheduled" | "%future added value";
export type TaskRepeat = "BiMonthly" | "Monthly" | "Never" | "Quarterly" | "%future added value";
export type TaskState = "Cancelled" | "Completed" | "InProgress" | "Scheduled" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskOverview_task$data = {
  readonly estimatedEndTime: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly overallState: TaskState;
  readonly schedule: {
    readonly repeat: TaskRepeat;
  };
  readonly settings: {
    readonly endOfDischargeVoltage?: number | null;
    readonly maxDischarged?: number | null;
    readonly maxDuration?: number | null;
    readonly reserveTime?: number;
    readonly targets?: ReadonlyArray<{
      readonly batteryType: {
        readonly id: string;
        readonly manufacturer: string;
        readonly model: string;
      } | null;
      readonly endOfDischargeVoltage: number;
    }>;
    readonly threshold?: number | null;
  };
  readonly testState: BatteryTaskState;
  readonly totalDevice: {
    readonly data: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly total: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OverviewFields_task" | "TaskProgressBar_task">;
  readonly " $fragmentType": "TaskOverview_task";
};
export type TaskOverview_task$key = {
  readonly " $data"?: TaskOverview_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskOverview_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endOfDischargeVoltage",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskOverview_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedEndTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskSchedule",
      "kind": "LinkedField",
      "name": "schedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeat",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BatteryTestTypeCapacityEDOVTypePair",
              "kind": "LinkedField",
              "name": "targets",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BatteryType",
                  "kind": "LinkedField",
                  "name": "batteryType",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "manufacturer",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "model",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "BatteryTestTypeCapacity",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reserveTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "threshold",
              "storageKey": null
            }
          ],
          "type": "BatteryTestTypeQuick",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxDuration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxDischarged",
              "storageKey": null
            }
          ],
          "type": "BatteryTestTypeCustom",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testState",
      "storageKey": null
    },
    {
      "alias": "totalDevice",
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OverviewFields_task"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaskProgressBar_task"
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
};
})();

(node as any).hash = "39267576b8e53189d82e46d5834435a5";

export default node;
