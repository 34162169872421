/**
 * @generated SignedSource<<17360266e672081ca67971a754b5ef9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ActivityLogSource = "Alert" | "AuditLog" | "GridEvent" | "%future added value";
export type ActivityLogType = "AlertSeverityCritical" | "AlertSeverityIndeterminate" | "AlertSeverityMajor" | "AlertSeverityMinor" | "AlertSeverityWarning" | "BatteryTest" | "DeviceAdd" | "DeviceEdit" | "DeviceRemove" | "GridEventStatusHigh" | "GridEventStatusLow" | "GridEventStatusOffline" | "%future added value";
export type AlertSeverity = "Critical" | "Indeterminate" | "Major" | "Minor" | "Warning" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type GeneratorOperationMode = "Auto" | "Manual" | "Off" | "Test" | "User" | "%future added value";
export type GeneratorState = "CoolingDown" | "Idle" | "Running" | "RunningOnLoad" | "WarmingUp" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GeneratorOverview_device$data = {
  readonly activityLogs: {
    readonly data: ReadonlyArray<{
      readonly changes: ReadonlyArray<{
        readonly field: string;
        readonly newValue: string | null;
        readonly oldValue: string | null;
      }> | null;
      readonly link: {
        readonly __typename: "Alert";
        readonly category: string;
        readonly device: {
          readonly id: string;
          readonly name: string;
        };
        readonly isActive: boolean;
        readonly message: string;
        readonly severity: AlertSeverity;
      } | {
        readonly __typename: "Device";
        readonly id: string;
        readonly name: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
      readonly source: ActivityLogSource;
      readonly timestamp: string;
      readonly type: ActivityLogType;
      readonly user: {
        readonly name: string | null;
        readonly username: string;
      } | null;
    }>;
    readonly hasMore: boolean;
    readonly total: number;
  };
  readonly connectionSettings: {
    readonly protocols: ReadonlyArray<{
      readonly gateway?: {
        readonly connectionSettings: {
          readonly addresses: ReadonlyArray<string>;
        };
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
  readonly fuelTankMetrics: {
    readonly capacity: number | null;
    readonly latestLevel: number | null;
  };
  readonly generatorMetrics: {
    readonly latestEngineRunTime: number | null;
    readonly latestOperationMode: GeneratorOperationMode | null;
    readonly latestRunningTime: string | null;
    readonly latestState: GeneratorState | null;
    readonly latestTripRunTime: number | null;
    readonly output: {
      readonly latestApparentPower: number | null;
    };
  };
  readonly generatorSettings: {
    readonly lowFuelLevelThresholdPercent: number | null;
  } | null;
  readonly health: DeviceHealth | null;
  readonly lastUpdate: string | null;
  readonly model: string | null;
  readonly name: string;
  readonly serialNumber: string | null;
  readonly site: {
    readonly id: string;
  };
  readonly type: {
    readonly displayName: string;
  };
  readonly " $fragmentType": "GeneratorOverview_device";
};
export type GeneratorOverview_device$key = {
  readonly " $data"?: GeneratorOverview_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneratorOverview_device">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitVolume"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneratorOverview_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "health",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConnectionSettings",
      "kind": "LinkedField",
      "name": "connectionSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "protocols",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Device",
                  "kind": "LinkedField",
                  "name": "gateway",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ConnectionSettings",
                      "kind": "LinkedField",
                      "name": "connectionSettings",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "addresses",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ProtocolGateway",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceGeneratorSettings",
      "kind": "LinkedField",
      "name": "generatorSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lowFuelLevelThresholdPercent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceGeneratorMetrics",
      "kind": "LinkedField",
      "name": "generatorMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestOperationMode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceGeneratorOutputMetrics",
          "kind": "LinkedField",
          "name": "output",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestApparentPower",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestRunningTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestEngineRunTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestTripRunTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceFuelTankMetrics",
      "kind": "LinkedField",
      "name": "fuelTankMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestLevel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "unit",
              "variableName": "unitVolume"
            }
          ],
          "kind": "ScalarField",
          "name": "capacity",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 5
        },
        {
          "kind": "Literal",
          "name": "types",
          "value": [
            "DeviceAdd",
            "DeviceEdit",
            "AlertSeverityCritical",
            "AlertSeverityMajor",
            "GridEventStatusOffline",
            "GridEventStatusHigh",
            "BatteryTest"
          ]
        }
      ],
      "concreteType": "PaginatedActivityLogs",
      "kind": "LinkedField",
      "name": "activityLogs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasMore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityLog",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "source",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChangeFieldValue",
              "kind": "LinkedField",
              "name": "changes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "field",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "oldValue",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "newValue",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "link",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "Device",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "severity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "message",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isActive",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Device",
                      "kind": "LinkedField",
                      "name": "device",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "Alert",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "activityLogs(limit:5,types:[\"DeviceAdd\",\"DeviceEdit\",\"AlertSeverityCritical\",\"AlertSeverityMajor\",\"GridEventStatusOffline\",\"GridEventStatusHigh\",\"BatteryTest\"])"
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "2fec7433a2cfceb38fc3ba026ae7a4cf";

export default node;
