import React, { FC } from 'react';

import { AlertCategoryFilter } from '../types';

export interface AlertCategoryFilterResultProps {
    filter: AlertCategoryFilter;
}

export const AlertCategoryFilterResult: FC<AlertCategoryFilterResultProps> = ({ filter }) => {
    if (filter.wildcard) {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span className='text-eggplantLight'>Matching</span>
                <span>{filter.value}</span>
            </div>
        );
    } else {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span>{filter.value}</span>
            </div>
        );
    }
};
