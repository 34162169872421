import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { getSectionTimeDescription } from '../../../common';
import { TimeRange } from '../../../settings';
import { DeviceFeedCard } from '../components/DeviceFeedCard';
import { ACDeviceFeedReliability_acPower$key } from './__generated__/ACDeviceFeedReliability_acPower.graphql';

export interface ACDeviceFeedReliabilityProps {
    fragmentRef: ACDeviceFeedReliability_acPower$key;
    timeRange: TimeRange;
}

export const ACDeviceFeedReliability: FC<ACDeviceFeedReliabilityProps> = ({ fragmentRef, timeRange }) => {
    const { feedEventMetrics } = useFragment(Fragment, fragmentRef);
    const { feeds } = feedEventMetrics;

    return (
        <div className='grid grid-cols-12 gap-x-4 col-span-12'>
            <div className='col-span-12 font-bold text-xl mb-2 mt-6'>
                Feed reliability{' '}
                <span className='font-light text-xs'>{getSectionTimeDescription(timeRange, 'device')}</span>
            </div>
            {feeds.map(feed => (
                <DeviceFeedCard key={feed.label} fragmentRef={feed} />
            ))}
            {feeds.length === 0 && (
                <div className='col-span-12'>
                    <p className='text-sm font-light text-eggplantLight'>
                        Feed information is unavailable for this device.
                    </p>
                </div>
            )}
        </div>
    );
};

const Fragment = graphql`
    fragment ACDeviceFeedReliability_acPower on DeviceACPower {
        feedEventMetrics(begin: $begin, end: $end) {
            feeds {
                label
                ...DeviceFeedCard
            }
        }
    }
`;
