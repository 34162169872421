/**
 * @generated SignedSource<<a0c318ac4126f3f77cbd3250579069f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetricsViewLive_metrics$data = {
  readonly latestCurrent: number | null;
  readonly latestRemainingCapacity: number | null;
  readonly latestTemperature: number | null;
  readonly latestUsedCapacity: number | null;
  readonly latestVoltage: number | null;
  readonly originalCapacity: number | null;
  readonly " $fragmentType": "MetricsViewLive_metrics";
};
export type MetricsViewLive_metrics$key = {
  readonly " $data"?: MetricsViewLive_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricsViewLive_metrics">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "AmpHour"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitTemperature"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricsViewLive_metrics",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "originalCapacity",
      "storageKey": "originalCapacity(unit:\"AmpHour\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Volts"
        }
      ],
      "kind": "ScalarField",
      "name": "latestVoltage",
      "storageKey": "latestVoltage(unit:\"Volts\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Amps"
        }
      ],
      "kind": "ScalarField",
      "name": "latestCurrent",
      "storageKey": "latestCurrent(unit:\"Amps\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "unit",
          "variableName": "unitTemperature"
        }
      ],
      "kind": "ScalarField",
      "name": "latestTemperature",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "latestRemainingCapacity",
      "storageKey": "latestRemainingCapacity(unit:\"AmpHour\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "latestUsedCapacity",
      "storageKey": "latestUsedCapacity(unit:\"AmpHour\")"
    }
  ],
  "type": "DeviceBatteryMetrics",
  "abstractKey": null
};
})();

(node as any).hash = "f3adf5780dd81e7d798200c3dc89858f";

export default node;
