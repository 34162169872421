/**
 * @generated SignedSource<<b6fddb978e3f78f19918d535b96bbb64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
export type ACPowerEventFilter = {
  activeWithin?: DateRangeFilter | null;
  affectedFeeds?: ReadonlyArray<StringFilter> | null;
  affectsAllFeeds?: BooleanFilter | null;
  deviceId?: ReadonlyArray<string> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  duration?: DurationFilter | null;
  endTime?: DateRangeFilter | null;
  isSignificant?: BooleanFilter | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  startTime?: DateRangeFilter | null;
  status?: ReadonlyArray<ACPowerEventStatusFilter> | null;
  voltage?: FloatRangeFilter | null;
  worstStatus?: ReadonlyArray<ACPowerEventStatusFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type ACPowerEventStatusFilter = {
  value: ACPowerEventStatus;
};
export type DurationFilter = {
  max: string;
  min: string;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type ACPowerIncidentListExportQuery$variables = {
  filter?: ACPowerEventFilter | null;
};
export type ACPowerIncidentListExportQuery$data = {
  readonly acEvents: {
    readonly data: ReadonlyArray<{
      readonly affectedFeeds: ReadonlyArray<{
        readonly id: string;
        readonly label: string;
        readonly status: ACPowerEventStatus;
        readonly voltage: number | null;
      }>;
      readonly device: {
        readonly id: string;
        readonly name: string;
        readonly site: {
          readonly id: string;
          readonly name: string;
        };
      };
      readonly duration: string;
      readonly endTime: string;
      readonly id: string;
      readonly startTime: string;
      readonly worstStatus: ACPowerEventStatus;
    }>;
    readonly total: number;
  };
};
export type ACPowerIncidentListExportQuery = {
  response: ACPowerIncidentListExportQuery$data;
  variables: ACPowerIncidentListExportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filter"
      },
      {
        "kind": "Literal",
        "name": "pageSize",
        "value": 10000
      }
    ],
    "concreteType": "PaginatedACPowerEvent",
    "kind": "LinkedField",
    "name": "acEvents",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ACPowerEvent",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "worstStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ACPowerEventFeed",
            "kind": "LinkedField",
            "name": "affectedFeeds",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voltage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ACPowerIncidentListExportQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ACPowerIncidentListExportQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9fdc59417bf0c4658460e17004e0d341",
    "id": null,
    "metadata": {},
    "name": "ACPowerIncidentListExportQuery",
    "operationKind": "query",
    "text": "query ACPowerIncidentListExportQuery(\n  $filter: ACPowerEventFilter\n) {\n  acEvents(pageSize: 10000, filters: $filter) {\n    total\n    data {\n      id\n      worstStatus\n      duration\n      startTime\n      endTime\n      device {\n        id\n        name\n        site {\n          id\n          name\n        }\n      }\n      affectedFeeds {\n        id\n        label\n        status\n        voltage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "76a4ef2f68ac78794a4ebd06ae1853e0";

export default node;
