import React from 'react';
import { RouteObject } from 'react-router-dom';

import { LoginView } from 'views';

import { LoggedOut } from './logged-out/LoggedOut';
import { ResetPasswordView } from './reset-password/ResetPasswordView';

const Routes: RouteObject[] = [
    {
        path: 'login',
        element: <LoginView />,
    },
    {
        path: 'logout',
        element: <LoggedOut />,
    },
    {
        path: 'reset-password',
        element: <ResetPasswordView />,
    },
];

export default Routes;
