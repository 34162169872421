import React, { FC } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { useTimeRange, useTimeRangeQuery } from 'views/reports/common';

import { CommonPropsWithTime } from '../common';
import { ReportLineChart } from './ReportLineChart';
import { CapacityChartQuery } from './__generated__/CapacityChartQuery.graphql';

export const CapacityChart: FC<CommonPropsWithTime> = ({ device, timeRange: defaultTimeRange, short, coarseTicks }) => {
    const [timeRange, menuItems] = useTimeRange(defaultTimeRange);

    const { data: result, error } = useTimeRangeQuery<CapacityChartQuery>(
        graphql`
            query CapacityChartQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
                device(id: $id) {
                    type {
                        features {
                            battery {
                                capacity
                            }
                        }
                    }
                    battery {
                        data: metrics {
                            remainingCapacity(unit: AmpHour, begin: $begin, end: $end, interval: $interval) {
                                ...ReportLineChart_data
                            }
                        }
                    }
                }
            }
        `,
        timeRange,
        { id: device }
    );

    const isDataSupported = result?.device?.type.features.battery.capacity ?? true;

    return (
        <ReportLineChart
            title='Battery capacity'
            unit='Ah'
            data={result?.device?.battery.data.remainingCapacity ?? null}
            error={!!error}
            timeRange={timeRange}
            menuItems={menuItems}
            disabled={!isDataSupported}
            short={short}
            coarseTicks={coarseTicks}
        />
    );
};
