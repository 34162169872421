import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { IncidentTypeFilter } from '../types';

export const IncidentTypeFilterOptions: IncidentTypeFilter[] = [
    { id: 'Outage', name: 'Outage' },
    { id: 'UnderVoltage', name: 'Undervoltage' },
    { id: 'OverVoltage', name: 'Overvoltage' },
];

export type ACPowerIncidentTypeFilterUIProps = CommonFilterProps<IncidentTypeFilter[]>;

export const ACPowerIncidentTypeFilterUI: FC<ACPowerIncidentTypeFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    return (
        <MultipleSelect
            options={IncidentTypeFilterOptions}
            active={current}
            title='Filter by incident type'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
