import { RunTimeFilter } from 'components/FilterDurationSelect/common';

import {
    BatteryTestNameFilter,
    BatteryTestStatusFilter,
    BatteryTestTypeFilter,
    ScheduleRepeatFilter,
    SmartStartStatus,
} from './types';

export function encodeBatteryTestNameFilter(filter: BatteryTestNameFilter): string {
    return encodeURIComponent(filter.name);
}

export function encodeBatteryTestTypeFilter(filter: BatteryTestTypeFilter): string {
    return encodeURIComponent(filter.id);
}

export function encodeBatteryTestStatusFilter(filter: BatteryTestStatusFilter): string {
    return encodeURIComponent(filter.id);
}

export function encodeBatteryTestUsingSmartStartFilter(filter: SmartStartStatus): string {
    return encodeURIComponent(filter);
}

export function encodeBatteryTestScheduleRepeatFilter(filter: ScheduleRepeatFilter): string {
    return encodeURIComponent(filter.id);
}

export function encodeBatteryTestRuntimeFilter(filter: RunTimeFilter): string {
    const separator = '-';

    const { range } = filter;

    return encodeURIComponent(range.join(separator));
}
