import React, { FC } from 'react';

import { FilterRange } from 'components/FilterRange';
import { CommonFilterProps } from 'filters/common';
import { IntRangeFilter } from 'lib/__generated__/queries_getAllDeviceIdsQuery.graphql';

export type ACPowerReliabilityRankRangeFilterProps = CommonFilterProps<IntRangeFilter | null>;

export const ACPowerReliabilityRankRangeFilterUI: FC<ACPowerReliabilityRankRangeFilterProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by AC power reliability rank'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            distributionRange={null}
            hideDistribution={true}
            limit={[0, 100]}
        />
    );
};
