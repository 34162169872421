/**
 * @generated SignedSource<<6ffd4f76ef641fe4f29393bfa54f8485>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReliabilitySection$data = {
  readonly reliablitySection20: {
    readonly data: ReadonlyArray<{
      readonly acPower: {
        readonly reliability: {
          readonly mtbf: {
            readonly current: number | null;
            readonly percentChange: number | null;
          };
          readonly mttr: {
            readonly current: number | null;
            readonly percentChange: number | null;
          };
        };
      };
    }>;
  };
  readonly reliablitySection50: {
    readonly data: ReadonlyArray<{
      readonly acPower: {
        readonly reliability: {
          readonly mtbf: {
            readonly current: number | null;
            readonly percentChange: number | null;
          };
          readonly mttr: {
            readonly current: number | null;
            readonly percentChange: number | null;
          };
        };
      };
    }>;
  };
  readonly " $fragmentType": "ReliabilitySection";
};
export type ReliabilitySection$key = {
  readonly " $data"?: ReliabilitySection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReliabilitySection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "acReliabilityTimeRange",
  "variableName": "timeRange"
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "current",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentChange",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Site",
    "kind": "LinkedField",
    "name": "data",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SiteACPower",
        "kind": "LinkedField",
        "name": "acPower",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "timeRange",
                "variableName": "timeRange"
              },
              {
                "kind": "Literal",
                "name": "unit",
                "value": "Seconds"
              }
            ],
            "concreteType": "SiteAcPowerReliabilityMetrics",
            "kind": "LinkedField",
            "name": "reliability",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReliabilityMetric",
                "kind": "LinkedField",
                "name": "mtbf",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReliabilityMetric",
                "kind": "LinkedField",
                "name": "mttr",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReliabilitySection",
  "selections": [
    {
      "alias": "reliablitySection20",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "acReliabilityPercentile": {
              "max": 20,
              "min": 20
            }
          }
        }
      ],
      "concreteType": "PaginatedSites",
      "kind": "LinkedField",
      "name": "sites",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": "reliablitySection50",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "acReliabilityPercentile": {
              "max": 50,
              "min": 50
            }
          }
        }
      ],
      "concreteType": "PaginatedSites",
      "kind": "LinkedField",
      "name": "sites",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8be8ef89cb8a4ebd44411c59881bd121";

export default node;
