/**
 * @generated SignedSource<<345f64843654d3d3deeaa5b2030d916a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesAtRisk$data = {
  readonly sitesAtRisk: {
    readonly data: ReadonlyArray<{
      readonly acPower: {
        readonly events: {
          readonly total: number;
        };
        readonly reliability: {
          readonly durationSum: number;
        };
      };
      readonly devices: {
        readonly data: ReadonlyArray<{
          readonly battery: {
            readonly estimatedReserveTime: number | null;
            readonly reserveTime: number | null;
          };
        }>;
      };
      readonly id: string;
      readonly name: string;
    }>;
  };
  readonly " $fragmentType": "SitesAtRisk";
};
export type SitesAtRisk$key = {
  readonly " $data"?: SitesAtRisk$data;
  readonly " $fragmentSpreads": FragmentRefs<"SitesAtRisk">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "unit",
  "value": "Seconds"
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SitesAtRisk",
  "selections": [
    {
      "alias": "sitesAtRisk",
      "args": [
        {
          "kind": "Variable",
          "name": "acReliabilityTimeRange",
          "variableName": "timeRange"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "dir": "Desc",
            "field": "ACRisk"
          }
        },
        {
          "kind": "Literal",
          "name": "pageSize",
          "value": 5
        }
      ],
      "concreteType": "PaginatedSites",
      "kind": "LinkedField",
      "name": "sites",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaginatedDevices",
              "kind": "LinkedField",
              "name": "devices",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Device",
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DeviceBattery",
                      "kind": "LinkedField",
                      "name": "battery",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": (v1/*: any*/),
                          "kind": "ScalarField",
                          "name": "estimatedReserveTime",
                          "storageKey": "estimatedReserveTime(unit:\"Seconds\")"
                        },
                        {
                          "alias": null,
                          "args": (v1/*: any*/),
                          "kind": "ScalarField",
                          "name": "reserveTime",
                          "storageKey": "reserveTime(unit:\"Seconds\")"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SiteACPower",
              "kind": "LinkedField",
              "name": "acPower",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "filters",
                      "value": {
                        "isSignificant": {
                          "value": true
                        }
                      }
                    }
                  ],
                  "concreteType": "PaginatedSiteACPowerEvent",
                  "kind": "LinkedField",
                  "name": "events",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "total",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "events(filters:{\"isSignificant\":{\"value\":true}})"
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "timeRange",
                      "variableName": "timeRange"
                    },
                    (v0/*: any*/)
                  ],
                  "concreteType": "SiteAcPowerReliabilityMetrics",
                  "kind": "LinkedField",
                  "name": "reliability",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "durationSum",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "fb5cd6f57ffd0174c2fa88cae986f008";

export default node;
