import React, { FC } from 'react';

import { SimpleLink } from 'components';

import { BlocId } from './common';

export interface BlocSelectionIndicatorProps {
    blocs: BlocId[];
    resetToggledBlocs: () => void;
}

export const BlocSelectionIndicator: FC<BlocSelectionIndicatorProps> = ({ blocs, resetToggledBlocs }) => {
    const perString = new Map<number, number[]>();
    const strings: number[] = [];

    for (const bloc of blocs) {
        if (perString.has(bloc[0])) {
            const blocsInString = perString.get(bloc[0])!;
            blocsInString.push(bloc[1]);
        } else {
            perString.set(bloc[0], [bloc[1]]);
            strings.push(bloc[0]);
        }
    }

    strings.sort();

    if (strings.length === 0) {
        return null;
    }

    const descriptions = [];

    for (const string of strings) {
        const parts: string[] = [];

        const blocsInString = perString.get(string)!;
        blocsInString.sort();

        if (blocsInString.length === 0) {
            continue;
        }

        let start = blocsInString[0];
        let last = blocsInString[0] - 1;
        for (const bloc of blocsInString) {
            if (bloc !== last + 1) {
                // there is a gap
                if (last - start > 0) {
                    parts.push(`Blocs ${start} - ${last}`);
                } else {
                    parts.push(`Bloc ${start}`);
                }
                start = bloc;
            }
            last = bloc;
        }

        // Remaining blocs if present
        if (last - start > 0) {
            parts.push(`Blocs ${start} - ${last}`);
        } else if (last - start === 0) {
            parts.push(`Bloc ${start}`);
        }

        const description = `String ${string} - ${parts.join(', ')}`;
        descriptions.push(description);
    }

    return (
        <div className='font-bold text-coralRegular'>
            <span className='pr-4'>{descriptions.join(', ')}:</span>
            <SimpleLink onClick={resetToggledBlocs}>Clear filters</SimpleLink>
        </div>
    );
};
