import { Dispatch } from 'react';

import { FilterAction, FilterState, useFilterReducer } from '../common';
import {
    DefaultValues,
    GeneratorRunReportColumnFilterMap,
    StaticGeneratorRunReportFilterDefinitions,
} from './settings';
import { GeneratorRunReportTableColumn } from './types';

export type GeneratorRunReportFilterState = FilterState<
    GeneratorRunReportTableColumn,
    GeneratorRunReportColumnFilterMap
>;
export type GeneratorRunReportFilterAction = FilterAction<
    GeneratorRunReportColumnFilterMap,
    GeneratorRunReportTableColumn
>;

export function useGeneratorRunReportFilter(): [
    GeneratorRunReportFilterState,
    Dispatch<GeneratorRunReportFilterAction>,
] {
    const [state, updateState] = useFilterReducer<
        GeneratorRunReportTableColumn,
        GeneratorRunReportColumnFilterMap,
        GeneratorRunReportFilterState,
        GeneratorRunReportFilterAction
    >('run-report-filter', DefaultValues, StaticGeneratorRunReportFilterDefinitions, {});

    return [state, updateState];
}

export function generatorReportToFilterObject(filters: GeneratorRunReportFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        Cause: cause,
        State: state,
        DeviceName: deviceName,
        ModelName: modelName,
        SerialNumber: serialNumber,
        FuelCapacity: fuelCapacity,
        CreatedTime: createdTime,
        FinalisedTime: finalisedTime,
        GeneratorStartTime: generatorStartTime,
        GeneratorStopTime: generatorStopTime,
        TotalRunTime: totalRunTime,
        ReportName: reportNames,
        RpmAvg: rpmAvg,
        RpmMin: rpmMin,
        RpmMax: rpmMax,
        CoolantTemperatureAvg: coolantTemperatureAvg,
        CoolantTemperatureMin: coolantTemperatureMin,
        CoolantTemperatureMax: coolantTemperatureMax,
        CoolantPressureAvg: coolantPressureAvg,
        CoolantPressureMin: coolantPressureMin,
        CoolantPressureMax: coolantPressureMax,
        OilTemperatureAvg: oilTemperatureAvg,
        OilTemperatureMin: oilTemperatureMin,
        OilTemperatureMax: oilTemperatureMax,
        OilPressureAvg: oilPressureAvg,
        OilPressureMin: oilPressureMin,
        OilPressureMax: oilPressureMax,
        PowerAvg: averageOutputPower,
        EnergyTotal: totalEneryOutput,
        FuelConsumedTotal: fuelConsumed,
        FuelRefuelledTotal: fuelRefuelled,
        FuelConsumptionRateAvg: fuelConsumptionAvg,
        FuelConsumptionRateMax: fuelConsumptionMax,
        FuelConsumptionRateMin: fuelConsumptionMin,
        FuelLevelFin: fuelLevelFinish,
        FuelLevelMin: fuelLevelMin,
        FuelLevelMax: fuelLevelMax,
    } = filters.columnValues;

    if (deviceName.length > 0) {
        output.deviceName = deviceName.map(filter => ({ value: filter.name }));
    }

    if (cause.length > 0) {
        output.cause = cause.map(filter => filter.id);
    }

    if (state.length > 0) {
        output.state = state.map(filter => filter.id);
    }

    if (createdTime) {
        const dateFilter = createdTime;
        output.createdTime = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }
    if (finalisedTime) {
        const dateFilter = finalisedTime;
        output.finalisedTime = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }
    if (generatorStartTime) {
        const dateFilter = generatorStartTime;
        output.generatorStartTime = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }
    if (generatorStopTime) {
        const dateFilter = generatorStopTime;
        output.generatorStopTime = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }

    if (totalRunTime) {
        output.totalRunTime = {
            min: totalRunTime.range[0],
            max: totalRunTime.range[1],
        };
    }

    if (reportNames.length > 0) {
        output.reportName = reportNames.map(filter => ({ value: filter.value }));
    }

    if (modelName.length > 0) {
        output.modelName = modelName.map(filter => ({ value: filter.value }));
    }

    if (serialNumber.length > 0) {
        output.serialNumber = serialNumber.map(filter => ({ value: filter.value }));
    }

    if (fuelCapacity) {
        output.fuelCapacity = {
            min: fuelCapacity.min,
            max: fuelCapacity.max,
        };
    }

    if (rpmAvg) {
        output.rpmAvg = {
            min: rpmAvg.min,
            max: rpmAvg.max,
        };
    }
    if (rpmMin) {
        output.rpmMin = {
            min: rpmMin.min,
            max: rpmMin.max,
        };
    }
    if (rpmMax) {
        output.rpmMax = {
            min: rpmMax.min,
            max: rpmMax.max,
        };
    }

    if (coolantTemperatureAvg) {
        output.coolantTemperatureAvg = {
            min: coolantTemperatureAvg.min,
            max: coolantTemperatureAvg.max,
        };
    }
    if (coolantTemperatureMin) {
        output.coolantTemperatureMin = {
            min: coolantTemperatureMin.min,
            max: coolantTemperatureMin.max,
        };
    }
    if (coolantTemperatureMax) {
        output.coolantTemperatureMax = {
            min: coolantTemperatureMax.min,
            max: coolantTemperatureMax.max,
        };
    }

    if (coolantPressureAvg) {
        output.coolantPressureAvg = {
            min: coolantPressureAvg.min,
            max: coolantPressureAvg.max,
        };
    }
    if (coolantPressureMin) {
        output.coolantPressureMin = {
            min: coolantPressureMin.min,
            max: coolantPressureMin.max,
        };
    }
    if (coolantPressureMax) {
        output.coolantPressureMax = {
            min: coolantPressureMax.min,
            max: coolantPressureMax.max,
        };
    }

    if (oilTemperatureAvg) {
        output.oilTemperatureAvg = {
            min: oilTemperatureAvg.min,
            max: oilTemperatureAvg.max,
        };
    }
    if (oilTemperatureMin) {
        output.oilTemperatureMin = {
            min: oilTemperatureMin.min,
            max: oilTemperatureMin.max,
        };
    }
    if (oilTemperatureMax) {
        output.oilTemperatureMax = {
            min: oilTemperatureMax.min,
            max: oilTemperatureMax.max,
        };
    }

    if (oilPressureAvg) {
        output.oilPressureAvg = {
            min: oilPressureAvg.min,
            max: oilPressureAvg.max,
        };
    }
    if (oilPressureMin) {
        output.oilPressureMin = {
            min: oilPressureMin.min,
            max: oilPressureMin.max,
        };
    }
    if (oilPressureMax) {
        output.oilPressureMax = {
            min: oilPressureMax.min,
            max: oilPressureMax.max,
        };
    }

    if (averageOutputPower) {
        output.averageOutputPower = {
            min: averageOutputPower.min,
            max: averageOutputPower.max,
        };
    }

    if (totalEneryOutput) {
        output.totalEneryOutput = {
            min: totalEneryOutput.min,
            max: totalEneryOutput.max,
        };
    }

    if (fuelConsumed) {
        output.fuelConsumed = {
            min: fuelConsumed.min,
            max: fuelConsumed.max,
        };
    }

    if (fuelRefuelled) {
        output.fuelRefuelled = {
            min: fuelRefuelled.min,
            max: fuelRefuelled.max,
        };
    }

    if (fuelConsumptionAvg) {
        output.fuelConsumptionRateAvg = {
            min: fuelConsumptionAvg.min,
            max: fuelConsumptionAvg.max,
        };
    }
    if (fuelConsumptionMax) {
        output.fuelConsumptionRateMax = {
            min: fuelConsumptionMax.min,
            max: fuelConsumptionMax.max,
        };
    }
    if (fuelConsumptionMin) {
        output.fuelConsumptionRateMin = {
            min: fuelConsumptionMin.min,
            max: fuelConsumptionMin.max,
        };
    }

    if (fuelLevelFinish) {
        output.fuelLevelFin = {
            min: fuelLevelFinish.min / 100,
            max: fuelLevelFinish.max / 100,
        };
    }
    if (fuelLevelMin) {
        output.fuelLevelMin = {
            min: fuelLevelMin.min / 100,
            max: fuelLevelMin.max / 100,
        };
    }
    if (fuelLevelMax) {
        output.fuelLevelMax = {
            min: fuelLevelMax.min / 100,
            max: fuelLevelMax.max / 100,
        };
    }

    return output;
}
