import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { formatDateFilter } from 'filters/common/util';

import { FilterDefinition, FilterValueMap } from '../common';
import {
    AlertCategoryFilterUI,
    AlertDateFilterUI,
    AlertDeviceNameFilterUI,
    AlertDomainFilterUI,
    AlertIsActiveFilterUI,
    AlertOriginatorFilterUI,
    AlertSeverityFilterUI,
    AlertSiteNameFilterUI,
} from './components';
import {
    decodeAlertCategoryFilter,
    decodeAlertClearDateFilter,
    decodeAlertDeviceNameFilter,
    decodeAlertDomainFilter,
    decodeAlertIsActiveFilter,
    decodeAlertOriginatorFilter,
    decodeAlertRaiseDateFilter,
    decodeAlertSeverityFilter,
    decodeAlertSiteNameFilter,
} from './decode';
import {
    encodeAlertCategoryFilter,
    encodeAlertClearDateFilter,
    encodeAlertDeviceNameFilter,
    encodeAlertDomainFilter,
    encodeAlertIsActiveFilter,
    encodeAlertOriginatorFilter,
    encodeAlertRaiseDateFilter,
    encodeAlertSeverityFilter,
    encodeAlertSiteNameFilter,
} from './encode';
import {
    AlertCategoryFilter,
    AlertClearTimeFilter,
    AlertDeviceNameFilter,
    AlertDomainFilter,
    AlertExtraFilters,
    AlertOriginatorFilter,
    AlertRaiseTimeFilter,
    AlertSeverityFilter,
    AlertSiteNameFilter,
    AlertTableColumn,
    IsAlertActive,
} from './types';

export type AlertColumnFilterMap = {
    [AlertTableColumn.Severity]: AlertSeverityFilter[];
    [AlertTableColumn.Domain]: AlertDomainFilter[];
    [AlertTableColumn.Message]: null;
    [AlertTableColumn.DeviceName]: AlertDeviceNameFilter[];
    [AlertTableColumn.SiteName]: AlertSiteNameFilter[];
    [AlertTableColumn.RaiseDate]: DateRangeFilter | null;
    [AlertTableColumn.ClearDate]: DateRangeFilter | null;
    [AlertTableColumn.Category]: AlertCategoryFilter[];
    [AlertTableColumn.Originator]: AlertOriginatorFilter[];
    [AlertTableColumn.IsActive]: IsAlertActive | null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type AlertExtraFilterMap = {};

export type AlertAllFilterMap = AlertColumnFilterMap & AlertExtraFilterMap;

export const DefaultValues: FilterValueMap<AlertColumnFilterMap> = {
    [AlertTableColumn.Severity]: [],
    [AlertTableColumn.Domain]: [],
    [AlertTableColumn.Message]: null,
    [AlertTableColumn.DeviceName]: [],
    [AlertTableColumn.SiteName]: [],
    [AlertTableColumn.RaiseDate]: null,
    [AlertTableColumn.ClearDate]: null,
    [AlertTableColumn.Category]: [],
    [AlertTableColumn.Originator]: [],
    [AlertTableColumn.IsActive]: null,
};

export const DefaultExtraFilters: Record<AlertExtraFilters, unknown> = {};

export const StaticAlertFilterDefinitions: FilterDefinition<AlertTableColumn>[] = [
    {
        id: AlertTableColumn.Severity,
        type: 'multi',
        name: 'Severity',
        category: 'Severity',
        column: AlertTableColumn.Severity,
        component: () => AlertSeverityFilterUI,
        describeValue: (value: AlertSeverityFilter) => value.value,
        encodeValue: encodeAlertSeverityFilter,
        decodeValue: decodeAlertSeverityFilter,
    },
    {
        id: AlertTableColumn.Domain,
        type: 'multi',
        name: 'Domain',
        category: 'Domain',
        column: AlertTableColumn.Domain,
        component: () => AlertDomainFilterUI,
        describeValue: (value: AlertDomainFilter) => value.value,
        encodeValue: encodeAlertDomainFilter,
        decodeValue: decodeAlertDomainFilter,
    },
    {
        id: AlertTableColumn.DeviceName,
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        column: AlertTableColumn.DeviceName,
        component: () => AlertDeviceNameFilterUI,
        describeValue: (value: AlertDeviceNameFilter) => value.name,
        encodeValue: encodeAlertDeviceNameFilter,
        decodeValue: decodeAlertDeviceNameFilter,
    },
    {
        id: AlertTableColumn.SiteName,
        type: 'multi',
        name: 'Site Name',
        category: 'Site Name',
        column: AlertTableColumn.SiteName,
        component: () => AlertSiteNameFilterUI,
        describeValue: (value: AlertSiteNameFilter) => value.name,
        encodeValue: encodeAlertSiteNameFilter,
        decodeValue: decodeAlertSiteNameFilter,
    },
    {
        id: AlertTableColumn.RaiseDate,
        type: 'single',
        name: 'Raise Time',
        category: 'Raise Time',
        column: AlertTableColumn.RaiseDate,
        component: () => AlertDateFilterUI,
        describeValue: (value: AlertRaiseTimeFilter) => formatDateFilter(value),
        encodeValue: encodeAlertRaiseDateFilter,
        decodeValue: decodeAlertRaiseDateFilter,
    },
    {
        id: AlertTableColumn.ClearDate,
        type: 'single',
        name: 'Cleared Time',
        category: 'Cleared Time',
        column: AlertTableColumn.ClearDate,
        component: () => AlertDateFilterUI,
        describeValue: (value: AlertClearTimeFilter) => formatDateFilter(value),
        encodeValue: encodeAlertClearDateFilter,
        decodeValue: decodeAlertClearDateFilter,
    },
    {
        id: AlertTableColumn.IsActive,
        type: 'single',
        name: 'Is Active',
        category: 'Is Active',
        column: AlertTableColumn.IsActive,
        component: () => AlertIsActiveFilterUI,
        describeValue: (value: IsAlertActive) => value,
        encodeValue: encodeAlertIsActiveFilter,
        decodeValue: decodeAlertIsActiveFilter,
    },
    {
        id: AlertTableColumn.Category,
        type: 'multi',
        name: 'Category',
        category: 'Category',
        column: AlertTableColumn.Category,
        component: () => AlertCategoryFilterUI,
        describeValue: (value: AlertCategoryFilter) => value.value,
        encodeValue: encodeAlertCategoryFilter,
        decodeValue: decodeAlertCategoryFilter,
    },
    {
        id: AlertTableColumn.Originator,
        type: 'multi',
        name: 'Originator',
        category: 'Originator',
        column: AlertTableColumn.Originator,
        component: () => AlertOriginatorFilterUI,
        describeValue: (value: AlertOriginatorFilter) => value.value,
        encodeValue: encodeAlertOriginatorFilter,
        decodeValue: decodeAlertOriginatorFilter,
    },
];
