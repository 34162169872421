import React, { FC, ReactNode } from 'react';

import { Link, NavigateTo, Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';

interface InlineLabelWithMetricProps {
    label: string;
    metric: string;
    icon?: ReactNode;
    link?: NavigateTo;
}

export const InlineLabelWithMetric: FC<InlineLabelWithMetricProps> = ({ label, metric, icon, link }) => {
    let metricContent: ReactNode;
    if (link) {
        metricContent = (
            <Link className={classNames('font-semibold', { 'hover:underline': link })} to={link}>
                {metric}
            </Link>
        );
    } else {
        metricContent = <div className='font-semibold'>{metric}</div>;
    }

    return (
        <div className='flex flex-row w-full'>
            <Tooltip overflowOnly content={<div className='whitespace-nowrap'>{label}</div>}>
                <div className='font-light truncate'>{label}</div>
            </Tooltip>
            <div className='border-b border-eggplantExtraLight border-dashed flex-grow mb-1 mx-1 shrink-0' />
            <div className='flex flex-row items-center shrink-0'>
                {icon ? <div className='h-4 w-4'>{icon}</div> : <></>}
                {metricContent}
            </div>
        </div>
    );
};
