/**
 * @generated SignedSource<<94b2cf3874e3b0bca17ab231517cc507>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlertDomain = "AcPower" | "Battery" | "Load" | "Rectifier" | "%future added value";
export type AlertOriginator = "Device" | "%future added value";
export type AlertSeverity = "Critical" | "Indeterminate" | "Major" | "Minor" | "Warning" | "%future added value";
export type AlertSortField = "Category" | "ClearDate" | "DeviceName" | "Domain" | "IsActive" | "Message" | "Originator" | "RaiseDate" | "Severity" | "SiteName" | "Type" | "%future added value";
export type AlertType = "Insight" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type AlertOrdering = {
  dir?: SortDirection | null;
  field: AlertSortField;
};
export type AlertFilter = {
  category?: ReadonlyArray<StringFilter> | null;
  clearDate?: DateRangeFilter | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  domain?: ReadonlyArray<AlertDomainFilter> | null;
  ids?: ReadonlyArray<string> | null;
  isActive?: BooleanFilter | null;
  message?: ReadonlyArray<StringFilter> | null;
  originator?: ReadonlyArray<AlertOriginatorFilter> | null;
  raiseDate?: DateRangeFilter | null;
  severity?: ReadonlyArray<AlertSeverityFilter> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  suggestions?: ReadonlyArray<StringFilter> | null;
  type?: ReadonlyArray<AlertTypeFilter> | null;
};
export type AlertTypeFilter = {
  value: AlertType;
};
export type AlertSeverityFilter = {
  value: AlertSeverity;
};
export type StringFilter = {
  value: string;
};
export type AlertDomainFilter = {
  value: AlertDomain;
};
export type AlertOriginatorFilter = {
  value: AlertOriginator;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type InsightsContentRefetchQuery$variables = {
  filter?: AlertFilter | null;
  id: string;
  onlyActiveAlerts?: boolean | null;
  orderBy?: AlertOrdering | null;
  page?: number | null;
};
export type InsightsContentRefetchQuery$data = {
  readonly alerts: {
    readonly " $fragmentSpreads": FragmentRefs<"InsightTable_alerts">;
  };
};
export type InsightsContentRefetchQuery = {
  response: InsightsContentRefetchQuery$data;
  variables: InsightsContentRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyActiveAlerts"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v5 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "deviceIds.0",
        "variableName": "id"
      }
    ],
    "kind": "ListValue",
    "name": "deviceIds"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filter"
  },
  {
    "kind": "Literal",
    "name": "includeCompanionDevices",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "onlyActiveAlerts",
    "variableName": "onlyActiveAlerts"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InsightsContentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PaginatedAlerts",
        "kind": "LinkedField",
        "name": "alerts",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InsightTable_alerts"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "InsightsContentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PaginatedAlerts",
        "kind": "LinkedField",
        "name": "alerts",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "severity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "originator",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "raiseDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clearDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "suggestions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "page",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNext",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPrevious",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9036c9b04fa50d108d48977264ac30d3",
    "id": null,
    "metadata": {},
    "name": "InsightsContentRefetchQuery",
    "operationKind": "query",
    "text": "query InsightsContentRefetchQuery(\n  $id: ID!\n  $page: Int = 1\n  $orderBy: AlertOrdering\n  $filter: AlertFilter\n  $onlyActiveAlerts: Boolean\n) {\n  alerts(deviceIds: [$id], includeCompanionDevices: true, page: $page, orderBy: $orderBy, filters: $filter, onlyActiveAlerts: $onlyActiveAlerts) {\n    ...InsightTable_alerts\n  }\n}\n\nfragment InsightTable_alerts on PaginatedAlerts {\n  total\n  data {\n    id\n    type\n    severity\n    category\n    domain\n    message\n    originator\n    raiseDate\n    clearDate\n    isActive\n    suggestions\n    device {\n      id\n      name\n    }\n    site {\n      id\n      name\n      address {\n        state\n      }\n    }\n  }\n  pageInfo {\n    page\n    size\n    total\n    hasNext\n    hasPrevious\n  }\n}\n"
  }
};
})();

(node as any).hash = "678bce0343d84dc1407a8de74dfc4872";

export default node;
