import React, { FC } from 'react';

import { SkeletonText } from '@accesstel/pcm-ui';

import classNames from 'classnames';

import style from './style.module.css';

export const ACSiteReportLayoutSkeleton: FC = () => {
    return (
        <div className='space-y-6'>
            <div>
                <SkeletonText characters={30} size='heading' />
                <div className='flex flex-row justify-between items-baseline'>
                    <SkeletonText characters={18} className='font-light' />
                </div>
            </div>

            <div>
                <div className={style.placeholder__tabs}>
                    <div className={style.placeholder__tabs__tab}></div>
                </div>

                <div className={classNames('bg-white p-8 space-y-8', style.main_area)}></div>
            </div>
        </div>
    );
};
