import React, { FC } from 'react';

import { Button, IconButton, PlusIcon, Tooltip } from '@accesstel/pcm-ui';

export interface AddColumnProps {
    /**
     * The column index the column occupies
     */
    column: number;

    /**
     * The number of rows in the column
     */
    rows: number;

    /**
     * Whether the column is small
     */
    small?: boolean;

    label: string;

    addButtonEnabled?: boolean;

    addButtonDisabledMessage?: string;

    onClick?: () => void;
}

export const AddColumn: FC<AddColumnProps> = ({
    column,
    rows,
    small,
    label,
    addButtonEnabled = true,
    addButtonDisabledMessage,
    onClick,
}) => {
    return (
        <>
            <div
                className='bg-white bg-opacity-40 grid place-items-center'
                style={{
                    gridColumn: column,
                    gridRow: 1,
                }}
            >
                {!small && (
                    <Button
                        buttonText={label}
                        variant='secondary'
                        size='small'
                        type='button'
                        onClick={onClick}
                        disabled={!addButtonEnabled}
                        disabledMessage={addButtonDisabledMessage}
                    />
                )}
                {small && (
                    <Tooltip content={label}>
                        <div>
                            <IconButton
                                icon={<PlusIcon />}
                                circleColor='white'
                                iconStroke='coral'
                                size='medium'
                                onClick={onClick}
                                disabled={!addButtonEnabled}
                            />
                        </div>
                    </Tooltip>
                )}
            </div>
            {Array.from({ length: rows }, (_, index) => (
                <div
                    key={index}
                    className='bg-white bg-opacity-40'
                    style={{
                        gridColumn: column,
                        gridRow: index + 2,
                    }}
                ></div>
            ))}
        </>
    );
};
