import { logError } from 'lib/log';

import { FilterState, FilterValue } from './types';

export function getFilters<ColumnType extends string, TypeMap extends Record<ColumnType, unknown>>(
    state: FilterState<ColumnType, TypeMap>
): FilterValue[] {
    const filters: FilterValue[] = [];
    for (const [column, singleValueOrArray] of Object.entries(state.columnValues)) {
        const definition = state.filterDefinitions.find(definition => definition.id === column);
        if (!definition) {
            if (process.env.NODE_ENV !== 'test') {
                logError('Warning: Definition not found for column filter', column);
            }
            continue;
        }

        if (Array.isArray(singleValueOrArray)) {
            singleValueOrArray.forEach((entry, index) => {
                filters.push({
                    definition: definition.id,
                    value: entry,
                    index,
                });
            });
        } else {
            filters.push({
                definition: definition.id,
                value: singleValueOrArray,
                index: -1,
            });
        }
    }

    for (const [definitionId, values] of Object.entries(state.extraFilters)) {
        const definition = state.filterDefinitions.find(definition => definition.id === definitionId);

        if (!definition) {
            if (process.env.NODE_ENV !== 'test') {
                logError('Warning: Definition not found for extra filter', definitionId);
            }
            continue;
        }

        if (Array.isArray(values)) {
            values.forEach((entry, index) => {
                filters.push({
                    definition: definition.id,
                    value: entry,
                    index,
                });
            });
        } else {
            filters.push({
                definition: definition.id,
                value: values,
                index: -1,
            });
        }
    }

    return filters.filter(({ value }) => value !== null);
}
