import React, { FC, Suspense } from 'react';
import { useFragment } from 'react-relay';

import { AlertIcon, DateLineChart, LineChartSeries, SkeletonChart, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { numberToLocaleString } from 'lib/numberFormatters';
import { DateTime } from 'luxon';

import {
    TemperatureChart_metrics$data,
    TemperatureChart_metrics$key,
} from './__generated__/TemperatureChart_metrics.graphql';

interface TemperatureChartProps {
    fragmentRef: TemperatureChart_metrics$key;
}

export const TemperatureChart: FC<TemperatureChartProps> = ({ fragmentRef }) => {
    const data = useFragment<TemperatureChart_metrics$key>(Fragment, fragmentRef);

    const chartData: LineChartSeries<Date>[] = [
        {
            name: 'Temperature',
            color: Theme.coralRegular,
            data:
                data.aggregatedTemperature?.map(dataPoint => ({
                    key: DateTime.fromISO(dataPoint.timestamp).toJSDate(),
                    value: dataPoint.average,
                })) ?? [],
        },
    ];

    if (!isTemperatureSupported(data)) {
        return (
            <div className='h-24 w-full bg-grayRegular rounded-lg text-sm text-coralRegular flex flex-col items-center p-2 space-y-1'>
                <div className='h-5 w-5'>
                    <AlertIcon />
                </div>
                <div>Data Unavailable</div>
                <div className='text-xs font-light text-eggplantRegular text-center'>
                    No temperature sensor installed on this device
                </div>
            </div>
        );
    }

    return (
        <Suspense fallback={<SkeletonChart aspectRatio='1.7' stretch hideText />}>
            <DateLineChart
                series={chartData}
                hideTooltip={true}
                sizeVariant='small'
                aspectRatio={1.7}
                formatYTick={(value, index, tickCount) => {
                    if (index === 0) {
                        return numberToLocaleString(Number(value), 0);
                    }

                    if (index === tickCount - 1) {
                        return numberToLocaleString(Number(value), 0);
                    }

                    return '';
                }}
            />
        </Suspense>
    );
};

function isTemperatureSupported(data: TemperatureChart_metrics$data): boolean {
    const voltageData = data.voltageCheck;
    const temperatureData = data.aggregatedTemperature;
    const currentData = data.currentCheck;
    const powerData = data.powerCheck;

    return !(
        voltageData &&
        voltageData.every(voltage => voltage.average !== null) &&
        voltageData.length > 0 &&
        currentData &&
        currentData.every(current => current.average !== null) &&
        currentData.length > 0 &&
        powerData &&
        powerData.every(power => power.average !== null) &&
        powerData.length > 0 &&
        (temperatureData === null ||
            temperatureData.length === 0 ||
            temperatureData.every(temperature => temperature.average === null))
    );
}

const Fragment = graphql`
    fragment TemperatureChart_metrics on DeviceBatteryTestResults
    @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
        aggregatedTemperature(points: 20, unit: $unitTemperature) {
            timestamp
            average
        }
        currentCheck: aggregatedCurrent {
            average
        }
        powerCheck: aggregatedPower {
            average
        }
        voltageCheck: aggregatedVoltage {
            average
        }
    }
`;
