/**
 * @generated SignedSource<<25f965354e4f332ca0294395f94e4958>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ACTimeRange = "Last6Months" | "LastMonth" | "LastQuarter" | "LastYear" | "Lifetime" | "%future added value";
export type SiteListSearchQuery$variables = {
  acTimeRange: ACTimeRange;
  name?: string | null;
  pageSize: number;
};
export type SiteListSearchQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type SiteListSearchQuery = {
  response: SiteListSearchQuery$data;
  variables: SiteListSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acTimeRange"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "acReliabilityTimeRange",
        "variableName": "acTimeRange"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "value",
                "variableName": "name"
              }
            ],
            "kind": "ObjectValue",
            "name": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      },
      {
        "kind": "Literal",
        "name": "onlyProvisioningStatuses",
        "value": "Active"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize"
      }
    ],
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteListSearchQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteListSearchQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e073cd21cd7f69a06ceefe047235e885",
    "id": null,
    "metadata": {},
    "name": "SiteListSearchQuery",
    "operationKind": "query",
    "text": "query SiteListSearchQuery(\n  $name: String = \"\"\n  $pageSize: Int!\n  $acTimeRange: ACTimeRange!\n) {\n  sites(filters: {name: {value: $name}}, pageSize: $pageSize, onlyProvisioningStatuses: Active, acReliabilityTimeRange: $acTimeRange) {\n    data {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbd63be125dbc461ca3d4b1695eca554";

export default node;
