import { Dispatch, useEffect } from 'react';

import { FilterAction, FilterActionType, FilterState, useFilterReducer } from '../common';
import {
    DefaultExtraFilters,
    DefaultValues,
    SiteIncidentColumnFilterMap,
    StaticSiteIncidentFilterDefinitions,
} from './settings';
import { SiteIncidentExtraFilters, SiteIncidentIsSignificantOnlyFilter, SiteIncidentTableColumn } from './types';

export type SiteIncidentFilterState = FilterState<SiteIncidentTableColumn, SiteIncidentColumnFilterMap>;
export type SiteIncidentFilterAction = FilterAction<SiteIncidentColumnFilterMap, SiteIncidentTableColumn>;
export function useSiteIncidentFilter(): [SiteIncidentFilterState, Dispatch<SiteIncidentFilterAction>] {
    const [state, updateState] = useFilterReducer<
        SiteIncidentTableColumn,
        SiteIncidentColumnFilterMap,
        SiteIncidentFilterState,
        SiteIncidentFilterAction
    >('site-incident-filter', DefaultValues, StaticSiteIncidentFilterDefinitions, DefaultExtraFilters);
    useEffect(() => {
        updateState({
            type: FilterActionType.UpdateFilterTypes,
            definitions: [...StaticSiteIncidentFilterDefinitions],
        });
    }, [updateState]);
    return [state, updateState];
}
export function siteIncidentToFilterObject(filters: SiteIncidentFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        DeviceName: deviceName,
        WorstStatus: incidentType,
        Duration: incidentDuration,
        AffectedFeeds: affectedFeeds,
        StartTime: incidentStartTime,
    } = filters.columnValues;
    if (deviceName.length > 0) {
        output.deviceName = deviceName.map(filter => ({ value: filter.name }));
    }
    if (incidentType.length > 0) {
        output.worstStatus = incidentType.map(filter => ({ value: filter.id }));
    }
    if (incidentDuration) {
        output.duration = {
            min: incidentDuration.range[0],
            max: incidentDuration.range[1],
        };
    }
    if (affectedFeeds.length > 0) {
        output.affectedFeeds = affectedFeeds.map(filter => ({ value: `${filter.id}` }));
    }
    if (incidentStartTime) {
        output.startTime = {
            min: incidentStartTime.range[0],
            max: incidentStartTime.range[1],
        };
    }

    for (const [definitionId, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition) {
            continue;
        }

        switch (definition.id) {
            case SiteIncidentExtraFilters.IsSignificant: {
                const isSignificantFilter = values as SiteIncidentIsSignificantOnlyFilter;
                if (isSignificantFilter) {
                    output.isSignificant = {
                        value: isSignificantFilter.value,
                    };
                }
                break;
            }
        }
    }

    return output;
}
