/**
 * @generated SignedSource<<05f9f455fed07f18d456d55da8f27d8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TaskBatteryTestNameFilterUI_testNameSearchQuery$variables = {
  deviceIds?: ReadonlyArray<string> | null;
  input: string;
  maxResults: number;
  state?: ReadonlyArray<string> | null;
};
export type TaskBatteryTestNameFilterUI_testNameSearchQuery$data = {
  readonly tasks: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
    }>;
  };
};
export type TaskBatteryTestNameFilterUI_testNameSearchQuery = {
  response: TaskBatteryTestNameFilterUI_testNameSearchQuery$data;
  variables: TaskBatteryTestNameFilterUI_testNameSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxResults"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "deviceIds",
        "variableName": "deviceIds"
      },
      {
        "items": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "value",
                "variableName": "input"
              }
            ],
            "kind": "ObjectValue",
            "name": "name.0"
          }
        ],
        "kind": "ListValue",
        "name": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "maxResults"
  },
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "state"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskBatteryTestNameFilterUI_testNameSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PaginatedTasks",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TaskBatteryTestNameFilterUI_testNameSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PaginatedTasks",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1d883c062ec7c6ffd0042b3f533f64b",
    "id": null,
    "metadata": {},
    "name": "TaskBatteryTestNameFilterUI_testNameSearchQuery",
    "operationKind": "query",
    "text": "query TaskBatteryTestNameFilterUI_testNameSearchQuery(\n  $input: String!\n  $maxResults: Int!\n  $state: [String!]\n  $deviceIds: [ID!]\n) {\n  tasks(filters: {name: [{value: $input}], deviceIds: $deviceIds}, pageSize: $maxResults, state: $state) {\n    data {\n      __typename\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c64a8d85ef1a5efc434f232699a7d232";

export default node;
