/**
 * @generated SignedSource<<7627d0ecb856d756aef93710383589cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type queries_GetSiteNamesQuery$variables = {
  limit: number;
  search: string;
};
export type queries_GetSiteNamesQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type queries_GetSiteNamesQuery = {
  response: queries_GetSiteNamesQuery$data;
  variables: queries_GetSiteNamesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "page",
        "value": 1
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_GetSiteNamesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queries_GetSiteNamesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "067f12874b78d377a9d97513b62d9700",
    "id": null,
    "metadata": {},
    "name": "queries_GetSiteNamesQuery",
    "operationKind": "query",
    "text": "query queries_GetSiteNamesQuery(\n  $search: String!\n  $limit: Int!\n) {\n  sites(search: $search, page: 1, pageSize: $limit) {\n    data {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05eafaef1190c0bd75a2a45b606a1e34";

export default node;
