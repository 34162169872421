import React, { FC, ReactNode } from 'react';

import { Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';

export interface LabelWithIconProps {
    label: ReactNode;
    icon: ReactNode;
    title?: string;
    classNames?: string;
}

// NOTE: This is similar to IconWithStatus, but slightly different sizing which fits the cards better

export const SmallerLabelWithIcon: FC<LabelWithIconProps> = ({ label, icon, title, classNames: extraClasses }) => {
    return (
        <Tooltip content={title}>
            <div
                className={classNames(
                    'flex h-4 gap-2 items-center relative text-nowrap whitespace-nowrap',
                    extraClasses
                )}
            >
                <div className='w-4 inline-block'>{icon}</div>
                <span>{label}</span>
            </div>
        </Tooltip>
    );
};
