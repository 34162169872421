import { captureMessage } from '@sentry/react';
import { logError } from 'lib/log';

import { MetricUnit } from '../__generated__/MetricsExploreContentQuery.graphql';

type Scale = 'unit' | 'milli' | 'kilo';

export function convertUnitToString(unit: MetricUnit, scale: Scale): string {
    const base = getUnitBaseText(unit);

    switch (scale) {
        case 'unit':
            return base;
        case 'milli':
            return `m${base}`;
        case 'kilo':
            return `k${base}`;
    }
}

export function getUnitBaseText(unit: MetricUnit): string {
    switch (unit) {
        case 'AmpHour':
            return 'Ah';
        case 'Amp':
            return 'A';
        case 'Celsius':
            return '°C';
        case 'Fahrenheit':
            return '°F';
        case 'Kelvin':
            return 'K';
        case 'Hertz':
            return 'Hz';
        case 'Minutes':
            return 'min';
        case 'Percent':
            return '%';
        case 'VoltAmp':
            return 'VA';
        case 'Volt':
            return 'V';
        case 'WattHour':
            return 'Wh';
        case 'Watt':
            return 'W';
        case 'Degree':
            return '°';
        case 'Hours':
            return 'hr';
        case 'Liter':
            return 'L';
        case 'USGallon':
            return 'gal';
        case 'LiterPerHour':
            return 'L/hr';
        case 'USGallonPerHour':
            return 'gal/hr';
        case 'Pascal':
            return 'Pa';
        case 'KiloPascal':
            return 'kPa';
        case 'Bar':
            return 'bar';
        case 'PSI':
            return 'psi';
        case 'RPM':
            return 'RPM';
        case 'VoltAmpReactive':
            return 'VAr';
        default:
            logError(`Unknown unit: ${unit}`);
            captureMessage('Unhandled unit', context => {
                context.setTag('Function', 'getUnitBaseText');
                context.setExtra('unit', unit);

                return context;
            });
            return '';
    }
}

export function getUnitDisplayName(unit: MetricUnit, scale: Scale = 'unit'): string {
    switch (unit) {
        case 'AmpHour':
            if (scale === 'milli') {
                return 'Milliamp Hours (mAh)';
            }
            return 'Amp Hours (Ah)';
        case 'Amp':
            if (scale === 'milli') {
                return 'Milliamps (mA)';
            }

            return 'Amps (A)';
        case 'Celsius':
            return 'Celsius (°C)';
        case 'Fahrenheit':
            return 'Fahrenheit (°F)';
        case 'Kelvin':
            return 'Kelvin (K)';
        case 'Hertz':
            return 'Hertz (Hz)';
        case 'Minutes':
            return 'Minutes (min)';
        case 'Percent':
            return 'Percent (%)';
        case 'VoltAmp':
            if (scale === 'kilo') {
                return 'Kilovolt Amps (kVA)';
            }
            return 'Volt Amps (VA)';
        case 'Volt':
            if (scale === 'milli') {
                return 'Millivolts (mV)';
            }
            return 'Volts (V)';
        case 'WattHour':
            if (scale === 'kilo') {
                return 'Kilowatt Hours (kWh)';
            }

            return 'Watt Hours (Wh)';
        case 'Watt':
            if (scale === 'kilo') {
                return 'Kilowatts (kW)';
            } else if (scale === 'milli') {
                return 'Milliwatts (mW)';
            }
            return 'Watts (W)';
        case 'Degree':
            return 'Degrees (°)';
        case 'Hours':
            return 'Hours (hr)';
        case 'Liter':
            return 'Litres (L)';
        case 'USGallon':
            return 'Gallons (gal)';
        case 'LiterPerHour':
            return 'Litres per Hour (L/hr)';
        case 'USGallonPerHour':
            return 'Gallons per Hour (gal/hr)';
        case 'Pascal':
            return 'Pascals (Pa)';
        case 'KiloPascal':
            return 'Kilopascals (kPa)';
        case 'Bar':
            return 'Bars (bar)';
        case 'PSI':
            return 'Pounds per Square Inch (psi)';
        case 'RPM':
            return 'RPM';
        case 'VoltAmpReactive':
            if (scale === 'kilo') {
                return 'Kilovolt Amps Reactive (kVAr)';
            }
            return 'Volt Amps Reactive (VAr)';
        default:
            captureMessage('Unhandled unit', context => {
                context.setTag('Function', 'getUnitDisplayName');
                context.setExtra('unit', unit);

                return context;
            });
            logError(`Unknown unit: ${unit}`);
            return '';
    }
}
