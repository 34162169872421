import React, { FC } from 'react';

import { Mode } from '../DeviceRectifiers';
import { DeviceRectifiers_device$data } from '../__generated__/DeviceRectifiers_device.graphql';
import { ModuleRow } from './ModuleRow';
import { ModuleTile } from './ModuleTile';

interface RectifierModuleDisplayProps {
    modules: NonNullable<DeviceRectifiers_device$data['rectifier']>['modules']['data'];
    displayMode: Mode;
    deviceId: string;
    deviceOffline: boolean;
}

export const RectifierModuleDisplay: FC<RectifierModuleDisplayProps> = ({
    modules,
    displayMode,
    deviceId,
    deviceOffline,
}) => {
    return (
        <>
            {modules.map(module =>
                displayMode === Mode.Tiles ? (
                    <ModuleTile key={module.id} module={module} deviceId={deviceId} deviceOffline={deviceOffline} />
                ) : (
                    <ModuleRow key={module.id} module={module} deviceId={deviceId} deviceOffline={deviceOffline} />
                )
            )}
            {modules.length === 0 && (
                <>
                    <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    <div className='col-span-4 grid place-items-center'>
                        <div className=''>
                            <span>Module information is unavailable</span>
                        </div>
                    </div>
                    <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                </>
            )}
        </>
    );
};
