import { IANAZone } from 'luxon';
import { object, string } from 'yup';

import { TimezoneOption } from './lib/timezone';

export const UnitSettingsValidationSchema = object({
    preset: string().required('This is required'),
    temperature: string().required('This is required'),
    pressure: string().required('This is required'),
    volume: string().required('This is required'),
    volumetricFlowRate: string().required('This is required'),
});

export const TimezoneSettingsValidationSchema = object({
    preset: string().required('This is required'),
    timezone: object()
        .required('This is required')
        .test('timezone', 'Invalid timezone', value => {
            return IANAZone.isValidZone((value as unknown as TimezoneOption).name);
        }),
});
