/**
 * @generated SignedSource<<08f9138395fb73b92e854baeee6d20f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ACTimeRange = "Last6Months" | "LastMonth" | "LastQuarter" | "LastYear" | "Lifetime" | "%future added value";
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type SitesWithBatteries = "All" | "None" | "Partial" | "%future added value";
export type SiteFilter = {
  acIncidentCount?: IntRangeFilter | null;
  acMtbf?: IntRangeFilter | null;
  acMttr?: IntRangeFilter | null;
  acOutageDurationSum?: IntRangeFilter | null;
  acReliabilityPercentile?: IntRangeFilter | null;
  attributes?: ReadonlyArray<AttributeFilters> | null;
  batteryCapacity?: FloatRangeFilter | null;
  batteryStatus?: ReadonlyArray<BatteryStatusFilter> | null;
  batteryTotalTimeRemaining?: FloatRangeFilter | null;
  deviceBatteries?: SitesWithBatteries | null;
  deviceCount?: IntRangeFilter | null;
  deviceHealth?: ReadonlyArray<DeviceHealthFilter> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  ids?: ReadonlyArray<string> | null;
  name?: ReadonlyArray<StringFilter> | null;
  postcode?: ReadonlyArray<StringFilter> | null;
  state?: ReadonlyArray<StringFilter> | null;
  type?: ReadonlyArray<StringFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type DeviceHealthFilter = {
  value: DeviceHealth;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type BatteryStatusFilter = {
  value: BatteryStatus;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type BatterySiteListAllQuery$variables = {
  acTimeRange: ACTimeRange;
  filters?: SiteFilter | null;
};
export type BatterySiteListAllQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly devices: {
        readonly data: ReadonlyArray<{
          readonly id: string;
        }>;
      };
    }>;
  };
};
export type BatterySiteListAllQuery = {
  response: BatterySiteListAllQuery$data;
  variables: BatterySiteListAllQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acTimeRange"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = [
  {
    "kind": "Variable",
    "name": "acReliabilityTimeRange",
    "variableName": "acTimeRange"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Literal",
    "name": "onlyProvisioningStatuses",
    "value": "Active"
  },
  {
    "kind": "Literal",
    "name": "onlyWithValidBatteries",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 10000
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "category": [
          "PowerController"
        ]
      }
    }
  ],
  "concreteType": "PaginatedDevices",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": "devices(filters:{\"category\":[\"PowerController\"]})"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BatterySiteListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedSites",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BatterySiteListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedSites",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d06d907b8721f125c210ebb41bb72f24",
    "id": null,
    "metadata": {},
    "name": "BatterySiteListAllQuery",
    "operationKind": "query",
    "text": "query BatterySiteListAllQuery(\n  $filters: SiteFilter\n  $acTimeRange: ACTimeRange!\n) {\n  sites(onlyProvisioningStatuses: Active, onlyWithValidBatteries: true, pageSize: 10000, filters: $filters, acReliabilityTimeRange: $acTimeRange) {\n    data {\n      devices(filters: {category: [PowerController]}) {\n        data {\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e8a4636629658944626104e218cff619";

export default node;
