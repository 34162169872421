import React, { FC } from 'react';

import { ListRow } from '@accesstel/pcm-ui';

import { Paths } from 'lib/routes';

import { GatewayOverview_connectedDevices$data } from '../__generated__/GatewayOverview_connectedDevices.graphql';
import style from './style.module.css';

interface ConnectedDeviceRowProps {
    idx: number;
    siteId: string;
    data: GatewayOverview_connectedDevices$data['devices'][number];
}

export const ConnectedDeviceRow: FC<ConnectedDeviceRowProps> = ({ idx, siteId, data }) => {
    const content = (
        <div className={style.grid_row}>
            <div className='flex flex-col justify-start items-start leading-none'>
                <div className='text-xl font-bold'>{data.name}</div>
                <div className='text-base font-medium'>{data.type.displayName}</div>
            </div>
            <div className='flex flex-col justify-start items-start leading-none'>
                <div className='text-xl font-bold'>{data.type.category}</div>
            </div>
        </div>
    );

    return (
        <ListRow
            key={`connected-device-row-${idx}`}
            label={idx.toString()}
            content={content}
            link={{
                pathname: Paths.SiteViewViewSiteDevicePage,
                params: { siteId: siteId, deviceId: data.id, page: 'overview' },
            }}
        />
    );
};
