import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { BatteryTestTypeFilter } from '../types';

export type TaskBatteryTestTypeFilterUIProps = CommonFilterProps<BatteryTestTypeFilter[]>;

export const TaskBatteryTestTypeOptions: BatteryTestTypeFilter[] = [
    { id: 'Capacity', name: 'Capacity' },
    { id: 'Custom', name: 'Custom' },
    { id: 'Quick', name: 'Quick' },
];

export const TaskBatteryTestTypeFilterUI: FC<TaskBatteryTestTypeFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <MultipleSelect
            options={TaskBatteryTestTypeOptions}
            active={current}
            title='Filter by battery test type'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
