import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';

import { ScheduleRepeatFilter } from '../types';

export type TaskScheduleRepeatFilterUIProps = CommonFilterProps<ScheduleRepeatFilter[]>;

export const TaskScheduleRepeatFilterOptions: ScheduleRepeatFilter[] = [
    { id: 'Never', name: 'Never' },
    { id: 'Monthly', name: 'Monthly' },
    { id: 'BiMonthly', name: 'BiMonthly' },
    { id: 'Quarterly', name: 'Quarterly' },
];

export const TaskScheduleRepeatFilterUI: FC<TaskScheduleRepeatFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <MultipleSelect
            options={TaskScheduleRepeatFilterOptions}
            active={current}
            title='Filter by task schedule repeat cadence'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
