import { FC, ReactNode } from 'react';
import React from 'react';

import { DeviceIcon, HighlightContext, Tooltip, highlight } from '@accesstel/pcm-ui';

import { formatDateIntl } from 'lib/dateFormatter';
import { BatteryTestCauseMap } from 'lib/textFormatters';

import { TestResult } from './types';

export interface ReportSearchResultProps {
    result: TestResult;
    context: HighlightContext;
}

export const TestSearchResult: FC<ReportSearchResultProps> = ({ result, context }) => {
    let primary: ReactNode;

    if (result.name) {
        primary = highlight(result.name, context);
    } else {
        primary = BatteryTestCauseMap[result.cause];
    }

    const date = result.commencedTime ? formatDateIntl(result.commencedTime, 'short-date') : '';

    const secondary = (
        <div className='flex flex-row gap-1 items-center leading-none'>
            <div className='w-4 h-4 text-eggplantRegular shrink-0'>
                <DeviceIcon />
            </div>
            <div className='truncate'>{highlight(result.device.name, context)}</div>
        </div>
    );

    return (
        <div>
            <div className='flex flex-row justify-between gap-2 max-w-full items-baseline'>
                <Tooltip content={primary} overflowOnly>
                    <div className='min-w-0 truncate'>{primary}</div>
                </Tooltip>
                <div className='text-xs text-mauveRegular'>{date}</div>
            </div>
            {secondary && <div className='text-xs text-eggplantLight'>{secondary}</div>}
        </div>
    );
};
