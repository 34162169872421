import React, { FC } from 'react';

import { FilterSingleSelect } from 'components/FilterSingleSelect';
import { CommonFilterProps } from 'filters/common';

import { SmartStartStatus } from '../types';

export type TaskUsingSmartStartFilterUIProps = CommonFilterProps<SmartStartStatus | null>;

export const TaskUsingSmartStartOptions: SmartStartStatus[] = [SmartStartStatus.Yes, SmartStartStatus.No];

export const TaskUsingSmartStartFilterUI: FC<TaskUsingSmartStartFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    return (
        <FilterSingleSelect
            options={TaskUsingSmartStartOptions}
            active={current ?? undefined}
            title='Filter by tasks using smart start'
            renderItem={item => item}
            onClear={onClearOrBack}
            onSubmit={item => onSubmit(item)}
            onClose={onClose}
            showBack={true}
        />
    );
};
