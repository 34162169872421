import React, { FC } from 'react';

import { DurationInput } from 'components/FilterDurationSelect/DurationInput';
import { Duration } from 'luxon';

import { TimeRangeFilter } from './common';

export interface CustomTimeRangeProps {
    value: TimeRangeFilter | null;
    onChange: (value: TimeRangeFilter | null) => void;
}

export const CustomTimeRange: FC<CustomTimeRangeProps> = ({ value, onChange }) => {
    const handleStartChange = (newValue: string) => {
        const newValueAsMinutes = Duration.fromISO(newValue).as('minutes');

        onChange({
            type: 'custom',
            range: { min: newValueAsMinutes, max: value?.range.max ?? 0 },
        });
    };

    const handleEndChange = (newValue: string) => {
        const newValueAsMinutes = Duration.fromISO(newValue).as('minutes');

        onChange({
            type: 'custom',
            range: { min: value?.range.min ?? 0, max: newValueAsMinutes },
        });
    };

    return (
        <div className='flex justify-between'>
            <DurationInput
                label='From'
                value={Duration.fromObject({ minutes: value?.range.min ?? 0 }).toISO()}
                onChange={value => handleStartChange(value)}
            />
            <DurationInput
                label='To'
                value={Duration.fromObject({ minutes: value?.range.max ?? 0 }).toISO()}
                onChange={value => handleEndChange(value)}
            />
        </div>
    );
};
