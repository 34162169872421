import React, { FC, PropsWithChildren, useState } from 'react';

import { Button } from '@accesstel/pcm-ui';

import { Form, useFormikContext } from 'formik';
import { capitalize } from 'lib/textFormatters';
import { ProvisioningStatus } from 'views/manage/device/add-edit/__generated__/EditDeviceFormQuery.graphql';

import style from './style.module.css';

export interface FormikProvisionLayoutProps {
    type: string;
    secondaryAction?: () => void;
    operation?: 'add' | 'edit' | 'view';
    provisioningStatus?: ProvisioningStatus;
}

export const FormikProvisionLayout: FC<PropsWithChildren<FormikProvisionLayoutProps>> = ({
    children,
    secondaryAction,
    type,
    operation = 'add',
    provisioningStatus,
}) => {
    const { isSubmitting } = useFormikContext();

    const [clickedButton, setClickedButton] = useState('none');

    const isView = operation === 'view';
    const isAdd = operation === 'add';

    const isDeleted = provisioningStatus === 'Deleted';

    return (
        <>
            <div>
                <h1 className={`pl-4 ${style.note}`}>*Mandatory Fields</h1>
                {isDeleted && <h1 className={`pl-4 ${style.note}`}>NOTE: You are viewing a deleted {type}</h1>}
            </div>
            <Form>
                {children}

                <div className={style.provision_grid}>
                    <div className={style.provision_buttons}>
                        {isView ? (
                            <Button
                                type='button'
                                variant='white'
                                buttonText='Close'
                                onClick={e => {
                                    e.preventDefault();
                                    secondaryAction?.();
                                }}
                                disabled={isSubmitting}
                            />
                        ) : (
                            <>
                                <Button
                                    type='submit'
                                    buttonText={`${isAdd ? 'Submit' : 'Save'} ${capitalize(type)}`}
                                    onClick={() => {
                                        setClickedButton('primary');
                                    }}
                                    processing={clickedButton === 'primary' && isSubmitting}
                                    disabled={isSubmitting || isDeleted}
                                    disabledMessage={isDeleted ? `This ${type} has already been deleted` : undefined}
                                />
                                <Button
                                    type={isAdd ? 'submit' : 'button'}
                                    buttonClasses='ml-8'
                                    variant='white'
                                    buttonText={isAdd ? 'Submit & Add Another' : 'Cancel'}
                                    onClick={() => {
                                        setClickedButton('secondary');
                                        secondaryAction?.();
                                    }}
                                    processing={clickedButton === 'secondary' && isSubmitting}
                                    disabled={isSubmitting}
                                />
                            </>
                        )}
                    </div>
                </div>
            </Form>
        </>
    );
};
