import React, { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';

import {
    CircleCheckIcon,
    CircleCrossIcon,
    CircleHelpIcon,
    CirclePlayIcon,
    CircleStopIcon,
    EyeIcon,
    FileEditIcon,
    FileIcon,
    FileMinusIcon,
    FilePlusIcon,
    HistoryIconColor,
    PowerIcon,
    HistoryItem as RawHistoryItem,
} from '@accesstel/pcm-ui';

import { captureMessage } from '@sentry/react';
import { AlertSeverity } from 'filters/alert';
import { DateTime, Duration } from 'luxon';
import {
    ACPowerEventStatus,
    HistoryContentQuery,
} from 'views/sites/view/sub-views/history/__generated__/HistoryContentQuery.graphql';

import { numberToLocaleString } from './numberFormatters';
import { Paths } from './routes';

type ActivityLog = HistoryContentQuery['response']['activityLogs']['data'][number];
type ActivityLogChanges = ActivityLog['changes'];
type ActivityLogLink = ActivityLog['link'];

/**
 * This is the terms used in the UI for the history page, which users are familiar with
 */
type HumanizedActivityLogSource = 'Insight' | 'AC Power Event' | 'Audit Log' | '';
export interface HistoryExportItem {
    source: HumanizedActivityLogSource;
    type: string;
    timestamp: string;
    user: {
        id: string;
        name: string;
    };
    action: string;
    device: {
        id: string;
        name: string;
    };
}

export interface HistoryItem extends RawHistoryItem {
    actionMessage: string;
}

export const humanizeActivityLogSource = (source: ActivityLog['source']): HumanizedActivityLogSource => {
    switch (source) {
        case 'Alert':
            return 'Insight';
        case 'GridEvent':
            return 'AC Power Event';
        case 'AuditLog':
            return 'Audit Log';
        default:
            captureMessage(`Unknown ActivityLog Source: ${source}`, scope => {
                scope.setTag('ActivityLog.Source', source);
                scope.setTag('Component', 'HistoryContent');
                scope.setTag('Function', 'humanizeActivityLogSource');
                return scope;
            });
            throw new Error(`Unknown ActivityLog Source: ${source}`);
    }
};

export const createHistoryItem = (log: ActivityLog, hasAssetsRead: boolean, hasTasksRead: boolean): HistoryItem => {
    let activityLogDevice: { id: string; name: string } | undefined = undefined;
    let user: { username: string; name?: string } = {
        username: log.user?.username ?? 'accata',
        name: log.user?.name ?? 'accata',
    };
    let link: string | undefined = undefined;
    switch (log.link?.__typename) {
        case 'Device':
            activityLogDevice = {
                id: log.link.id,
                name: log.link.name,
            };
            user = { username: log.user?.username ?? 'accata', name: log.user?.name ?? undefined };
            link = hasAssetsRead ? generatePath(Paths.EditDevice, { id: log.link.id }) : undefined;
            break;
        case 'Alert':
            activityLogDevice = {
                id: log.link.device.id,
                name: log.link.device.name,
            };
            user = { username: 'accata', name: 'accata' };
            link = generatePath(Paths.ReportAlerts); // TODO: Apply filter for alert only for this device
            break;
        case 'ACPowerEvent':
            user = { username: 'accata', name: 'accata' };
            activityLogDevice = {
                id: log.link.device.id,
                name: log.link.device.name,
            };
            break;
        case 'DeviceBatteryTestResults': {
            if (log.link.task?.testState === 'Aborted' && log.link.task.abortedUser?.name) {
                user = { username: log.link.task.abortedUser.username, name: log.link.task.abortedUser.name };
            }
            activityLogDevice = {
                id: log.link.device.id,
                name: log.link.device.name,
            };
            link =
                log.link.task?.id && hasTasksRead
                    ? generatePath(Paths.ViewTaskDetails, { id: log.link.task.id })
                    : undefined;
            break;
        }
    }

    const [actionMessageNode, actionMessageString] = getActionForHistory(log);
    const rawMessageWithParty = user.name ? `${user.name} ${actionMessageString}` : actionMessageString;

    return {
        timestamp: DateTime.fromISO(log.timestamp).toJSDate(),
        icon: resolveIconForHistory(log),
        user,
        rawMessage: rawMessageWithParty,
        message: actionMessageNode,
        actionMessage: actionMessageString,
        link,
        device: activityLogDevice,
        iconColor: resolveIconColorForHistory(log),
    };
};

function resolveIconForHistory(log: ActivityLog): ReactNode {
    switch (log.source) {
        case 'Alert':
            return <EyeIcon />;
        case 'GridEvent':
            return <PowerIcon />;
        case 'AuditLog': {
            if (log.type === 'DeviceAdd') {
                return <FilePlusIcon />;
            } else if (log.type === 'DeviceEdit') {
                return <FileEditIcon />;
            } else if (log.type === 'DeviceRemove') {
                return <FileMinusIcon />;
            } else if (log.type === 'BatteryTest') {
                return resolveIconForBatteryTest(log.link);
            } else {
                return <FileIcon />;
            }
        }
        default:
            captureMessage(`Unknown ActivityLog Source: ${log.source}`, scope => {
                scope.setTag('ActivityLog.Source', log.source);
                scope.setTag('Component', 'HistoryContent');
                scope.setTag('Function', 'resolveIconForHistory');
                return scope;
            });
            return <CircleHelpIcon />;
    }
}

function getActionForHistory(log: ActivityLog): [ReactNode, string] {
    switch (log.source) {
        case 'Alert': {
            const fallbackText = 'raised an insight';
            const alert = log.link?.__typename === 'Alert' ? log.link : undefined;
            const message = alert ? (
                <div>
                    detected {emphasizeText(alert.category, alert.isActive ? undefined : HistoryIconColor.Eggplant)}{' '}
                    insight. {alert.message}
                </div>
            ) : (
                <div>fallbackText</div>
            );
            const rawMessage = alert ? `detected ${alert.category} insight. ${alert.message}` : fallbackText;
            return [message, rawMessage];
        }
        case 'GridEvent': {
            const fallbackText = 'detected a grid event';
            const gridEvent = log.link?.__typename === 'ACPowerEvent' ? log.link : undefined;
            const count = gridEvent?.affectedFeeds.map(feed => feed.status === gridEvent.worstStatus).length;
            const message =
                gridEvent && count ? (
                    <div>
                        detected a {emphasizeText(outageText(gridEvent.worstStatus))} on {count}{' '}
                        {count === 1 ? 'feed' : 'feeds'}, lasted for{' '}
                        {numberToLocaleString(Duration.fromISO(gridEvent.duration).as('minutes'), 0)} minutes
                    </div>
                ) : (
                    <div>{fallbackText}</div>
                );
            const rawMessage =
                gridEvent && count
                    ? `detected a ${outageText(gridEvent.worstStatus)} on ${count} ${
                          count === 1 ? 'feed' : 'feeds'
                      }, lasted for ${numberToLocaleString(
                          Duration.fromISO(gridEvent.duration).as('minutes'),
                          0
                      )} minute(s)`
                    : fallbackText;
            return [<>{message}</>, rawMessage];
        }
        case 'AuditLog': {
            if (log.type === 'DeviceAdd') {
                const message = 'added this device';
                return [<>{message}</>, message];
            } else if (log.type === 'DeviceEdit') {
                const changes = log.changes ?? [];
                const [nodeMessage, message] = deviceChangesToText(changes);
                return [nodeMessage, message];
            } else if (log.type === 'DeviceRemove') {
                const message = 'removed this device';
                return [<>{message}</>, message];
            } else if (log.type === 'BatteryTest') {
                const [nodeMessage, message] = batteryTestToText(log.link);
                return [nodeMessage, message];
            }

            captureMessage(`Unhandled ActivityLog Type: ${log.type}`, scope => {
                scope.setTag('ActivityLog.Type', log.type);
                scope.setTag('Component', 'HistoryContent');
                scope.setTag('Function', 'getActionForHistory');
                return scope;
            });
            const message = `detected ${log.type} event`;
            return [<>{message}</>, message];
        }
        default: {
            captureMessage(`Unhandled ActivityLog Source: ${log.source}`, scope => {
                scope.setTag('ActivityLog.Source', log.source);
                scope.setTag('Component', 'HistoryContent');
                scope.setTag('Function', 'getActionForHistory');
                return scope;
            });
            const message = `detected ${log.type} event`;
            return [<>{message}</>, message];
        }
    }
}

function batteryTestToText(link: ActivityLogLink): [ReactNode, string] {
    if (link?.__typename === 'DeviceBatteryTestResults') {
        const type = link.task?.type;
        let result: string | undefined = undefined;
        let action = 'initiated';
        let textColor = HistoryIconColor.Eggplant;
        if (link.task?.testState === 'Aborted') {
            action = 'aborted';
        } else if (link.state === 'Failed') {
            result = link.state;
            textColor = HistoryIconColor.Coral;
        } else if (link.state === 'Passed') {
            result = link.state;
            textColor = HistoryIconColor.Pine;
        } else if (link.state === 'Inconclusive') {
            result = link.state;
        }

        const message = `${action} a ${type ? type.toLowerCase() : ''} test ${
            result ? `that finished with a ${result.toUpperCase()} result` : ``
        }`;
        const nodeMessage = (
            <div>
                {action} a {type ? type.toLowerCase() : ''} test {result ? 'that finished with a ' : ``}
                {result ? emphasizeText(result.toUpperCase(), textColor) : ``}
                {result ? ` result` : ``}
            </div>
        );
        return [nodeMessage, message];
    } else {
        const message = 'initiated a test';
        return [<>{message}</>, message];
    }
}

function deviceChangesToText(rawChanges: ActivityLogChanges): [ReactNode, string] {
    let message = 'edited this device';
    let nodeMessage: ReactNode = <div>{message}</div>;

    const changes = rawChanges ?? [];
    if (changes.length === 0) {
        message = 'edited this device';
    } else if (changes.length === 1) {
        const change = changes[0];
        if (change.oldValue && change.newValue) {
            message = `changed the value of ${change.field} from ${change.oldValue} to ${change.newValue}`;
            nodeMessage = (
                <div>
                    changed the value of {emphasizeText(change.field, HistoryIconColor.Eggplant)} from{' '}
                    {emphasizeText(change.oldValue, HistoryIconColor.Eggplant)} to{' '}
                    {emphasizeText(change.newValue, HistoryIconColor.Eggplant)}
                </div>
            );
        } else if (change.oldValue) {
            message = `removed a value of ${change.oldValue} from ${change.field}`;
            nodeMessage = (
                <div>
                    removed a value of {emphasizeText(change.oldValue, HistoryIconColor.Eggplant)} from{' '}
                    {emphasizeText(change.field, HistoryIconColor.Eggplant)}
                </div>
            );
        } else if (change.newValue) {
            message = `added a value of ${change.newValue} to ${change.field}`;
            nodeMessage = (
                <div>
                    added a value of {emphasizeText(change.newValue, HistoryIconColor.Eggplant)} to{' '}
                    {emphasizeText(change.field, HistoryIconColor.Eggplant)}
                </div>
            );
        } else {
            message = `edited ${change.field} field`;
            nodeMessage = <div>edited {emphasizeText(change.field, HistoryIconColor.Eggplant)} field</div>;
        }
    } else if (changes.length <= 4) {
        const last = changes[changes.length - 1];
        const allExceptLast = changes.slice(0, changes.length - 1);
        message = `edited ${allExceptLast.map(change => change.field).join(', ')}${
            last ? ` and ${last.field}` : ''
        } fields`;
        nodeMessage = (
            <div>
                edited {emphasizeText(allExceptLast.map(change => change.field).join(', '), HistoryIconColor.Eggplant)}
                {last ? ` and ` : ''}
                {last ? emphasizeText(last.field, HistoryIconColor.Eggplant) : ''} fields
            </div>
        );
    } else {
        message = `edited ${changes.length} fields for this device`;
        nodeMessage = (
            <div>edited {emphasizeText(`${changes.length}`, HistoryIconColor.Eggplant)} fields for this device</div>
        );
    }

    return [nodeMessage, message];
}

function outageText(status: ACPowerEventStatus): string {
    let text = '';
    if (status === 'Outage') {
        text = status;
    } else if (status === 'OverVoltage') {
        text = 'Overvoltage';
    } else if (status === 'UnderVoltage') {
        text = 'Undervoltage';
    } else {
        captureMessage(`Unhandled GridEvent Status: ${status}`, scope => {
            scope.setTag('GridEvent.Status', status);
            scope.setTag('Component', 'HistoryContent');
            scope.setTag('Function', 'outageText');
            return scope;
        });
        return 'grid event';
    }
    return text;
}

function emphasizeText(text: string, color = HistoryIconColor.Coral): ReactNode {
    let cssColor;
    switch (color) {
        case HistoryIconColor.Coral:
            cssColor = 'text-coralRegular';
            break;
        case HistoryIconColor.Pine:
            cssColor = 'text-pineRegular';
            break;
        case HistoryIconColor.Eggplant:
            cssColor = 'text-eggplantRegular';
            break;
        default:
            cssColor = 'text-coralRegular';
            break;
    }

    return <span className={`${cssColor} font-bold`}>{text}</span>;
}

function resolveIconColorForHistory(log: ActivityLog): HistoryIconColor | undefined {
    let color: HistoryIconColor | undefined = undefined;
    switch (log.source) {
        case 'AuditLog':
            if (log.link?.__typename === 'DeviceBatteryTestResults') {
                if (log.link.state === 'Failed') {
                    color = HistoryIconColor.Coral;
                } else if (log.link.state === 'Passed') {
                    color = HistoryIconColor.Pine;
                }
            }
            break;
        case 'Alert':
            color =
                log.link?.__typename === 'Alert'
                    ? resolveIconColorForInsight(log.link.severity as AlertSeverity, log.link.isActive)
                    : undefined;
            break;
        case 'GridEvent':
            color =
                log.link?.__typename === 'ACPowerEvent'
                    ? resolveIconColorForGridEvent(log.link.worstStatus)
                    : undefined;
    }

    return color;
}

function resolveIconColorForInsight(severity: AlertSeverity, isActive: boolean): HistoryIconColor | undefined {
    if (!isActive) {
        return HistoryIconColor.Eggplant;
    }

    let color: HistoryIconColor | undefined = undefined;
    if (severity === 'Critical' || severity === 'Major') {
        color = HistoryIconColor.Coral;
    } else {
        color = HistoryIconColor.Eggplant;
    }

    return color;
}

function resolveIconColorForGridEvent(status: ACPowerEventStatus): HistoryIconColor | undefined {
    let color: HistoryIconColor | undefined = undefined;
    if (status === 'Outage') {
        color = HistoryIconColor.Coral;
    } else {
        color = HistoryIconColor.Eggplant;
    }

    return color;
}

function resolveIconForBatteryTest(link: ActivityLogLink): ReactNode {
    const insufficientDataIcon = <CircleHelpIcon />;

    if (!link) {
        // old implementation - audit log did not store proper metadata, unable to find link
        return insufficientDataIcon;
    }

    if (link.__typename === 'DeviceBatteryTestResults') {
        if (link.task?.testState === 'Aborted') {
            return <CircleStopIcon />;
        } else if (link.state === 'InProgress') {
            return <CirclePlayIcon />;
        } else if (link.state === 'Inconclusive') {
            return <CircleHelpIcon />;
        } else if (link.state === 'Failed') {
            return <CircleCrossIcon />;
        } else if (link.state === 'Passed') {
            return <CircleCheckIcon />;
        }
    }

    return insufficientDataIcon;
}
