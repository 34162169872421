import React, { FC, useEffect } from 'react';
import { generatePath, useLocation } from 'react-router-dom';

import {
    BatteryChargingIcon,
    ClockIcon,
    EyeIcon,
    GeneratorIcon,
    HomeIcon,
    LoadIcon,
    PowerIcon,
    RectifierIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';
import { SiteViewNavigationItem } from '@accesstel/pcm-ui/dist/navigation/SiteViewNavigation/types';
import { DeviceCategory } from '@accesstel/pcm-ui/dist/navigation/SiteViewNavigation/types';

import { Paths } from 'lib/routes';

import { useSiteDevices } from './context';

interface NavigateToFirstDeviceProps {
    page: string;
}

export const NavigateToFirstDevice: FC<NavigateToFirstDeviceProps> = ({ page }) => {
    const devices = useSiteDevices();
    const navigate = useExtendedNavigate();
    const location = useLocation();

    useEffect(() => {
        if (devices.length === 0) {
            return;
        }

        navigate(generatePath(Paths.SiteViewRelativeDevicePage, { deviceId: devices[0].device.id, page }), {
            replace: true,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices, location.pathname]);

    return null;
};

const AllSupportedCategories: DeviceCategory[] = ['Generator', 'PowerController', 'Gateway'];

export const GeneratorPageDeviceCategories: DeviceCategory[] = ['Generator'];
export const RectifierPageDeviceCategories: DeviceCategory[] = ['PowerController'];
export const BatteryPageDeviceCategories: DeviceCategory[] = ['PowerController'];
export const LoadPageDeviceCategories: DeviceCategory[] = ['PowerController'];

/**
 * Retrieves the navigation options for the site view.
 * Update this to add new pages to the site view.
 */
export function getNavigationOptions(deviceId: string, onOverviewPage: boolean): SiteViewNavigationItem[] {
    return [
        {
            icon: <HomeIcon />,
            title: 'Overview',
            to: onOverviewPage ? 'overview' : `${deviceId}/overview`,
            limitDeviceCategories: AllSupportedCategories,
            showSiteTab: true,
        },
        {
            icon: <GeneratorIcon />,
            title: 'Generators',
            to: deviceId ? `${deviceId}/generators` : 'generators',
            limitDeviceCategories: GeneratorPageDeviceCategories,
        },
        {
            icon: <PowerIcon />,
            title: 'AC Power',
            to: deviceId ? `${deviceId}/ac-power` : 'ac-power',
            limitDeviceCategories: ['Generator', 'PowerController'],
        },
        {
            icon: <RectifierIcon />,
            title: 'Rectifiers',
            to: deviceId ? `${deviceId}/rectifiers` : 'rectifiers',
            limitDeviceCategories: RectifierPageDeviceCategories,
        },
        {
            icon: <BatteryChargingIcon />,
            title: 'Batteries',
            to: deviceId ? `${deviceId}/batteries` : 'batteries',
            limitDeviceCategories: BatteryPageDeviceCategories,
        },
        {
            icon: <LoadIcon />,
            title: 'Load',
            to: deviceId ? `${deviceId}/load` : 'load',
            limitDeviceCategories: LoadPageDeviceCategories,
        },
        {
            icon: <EyeIcon />,
            title: 'Insights',
            to: deviceId ? `${deviceId}/insights` : 'insights',
            limitDeviceCategories: ['Generator', 'PowerController'],
        },
        {
            icon: <ClockIcon />,
            title: 'History',
            to: deviceId ? `${deviceId}/history` : 'history',
            limitDeviceCategories: AllSupportedCategories,
        },
    ];
}
