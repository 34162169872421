import React, { FC } from 'react';

import { SkeletonText } from '@accesstel/pcm-ui';

export const TestParameterViewSkeleton: FC = () => {
    return (
        <>
            <div className='grid grid-cols-2 pt-4'>
                <SkeletonText characters={40} className='font-light text-base' />
                <SkeletonText characters={40} className='font-light text-base' />
                <SkeletonText characters={40} className='font-light text-base' />
                <SkeletonText characters={40} className='font-light text-base' />
                <SkeletonText characters={40} className='font-light text-base' />
                <SkeletonText characters={40} className='font-light text-base' />
            </div>

            <SkeletonText characters={10} className='font-light text-sm' />
        </>
    );
};
