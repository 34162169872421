import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { TimeRangeFilter } from 'components/FilterTimeRangeSelect/common';

import { AttributeFilter, RangeFilter } from './types';

export function encodeRangeFilter(range: RangeFilter): string {
    return encodeURIComponent(`${range.min}-${range.max}`);
}

/**
 * NOTE: This will only preserve the range part of the filter
 */
export function encodeTimeRangeFilter(timeRange: TimeRangeFilter): string {
    // TODO: Preserve the preset part of the filter (if it is of type 'predefined)
    return encodeRangeFilter(timeRange.range);
}

/**
 * NOTE: This will only preserve the range part of the filter
 */
export function encodeDateRangeFilter(dateRange: DateRangeFilter): string {
    const separator = '_';

    const dateFrom = dateRange.range[0].toISOString();
    const dateTo = dateRange.range[1].toISOString();

    return encodeURIComponent(`${dateFrom}${separator}${dateTo}`);
}

export function encodeCustomAttributeFilter(attribute: AttributeFilter): string {
    return encodeURIComponent(attribute.value);
}
