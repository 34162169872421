/**
 * @generated SignedSource<<643a95f66bd2ccb5a9b49fa8598a5e9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ACDeviceFeedReliability_acPower$data = {
  readonly feedEventMetrics: {
    readonly feeds: ReadonlyArray<{
      readonly label: string;
      readonly " $fragmentSpreads": FragmentRefs<"DeviceFeedCard">;
    }>;
  };
  readonly " $fragmentType": "ACDeviceFeedReliability_acPower";
};
export type ACDeviceFeedReliability_acPower$key = {
  readonly " $data"?: ACDeviceFeedReliability_acPower$data;
  readonly " $fragmentSpreads": FragmentRefs<"ACDeviceFeedReliability_acPower">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "begin"
    },
    {
      "kind": "RootArgument",
      "name": "end"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ACDeviceFeedReliability_acPower",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "begin",
          "variableName": "begin"
        },
        {
          "kind": "Variable",
          "name": "end",
          "variableName": "end"
        }
      ],
      "concreteType": "DeviceFeedsACEventMetrics",
      "kind": "LinkedField",
      "name": "feedEventMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceFeedACEventMetrics",
          "kind": "LinkedField",
          "name": "feeds",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DeviceFeedCard"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceACPower",
  "abstractKey": null
};

(node as any).hash = "2838a4125032fee16791539476f57b0c";

export default node;
