import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { CustomDurationRangeFilter, PredefinedDurationRangeFilter } from 'components/FilterDurationSelect/common';
import { ACPowerEventStatus } from 'views/reports/ac-power/device-incident-list/__generated__/ACPowerDeviceIncidentListTableQuery.graphql';

// IMPORTANT: Value has to be unique and cannot have spaces
export enum IncidentTableColumn {
    DeviceName = 'DeviceName',
    IncidentType = 'WorstStatus',
    IncidentDuration = 'Duration',
    AffectedFeeds = 'AffectedFeeds',
    IncidentStartTime = 'StartTime',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum IncidentExtraFilters {
    IsSignificant = 'IsSignificant',
    ActiveWithin = 'ActiveWithin',
}
export interface IncidentDeviceNameFilter {
    name: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    id?: string;
    siteName?: string;
    state?: string;
}

export interface IncidentTypeFilter {
    name: string;
    id: ACPowerEventStatus;
}

export interface AffectedFeedFilter {
    id: number;
    label: string;
}

export type IncidentDateTimeFilter = DateRangeFilter;
export type IncidentDurationFilter = PredefinedDurationRangeFilter | CustomDurationRangeFilter;

export interface IncidentIsSignificantOnlyFilter {
    label: string;
    value: boolean;
}
