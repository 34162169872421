import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { CustomDurationRangeFilter, PredefinedDurationRangeFilter } from 'components/FilterDurationSelect/common';
import { RangeFilter } from 'filters/common';
import { DeviceNameFilter } from 'filters/device';
import {
    BatteryTestFailReason,
    BatteryTestReason,
    BatteryTestState,
} from 'views/tasks/battery-health/test-result-list/__generated__/TestResultListQuery.graphql';

// IMPORTANT: Value has to be unique and cannot have spaces
export enum TestResultTableColumn {
    DeviceName = 'DeviceName',
    State = 'State',
    RunTime = 'RunTime',
    StartTime = 'StartTime',
    Cause = 'Cause',
    FailReason = 'FailReason',
    BatteryStringCount = 'BatteryStringCount',
    CurrentMetrics = 'AverageCurrent',
    VoltageMetrics = 'EndVoltage',
    TemperatureMetrics = 'AverageTemperature',
    DischargedMetrics = 'TotalDischarged',
    EstimatedStateOfHealth = 'EstimatedStateOfHealth',
    EstimatedCapacity = 'EstimatedCapacity',
    EstimatedReserveTime = 'EstimatedReserveTime',
    Name = 'Name',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum TestResultExtraFilters {}

export interface TestResultStateFilter {
    id: BatteryTestState;
    name: string;
}

export interface TestResultFailReasonFilter {
    id: BatteryTestFailReason;
    name: string;
}

export interface TestResultCauseFilter {
    id: BatteryTestReason;
    name: string;
}

export type TestResultDeviceNameFilter = DeviceNameFilter;
export type TestResultBatteryStringCountFilter = RangeFilter;
export type TestResultStartTimeFilter = DateRangeFilter;
export type TestResultDurationFilter = PredefinedDurationRangeFilter | CustomDurationRangeFilter;
export type TestResultMetricsFilter = RangeFilter;
export type TestResultEstimatedStateOfHealthFilter = RangeFilter;
export type TestResultEstimatedCapacityFilter = RangeFilter;
export type TestResultEstimatedReserveTimeFilter = PredefinedDurationRangeFilter | CustomDurationRangeFilter;
