/**
 * @generated SignedSource<<6824c43c2c933040761832fc1f47f370>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type FirmwareStatus = "Failed" | "NewVersion" | "NotSet" | "OldVersion" | "SameVersion" | "Unknown" | "%future added value";
export type FirmwareTableQuery$variables = {
  page?: number | null;
  search: string;
};
export type FirmwareTableQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly address: {
        readonly state: string;
      };
      readonly devices: {
        readonly data: ReadonlyArray<{
          readonly firmware: {
            readonly name: string;
          } | null;
          readonly firmwareStatus: FirmwareStatus;
          readonly id: string;
          readonly lastUpdate: string | null;
          readonly name: string;
        }>;
      };
      readonly firmware: {
        readonly commonVersion: {
          readonly name: string;
        } | null;
        readonly multipleVersions: boolean;
        readonly status: FirmwareStatus;
      };
      readonly id: string;
      readonly name: string;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly total: number;
    };
  };
};
export type FirmwareTableQuery = {
  response: FirmwareTableQuery$data;
  variables: FirmwareTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "onlyProvisioningStatuses",
    "value": "Active"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "dir": "Asc",
      "field": "Name"
    }
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrevious",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "multipleVersions",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firmwareStatus",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdate",
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FirmwareTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedSites",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteFirmwareStatus",
                "kind": "LinkedField",
                "name": "firmware",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FirmwareVersion",
                    "kind": "LinkedField",
                    "name": "commonVersion",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PaginatedDevices",
                "kind": "LinkedField",
                "name": "devices",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Device",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v3/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FirmwareVersion",
                        "kind": "LinkedField",
                        "name": "firmware",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FirmwareTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedSites",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteFirmwareStatus",
                "kind": "LinkedField",
                "name": "firmware",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FirmwareVersion",
                    "kind": "LinkedField",
                    "name": "commonVersion",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PaginatedDevices",
                "kind": "LinkedField",
                "name": "devices",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Device",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v3/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FirmwareVersion",
                        "kind": "LinkedField",
                        "name": "firmware",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df6a1b8956b2e6f514ca4ee6154eb2b6",
    "id": null,
    "metadata": {},
    "name": "FirmwareTableQuery",
    "operationKind": "query",
    "text": "query FirmwareTableQuery(\n  $page: Int = 1\n  $search: String!\n) {\n  sites(page: $page, search: $search, orderBy: {field: Name, dir: Asc}, onlyProvisioningStatuses: Active) {\n    pageInfo {\n      page\n      total\n      hasNext\n      hasPrevious\n    }\n    data {\n      firmware {\n        commonVersion {\n          name\n          id\n        }\n        multipleVersions\n        status\n      }\n      name\n      address {\n        state\n      }\n      id\n      devices {\n        data {\n          id\n          name\n          firmwareStatus\n          lastUpdate\n          firmware {\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2cd678e8ce1a801b8e43493e572594db";

export default node;
