import React, { Dispatch, FC } from 'react';

import { FormArea, FormikTextField } from '@accesstel/pcm-ui';

import { isFeatureEnabled } from 'lib/featureFlag';
import { Attributes } from 'views/manage/common/sub-forms/Attributes';

import manageStyle from '../../../style.module.css';
import { DeviceTypeSelector, SiteSelector, TargetFirmwareSelector } from '../components';
import { FormControlAction, FormControlParameters } from '../lib/reducer';

interface GeneralInformationProps {
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const GeneralInformation: FC<GeneralInformationProps> = ({ formControlState, formControlDispatch }) => {
    return (
        <FormArea label='General Information' blurb='This is some general information that relates to the device.'>
            <div className={manageStyle.provisioning_content_grid}>
                <FormikTextField
                    name='name'
                    placeHolder='Device Name*'
                    required
                    light
                    autoFocus
                    variant='outlined'
                    testId='device-name'
                />

                <DeviceTypeSelector name='type' />

                {isFeatureEnabled('firmware') && (
                    <div className='col-start-1'>
                        <TargetFirmwareSelector name='firmwareVersion' />
                    </div>
                )}

                <SiteSelector formControlState={formControlState} formControlDispatch={formControlDispatch} />

                <div className={manageStyle.custom_attributes}>
                    <Attributes type='Device' />
                </div>
            </div>
        </FormArea>
    );
};
