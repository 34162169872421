/**
 * @generated SignedSource<<79cae452d9842bc53a5e3af2232cd5cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimezoneSettingsFragment$data = {
  readonly timezone: string | null;
  readonly " $fragmentType": "TimezoneSettingsFragment";
};
export type TimezoneSettingsFragment$key = {
  readonly " $data"?: TimezoneSettingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimezoneSettingsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimezoneSettingsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    }
  ],
  "type": "UserPreferences",
  "abstractKey": null
};

(node as any).hash = "a066e576cefe6ff02fc0063b4c8c0077";

export default node;
