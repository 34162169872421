import React from 'react';

import { BarDataType, MetricDisplay, StackedHorizontalBar, useExtendedNavigate } from '@accesstel/pcm-ui';

import { useCurrentUserUnitsPref } from 'lib/auth';
import { round } from 'lib/number';
import { numberToLocaleString } from 'lib/numberFormatters';
import { formatValueWithUnit } from 'lib/units';
import { makeLinkToMetric } from 'views/explore/lib/link';
import { Operation } from 'views/explore/types';

interface GeneratorFuelSectionProps {
    deviceId: string;
    capacity: number | null;
    remaining: number | null;
    consumption: number | null;
}

export const GeneratorFuelSection: React.FC<GeneratorFuelSectionProps> = ({
    capacity,
    remaining,
    consumption,
    deviceId,
}) => {
    const navigate = useExtendedNavigate();
    const unitPreferences = useCurrentUserUnitsPref();

    const used = capacity !== null && remaining !== null ? capacity - remaining : null;

    const fuelMetricsCapacity = formatValueWithUnit(numberToLocaleString(capacity, 0), unitPreferences.volume);
    const fuelMetricsUsed = formatValueWithUnit(numberToLocaleString(used, 0), unitPreferences.volume);
    const fuelMetricsRemaining = formatValueWithUnit(numberToLocaleString(remaining, 0), unitPreferences.volume);
    const fuelMetricsConsumption = consumption !== null ? `${numberToLocaleString(consumption, 1)}L/h` : '-L/h';

    const barData: BarDataType[] =
        used !== null && capacity !== null
            ? [
                  { label: 'Remaining', value: round(capacity - used, 2), bgClass: 'bg-eggplantRegular' },
                  { label: 'Used', value: round(used, 2), bgClass: 'bg-coralRegular' },
              ]
            : [];

    const navigateToMetrics = (metric: string) => {
        const to = makeLinkToMetric(deviceId, { metric, op: Operation.Average });
        navigate(to);
    };

    return (
        <div>
            <div className='font-bold text-xl'>Fuel</div>
            <div className='py-4'>
                <StackedHorizontalBar
                    data={barData}
                    emptyLabel='No data available'
                    sort={false}
                    valueFormatter={value => formatValueWithUnit(numberToLocaleString(value), unitPreferences.volume)}
                />
            </div>

            <div className='flex flex-row space-x-4'>
                <MetricDisplay
                    size='small'
                    label='Capacity'
                    value={fuelMetricsCapacity}
                    valueOnClick={() => navigateToMetrics('FuelTankCapacity')}
                />
                <MetricDisplay
                    size='small'
                    label='Remaining'
                    value={fuelMetricsRemaining}
                    valueOnClick={() => navigateToMetrics('FuelTankAmount')}
                />
                <MetricDisplay size='small' label='Used' value={fuelMetricsUsed} />
                <MetricDisplay
                    size='small'
                    label='Consumption'
                    value={fuelMetricsConsumption}
                    valueOnClick={() => navigateToMetrics('GeneratorFuelConsumption')}
                />
            </div>
        </div>
    );
};
