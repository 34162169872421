/**
 * @generated SignedSource<<85388ecb1606a5da72918605f1daf955>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardQuery$variables = Record<PropertyKey, never>;
export type DashboardQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActiveDevices" | "BatteryReportsSection" | "OverallPerformanceSection" | "PowerControllersSection">;
};
export type DashboardQuery = {
  response: DashboardQuery$data;
  variables: DashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ActiveDevices"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "OverallPerformanceSection"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PowerControllersSection"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BatteryReportsSection"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GlobalDeviceHealth",
        "kind": "LinkedField",
        "name": "globalDeviceHealth",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GlobalBatteryMetrics",
        "kind": "LinkedField",
        "name": "batteryMetrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalBatteryHealth",
            "kind": "LinkedField",
            "name": "overallHealth",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BatteryStatusBreakdown",
            "kind": "LinkedField",
            "name": "statusBreakdown",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CapacityBreakdown",
            "kind": "LinkedField",
            "name": "remainingCapacityBreakdown",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeLow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeHigh",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GlobalFirmwareStatus",
        "kind": "LinkedField",
        "name": "globalFirmwareStatus",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b26e75f4f1e17d9a6321639f1049a10",
    "id": null,
    "metadata": {},
    "name": "DashboardQuery",
    "operationKind": "query",
    "text": "query DashboardQuery {\n  ...ActiveDevices\n  ...OverallPerformanceSection\n  ...PowerControllersSection\n  ...BatteryReportsSection\n}\n\nfragment ActiveDevices on Query {\n  devices {\n    total\n  }\n}\n\nfragment BatteriesGauge on Query {\n  devices {\n    total\n  }\n  batteryMetrics {\n    overallHealth {\n      status\n      count\n    }\n  }\n}\n\nfragment BatteryReportsSection on Query {\n  ...CurrentStatusDoughnut\n  ...CurrentCapacityDoughnut\n}\n\nfragment CurrentCapacityDoughnut on Query {\n  batteryMetrics {\n    remainingCapacityBreakdown {\n      rangeLow\n      rangeHigh\n      deviceCount\n    }\n  }\n}\n\nfragment CurrentStatusDoughnut on Query {\n  batteryMetrics {\n    statusBreakdown {\n      status\n      deviceCount\n    }\n  }\n}\n\nfragment FirmwareGauge on Query {\n  devices {\n    total\n  }\n  globalFirmwareStatus {\n    name\n    count\n  }\n}\n\nfragment OverallPerformanceSection on Query {\n  ...PowerControllersGauge\n  ...BatteriesGauge\n  ...FirmwareGauge\n}\n\nfragment PowerControllersGauge on Query {\n  devices {\n    total\n  }\n  globalDeviceHealth {\n    status\n    count\n  }\n}\n\nfragment PowerControllersHorizontalBar on Query {\n  devices {\n    total\n  }\n  globalDeviceHealth {\n    status\n    count\n  }\n}\n\nfragment PowerControllersSection on Query {\n  ...PowerControllersHorizontalBar\n}\n"
  }
};
})();

(node as any).hash = "99ced0b7184e814a16cda2e788d93eab";

export default node;
