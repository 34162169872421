import React, { FC, ReactNode } from 'react';

import classNamesBind from 'classnames/bind';

import style from './style.module.css';

const classNames = classNamesBind.bind(style);

export interface BorderedSectionProps {
    icon: ReactNode;
    title: string;
    children: ReactNode;
    className?: string;
    action?: ReactNode;
}

export const BorderedSection: FC<BorderedSectionProps> = ({ icon, title, children, className, action }) => {
    return (
        <div className={classNames('section', className)}>
            <div className={classNames('section_icon')}>{icon}</div>
            <div className={classNames('section_panel')}>
                <div>
                    <span className={classNames('section_title')}>{title}</span>
                    {action && <span className={classNames('section_action')}>{action}</span>}
                </div>
                <div className={classNames('section_list')}>{children}</div>
            </div>
        </div>
    );
};
