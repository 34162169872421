import React, { useMemo } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'lib/query-helpers';
import { useTimeRange } from 'views/reports/common';

import { CommonPropsWithTime } from '../common';
import { ReportLineChart } from './ReportLineChart';
import { CurrentChartBlocQuery, CurrentChartBlocQuery$variables } from './__generated__/CurrentChartBlocQuery.graphql';
import {
    CurrentChartStandardQuery,
    CurrentChartStandardQuery$variables,
} from './__generated__/CurrentChartStandardQuery.graphql';
import { ReportLineChart_data$key } from './__generated__/ReportLineChart_data.graphql';

const StandardQuery = graphql`
    query CurrentChartStandardQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
        device(id: $id) {
            battery {
                data: metrics {
                    current(unit: Amps, begin: $begin, end: $end, interval: $interval) {
                        ...ReportLineChart_data
                    }
                }
            }
        }
    }
`;
const BlocQuery = graphql`
    query CurrentChartBlocQuery(
        $id: ID!
        $begin: Timestamp!
        $end: Timestamp!
        $interval: Duration!
        $string: Int!
        $bloc: Int!
    ) {
        device(id: $id) {
            battery {
                bloc(string: $string, bloc: $bloc) {
                    data: metrics {
                        current(unit: Amps, begin: $begin, end: $end, interval: $interval) {
                            ...ReportLineChart_data
                        }
                    }
                }
            }
        }
    }
`;

export function CurrentChart({
    device,
    timeRange: defaultTimeRange,
    bloc,
    short,
    coarseTicks,
}: CommonPropsWithTime): JSX.Element {
    const [timeRange, menuItems] = useTimeRange(defaultTimeRange);

    const queryArgs = useMemo<CurrentChartStandardQuery$variables | CurrentChartBlocQuery$variables>(() => {
        const range = timeRange.getRange();
        let batteryString: number | undefined;
        let batteryBloc: number | undefined;
        if (bloc) {
            batteryString = bloc[0];
            batteryBloc = bloc[1];
        }
        return {
            id: device,
            begin: range[0].toISO(),
            end: range[1].toISO(),
            interval: timeRange.interval.toISO(),
            string: batteryString,
            bloc: batteryBloc,
        };
    }, [device, timeRange, bloc]);

    const { data: result, error } = useQuery<CurrentChartStandardQuery | CurrentChartBlocQuery>(
        bloc ? BlocQuery : StandardQuery,
        queryArgs
    );

    let data: ReportLineChart_data$key | null;
    if (result?.device) {
        if ('data' in result.device.battery) {
            data = result.device.battery.data.current;
        } else {
            data = result.device.battery.bloc?.data.current ?? null;
        }
    } else {
        data = null;
    }

    return (
        <ReportLineChart
            title='Battery current'
            unit='A'
            data={data}
            error={!!error}
            timeRange={timeRange}
            menuItems={menuItems}
            allowNegative
            short={short}
            coarseTicks={coarseTicks}
        />
    );
}
