import React, { FC } from 'react';

import { BatteryTestNameFilter } from '../types';

export interface BatteryTestNameFilterResultProps {
    filter: BatteryTestNameFilter;
}

export const TaskBatteryTestNameFilterResult: FC<BatteryTestNameFilterResultProps> = ({ filter }) => {
    if (filter.wildcard) {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span className='text-eggplantLight'>Matching</span>
                <span>{filter.name}</span>
            </div>
        );
    } else {
        return <span>{filter.name}</span>;
    }
};
