/**
 * @generated SignedSource<<8bfebcbec51ff7b7c68d0d532ea16ee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ACSiteInternalReliability_site$data = {
  readonly acPower: {
    readonly " $fragmentSpreads": FragmentRefs<"IncidentCountsChart_acPower">;
  };
  readonly leastReliableDevices: {
    readonly data: ReadonlyArray<{
      readonly acPower: {
        readonly reliability: {
          readonly mtbf: {
            readonly current: number | null;
          };
          readonly mttr: {
            readonly current: number | null;
          };
        };
      };
      readonly id: string;
      readonly name: string;
      readonly site: {
        readonly name: string;
      };
    }>;
  };
  readonly name: string;
  readonly " $fragmentType": "ACSiteInternalReliability_site";
};
export type ACSiteInternalReliability_site$key = {
  readonly " $data"?: ACSiteInternalReliability_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ACSiteInternalReliability_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "current",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ACSiteInternalReliability_site",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "leastReliableDevices",
      "args": [
        {
          "kind": "Variable",
          "name": "acReliabilityTimeRange",
          "variableName": "timeRange"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "dir": "Asc",
            "field": "ACReliability"
          }
        },
        {
          "kind": "Literal",
          "name": "pageSize",
          "value": 5
        }
      ],
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Site",
              "kind": "LinkedField",
              "name": "site",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DeviceACPower",
              "kind": "LinkedField",
              "name": "acPower",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "timeRange",
                      "variableName": "timeRange"
                    },
                    {
                      "kind": "Literal",
                      "name": "unit",
                      "value": "Seconds"
                    }
                  ],
                  "concreteType": "DeviceAcPowerReliabilityMetrics",
                  "kind": "LinkedField",
                  "name": "reliability",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReliabilityMetric",
                      "kind": "LinkedField",
                      "name": "mttr",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReliabilityMetric",
                      "kind": "LinkedField",
                      "name": "mtbf",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteACPower",
      "kind": "LinkedField",
      "name": "acPower",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "IncidentCountsChart_acPower"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "2ce34440dc42f5b231437c517ba11ebd";

export default node;
