import React, { FC, useCallback, useMemo } from 'react';
import { useFragment } from 'react-relay';

import { BarDataType, LabelFormatterType, StackedHorizontalBar, ValueFormatterType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { StateStyleMap } from 'lib/common';
import { StateLabelMap } from 'lib/textFormatters';

import { TaskProgressBar_task$key } from './__generated__/TaskProgressBar_task.graphql';

export interface TaskProgressBarProps {
    task: TaskProgressBar_task$key;
    isCancelledTask?: boolean;
}

export const TaskProgressBar: FC<TaskProgressBarProps> = ({ task, isCancelledTask = false }) => {
    const result = useFragment(
        graphql`
            fragment TaskProgressBar_task on Task {
                countByState {
                    state
                    deviceCount
                }
            }
        `,
        task
    );

    const countByState = result?.countByState;

    const data = useMemo<BarDataType[]>(() => {
        if (isCancelledTask) {
            return [
                {
                    label: 'cancelled',
                    value: 0,
                },
            ];
        } else {
            return (
                countByState.map(countState => ({
                    label: StateLabelMap[countState.state] ?? countState.state,
                    value: countState.deviceCount,
                    ...(StateStyleMap[countState.state] ?? {}),
                })) ?? []
            );
        }
    }, [countByState, isCancelledTask]);

    const labelFormatter: LabelFormatterType<BarDataType> = useCallback(
        (label, data) => {
            if (label === null) {
                return '';
            } else if (countByState.length === 0) {
                return `${label.toUpperCase()}`;
            }
            return `${data.value} ${label.toUpperCase()}`;
        },
        [countByState]
    );

    const valueFormatter: ValueFormatterType<BarDataType> = useCallback(value => {
        return `${value} Devices`;
    }, []);

    const tooltipRenderer = (element: BarDataType) => {
        return (
            <>
                <div className='text-coralRegular text-xs font-light'>{element.label.toUpperCase()}</div>
                <div className='text-eggplantRegular text-base font-normal'>
                    {valueFormatter(element.value, element)}
                </div>
            </>
        );
    };

    return (
        <StackedHorizontalBar
            labelFormatter={labelFormatter}
            valueFormatter={valueFormatter}
            data={data}
            tooltipRenderer={tooltipRenderer}
            disableTooltips={isCancelledTask}
        />
    );
};
