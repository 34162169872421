import React, { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import {
    BatteryChargingIcon,
    GeneratorIcon,
    Link,
    LoadIcon,
    PowerIcon,
    RectifierIcon,
    Tooltip,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { useDocumentTitle } from 'components';
import { FilterValueMap } from 'filters/common';
import { DeviceTableColumn, DeviceTableColumnId } from 'filters/device';
import { DeviceAllFilterMap, StaticDeviceFilterDefinitions } from 'filters/device/settings';
import humanizeDuration from 'humanize-duration';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { getGlobalEnvironment } from 'lib/environment';
import { numberToLocaleString } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { renderDeviceHealthCell } from 'lib/table-columns';
import { encodeFilterParameters } from 'lib/table-filter';
import { formatGeneratorOperationMode, formatGeneratorState } from 'lib/textFormatters';
import { formatValueWithString } from 'lib/units';
import { DateTime } from 'luxon';
import { makeLinkToMetric } from 'views/explore/lib/link';
import { Operation } from 'views/explore/types';

import { LiveDataRefreshInterval } from '../../settings';
import {
    DeviceHealth,
    GeneratorState,
    SiteOverviewContentQuery,
    SiteOverviewContentQuery$data,
    SiteOverviewContentQuery$variables,
} from './__generated__/SiteOverviewContentQuery.graphql';
import { getACPowerSVGPath, getDCPowerSVGPath } from './common';
import { DomainContainer, InlineLabelWithMetric } from './components';
import style from './style.module.css';

export enum DCPowerStatus {
    RectifierPower,
    BatteryPower,
    BatteryCharging,
    NoLoad,
    NoLoadBatteryCharging,
    NoPowerControllers,
}

export enum ACPowerStatus {
    GridPower,
    GeneratorPower,
}

export type Device = NonNullable<SiteOverviewContentQuery$data['site']>['devices']['data'][number];

export interface SiteOverviewContentProps {
    queryRef: PreloadedQuery<SiteOverviewContentQuery>;
    siteId: string;
}

export const SiteOverviewContent: FC<SiteOverviewContentProps> = ({ queryRef, siteId }) => {
    const initialData = usePreloadedQuery(SiteOverviewQuery, queryRef);
    const environment = useRelayEnvironment();
    const navigate = useExtendedNavigate();
    const [data, setData] = useState(initialData);

    const gridRef = useRef<HTMLDivElement>(null);
    const [colWidth, setColWidth] = useState<number>(0);
    const [colHeight, setColHeight] = useState<number>(0);

    const site = data.site;
    const devices = site?.devices.data;
    const generatorDevices = devices?.filter(device => device.type.category === 'Generator') ?? [];
    const powerControllerDevices = useMemo(
        () => devices?.filter(device => device.type.category === 'PowerController') ?? [],
        [devices]
    );
    const onlinePowerControllerDevices = useMemo(
        () =>
            powerControllerDevices?.filter(device => device.health !== 'Offline' && device.health !== 'Unknown') ?? [],
        [powerControllerDevices]
    );
    const powerControllerIds = powerControllerDevices.map(device => device.id);

    useDocumentTitle(`${site?.name ?? 'Site'}`);

    // Live refresh the data
    useEffect(() => {
        const refetchInterval = setInterval(() => {
            const refetchVariables: SiteOverviewContentQuery$variables = {
                id: siteId,
            };

            fetchQuery<SiteOverviewContentQuery>(environment, SiteOverviewQuery, refetchVariables, {
                fetchPolicy: 'network-only',
            }).subscribe({
                next(value) {
                    setData(value);
                },
                error(error: unknown) {
                    captureException(error, scope => {
                        scope.setExtra('variables', refetchVariables);
                        scope.setTag('Component', 'InsightsContent');
                        return scope;
                    });
                },
            });
        }, LiveDataRefreshInterval);

        return () => clearInterval(refetchInterval);
    }, [siteId, environment]);

    useEffect(() => {
        const handleResize = () => {
            if (gridRef.current) {
                const gridBoxSize = gridRef.current.querySelector('.row-start-1.col-start-1');

                if (gridBoxSize) {
                    const { width, height } = gridBoxSize.getBoundingClientRect();
                    setColHeight(height);
                    setColWidth(width);
                }
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // generator
    const generatorDevice = generatorDevices.length > 0 ? generatorDevices[0] : null;
    let generatorState: GeneratorState | null = null;
    let fuelPercentage: number | null = 0;
    if (generatorDevice) {
        generatorState = generatorDevice.generatorMetrics.latestState;
        fuelPercentage = generatorDevice.fuelTankMetrics.latestLevel;
    }
    let formattedFuelPercentage: string;
    if (generatorDevice && fuelPercentage != null && generatorDevice.health !== 'Offline') {
        formattedFuelPercentage = formatValueWithString(numberToLocaleString(fuelPercentage, 0), '%');
    } else {
        formattedFuelPercentage = formatValueWithString('-', '%');
    }

    // grid
    const isPowerOnGenerator = generatorDevice?.generatorMetrics.latestState === 'RunningOnLoad';

    const gridPhase1Voltage =
        onlinePowerControllerDevices?.reduce(
            (agg, cur) => agg + (cur.acPower.feeds.data[0]?.metrics.latestVoltage ?? 0),
            0
        ) / onlinePowerControllerDevices.length;
    let formattedGridPhase1Voltage: string;
    if (Number.isFinite(gridPhase1Voltage) && !isPowerOnGenerator) {
        formattedGridPhase1Voltage = formatValueWithString(numberToLocaleString(gridPhase1Voltage, 0), 'V');
    } else {
        formattedGridPhase1Voltage = formatValueWithString('-', 'V');
    }

    const gridPhase2Voltage =
        onlinePowerControllerDevices?.reduce(
            (agg, cur) => agg + (cur.acPower.feeds.data[1]?.metrics.latestVoltage ?? 0),
            0
        ) / onlinePowerControllerDevices.length;
    let formattedGridPhase2Voltage: string;
    if (Number.isFinite(gridPhase2Voltage) && !isPowerOnGenerator) {
        formattedGridPhase2Voltage = formatValueWithString(numberToLocaleString(gridPhase2Voltage, 0), 'V');
    } else {
        formattedGridPhase2Voltage = formatValueWithString('-', 'V');
    }

    const gridPhase3Voltage =
        onlinePowerControllerDevices?.reduce(
            (agg, cur) => agg + (cur.acPower.feeds.data[2]?.metrics.latestVoltage ?? 0),
            0
        ) / onlinePowerControllerDevices.length;
    let formattedGridPhase3Voltage: string;
    if (Number.isFinite(gridPhase3Voltage) && !isPowerOnGenerator) {
        formattedGridPhase3Voltage = formatValueWithString(numberToLocaleString(gridPhase3Voltage, 0), 'V');
    } else {
        formattedGridPhase3Voltage = formatValueWithString('-', 'V');
    }

    // rectifier
    const totalRectifierOutput = onlinePowerControllerDevices.reduce(
        (agg, cur) => agg + (cur.rectifier?.metrics.latestOutputPower ?? 0),
        0
    );
    let formattedTotalRectifierOutput: string;
    if (onlinePowerControllerDevices.length !== 0) {
        formattedTotalRectifierOutput = formatValueWithString(numberToLocaleString(totalRectifierOutput, 2), 'kW');
    } else {
        formattedTotalRectifierOutput = formatValueWithString('-', 'kW');
    }

    // battery
    const batteriesPresent = powerControllerDevices.some(device => device.battery?.installed === true);
    const batteryStatus =
        onlinePowerControllerDevices.length !== 0
            ? batteryStatusToString(site?.batteryMetrics.commonStatus ?? null)
            : 'Unknown';
    const capacitiesArray = onlinePowerControllerDevices
        .map(device => {
            if (
                device.battery?.metrics.latestRemainingCapacity !== null &&
                device.battery?.metrics.latestUsedCapacity !== null
            ) {
                const latestRemainingCapacity = device.battery.metrics.latestRemainingCapacity;
                const latestUsedCapacity = device.battery.metrics.latestUsedCapacity;

                return (latestRemainingCapacity / (latestUsedCapacity + latestRemainingCapacity)) * 100;
            }

            return NaN;
        })
        .filter(val => Number.isFinite(val));
    const batteryCapacity = capacitiesArray.reduce((agg, cur) => agg + cur, 0) / capacitiesArray.length;
    let formattedBatteryCapacity: string;
    if (Number.isFinite(batteryCapacity)) {
        formattedBatteryCapacity = formatValueWithString(numberToLocaleString(batteryCapacity, 0), '%');
    } else {
        formattedBatteryCapacity = formatValueWithString('-', '%');
    }

    // load
    const totalLoad = onlinePowerControllerDevices.reduce((agg, cur) => agg + (cur.load.metrics.latestPower ?? 0), 0);
    let formattedTotalLoad: string;
    if (onlinePowerControllerDevices.length !== 0) {
        formattedTotalLoad = formatValueWithString(numberToLocaleString(totalLoad, 2), 'kW');
    } else {
        formattedTotalLoad = formatValueWithString('-', 'kW');
    }

    // AC and DC power states
    const acPowerState: ACPowerStatus | null = useMemo(
        () => getACPowerState(generatorDevice, onlinePowerControllerDevices),
        [generatorDevice, onlinePowerControllerDevices]
    );
    const dcPowerState = useMemo(() => getDCPowerState(onlinePowerControllerDevices), [onlinePowerControllerDevices]);

    return (
        <div>
            <div className='px-4 pb-1'>
                <div className='flex flex-row justify-between items-center'>
                    <div className='font-bold text-3xl truncate'>Site Overview</div>
                    {renderSiteHealth(site?.health?.commonStatus ?? null, site?.health?.multipleStatuses ?? false)}
                </div>
                <div className='flex flex-row justify-between items-center'>
                    <div className='font-light text-sm whitespace-nowrap'>Aggregated view of the entire site</div>
                    {renderOnlineStatus(devices, site?.health?.commonStatus ?? null)}
                </div>
            </div>
            <div className={style.container} ref={gridRef}>
                <div className='row-start-1 col-start-1'>
                    <DomainContainer title='Grid' icon={<PowerIcon />}>
                        <div className='flex flex-col w-full'>
                            <InlineLabelWithMetric
                                label='Phase 1'
                                metric={formattedGridPhase1Voltage}
                                link={
                                    powerControllerDevices.length > 0
                                        ? makeLinkToMetric(powerControllerIds, {
                                              metric: `RectifierMainsVoltage.1`,
                                              op: Operation.Average,
                                          })
                                        : undefined
                                }
                            />
                            <InlineLabelWithMetric
                                label='Phase 2'
                                metric={formattedGridPhase2Voltage}
                                link={
                                    powerControllerDevices.length > 0
                                        ? makeLinkToMetric(powerControllerIds, {
                                              metric: `RectifierMainsVoltage.2`,
                                              op: Operation.Average,
                                          })
                                        : undefined
                                }
                            />
                            <InlineLabelWithMetric
                                label='Phase 3'
                                metric={formattedGridPhase3Voltage}
                                link={
                                    powerControllerDevices.length > 0
                                        ? makeLinkToMetric(powerControllerIds, {
                                              metric: `RectifierMainsVoltage.3`,
                                              op: Operation.Average,
                                          })
                                        : undefined
                                }
                            />
                        </div>
                    </DomainContainer>
                </div>

                <div className='row-start-3 col-start-1'>
                    <DomainContainer
                        title='Generator'
                        icon={<GeneratorIcon />}
                        unsupported={!generatorDevice}
                        unsupportedMessage='No generator configured'
                        unsupportedButtonText='Add generator'
                        unsupportedButtonOnClick={() =>
                            navigate({ pathname: Paths.AddDevice, search: { site: siteId } })
                        }
                    >
                        {generatorDevice && (
                            <div className='flex flex-col w-full'>
                                <InlineLabelWithMetric
                                    label='State'
                                    metric={formatGeneratorState(
                                        generatorDevice.health !== 'Offline' ? generatorState : null
                                    )}
                                />
                                <InlineLabelWithMetric
                                    label='Mode'
                                    metric={formatGeneratorOperationMode(
                                        generatorDevice.health !== 'Offline'
                                            ? generatorDevice!.generatorMetrics.latestOperationMode
                                            : null
                                    )}
                                />
                                <InlineLabelWithMetric
                                    label={'Fuel level'}
                                    metric={formattedFuelPercentage}
                                    link={makeLinkToMetric(generatorDevice.id, {
                                        metric: `FuelTankCapacity`,
                                        op: Operation.Average,
                                    })}
                                />
                            </div>
                        )}
                    </DomainContainer>
                </div>

                {getACPowerSVGPath(acPowerState, colWidth, colHeight)}

                <div className='row-start-2 col-start-3'>
                    <DomainContainer
                        title='Rectifiers'
                        icon={<RectifierIcon />}
                        unsupported={powerControllerDevices.length === 0}
                        unsupportedMessage='No rectifiers configured'
                        unsupportedButtonText='Add device'
                        unsupportedButtonOnClick={() =>
                            navigate({ pathname: Paths.AddDevice, search: { site: siteId } })
                        }
                    >
                        {powerControllerDevices.length > 0 ? (
                            <Link
                                className='font-semibold text-2xl hover:underline'
                                to={makeLinkToMetric(powerControllerIds, {
                                    metric: `RectifierPower`,
                                    op: Operation.Average,
                                })}
                            >
                                {formattedTotalRectifierOutput}
                            </Link>
                        ) : (
                            <div className='font-semibold text-2xl'>{formatValueWithString('-', 'kW')}</div>
                        )}
                    </DomainContainer>
                </div>

                {getDCPowerSVGPath(dcPowerState, colWidth, colHeight)}

                <div className='row-start-3 col-start-4 z-10'>
                    <DomainContainer
                        title='Battery'
                        icon={<BatteryChargingIcon />}
                        unsupported={!batteriesPresent}
                        unsupportedMessage='No battery configured'
                        unsupportedButtonText='Add batteries'
                        unsupportedButtonOnClick={() => {
                            if (powerControllerDevices.length === 1) {
                                return navigate(generatePath(Paths.EditDevice, { id: powerControllerIds[0] }));
                            } else {
                                return navigate({
                                    pathname: Paths.Devices,
                                    search: {
                                        ...encodeFilterParameters<
                                            DeviceTableColumnId,
                                            FilterValueMap<DeviceAllFilterMap>
                                        >(
                                            {
                                                [DeviceTableColumn.Site]: [
                                                    {
                                                        type: 'result',
                                                        name: site!.name,
                                                    },
                                                ],
                                            },
                                            StaticDeviceFilterDefinitions
                                        ),
                                    },
                                });
                            }
                        }}
                    >
                        <div className='flex flex-col w-full'>
                            <InlineLabelWithMetric label={'Status'} metric={batteryStatus} />
                            <InlineLabelWithMetric
                                label={'Capacity'}
                                metric={formattedBatteryCapacity}
                                link={makeLinkToMetric(powerControllerIds, {
                                    metric: `BatteryCapacityPercent`,
                                    op: Operation.Average,
                                })}
                            />
                        </div>
                    </DomainContainer>
                </div>

                <div className='row-start-2 col-start-5'>
                    <DomainContainer title='Load' icon={<LoadIcon />}>
                        {powerControllerDevices.length > 0 ? (
                            <Link
                                className='font-semibold text-2xl hover:underline'
                                to={makeLinkToMetric(powerControllerIds, {
                                    metric: `LoadPower`,
                                    op: Operation.Average,
                                })}
                            >
                                {formattedTotalLoad}
                            </Link>
                        ) : (
                            <div className='font-semibold text-2xl'>{formatValueWithString('-', 'kW')}</div>
                        )}
                    </DomainContainer>
                </div>
            </div>
        </div>
    );
};

export const SiteOverviewQuery = graphql`
    query SiteOverviewContentQuery($id: ID!) {
        site(id: $id) {
            name
            health {
                status
                commonStatus
                multipleStatuses
            }
            batteryMetrics {
                commonStatus
                multipleStatuses
            }
            devices {
                data {
                    id
                    health
                    lastUpdate
                    lastOnline
                    type {
                        category
                    }
                    load {
                        metrics {
                            isOnline
                            latestPower(unit: KiloWatt)
                        }
                    }
                    rectifier {
                        metrics {
                            latestOutputPower(unit: KiloWatt)
                        }
                    }
                    acPower {
                        feeds {
                            data {
                                status
                                metrics {
                                    latestVoltage
                                }
                            }
                        }
                    }
                    battery {
                        installed
                        metrics {
                            latestStatus
                            latestRemainingCapacity
                            latestUsedCapacity
                        }
                    }
                    generatorMetrics {
                        latestState
                        latestOperationMode
                    }
                    fuelTankMetrics {
                        latestLevel
                    }
                }
            }
        }
    }
`;

export function loadSiteOverviewPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        SiteOverviewQuery,
        {
            id,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}

function getACPowerState(generatorDevice: Device | null, powerControllerDevices: Device[]): ACPowerStatus | null {
    const someFeedsOnline = powerControllerDevices.some(device =>
        device.acPower.feeds.data.some(feed => feed.status === 'Healthy')
    );
    const allFeedsOffline = powerControllerDevices.every(device =>
        device.acPower.feeds.data.every(feed => feed.status === 'Offline')
    );

    if (generatorDevice) {
        const generatorStatus = generatorDevice.generatorMetrics.latestState;

        if (generatorStatus === 'RunningOnLoad' && generatorDevice.health !== 'Offline') {
            return ACPowerStatus.GeneratorPower;
        } else if (generatorStatus !== 'Running' && someFeedsOnline) {
            return ACPowerStatus.GridPower;
        } else if (generatorStatus !== 'Running' && allFeedsOffline) {
            return null;
        }
    } else if (allFeedsOffline) {
        return null;
    }

    return ACPowerStatus.GridPower;
}

function getDCPowerState(powerControllerDevices: Device[]): DCPowerStatus {
    if (powerControllerDevices.length === 0) {
        return DCPowerStatus.NoPowerControllers;
    }

    // load
    const allLoadsOnline = powerControllerDevices.every(device => device.load.metrics.isOnline === true);
    const someLoadsOnline = powerControllerDevices.some(device => device.load.metrics.isOnline === true);
    const allLoadsOffline = powerControllerDevices.every(device => device.load.metrics.isOnline === false);
    // battery installed
    const allBatteriesInstalled = powerControllerDevices.every(device => device.battery?.installed === true);
    const noBatteriesInstalled = powerControllerDevices.every(device => device.battery?.installed === false);
    // battery charge
    const allBatteriesCharging = powerControllerDevices.every(
        device =>
            device.battery.metrics.latestStatus === 'Charging' ||
            device.battery.metrics.latestStatus === 'BoostCharging' ||
            device.battery.metrics.latestStatus === 'FloatCharging'
    );
    const someBatteriesCharging = powerControllerDevices.some(
        device =>
            device.battery.metrics.latestStatus === 'Charging' ||
            device.battery.metrics.latestStatus === 'BoostCharging' ||
            device.battery.metrics.latestStatus === 'FloatCharging'
    );
    // battery discharge
    const allBatteriesDischarging = powerControllerDevices.every(
        device => device.battery.metrics.latestStatus === 'Discharging'
    );
    const allBatteriesAreIdle = powerControllerDevices.every(
        device =>
            device.battery.metrics.latestStatus === 'Idle' ||
            device.battery.metrics.latestStatus === 'Disconnected' ||
            device.battery.metrics.latestStatus === 'Missing' ||
            device.battery.metrics.latestStatus === 'Unknown'
    );

    if (
        (allLoadsOnline || someLoadsOnline) &&
        allBatteriesInstalled &&
        (allBatteriesCharging || someBatteriesCharging)
    ) {
        return DCPowerStatus.BatteryCharging;
    }

    if ((allLoadsOnline || someLoadsOnline) && (noBatteriesInstalled || allBatteriesAreIdle)) {
        return DCPowerStatus.RectifierPower;
    }

    if (allLoadsOffline && (allBatteriesCharging || someBatteriesCharging)) {
        return DCPowerStatus.NoLoadBatteryCharging;
    }

    if (allBatteriesDischarging && (allLoadsOnline || someLoadsOnline)) {
        return DCPowerStatus.BatteryPower;
    }

    return DCPowerStatus.NoLoad;
}

function renderSiteHealth(commonStatus: DeviceHealth | null, multipleStatuses: boolean): ReactElement {
    return renderDeviceHealthCell(multipleStatuses ? 'Various' : commonStatus ?? 'Various');
}

function renderOnlineStatus(
    powerControllerDevices: NonNullable<SiteOverviewContentQuery$data['site']>['devices']['data'] | undefined,
    commonStatus: DeviceHealth | null
): ReactElement {
    if (!powerControllerDevices || powerControllerDevices.length === 0) {
        return <div className='text-xs'>No devices configured</div>;
    }

    let lastUpdatedTooltip: string;
    let lastUpdatedDescription: string;
    let lastDescriptionText: string;

    const lastUpdate = powerControllerDevices.reduce((agg: string, cur) => {
        if (cur.lastUpdate) {
            return cur.lastUpdate > agg ? cur.lastUpdate : agg;
        }
        return agg;
    }, '');
    const lastOnline = powerControllerDevices.reduce((agg, cur) => {
        if (cur.lastOnline) {
            return cur.lastOnline > agg ? cur.lastOnline : agg;
        }
        return agg;
    }, '');

    if (commonStatus === 'Offline') {
        lastDescriptionText = 'Last online';

        if (lastOnline !== '') {
            lastUpdatedTooltip = getDateTimeFormat(lastOnline);
            const lastOnlineDate = DateTime.fromISO(lastOnline);
            const timeSinceLastOnline = lastOnlineDate.diffNow().negate();

            const timeSinceLastOnlineInSeconds = Math.round(timeSinceLastOnline.as('seconds'));

            lastUpdatedDescription = `${humanizeDuration(timeSinceLastOnlineInSeconds * 1000, { largest: 1 })} ago`;
        } else {
            lastUpdatedTooltip = 'This site has never been online';
            lastUpdatedDescription = 'never';
        }
    } else {
        lastDescriptionText = 'Last collected';

        if (lastUpdate) {
            lastUpdatedTooltip = getDateTimeFormat(lastUpdate);
            const lastUpdatedDate = DateTime.fromISO(lastUpdate);
            const timeSinceLastUpdate = lastUpdatedDate.diffNow().negate();

            const timeSinceLastUpdateInSeconds = Math.round(timeSinceLastUpdate.as('seconds'));

            lastUpdatedDescription = `${humanizeDuration(timeSinceLastUpdateInSeconds * 1000, { largest: 1 })} ago`;
        } else {
            lastUpdatedTooltip = 'This site has never been collected';
            lastUpdatedDescription = 'never';
        }
    }

    const StatusBlinker: FC<{ state: DeviceHealth | 'Various' }> = ({ state }) => {
        let colorClass: string;

        switch (state) {
            case 'Offline':
                colorClass = 'bg-coralRegular';
                break;
            case 'Healthy':
                colorClass = 'bg-pineRegular';
                break;
            default:
                colorClass = 'bg-eggplantRegular';
        }

        return (
            <span className='relative flex h-3 w-3'>
                <span
                    className={classNames(
                        colorClass,
                        'animate-ping absolute inline-flex h-full w-full rounded-full opacity-50'
                    )}
                ></span>
                <span className={classNames(colorClass, 'relative inline-flex rounded-full h-3 w-3')}></span>
            </span>
        );
    };

    return (
        <div className='flex flex-row space-x-2 items-center'>
            <Tooltip content={lastUpdatedTooltip}>
                <div className='text-xs'>
                    <span>{lastDescriptionText}</span> <span>{lastUpdatedDescription}</span>
                </div>
            </Tooltip>
            <StatusBlinker state={commonStatus ?? 'Various'} />
        </div>
    );
}
