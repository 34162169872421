import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { ActivityLogType } from 'components/PagedActivityLog/__generated__/PagedActivityLogQuery.graphql';
import { GridEventStatus } from 'filters/grid-event';

import { HistoryCategory, SelectCategoriesPaneProps } from './SelectCategoryPane';

type GridEventStatusPaneProps = SelectCategoriesPaneProps;

export const GridEventStatusPane: FC<GridEventStatusPaneProps> = ({
    current,
    onClearOrBack,
    onSubmit,
    hasPrevious,
    onClose,
    clearButton,
}) => {
    return (
        <MultipleSelect
            title='Grid Event Status'
            variant='standard'
            options={[GridEventStatus.Outage, GridEventStatus.OverVoltage, GridEventStatus.UnderVoltage]}
            active={
                current
                    ? ((current as ActivityLogType[])
                          .map(humanizeGridEventStatusEvent)
                          .filter(type => type != null) as GridEventStatus[])
                    : []
            }
            renderItem={event => event}
            renderItemSimple={event => event}
            onSubmit={events =>
                onSubmit(
                    events.map(convertGridEventStatusEvent).filter(event => event != null) as ActivityLogType[],
                    HistoryCategory.GridEvent
                )
            }
            onClear={() => {
                onClearOrBack();
            }}
            showBack={hasPrevious}
            onClose={onClose}
            clearButton={clearButton}
        />
    );
};

function convertGridEventStatusEvent(status: GridEventStatus): ActivityLogType | null {
    if (status === GridEventStatus.Outage) {
        return 'GridEventStatusOffline';
    }
    if (status === GridEventStatus.OverVoltage) {
        return 'GridEventStatusHigh';
    }
    if (status === GridEventStatus.UnderVoltage) {
        return 'GridEventStatusLow';
    }

    return null;
}

export function humanizeGridEventStatusEvent(event: ActivityLogType): GridEventStatus | null {
    if (event === 'GridEventStatusOffline') {
        return GridEventStatus.Outage;
    }
    if (event === 'GridEventStatusHigh') {
        return GridEventStatus.OverVoltage;
    }
    if (event === 'GridEventStatusLow') {
        return GridEventStatus.UnderVoltage;
    }

    return null;
}
