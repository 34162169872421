import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData } from 'react-router-dom';

import { useDocumentTitle } from 'components';

import { ACPowerOverviewContent } from './ACPowerOverviewContent';
import { ACPowerOverviewSkeleton } from './ACPowerOverviewSkeleton';
import { ACPowerOverviewContentQuery } from './__generated__/ACPowerOverviewContentQuery.graphql';

export const ACPowerOverview: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<ACPowerOverviewContentQuery>;
    useDocumentTitle('Reports - AC power');

    return (
        <>
            {!queryRef && <ACPowerOverviewSkeleton />}
            {queryRef && (
                <Suspense fallback={<ACPowerOverviewSkeleton />}>
                    <ACPowerOverviewContent queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};
