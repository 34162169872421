import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ArrowLeftIcon, FilterContainer, FilterKeyValue, SearchBox, SelectedFilters } from '@accesstel/pcm-ui';

import { useDebouncer } from '../../lib/debounce';

export interface FilterSearchSelectProps<T> {
    title: string;
    placeholder: string;
    active?: T[];
    onClear: () => void;
    onSubmit?: (items: T[]) => void;
    onSearch?: (input: string) => Promise<T[]>;
    onClose?: () => void;
    renderItem: (item: T) => ReactNode;
    renderItemSimple: (item: T) => string;
    createDefaultItem?: (input: string) => T | null;
    showBack?: boolean;
    searchOnLoad?: boolean;
}

export function FilterSearchSelect<T>({
    title,
    placeholder,
    active,
    onClear,
    onSubmit,
    onSearch,
    onClose,
    renderItem,
    renderItemSimple,
    createDefaultItem,
    showBack,
    searchOnLoad,
}: FilterSearchSelectProps<T>): ReactElement {
    const [items, setItems] = useState<T[]>(active ?? []);
    const [results, setResults] = useState<T[] | undefined>(undefined);
    const [searchValue, setSearchValue] = useState<string>('');
    const currentSearchValue = useRef<string>('');

    const activeFilters = useMemo<FilterKeyValue[]>(
        () =>
            items.map(item => ({
                label: '',
                value: renderItemSimple(item),
            })),
        [items, renderItemSimple]
    );

    const searchWithDebounce = useDebouncer((input: string) => {
        onSearch?.(input)
            .then(setResults)
            .catch(() => setResults([]));
    }, 500);

    const handleChange = useCallback(
        (input: string) => {
            currentSearchValue.current = input;
            setSearchValue(input);

            searchWithDebounce(input);
        },
        [searchWithDebounce]
    );

    const addItem = useCallback((item: T) => {
        setItems(items => {
            if (!items.includes(item)) {
                return [...items, item];
            } else {
                return items;
            }
        });
        setSearchValue('');
        setResults(undefined);
    }, []);

    const handleSearch = useCallback(
        (input: string) => {
            if (!onSearch) {
                return;
            }

            onSearch(input)
                .then(results => {
                    if (results.length > 0) {
                        addItem(results[0]);
                    } else {
                        setResults([]);
                    }
                })
                .catch(() => setResults([]));
        },
        [addItem, onSearch]
    );

    const handleOnRemove = useCallback((filter: FilterKeyValue, index: number) => {
        setItems(items => items.filter((_, itemIndex) => itemIndex !== index));
    }, []);

    const handleConfirmClick = useCallback(() => {
        if (!onSubmit) {
            return;
        }

        if (currentSearchValue.current && createDefaultItem) {
            const defaultItem = createDefaultItem(currentSearchValue.current);
            if (defaultItem !== null) {
                onSubmit([...items, defaultItem]);
                return;
            }
        }

        onSubmit(items);
    }, [onSubmit, createDefaultItem, items]);

    useEffect(() => {
        if (searchOnLoad && onSearch) {
            onSearch('').then(setResults);
        }
    }, [onSearch, searchOnLoad]);

    let customClearButton: ReactNode;
    if (showBack && items.length === 0) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-coralRegular'>
                <ArrowLeftIcon />
            </div>
        );
    }

    return (
        <FilterContainer
            title={title}
            customButton={customClearButton}
            onClearClick={onClear}
            onConfirmClick={handleConfirmClick}
            onClose={onClose}
            primaryContent={
                <SearchBox
                    id='filterSearch'
                    variant='filter'
                    renderResult={renderItem}
                    searchResults={results}
                    value={searchValue}
                    onChange={handleChange}
                    onSubmit={handleSearch}
                    onResultClick={addItem}
                    placeHolder={placeholder}
                    autoFocus={true}
                />
            }
            secondaryContent={
                activeFilters.length > 0 && <SelectedFilters filters={activeFilters} onRemove={handleOnRemove} />
            }
            hideClearButton={items.length === 0 && !showBack}
            hideConfirmButton={items.length === 0 && (!active || active.length === 0)}
        />
    );
}
