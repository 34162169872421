import React, { ReactElement, ReactNode, useCallback, useEffect } from 'react';

import {
    BarDataType,
    Button,
    LabelFormatterType,
    PageHeading,
    SearchBoxProps,
    SortState,
    StackedHorizontalBar,
    Tooltip,
} from '@accesstel/pcm-ui';
import { HighlightContext, ValueFormatterType, highlight } from '@accesstel/pcm-ui';

import { ColumnDef } from '@tanstack/react-table';
import { useDocumentTitle } from 'components';

import { colorPalette } from '../../views/manage/AssetManagement';
import { CoreTableLayout, CoreTableLayoutProps } from './CoreTableLayout';
import { ActiveCount } from './components/ActiveCount';
import { Search, SearchResultWithGroups } from './components/Search';
import { TableActionType } from './reducer';

interface ExportValueOptions<DataType, ValueType> {
    value?: ValueType;
    row: DataType;
}

export type ColumnWithId<ColumnType extends string, DataType, ValueType = never> = ColumnDef<DataType, ValueType> & {
    id: ColumnType;
    exportValue?: (options: ExportValueOptions<DataType, ValueType>) => string;
};

export type DefinedSortState = Omit<SortState, 'unsorted'>;

const MAXIMUM_SEGMENT_LENGTH = 5;

export interface TableLayoutProps<
    ColumnType extends string,
    SearchResultType,
    DataType,
    TypeMap extends Record<ColumnType, unknown>,
    ChildDataType,
> extends CoreTableLayoutProps<ColumnType, DataType, TypeMap, ChildDataType> {
    title: string;

    overallCount?: number;
    resultCount?: number;

    primaryAction?: string;
    primaryActionLink?: string;

    resultCountClarification?: string;

    searchPlaceholder: string;
    onSearch?: (input: string) => Promise<SearchResultType[] | SearchResultWithGroups<SearchResultType>>;
    onSearchResultClick?: (item: SearchResultType) => void;
    renderSearchResult?: (item: SearchResultType, context: HighlightContext) => ReactNode;
    renderSearchResultAsString: (item: SearchResultType) => string;
    searchBoxProperties?: Partial<SearchBoxProps<SearchResultType>>;

    /**
     * The data for the stacked bar chart at the top - Usually used to show the distribution of data.
     * There will be a maximum of 5 (`MAXIMUM_SEGMENT_LENGTH`) segments shown.
     * If there are more than 5 segments, the rest will be grouped into an 'Others' segment.
     *
     * @param data - The data to be displayed
     * @param emptyLabel - The label to be displayed when there is no data
     * @param labelFormatter - The function to format the label
     * @param valueFormatter - The function to format the value
     * @param onSegmentClick - The function to be called when a segment is clicked
     *
     * @default undefined
     *
     * @example
     * {
     *    data: [
     *      { id: '1', label: 'Label 1', value: 10 },
     *      { id: '2', label: 'Label 2', value: 20 },
     *   ],
     *  emptyLabel: 'No data',
     *  labelFormatter: (label) => label,
     *  onSegmentClick: (id) => console.log(id)
     * }
     *
     */
    barChartData?: {
        data: BarDataType[];
        emptyLabel: string;
        labelFormatter: LabelFormatterType<BarDataType>;
        valueFormatter?: ValueFormatterType<BarDataType>;
        onSegmentClick: (id: string) => void;
    };
}

export function TableLayout<
    ColumnType extends string,
    DataType,
    ChildDataType,
    SearchResultType,
    TypeMap extends Record<ColumnType, unknown>,
>({
    title,
    overallCount,
    resultCount,
    hasError,
    primaryAction,
    primaryActionLink,
    resultCountClarification,
    searchPlaceholder,
    onSearch,
    onSearchResultClick,
    renderSearchResultAsString,
    renderSearchResult = (result, context) =>
        DefaultRenderSearchResultWithHighligthing<SearchResultType>(result, context, renderSearchResultAsString),
    searchBoxProperties,
    barChartData,

    tableState,
    dispatchTableState,
    page = 1,
    pageCount = 1,
    onRetry,

    selection,
    unit = 'Item',
    unitPlural = `${unit}s`,

    ...rest
}: TableLayoutProps<ColumnType, SearchResultType, DataType, TypeMap, ChildDataType>): ReactElement {
    useDocumentTitle(title);

    const handleDoSearch = useCallback(
        (input: string, item?: SearchResultType | null) => {
            if (item && onSearchResultClick) {
                onSearchResultClick(item);
            } else {
                dispatchTableState({ type: TableActionType.UpdateSearch, search: input });
            }
        },
        [dispatchTableState, onSearchResultClick]
    );

    const handleSearchClear = useCallback(() => {
        dispatchTableState({ type: TableActionType.UpdateSearch, search: '' });
    }, [dispatchTableState]);

    useEffect(() => {
        // whenever the page number exceeds the total number of pages,
        // set the page number to the first page
        if (page > pageCount) {
            dispatchTableState({ type: TableActionType.ChangePage, page: 1 });
        }
    }, [dispatchTableState, page, pageCount]);

    const limitedBarChartData =
        barChartData && barChartData.data.length > MAXIMUM_SEGMENT_LENGTH
            ? [
                  ...barChartData.data
                      .sort((curr, prev) => prev.value - curr.value)
                      .slice(0, MAXIMUM_SEGMENT_LENGTH - 1),
                  {
                      label: 'Others',
                      value: barChartData.data
                          .sort((curr, prev) => prev.value - curr.value)
                          .slice(MAXIMUM_SEGMENT_LENGTH - 1)
                          .reduce((acc, curr) => acc + curr.value, 0),
                      bgClass: colorPalette[MAXIMUM_SEGMENT_LENGTH % colorPalette.length],
                  },
              ]
            : barChartData?.data;

    const defaultValueFormatter = (value: number | null) => `${value} ${value === 1 ? unit : unitPlural}`;

    return (
        <>
            <div className='flex justify-between items-center'>
                <PageHeading value={title} />
                <div className='flex'>
                    {onSearch && (
                        <div className='w-64 pr-4'>
                            <Search
                                onSearch={onSearch}
                                renderSearchResult={renderSearchResult}
                                renderSearchResultAsString={renderSearchResultAsString}
                                placeholder={searchPlaceholder}
                                additionalProps={searchBoxProperties}
                                initialValue={tableState.search}
                                onFilter={handleDoSearch}
                                onClear={handleSearchClear}
                                isFiltering={tableState.search.length > 0}
                            />
                        </div>
                    )}
                    {primaryAction && primaryActionLink && (
                        <Button buttonText={primaryAction!} size='small' to={primaryActionLink} />
                    )}
                </div>
            </div>
            <ActiveCount
                hasError={hasError}
                retry={onRetry}
                count={overallCount}
                unitSingular={unit}
                unitPlural={unitPlural}
                resultCount={resultCount}
                clarificationText={resultCountClarification}
            />
            {barChartData && (
                <div className='pt-4 pb-8'>
                    <StackedHorizontalBar
                        data={limitedBarChartData as BarDataType[]}
                        valueFormatter={barChartData.valueFormatter ?? defaultValueFormatter}
                        labelFormatter={barChartData.labelFormatter}
                        emptyLabel={barChartData.emptyLabel}
                        onSegmentClick={barChartData.onSegmentClick}
                        sort={false}
                    />
                </div>
            )}
            <div className='pb-24'>
                <CoreTableLayout
                    {...rest}
                    tableState={tableState}
                    dispatchTableState={dispatchTableState}
                    page={page}
                    pageCount={pageCount}
                    onRetry={onRetry}
                    selection={selection}
                    unit={unit}
                    unitPlural={unitPlural}
                />
            </div>
        </>
    );
}

const DefaultRenderSearchResultWithHighligthing: <T>(
    result: T,
    context: HighlightContext,
    renderSearchResultAsString: (item: T) => string
) => ReactNode = (result, context, renderSearchResultAsString) => {
    const resultString = renderSearchResultAsString(result);
    const title = highlight(resultString, context);

    return (
        <div className='flex items-center'>
            <Tooltip content={title} overflowOnly>
                <div className='truncate'>{title}</div>
            </Tooltip>
        </div>
    );
};
