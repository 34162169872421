import { DateRangeFilter } from '../../components/FilterDateSelect/common';
import { RunTimeFilter } from '../../components/FilterDurationSelect/common';
import {
    GeneratorRunReportCause,
    GeneratorRunReportState,
} from '../../views/tasks/generator-run/report-list/__generated__/GeneratorRunListQuery.graphql';
import { RangeFilter } from '../common';
import { DeviceNameFilter } from '../device';

export type { GeneratorRunReportState, GeneratorRunReportCause };

export enum GeneratorRunReportTableColumn {
    ReportName = 'ReportName',
    DeviceName = 'DeviceName',
    CreatedTime = 'CreatedTime',
    FinalisedTime = 'FinalisedTime',
    GeneratorStartTime = 'GeneratorStartTime',
    GeneratorStopTime = 'GeneratorStopTime',
    TotalRunTime = 'TotalRunTime',
    State = 'State',
    Cause = 'Cause',
    ModelName = 'ModelName',
    SerialNumber = 'SerialNumber',
    FuelCapacity = 'FuelCapacity',

    RpmMin = 'RpmMin',
    RpmMax = 'RpmMax',
    RpmAvg = 'RpmAvg',

    CoolantTemperatureMin = 'CoolantTemperatureMin',
    CoolantTemperatureMax = 'CoolantTemperatureMax',
    CoolantTemperatureAvg = 'CoolantTemperatureAvg',
    CoolantPressureMin = 'CoolantPressureMin',
    CoolantPressureMax = 'CoolantPressureMax',
    CoolantPressureAvg = 'CoolantPressureAvg',

    OilPressureMin = 'OilPressureMin',
    OilPressureMax = 'OilPressureMax',
    OilPressureAvg = 'OilPressureAvg',
    OilTemperatureMin = 'OilTemperatureMin',
    OilTemperatureMax = 'OilTemperatureMax',
    OilTemperatureAvg = 'OilTemperatureAvg',

    OutputPowerAvg = 'PowerAvg',
    OutputEnergyTotal = 'EnergyTotal',

    FuelConsumptionTotal = 'FuelConsumedTotal',
    FuelRefuelledTotal = 'FuelRefuelledTotal',

    FuelConsumptionAvg = 'FuelConsumptionRateAvg',
    FuelConsumptionMax = 'FuelConsumptionRateMax',
    FuelConsumptionMin = 'FuelConsumptionRateMin',

    FuelLevelFinish = 'FuelLevelFin',
    FuelLevelMin = 'FuelLevelMin',
    FuelLevelMax = 'FuelLevelMax',
}

export type GeneratorRunReportDeviceNameFilter = DeviceNameFilter;
export type GeneratorRunReportTotalRunTimeFilter = RunTimeFilter;
export type GeneratorRunReportTimeFilter = DateRangeFilter;

export interface GeneratorRunReportStateFilter {
    id: GeneratorRunReportState;
    name: string;
}

export interface GeneratorRunReportCauseFilter {
    id: GeneratorRunReportCause;
    name: string;
}

export interface TextFilterFilterBase {
    type: 'result' | 'special';
    value: string;
    wildcard?: boolean;
}

export type GeneratorRunReportNameFilter = TextFilterFilterBase;
export type GeneratorRunReportModelFilter = TextFilterFilterBase;
export type GeneratorRunReportSerialNumberFilter = TextFilterFilterBase;

export type GeneratorRunReportFuelCapacityFilter = RangeFilter;

/**
 * Used for all the stats range filters
 */
export type GeneratorRunReportStatsFilter = RangeFilter;
