import { encodeDateRangeFilter } from 'filters/common';

import {
    AlertCategoryFilter,
    AlertClearTimeFilter,
    AlertDeviceNameFilter,
    AlertDomainFilter,
    AlertOriginatorFilter,
    AlertRaiseTimeFilter,
    AlertSeverityFilter,
    AlertSiteNameFilter,
    IsAlertActive,
} from './types';

export function encodeAlertSeverityFilter(severity: AlertSeverityFilter): string {
    return encodeURIComponent(severity.id);
}

export function encodeAlertDomainFilter(domain: AlertDomainFilter): string {
    return encodeURIComponent(domain.id);
}

export function encodeAlertDeviceNameFilter(deviceName: AlertDeviceNameFilter): string {
    return encodeURIComponent(deviceName.name);
}

export function encodeAlertSiteNameFilter(siteName: AlertSiteNameFilter): string {
    return encodeURIComponent(siteName.name);
}

export function encodeAlertRaiseDateFilter(raiseDate: AlertRaiseTimeFilter): string {
    return encodeDateRangeFilter(raiseDate);
}

export function encodeAlertClearDateFilter(clearDate: AlertClearTimeFilter): string {
    return encodeDateRangeFilter(clearDate);
}

export function encodeAlertCategoryFilter(category: AlertCategoryFilter): string {
    return encodeURIComponent(category.value);
}

export function encodeAlertOriginatorFilter(originator: AlertOriginatorFilter): string {
    return encodeURIComponent(originator.value);
}

export function encodeAlertIsActiveFilter(isActive: IsAlertActive): string {
    return encodeURIComponent(isActive);
}
