import React from 'react';
import { RouteObject } from 'react-router-dom';

import { AuthenticatedPageLayout } from 'layouts/AuthenticatedPageLayout';
import { RouteExtraData } from 'lib/route-helpers';

import DashboardRoutes from './Dashboard/route';
import { ErrorNotFound } from './ErrorPage/ErrorNotFound';
import { ErrorUnhandled } from './ErrorPage/ErrorUnhandled';
import FirmwareRoutes from './FirmwareStatus/route';
import AccountRoutes from './account/route';
import ExploreRoutes from './explore/route';
import LoginRoutes from './login/route';
import ManageRoutes from './manage/route';
import ReportRoutes from './reports/route';
import SettingRoutes from './settings/route';
import PowerControllerRoutes from './sites/route';
import TaskRoutes from './tasks/route';

export const Routes: RouteObject[] = [
    {
        // This route is only available if the user is logged in.
        path: '/',
        element: <AuthenticatedPageLayout />,
        errorElement: <ErrorUnhandled />,
        handle: {
            title: 'Overview',
            altTitle: 'Overview',
            description: 'The overview of the system.',
        } as RouteExtraData,
        children: [
            ...DashboardRoutes,
            ...PowerControllerRoutes,
            ...ReportRoutes,
            ...TaskRoutes,
            ...ManageRoutes,
            ...SettingRoutes,
            ...FirmwareRoutes,
            ...ExploreRoutes,
            ...AccountRoutes,
            {
                path: '*',
                element: <ErrorNotFound />,
            },
        ],
    },
    ...LoginRoutes,
];
