import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { InlineTextField, Menu, MenuItem, StatusLabel, useExtendedNavigate, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useUserPermissions } from 'lib/auth';
import { getFormattedDateFromDate } from 'lib/dateFormatter';
import { logError } from 'lib/log';
import { MenuItemGroup } from 'lib/menu';
import { Paths } from 'lib/routes';
import { generatorRunStatusToStatusLabelConfig } from 'lib/statusLabelFormatter';
import { DateTime } from 'luxon';
import { SiteViewPagePath } from 'views/sites/paths';
import { DateDisplay } from 'views/tasks/battery-health/test-result-view/components/DateDisplay';

import { Exporter } from '../lib/run-report-exporter';
import { RunReportHeaderUpdateNameMutation } from './__generated__/RunReportHeaderUpdateNameMutation.graphql';
import { RunReportHeader_data$key } from './__generated__/RunReportHeader_data.graphql';

export interface RunReportHeaderProps {
    runReport: RunReportHeader_data$key;
}

export const RunReportHeader: FC<RunReportHeaderProps> = ({ runReport }) => {
    const data = useFragment<RunReportHeader_data$key>(RunReportHeaderFragment, runReport);
    const navigate = useExtendedNavigate();
    const { hasAssetsRead, hasAssetsWrite, hasTasksWrite } = useUserPermissions();
    const exporter = useRef<Exporter | null>(null);
    const environment = useRelayEnvironment();
    const { show } = useToast();

    const [updateRunReportName] = useMutation<RunReportHeaderUpdateNameMutation>(UpdateRunReportNameMutation);
    const handleSaveRunReportName = useCallback(
        (newName: string | undefined) => {
            const sanitisedName = newName && newName.trim().length !== 0 ? newName.trim() : undefined;

            updateRunReportName({
                variables: {
                    id: data.id,
                    name: sanitisedName,
                },
                optimisticUpdater(store) {
                    const runResultRecord = store.get(data.id);
                    if (runResultRecord) {
                        if (sanitisedName) {
                            runResultRecord.setValue(sanitisedName, 'name');
                        } else {
                            runResultRecord.setValue(
                                `Generator Run Report ${getFormattedDateFromDate(data.generatorStartTime, {
                                    fullMonth: true,
                                })}`,
                                'name'
                            );
                        }
                    }
                },
                updater(store) {
                    const runResultRecord = store.get(data.id);
                    if (runResultRecord) {
                        if (sanitisedName) {
                            runResultRecord.setValue(sanitisedName, 'name');
                        } else {
                            runResultRecord.setValue(
                                `Generator Run Report ${getFormattedDateFromDate(data.generatorStartTime, {
                                    fullMonth: true,
                                })}`,
                                'name'
                            );
                        }
                    }
                },
                onError(error) {
                    logError('Error updating test name', error);
                    captureException(error, scope => {
                        scope.setTag('Component', 'RunReportHeader');
                        scope.setTag('Function', 'UpdateRunReportName');
                        scope.setExtra('id', data.id);
                        scope.setExtra('name', newName);
                        return scope;
                    });
                    show({
                        text: 'Failed to update run report name',
                        variant: 'error',
                    });
                },
                onCompleted(result) {
                    if (result.editGeneratorRunReportName === 'InvalidValue') {
                        show({
                            text: 'Invalid test name',
                            variant: 'error',
                        });
                    } else if (result.editGeneratorRunReportName !== 'Success') {
                        show({
                            text: 'Failed to update test name',
                            variant: 'error',
                        });
                    }
                },
            });
        },
        [data.generatorStartTime, data.id, show, updateRunReportName]
    );

    let title: string;
    let initialEditValue: string;

    if (data.name) {
        title = data.name;
        initialEditValue = title;
    } else {
        title = `Generator Run Report ${getFormattedDateFromDate(data.generatorStartTime, { fullMonth: true })}`;
        initialEditValue = '';
    }
    const isComplete = data.state === 'Completed' || data.state === 'Error';
    const canExport = isComplete;

    const doExport = useCallback(() => {
        if (!data.id) {
            return;
        }

        if (!isComplete) {
            return;
        }

        if (exporter.current?.running) {
            return;
        }

        exporter.current = new Exporter(data.id, show, environment);
        exporter.current.begin();
    }, [data.id, isComplete, show, environment]);

    const menuItems = useMemo<MenuItem[]>(() => {
        const items: MenuItem[] = [];

        items.push({
            name: 'Export metrics',
            onClick: doExport,
            disabled: !canExport,
        });

        items.push({
            name: 'View current state',
            onClick: () =>
                navigate({
                    pathname: Paths.SiteViewViewSiteDevicePage,
                    params: {
                        siteId: data.generator.site.id,
                        deviceId: data.generator.id,
                        page: SiteViewPagePath.Generator,
                    },
                }),
        });

        // ASSETS
        items.push({
            name: hasAssetsWrite ? 'Edit device' : 'View device',
            onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: data.generator.id } }),
            disabled: !hasAssetsRead,
            group: MenuItemGroup.Assets,
        });
        items.push({
            name: hasAssetsWrite ? 'Edit site' : 'View site',
            onClick: () => navigate({ pathname: Paths.EditSite, params: { id: data.generator.site.id } }),
            disabled: !hasAssetsRead,
            group: MenuItemGroup.Assets,
        });

        return items;
    }, [canExport, data.generator.id, data.generator.site.id, doExport, hasAssetsRead, hasAssetsWrite, navigate]);

    const uniqueMenuGroups = new Set<string>(menuItems.filter(item => item.group).map(item => item.group as string));
    const groupDefinition = Array.from(uniqueMenuGroups).map(group => ({
        key: group,
        title: group,
    }));

    return (
        <div className='px-8'>
            <div className='flex flex-row justify-between items-start relative gap-4'>
                <div className='flex-grow min-w-0'>
                    {!hasTasksWrite && <div className='text-3xl truncate'>{title}</div>}
                    {hasTasksWrite && (
                        <InlineTextField
                            value={title}
                            initialEditValue={initialEditValue}
                            className='text-3xl'
                            onEdit={handleSaveRunReportName}
                            noWrap
                            maxLength={50}
                        />
                    )}
                </div>
                <div className='flex flex-row space-x-2'>
                    <StatusLabel
                        label={data.state.toUpperCase()}
                        {...generatorRunStatusToStatusLabelConfig(data.state)}
                    />
                    <Menu id='run-report-props-menu' menuItems={menuItems} groups={groupDefinition} />
                </div>
            </div>

            <div className='flex gap-6 mt-4'>
                <DateDisplay
                    startTime={DateTime.fromISO(data.generatorStartTime).toJSDate()}
                    endTime={data.generatorStopTime ? DateTime.fromISO(data.generatorStopTime).toJSDate() : undefined}
                />
            </div>
        </div>
    );
};

const RunReportHeaderFragment = graphql`
    fragment RunReportHeader_data on GeneratorRunReport {
        id
        generator {
            id
            site {
                id
            }
        }
        name
        state
        generatorStartTime
        generatorStopTime
    }
`;

const UpdateRunReportNameMutation = graphql`
    mutation RunReportHeaderUpdateNameMutation($id: ID!, $name: String) {
        editGeneratorRunReportName(id: $id, name: $name)
    }
`;
