import React, { FC } from 'react';

import { FormArea, FormikGeneralSelect, FormikTextField } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';
import { StateList } from 'lib/provision';

import manageStyle from '../../../style.module.css';

interface AddressProps {
    namespace?: string;
}

export const Address: FC<AddressProps> = ({ namespace }) => {
    return (
        <FormArea label='Address Details' blurb='Theres are the deails of the physical location of the site.'>
            <div className={manageStyle.provisioning_content_grid}>
                <div className='col-start-1'>
                    <FormikTextField
                        name={getQualifiedName('address', namespace)}
                        required
                        placeHolder={'Address*'}
                        light
                        variant='outlined'
                        testId='site-address'
                    />
                </div>

                <div className='col-start-1 grid grid-cols-3 gap-6'>
                    <div className='col-start-1 col-span-2'>
                        <FormikGeneralSelect
                            name={getQualifiedName('state', namespace)}
                            placeHolder='State*'
                            light
                            items={StateList}
                            renderItem={(item: string) => item}
                            variant='outlined'
                            testId='site-state'
                        />
                    </div>
                    <div className='col-start-3'>
                        <FormikTextField
                            name={getQualifiedName('postcode', namespace)}
                            placeHolder={'Postcode'}
                            type={'text'}
                            maxLength={4} // 4 digits postcode (AU)
                            light
                            variant='outlined'
                            testId='site-postcode'
                        />
                    </div>
                </div>
            </div>
        </FormArea>
    );
};
