import { TestResultCauseFilter, TestResultFailReasonFilter, TestResultStateFilter } from './types';

export function encodeTestResultStateFilter(state: TestResultStateFilter): string {
    return encodeURIComponent(state.id);
}

export function encodeTestResultCauseFilter(cause: TestResultCauseFilter): string {
    return encodeURIComponent(cause.id);
}

export function encodeTestResultFailReasonFilter(filter: TestResultFailReasonFilter): string {
    return encodeURIComponent(filter.id);
}
