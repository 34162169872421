import { createContext, useContext } from 'react';
import { fetchQuery } from 'react-relay';

import { captureMessage } from '@sentry/react';
import { Amplify } from 'aws-amplify';
import {
    AuthError,
    confirmResetPassword,
    confirmSignIn,
    fetchAuthSession,
    fetchUserAttributes,
    signIn,
    signInWithRedirect,
    signOut,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import graphql from 'babel-plugin-relay/macro';
import { getGlobalEnvironment } from 'lib/environment';
import { IEnvironment } from 'relay-runtime';
import { PresetOptions, UnitPreset } from 'views/account/personal-settings/lib/units';

import { authUserPreferencesQuery } from './__generated__/authUserPreferencesQuery.graphql';
import { logError } from './log';
import { SuspenseObject } from './suspense';

export enum AuthRole {
    AssetsReadOnly = 'AssetsR',
    AssetsReadWrite = 'AssetsRW',
    TasksBatteryHealthReadOnly = 'TasksBatteryHealthR',
    TasksBatteryHealthReadWrite = 'TasksBatteryHealthRW',
    ReadOnly = 'ReadOnly',
    Auditor = 'Auditor',
    Administrator = 'Administrator',
}

export enum SignOutReason {
    UserRequest = 'signout',
    SessionExpired = 'sessionexpired',
}

export function setup(): void {
    const baseURL = `${window.location.protocol}//${window.location.host}`;
    // allow cognito to be proxied through our api, so we don't have any third party domains used
    let userPoolEndpoint: string | undefined;
    if (process.env.REACT_APP_COGNITO_API) {
        userPoolEndpoint = process.env.REACT_APP_COGNITO_API;

        if (userPoolEndpoint.startsWith('/')) {
            userPoolEndpoint = `${baseURL}${userPoolEndpoint}`;
        }
    }

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: process.env.REACT_APP_COGNITO_POOL ?? '',
                userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT ?? '',
                loginWith: {
                    oauth: {
                        domain: process.env.REACT_APP_COGNITO_DOMAIN ?? '',
                        redirectSignIn: [`${baseURL}`],
                        redirectSignOut: [`${baseURL}/logout`],
                        responseType: 'code',
                        scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
                    },
                },
                userPoolEndpoint,
            },
        },
    });
}

export enum AuthResultType {
    Success = 'success',
    SuccessNeedNewPassword = 'success_newpass',
    SuccessNeedPasswordReset = 'success_password_reset',
    FailureBadAuth = 'fail_auth',
    FailureBadCode = 'fail_code',
    FailureOther = 'fail',
    RateLimitExceeded = 'rate_limit',
}

export interface User {
    username: string;
    name: string;
    timezone?: string;
    units: authUserPreferencesQuery['response']['currentUser']['preferences']['units'];
    roles?: string[];
}

type AuthResultErrorMessage = string | undefined;
export type AuthResult = [AuthResultType, AuthResultErrorMessage];

// The context which allows everything on the site to know the current user and when that changes.
export interface AuthContextType {
    isLoggedIn: boolean;
    currentUser: User | null;
    initialErrorMessage: string | null;
}

export const AuthContext = createContext<AuthContextType>({
    isLoggedIn: false,
    currentUser: null,
    initialErrorMessage: null,
});

/**
 * Attempts to log into the system.
 * If the result is success, the user can be obtained using @see getCurrentUser
 * @param username Username of user
 * @param password Password of user
 * @returns A promise of an AuthResult.
 */
export async function login(username: string, password: string): Promise<AuthResult> {
    try {
        const { nextStep } = await signIn({ username, password });

        switch (nextStep.signInStep) {
            case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
                // First time login or password reset
                return [AuthResultType.SuccessNeedNewPassword, undefined];
            case 'DONE':
                return [AuthResultType.Success, undefined];
            default:
                // Unsupported challenge
                return [AuthResultType.FailureOther, undefined];
        }
    } catch (error) {
        if (error instanceof AuthError) {
            if (error.name === 'PasswordResetRequiredException') {
                return [AuthResultType.SuccessNeedPasswordReset, error.message];
            }
            if (error.name === 'NotAuthorizedException' || error.name === 'UserNotFoundException') {
                return [AuthResultType.FailureBadAuth, error.message];
            }
        }
        return [AuthResultType.FailureOther, error.message];
    }
}

/**
 * Attempts to login using an identity provider.
 * This will send the user off to the providers login page.
 * This function will not return.
 * The user can be obtained using @see getCurrentUser if login was successful
 * @param provider The id of the identity provider
 */
export async function loginWithFederation(provider: string): Promise<void> {
    await signInWithRedirect({
        provider: {
            custom: provider,
        },
    });
}

/**
 * Provides a new password for the user account.
 * This is only to be used when login requires a new pasword.
 * @param newPassword The password
 * @returns A Promise for an AuthResult.
 */
export async function supplyNewPassword(newPassword: string): Promise<AuthResult> {
    try {
        // NOTE: This does not currently support supplying required attributes
        // It is currently assumed that these will be set by the admin
        const { nextStep } = await confirmSignIn({ challengeResponse: newPassword });

        switch (nextStep.signInStep) {
            case 'DONE':
                return [AuthResultType.Success, undefined];
            default:
                // Unsupported challenge
                return [AuthResultType.FailureOther, undefined];
        }
    } catch (error) {
        if (error instanceof AuthError && error.name === 'InvalidPasswordException') {
            return [AuthResultType.FailureBadAuth, error.message];
        }
        return [AuthResultType.FailureOther, error.message];
    }
}

/**
 * Confirms a password reset after the user has received a verification code
 * @param username The username of the user
 * @param code The verification code
 * @param newPassword The new password for the user
 * @returns the next state
 */
export async function confirmPasswordReset(username: string, code: string, newPassword: string): Promise<AuthResult> {
    try {
        await confirmResetPassword({ username, confirmationCode: code, newPassword });
        return [AuthResultType.Success, undefined];
    } catch (error) {
        if (error instanceof AuthError) {
            if (error.name === 'UserNotFoundException') {
                return [AuthResultType.FailureBadCode, 'Invalid code provided, please request a code again.'];
            }
            if (error.name === 'InvalidPasswordException') {
                return [AuthResultType.FailureBadAuth, error.message];
            }
            if (error.name === 'ExpiredCodeException') {
                return [AuthResultType.FailureBadCode, error.message];
            }
            if (error.name === 'LimitExceededException') {
                return [AuthResultType.RateLimitExceeded, error.message];
            }
        }

        return [AuthResultType.FailureOther, error.message];
    }
}

let lastSignOutReason: SignOutReason | undefined;

/**
 * Logs the user out of the system.
 * Note, this does not return whether this was successful.
 * it should be assumed that this always succeeds.
 */
export async function logout(reason: SignOutReason = SignOutReason.UserRequest): Promise<AuthResult> {
    lastSignOutReason = reason;
    await signOut();

    return [AuthResultType.Success, undefined];
}

/**
 * Retrieves the last sign out reason.
 * @returns The last sign out reason or undefined if the user has not signed out.
 */
export function getLastSignOutReason(): SignOutReason | undefined {
    return lastSignOutReason;
}

/**
 * Retrieves the current user if authenticated.
 * @returns A promise to the @see User or null.
 */
export async function getCurrentUser(environment?: IEnvironment): Promise<User | null> {
    try {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        if (!accessToken) {
            return null;
        }

        const userAttributes = await fetchUserAttributes();
        const email = userAttributes.email;
        const name = userAttributes.name || '';
        if (!email) {
            return null;
        }

        const groups = accessToken.payload['cognito:groups'] as string[];

        const currentUserPreferences = await fetchQuery<authUserPreferencesQuery>(
            environment ?? getGlobalEnvironment(),
            graphql`
                query authUserPreferencesQuery {
                    currentUser {
                        preferences {
                            timezone
                            units {
                                pressure
                                temperature
                                volume
                                volumetricFlowRate
                            }
                        }
                    }
                }
            `,
            {}
        ).toPromise();

        // NOTE: Any additional attributes should be captured by this interface
        return {
            username: email,
            name,
            timezone: currentUserPreferences!.currentUser.preferences.timezone ?? undefined,
            units: { ...PresetOptions[UnitPreset.Metric], ...currentUserPreferences!.currentUser.preferences.units },
            roles: groups,
        };
    } catch {
        return null;
    }
}

export function fetchCurrentUser(): SuspenseObject<User | null> {
    return new SuspenseObject(getCurrentUser());
}

/**
 * Retrieves the authentication token to pass to API requests
 */
export async function getAuthToken(): Promise<string | null> {
    try {
        const authTokens = (await fetchAuthSession()).tokens;

        if (authTokens) {
            return authTokens.accessToken.toString();
        } else {
            return null;
        }
    } catch (error) {
        if (error === 'No current user') {
            return null;
        }

        logError('Unhandled / unexpected error in getAuthToken', error);
        return null;
    }
}

/**
 * Retrieves the current user.
 * If the user is not authenticated, then no user is returned.
 */
export function useCurrentUser(): User | null {
    const context = useContext(AuthContext);
    return context.currentUser;
}

export type UserUnitPreferences = authUserPreferencesQuery['response']['currentUser']['preferences']['units'];
export type UserTimezonePreferences =
    | authUserPreferencesQuery['response']['currentUser']['preferences']['timezone']
    | null;

/**
 *
 * @returns The current user's timezone preference
 */
export function useCurrentUserTimezonePref(): UserTimezonePreferences {
    const context = useContext(AuthContext);
    return context.currentUser?.timezone ?? null;
}

/**
 * Replicate useCurrentUserTimezonePref but without the hooks
 * @returns A promise of the current user's timezone preference
 */
export async function getCurrentUserTimezonePref(): Promise<UserTimezonePreferences> {
    const user = await getCurrentUser();
    return user?.timezone ?? null;
}

/**
 *
 * @returns The current user's units preference
 */
export function useCurrentUserUnitsPref(): UserUnitPreferences {
    const context = useContext(AuthContext);

    if (!context.currentUser) {
        captureMessage('Assertion failed: currentUser should not be null', scope => {
            scope.setExtra('context', context);
            return scope;
        });
    }

    return context.currentUser!.units;
}

/**
 * Replicate useCurrentUserUnitsPref but without the hooks
 * @returns A promise of the current user's units preference
 */
export async function getCurrentUserUnitsPref(): Promise<UserUnitPreferences> {
    const user = await getCurrentUser();

    if (!user) {
        captureMessage('Assertion failed: user should not be null', scope => {
            scope.setExtra('user', user);
            return scope;
        });
    }

    return user!.units;
}

export function useInitialErrorMessage(): string | null {
    const context = useContext(AuthContext);
    return context.initialErrorMessage;
}

/**
 * Checks if the user has at least one of the specified roles.
 * Note: You should really use the useUserPermissions hook if you are trying to
 * see what the user can access.
 * @param roles The roles to check
 * @returns True if the user has at least one of those roles
 * @see useUserPermissions
 */
export function useUserAuthorization(...roles: AuthRole[]): boolean {
    const user = useCurrentUser();
    if (!user || user.roles === undefined) {
        return false;
    }

    return roles.some(role => user.roles!.includes(role));
}

export interface UserPermissions {
    hasGeneralRead: boolean;
    hasAssetsRead: boolean;
    hasAssetsWrite: boolean;
    hasTasksRead: boolean;
    hasTasksWrite: boolean;
    hasAdministration: boolean;
    hasAuditRead: boolean;
}

/**
 * Gets the users effective permissions.
 * This takes care of the detail about what roles produce what permissions.
 * @returns An object containing all user permissions levels
 */
export function useUserPermissions(): UserPermissions {
    const user = useCurrentUser();
    if (!user || user.roles === undefined) {
        return {
            hasAdministration: false,
            hasAssetsRead: false,
            hasAssetsWrite: false,
            hasGeneralRead: false,
            hasTasksRead: false,
            hasTasksWrite: false,
            hasAuditRead: false,
        };
    }

    const permissions: UserPermissions = {
        hasAdministration: user.roles.some(role => role === AuthRole.Administrator),
        hasAssetsRead: user.roles.some(role => role === AuthRole.AssetsReadOnly || role === AuthRole.AssetsReadWrite),
        hasAssetsWrite: user.roles.some(role => role === AuthRole.AssetsReadWrite),
        hasGeneralRead: user.roles.some(role => role === AuthRole.ReadOnly),
        hasTasksRead: user.roles.some(
            role => role === AuthRole.TasksBatteryHealthReadOnly || role === AuthRole.TasksBatteryHealthReadWrite
        ),
        hasTasksWrite: user.roles.some(role => role === AuthRole.TasksBatteryHealthReadWrite),
        hasAuditRead: user.roles.some(role => role === AuthRole.Auditor || role === AuthRole.Administrator), // NOTE: Administrator can also read audit logs
    };

    return permissions;
}

/**
 * Replicate useUserPermissions but without the hooks
 * @returns An promise object containing all user permissions levels
 */
export async function getGlobalPermissions(): Promise<UserPermissions> {
    const user = await getCurrentUser();

    if (!user || user.roles === undefined) {
        return {
            hasAdministration: false,
            hasAssetsRead: false,
            hasAssetsWrite: false,
            hasGeneralRead: false,
            hasTasksRead: false,
            hasTasksWrite: false,
            hasAuditRead: false,
        };
    }

    const permissions: UserPermissions = {
        hasAdministration: user.roles.some(role => role === AuthRole.Administrator),
        hasAssetsRead: user.roles.some(role => role === AuthRole.AssetsReadOnly || role === AuthRole.AssetsReadWrite),
        hasAssetsWrite: user.roles.some(role => role === AuthRole.AssetsReadWrite),
        hasGeneralRead: user.roles.some(role => role === AuthRole.ReadOnly),
        hasTasksRead: user.roles.some(
            role => role === AuthRole.TasksBatteryHealthReadOnly || role === AuthRole.TasksBatteryHealthReadWrite
        ),
        hasTasksWrite: user.roles.some(role => role === AuthRole.TasksBatteryHealthReadWrite),
        hasAuditRead: user.roles.some(role => role === AuthRole.Auditor || role === AuthRole.Administrator), // NOTE: Administrator can also read audit logs
    };

    return permissions;
}

export const UserChannel = 'user';

export enum UserChannelEvent {
    UnitPreferencesUpdated = 'unitPreferencesUpdated',
    TimezonePreferencesUpdated = 'timezonePreferencesUpdated',
}

export function dispatchUserEvent(event: UserChannelEvent): void {
    Hub.dispatch(UserChannel, { event });
}
