import { Dispatch } from 'react';

import { AttributeFilter, FilterAction, FilterState, FilterValueMap, RangeFilter, useFilterReducer } from '../common';
import { useDynamicFilters } from './dynamic';
import {
    DefaultExtraFilters,
    DefaultValues,
    DeviceColumnFilterMap,
    StaticDeviceFilterDefinitions,
    StaticTestDeviceFilterDefinitions,
    TestDeviceColumnFilterMap,
    TestDeviceDefaultExtraFilters,
    TestDeviceDefaultValues,
} from './settings';
import {
    DeviceBatteryDateFilter,
    DeviceBatteryStatusFilter,
    DeviceExtraFilters,
    DeviceSNMPVersionFilter,
    DeviceStatusFilter,
    DeviceTableColumnId,
    MonitorOnlyStatus,
    TestDeviceExtraFilters,
    TestDeviceTableColumn,
} from './types';

export type DeviceFilterState = FilterState<DeviceTableColumnId, DeviceColumnFilterMap>;
export type DeviceFilterAction = FilterAction<DeviceColumnFilterMap, DeviceTableColumnId>;

export type TestDeviceFilterState = FilterState<TestDeviceTableColumn, TestDeviceColumnFilterMap>;
export type TestDeviceFilterAction = FilterAction<TestDeviceColumnFilterMap, TestDeviceTableColumn>;

/**
 *
 * @param options.defaultFilters can be passed in to override the default values for the filters.
 * @param options.extraFilters can be passed in to override the default values for the extra filters.
 *
 */
export function useDeviceFilter(options?: {
    defaultFilters?: Partial<FilterValueMap<DeviceColumnFilterMap>>;
    extraFilters?: Partial<Record<DeviceExtraFilters, unknown>>;
}): [DeviceFilterState, Dispatch<DeviceFilterAction>] {
    const defaultFilters = {
        ...DefaultValues,
        ...(options?.defaultFilters ?? {}),
    } as FilterValueMap<DeviceColumnFilterMap>;

    const extraFilters = {
        ...DefaultExtraFilters,
        ...(options?.extraFilters ?? {}),
    };

    const dynamicFilterDefinitions = useDynamicFilters();

    const [state, updateState] = useFilterReducer<
        DeviceTableColumnId,
        DeviceColumnFilterMap,
        DeviceFilterState,
        DeviceFilterAction
    >('device-filter', defaultFilters, [...StaticDeviceFilterDefinitions, ...dynamicFilterDefinitions], extraFilters);

    return [state, updateState];
}

export function useScheduleTestDeviceFilter(): [TestDeviceFilterState, Dispatch<TestDeviceFilterAction>] {
    const [state, updateState] = useFilterReducer<
        TestDeviceTableColumn,
        TestDeviceColumnFilterMap,
        TestDeviceFilterState,
        TestDeviceFilterAction
    >('test-device-filter', TestDeviceDefaultValues, StaticTestDeviceFilterDefinitions, TestDeviceDefaultExtraFilters);

    return [state, updateState];
}

export function deviceToFilterObject(filters: DeviceFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};

    const {
        Name: name,
        Type: type,
        Site: site,
        State: state,
        SnmpVersion: snmpVersion,
        DeviceStatus: deviceStatus,
        BatteryStatus: batteryStatus,
        MonitorOnly: monitorOnly,
        BatteryStringCount: batteryStringCount,
        BatteryReserveTime: batteryReserveTime,
        BatteryStateOfHealth: batteryStateOfHealth,
        BatteryTemperature: batteryTemperature,
        BatteryCapacityRemaining: batteryCapacityRemaining,
        BatteryEnergyTotal: batteryEnergyTotal,
        ACReliability: acReliability,
        ACIncidentCount: acIncidentCount,
        ACOutageDurationSum: acOutageDurationSum,
        ACMttr: acMttr,
        ACMtbf: acMtbf,
    } = filters.columnValues;

    if (name.length > 0) {
        output.name = name.map(filter => ({ value: filter.name }));
    }
    if (type.length > 0) {
        output.type = type.map(filter => ({ value: filter.id }));
    }
    if (site.length > 0) {
        output.siteName = site.map(filter => ({ value: filter.name }));
    }
    if (state.length > 0) {
        output.siteState = state.map(filter => ({ value: filter.state }));
    }

    if (snmpVersion.length > 0) {
        output.snmpVersion = snmpVersion.map(version => ({ value: version.id }));
    }
    if (deviceStatus.length > 0) {
        output.health = deviceStatus.map(status => ({ value: status.id }));
    }
    if (batteryStatus.length > 0) {
        output.batteryState = batteryStatus.map(status => ({ value: status.id }));
    }
    if (monitorOnly) {
        output.monitorOnly = { value: monitorOnly === MonitorOnlyStatus.Yes };
    }
    if (batteryStringCount) {
        output.batteryStringCount = batteryStringCount;
    }
    if (batteryReserveTime) {
        output.batteryReserveTime = batteryReserveTime;
    }
    if (batteryStateOfHealth) {
        output.batteryStateOfHealth = batteryStateOfHealth;
    }
    if (batteryTemperature) {
        output.batteryTemperature = batteryTemperature;
    }
    if (batteryCapacityRemaining) {
        output.batteryCapacityRemaining = batteryCapacityRemaining;
    }
    if (batteryEnergyTotal) {
        output.batteryEnergyTotal = batteryEnergyTotal;
    }
    if (acReliability) {
        output.acReliabilityPercentile = acReliability;
    }
    if (acIncidentCount) {
        output.acIncidentCount = acIncidentCount;
    }
    if (acOutageDurationSum) {
        output.acOutageDurationSum = acOutageDurationSum.range;
    }
    if (acMttr) {
        output.acMttr = acMttr.range;
    }
    if (acMtbf) {
        output.acMtbf = acMtbf.range;
    }

    let attributeFilters: unknown[] | undefined;

    for (const [definitionId, values] of Object.entries(filters.columnValues)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition || !values) {
            continue;
        }

        if (definition.category === 'Attribute') {
            const filterObjects = values as AttributeFilter[];
            if (filterObjects.length > 0) {
                if (!attributeFilters) {
                    attributeFilters = [];
                }
                attributeFilters.push({
                    name: definition.attributeName,
                    filters: filterObjects,
                });
            }
        }
    }

    if (attributeFilters && attributeFilters.length > 0) {
        output.attributes = attributeFilters;
    }

    const extraFilters = processDeviceExtraFilters(filters);

    return {
        ...output,
        ...extraFilters,
    };
}

export function scheduleTestDeviceToFilterObject(filters: TestDeviceFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};

    const { Site: site, State: state, Name: name, LastTested: lastTestedDate } = filters.columnValues;

    if (name.length > 0) {
        output.name = name.map(filter => ({ value: filter.name }));
    }
    if (site.length > 0) {
        output.siteName = site.map(filter => ({ value: filter.name }));
    }
    if (state.length > 0) {
        output.siteState = state.map(filter => ({ value: filter.state }));
    }
    if (lastTestedDate) {
        output.lastTestedDate = {
            min: lastTestedDate.range[0].toISOString(),
            max: lastTestedDate.range[1].toISOString(),
        };
    }

    const testDeviceExtraFilters = processTestDeviceExtraFilters(filters);

    return {
        ...output,
        ...testDeviceExtraFilters,
    };
}

function processDeviceExtraFilters(filters: DeviceFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};

    for (const [definitionId, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition || !values) {
            continue;
        }

        switch (definition.id) {
            case DeviceExtraFilters.BatteryCapacityTotal: {
                const batteryCapacityTotalRange = values as RangeFilter;
                output.batteryCapacityTotal = batteryCapacityTotalRange;
                break;
            }
            case DeviceExtraFilters.BatteryInstallDate: {
                const dateFilter = values as DeviceBatteryDateFilter;
                output.batteryInstallDate = {
                    min: dateFilter.range[0].toISOString(),
                    max: dateFilter.range[1].toISOString(),
                };
                break;
            }
            case DeviceExtraFilters.BatteryManufactureDate: {
                const dateFilter = values as DeviceBatteryDateFilter;
                output.batteryManufactureDate = {
                    min: dateFilter.range[0].toISOString(),
                    max: dateFilter.range[1].toISOString(),
                };
                break;
            }
        }
    }

    return output;
}

function processTestDeviceExtraFilters(filters: TestDeviceFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};

    let attributeFilters: unknown[] | undefined;

    // FIXME: Some of these overlap with the DeviceExtraFilters, they are duplicated. Room to refactor
    for (const [definitionId, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition || !values) {
            continue;
        }

        switch (definition.id) {
            case TestDeviceExtraFilters.BatteryCapacityTotal: {
                const batteryCapacityTotalRange = values as RangeFilter;
                output.batteryCapacityTotal = batteryCapacityTotalRange;
                break;
            }
            case TestDeviceExtraFilters.BatteryInstallDate: {
                const dateFilter = values as DeviceBatteryDateFilter;
                output.batteryInstallDate = {
                    min: dateFilter.range[0].toISOString(),
                    max: dateFilter.range[1].toISOString(),
                };
                break;
            }
            case TestDeviceExtraFilters.BatteryManufactureDate: {
                const dateFilter = values as DeviceBatteryDateFilter;
                output.batteryManufactureDate = {
                    min: dateFilter.range[0].toISOString(),
                    max: dateFilter.range[1].toISOString(),
                };
                break;
            }
            case TestDeviceExtraFilters.BatteryStringCount: {
                const batteryStringRange = values as RangeFilter;
                output.batteryStringCount = batteryStringRange;
                break;
            }
            case TestDeviceExtraFilters.BatteryReserveTime: {
                const batteryReserveTimeRange = values as RangeFilter;
                output.batteryReserveTime = batteryReserveTimeRange;
                break;
            }
            case TestDeviceExtraFilters.BatteryStateOfHealth: {
                const batteryStateOfHealthRange = values as RangeFilter;
                output.batteryStateOfHealth = batteryStateOfHealthRange;
                break;
            }
            case TestDeviceExtraFilters.BatteryTemperature: {
                const batteryTemperatureRange = values as RangeFilter;
                output.batteryTemperature = batteryTemperatureRange;
                break;
            }
            case TestDeviceExtraFilters.BatteryCapacityRemaining: {
                const batteryCapacityRemainingRange = values as RangeFilter;
                output.batteryCapacityRemaining = batteryCapacityRemainingRange;
                break;
            }
            case TestDeviceExtraFilters.BatteryEnergyTotal: {
                const batteryEnergyTotalRange = values as RangeFilter;
                output.batteryEnergyTotal = batteryEnergyTotalRange;
                break;
            }
            case TestDeviceExtraFilters.SnmpVersion: {
                if ((values as DeviceSNMPVersionFilter[]).length > 0) {
                    const snmpVersions = values as DeviceSNMPVersionFilter[];
                    output.snmpVersion = snmpVersions.map(version => ({ value: version.id }));
                }
                break;
            }
            case TestDeviceExtraFilters.DeviceStatus: {
                if ((values as DeviceStatusFilter[]).length > 0) {
                    const statuses = values as DeviceStatusFilter[];
                    output.health = statuses.map(status => ({ value: status.id }));
                }
                break;
            }
            case TestDeviceExtraFilters.BatteryStatus: {
                if ((values as DeviceBatteryStatusFilter[]).length > 0) {
                    const statuses = values as DeviceBatteryStatusFilter[];
                    output.batteryState = statuses.map(status => ({ value: status.id }));
                }
                break;
            }
        }

        if (definition.category === 'Attribute') {
            const filterObjects = values as AttributeFilter[];
            if (filterObjects.length > 0) {
                if (!attributeFilters) {
                    attributeFilters = [];
                }
                attributeFilters.push({
                    name: definition.attributeName,
                    filters: filterObjects,
                });
            }
        }
    }

    if (attributeFilters && attributeFilters.length > 0) {
        output.attributes = attributeFilters;
    }

    return output;
}
