/**
 * @generated SignedSource<<d5b9ca9cd368ed8d7122a59be2bdda86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProvisioningStatus = "Active" | "Deleted" | "%future added value";
export type breadcrumbsBatteryTypeQuery$variables = {
  id: string;
};
export type breadcrumbsBatteryTypeQuery$data = {
  readonly batteryType: {
    readonly manufacturer: string;
    readonly model: string;
    readonly provisioningStatus: ProvisioningStatus;
  } | null;
};
export type breadcrumbsBatteryTypeQuery = {
  response: breadcrumbsBatteryTypeQuery$data;
  variables: breadcrumbsBatteryTypeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provisioningStatus",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manufacturer",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "model",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "breadcrumbsBatteryTypeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BatteryType",
        "kind": "LinkedField",
        "name": "batteryType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "breadcrumbsBatteryTypeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BatteryType",
        "kind": "LinkedField",
        "name": "batteryType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b5e4a356395607b5229017eda663c39f",
    "id": null,
    "metadata": {},
    "name": "breadcrumbsBatteryTypeQuery",
    "operationKind": "query",
    "text": "query breadcrumbsBatteryTypeQuery(\n  $id: ID!\n) {\n  batteryType(id: $id) {\n    provisioningStatus\n    manufacturer\n    model\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "99ace78e267248f91a3a2b34fc212a3a";

export default node;
