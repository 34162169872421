import React, { FC, useCallback, useMemo, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { ArrowDownIcon, CrossIcon, Modal, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { SiteExtraFilters, SiteTableColumn, SiteTableColumnId, siteToFilterObject, useSiteFilter } from 'filters/site';
import { SiteColumnFilterMap } from 'filters/site/settings';
import { TableActionType, TableLayout, useTableReducer } from 'layouts';
import { Paths } from 'lib/routes';
import { ExportForm } from 'views/exporting/components/ExportForm';
import { DefaultTimeRange, TimeRange } from 'views/reports/ac-power/settings';

import { useSiteColumnDefinitions } from '../../../../lib/tables/site/columns';
import { useSiteTableQuery } from '../../../../lib/tables/site/request';
import { SiteDevice, SiteWithDevices } from '../../../../lib/tables/site/type';
import { ReportSearchResults, SearchGroups, renderSearchResult } from '../search';
import { BatterySiteListAllQuery } from './__generated__/BatterySiteListAllQuery.graphql';
import { BatterySiteListSearchQuery } from './__generated__/BatterySiteListSearchQuery.graphql';
import { AllTableColumns, BaseTableColumns } from './settings';

const TableStorageKeyPrefix = 'battery-by-site-table';

export const BatterySiteList: FC = () => {
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [enableExport, setEnableExport] = useState(false);

    const environment = useRelayEnvironment();
    const navigate = useExtendedNavigate();

    useDocumentTitle('Reports - Batteries by site');

    const columnDefinitions = useSiteColumnDefinitions({
        columns: AllTableColumns,
    });

    const [tableState, dispatchTableState] = useTableReducer<SiteTableColumnId>({
        defaultSortColumn: SiteTableColumn.Name,
        allColumns: columnDefinitions,
        defaultVisibleColumns: BaseTableColumns,
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useSiteFilter({
        extraFilters: {
            [SiteExtraFilters.ACReliabilityTimeRange]: DefaultTimeRange,
        },
    });
    const filterObject = useMemo(() => siteToFilterObject(filters), [filters]);

    const acReliabilityTimeRange: TimeRange =
        (filters.extraFilters[SiteExtraFilters.ACReliabilityTimeRange] as TimeRange | undefined) ?? DefaultTimeRange;

    const { data: props, error, retry, isFetching } = useSiteTableQuery(tableState, filters);

    const handleRequestAllDeviceIds = useCallback(() => {
        return fetchQuery<BatterySiteListAllQuery>(
            environment,
            graphql`
                query BatterySiteListAllQuery($filters: SiteFilter, $acTimeRange: ACTimeRange!) {
                    sites(
                        onlyProvisioningStatuses: Active
                        onlyWithValidBatteries: true
                        pageSize: 10000
                        filters: $filters
                        acReliabilityTimeRange: $acTimeRange
                    ) {
                        data {
                            devices(filters: { category: [PowerController] }) {
                                data {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            { filters: filterObject, acTimeRange: acReliabilityTimeRange },
            { fetchPolicy: 'network-only' }
        )
            .toPromise()
            .then(data =>
                (data?.sites.data ?? [])
                    .map(site => site.devices)
                    .flatMap(devices => devices.data.map(device => device.id))
            );
    }, [acReliabilityTimeRange, environment, filterObject]);

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<BatterySiteListSearchQuery>(
                environment,
                graphql`
                    query BatterySiteListSearchQuery($search: String!, $pageSize: Int!) {
                        sites(
                            search: $search
                            pageSize: $pageSize
                            orderBy: { field: Name, dir: Asc }
                            onlyProvisioningStatuses: Active
                        ) {
                            data {
                                id
                                name
                                address {
                                    state
                                }
                            }
                        }
                        devices(
                            search: $search
                            pageSize: $pageSize
                            orderBy: { field: Name, dir: Asc }
                            filters: { category: [PowerController] }
                        ) {
                            data {
                                id
                                name
                                site {
                                    id
                                    name
                                    address {
                                        state
                                    }
                                }
                            }
                        }
                    }
                `,
                { search: input, pageSize: 10 }
            )
                .toPromise()
                .then(results => {
                    const deviceResults: ReportSearchResults[] =
                        results?.devices.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.site.id,
                            siteName: result.site.name,
                            state: result.site.address.state,
                            type: 'device',
                        })) ?? [];

                    const siteResults: ReportSearchResults[] =
                        results?.sites.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.id,
                            siteName: result.name,
                            state: result.address.state,
                            type: 'site',
                        })) ?? [];

                    return deviceResults.concat(siteResults);
                });
        },
        [environment]
    );

    const handleSearchResultClick = useCallback(
        (item: ReportSearchResults) => {
            let url = generatePath(Paths.ReportBatteriesViewSite, { siteId: item.site });
            if (item.type === 'device') {
                url = generatePath(Paths.ReportBatteriesViewSiteDevice, { siteId: item.site, deviceId: item.id });
            }

            navigate(url);
        },
        [navigate]
    );

    return (
        <>
            <TableLayout<SiteTableColumnId, SiteWithDevices, SiteDevice, ReportSearchResults, SiteColumnFilterMap>
                title='Batteries by site'
                columns={columnDefinitions}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={props?.sites.data ?? null}
                isProcessing={!!props && isFetching}
                getRowId={(row: SiteWithDevices | SiteDevice) => row.id}
                getSubRows={row => [
                    ...(row?.devices?.data.filter(device => device.type.category === 'PowerController') ?? []),
                ]}
                page={props?.sites.pageInfo.page}
                pageCount={props?.sites.pageInfo.total}
                overallCount={props?.overallSites.total}
                resultCount={props?.sites.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Site/Device'
                onSearch={handleSearch}
                onSearchResultClick={handleSearchResultClick}
                renderSearchResult={renderSearchResult}
                renderSearchResultAsString={(item: ReportSearchResults) => item.name}
                emptyMessage='There are no matching sites'
                unit='Site'
                selection={enableExport}
                selectionFooterActions={[
                    {
                        buttonText: 'Export selected',
                        onClick: () => setIsExportModalOpen(true),
                        disabled: tableState.selectedItems.length === 0,
                        disabledMessage: 'Please select at least one device to export',
                    },
                ]}
                allowFooterZeroCount
                onRequestAllIds={handleRequestAllDeviceIds}
                selectionFooterSelectedItems={tableState.selectedItems}
                selectionFooterUnitOverride={'Device'}
                selectionFooterUnitPluralOverride={'Devices'}
                emptySubRowsMessage='There are no relevant devices on this site'
                additionalActions={[
                    {
                        buttonText: enableExport ? 'Back' : 'Export',
                        buttonIcon: enableExport ? <CrossIcon /> : <ArrowDownIcon />,
                        onClick: () => {
                            dispatchTableState({
                                type: TableActionType.SetSelection,
                                selection: [],
                            });
                            setEnableExport(prev => !prev);
                        },
                    },
                ]}
                getItemLink={row => {
                    if (!('site' in row)) {
                        return '';
                    }

                    return generatePath(Paths.ReportBatteriesViewSiteDevice, { siteId: row.site.id, deviceId: row.id });
                }}
                searchBoxProperties={{
                    groups: SearchGroups,
                    groupKey: 'type',
                }}
            />
            <Modal
                bgColor='bg-white'
                open={isExportModalOpen}
                onHide={() => setIsExportModalOpen(false)}
                closeButton={true}
            >
                <ExportForm
                    deviceIds={tableState.selectedItems}
                    basicMetrics={['BatteryVoltage', 'BatteryCurrent', 'BatteryTemperature', 'BatteryPower']}
                />
            </Modal>
        </>
    );
};
