import React, { FC } from 'react';

import {
    FooterAction, // eslint-disable-next-line no-restricted-imports
    SelectionFooter as SelectionFooterBase,
} from '@accesstel/pcm-ui';

import style from './style.module.css';

export interface SelectionFooterProps {
    selectedCount: number;
    unitSingular: string;
    unitPlural: string;
    allowShowOnZeroCount?: boolean;
    actions: FooterAction[];
}

/**
 * This component is a wrapper around the SelectionFooter component from pcm-ui.
 * It handles the logic of when to show the footer, the positioning and the structure of the message so that it is consistent across the application.
 */
export const SelectionFooter: FC<SelectionFooterProps> = ({
    selectedCount,
    unitSingular,
    unitPlural,
    allowShowOnZeroCount = false,
    actions,
}) => {
    let suffix: string;
    if (selectedCount !== 1) {
        suffix = unitPlural;
    } else {
        suffix = unitSingular;
    }

    const genericDisableButtonMessage = `No ${suffix} selected`;

    if (selectedCount !== 0 || allowShowOnZeroCount) {
        return (
            <div className={style.fixed_footer}>
                <SelectionFooterBase
                    message={`${selectedCount} ${suffix} Selected`}
                    actions={actions.map(action => ({
                        ...action,
                        buttonVariant: action.buttonVariant ?? 'secondary',
                        disabledMessage: action.disabledMessage ?? genericDisableButtonMessage,
                    }))}
                />
            </div>
        );
    } else {
        return <></>;
    }
};
