import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { AttributeFilter, AttributeFilterUI, FilterDefinition } from 'filters/common';

import { dynamic_loadDeviceAttributeNamesQuery } from './__generated__/dynamic_loadDeviceAttributeNamesQuery.graphql';
import { DeviceTableColumnId } from './types';

export function useDynamicFilters(): FilterDefinition<DeviceTableColumnId>[] {
    const data = useLazyLoadQuery<dynamic_loadDeviceAttributeNamesQuery>(
        graphql`
            query dynamic_loadDeviceAttributeNamesQuery {
                attributeNames(search: "", only: Device, limit: 1000, status: Active)
            }
        `,
        {},
        {
            fetchPolicy: 'store-or-network',
        }
    );

    return (
        data?.attributeNames.map(name => ({
            id: `tag_${name}`,
            column: `tag_${name}`,
            type: 'multi',
            name: `Tag ${name}`,
            category: 'Attribute',
            attributeName: name,
            component: () => props => <AttributeFilterUI {...props} attribute={name} type='Device' />,
            describeValue: (value: AttributeFilter) => value.value,
            encodeValue: () => '', // NO-OP
            decodeValue: () => null, // NO-OP
        })) ?? []
    );
}
