import React, { FC } from 'react';

import { Toggle, Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';
import { numberToLocaleString } from 'lib/numberFormatters';

import { checkIfSelected } from '../../single-plane';
import { BlocId } from '../common';
import { BlocHealthResults } from './BlocHealthTable';
import { BatteryTestState } from './__generated__/BlocHealthTable_test.graphql';
import style from './style.module.css';

interface TableBodyProps {
    selectedBlocs: BlocId[];
    toggleBloc: (selectedBloc: BlocId) => void;
    blocData: BlocHealthResults;
}

interface ProcessesBlocData {
    bloc: number;
    string: number;
    stateOfHealth: number | null;
    state: BatteryTestState;
}

export const TableBody: FC<TableBodyProps> = ({ selectedBlocs, toggleBloc, blocData }) => {
    const processesData = blocData.reduce((blocsSoFar: Record<string, ProcessesBlocData[]>, bloc) => {
        const key = bloc.string;

        if (!blocsSoFar[key]) {
            blocsSoFar[bloc.string] = [];
        }
        blocsSoFar[key].push(bloc);
        return blocsSoFar;
    }, {});

    return (
        <>
            {Object.entries(processesData).map(([string, element]) => {
                return (
                    <tr key={`table-row-${string}`}>
                        <td className={style.table_cell_bold}>String {string}</td>
                        {element.map(blocData => getTableCell(blocData, selectedBlocs, toggleBloc))}
                    </tr>
                );
            })}
        </>
    );
};

function getTableCell(
    blocData: ProcessesBlocData,
    selectedBlocs: BlocId[],
    toggleBloc: (selectedBloc: BlocId) => void
): React.ReactNode {
    const currentStringBloc: BlocId = [blocData.string, blocData.bloc];

    const onChange = () => {
        toggleBloc(currentStringBloc);
    };

    let textColor: string;
    switch (blocData.state) {
        case 'Failed':
            textColor = 'text-coralRegular';
            break;
        default:
            textColor = 'text-eggplantRegular';
    }

    return (
        <td key={`table-cell-${blocData.string}-${blocData.bloc}`}>
            <div className={classNames(textColor, style.table_data)} data-testid='table-data-cell'>
                {blocData.stateOfHealth ? (
                    <div>{numberToLocaleString(blocData.stateOfHealth, 0)}%</div>
                ) : (
                    <Tooltip content='Inconclusive'>
                        <div>Inc.</div>
                    </Tooltip>
                )}
                <Toggle value={checkIfSelected(currentStringBloc, selectedBlocs)} onChange={onChange} />
            </div>
        </td>
    );
}
