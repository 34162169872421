import React, { Dispatch, FC, useEffect, useMemo } from 'react';

import { useFormikContext } from 'formik';

import { DualPlaneCompanion } from './components';
import { Integration } from './components/Integration';
import { DeviceType } from './lib/device-types';
import { FormControlAction, FormControlParameters } from './lib/reducer';
import { DeviceFormValues } from './schema';
import { DualPlaneConfiguration } from './sections/DualPlaneConfiguration';
import { GeneralInformation } from './sections/GeneralInformation';
import { MonitorOnlyMode } from './sub-forms/MonitorOnlyMode';
import { DeviceBatteries } from './sub-forms/battery-settings';
import { ConnectivitySettings } from './sub-forms/connectivity-settings/ConnectivitySettings';
import { prepopulateProtocolSettings } from './sub-forms/connectivity-settings/schema';

export interface DeviceInformationProps {
    deviceTypes: DeviceType[];
    editingDeviceId?: string;

    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const DeviceInformation: FC<DeviceInformationProps> = ({
    deviceTypes,
    editingDeviceId,

    formControlState,
    formControlDispatch,
}) => {
    const { values, setValues } = useFormikContext<DeviceFormValues>();

    const deviceType = useMemo(
        () => deviceTypes.find(deviceType => deviceType.id === values.type),
        [deviceTypes, values.type]
    );

    // Auto populate default protocol settings based on device type
    useEffect(() => {
        if (!deviceType) {
            return;
        }

        prepopulateProtocolSettings(values.settings, deviceType);
        prepopulateProtocolSettings(values.newCompanion.settings, deviceType);

        setValues(values);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceType]);

    const displayedSections = {
        optimaIntegration: false,
        monitorOnlyMode: false,
        deviceBatteries: false,
        dualPlaneConfiguration: false,
    };

    switch (deviceType?.category) {
        case 'PowerController':
            displayedSections.optimaIntegration = true;
            displayedSections.monitorOnlyMode = true;
            displayedSections.deviceBatteries = true;
            displayedSections.dualPlaneConfiguration = true;
            break;
        case 'Generator':
        case 'Gateway':
            break;
    }

    return (
        <div className='space-y-6'>
            <GeneralInformation formControlState={formControlState} formControlDispatch={formControlDispatch} />

            {deviceType != null &&
                (deviceType.connectivity.ipEnabled || deviceType.connectivity.protocols.length > 0) && (
                    <ConnectivitySettings deviceType={deviceType} namespace='settings' />
                )}

            {deviceType != null && displayedSections.optimaIntegration && <Integration namespace='optimaIntegration' />}

            {deviceType != null && displayedSections.monitorOnlyMode && <MonitorOnlyMode />}

            {deviceType != null && displayedSections.deviceBatteries && (
                <DeviceBatteries
                    namespace='batteries'
                    formControlState={formControlState}
                    formControlDispatch={formControlDispatch}
                />
            )}

            {deviceType != null && displayedSections.dualPlaneConfiguration && (
                <DualPlaneConfiguration
                    editingDeviceId={editingDeviceId}
                    formControlState={formControlState}
                    formControlDispatch={formControlDispatch}
                />
            )}

            {formControlState.addNewDualPlaneCompanionDevice && (
                <DualPlaneCompanion
                    namespace='newCompanion'
                    deviceType={deviceType}
                    planeAMonitorOnlyModeState={values.monitorOnly}
                />
            )}

            {formControlState.addNewDualPlaneCompanionDevice && (
                <DeviceBatteries
                    namespace='newCompanion.batteries'
                    formControlState={formControlState}
                    formControlDispatch={formControlDispatch}
                    companionDevice={true}
                />
            )}
        </div>
    );
};
