import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import {
    HistoryItem,
    History as HistoryLine,
    InsightCount,
    LoadIcon,
    Menu,
    MetricTile,
    MetricTileProps,
    PowerIcon,
    RectifierIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useUserPermissions } from 'lib/auth';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { createHistoryItem } from 'lib/history';
import { Paths } from 'lib/routes';
import { Operation } from 'views/explore/types';

import { makeLinkToMetric } from '../../../../explore/lib/link';
import { batteryStatusToColor, batteryStatusToIcon } from '../../lib';
import { DeviceOverview_device$key } from './__generated__/DeviceOverview_device.graphql';
import { DeviceHealth } from './__generated__/DeviceOverview_device.graphql';

export interface MetricInfo {
    metricValue?: number;
    metricUnit?: string;
}

export interface DeviceOverviewProps {
    device: DeviceOverview_device$key;
    deviceId: string;
}

/**
 * Only used to represent Device of type 'PowerController'
 */
export const DeviceOverview: FC<DeviceOverviewProps> = ({ device, deviceId }) => {
    const { hasAssetsWrite, hasAssetsRead, hasTasksRead } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(Fragment, device);

    let systemType: string;

    if (data.dualPlaneCompanion?.device) {
        systemType = 'Dual plane';
    } else {
        systemType = 'Single plane';
    }

    const historyItems: HistoryItem[] = data.activityLogs.data.map(log =>
        createHistoryItem(log, hasAssetsRead, hasTasksRead)
    );

    const navigateToHistoryPage = () => {
        navigate({
            pathname: Paths.SiteViewViewSiteDevicePage,
            params: {
                siteId: data.site.id,
                deviceId,
                page: 'history',
            },
        });
    };

    return (
        <div>
            <div className='grid grid-cols-2 gap-2 '>
                <div className='mb-2'>
                    <div className='text-xs'>
                        <div data-testid='system_type'>
                            <span className='font-light'>System type: </span>
                            <span className='font-normal'>{systemType ?? '-'}</span>
                        </div>
                        <div data-testid='firmware_version'>
                            <span className='font-light'>Firmware version: </span>
                            <span className='font-normal'>{data.firmware?.name ?? '-'}</span>
                        </div>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className='float-right'>
                        <Menu
                            id={`device-menu-${deviceId}`}
                            menuItems={[
                                {
                                    name: hasAssetsWrite ? 'Edit device' : 'View device',
                                    disabled: !hasAssetsRead,
                                    onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                                },
                            ]}
                            variant='small'
                        />
                    </div>
                    <div className='text-xs'>
                        <div data-testid='serial_number'>
                            <span className='font-light'>Serial number: </span>
                            <span className='font-normal'>{data.serialNumber || '-'}</span>
                        </div>
                    </div>
                </div>
                <MetricTile
                    // header
                    icon={<PowerIcon />}
                    title='AC Power'
                    link='../ac-power'
                    // subtitle
                    subtitle={data.health === 'Offline' ? 'Device offline' : 'Connected'}
                    subtitleColor={data.health === 'Offline' ? 'coral' : 'pine'}
                    // metric
                    {...getMetricInfo(
                        data.health === 'Offline' ? null : data.acPower.metrics.latestApparentPower,
                        'VA',
                        'kVA'
                    )}
                    metricLabel='Apparent power'
                    metricLink={makeLinkToMetric(deviceId, {
                        metric: 'RectifierMainsApparentPower',
                        op: Operation.Average,
                    })}
                    // insights count
                    secondaryHeaderElement={<InsightCount count={data.acPowerAlerts.total} link='../insights' />}
                />
                <MetricTile
                    // header
                    icon={<RectifierIcon />}
                    title='Rectifiers'
                    link='../rectifiers'
                    // subtitle
                    {...getRectifierTileSubtitle(
                        data.rectifier?.totalCapacity,
                        data.rectifier?.freeCapacity,
                        data.rectifier?.capacityAtRiskWithHeadroom,
                        data.health
                    )}
                    // metric
                    {...getMetricInfo(
                        data.health === 'Offline' ? null : data.rectifier?.metrics.latestOutputPower,
                        'W',
                        'kW'
                    )}
                    metricLabel='Output power'
                    metricLink={makeLinkToMetric(deviceId, {
                        metric: 'RectifierPower',
                        op: Operation.Average,
                    })}
                    // insights count
                    secondaryHeaderElement={<InsightCount count={data.rectifierAlerts.total} link='../insights' />}
                />
                <MetricTile
                    // header
                    icon={batteryStatusToIcon(
                        data.health === 'Offline' ? 'Offline' : data.battery?.metrics?.latestStatus
                    )}
                    title='Batteries'
                    link='../batteries'
                    // subtitle
                    subtitle={
                        data.health === 'Offline'
                            ? 'Device offline'
                            : batteryStatusToString(data.battery?.metrics?.latestStatus)
                    }
                    subtitleColor={batteryStatusToColor(
                        data.health === 'Offline' ? 'Offline' : data.battery?.metrics?.latestStatus
                    )}
                    // metric
                    {...getMetricInfo(data.health === 'Offline' ? null : data.battery?.metrics.latestVoltage, 'V')}
                    metricLabel='System voltage'
                    metricLink={makeLinkToMetric(deviceId, {
                        metric: 'BatteryVoltage',
                        op: Operation.Average,
                    })}
                    // insights count
                    secondaryHeaderElement={<InsightCount count={data.batteryAlerts.total} link='../insights' />}
                />
                <MetricTile
                    // header
                    icon={<LoadIcon />}
                    title='Load'
                    link='../load'
                    // subtitle
                    subtitle={data.health === 'Offline' ? 'Device offline' : 'Connected'}
                    subtitleColor={data.health === 'Offline' ? 'coral' : 'pine'}
                    // metric
                    {...getMetricInfo(data.health === 'Offline' ? null : data.load.metrics.latestPower, 'W', 'kW')}
                    metricLabel='Consumed power'
                    metricLink={makeLinkToMetric(deviceId, {
                        metric: 'LoadPower',
                        op: Operation.Average,
                    })}
                    // insights count
                    secondaryHeaderElement={<InsightCount count={data.loadAlerts.total} link='../insights' />}
                />
            </div>
            <div className='pt-4'>
                <HistoryLine
                    title='History'
                    lineStyle='short'
                    itemGroups={[{ items: historyItems }]}
                    moreItems={data.activityLogs.hasMore}
                    loadMoreCallback={navigateToHistoryPage}
                />
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceOverview_device on Device {
        site {
            id
        }
        name
        lastUpdate
        health
        type {
            displayName
        }
        dualPlaneCompanion {
            device {
                name
            }
        }
        firmware {
            name
        }
        serialNumber
        acPower {
            metrics {
                latestApparentPower(unit: VoltAmp)
            }
        }
        battery {
            metrics {
                latestVoltage
                latestStatus
            }
        }
        load {
            metrics {
                latestPower(unit: Watt)
            }
        }
        rectifier {
            totalCapacity
            freeCapacity
            usedCapacity
            offlineCapacity
            capacityAtRiskWithHeadroom
            metrics {
                latestOutputPower(unit: Watt)
            }
        }
        acPowerAlerts: alerts(filters: { domain: { value: AcPower } }) {
            total
        }
        batteryAlerts: alerts(filters: { domain: { value: Battery } }) {
            total
        }
        loadAlerts: alerts(filters: { domain: { value: Load } }) {
            total
        }
        rectifierAlerts: alerts(filters: { domain: { value: Rectifier } }) {
            total
        }
        activityLogs(
            types: [
                DeviceAdd
                DeviceEdit
                AlertSeverityCritical
                AlertSeverityMajor
                GridEventStatusOffline
                GridEventStatusHigh
                BatteryTest
            ]
            limit: 5
        ) {
            hasMore
            total
            data {
                source
                type
                timestamp
                user {
                    username
                    name
                }
                changes {
                    field
                    oldValue
                    newValue
                }
                link {
                    __typename
                    ... on Device {
                        id
                        name
                    }
                    ... on Alert {
                        severity
                        category
                        message
                        isActive
                        device {
                            id
                            name
                        }
                    }
                    ... on ACPowerEvent {
                        worstStatus
                        duration
                        affectedAllFeeds
                        affectedFeeds {
                            id
                            status
                        }
                        device {
                            id
                            name
                        }
                    }
                    ... on DeviceBatteryTestResults {
                        id
                        commencedTime
                        state
                        task {
                            id
                            name
                            type
                            testState
                            abortedUser {
                                username
                                name
                            }
                        }
                        device {
                            id
                            name
                        }
                    }
                    ... on BatteryTest {
                        id
                        commencedTime
                        taskName: name
                    }
                }
            }
        }
    }
`;

export function getMetricInfo(value: number | null | undefined, unit: string, largerUnit?: string): MetricInfo {
    if (value == null) {
        return {
            metricValue: undefined,
            metricUnit: unit,
        };
    }

    if (largerUnit && value > 1000) {
        return {
            metricValue: value / 1000,
            metricUnit: largerUnit,
        };
    }

    return {
        metricValue: largerUnit ? Math.round(value) : value,
        metricUnit: unit,
    };
}

function getRectifierTileSubtitle(
    totalCapacity: number | undefined | null,
    freeCapacity: number | undefined | null,
    capacityAtRiskWithHeadroom: boolean | undefined | null,
    health: DeviceHealth | null
): {
    subtitle: string;
    subtitleColor: MetricTileProps['subtitleColor'];
} {
    if (!health || health === 'Offline') {
        return {
            subtitle: 'Device offline',
            subtitleColor: 'coral',
        };
    }

    if (totalCapacity == null) {
        return {
            subtitle: 'No data',
            subtitleColor: 'eggplant',
        };
    }

    const freeCapacityPercent = Math.round(((freeCapacity ?? 0) / totalCapacity) * 100);

    const subtitle = `${freeCapacityPercent}% free capacity`;
    let subtitleColor: MetricTileProps['subtitleColor'];

    if (capacityAtRiskWithHeadroom) {
        subtitleColor = 'coral';
    } else {
        subtitleColor = 'pine';
    }

    return {
        subtitle,
        subtitleColor,
    };
}
