import React, { FC, useCallback, useRef } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { PageHeading, useExtendedNavigate, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import { Formik, FormikHelpers } from 'formik';

import { useDocumentTitle } from '../../../../components';
import { FormikProvisionLayout } from '../../../../layouts/FormikProvisionLayout';
import { Paths } from '../../../../lib/routes';
import { GeneralSection } from './components/GeneralSection';
import { PermissionsSection } from './components/PermissionsSection';
import { addNewUser, decodeUserApiErrors } from './lib/saving';
import { UserFormValues, createDefaultUserFormValues } from './schema';
import { UserValidationSchema } from './validation';

export const AddUser: FC = () => {
    useDocumentTitle('Add User');

    const environment = useRelayEnvironment();
    const { show } = useToast();
    const navigate = useExtendedNavigate();
    const shouldReset = useRef(false);

    const handleAdd = useCallback(
        async (values: UserFormValues, { setErrors, setSubmitting, resetForm }: FormikHelpers<UserFormValues>) => {
            setSubmitting(true);

            try {
                await addNewUser(values, environment);

                show({
                    text: 'User succesfully added!',
                    variant: 'info',
                });

                if (shouldReset.current) {
                    shouldReset.current = false;
                    resetForm();
                } else {
                    navigate(Paths.AdministrationUsers);
                }
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeUserApiErrors(error);
                    setErrors(formErrors);

                    show({
                        text: 'Unable to add user. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        return scope;
                    });

                    show({
                        text: 'Unable to add user. Try again later',
                        variant: 'error',
                    });
                }
            }
        },
        [environment, navigate, show]
    );

    return (
        <div className='space-y-6'>
            <PageHeading value='Add User' />
            <Formik
                initialValues={createDefaultUserFormValues()}
                validationSchema={UserValidationSchema}
                onSubmit={handleAdd}
            >
                <FormikProvisionLayout
                    type='user'
                    operation='add'
                    secondaryAction={() => {
                        shouldReset.current = true;
                    }}
                >
                    <GeneralSection />
                    <PermissionsSection />
                </FormikProvisionLayout>
            </Formik>
        </div>
    );
};
