import { DependencyList, useEffect, useRef, useState } from 'react';
import { RouteObject } from 'react-router-dom';

import { isAllowedAccess } from 'components/AuthenticatedSection';
import { Routes } from 'views/route';

import { useUserPermissions } from './auth';
import { logError } from './log';
import { Permissions } from './route-helpers';

type AsyncEffectCallback = () => Promise<void>;

export function usePrevious<T>(value: T): T {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref: any = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export function useAsyncEffect(effect: AsyncEffectCallback, deps?: DependencyList): void {
    useEffect(() => {
        effect().catch(error => {
            logError('async effect failed', error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

export const ACTION_KEY_DEFAULT = 'Ctrl'; // control key
export const ACTION_KEY_APPLE = '⌘'; // command key;

export function useActionKey() {
    const [actionKey, setActionKey] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (typeof navigator !== 'undefined') {
            if (/Mac|iPhone|iPod|iPad/.test(navigator.userAgent)) {
                setActionKey(ACTION_KEY_APPLE);
            } else {
                setActionKey(ACTION_KEY_DEFAULT);
            }
        }
    }, []);

    return actionKey;
}

export function useScrollLock(enabled: boolean) {
    useEffect(() => {
        if (!enabled) {
            return;
        }

        const previous = document.documentElement.style.overflow;
        document.documentElement.style.overflow = 'hidden';
        return () => {
            document.documentElement.style.overflow = previous;
        };
    }, [enabled]);
}

export function useOutsideClick(
    enabled: boolean,
    elementRef: React.MutableRefObject<HTMLElement | null>,
    callback: () => void
) {
    useEffect(() => {
        if (!enabled) {
            return;
        }

        const element = elementRef.current;
        if (!element) {
            return;
        }

        function handle(e: MouseEvent) {
            if (!element?.contains(e.target as Node)) {
                callback();
            }
        }

        document.addEventListener('click', handle);

        return () => {
            document.removeEventListener('click', handle);
        };
    }, [enabled, elementRef, callback]);
}

export interface ReadableRoute {
    title: string;
    path: string;
    permissions?: Permissions;
    description?: string;
}

/**
 * Return a list of routes filtered based on the user's permissions.
 * These routes are only for static routes.
 */
export function useReadableRoutes() {
    const paths: ReadableRoute[] = [];
    const userPermissions = useUserPermissions();

    const extractPaths = (routes: RouteObject[], parentRoute?: string, parentPermissions?: Permissions) => {
        const pathSet = new Set<string>();
        for (const route of routes) {
            if (route.path && route.handle?.title) {
                const title = route.handle.title;

                if (typeof title === 'function') {
                    continue;
                }

                if (route.path.includes(':')) {
                    continue;
                }

                const routePermissions: Permissions | undefined = route.handle.permissions ?? parentPermissions;

                if (routePermissions && !isAllowedAccess(routePermissions, userPermissions)) {
                    continue;
                }

                const fullPath = joinPaths(parentRoute, route.path);

                if (route.handle.altTitle) {
                    const readableRoute: ReadableRoute = {
                        title: route.handle.altTitle,
                        path: fullPath,
                        permissions: routePermissions,
                        description: route.handle.description,
                    };

                    if (pathSet.has(readableRoute.path)) {
                        continue;
                    }

                    paths.push(readableRoute);
                    pathSet.add(readableRoute.path);
                }

                if (route.children) {
                    extractPaths(route.children, fullPath, routePermissions);
                }
            }
        }
    };

    const rootRoute = Routes.find(route => route.path === '/');
    if (rootRoute) {
        extractPaths([rootRoute]);
    }

    const sortedPaths = paths.sort((a, b) => a.path.localeCompare(b.path));
    return sortedPaths;
}

/**
 * Utility function to join paths without duplicated slashes and ensure the path starts with a `/`.
 */
const joinPaths = (parentRoute: string | undefined, routePath: string): string => {
    let fullPath = parentRoute ? `${parentRoute}/${routePath}` : routePath;
    fullPath = fullPath.replace(/\/+/g, '/');
    if (!fullPath.startsWith('/')) {
        fullPath = `/${fullPath}`;
    }
    return fullPath;
};
