import { Dispatch } from 'react';

import { FilterAction, FilterState, useFilterReducer } from 'filters/common';
import { Duration } from 'luxon';

import { DefaultValues, StaticTestResultFilterDefinitions, TestResultColumnFilterMap } from './settings';
import { TestResultTableColumn } from './types';

export type TestResultFilterState = FilterState<TestResultTableColumn, TestResultColumnFilterMap>;
export type TestResultFilterAction = FilterAction<TestResultColumnFilterMap, TestResultTableColumn>;

export function useTestResultFilter(): [TestResultFilterState, Dispatch<TestResultFilterAction>] {
    const [state, updateState] = useFilterReducer<
        TestResultTableColumn,
        TestResultColumnFilterMap,
        TestResultFilterState,
        TestResultFilterAction
    >('test-result-filter', DefaultValues, StaticTestResultFilterDefinitions, {});

    return [state, updateState];
}

export function testResultToFilterObject(filters: TestResultFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};

    const {
        DeviceName: devices,
        State: statuses,
        RunTime: runTime,
        StartTime: startTime,
        Cause: causes,
        FailReason: failReason,
        BatteryStringCount: batteryStringCount,
        AverageCurrent: averageCurrent,
        AverageTemperature: averageTemperature,
        TotalDischarged: totalDischarged,
        EndVoltage: endVoltage,
        EstimatedStateOfHealth: estimatedStateOfHealth,
        EstimatedCapacity: estimatedCapacity,
        EstimatedReserveTime: estimatedReserveTime,
    } = filters.columnValues;

    if (devices.length > 0) {
        output.deviceName = devices.map(filter => ({ value: filter.name }));
    }

    if (statuses.length > 0) {
        output.state = statuses.map(filter => filter.id);
    }

    if (runTime) {
        output.runTime = {
            min: runTime.range[0],
            max: runTime.range[1],
        };
    }

    if (startTime) {
        output.startTime = {
            min: startTime.range[0].toISOString(),
            max: startTime.range[1].toISOString(),
        };
    }

    if (causes.length > 0) {
        output.cause = causes.map(filter => filter.id);
    }

    if (failReason.length > 0) {
        output.failReason = failReason.map(status => status.id);
    }

    if (batteryStringCount) {
        output.batteryStringCount = batteryStringCount;
    }

    if (endVoltage) {
        output.voltageMetrics = endVoltage;
    }

    if (totalDischarged) {
        output.dischargedMetrics = totalDischarged;
    }

    if (averageCurrent) {
        output.currentMetrics = averageCurrent;
    }

    if (averageTemperature) {
        output.temperatureMetrics = averageTemperature;
    }

    if (estimatedStateOfHealth) {
        output.estimatedStateOfHealth = estimatedStateOfHealth;
    }

    if (estimatedCapacity) {
        output.estimatedCapacity = estimatedCapacity;
    }

    if (estimatedReserveTime) {
        // As a range of minutes
        output.estimatedReserveTime = {
            min: Duration.fromISO(estimatedReserveTime.range[0]).as('minutes'),
            max: Duration.fromISO(estimatedReserveTime.range[1]).as('minutes'),
        };
    }

    for (const [definitionId] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition) {
            continue;
        }
        // TODO: Extra filters yet to be handled
    }

    return output;
}
