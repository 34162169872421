import React, { FC } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { CommonFilterProps } from 'filters/common';
import { DeviceNameFilterResult } from 'filters/device/components/DeviceNameFilterResult';
import { IEnvironment } from 'relay-runtime';

import { GeneratorRunReportDeviceNameFilter } from '../types';
import { GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery } from './__generated__/GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery.graphql';

export type GeneratorReportDeviceNameFilterUIProps = CommonFilterProps<GeneratorRunReportDeviceNameFilter[]>;

export const GeneratorReportDeviceNameFilterUI: FC<GeneratorReportDeviceNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by device'
            placeholder='Enter device name'
            renderItem={item => <DeviceNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

async function doSearch(
    environment: IEnvironment,
    input: string,
    maxResults: number
): Promise<GeneratorRunReportDeviceNameFilter[]> {
    try {
        const results = await fetchQuery<GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery>(
            environment,
            graphql`
                query GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery($input: String!, $maxResults: Int!) {
                    generatorRunReports(filters: { deviceName: { value: $input } }, pageSize: $maxResults) {
                        data {
                            id
                            generator {
                                id
                                name
                                site {
                                    id
                                    name
                                    address {
                                        state
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const reports = results?.generatorRunReports.data ?? [];

        const output: GeneratorRunReportDeviceNameFilter[] = [];
        reports.forEach(alert => {
            const device: GeneratorRunReportDeviceNameFilter = {
                type: 'result',
                id: alert.generator.id,
                name: alert.generator.name,
                siteName: alert.generator.site.name,
                state: alert.generator.site.address.state,
            };

            output.push(device);
        });

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        const uniqueDevices = output.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));

        return uniqueDevices;
    } catch {
        return [];
    }
}

function createWildcardFilter(input: string): GeneratorRunReportDeviceNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
