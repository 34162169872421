import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { InfoTip } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { formatMinutesAsHoursAndMinutes } from 'lib/dateFormatter';
import { numberToLocaleString } from 'lib/numberFormatters';
import { getNiceStatusName, prettyFailMessage } from 'lib/textFormatters';
import { formatRangeWithString, formatValueWithString } from 'lib/units';

import { StatusLabel, StatusType } from './StatusLabel';
import { BatteryTestState, ResultView_test$key } from './__generated__/ResultView_test.graphql';

const StateOfHealthWarningMargin = 0.05; // 5%

const ResultViewFragment = graphql`
    fragment ResultView_test on DeviceBatteryTestResults {
        state
        failReason

        batteryTypes {
            minimumStateOfHealth
            dischargeTables {
                endOfDischargeVoltage
            }
        }
        estimatedStateOfHealth {
            ... on EstimatedValue {
                value
                type
                confidenceInterval {
                    lower
                    upper
                }
            }
            ... on MissingEstimatedValue {
                reasonDescription
            }
        }

        originalCapacity(unit: AmpHour)
        estimatedCapacity(unit: AmpHour) {
            ... on EstimatedValue {
                value
                type
                confidenceInterval {
                    lower
                    upper
                }
            }
            ... on MissingEstimatedValue {
                reasonDescription
            }
        }

        device {
            battery {
                reserveTime(unit: Minutes)
                allowedVoltage {
                    minimum
                }
                quickTestFailThreshold
                strings {
                    strings {
                        cellsPerString
                    }
                }
            }
        }
        estimatedReserveTime(unit: Minutes) {
            ... on EstimatedValue {
                value
                type
                confidenceInterval {
                    lower
                    upper
                }
            }
            ... on MissingEstimatedValue {
                reasonDescription
            }
        }
        quickCheckResult {
            passed
            reason
        }
        task {
            settings {
                ... on BatteryTestTypeQuick {
                    threshold
                }
            }
        }
    }
`;

export interface ResultViewProps {
    testResult: ResultView_test$key | null;
}

export const ResultView: FC<ResultViewProps> = ({ testResult }) => {
    const result = useFragment<ResultView_test$key>(ResultViewFragment, testResult);

    let reserveTimeTargetVoltage: number | null = null;
    let reserveTimeTargetVoltageSource: string | undefined;

    if (result?.device?.battery?.allowedVoltage?.minimum != null) {
        reserveTimeTargetVoltage = result.device.battery.allowedVoltage.minimum;
        reserveTimeTargetVoltageSource = "the device's minimum allowed voltage setting";
    } else if (result?.batteryTypes && result.batteryTypes.length > 0) {
        // Highest end of discharge voltage from all battery types and discharge tables
        for (const batteryType of result.batteryTypes) {
            for (const dischargeTable of batteryType.dischargeTables) {
                if (
                    reserveTimeTargetVoltage == null ||
                    dischargeTable.endOfDischargeVoltage > reserveTimeTargetVoltage
                ) {
                    reserveTimeTargetVoltage = dischargeTable.endOfDischargeVoltage;
                    reserveTimeTargetVoltageSource =
                        'the highest end of discharge voltage in all relevant discharge tables';
                }
            }
        }

        // Convert to system voltage
        if (reserveTimeTargetVoltage != null && result.device.battery.strings?.strings?.[0]?.cellsPerString != null) {
            reserveTimeTargetVoltage *= result.device.battery.strings.strings[0].cellsPerString;
        }
    } else if (result?.task?.settings?.threshold != null) {
        reserveTimeTargetVoltage = result.task.settings.threshold;
        reserveTimeTargetVoltageSource = 'the quick test threshold voltage in task settings';
    } else if (result?.device?.battery?.quickTestFailThreshold != null) {
        reserveTimeTargetVoltage = result.device.battery.quickTestFailThreshold;
        reserveTimeTargetVoltageSource = 'the quick test threshold voltage in battery settings';
    }

    let stateOfHealth;
    let stateOfHealthStatus: StatusType = 'unknown';
    let stateOfHealthSecondaryText: string | undefined;

    let minimumStateOfHealth: number | null = null;
    if (result?.batteryTypes && result.batteryTypes.length > 0) {
        // TODO: Mixed battery types not supported yet
        minimumStateOfHealth = result.batteryTypes[0].minimumStateOfHealth;
        stateOfHealthSecondaryText = `Minimum ${formatValueWithString(
            numberToLocaleString(minimumStateOfHealth, 0),
            '%'
        )}`;
    }

    if (result && result.estimatedStateOfHealth?.type != null) {
        if (result.estimatedStateOfHealth.confidenceInterval) {
            const lower = result.estimatedStateOfHealth.confidenceInterval.lower;
            const upper = result.estimatedStateOfHealth.confidenceInterval.upper;
            stateOfHealth = formatRangeWithString(numberToLocaleString(lower, 0), numberToLocaleString(upper, 0), '%');

            if (minimumStateOfHealth != null) {
                if (upper < minimumStateOfHealth) {
                    stateOfHealthStatus = 'bad';
                } else if (lower >= minimumStateOfHealth * (1 + StateOfHealthWarningMargin)) {
                    stateOfHealthStatus = 'good';
                } else {
                    stateOfHealthStatus = 'warning';
                }
            }
        } else if (result.estimatedStateOfHealth.value != null) {
            let prefix = '';
            if (result.estimatedStateOfHealth.type === 'Minimum') {
                prefix = 'Above ';
            } else if (result.estimatedStateOfHealth.type === 'Maximum') {
                prefix = 'Below ';
            }

            stateOfHealth = `${prefix}${formatValueWithString(
                numberToLocaleString(result.estimatedStateOfHealth.value, 0),
                '%'
            )}`;

            if (result.estimatedStateOfHealth.type !== 'Standard') {
                stateOfHealth = (
                    <div className='flex flex-row'>
                        {stateOfHealth}
                        <div className='text-base mt-1'>
                            <InfoTip message='There is a lot of uncertainty in the estimation. A deeper discharge will offer a more accurate value' />
                        </div>
                    </div>
                );
            }

            if (minimumStateOfHealth != null) {
                if (result.estimatedStateOfHealth.value < minimumStateOfHealth) {
                    stateOfHealthStatus = 'bad';
                } else if (
                    result.estimatedStateOfHealth.value >=
                    minimumStateOfHealth * (1 + StateOfHealthWarningMargin)
                ) {
                    stateOfHealthStatus = 'good';
                } else {
                    stateOfHealthStatus = 'warning';
                }
            }
        }
    } else {
        if (result && isTestFinished(result.state)) {
            let message: string | undefined;
            if (result.state === 'Inconclusive' && !result.failReason) {
                message = 'This result was not analysed because no discharge was detected.';
            } else if (result.estimatedStateOfHealth?.reasonDescription) {
                message = result.estimatedStateOfHealth.reasonDescription;
            }

            stateOfHealth = (
                <div className='flex flex-row'>
                    N/A
                    {message && (
                        <div className='text-base mt-1'>
                            <InfoTip message={message} />
                        </div>
                    )}
                </div>
            );
        } else {
            stateOfHealth = '-';
        }
    }

    let reserveTime;
    let reserveTimeStatus: StatusType = 'unknown';
    let designReserveTime: string | undefined;
    let reserveTimeTitle: string;
    let reserveTimeTooltip: string;

    if (reserveTimeTargetVoltage != null) {
        reserveTimeTitle = `Estimated reserve time to ${formatValueWithString(
            numberToLocaleString(reserveTimeTargetVoltage, 1),
            'V'
        )}`;

        reserveTimeTooltip = `The reserve time is the time the battery can power the load before reaching the specified voltage.\nThe voltage was specified by ${reserveTimeTargetVoltageSource}.`;
    } else {
        reserveTimeTitle = 'Estimated reserve time';
        reserveTimeTooltip =
            'The reserve time is the time the battery can power the load before reaching the minimum allowed voltage.\nThe minimum allowed voltage was not specified.';
    }

    const configuredReserveTime = result?.device?.battery?.reserveTime;
    if (configuredReserveTime != null) {
        designReserveTime = `Minimum ${formatMinutesAsHoursAndMinutes(configuredReserveTime)}`;
    }

    if (result && result.estimatedReserveTime?.type != null) {
        if (result.estimatedReserveTime.confidenceInterval) {
            const lower = result.estimatedReserveTime.confidenceInterval.lower;
            const upper = result.estimatedReserveTime.confidenceInterval.upper;

            reserveTime = `${formatMinutesAsHoursAndMinutes(lower, true)} to ${formatMinutesAsHoursAndMinutes(
                upper,
                true
            )}`;

            if (configuredReserveTime != null) {
                if (upper < configuredReserveTime) {
                    reserveTimeStatus = 'bad';
                } else if (lower >= configuredReserveTime) {
                    reserveTimeStatus = 'good';
                } else {
                    reserveTimeStatus = 'warning';
                }
            }
        } else if (result.estimatedReserveTime.value != null) {
            let prefix = '';
            if (result.estimatedReserveTime.type === 'Minimum') {
                prefix = 'Above ';
            } else if (result.estimatedReserveTime.type === 'Maximum') {
                prefix = 'Below ';
            }

            const value = result.estimatedReserveTime.value;

            reserveTime = `${prefix}${formatMinutesAsHoursAndMinutes(value, true)}`;

            if (result.estimatedReserveTime.type !== 'Standard') {
                reserveTime = (
                    <div className='flex flex-row'>
                        {reserveTime}
                        <div className='text-base mt-1'>
                            <InfoTip message='There is a lot of uncertainty in the estimation. A deeper discharge will offer a more accurate value' />
                        </div>
                    </div>
                );
            }

            if (configuredReserveTime != null) {
                if (value < configuredReserveTime) {
                    reserveTimeStatus = 'bad';
                } else {
                    reserveTimeStatus = 'good';
                }
            }
        }
    } else {
        if (result && isTestFinished(result.state)) {
            let message: string | undefined;
            if (result.state === 'Inconclusive' && !result.failReason) {
                message = 'This result was not analysed because no discharge was detected.';
            } else if (result.estimatedReserveTime?.reasonDescription) {
                message = result.estimatedReserveTime.reasonDescription;
            }

            reserveTime = (
                <div className='flex flex-row'>
                    N/A
                    {message && (
                        <div className='text-base mt-1'>
                            <InfoTip message={message} />
                        </div>
                    )}
                </div>
            );
        } else {
            reserveTime = '-';
        }
    }

    let capacity;
    let capacityStatus: StatusType = 'unknown';
    let originalCapacityDescription: string | undefined;

    const originalCapacity = result?.originalCapacity;
    if (originalCapacity != null) {
        originalCapacityDescription = `Original ${formatValueWithString(
            numberToLocaleString(originalCapacity, 0),
            'Ah'
        )}`;
    }
    if (result && result.estimatedCapacity?.type != null) {
        capacityStatus = 'neutral';
        if (result.estimatedCapacity.confidenceInterval) {
            const lower = numberToLocaleString(result.estimatedCapacity.confidenceInterval.lower, 0);
            const upper = numberToLocaleString(result.estimatedCapacity.confidenceInterval.upper, 0);
            capacity = formatRangeWithString(lower, upper, 'Ah');
        } else if (result.estimatedCapacity.value != null) {
            let prefix = '';
            if (result.estimatedCapacity.type === 'Minimum') {
                prefix = 'Above ';
            } else if (result.estimatedCapacity.type === 'Maximum') {
                prefix = 'Below ';
            }

            capacity = `${prefix}${formatValueWithString(
                numberToLocaleString(result.estimatedCapacity.value, 0),
                'Ah'
            )}`;

            if (result.estimatedCapacity.type !== 'Standard') {
                capacity = (
                    <div className='flex flex-row'>
                        {capacity}
                        <div className='text-base mt-1'>
                            <InfoTip message='There is a lot of uncertainty in the estimation. A deeper discharge will offer a more accurate value' />
                        </div>
                    </div>
                );
            }
        }
    } else {
        if (result && isTestFinished(result.state)) {
            let message: string | undefined;
            if (result.state === 'Inconclusive' && !result.failReason) {
                message = 'This result was not analysed because no discharge was detected.';
            } else if (result.estimatedCapacity?.reasonDescription) {
                message = result.estimatedCapacity.reasonDescription;
            }

            capacity = (
                <div className='flex flex-row'>
                    N/A
                    {message && (
                        <div className='text-base mt-1'>
                            <InfoTip message={message} />
                        </div>
                    )}
                </div>
            );
        } else {
            capacity = '-';
        }
    }

    let resultMessage = '-';
    let resultStatus: StatusType = 'unknown';
    let resultSecondaryText: string | undefined;
    if (result) {
        if (isTestFinished(result.state)) {
            resultMessage = getNiceStatusName(result.state);
            if (result.state === 'Failed') {
                resultStatus = 'bad';
            } else if (result.state === 'Passed') {
                resultStatus = 'good';
            } else {
                resultStatus = 'unknown';
            }

            if ((result.state === 'Failed' || result.state === 'Inconclusive') && result.failReason) {
                resultSecondaryText = prettyFailMessage(result.failReason);
            } else if (result.state === 'Inconclusive' && !result.failReason) {
                // This is the only way this happens
                resultSecondaryText = 'No discharge detected';
            } else if (reserveTimeStatus === 'good') {
                resultSecondaryText = 'Sufficient reserve';
            } else if (stateOfHealthStatus === 'good') {
                resultSecondaryText = 'Health good';
            } else if (result.quickCheckResult?.passed === true) {
                resultSecondaryText = 'Quick test passed';
            }
        } else {
            resultMessage = '-';
            resultStatus = 'neutral';
        }
    }

    return (
        <div className='grid grid-cols-4'>
            <StatusLabel
                title={reserveTimeTitle}
                content={reserveTime}
                status={reserveTimeStatus}
                secondary={designReserveTime}
                titleTooltip={reserveTimeTooltip}
            />
            <StatusLabel
                title='Estimated capacity'
                content={capacity}
                status={capacityStatus}
                secondary={originalCapacityDescription}
                titleTooltip='The capacity is the amount of energy the battery can store. This is calculated from the state of health and the factory capacity, which is the design capacity of the battery at this load.'
            />
            <StatusLabel
                title='Estimated state of health'
                content={stateOfHealth}
                status={stateOfHealthStatus}
                secondary={stateOfHealthSecondaryText}
                titleTooltip='The state of health is an estimation of the remaining capacity of the battery.'
            />
            <StatusLabel
                title='Result'
                content={resultMessage}
                status={resultStatus}
                secondary={resultSecondaryText}
                titleTooltip='The result is an estimation of how suitable the battery is for continued use.'
            />
        </div>
    );
};

function isTestFinished(status: BatteryTestState): boolean {
    switch (status) {
        case 'Aborted':
        case 'Failed':
        case 'Inconclusive':
        case 'Passed':
            return true;
        default:
            return false;
    }
}
