import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { SiteOverviewContent } from './SiteOverviewContent';
import { SiteOverviewSkeleton } from './SiteOverviewSkeleton';
import { SiteOverviewContentQuery } from './__generated__/SiteOverviewContentQuery.graphql';

export const SiteOverview: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<SiteOverviewContentQuery> | null;
    const { siteId } = useParams() as { siteId: string };

    return (
        <>
            {!queryRef && <SiteOverviewSkeleton />}
            {queryRef && (
                <Suspense fallback={<SiteOverviewSkeleton />}>
                    <SiteOverviewContent key={siteId} queryRef={queryRef} siteId={siteId} />
                </Suspense>
            )}
        </>
    );
};
