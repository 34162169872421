import React, { FC } from 'react';

import { FilterTimeRangeSelect } from 'components/FilterTimeRangeSelect';
import { PredefinedTimeRange, TimeRangeFilter } from 'components/FilterTimeRangeSelect/common';
import { CommonFilterProps } from 'filters/common';
import { Duration } from 'luxon';

const Presets: PredefinedTimeRange[] = [
    {
        title: 'Less than 2 weeks',
        asTimeRange: () => ({
            min: 0,
            max: Duration.fromObject({ weeks: 2 }).as('minutes'),
        }),
    },
    {
        title: 'Less than 1 month',
        asTimeRange: () => ({
            min: 0,
            max: Duration.fromObject({ month: 1 }).as('minutes'),
        }),
    },
    {
        title: 'Less than 3 months',
        asTimeRange: () => ({
            min: 0,
            max: Duration.fromObject({ month: 3 }).as('minutes'),
        }),
    },
    {
        title: '3 to 6 months',
        asTimeRange: () => ({
            min: Duration.fromObject({ month: 3 }).as('minutes'),
            max: Duration.fromObject({ month: 6 }).as('minutes'),
        }),
    },
    {
        title: '6 to 12 months',
        asTimeRange: () => ({
            min: Duration.fromObject({ month: 6 }).as('minutes'),
            max: Duration.fromObject({ month: 12 }).as('minutes'),
        }),
    },
];

export type ACPowerMTBFRangeFilterUIProps = CommonFilterProps<TimeRangeFilter>;

export const ACPowerMTBFRangeFilterUI: FC<ACPowerMTBFRangeFilterUIProps> = ({
    current,
    onClearOrBack,
    onSubmit,
    onClose,
    hasPrevious,
}) => {
    return (
        <FilterTimeRangeSelect
            title='Filter by MTBF'
            presets={Presets}
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            hasPrevious={hasPrevious}
            onClearOrBack={onClearOrBack}
        />
    );
};
