import { useSearchParams } from 'react-router-dom';

import { Duration } from 'luxon';

import { DefaultTimeRange, TimeRange, TimeRangeDescriptions, TimeRangeSearchParameter } from './settings';

export const DefinitionTooltip = {
    TotalSites: 'The total number of sites within the network.',
    Undervoltages:
        'Undervoltages are defined as a period of time where the voltage is below the undervoltage threshold.',
    Outages: 'Outages are defined as a period of time where the voltage is below the outage threshold.',
    Overvoltages: 'Overvoltages are defined as a period of time where the voltage is above the overvoltage threshold.',
};

export function intervalToIncidentCountChart(interval: TimeRange): Duration {
    switch (interval) {
        case TimeRange.LastMonth:
            return Duration.fromObject({ days: 1 });
        case TimeRange.LastQuarter:
            return Duration.fromObject({ weeks: 1 });
        case TimeRange.Last6Months:
            return Duration.fromObject({ months: 1 });
        case TimeRange.LastYear:
            return Duration.fromObject({ months: 1 });
        case TimeRange.Lifetime:
            return Duration.fromObject({ months: 6 });
    }
}

export function intervalToHuman(
    interval: TimeRange,
    options?: { lifetimeOverride?: string; prefix?: string; suffix?: string }
): string {
    if (interval === TimeRange.Lifetime) {
        return options?.lifetimeOverride ?? 'Lifetime';
    } else {
        const intervalDescription = TimeRangeDescriptions[interval];

        return `${options?.prefix ?? ''}${intervalDescription}${options?.suffix ?? ''}`;
    }
}

export function sanitizeTimeRange(input: string): TimeRange {
    if (input in TimeRange && input !== '%future added value') {
        return input as TimeRange;
    }

    return DefaultTimeRange;
}

export function useTimeRangeFromSearch(): TimeRange {
    const [searchParams] = useSearchParams({
        [TimeRangeSearchParameter]: DefaultTimeRange,
    });

    return sanitizeTimeRange(searchParams.get(TimeRangeSearchParameter)!);
}

export function getSectionTimeDescription(timeRange: TimeRange, type: 'site' | 'device'): string {
    return intervalToHuman(timeRange, {
        lifetimeOverride: `since the ${type} was created`,
        prefix: 'for the last ',
    });
}
