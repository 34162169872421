/**
 * @generated SignedSource<<2abb9e82aae524b2e1cb6cbba56927e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneratorVoltageChart_metrics$data = {
  readonly phase1: {
    readonly voltage: {
      readonly end: string;
      readonly start: string;
      readonly values: ReadonlyArray<{
        readonly timestamp: string;
        readonly value: number | null;
      }>;
    } | null;
  } | null;
  readonly phase2: {
    readonly voltage: {
      readonly end: string;
      readonly start: string;
      readonly values: ReadonlyArray<{
        readonly timestamp: string;
        readonly value: number | null;
      }>;
    } | null;
  } | null;
  readonly phase3: {
    readonly voltage: {
      readonly end: string;
      readonly start: string;
      readonly values: ReadonlyArray<{
        readonly timestamp: string;
        readonly value: number | null;
      }>;
    } | null;
  } | null;
  readonly " $fragmentType": "GeneratorVoltageChart_metrics";
};
export type GeneratorVoltageChart_metrics$key = {
  readonly " $data"?: GeneratorVoltageChart_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneratorVoltageChart_metrics">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "begin",
        "variableName": "begin"
      },
      {
        "kind": "Variable",
        "name": "end",
        "variableName": "end"
      },
      {
        "kind": "Literal",
        "name": "interval",
        "value": "PT15M"
      }
    ],
    "concreteType": "TimeSeriesMetrics",
    "kind": "LinkedField",
    "name": "voltage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TimestampMetric",
        "kind": "LinkedField",
        "name": "values",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "begin"
    },
    {
      "kind": "RootArgument",
      "name": "end"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneratorVoltageChart_metrics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceGeneratorPhaseMetrics",
      "kind": "LinkedField",
      "name": "phase1",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceGeneratorPhaseMetrics",
      "kind": "LinkedField",
      "name": "phase2",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceGeneratorPhaseMetrics",
      "kind": "LinkedField",
      "name": "phase3",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "DeviceGeneratorOutputMetrics",
  "abstractKey": null
};
})();

(node as any).hash = "6648215fc7d6a5080030fe70112886b2";

export default node;
