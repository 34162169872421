import React, { FC, Fragment, ReactNode } from 'react';

import { AlertIcon, Button, CheckIcon, FormArea } from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';
import { useField } from 'formik';

import { getQualifiedName } from '../../../../../../lib/namespaces';
import manageStyle from '../../../../style.module.css';
import { DeviceType } from '../../lib/device-types';
import { Addresses } from './components/Addresses';
import { GatewaySettings } from './components/GatewaySettings';
import { SNMPSettings } from './components/SNMPSettings';
import { WebCredentials } from './components/WebCredentials';
import style from './connection-settings.module.css';
import { ConnectivitySettingsFormValues } from './schema';
import { useConnectionTest } from './use-connection-test';

const classNames = classNamesBind.bind(style);

export interface ConnectivitySettingsProps {
    deviceType: DeviceType;
    namespace: string;
}

export const ConnectivitySettings: FC<ConnectivitySettingsProps> = ({ namespace, deviceType }) => {
    const [, currentSettingsMeta] = useField<ConnectivitySettingsFormValues>(namespace);
    const currentSettings = currentSettingsMeta.value;

    const { testConnection, canCheckConnection, isTestingConnection, isConnectionOk, connectionStatus } =
        useConnectionTest(currentSettings, deviceType);

    const protocolBaseNamespace = getQualifiedName('protocols', namespace);

    return (
        <FormArea label='Connection Settings' blurb='Connection details for communicating with the device.'>
            <div className={manageStyle.provisioning_content_grid}>
                {deviceType.connectivity.ipEnabled && (
                    <div className='col-start-1'>
                        <Addresses namespace={namespace} />
                    </div>
                )}

                {deviceType.connectivity.protocols.map(protocol => {
                    const protocolNamespace = getQualifiedName(protocol.id, protocolBaseNamespace);

                    let component: ReactNode;
                    if (protocol.type === 'Snmp') {
                        component = <SNMPSettings key={protocol.id} namespace={protocolNamespace} />;
                    } else if (protocol.type === 'Basic') {
                        component = <WebCredentials key={protocol.id} namespace={protocolNamespace} />;
                    } else if (protocol.type === 'Gateway') {
                        component = <GatewaySettings key={protocol.id} namespace={protocolNamespace} />;
                    } else {
                        component = <></>;
                    }

                    if (deviceType.connectivity.protocols.length > 1) {
                        // Use headings if there are multiple protocols
                        return (
                            <Fragment key={protocol.id}>
                                <h3 className='text-md font-semibold col-start-1'>{protocol.displayName}</h3>
                                {component}
                            </Fragment>
                        );
                    } else {
                        return component;
                    }
                })}

                <div className={classNames('test_connection_section', 'col-start-1')}>
                    <Button
                        buttonClasses={classNames('test_connection_button')}
                        size='small'
                        buttonText='Test connection'
                        variant='secondary'
                        processing={isTestingConnection}
                        disabled={!canCheckConnection}
                        disabledMessage='Make sure all connection settings are filled in'
                        onClick={testConnection}
                    />
                    <div
                        className={classNames('test_connection_result', {
                            'text-eggplantRegular': isConnectionOk,
                            'text-coralRegular': !isConnectionOk,
                        })}
                    >
                        <div className='w-4'>
                            {connectionStatus && (isConnectionOk ? <CheckIcon /> : <AlertIcon />)}
                        </div>
                        {connectionStatus}
                    </div>
                </div>
            </div>
        </FormArea>
    );
};
