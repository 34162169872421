import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { Button, InlineTextField, Menu, Modal, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { NameValuePair } from 'components';
import { NameValuePairClassesProps } from 'components/NameValuePair';
import { useUserPermissions } from 'lib/auth';
import { logError } from 'lib/log';
import { Paths } from 'lib/routes';
import { DateTime, Duration } from 'luxon';

import { useConfirmationModal } from '../../../../../lib/confirmation';
import { TaskProgressBar } from '../../components/TaskProgressBar';
import { CancelTaskResult, cancelTask } from '../lib/task-cancel';
import { Exporter } from '../lib/task-exporter';
import { TaskOverviewUpdateNameMutation } from './__generated__/TaskOverviewUpdateNameMutation.graphql';
import { TaskOverview_task$key } from './__generated__/TaskOverview_task.graphql';
import { AbortTestModal } from './components/AbortTestModal';
import { OverviewFields } from './components/OverviewFields';
import { RepeatTaskModal } from './components/RepeatTaskModal';

export interface TaskOverviewProps {
    task: TaskOverview_task$key;
}

const nameValueClasses: NameValuePairClassesProps = {
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
        lineHeight: '1.5rem',
    },
    value: {
        gridColumnStart: 5,
    },
};

export const TaskOverview: FC<TaskOverviewProps> = ({ task }) => {
    const { hasTasksWrite } = useUserPermissions();
    const [showAbortModal, setShowAbortModal] = useState(false);
    const [showRepeatModal, setShowRepeatModal] = useState(false);
    const environment = useRelayEnvironment();
    const { show: showToast } = useToast();
    const [showModal, modalComponent] = useConfirmationModal();

    const exporter = useRef<Exporter | null>(null);

    const result = useFragment<TaskOverview_task$key>(
        graphql`
            fragment TaskOverview_task on BatteryTest {
                id
                name
                overallState
                estimatedEndTime
                schedule {
                    repeat
                }
                settings {
                    ... on BatteryTestTypeCapacity {
                        targets {
                            endOfDischargeVoltage
                            batteryType {
                                id
                                manufacturer
                                model
                            }
                        }
                    }
                    ... on BatteryTestTypeQuick {
                        reserveTime
                        threshold
                    }
                    ... on BatteryTestTypeCustom {
                        endOfDischargeVoltage
                        maxDuration
                        maxDischarged
                    }
                }
                testState
                totalDevice: devices {
                    total
                    data {
                        id
                    }
                }
                ...OverviewFields_task
                ...TaskProgressBar_task
            }
        `,
        task
    );

    const [updateTaskName] = useMutation<TaskOverviewUpdateNameMutation>(UpdateNameMutation);

    const handleSaveTaskName = useCallback(
        (newName: string) => {
            if (newName.trim().length === 0) {
                return;
            }

            updateTaskName({
                variables: {
                    id: result.id,
                    name: newName,
                },
                optimisticUpdater(store) {
                    // Preemptively update the store so that the new name is reflected immediately
                    const taskRecord = store.get(result.id);

                    if (taskRecord) {
                        taskRecord.setValue(newName, 'name');
                    }
                },
                updater(store) {
                    // FIXME: Only update the store if the response was success
                    // Cant seem to get the response value. getRootField("editTaskName") errors because the field 'doesnt exist'.
                    // All docs and the error message itself point back to this way, so no idea why that doesnt work here.
                    // Alternatively trying to get the response using store.getRoot().getValue('editTaskName') just returns undefined.

                    const taskRecord = store.get(result.id);

                    if (taskRecord) {
                        taskRecord.setValue(newName, 'name');
                    }
                },
                onError(error) {
                    logError('Error updating task name', error);
                    captureException(error, scope => {
                        scope.setTag('Component', 'TaskOverview');
                        scope.setTag('Function', 'updateTaskName');
                        scope.setExtra('id', result.id);
                        scope.setExtra('name', newName);
                        return scope;
                    });
                    showToast({
                        text: 'Failed to update task name',
                        variant: 'error',
                    });
                },
                onCompleted(result) {
                    if (result.editTaskName === 'InvalidValue') {
                        showToast({
                            text: 'Invalid task name',
                            variant: 'error',
                        });
                    } else if (result.editTaskName !== 'Success') {
                        showToast({
                            text: 'Failed to update task name',
                            variant: 'error',
                        });
                    }
                },
            });
        },
        [result.id, showToast, updateTaskName]
    );

    useEffect(() => {
        return () => {
            if (exporter.current) {
                exporter.current.cancel();
            }
        };
    }, []);

    const testId = result?.id;
    const deviceIds = result ? result.totalDevice.data.map(({ id }) => id) : [];

    let estimatedEndTime: ReactNode;
    if (result && result.estimatedEndTime && result.overallState === 'InProgress') {
        const minutesToEnd = DateTime.fromISO(result.estimatedEndTime as string)
            .diff(DateTime.now(), 'minutes')
            .as('minutes');
        const durationToEnd = Duration.fromObject({ minutes: minutesToEnd });

        estimatedEndTime = (
            <NameValuePair
                name='Test Complete In'
                value={`${durationToEnd.hours}.${durationToEnd.minutes}`}
                classes={nameValueClasses}
            />
        );
    }

    let name: string;
    if (result) {
        name = result.name ?? 'Unnamed test';
    } else {
        name = 'Loading...';
    }

    const doExport = useCallback(() => {
        if (!testId) {
            return;
        }

        if (result.overallState !== 'Completed') {
            return;
        }

        if (exporter.current?.running) {
            return;
        }

        exporter.current = new Exporter(testId, showToast, environment);
        exporter.current.begin();
    }, [environment, result.overallState, showToast, testId]);

    const handleCancelTask = useCallback(() => {
        const isRecurring = result.schedule?.repeat !== 'Never';

        function showFailModal() {
            showModal({
                title: 'Error',
                content: 'Unable to cancel the test, please try again later.',
                buttons: [
                    {
                        id: 'close',
                        label: 'Close',
                        variant: 'primary',
                    },
                ],
                onResult: async () => {
                    // No action
                },
            });
        }

        showModal({
            title: 'Cancel Test?',
            content: (
                <>
                    <div className='space-y-1'>
                        <div>Are you sure you want to cancel this test?</div>
                        {isRecurring && (
                            <div className='text-xs'>
                                <span className='text-coralRegular'>WARNING: </span>
                                <span className='text-eggplantRegular'>
                                    Cancelling this test will cancel all future recurrences.
                                </span>
                            </div>
                        )}
                    </div>
                </>
            ),
            buttons: [
                {
                    id: 'confirm',
                    label: 'Cancel Test',
                    variant: 'primary',
                },
                {
                    id: 'cancel',
                    label: 'No',
                    variant: 'white',
                },
            ],
            onResult: async result => {
                if (result === 'cancel') {
                    return;
                }

                try {
                    const result = await cancelTask(environment, testId);
                    if (result === CancelTaskResult.Success) {
                        showToast({
                            text: 'Test cancelled successfully',
                            variant: 'info',
                        });
                        return;
                    }
                } catch {
                    // No logging required, already logged
                }

                showFailModal();
            },
        });
    }, [environment, result.schedule?.repeat, showToast, showModal, testId]);

    return (
        <>
            <div className='flex flex-row justify-between'>
                {!hasTasksWrite && <div className='text-coralRegular font-bold text-3xl'>{name}</div>}
                {hasTasksWrite && (
                    <InlineTextField
                        value={name}
                        className='text-coralRegular font-bold text-3xl'
                        onEdit={handleSaveTaskName}
                        noWrap
                        maxLength={50}
                    />
                )}
                {estimatedEndTime}
                <Menu
                    id='task-menu'
                    menuItems={[
                        {
                            name: 'Export',
                            onClick: doExport,
                            disabled: result?.overallState !== 'Completed',
                        },
                    ]}
                />
            </div>
            <TaskProgressBar task={result} isCancelledTask={result?.overallState === 'Cancelled'} />
            <OverviewFields task={result} />
            <div className='flex flex-row-reverse justify-between'>
                <Button buttonText='Return to Tasks' variant='primary' size='medium' to={Paths.TestsOverview} />
                {hasTasksWrite && result?.testState === 'InProgress' && (
                    <Button
                        buttonText='Abort test'
                        variant='gray'
                        size='medium'
                        onClick={() => setShowAbortModal(true)}
                    />
                )}
                {hasTasksWrite && result?.testState === 'Scheduled' && (
                    <Button buttonText='Cancel test' variant='gray' size='medium' onClick={handleCancelTask} />
                )}
                {hasTasksWrite && result?.overallState === 'Completed' && (
                    <Button
                        buttonText='Repeat Task'
                        variant='gray'
                        size='medium'
                        onClick={() => setShowRepeatModal(true)}
                    />
                )}
                {hasTasksWrite && result?.overallState === 'Cancelled' && (
                    <Button
                        buttonText='Reschedule'
                        variant='gray'
                        size='medium'
                        to={`${Paths.TestsScheduleTest}?copy-task=${encodeURIComponent(testId ?? '')}`}
                    />
                )}
            </div>
            {result?.testState === 'InProgress' && (
                <Modal className='bg-white text-center' open={showAbortModal} closeButton={false}>
                    <AbortTestModal testId={testId} testName={name} setShowAbortModal={setShowAbortModal} />
                </Modal>
            )}

            {result?.overallState === 'Completed' && testId && (
                <Modal className='text-center' bgColor='bg-white' open={showRepeatModal} closeButton={false}>
                    <RepeatTaskModal devices={deviceIds} taskId={testId} onSetVisible={setShowRepeatModal} />
                </Modal>
            )}
            {modalComponent}
        </>
    );
};

const UpdateNameMutation = graphql`
    mutation TaskOverviewUpdateNameMutation($id: ID!, $name: String!) {
        editTaskName(id: $id, name: $name)
    }
`;
