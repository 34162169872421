import React from 'react';
import { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { Paths } from 'lib/routes';
import { CardSection } from 'views/tasks/components';

import { FilterValueMap } from '../../../../../filters/common';
import { GeneratorRunReportTableColumn } from '../../../../../filters/generator-run-report';
import {
    GeneratorRunReportAllFilterMap,
    StaticGeneratorRunReportFilterDefinitions,
} from '../../../../../filters/generator-run-report/settings';
import { encodeFilterParameters } from '../../../../../lib/table-filter';
import { GeneratorRunCard } from '../components/GeneratorRunCard';
import { CompletedGeneratorRuns_tasks$key } from './__generated__/CompletedGeneratorRuns_tasks.graphql';

export interface CompletedGeneratorRunsProps {
    tasks: CompletedGeneratorRuns_tasks$key | null;
    maxItems: number;
}

export const CompletedGeneratorRuns: FC<CompletedGeneratorRunsProps> = ({ maxItems, tasks }) => {
    const result = useFragment<CompletedGeneratorRuns_tasks$key>(
        graphql`
            fragment CompletedGeneratorRuns_tasks on PaginatedGeneratorRunReport {
                data {
                    id
                    name
                    cause
                    state
                    generator {
                        id
                        name
                        site {
                            id
                            name
                            address {
                                state
                            }
                        }
                    }
                    generatorStartTime
                    generatorStopTime
                }
                total
            }
        `,
        tasks
    );

    if (!result || result?.data.length === 0) {
        return null;
    }

    const itemsToDisplay = result.data.slice(0, maxItems);

    return (
        <CardSection
            title='Completed runs'
            testId='completed-section'
            to={{
                pathname: Paths.TasksGeneratorsList,
                search: encodeFilterParameters<
                    GeneratorRunReportTableColumn,
                    FilterValueMap<GeneratorRunReportAllFilterMap>
                >(
                    {
                        [GeneratorRunReportTableColumn.State]: [
                            {
                                id: 'Completed',
                                name: 'Completed',
                            },
                            {
                                id: 'Error',
                                name: 'Errored',
                            },
                        ],
                    },
                    StaticGeneratorRunReportFilterDefinitions
                ),
            }}
        >
            {itemsToDisplay.map(runReport => {
                return <GeneratorRunCard key={runReport.id} runReport={runReport} />;
            })}
        </CardSection>
    );
};
