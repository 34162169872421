/**
 * @generated SignedSource<<9e28f7957d0cfc306ca277cf9b75612f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StringFilter = {
  value: string;
};
export type PreviousTestsQuery$variables = {
  beforeTime?: string | null;
  deviceIds: ReadonlyArray<StringFilter>;
};
export type PreviousTestsQuery$data = {
  readonly batteryTestResults: {
    readonly data: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"BatteryTestResultCard_test">;
    }>;
  };
};
export type PreviousTestsQuery = {
  response: PreviousTestsQuery$data;
  variables: PreviousTestsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "beforeTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceIds"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceIds"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "dir": "Desc",
      "field": "StartTime"
    }
  },
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 3
  },
  {
    "kind": "Variable",
    "name": "to",
    "variableName": "beforeTime"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviousTestsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BatteryTestResultCard_test"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PreviousTestsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commencedTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cause",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BatteryTest",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdTime",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e78d2475fad3036677d55640244e1aa9",
    "id": null,
    "metadata": {},
    "name": "PreviousTestsQuery",
    "operationKind": "query",
    "text": "query PreviousTestsQuery(\n  $deviceIds: [StringFilter!]!\n  $beforeTime: Timestamp\n) {\n  batteryTestResults(filters: {deviceId: $deviceIds}, to: $beforeTime, pageSize: 3, orderBy: {field: StartTime, dir: Desc}) {\n    data {\n      ...BatteryTestResultCard_test\n      id\n    }\n  }\n}\n\nfragment BatteryTestResultCard_test on DeviceBatteryTestResults {\n  id\n  name\n  state\n  commencedTime\n  completedTime\n  cause\n  device {\n    id\n    name\n    site {\n      name\n      address {\n        state\n      }\n      id\n    }\n  }\n  task {\n    createdTime\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "697ca62e92719fdfe52e18f4a1e3a547";

export default node;
