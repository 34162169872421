import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { LoadableContentArea, PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { LargeTextDisplay } from './LargeTextDisplay';
import { ActiveDevices$key } from './__generated__/ActiveDevices.graphql';
import style from './style.module.css';

export interface ActiveDevicesProps {
    data: ActiveDevices$key | null;
    error?: boolean;
    retry: () => void;
}
export const ActiveDevices: FC<ActiveDevicesProps> = ({ data, error, retry }) => {
    const props = useFragment<ActiveDevices$key>(
        graphql`
            fragment ActiveDevices on Query {
                devices {
                    total
                }
            }
        `,
        data
    );

    return (
        <div className='flex flex-col'>
            <PageHeading value='Active Devices' subheading secondary />
            <LoadableContentArea
                className={style.active_devices_content_area}
                variant='framed'
                data={props}
                error={!!error}
                onRetry={retry}
                render={props => {
                    return <LargeTextDisplay value={props.devices.total.toLocaleString()} />;
                }}
            />
        </div>
    );
};
