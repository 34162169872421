/**
 * @generated SignedSource<<856fae8d892c4d58263fe2d1d63ecb33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTechnologyType = "LeadAcidAGM" | "LeadAcidFlooded" | "LeadAcidGel" | "LeadAcidOther" | "LeadAcidTPPL" | "LeadAcidVRLA" | "LithiumIon" | "LithiumIronPhosphate" | "LithiumPolymer" | "NickelCadmium" | "NickelMetalHydride" | "Other" | "Supercapacitor" | "%future added value";
export type BatteryTypeFilter = {
  attributes?: ReadonlyArray<AttributeFilters> | null;
  capacity?: FloatRangeFilter | null;
  cellCapacityRating?: FloatRangeFilter | null;
  cellReferenceTemperature?: FloatRangeFilter | null;
  cellsPerBloc?: IntRangeFilter | null;
  ids?: ReadonlyArray<string> | null;
  manufacturer?: ReadonlyArray<StringFilter> | null;
  model?: ReadonlyArray<StringFilter> | null;
  peukertsConstant?: FloatRangeFilter | null;
  technology?: ReadonlyArray<BatteryTechnologyType> | null;
};
export type StringFilter = {
  value: string;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type BatteryTypesAllIdsQuery$variables = {
  filters?: BatteryTypeFilter | null;
};
export type BatteryTypesAllIdsQuery$data = {
  readonly batteryTypes: {
    readonly data: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type BatteryTypesAllIdsQuery = {
  response: BatteryTypesAllIdsQuery$data;
  variables: BatteryTypesAllIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Literal",
        "name": "onlyProvisioningStatuses",
        "value": "Active"
      },
      {
        "kind": "Literal",
        "name": "pageSize",
        "value": 10000
      }
    ],
    "concreteType": "PaginatedBatteryTypes",
    "kind": "LinkedField",
    "name": "batteryTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BatteryType",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryTypesAllIdsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatteryTypesAllIdsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "57766d262252b7778b02f768539321c7",
    "id": null,
    "metadata": {},
    "name": "BatteryTypesAllIdsQuery",
    "operationKind": "query",
    "text": "query BatteryTypesAllIdsQuery(\n  $filters: BatteryTypeFilter\n) {\n  batteryTypes(onlyProvisioningStatuses: Active, pageSize: 10000, filters: $filters) {\n    data {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5cbd0ce2e316e1f9bfc1672cfd704bee";

export default node;
