import { GroupDefinition } from '@accesstel/pcm-ui';

import { DateTime, IANAZone, SystemZone } from 'luxon';

export enum TimezonePreset {
    Automatic = 'Automatic',
    Manual = 'Manual',
}

export const TimezonePresetLabels: Record<TimezonePreset, string> = {
    [TimezonePreset.Automatic]: 'Automatic (Location Based)',
    [TimezonePreset.Manual]: 'Manual',
};

export interface TimezoneOption {
    region: string;
    label: string;
    name: string;
    regionalName: string | null;
    keywords: string;
}

export function getTimezoneOptions(): TimezoneOption[] {
    const zones = Intl.supportedValuesOf('timeZone')
        // Don't show the Etc/* zones
        .filter(zone => !zone.startsWith('Etc/'))
        // Don't show the other miscellaneous zones
        .filter(zone => zone.includes('/'))
        .map(makeTimezoneOption);

    // Apply special zones
    zones.push(makeTimezoneOption('UTC'));
    zones.push(makeTimezoneOption('GMT'));

    return zones;
}

export function makeTimezoneOption(timezone: string): TimezoneOption {
    const zone = IANAZone.create(timezone);
    if (!zone.isValid) {
        return {
            region: 'Special',
            label: timezone,
            name: timezone,
            keywords: timezone.toLowerCase(),
            regionalName: null,
        };
    }

    let region: string;
    let name: string;
    const keywords: string[] = [];
    if (zone.name.includes('/')) {
        const lastSlash = zone.name.lastIndexOf('/');
        region = zone.name.slice(0, lastSlash);
        region = region.replaceAll('_', ' ');
        name = zone.name.slice(lastSlash + 1);
        name = name.replaceAll('_', ' ');

        // Ensure proper capitalization
        name = name.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
        region = region.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());

        keywords.push(region);
    } else {
        region = 'Special';
        name = zone.name;
    }

    keywords.push(name);

    const offset = zone.formatOffset(DateTime.now().toMillis(), 'short');
    keywords.push(offset);

    // The regional name is a short name for the timezone, e.g. 'AEDT', or 'EST'
    let regionalName: string | null = zone.offsetName(DateTime.now().toMillis(), {
        format: 'short',
    });

    // Not interested in the GMT offsets
    if (regionalName.includes('GMT')) {
        regionalName = null;
    }

    if (regionalName) {
        keywords.push(regionalName);
    }

    return {
        region,
        label: `${name} (${offset})`,
        name: zone.name,
        regionalName,
        keywords: keywords.join(' ').toLowerCase(),
    };
}

export function extractTimezoneGroups(options: TimezoneOption[]): GroupDefinition[] {
    const groups: GroupDefinition[] = [];
    const addedGroups = new Set<string>();

    for (const option of options) {
        if (addedGroups.has(option.region)) {
            continue;
        }
        addedGroups.add(option.region);

        groups.push({
            key: option.region,
            title: option.region,
        });
    }

    return groups;
}

export function describeTimezoneSimple(option: TimezoneOption): string {
    const zone = IANAZone.create(option.name);
    const fullName = option.name.replaceAll('_', ' ');

    const offset = zone.formatOffset(DateTime.now().toMillis(), 'short');

    return `${fullName} (${offset})`;
}

/**
 * Detects the browser's timezone and returns the timezone option for it.
 * @returns The timezone option for the browser's timezone
 */
export function getBrowserTimezone(): TimezoneOption {
    const name = new SystemZone().name;

    if (IANAZone.isValidZone(name)) {
        return makeTimezoneOption(name);
    } else {
        return makeTimezoneOption('UTC');
    }
}
