export interface BatteryTestTypeFilter {
    name: string;
    id: 'Custom' | 'Capacity' | 'Quick';
}

export interface BatteryTestNameFilter {
    name: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    id?: string;
}

export enum TaskDeviceTableColumn {
    Site = 'Site',
    State = 'State',
    Device = 'Name',
    Status = 'Status',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum NotInProgTestsTableColumn {
    Name = 'Name',
    Type = 'Type',
    Devices = 'DeviceCount',
    Status = 'Status',
    Date = 'Date',
    ScheduleRepeat = 'ScheduleRepeat',
    UsingSmartStart = 'UsingSmartStart',
    RunTime = 'RunTime',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum TaskExtraFilters {
    DateCreated = 'DateCreated',
    DateCommenced = 'DateCommenced',
    DateCompleted = 'DateCompleted',
    DateCancelled = 'DateCancelled',
    DateAborted = 'DateAborted',
    DateScheduled = 'DateScheduled',
    BatteryStringManufacturer = 'BatteryStringManufacturer',
    BatteryStringModel = 'BatteryStringModel',
}

export type PageTypes = 'scheduled' | 'completed' | 'cancelled';

export interface BatteryTestStatusFilter {
    name: string;
    id:
        | 'Passed'
        | 'Failed'
        | 'PartialFail'
        | 'PartialPass'
        | 'Aborted'
        | 'InProgress'
        | 'Scheduled'
        | 'Inconclusive'
        | 'Cancelled';
}

export enum SmartStartStatus {
    Yes = 'Yes',
    No = 'No',
}

export interface ScheduleRepeatFilter {
    name: string;
    id: 'Never' | 'Monthly' | 'BiMonthly' | 'Quarterly';
}
