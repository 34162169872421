import React, { ReactNode } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { UserTableColumn } from 'filters/user';
import { ColumnWithId } from 'layouts';

import { getDateTimeFormat } from '../../../lib/dateFormatter';
import { EmptyCell } from '../../../lib/table-columns';
import type { ManageUsersTableQuery$data, UserStatus } from './__generated__/ManageUsersTableQuery.graphql';

type User = ManageUsersTableQuery$data['users']['data'][number];

function renderRoles(result: User): ReactNode {
    if (result.type === 'External') {
        return <div className='italic'>Provided from external source</div>;
    }

    return result.roles?.join(', ');
}

const StatusToString: Record<UserStatus, string> = {
    Active: 'Active',
    Inactive: 'Inactive',
    Invited: 'Invited',
    PasswordResetRequired: 'Password Reset Required',
    VerificationRequired: 'Not Verified',
    Unknown: 'Unknown',
    '%future added value': 'Unknown',
};

const columnHelper = createColumnHelper<User>();

export const NameColumn = columnHelper.accessor('name', {
    id: UserTableColumn.Name,
    header: 'Name',
    meta: {
        maxWidth: '30rem',
    },
});
export const EmailColumn = columnHelper.accessor('email', {
    id: UserTableColumn.Email,
    header: 'Email',
    meta: {
        maxWidth: '15rem',
    },
});
export const RolesColumn = columnHelper.display({
    id: UserTableColumn.Roles,
    header: 'Roles',
    cell: ({ row }) => renderRoles(row.original),
});
export const CreatedAtColumn = columnHelper.accessor('createdAt', {
    id: UserTableColumn.CreatedAt,
    header: 'Created Date',
    cell: ({ getValue }) => {
        const value = getValue();
        return getDateTimeFormat(value);
    },
});
export const UpdatedAtColumn = columnHelper.accessor('updatedAt', {
    id: UserTableColumn.UpdatedAt,
    header: 'Updated Date',
    cell: ({ getValue }) => {
        const value = getValue();
        if (value) {
            return getDateTimeFormat(value);
        }

        return <EmptyCell text='Not updated' />;
    },
});
export const EnabledColumn = columnHelper.accessor('enabled', {
    id: UserTableColumn.Enabled,
    header: 'Enabled',
    cell: ({ getValue }) => {
        const value = getValue();
        return value ? 'Yes' : 'No';
    },
});
export const LastLoginColumn = columnHelper.accessor('lastLogin', {
    id: UserTableColumn.LastLogin,
    header: 'Last Login',
    cell: ({ getValue }) => {
        const value = getValue();
        if (value) {
            return getDateTimeFormat(value);
        }

        return <EmptyCell text='Never' />;
    },
});

export const StatusColumn = columnHelper.accessor('status', {
    id: UserTableColumn.Status,
    header: 'Status',
    cell: ({ getValue }) => {
        const value = getValue();
        return StatusToString[value] ?? 'Unknown';
    },
});

export const AllTableColumns = [
    NameColumn,
    EmailColumn,
    RolesColumn,
    CreatedAtColumn,
    UpdatedAtColumn,
    EnabledColumn,
    LastLoginColumn,
    StatusColumn,
] as ColumnWithId<UserTableColumn, User>[];

export const DefaultTableColumns = [NameColumn, EmailColumn, StatusColumn, RolesColumn] as ColumnWithId<
    UserTableColumn,
    User
>[];
