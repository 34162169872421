/**
 * @generated SignedSource<<d538818d73235cdd2259cc929abd9a07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MetricAggregationOperation = "Average" | "Maximum" | "Minimum" | "Sum" | "%future added value";
export type MetricAggregationSettingIn = {
  metric: string;
  op?: MetricAggregationOperation | null;
};
export type metricLoaderQuery$variables = {
  devices: ReadonlyArray<string>;
  endDate: string;
  interval: string;
  metrics: ReadonlyArray<MetricAggregationSettingIn>;
  startDate: string;
};
export type metricLoaderQuery$data = {
  readonly aggregateMetrics: {
    readonly metrics: ReadonlyArray<{
      readonly metric: string;
      readonly op: MetricAggregationOperation;
      readonly values: ReadonlyArray<{
        readonly timestamp: string;
        readonly value: number | null;
      }>;
    }>;
  };
};
export type metricLoaderQuery = {
  response: metricLoaderQuery$data;
  variables: metricLoaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "devices"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metrics"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "from",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "interval",
        "variableName": "interval"
      },
      {
        "kind": "Variable",
        "name": "metrics",
        "variableName": "metrics"
      },
      {
        "kind": "Variable",
        "name": "onlyDevices",
        "variableName": "devices"
      },
      {
        "kind": "Variable",
        "name": "to",
        "variableName": "endDate"
      }
    ],
    "concreteType": "MetricAggregationResult",
    "kind": "LinkedField",
    "name": "aggregateMetrics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetricAggregationSingleResult",
        "kind": "LinkedField",
        "name": "metrics",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metric",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "op",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TimestampMetric",
            "kind": "LinkedField",
            "name": "values",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "metricLoaderQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "metricLoaderQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "fba13d40e4ec9e54d820c3575c7a9710",
    "id": null,
    "metadata": {},
    "name": "metricLoaderQuery",
    "operationKind": "query",
    "text": "query metricLoaderQuery(\n  $startDate: Timestamp!\n  $endDate: Timestamp!\n  $interval: Duration!\n  $metrics: [MetricAggregationSettingIn!]!\n  $devices: [ID!]!\n) {\n  aggregateMetrics(onlyDevices: $devices, metrics: $metrics, from: $startDate, to: $endDate, interval: $interval) {\n    metrics {\n      metric\n      op\n      values {\n        timestamp\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f4bf703be8b6be36648d0fca6507a66";

export default node;
