/**
 *
 * @param filename The name of the csv file to be downloaded. If the filename does not end with ".csv", the function will append it automatically
 * @param headers The headers of the csv file.
 * @param rows The body of the csv file.
 *
 * This function is responsible to download the csv file. It validates the length of the headers and rows to ensure that the csv file is valid.
 */
export function exportAsCsv(filename: string, headers: string[], rows: string[][]) {
    if (!filename.endsWith('.csv')) {
        filename = `${filename}.csv`;
    }

    if (headers.length === 0) {
        return;
    }

    if (rows.length === 0) {
        return;
    }

    for (const row of rows) {
        if (row.length !== headers.length) {
            return;
        }
    }

    const csvHeader: string = headers.join(',');
    const csvRows: string[] = rows.map((row: string[]) => {
        return row
            .map(item => {
                if (item.includes('"')) {
                    return `"${item.replace(/"/g, '""')}"`;
                } else if (item.includes(',')) {
                    return `"${item}"`;
                } else {
                    return item;
                }
            })
            .join(',');
    });

    const csv = [csvHeader, ...csvRows].join('\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
}
