/**
 * @generated SignedSource<<a9b8e6e4c59c74a985593e2e43206c3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTechnologyType = "LeadAcidAGM" | "LeadAcidFlooded" | "LeadAcidGel" | "LeadAcidOther" | "LeadAcidTPPL" | "LeadAcidVRLA" | "LithiumIon" | "LithiumIronPhosphate" | "LithiumPolymer" | "NickelCadmium" | "NickelMetalHydride" | "Other" | "Supercapacitor" | "%future added value";
export type BatteryTypeErrorType = "DuplicateDischargeTable" | "DuplicateModelName" | "EmptyDischargeTable" | "InvalidCellCapacity" | "InvalidCellCapacityRating" | "InvalidCellMaximumAllowedVoltage" | "InvalidCellMaximumBoostVoltage" | "InvalidCellMaximumFloatVoltage" | "InvalidCellMinimumAllowedVoltage" | "InvalidCellMinimumBoostVoltage" | "InvalidCellMinimumFloatVoltage" | "InvalidCellReferenceTemperature" | "InvalidCellTypicalVoltage" | "InvalidCellsPerBloc" | "InvalidPeukertsConstant" | "InvalidTableDischargeTime" | "InvalidTableDischargeValue" | "InvalidTableEodv" | "MissingManufacturerName" | "MissingModelName" | "Noop" | "%future added value";
export type DischargeTableType = "Current" | "Power" | "%future added value";
export type BatteryTypeUpdateIn = {
  attributes?: ReadonlyArray<AttributeIn> | null;
  cells?: BatteryCellDefinitionUpdateIn | null;
  cellsPerBloc?: number | null;
  dischargeTables?: ReadonlyArray<DischargeTableIn> | null;
  manufacturer?: string | null;
  model?: string | null;
  peukertsConstant?: number | null;
  technology?: BatteryTechnologyType | null;
};
export type BatteryCellDefinitionUpdateIn = {
  capacity?: number | null;
  capacityRating?: number | null;
  maximumAllowedVoltage?: number | null;
  maximumBoostVoltage?: number | null;
  maximumFloatVoltage?: number | null;
  minimumAllowedVoltage?: number | null;
  minimumBoostVoltage?: number | null;
  minimumFloatVoltage?: number | null;
  referenceTemperature?: number | null;
  typicalVoltage?: number | null;
};
export type DischargeTableIn = {
  endOfDischargeVoltage: number;
  rows: ReadonlyArray<DischargeTableRowIn>;
  type: DischargeTableType;
};
export type DischargeTableRowIn = {
  time: number;
  value: number;
};
export type AttributeIn = {
  name: string;
  value: string;
};
export type mutations_EditBatteryTypeMutation$variables = {
  id: string;
  type: BatteryTypeUpdateIn;
};
export type mutations_EditBatteryTypeMutation$data = {
  readonly editBatteryType: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<{
      readonly row: number | null;
      readonly table: number | null;
      readonly type: BatteryTypeErrorType;
    }>;
  } | null;
};
export type mutations_EditBatteryTypeMutation = {
  response: mutations_EditBatteryTypeMutation$data;
  variables: mutations_EditBatteryTypeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BatteryType",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTypeProblem",
      "kind": "LinkedField",
      "name": "problems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "table",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "row",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BatteryTypeProblemResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutations_EditBatteryTypeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editBatteryType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutations_EditBatteryTypeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editBatteryType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb4b926edb2ae87f8986c4d3a5b0d560",
    "id": null,
    "metadata": {},
    "name": "mutations_EditBatteryTypeMutation",
    "operationKind": "mutation",
    "text": "mutation mutations_EditBatteryTypeMutation(\n  $id: ID!\n  $type: BatteryTypeUpdateIn!\n) {\n  editBatteryType(id: $id, type: $type) {\n    __typename\n    ... on BatteryType {\n      id\n    }\n    ... on BatteryTypeProblemResponse {\n      problems {\n        type\n        table\n        row\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5bd5091609bd516d1ab0c717ca9d9a5";

export default node;
