import React from 'react';

import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { TimeRangeFilter } from 'components/FilterTimeRangeSelect/common';
import {
    ACPowerIncidentCountRangeFilterUI,
    ACPowerMTBFRangeFilterUI,
    ACPowerMTTRRangeFilterUI,
    ACPowerReliabilityRankRangeFilterUI,
    ACPowerReliabilityTimeRangeFilterUI,
    ACPowerTotalOutageDurationRangeFilterUI,
    decodeTimeRange,
    encodeTimeRange,
} from 'filters/ac-power';
import { SiteStateFilterUI } from 'filters/site/components/SiteStateFilterUI';
import { IntRangeFilter } from 'lib/__generated__/queries_getAllDeviceIdsQuery.graphql';
import { formatRangeWithString, formatRangeWithUnit } from 'lib/units';
import { intervalToHuman } from 'views/reports/ac-power/common';
import { TimeRange } from 'views/reports/ac-power/settings';

import {
    AttributeTypeMap,
    FilterDefinition,
    FilterValueMap,
    RangeFilter,
    decodeDateRangeFilter,
    decodeRangeFilter,
    decodeTimeRangeFilter,
    encodeDateRangeFilter,
    encodeRangeFilter,
    encodeTimeRangeFilter,
} from '../common';
import { formatDateFilter } from '../common/util';
import { SiteNameFilter, SiteNameFilterUI, SiteStateFilter } from '../site';
import { decodeSiteNameFilter, decodeSiteStateFilter } from '../site/decode';
import { encodeSiteNameFilter, encodeSiteStateFilter } from '../site/encode';
import {
    DeviceBatteryCapacityRemainingFilterUI,
    DeviceBatteryCapacityTotalFilterUI,
    DeviceBatteryEnergyTotalFilterUI,
    DeviceBatteryReserveTimeFilterUI,
    DeviceBatteryStateOfHealthFilterUI,
    DeviceBatteryStatusFilterUI,
    DeviceBatteryStringFilterUI,
    DeviceBatteryTemperatureFilterUI,
    DeviceMonitorOnlyFilterUI,
    DeviceSNMPVersionFilterUI,
    DeviceStatusFilterUI,
} from './components';
import { DeviceBatteryDateFilterUI } from './components/DeviceBatteryDateFilterUI';
import { DeviceNameFilterUI } from './components/DeviceNameFilterUI';
import { DeviceTypeFilterUI } from './components/DeviceTypeFilterUI';
import {
    decodeDeviceBatteryStatusFilter,
    decodeDeviceMonitorOnlyStatusFilter,
    decodeDeviceNameFilter,
    decodeDeviceSNMPVersionFilter,
    decodeDeviceStatusFilter,
    decodeDeviceTypeFilter,
} from './decode';
import {
    encodeDeviceBatteryStatusFilter,
    encodeDeviceMonitorOnlyStatusFilter,
    encodeDeviceNameFilter,
    encodeDeviceSNMPVersionFilter,
    encodeDeviceStatusFilter,
    encodeDeviceTypeFilter,
} from './encode';
import {
    DeviceBatteryStatusFilter,
    DeviceExtraFilters,
    DeviceLastTestedDateFilter,
    DeviceNameFilter,
    DeviceSNMPVersionFilter,
    DeviceStatusFilter,
    DeviceTableColumn,
    DeviceTypeFilter,
    MonitorOnlyStatus,
    TestDeviceExtraFilters,
    TestDeviceTableColumn,
} from './types';

export type DeviceColumnFilterMap = {
    [DeviceTableColumn.Name]: DeviceNameFilter[];
    [DeviceTableColumn.Site]: SiteNameFilter[];
    [DeviceTableColumn.State]: SiteStateFilter[];
    [DeviceTableColumn.Type]: DeviceTypeFilter[];
    [DeviceTableColumn.LastTested]: DeviceLastTestedDateFilter | null;
    [DeviceTableColumn.SnmpVersion]: DeviceSNMPVersionFilter[];
    [DeviceTableColumn.DeviceStatus]: DeviceStatusFilter[];
    [DeviceTableColumn.BatteryStatus]: DeviceBatteryStatusFilter[];
    [DeviceTableColumn.MonitorOnly]: MonitorOnlyStatus | null;
    [DeviceTableColumn.BatteryStringCount]: IntRangeFilter | null;
    [DeviceTableColumn.BatteryReserveTime]: RangeFilter | null;
    [DeviceTableColumn.BatteryStateOfHealth]: RangeFilter | null;
    [DeviceTableColumn.BatteryTemperature]: RangeFilter | null;
    [DeviceTableColumn.BatteryCapacityRemaining]: RangeFilter | null;
    [DeviceTableColumn.BatteryEnergyTotal]: RangeFilter | null;

    [DeviceTableColumn.ACReliability]: RangeFilter | null;
    [DeviceTableColumn.IncidentCount]: RangeFilter | null;
    [DeviceTableColumn.Mttr]: TimeRangeFilter | null;
    [DeviceTableColumn.Mtbf]: TimeRangeFilter | null;
    [DeviceTableColumn.OutageDurationSum]: TimeRangeFilter | null;
} & AttributeTypeMap;

export type DeviceExtraFilterMap = {
    [DeviceExtraFilters.BatteryInstallDate]: DateRangeFilter | null;
    [DeviceExtraFilters.BatteryManufactureDate]: DateRangeFilter | null;
    [DeviceExtraFilters.BatteryCapacityTotal]: RangeFilter | null;
    [DeviceExtraFilters.ACReliabilityTimeRange]: TimeRange | null;
};

export type DeviceAllFilterMap = DeviceColumnFilterMap & DeviceExtraFilterMap;

export const DefaultValues: FilterValueMap<DeviceColumnFilterMap> = {
    [DeviceTableColumn.Name]: [],
    [DeviceTableColumn.Site]: [],
    [DeviceTableColumn.State]: [],
    [DeviceTableColumn.Type]: [],
    [DeviceTableColumn.LastTested]: null,
    [DeviceTableColumn.SnmpVersion]: [],
    [DeviceTableColumn.DeviceStatus]: [],
    [DeviceTableColumn.BatteryStatus]: [],
    [DeviceTableColumn.MonitorOnly]: null,
    [DeviceTableColumn.BatteryStringCount]: null,
    [DeviceTableColumn.BatteryReserveTime]: null,
    [DeviceTableColumn.BatteryStateOfHealth]: null,
    [DeviceTableColumn.BatteryTemperature]: null,
    [DeviceTableColumn.BatteryCapacityRemaining]: null,
    [DeviceTableColumn.BatteryEnergyTotal]: null,

    [DeviceTableColumn.ACReliability]: null,
    [DeviceTableColumn.IncidentCount]: null,
    [DeviceTableColumn.Mttr]: null,
    [DeviceTableColumn.Mtbf]: null,
    [DeviceTableColumn.OutageDurationSum]: null,
};

export const DefaultExtraFilters: FilterValueMap<DeviceExtraFilterMap> = {
    [DeviceExtraFilters.BatteryInstallDate]: null,
    [DeviceExtraFilters.BatteryManufactureDate]: null,
    [DeviceExtraFilters.BatteryCapacityTotal]: null,
    [DeviceExtraFilters.ACReliabilityTimeRange]: null,
};

export const StaticDeviceFilterDefinitions: FilterDefinition<DeviceTableColumn>[] = [
    {
        id: DeviceTableColumn.Name,
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: DeviceTableColumn.Name,
        component: () => DeviceNameFilterUI,
        describeValue: (value: DeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: DeviceTableColumn.Type,
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: DeviceTableColumn.Type,
        component: () => DeviceTypeFilterUI,
        describeValue: (value: DeviceTypeFilter) => value.name,
        encodeValue: encodeDeviceTypeFilter,
        decodeValue: decodeDeviceTypeFilter,
    },
    {
        id: DeviceTableColumn.Site,
        type: 'multi',
        name: 'Site Name',
        category: 'Site Name',
        column: DeviceTableColumn.Site,
        component: () => SiteNameFilterUI,
        describeValue: (value: SiteNameFilter) => value.name,
        encodeValue: encodeSiteNameFilter,
        decodeValue: decodeSiteNameFilter,
    },
    {
        id: DeviceTableColumn.State,
        type: 'multi',
        name: 'Site State',
        category: 'Site State',
        column: DeviceTableColumn.State,
        component: () => SiteStateFilterUI,
        describeValue: (value: SiteStateFilter) => value.state,
        encodeValue: encodeSiteStateFilter,
        decodeValue: decodeSiteStateFilter,
    },
    {
        id: DeviceTableColumn.LastTested,
        type: 'single',
        name: 'Last Tested',
        category: 'Last Tested',
        column: DeviceTableColumn.LastTested,
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Last Tested Battery Test' />,
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: DeviceTableColumn.BatteryStringCount,
        type: 'single',
        name: 'Battery String Count',
        category: 'Battery String Count',
        column: DeviceTableColumn.BatteryStringCount,
        component: () => DeviceBatteryStringFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceTableColumn.BatteryReserveTime,
        type: 'single',
        name: 'Battery Reserve Time',
        category: 'Battery Reserve Time',
        column: DeviceTableColumn.BatteryReserveTime,
        component: () => DeviceBatteryReserveTimeFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceTableColumn.BatteryStateOfHealth,
        type: 'single',
        name: 'Battery State of Health',
        category: 'Battery State of Health',
        column: DeviceTableColumn.BatteryStateOfHealth,
        component: () => DeviceBatteryStateOfHealthFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceTableColumn.SnmpVersion,
        type: 'multi',
        name: 'SNMP Version',
        category: 'SNMP Version',
        column: DeviceTableColumn.SnmpVersion,
        component: () => DeviceSNMPVersionFilterUI,
        describeValue: (value: DeviceSNMPVersionFilter) => value.name,
        encodeValue: encodeDeviceSNMPVersionFilter,
        decodeValue: decodeDeviceSNMPVersionFilter,
    },
    {
        id: DeviceTableColumn.DeviceStatus,
        type: 'multi',
        name: 'Device Status',
        category: 'Device Status',
        column: DeviceTableColumn.DeviceStatus,
        component: () => DeviceStatusFilterUI,
        describeValue: (value: DeviceStatusFilter) => value.name,
        encodeValue: encodeDeviceStatusFilter,
        decodeValue: decodeDeviceStatusFilter,
    },
    {
        id: DeviceTableColumn.BatteryStatus,
        type: 'multi',
        name: 'Battery Status',
        category: 'Battery Status',
        column: DeviceTableColumn.BatteryStatus,
        component: () => DeviceBatteryStatusFilterUI,
        describeValue: (value: DeviceBatteryStatusFilter) => value.name,
        encodeValue: encodeDeviceBatteryStatusFilter,
        decodeValue: decodeDeviceBatteryStatusFilter,
    },
    {
        id: DeviceTableColumn.BatteryTemperature,
        type: 'single',
        name: 'Battery Temperature',
        category: 'Battery Temperature',
        column: DeviceTableColumn.BatteryTemperature,
        component: () => DeviceBatteryTemperatureFilterUI,
        describeValue: (value: RangeFilter, meta): string =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceExtraFilters.BatteryCapacityTotal,
        type: 'single',
        name: 'Battery Capacity Total',
        category: 'Battery Capacity Total',
        component: () => DeviceBatteryCapacityTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceTableColumn.BatteryCapacityRemaining,
        type: 'single',
        name: 'Battery Remaining Capacity',
        category: 'Battery Remaining Capacity',
        column: DeviceTableColumn.BatteryCapacityRemaining,
        component: () => DeviceBatteryCapacityRemainingFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceTableColumn.BatteryEnergyTotal,
        type: 'single',
        name: 'Battery Total Energy',
        category: 'Battery Total Energy',
        column: DeviceTableColumn.BatteryEnergyTotal,
        component: () => DeviceBatteryEnergyTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceTableColumn.ACReliability,
        type: 'single',
        name: 'Reliability Rank',
        category: 'Reliability Rank',
        column: DeviceTableColumn.ACReliability,
        component: () => ACPowerReliabilityRankRangeFilterUI,
        describeValue: (value: IntRangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceTableColumn.IncidentCount,
        type: 'single',
        name: 'Incident Count',
        category: 'Incident Count',
        column: DeviceTableColumn.IncidentCount,
        component: () => ACPowerIncidentCountRangeFilterUI,
        describeValue: (value: IntRangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: DeviceTableColumn.Mttr,
        type: 'single',
        name: 'MTTR',
        category: 'MTTR',
        column: DeviceTableColumn.Mttr,
        component: () => ACPowerMTTRRangeFilterUI,
        describeValue: (value: TimeRangeFilter): string => {
            if (value.type === 'predefined') {
                return value.preset;
            }

            // TODO: Make this better by showing hours/days when appropriate. Be mindful of readability, such as 3.2 hours vs 3 hours 12 minutes
            return formatRangeWithString(value.range.min, value.range.max, 'min');
        },
        encodeValue: encodeTimeRangeFilter,
        decodeValue: decodeTimeRangeFilter,
    },
    {
        id: DeviceTableColumn.Mtbf,
        type: 'single',
        name: 'MTBF',
        category: 'MTBF',
        column: DeviceTableColumn.Mtbf,
        component: () => ACPowerMTBFRangeFilterUI,
        describeValue: (value: TimeRangeFilter): string => {
            if (value.type === 'predefined') {
                return value.preset;
            }

            // TODO: Make this better by showing hours/days when appropriate. Be mindful of readability, such as 3.2 hours vs 3 hours 12 minutes
            return formatRangeWithString(value.range.min, value.range.max, 'min');
        },
        encodeValue: encodeTimeRangeFilter,
        decodeValue: decodeTimeRangeFilter,
    },
    {
        id: DeviceTableColumn.OutageDurationSum,
        type: 'single',
        name: 'Total Outage Duration',
        category: 'Total Outage Duration',
        column: DeviceTableColumn.OutageDurationSum,
        component: () => ACPowerTotalOutageDurationRangeFilterUI,
        describeValue: (value: TimeRangeFilter): string => {
            if (value.type === 'predefined') {
                return value.preset;
            }

            // TODO: Make this better by showing hours/days when appropriate. Be mindful of readability, such as 3.2 hours vs 3 hours 12 minutes
            return formatRangeWithString(value.range.min, value.range.max, 'min');
        },
        encodeValue: encodeTimeRangeFilter,
        decodeValue: decodeTimeRangeFilter,
    },
    {
        id: DeviceExtraFilters.BatteryInstallDate,
        type: 'single',
        name: 'Battery Install Date',
        category: 'Battery Install Date',
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Battery Install Date' />,
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: DeviceExtraFilters.BatteryManufactureDate,
        type: 'single',
        name: 'Battery Manufacture Date',
        category: 'Battery Manufacture Date',
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Battery Manufacture Date' />,
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: DeviceTableColumn.MonitorOnly,
        type: 'single',
        name: 'Monitor Only',
        category: 'Monitor Only',
        column: DeviceTableColumn.MonitorOnly,
        component: () => DeviceMonitorOnlyFilterUI,
        describeValue: (value: MonitorOnlyStatus) => value,
        encodeValue: encodeDeviceMonitorOnlyStatusFilter,
        decodeValue: decodeDeviceMonitorOnlyStatusFilter,
    },
    {
        id: DeviceExtraFilters.ACReliabilityTimeRange,
        type: 'single',
        name: 'AC Reliability Time Range',
        category: 'AC Reliability Time Range',
        component: () => ACPowerReliabilityTimeRangeFilterUI,
        describeValue: (value: TimeRange): string => intervalToHuman(value, { prefix: 'Last ' }),
        encodeValue: encodeTimeRange,
        decodeValue: decodeTimeRange,
    },
];

export type TestDeviceColumnFilterMap = {
    [TestDeviceTableColumn.Name]: DeviceNameFilter[];
    [TestDeviceTableColumn.Site]: SiteNameFilter[];
    [TestDeviceTableColumn.State]: SiteStateFilter[];
    [TestDeviceTableColumn.LastTested]: DeviceLastTestedDateFilter | null;
};

export type TestDeviceExtraFilterMap = {
    [TestDeviceExtraFilters.BatteryInstallDate]: DateRangeFilter | null;
    [TestDeviceExtraFilters.BatteryManufactureDate]: DateRangeFilter | null;
    [TestDeviceExtraFilters.BatteryCapacityTotal]: RangeFilter | null;
    [TestDeviceExtraFilters.Type]: DeviceTypeFilter[];
    [TestDeviceExtraFilters.SnmpVersion]: DeviceSNMPVersionFilter[];
    [TestDeviceExtraFilters.DeviceStatus]: DeviceStatusFilter[];
    [TestDeviceExtraFilters.BatteryStatus]: DeviceBatteryStatusFilter[];
    [TestDeviceExtraFilters.BatteryStringCount]: IntRangeFilter | null;
    [TestDeviceExtraFilters.BatteryReserveTime]: RangeFilter | null;
    [TestDeviceExtraFilters.BatteryStateOfHealth]: RangeFilter | null;
    [TestDeviceExtraFilters.BatteryTemperature]: RangeFilter | null;
    [TestDeviceExtraFilters.BatteryCapacityRemaining]: RangeFilter | null;
    [TestDeviceExtraFilters.BatteryEnergyTotal]: RangeFilter | null;
};

export type TestDeviceAllFilterMap = TestDeviceColumnFilterMap & TestDeviceExtraFilterMap;

export const TestDeviceDefaultValues: FilterValueMap<TestDeviceColumnFilterMap> = {
    [TestDeviceTableColumn.Name]: [],
    [TestDeviceTableColumn.Site]: [],
    [TestDeviceTableColumn.State]: [],
    [TestDeviceTableColumn.LastTested]: null,
};

export const TestDeviceDefaultExtraFilters: FilterValueMap<TestDeviceExtraFilterMap> = {
    [TestDeviceExtraFilters.BatteryInstallDate]: null,
    [TestDeviceExtraFilters.BatteryManufactureDate]: null,
    [TestDeviceExtraFilters.BatteryCapacityTotal]: null,
    [TestDeviceExtraFilters.Type]: [],
    [TestDeviceExtraFilters.SnmpVersion]: [],
    [TestDeviceExtraFilters.DeviceStatus]: [],
    [TestDeviceExtraFilters.BatteryStatus]: [],
    [TestDeviceExtraFilters.BatteryStringCount]: null,
    [TestDeviceExtraFilters.BatteryReserveTime]: null,
    [TestDeviceExtraFilters.BatteryStateOfHealth]: null,
    [TestDeviceExtraFilters.BatteryTemperature]: null,
    [TestDeviceExtraFilters.BatteryCapacityRemaining]: null,
    [TestDeviceExtraFilters.BatteryEnergyTotal]: null,
};

export const StaticTestDeviceFilterDefinitions: FilterDefinition<TestDeviceTableColumn>[] = [
    {
        id: TestDeviceTableColumn.Name,
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: TestDeviceTableColumn.Name,
        component: () => DeviceNameFilterUI,
        describeValue: (value: DeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: TestDeviceExtraFilters.Type,
        type: 'multi',
        name: 'Type',
        category: 'Type',
        component: () => DeviceTypeFilterUI,
        describeValue: (value: DeviceTypeFilter) => value.name,
        encodeValue: encodeDeviceTypeFilter,
        decodeValue: decodeDeviceTypeFilter,
    },
    {
        id: TestDeviceTableColumn.Site,
        type: 'multi',
        name: 'Site Name',
        category: 'Site Name',
        column: TestDeviceTableColumn.Site,
        component: () => SiteNameFilterUI,
        describeValue: (value: SiteNameFilter) => value.name,
        encodeValue: encodeSiteNameFilter,
        decodeValue: decodeSiteNameFilter,
    },
    {
        id: TestDeviceTableColumn.State,
        type: 'multi',
        name: 'Site State',
        category: 'Site State',
        column: TestDeviceTableColumn.State,
        component: () => SiteStateFilterUI,
        describeValue: (value: SiteStateFilter) => value.state,
        encodeValue: encodeSiteStateFilter,
        decodeValue: decodeSiteStateFilter,
    },
    {
        id: TestDeviceTableColumn.LastTested,
        type: 'single',
        name: 'Last Tested',
        category: 'Last Tested',
        column: TestDeviceTableColumn.LastTested,
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Last Tested Battery Test' />,
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryStringCount,
        type: 'single',
        name: 'Battery String Count',
        category: 'Battery String Count',
        component: () => DeviceBatteryStringFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryReserveTime,
        type: 'single',
        name: 'Battery Reserve Time',
        category: 'Battery Reserve Time',
        component: () => DeviceBatteryReserveTimeFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryStateOfHealth,
        type: 'single',
        name: 'Battery State Of Health',
        category: 'Battery State Of Health',
        component: () => DeviceBatteryStateOfHealthFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.SnmpVersion,
        type: 'multi',
        name: 'SNMP Version',
        category: 'SNMP Version',
        component: () => DeviceSNMPVersionFilterUI,
        describeValue: (value: DeviceSNMPVersionFilter) => value.name,
        encodeValue: encodeDeviceSNMPVersionFilter,
        decodeValue: decodeDeviceSNMPVersionFilter,
    },
    {
        id: TestDeviceExtraFilters.DeviceStatus,
        type: 'multi',
        name: 'Device Health',
        category: 'Device Health',
        component: () => DeviceStatusFilterUI,
        describeValue: (value: DeviceStatusFilter) => value.name,
        encodeValue: encodeDeviceStatusFilter,
        decodeValue: decodeDeviceStatusFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryStatus,
        type: 'multi',
        name: 'Battery Status',
        category: 'Battery Status',
        component: () => DeviceBatteryStatusFilterUI,
        describeValue: (value: DeviceBatteryStatusFilter) => value.name,
        encodeValue: encodeDeviceBatteryStatusFilter,
        decodeValue: decodeDeviceBatteryStatusFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryTemperature,
        type: 'single',
        name: 'Battery Temperature',
        category: 'Battery Temperature',
        component: () => DeviceBatteryTemperatureFilterUI,
        describeValue: (value: RangeFilter, meta): string =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryCapacityTotal,
        type: 'single',
        name: 'Battery Capacity Total',
        category: 'Battery Capacity Total',
        component: () => DeviceBatteryCapacityTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryCapacityRemaining,
        type: 'single',
        name: 'Battery Remaining Capacity',
        category: 'Battery Remaining Capacity',
        component: () => DeviceBatteryCapacityRemainingFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryEnergyTotal,
        type: 'single',
        name: 'Battery Total Energy',
        category: 'Battery Total Energy',
        component: () => DeviceBatteryEnergyTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryInstallDate,
        type: 'single',
        name: 'Battery Install Date',
        category: 'Battery Install Date',
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Battery Install Date' />,
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TestDeviceExtraFilters.BatteryManufactureDate,
        type: 'single',
        name: 'Battery Manufacture Date',
        category: 'Battery Manufacture Date',
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Battery Manufacture Date' />,
        describeValue: formatDateFilter,
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
];
