import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';

import { formatGeneratorRunReportState } from '../../../lib/textFormatters';
import { GeneratorRunReportState, GeneratorRunReportStateFilter } from '../types';

export type StateFilterUIProps = CommonFilterProps<GeneratorRunReportStateFilter[]>;

export const StateOptions: GeneratorRunReportStateFilter[] = (
    ['Running', 'Analysing', 'Completed', 'Error', 'Finishing'] as GeneratorRunReportState[]
).map(state => ({ id: state, name: formatGeneratorRunReportState(state) }));

export const StateFilterUI: FC<StateFilterUIProps> = ({ current, onClearOrBack, onClose, onSubmit, hasPrevious }) => {
    return (
        <MultipleSelect
            options={StateOptions}
            active={current}
            title='Filter by report state'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
