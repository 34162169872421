/**
 * @generated SignedSource<<e115299127e69bbc462cf145dbb831aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTestScheduleErrorType = "DeviceBatteryMissing" | "InvalidCapacityBattery" | "InvalidCapacityBatteryInsufficient" | "InvalidCapacityEodv" | "InvalidCapacityMissingBattery" | "InvalidCustomDischarged" | "InvalidCustomDuration" | "InvalidCustomMinVoltage" | "InvalidCustomMissingRequired" | "InvalidDevice" | "InvalidName" | "InvalidQuickNoDefinedReserve" | "InvalidQuickReserveTime" | "InvalidQuickThreshold" | "InvalidScheduleTime" | "MissingDevices" | "Noop" | "NotPermitted" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type TaskRepeat = "BiMonthly" | "Monthly" | "Never" | "Quarterly" | "%future added value";
export type BatteryTestSettingsInput = {
  capacitySettings?: BatteryTestCapacityTypeInput | null;
  customSettings?: BatteryTestCustomTypeInput | null;
  devices: ReadonlyArray<string>;
  name: string;
  quickSettings?: BatteryTestQuickTypeInput | null;
  schedule?: BatteryTestScheduleInput | null;
  smartStart?: boolean | null;
  type: BatteryTestType;
};
export type BatteryTestCapacityTypeInput = {
  targets: ReadonlyArray<BatteryTestCapacityEODVTypePair>;
};
export type BatteryTestCapacityEODVTypePair = {
  batteryType: string;
  eodv: number;
};
export type BatteryTestQuickTypeInput = {
  reserveTime: number;
  threshold?: number | null;
};
export type BatteryTestCustomTypeInput = {
  maxDischarged?: number | null;
  maxDuration?: number | null;
  minVoltage?: number | null;
};
export type BatteryTestScheduleInput = {
  repeat: TaskRepeat;
  time?: string | null;
};
export type RepeatTaskModal_RepeatTaskMutation$variables = {
  task: BatteryTestSettingsInput;
};
export type RepeatTaskModal_RepeatTaskMutation$data = {
  readonly addBatteryTest: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<BatteryTestScheduleErrorType>;
  } | null;
};
export type RepeatTaskModal_RepeatTaskMutation = {
  response: RepeatTaskModal_RepeatTaskMutation$data;
  variables: RepeatTaskModal_RepeatTaskMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "task"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "settings",
    "variableName": "task"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problems",
      "storageKey": null
    }
  ],
  "type": "BatteryTestScheduleProblemResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RepeatTaskModal_RepeatTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addBatteryTest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RepeatTaskModal_RepeatTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addBatteryTest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71917b5e9d09881bcc49ebf8f96352b3",
    "id": null,
    "metadata": {},
    "name": "RepeatTaskModal_RepeatTaskMutation",
    "operationKind": "mutation",
    "text": "mutation RepeatTaskModal_RepeatTaskMutation(\n  $task: BatteryTestSettingsInput!\n) {\n  addBatteryTest(settings: $task) {\n    __typename\n    ... on BatteryTest {\n      id\n    }\n    ... on BatteryTestScheduleProblemResponse {\n      problems\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0240abbe4dea94a60e95a57422fecef9";

export default node;
