import React, { FC, useState } from 'react';
import { useFragment } from 'react-relay';
import { useParams } from 'react-router-dom';

import {
    BarDataType,
    ChartArea,
    ChartMarker,
    DateLineChart,
    Domain,
    LineChartDataPoint,
    LineChartSeries,
    ListView,
    Menu,
    StackedHorizontalBar,
    Theme,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { NBSP } from 'constants/';
import humanizeDuration, { Unit } from 'humanize-duration';
import { useUserPermissions } from 'lib/auth';
import { MenuItemGroup } from 'lib/menu';
import { numberToLocaleString, percentageToStringCapped } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { formatValueWithString } from 'lib/units';
import { DateTime, Duration } from 'luxon';

import { DeviceACPower_device$data, DeviceACPower_device$key } from './__generated__/DeviceACPower_device.graphql';
import { FeedDisplay } from './components';

type ACPowerSection = NonNullable<DeviceACPower_device$data['acPower']>;
type Feed = NonNullable<ACPowerSection['feeds']['data']>[number];
type Thresholds = ACPowerSection['threshold'];

export const FeedColours: string[] = [
    Theme.eggplantDark,
    Theme.mauveDark,
    Theme.clayDark,
    Theme.eggplantRegular,
    Theme.mauveRegular,
    Theme.clayRegular,
];

export interface DeviceACPowerProps {
    device: DeviceACPower_device$key;
    deviceId: string;
    timeRange: Duration;
}

export const DeviceACPower: FC<DeviceACPowerProps> = ({ device, deviceId, timeRange }) => {
    const { siteId } = useParams();
    const { hasAssetsWrite, hasAssetsRead } = useUserPermissions();
    const data = useFragment(Fragment, device);
    const navigate = useExtendedNavigate();

    let durationUnits: Unit[];
    if (timeRange.as('days') >= 1) {
        durationUnits = ['h', 'm'];
    } else {
        durationUnits = ['d', 'h', 'm'];
    }

    const humanizeOpts = { largest: 1, round: true, units: durationUnits };
    const timeRangeString = humanizeDuration(timeRange.as('milliseconds'), humanizeOpts);

    const uptime = data.acPower?.uptime?.percentage ? Math.round(data.acPower.uptime.percentage) : 0;

    const uptimeBarData: BarDataType[] = [];

    if (uptime > 0) {
        uptimeBarData.push({
            label: 'Online',
            value: uptime,
            bgClass: 'bg-eggplantRegular',
        });
    }

    if (uptime < 100) {
        uptimeBarData.push({
            label: 'Offline',
            value: 100 - uptime,
            bgClass: 'bg-coralRegular',
        });
    }

    const startTime = DateTime.local().minus(timeRange);
    const endTime = DateTime.local();

    const chartXDomain: Domain<Date> = [startTime.startOf('hour').toJSDate(), endTime.endOf('hour').toJSDate()];
    let chartYDomain: Domain;
    if (!data.acPower?.feeds?.data?.length) {
        chartYDomain = [220, 260];
    } else {
        chartYDomain = ['dataMin-5', 'dataMax+5'];
    }

    const feeds = data.acPower?.feeds?.data ?? [];
    const [series, setSeries] = useState<LineChartSeries<Date>[]>(() => {
        const seriesData: LineChartSeries<Date>[] = [];
        feeds.forEach((feed, index) => {
            let feedData: LineChartDataPoint<Date>[];

            if (feed.metrics?.voltage?.values) {
                feedData = feed.metrics.voltage.values.map<LineChartDataPoint<Date>>(value => ({
                    key: new Date(value.timestamp),
                    value: value.value,
                }));
            } else {
                feedData = [];
            }

            if (feedData) {
                seriesData.push({
                    id: feed.label,
                    data: feedData,
                    color: FeedColours[index % FeedColours.length],
                    name: `Feed ${feed.label}`,
                    hidden: false,
                });
            }
        });

        return seriesData;
    });

    const hiddenFeeds = series.filter(series => series.hidden && series.id).map(series => series.id!);

    const eventMarkers = createIncidentMarkersFromFeeds(
        feeds.filter(feed => !hiddenFeeds.includes(feed.id)),
        // NOTE: The fallback thresholds don't matter as they are only used when there is no data, hence no markers
        data.acPower?.threshold ?? {
            outage: 0,
            underVoltage: 0,
            overVoltage: 0,
        }
    );
    const noDataMarkers = createNoDataMarkersFromFeeds(feeds.filter(feed => !hiddenFeeds.includes(feed.id)));
    const allMarkers = [...eventMarkers, ...noDataMarkers];

    const onLegendClickHandler = (name: string) => {
        setSeries(prevSeries => {
            const newSeries = prevSeries.map(series => {
                if (series.name === name) {
                    return {
                        ...series,
                        hidden: !series.hidden,
                    };
                }

                return series;
            });

            return newSeries;
        });
    };

    const toggleFeedVisibility = (feedId: string) => {
        setSeries(prevSeries => {
            const newSeries = prevSeries.map(series => {
                if (series.id === feedId) {
                    return {
                        ...series,
                        hidden: !series.hidden,
                    };
                }

                return series;
            });

            return newSeries;
        });
    };

    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-bold text-xl'>AC Power</div>
                <div className='flex flex-col justify-start items-end flex-grow font-bold text-xl'>
                    <div>{percentageToStringCapped(uptime)}</div>
                    <div className='text-xs font-normal'>Uptime last {timeRangeString}</div>
                </div>
                <div>
                    <Menu
                        id={`device-menu-${deviceId}`}
                        groups={[
                            { key: MenuItemGroup.Assets, title: MenuItemGroup.Assets },
                            { key: MenuItemGroup.Reports, title: MenuItemGroup.Reports },
                        ]}
                        menuItems={[
                            {
                                name: hasAssetsWrite ? 'Edit device' : 'View device',
                                onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                                disabled: !hasAssetsRead,
                                group: MenuItemGroup.Assets,
                            },
                            {
                                name: 'AC power report',
                                onClick: () =>
                                    navigate({
                                        pathname: Paths.ReportACPowerSiteViewDevice,
                                        params: { siteId: siteId!, deviceId: deviceId },
                                    }),
                                group: MenuItemGroup.Reports,
                            },
                        ]}
                        variant='small'
                    />
                </div>
            </div>
            <StackedHorizontalBar
                data={uptimeBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }
                    return humanizeDuration(timeRange.as('milliseconds') * (value / 100), humanizeOpts);
                }}
            />
            <ListView title='Feeds' view='grid'>
                <FeedDisplay
                    feeds={feeds}
                    health={data.health}
                    deviceId={deviceId}
                    hiddenFeeds={hiddenFeeds}
                    toggleFeedVisibility={toggleFeedVisibility}
                />
            </ListView>
            <ChartArea title={`Last ${timeRangeString}`}>
                <DateLineChart
                    series={series}
                    axisUnits
                    unit={`${NBSP}V`}
                    aspectRatio={2.2}
                    yDomain={chartYDomain}
                    xDomain={chartXDomain}
                    formatTooltipLabel={(label: string | null, data: LineChartDataPoint<Date>) => {
                        const timeToNow = DateTime.fromJSDate(data.key).diffNow().negate();

                        if (timeToNow.as('hours') < 1) {
                            return 'Now';
                        } else {
                            return `${humanizeDuration(timeToNow.as('milliseconds'), { largest: 1, round: true })} ago`;
                        }
                    }}
                    formatTooltipValue={(value: number | null) =>
                        formatValueWithString(numberToLocaleString(value, 0), 'V')
                    }
                    xTicks={8}
                    end
                    formatEndTick={() => 'Now'}
                    markers={allMarkers}
                    legend
                    legendLayout='horizontal'
                    legendOnClick={onLegendClickHandler}
                />
            </ChartArea>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceACPower_device on Device {
        id
        health
        acPower {
            uptime(from: $begin, to: $end) {
                percentage
            }
            threshold {
                outage
                underVoltage
                overVoltage
            }
            feeds {
                data {
                    id
                    label
                    metrics {
                        voltage(begin: $begin, end: $end, interval: "PT15M") {
                            values {
                                timestamp
                                value
                            }
                        }
                    }
                    ...FeedTile_data
                }
            }
        }
    }
`;

const OutageColor = Theme.coralRegular;
const UnderVoltageColor = Theme.mustardRegular;
const OverVoltageColor = Theme.mustardRegular;

enum Status {
    Outage,
    UnderVoltage,
    OverVoltage,
    Normal,
}

const MarkerColors: Record<Status, string> = {
    [Status.Outage]: OutageColor,
    [Status.UnderVoltage]: UnderVoltageColor,
    [Status.OverVoltage]: OverVoltageColor,
    [Status.Normal]: 'transparent', // Not valid for markers
};

const MarkerLabelsFull: Record<Status, string> = {
    [Status.Outage]: 'Outage',
    [Status.UnderVoltage]: 'Undervoltage',
    [Status.OverVoltage]: 'Overvoltage',
    [Status.Normal]: 'Normal', // Not valid for markers
};

const MarkerLabelsPartial: Record<Status, string> = {
    [Status.Outage]: 'Partial outage',
    [Status.UnderVoltage]: 'Partial undervoltage',
    [Status.OverVoltage]: 'Partial overvoltage',
    [Status.Normal]: 'Normal', // Not valid for markers
};

function createIncidentMarkersFromFeeds(feeds: Feed[], thresholds: Thresholds): ChartMarker<Date>[] {
    const markers: ChartMarker<Date>[] = [];

    // First find the feeds which in use
    const activeFeeds = new Set<string>();
    for (const feed of feeds) {
        // Feed is active if it has a value above 0
        if (!feed.metrics.voltage) {
            continue;
        }

        const voltageReadings = feed.metrics.voltage.values;
        const hasActiveValue = voltageReadings.some(value => value.value != null && value.value > 0);

        if (hasActiveValue) {
            activeFeeds.add(feed.id);
        }
    }

    // group by timestamp
    interface FeedValue {
        feedId: string;
        voltage: number;
    }

    const valuesByTimestamp = new Map<string, FeedValue[]>();
    let latestTimestamp = '';
    for (const feed of feeds) {
        const voltageReadings = feed.metrics?.voltage?.values ?? [];

        for (const value of voltageReadings) {
            if (value.value == null) {
                continue;
            }

            let feedValues = valuesByTimestamp.get(value.timestamp);

            if (feedValues == null) {
                feedValues = [];
                valuesByTimestamp.set(value.timestamp, feedValues);
            }

            feedValues.push({ feedId: feed.id, voltage: value.value });

            // Values are expected to be sorted by timestamp
            latestTimestamp = value.timestamp;
        }
    }

    // Determine markers
    let currentMarkerStartTimestamp: string | null = null;
    let currentMarkerStatus: Status | null = null;
    let currentMarkerPartial = false;

    valuesByTimestamp.forEach((values, timestamp) => {
        let status = Status.Normal;
        const affectedFeeds = new Set<string>();

        for (const value of values) {
            if (!activeFeeds.has(value.feedId)) {
                continue;
            }

            if (value.voltage <= thresholds.outage) {
                status = Status.Outage;
                affectedFeeds.add(value.feedId);
            } else if (value.voltage <= thresholds.underVoltage) {
                status = Status.UnderVoltage;
                affectedFeeds.add(value.feedId);
            } else if (value.voltage >= thresholds.overVoltage) {
                status = Status.OverVoltage;
                affectedFeeds.add(value.feedId);
            }
        }

        const isPartialIncident = affectedFeeds.size !== activeFeeds.size;

        if (currentMarkerStartTimestamp && currentMarkerStatus !== status) {
            // Output a marker
            const markerEndTimestamp = timestamp;
            const markerColor = MarkerColors[currentMarkerStatus!];
            let markerLabel: string;

            if (currentMarkerPartial) {
                markerLabel = MarkerLabelsPartial[currentMarkerStatus!];
            } else {
                markerLabel = MarkerLabelsFull[currentMarkerStatus!];
            }

            markers.push({
                type: 'vertical',
                color: markerColor,
                x: new Date(currentMarkerStartTimestamp),
                label: markerLabel,
                labelPosition: 'insideBottomLeft',
                showLabelOnHover: true,
            });

            markers.push({
                type: 'area-vertical',
                color: markerColor,
                x1: new Date(currentMarkerStartTimestamp),
                x2: new Date(markerEndTimestamp),
                opacity: 0.4,
                below: true,
            });

            currentMarkerStartTimestamp = null;
            currentMarkerStatus = null;
            currentMarkerPartial = false;
        }

        if (!currentMarkerStartTimestamp && status !== Status.Normal) {
            // Start new marker
            currentMarkerStartTimestamp = timestamp;
            currentMarkerStatus = status;
            currentMarkerPartial = isPartialIncident;
        }
    });

    // Finish up markers if any
    if (currentMarkerStartTimestamp !== null) {
        // Output a marker
        const markerEndTimestamp = latestTimestamp;
        const markerColor = MarkerColors[currentMarkerStatus!];
        let markerLabel: string;

        if (currentMarkerPartial) {
            markerLabel = MarkerLabelsPartial[currentMarkerStatus!];
        } else {
            markerLabel = MarkerLabelsFull[currentMarkerStatus!];
        }

        markers.push({
            type: 'vertical',
            color: markerColor,
            x: new Date(currentMarkerStartTimestamp),
            label: markerLabel,
            labelPosition: 'insideBottomLeft',
            showLabelOnHover: true,
        });

        markers.push({
            type: 'area-vertical',
            color: markerColor,
            x1: new Date(currentMarkerStartTimestamp),
            x2: new Date(markerEndTimestamp),
            opacity: 0.4,
            below: true,
        });

        currentMarkerStartTimestamp = null;
        currentMarkerStatus = null;
        currentMarkerPartial = false;
    }

    return markers;
}

// Return shaded markers for when there is no data for EVERY feeds
function createNoDataMarkersFromFeeds(
    feeds: NonNullable<NonNullable<NonNullable<DeviceACPower_device$data['acPower']>['feeds']>['data']>
): ChartMarker<Date>[] {
    const markers: ChartMarker<Date>[] = [];
    const bandColor = Theme.eggplantExtraLight;
    const regionColor = Theme.eggplantLight;

    // group by timestamp
    const timestampValues: { [timestamp: string]: (number | null)[] } = {};
    for (const feed of feeds) {
        for (const value of feed.metrics?.voltage?.values ?? []) {
            if (timestampValues[value.timestamp] == null) {
                timestampValues[value.timestamp] = [];
            }
            timestampValues[value.timestamp].push(value.value);
        }
    }

    let noDataStartTimestamp;
    for (const timestamp of Object.keys(timestampValues)) {
        const values = timestampValues[timestamp];
        const areAllMissing = values.every(value => value == null);

        if (areAllMissing) {
            if (noDataStartTimestamp == null) {
                noDataStartTimestamp = timestamp;
            }
        }

        if (!areAllMissing && noDataStartTimestamp != null) {
            const noDataEndTimestamp = timestamp;
            markers.push({
                type: 'vertical',
                color: bandColor,
                x: new Date(noDataStartTimestamp),
                label: 'No data',
                labelPosition: 'insideBottomLeft',
                showLabelOnHover: true,
            });
            markers.push({
                type: 'area-vertical',
                color: regionColor,
                x1: new Date(noDataStartTimestamp),
                x2: new Date(noDataEndTimestamp),
                opacity: 0.4,
                below: true,
            });
            noDataStartTimestamp = null;
        }
    }

    return markers;
}
