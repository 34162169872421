import React, { FC, useEffect } from 'react';
import { PreloadedQuery, fetchQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import { PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { StateTitle } from 'lib/textFormatters';
import { DateTime } from 'luxon';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { useDocumentTitle } from '../../../../components';
import { ViewBatteryHealthTaskContentQuery as ViewBatteryHealthTaskContentQueryType } from './__generated__/ViewBatteryHealthTaskContentQuery.graphql';
import { StatusListContainer } from './subviews/StatusListContainer';
import { TaskOverview } from './subviews/TaskOverview';

interface ViewBatteryHealthTaskContentProps {
    queryRef: PreloadedQuery<ViewBatteryHealthTaskContentQueryType>;
}

export const ViewBatteryHealthTaskContent: FC<ViewBatteryHealthTaskContentProps> = ({ queryRef }) => {
    const props = usePreloadedQuery(ViewBatteryHealthTaskContentQuery, queryRef);

    let pageTitle: string;
    if (props.task?.name) {
        pageTitle = `${props.task.name}`;
    } else {
        pageTitle = 'Battery test';
    }
    useDocumentTitle(pageTitle);

    useLiveData(props, props.task?.id ?? '');

    // Live data
    if (props.task === null) {
        // Task not found
        return <ErrorNotFound />;
    }

    let title: string;
    switch (props.task?.overallState) {
        case 'Cancelled':
            title = StateTitle[props.task?.overallState];
            break;
        default:
            title =
                StateTitle[props.task?.testState ?? ''] ?? StateTitle[props.task?.overallState ?? ''] ?? 'Battery test';
            break;
    }

    return (
        <div className='space-y-6 mb-32' key={props.task.id}>
            <PageHeading value={title} />
            <div className='bg-white p-8 space-y-8'>
                <TaskOverview task={props.task} />
            </div>
            <div className='bg-white p-8'>
                <StatusListContainer id={props.task.id} task={props.task} />
            </div>
        </div>
    );
};

function useLiveData(data: ViewBatteryHealthTaskContentQueryType['response'], taskId: string) {
    const environment = useRelayEnvironment();
    const task = data.task;

    const taskState = task?.testState;
    const completedTime = task?.completedTime as string;
    const abortedTime = task?.abortedTime as string;
    const cancelledTime = task?.cancelledTime as string;

    let shouldBeLive = false;
    if (taskState === 'InProgress' || taskState === 'Scheduled') {
        shouldBeLive = true;
    } else if (completedTime || abortedTime || cancelledTime) {
        let finishedTime: string;

        if (completedTime) {
            finishedTime = completedTime;
        } else if (abortedTime) {
            finishedTime = abortedTime;
        } else {
            finishedTime = cancelledTime;
        }

        shouldBeLive = DateTime.now().diff(DateTime.fromISO(finishedTime), 'minutes').as('minutes') < 5;
    }

    useEffect(() => {
        if (!shouldBeLive) {
            return;
        }

        const observable = fetchQuery<ViewBatteryHealthTaskContentQueryType>(
            environment,
            ViewBatteryHealthTaskContentQuery,
            { id: taskId, page: 1 },
            { fetchPolicy: 'network-only' }
        );

        const subscription = observable.poll(5000).subscribe({});

        return () => {
            subscription.unsubscribe();
        };
    }, [environment, shouldBeLive, taskId]);
}

export const ViewBatteryHealthTaskContentQuery = graphql`
    query ViewBatteryHealthTaskContentQuery(
        $id: ID!
        $filters: DeviceFilter
        $deviceFilters: TaskDeviceFilter
        $orderBy: TaskDeviceOrdering
        $page: Int = 1
    ) {
        task(id: $id) {
            id
            ...TaskOverview_task
            ...StatusList_task
            ... on BatteryTest {
                name
                overallState
                testState
                completedTime
                commencedTime
                abortedTime
                cancelledTime
            }
        }
    }
`;
