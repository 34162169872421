import { RunTimeFilter } from 'components/FilterDurationSelect/common';
import { Duration } from 'luxon';

import {
    TaskBatteryTestStatusOptions,
    TaskBatteryTestTypeOptions,
    TaskScheduleRepeatFilterOptions,
    TaskUsingSmartStartOptions,
} from './components';
import {
    BatteryTestNameFilter,
    BatteryTestStatusFilter,
    BatteryTestTypeFilter,
    ScheduleRepeatFilter,
    SmartStartStatus,
} from './types';

export function decodeBatteryTestNameFilter(value: string): BatteryTestNameFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return {
        name: decodedValue,
        type: 'result',
    };
}

export function decodeBatteryTestTypeFilter(value: string): BatteryTestTypeFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = TaskBatteryTestTypeOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeBatteryTestStatusFilter(value: string): BatteryTestStatusFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = TaskBatteryTestStatusOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeBatteryTestUsingSmartStartFilter(value: string): SmartStartStatus | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = TaskUsingSmartStartOptions.find(option => option === decodedValue);
    if (!match) {
        return null;
    }
    return match;
}

export function decodeBatteryTestScheduleRepeatFilter(value: string): ScheduleRepeatFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = TaskScheduleRepeatFilterOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeBatteryTestRuntimeFilter(value: string): RunTimeFilter | null {
    const separator = '-';

    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    if (!decodedValue.includes(separator)) {
        return null;
    }

    if (decodedValue.split(separator).length !== 2) {
        return null;
    }

    const [durationStartString, durationEndString] = decodedValue.split(separator);

    if (!Duration.fromISO(durationStartString).isValid || !Duration.fromISO(durationEndString).isValid) {
        return null;
    }

    const durationStart = Duration.fromISO(durationStartString);
    const durationEnd = Duration.fromISO(durationEndString);

    if (durationStart > durationEnd) {
        return null;
    }

    return {
        type: 'custom',
        range: [durationStart.toISO(), durationEnd.toISO()],
    };
}
