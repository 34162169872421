import React, { Dispatch, FC, useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';

import { CrossIcon, FormikDropdownWithSearch, GroupDefinition, IconButton } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';

import { useSearcher } from '../../../../../lib/search-fetcher';
import { FormControlAction, FormControlActionTypes, FormControlParameters } from '../lib/reducer';
import { SiteReference } from '../schema';
import style from '../style.module.css';
import {
    SiteSelectorSuggestQuery,
    SiteSelectorSuggestQuery$data,
} from './__generated__/SiteSelectorSuggestQuery.graphql';

type SiteSuggestions = SiteSelectorSuggestQuery$data['suggestSite'];

const GroupDefinitions: GroupDefinition[] = [
    {
        key: 'recent',
        title: 'Recent sites',
    },
    {
        key: 'all',
        title: 'All sites',
    },
];

export interface SiteSelectorProps {
    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const SiteSelector: FC<SiteSelectorProps> = ({ formControlState, formControlDispatch }) => {
    const defaultSuggestionsRaw = useLazyLoadQuery<SiteSelectorSuggestQuery>(SuggestionQuery, { query: null });
    const defaultSuggestions = useMemo(
        () => reformatSuggestions(defaultSuggestionsRaw.suggestSite),
        [defaultSuggestionsRaw]
    );

    const [siteSuggestions, handleSearch, hasSearchError] = useSearcher<SiteSelectorSuggestQuery, SiteReference>(
        SuggestionQuery,
        search => ({ query: search }),
        data => reformatSuggestions(data.suggestSite)
    );

    const visibleSuggestions = siteSuggestions ?? defaultSuggestions;

    return (
        <>
            <div className={classNames('col-start-1', formControlState.addNewSite ? style.page_item_disabled : '')}>
                <FormikDropdownWithSearch
                    name='site'
                    placeHolder={'Site*'}
                    light
                    disabledText='Adding New Site'
                    disabled={formControlState.addNewSite}
                    required={!formControlState.addNewSite}
                    renderItemAsString={item => item.displayName}
                    items={visibleSuggestions}
                    onSearch={handleSearch}
                    variant='outlined'
                    testId='site-name'
                    noResultsText={search => {
                        if (hasSearchError) {
                            return 'Unable to search at this time';
                        }

                        if (search.length > 0) {
                            return 'No matching sites';
                        }

                        return 'No sites available';
                    }}
                    groups={GroupDefinitions}
                    groupKey='type'
                />
            </div>

            <div className='flex items-end col-start-2'>
                <IconButton
                    title={formControlState.addNewSite ? 'Use Existing Site' : 'New Site'}
                    size='small'
                    onClick={() =>
                        formControlDispatch({
                            type: FormControlActionTypes.AddNewSite,
                            payload: !formControlState.addNewSite,
                        })
                    }
                    icon={formControlState.addNewSite ? <CrossIcon /> : undefined}
                />
            </div>
        </>
    );
};

const SuggestionQuery = graphql`
    query SiteSelectorSuggestQuery($query: String) {
        suggestSite(query: $query, settings: { includeRecent: true, includeAll: true, recentLimit: 5, allLimit: 5 }) {
            recent {
                relatedId
                title
            }
            all {
                relatedId
                title
            }
        }
    }
`;

function reformatSuggestions(suggestions: SiteSuggestions): SiteReference[] {
    const output: SiteReference[] = [];

    if (suggestions.recent) {
        for (const suggestion of suggestions.recent) {
            output.push({
                type: 'recent',
                id: suggestion.relatedId,
                displayName: suggestion.title,
            });
        }
    }

    if (suggestions.all) {
        for (const suggestion of suggestions.all) {
            output.push({
                type: 'all',
                id: suggestion.relatedId,
                displayName: suggestion.title,
            });
        }
    }

    return output;
}
