import React, { FC, useMemo } from 'react';

import {
    DateLineChart,
    DomainAbsolute,
    LineChartDataPoint,
    LineChartSeries,
    LoadableContentArea,
    Theme,
    tickEveryTimeUnit,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'lib/query-helpers';
import { DateTime, Duration } from 'luxon';

import { Breakpoint, useBreakpoint } from '../../../../../../lib/breakpoints';
import { DeviceBatteryHealthQuery } from './__generated__/DeviceBatteryHealthQuery.graphql';

export interface DeviceBatteryHealthProps {
    device: string;
}

export const DeviceBatteryHealth: FC<DeviceBatteryHealthProps> = ({ device }) => {
    const breakpoint = useBreakpoint();

    const startTimestamp = DateTime.now().minus({ months: 12 }).toFormat('yyyy-LL-01');
    const endTimestamp = DateTime.now().toFormat('yyyy-LL-01');
    const interval = Duration.fromObject({ month: 1 });

    const {
        data: props,
        error,
        retry: retryMain,
    } = useQuery<DeviceBatteryHealthQuery>(
        graphql`
            query DeviceBatteryHealthQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
                device(id: $id) {
                    battery {
                        metrics {
                            timeRunningOnBattery(begin: $begin, end: $end, interval: $interval) {
                                start
                                end
                                bucket
                                values {
                                    timestamp
                                    value
                                }
                            }
                            batteryHealth(begin: $begin, end: $end, interval: $interval) {
                                start
                                end
                                bucket
                                values {
                                    timestamp
                                    value
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            id: device,
            begin: startTimestamp,
            end: endTimestamp,
            interval: interval.toISO(),
        },
        {
            fetchPolicy: 'network-only',
            networkCacheConfig: { metadata: { timeout: 10_000 } }, // has to be less or equal than the cap set in API
        }
    );
    // add a fixed x domain for the last 12 months from the last month
    const xDomain = useMemo<DomainAbsolute<Date>>(() => {
        const end = DateTime.now().minus({ month: 1 }).set({ day: 1 });
        let start = DateTime.now().minus({ months: 12 });
        start = DateTime.fromObject({
            year: start.year,
            month: start.month,
        });

        return [start.toJSDate(), end.toJSDate()];
    }, []);

    return (
        <LoadableContentArea
            data={props}
            error={!!error}
            onRetry={retryMain}
            render={props => {
                const timeOnBatteryLineData: LineChartDataPoint<Date>[] =
                    props.device!.battery!.metrics.timeRunningOnBattery!.values?.map(value => ({
                        key: new Date(value.timestamp as string),
                        value: value.value! * 100,
                    }));

                const batteryHealthLineData: LineChartDataPoint<Date>[] =
                    props.device!.battery!.metrics.batteryHealth!.values?.map(value => ({
                        key: new Date(value.timestamp as string),
                        value: value.value!,
                    }));

                const series: LineChartSeries<Date>[] = [
                    {
                        data: timeOnBatteryLineData,
                        color: Theme.coralRegular,
                        name: 'Time running on battery',
                    },
                    {
                        data: batteryHealthLineData,
                        color: Theme.eggplantLight,
                        strokeDash: '1 2',
                        name: 'Battery Health',
                    },
                ];
                return (
                    <DateLineChart
                        series={series}
                        aspectRatio={3}
                        unit='%'
                        axisUnits
                        yTicks={11}
                        yDomain={[0, 100]}
                        xDomain={xDomain}
                        xTicks={tickEveryTimeUnit('month')}
                        legend
                        legendSeparate={breakpoint < Breakpoint.Large}
                        legendWrapperStyle={{ paddingLeft: '0.25rem', paddingRight: '6rem' }}
                        formatTooltipLabel={(label: string | null, data: LineChartDataPoint<Date>) =>
                            `${data.key ? DateTime.fromJSDate(data.key).toFormat('LLL yyyy').toUpperCase() : ''}`
                        }
                    />
                );
            }}
        />
    );
};
