import React, { FC, useCallback, useState } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { BlocHealthTable, BlocSelectionIndicator, MetricsView } from '../components';
import { MetricsViewLive } from '../components/MetricsViewLive';
import { BlocId } from '../components/common';
import { BlocDataDisplay } from './BlocDataDisplay';
import { DataDisplay } from './DataDisplay';
import { TestResultDisplay_test$key } from './__generated__/TestResultDisplay_test.graphql';

interface TestResultDisplayProps {
    test: TestResultDisplay_test$key;
}

export const TestResultDisplay: FC<TestResultDisplayProps> = ({ test }) => {
    const [selectedBlocs, setSelectedBlocs] = useState<BlocId[]>([]);

    const testData = useFragment<TestResultDisplay_test$key>(
        graphql`
            fragment TestResultDisplay_test on DeviceBatteryTestResults
            @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
                id
                task {
                    name
                }
                state
                commencedTime
                completedTime
                abortedTime
                device {
                    id
                    battery {
                        metrics {
                            ...MetricsViewLive_metrics @arguments(unitTemperature: $unitTemperature)
                        }
                    }
                }
                ...TestParameterView_test
                ...MetricsView_test @arguments(unitTemperature: $unitTemperature)
                ...DataDisplay_test
                ...BlocDataDisplay_test
                ...BlocHealthTable_test
            }
        `,
        test
    );

    const testState = testData.state;

    const toggleBloc = useCallback(
        (selectedBloc: BlocId) => {
            let newState: BlocId[] = [];

            if (checkIfSelected(selectedBloc, selectedBlocs)) {
                newState = removeItemFromArray(selectedBloc, selectedBlocs);
            } else {
                newState = Array.from(selectedBlocs);
                newState.push(selectedBloc);
            }

            setSelectedBlocs(newState);
        },
        [selectedBlocs]
    );

    const resetToggledBlocs = useCallback(() => {
        setSelectedBlocs([]);
    }, []);

    return (
        <>
            {testData.state === 'InProgress' && <MetricsViewLive metrics={testData.device.battery.metrics} />}
            {testState !== 'InProgress' && <MetricsView test={testData} />}
            <BlocHealthTable
                selectedBlocs={selectedBlocs}
                toggleBloc={toggleBloc}
                resetToggledBlocs={resetToggledBlocs}
                test={testData}
            />
            <BlocSelectionIndicator blocs={selectedBlocs} resetToggledBlocs={resetToggledBlocs} />
            {selectedBlocs.length > 0 ? (
                <BlocDataDisplay test={testData} blocs={selectedBlocs} />
            ) : (
                <DataDisplay key={testData.id} test={testData} />
            )}
        </>
    );
};

export function checkIfSelected(selectedBloc: BlocId, selectedBlocs: BlocId[]): boolean {
    const foundArray = selectedBlocs.some(bloc => bloc.every((val, index) => val === selectedBloc[index]));

    return foundArray;
}

function removeItemFromArray(selectedBloc: BlocId, selectedBlocs: BlocId[]): BlocId[] {
    return selectedBlocs.filter(item => !item.every((val, index) => val === selectedBloc[index]));
}
