import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { MetricDisplay } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { numberToLocaleString } from 'lib/numberFormatters';
import { formatValueWithString, formatValueWithUnit } from 'lib/units';

import { MetricsViewLive_metrics$key } from './__generated__/MetricsViewLive_metrics.graphql';

interface MetricsViewLiveProps {
    metrics: MetricsViewLive_metrics$key | null;
}

export const MetricsViewLive: FC<MetricsViewLiveProps> = ({ metrics }) => {
    const userPreferences = useCurrentUserUnitsPref();

    const result = useFragment<MetricsViewLive_metrics$key>(
        graphql`
            fragment MetricsViewLive_metrics on DeviceBatteryMetrics
            @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
                originalCapacity(unit: AmpHour)
                latestVoltage(unit: Volts)
                latestCurrent(unit: Amps)
                latestTemperature(unit: $unitTemperature)
                latestRemainingCapacity(unit: AmpHour)
                latestUsedCapacity(unit: AmpHour)
            }
        `,
        metrics
    );

    let capacity;
    if (result && result.originalCapacity !== null && result.latestRemainingCapacity !== null) {
        capacity = `${numberToLocaleString(result.latestRemainingCapacity, 0)} / ${formatValueWithString(
            numberToLocaleString(result.originalCapacity, 0),
            'Ah'
        )}`;
    } else if (result && result.originalCapacity !== null) {
        capacity = `- / ${formatValueWithString(numberToLocaleString(result.originalCapacity, 0), 'Ah')}`;
    } else {
        capacity = '-';
    }

    let endVoltage;
    if (result && result.latestVoltage != null) {
        endVoltage = formatValueWithString(numberToLocaleString(result.latestVoltage, 1), 'V');
    } else {
        endVoltage = '-';
    }

    let avgTemperature;
    if (result && result.latestTemperature != null) {
        avgTemperature = formatValueWithUnit(
            numberToLocaleString(result.latestTemperature, 1),
            userPreferences.temperature
        );
    } else {
        avgTemperature = '-';
    }

    let avgCurrent;
    if (result && result.latestCurrent != null) {
        avgCurrent = formatValueWithString(numberToLocaleString(result.latestCurrent, 1), 'A');
    } else {
        avgCurrent = '-';
    }

    let discharged;
    if (result && result.latestUsedCapacity != null) {
        discharged = formatValueWithString(numberToLocaleString(result.latestUsedCapacity, 0), 'Ah');
    } else {
        discharged = '-';
    }

    return (
        <>
            <div className='grid grid-cols-3'>
                <MetricDisplay label='Remaining capacity' value={capacity} />
                <MetricDisplay label='Latest voltage' value={endVoltage} />
                <MetricDisplay label='Latest temperature' value={avgTemperature} />
                <MetricDisplay label='Latest current' value={avgCurrent} />
                <MetricDisplay label='Depth of discharge' value={discharged} />
                <MetricDisplay label='State of Health' value='-' />
            </div>
        </>
    );
};
