export enum UserTableColumn {
    Name = 'Name',
    Email = 'Email',
    Roles = 'Roles',
    CreatedAt = 'CreatedAt',
    UpdatedAt = 'UpdatedAt',
    Enabled = 'Enabled',
    LastLogin = 'LastLogin',
    Status = 'Status',
}
export enum UserExtraFilters {}
