import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { formatBatteryType } from 'lib/textFormatters';

import { CommonFilterProps } from '../../common/types';
import { BatteryTechnology, BatteryTypeTechnologyFilter } from '../types';

export type BatteryTypeTechnologyFilterUIProps = CommonFilterProps<BatteryTypeTechnologyFilter[]>;

export const BatteryTypeTechnologyOptions: BatteryTypeTechnologyFilter[] = [
    { id: BatteryTechnology.LeadAcidAGM, name: formatBatteryType(BatteryTechnology.LeadAcidAGM) },
    { id: BatteryTechnology.LeadAcidGel, name: formatBatteryType(BatteryTechnology.LeadAcidGel) },
    { id: BatteryTechnology.LeadAcidVRLA, name: formatBatteryType(BatteryTechnology.LeadAcidVRLA) },
    { id: BatteryTechnology.LeadAcidTPPL, name: formatBatteryType(BatteryTechnology.LeadAcidTPPL) },
    { id: BatteryTechnology.LithiumIon, name: formatBatteryType(BatteryTechnology.LithiumIon) },
    { id: BatteryTechnology.LithiumPolymer, name: formatBatteryType(BatteryTechnology.LithiumPolymer) },
    { id: BatteryTechnology.LithiumIronPhosphate, name: formatBatteryType(BatteryTechnology.LithiumIronPhosphate) },
    { id: BatteryTechnology.NickelCadmium, name: formatBatteryType(BatteryTechnology.NickelCadmium) },
    { id: BatteryTechnology.NickelMetalHydride, name: formatBatteryType(BatteryTechnology.NickelMetalHydride) },
    { id: BatteryTechnology.Supercapacitor, name: formatBatteryType(BatteryTechnology.Supercapacitor) },
];

export const BatteryTypeTechnologyFilterUI: FC<BatteryTypeTechnologyFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    return (
        <MultipleSelect
            options={BatteryTypeTechnologyOptions}
            active={current}
            title='Filter by battery technology'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.name === itemB.name}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
