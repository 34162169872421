import React, { FC } from 'react';
import { useLazyLoadQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { GatewayModuleDetailsQuery } from './__generated__/GatewayModuleDetailsQuery.graphql';

interface GatewayModuleDetailsProps {
    gatewayId: string;
}

export const GatewayModuleDetails: FC<GatewayModuleDetailsProps> = ({ gatewayId }) => {
    const data = useLazyLoadQuery<GatewayModuleDetailsQuery>(GatewayQuery, { id: gatewayId });

    let modulesDisplayName = '-';
    let modulesVersion = '-';

    if (data.gatewayInventory?.modules && data.gatewayInventory.modules.length > 0) {
        modulesDisplayName = data.gatewayInventory.modules[0].displayName;
        modulesVersion = data.gatewayInventory.modules[0].version;
    }

    return (
        <div className='text-xs'>
            <div data-testid='module_name'>
                <span className='font-light'>Module name: </span>
                <span className='font-normal'>{modulesDisplayName}</span>
            </div>
            <div data-testid='module_version'>
                <span className='font-light'>Module version: </span>
                <span className='font-normal'>{modulesVersion}</span>
            </div>
        </div>
    );
};

const GatewayQuery = graphql`
    query GatewayModuleDetailsQuery($id: ID!) {
        gatewayInventory(id: $id) {
            ... on GatewayInventoryError {
                error
            }
            ... on GatewayInventoryResult {
                modules {
                    displayName
                    version
                }
            }
        }
    }
`;
