/**
 * @generated SignedSource<<73af98490fb3e52623867befc706ca31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type columns_batteryTypeAttributeColumnsQuery$variables = {
  disableAttributeColumns: boolean;
};
export type columns_batteryTypeAttributeColumnsQuery$data = {
  readonly attributeNames?: ReadonlyArray<string>;
};
export type columns_batteryTypeAttributeColumnsQuery = {
  response: columns_batteryTypeAttributeColumnsQuery$data;
  variables: columns_batteryTypeAttributeColumnsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "disableAttributeColumns"
  }
],
v1 = [
  {
    "condition": "disableAttributeColumns",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "limit",
            "value": 1000
          },
          {
            "kind": "Literal",
            "name": "only",
            "value": "Battery"
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          },
          {
            "kind": "Literal",
            "name": "status",
            "value": "Active"
          }
        ],
        "kind": "ScalarField",
        "name": "attributeNames",
        "storageKey": "attributeNames(limit:1000,only:\"Battery\",search:\"\",status:\"Active\")"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "columns_batteryTypeAttributeColumnsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "columns_batteryTypeAttributeColumnsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cfa8f133636b447fb53a3b9e595178bd",
    "id": null,
    "metadata": {},
    "name": "columns_batteryTypeAttributeColumnsQuery",
    "operationKind": "query",
    "text": "query columns_batteryTypeAttributeColumnsQuery(\n  $disableAttributeColumns: Boolean!\n) {\n  attributeNames(search: \"\", only: Battery, limit: 1000, status: Active) @skip(if: $disableAttributeColumns)\n}\n"
  }
};
})();

(node as any).hash = "7e26d9f7252c109057ca1a799828e6b8";

export default node;
