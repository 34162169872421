import { BatteryTypeTechnologyOptions } from './components';
import { BatteryTypeManModelFilter, BatteryTypeTechnologyFilter } from './types';

export function decodeBatteryTypeManModFilter(value: string): BatteryTypeManModelFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return {
        name: decodedValue,
        type: 'result',
    };
}

export function decodeBatteryTypeTechnologyFilter(value: string): BatteryTypeTechnologyFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = BatteryTypeTechnologyOptions.find(option => option.id === decodedValue);

    if (!match) {
        return null;
    }

    return {
        name: match.name,
        id: match.id,
    };
}
