import React, { FC, useCallback, useEffect } from 'react';
import { useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';

import { SuspenseContentArea } from '@accesstel/pcm-ui';

import { EditUserContent } from './EditUserContent';
import EditUserDataLoadQuery from './__generated__/EditUserContentQuery.graphql';
import type { EditUserContentQuery as EditUserContentQueryType } from './__generated__/EditUserContentQuery.graphql';

export const EditUser: FC = () => {
    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { id: userId } = useParams() as { id: string };

    const [queryRef, loadQuery, dispose] = useQueryLoader<EditUserContentQueryType>(EditUserDataLoadQuery);

    const load = useCallback(() => {
        loadQuery({ username: userId }, { fetchPolicy: 'network-only' });
    }, [loadQuery, userId]);

    useEffect(() => {
        load();
        return () => {
            dispose();
        };
    }, [dispose, load]);

    return (
        <SuspenseContentArea
            data={queryRef}
            className='h-96 my-6'
            render={loadedQueryRef => <EditUserContent key={userId} userId={userId} queryRef={loadedQueryRef} />}
            onRetry={load}
        />
    );
};
