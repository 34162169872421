import React, { ReactNode } from 'react';

import { AlertIcon } from '@accesstel/pcm-ui';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SiteTableColumn } from 'filters/site';

import { TableDeviceHeader } from './TableDeviceHeader';
import { FirmwareTableQuery$data } from './__generated__/FirmwareTableQuery.graphql';

export type Site = FirmwareTableQuery$data['sites']['data'][number];
export type Device = Site['devices']['data'][number];

function renderFirmwareVersion(siteOrDevice: Site | Device) {
    if ('devices' in siteOrDevice) {
        return renderSiteFirmware(siteOrDevice);
    } else {
        return renderDeviceFirmware(siteOrDevice);
    }
}

function renderSiteFirmware(site: Site) {
    const firmware = site.firmware;
    if (firmware.multipleVersions) {
        return <div>Multiple Firmware</div>;
    }

    if (firmware.commonVersion) {
        return <div>{firmware.commonVersion.name}</div>;
    }

    return <div>Unknown</div>;
}

export function renderDeviceFirmware(device: Device): ReactNode {
    let version: ReactNode;
    if (device.firmware) {
        version = device.firmware?.name;
    } else {
        version = 'Unknown';
    }

    return <b>{version}</b>;
}

function renderFirmwareStatus(siteOrDevice: Site | Device) {
    if ('devices' in siteOrDevice) {
        return renderFirmwareStatusText(siteOrDevice.firmware.status);
    } else {
        return renderFirmwareStatusText(siteOrDevice.firmwareStatus);
    }
}

function renderFirmwareStatusText(value: string | null) {
    switch (value) {
        case 'OldVersion':
            return <div className='text-coralRegular'>Yes</div>;
        case 'NewVersion':
        case 'SameVersion':
        case 'NotSet':
            return <div>No</div>;
        case 'Failed':
            return (
                <div className='flex flex-row text-coralRegular'>
                    <div className='h-4 w-4 ml-1 inline-block align-middle'>
                        <AlertIcon />
                    </div>
                    <div className='pl-1 align-middle'>Failed</div>
                </div>
            );
        default:
            return <div>{value}</div>;
    }
}

const columnHelper = createColumnHelper<Site | Device>();

export const NameColumn = columnHelper.accessor('name', {
    id: SiteTableColumn.Name,
    header: 'Site',
    cell: ({ cell, row }) => (row.depth === 0 ? cell.getValue() : <TableDeviceHeader name={cell.getValue()} />),
    meta: {
        maxWidth: '16rem',
    },
});

export const StateColumn = columnHelper.accessor('address.state', {
    id: SiteTableColumn.State,
    header: 'State',
});

export const FirmwareVersionColumn = columnHelper.display({
    id: 'firmware',
    header: 'Firmware Version',
    cell: ({ row }) => renderFirmwareVersion(row.original),
    meta: {
        width: '25%',
    },
});

export const UpdatesColumn = columnHelper.display({
    id: 'updates',
    header: 'Updates',
    cell: ({ row }) => renderFirmwareStatus(row.original),
    meta: {
        width: '25%',
    },
});

export const TableColumns = [NameColumn, StateColumn, FirmwareVersionColumn, UpdatesColumn] as ColumnDef<
    Site | Device,
    never
>[];
