import React, { FC } from 'react';

import classNames from 'classnames';

import style from '../style.module.css';

interface ConnectedSVGPathProps {
    path: string;
    reverseDirection?: boolean;
    animationMaskClass: string;
    animationMaskId: string;
}

export const ConnectedSVGPath: FC<ConnectedSVGPathProps> = ({
    path,
    animationMaskClass,
    animationMaskId,
    reverseDirection = false,
}) => {
    return (
        <>
            <div className={classNames(style.ani, animationMaskClass, reverseDirection ? style.up : null)}></div>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                preserveAspectRatio='none'
                className='absolute top-0 left-0 w-full h-full'
            >
                <mask id={animationMaskId}>
                    <path d={path} fill='none' stroke='white' strokeWidth={8} />
                </mask>
            </svg>
        </>
    );
};
