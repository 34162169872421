import { Environment, commitMutation, fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { deleteDeviceValidationQuery } from './__generated__/deleteDeviceValidationQuery.graphql';
import { DeviceRemoveResponse, deleteDevicesMutation } from './__generated__/deleteDevicesMutation.graphql';

export function deleteDevices(deviceIds: string[], environment: Environment): Promise<DeviceRemoveResponse> {
    return new Promise((resolve, reject) => {
        commitMutation<deleteDevicesMutation>(environment, {
            mutation: DeleteMutation,
            variables: {
                ids: deviceIds,
            },
            onError: () => {
                reject();
            },
            onCompleted: (response, err) => {
                if (err) {
                    reject();
                }

                if (response) {
                    resolve(response.removeDevices);
                }
            },
        });
    });
}

interface DeviceValidationProblem {
    title: string;
    description: string;
}

// ensuring that all the gateways are not linked to any other devices
export async function validateDevicesForDelete(
    deviceIds: string[],
    environment: Environment
): Promise<DeviceValidationProblem[]> {
    const gatewayDependencies = await fetchQuery<deleteDeviceValidationQuery>(environment, ValidationQuery, {
        ids: deviceIds,
    }).toPromise();

    const safeToDelete = gatewayDependencies?.gatewayConnectedDevices.every(gateway => {
        if (gateway.devices.length === 0) {
            return true;
        }
        if (gateway.devices && gateway.devices.every(device => deviceIds.includes(device.id))) {
            return true;
        }

        return false;
    });

    if (safeToDelete) {
        return []; // no problem - all OK
    }

    return [
        {
            title: 'Unable to delete devices',
            description:
                'The selection contains gateway that are dependent by other generators. You will to include all generators that are linked via the gateway in the selection in order to proceed.',
        },
    ];
}

const DeleteMutation = graphql`
    mutation deleteDevicesMutation($ids: [ID!]!) {
        removeDevices(ids: $ids)
    }
`;

const ValidationQuery = graphql`
    query deleteDeviceValidationQuery($ids: [ID!]!) {
        gatewayConnectedDevices(gatewayIds: $ids) {
            gateway {
                id
            }
            devices {
                id
            }
        }
    }
`;
