import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { encodeDateRangeFilter } from 'filters/common';

import { SelectedMetric } from '../types';

export function encodeDeviceIds(deviceIds: string[]): string {
    return deviceIds.map(encodeURIComponent).join(',');
}

export function encodeMetrics(metrics: SelectedMetric[]): string {
    const encodedMetrics = metrics.map(metric => {
        return encodeURIComponent(`${metric.metric}:${metric.op}`);
    });

    return encodedMetrics.join(',');
}

export function encodeMetricTimeRange(timeRange: DateRangeFilter): string {
    return encodeDateRangeFilter(timeRange);
}
