import React from 'react';
import { FC } from 'react';
import { Link, generatePath } from 'react-router-dom';

import {
    CheckCircledOpenIcon,
    CircleAlertIcon,
    CircleHelpIcon,
    CircleMinusIcon,
    CirclePlayIcon,
    DeviceIcon,
    Tooltip,
} from '@accesstel/pcm-ui';

import { IconWithStatus } from 'components';
import { Paths } from 'lib/routes';
import { formatGeneratorRunReportCause, formatGeneratorRunReportState } from 'lib/textFormatters';
import { DateTime } from 'luxon';
import { CardDateDisplay } from 'views/tasks/components';

import {
    CompletedGeneratorRuns_tasks$data,
    GeneratorRunReportState,
} from '../sections/__generated__/CompletedGeneratorRuns_tasks.graphql';

type GeneratorRunReport = CompletedGeneratorRuns_tasks$data['data'][number];

interface GeneratorRunCardProps {
    runReport: GeneratorRunReport;
}

export const GeneratorRunCard: FC<GeneratorRunCardProps> = ({ runReport }) => (
    <Link
        key={runReport.id}
        to={generatePath(Paths.TasksGeneratorsDetails, {
            id: runReport.id,
        })}
        className='bg-white p-4 hover:shadow-xl'
        data-testid='generator-run-card'
    >
        <div className='mb-4'>
            <IconWithStatus
                icon={getGeneratorRunStateIcon(runReport.state)}
                label={formatGeneratorRunReportState(runReport.state)}
                containerClasses={getGeneratorRunStateColor(runReport.state)}
                variant='lg'
            />
        </div>
        <div className='flex flex-col gap-2 mb-2 text-eggplantRegular'>
            <Tooltip content={runReport.name ?? 'Generator Run Report'} overflowOnly>
                <div className='text-lg truncate'>{runReport.name ?? 'Generator Run Report'}</div>
            </Tooltip>
            <div>{formatGeneratorRunReportCause(runReport.cause)}</div>
            <div className='flex flex-row gap-1'>
                <div className='w-5 h-5'>
                    <DeviceIcon />
                </div>
                <div>
                    <div>{runReport.generator.name}</div>
                    <div className='text-sm font-light text-eggplantLight'>
                        {runReport.generator.site.name}, {runReport.generator.site.address.state}
                    </div>
                </div>
            </div>
            <CardDateDisplay
                startTime={DateTime.fromISO(runReport.generatorStartTime).toJSDate()}
                endTime={
                    runReport.generatorStopTime ? DateTime.fromISO(runReport.generatorStopTime).toJSDate() : undefined
                }
                future={false}
            />
        </div>
    </Link>
);

export function getGeneratorRunStateIcon(state: GeneratorRunReportState) {
    switch (state) {
        case 'Completed':
            return <CheckCircledOpenIcon />;
        case 'Error':
            return <CircleAlertIcon />;
        case 'Running':
            return <CirclePlayIcon />;
        case 'Analysing':
        case 'Finishing':
            return <CircleMinusIcon />;
        default:
            return <CircleHelpIcon />;
    }
}

export function getGeneratorRunStateColor(state: GeneratorRunReportState) {
    switch (state) {
        case 'Completed':
            return 'text-pineRegular';
        case 'Error':
            return 'text-coralRegular';
        case 'Running':
        case 'Analysing':
        case 'Finishing':
        default:
            return 'text-eggplantRegular';
    }
}
