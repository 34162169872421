import React, { FC } from 'react';

import { CrossIcon, NavigateTo, ThemedLink, Tooltip } from '@accesstel/pcm-ui';

import style from './CompareColumnHeader.module.css';

export interface CompareColumnHeaderProps {
    title: string;
    subtitle: string;
    linkName: string;
    link: NavigateTo;

    removeEnabled?: boolean;
    removeLabel: string;
    onRemove: () => void;
}

export const CompareColumnHeader: FC<CompareColumnHeaderProps> = ({
    title,
    subtitle,
    linkName,
    link,
    removeEnabled,
    removeLabel,
    onRemove,
}) => {
    return (
        <div className={style.header}>
            <Tooltip content={title} overflowOnly>
                <div className='leading-4 truncate'>{title}</div>
            </Tooltip>
            <Tooltip content={subtitle} overflowOnly>
                <div className='text-sm text-eggplantLight truncate'>{subtitle}</div>
            </Tooltip>
            <ThemedLink className='text-sm' to={link} externalLink>
                {linkName}
            </ThemedLink>
            {removeEnabled && (
                <div className={style.remove_container}>
                    <Tooltip content={removeLabel}>
                        <button
                            type='button'
                            aria-label={removeLabel}
                            className={style.remove_button}
                            onClick={onRemove}
                        >
                            <div className={style.remove_button_inner}>
                                <CrossIcon />
                            </div>
                        </button>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};
