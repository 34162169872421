import { DeviceHealthStatus } from 'filters/site';

import { DeviceBatteryStatusOptions, SNMPVersions } from './components';
import {
    DeviceBatteryStatusFilter,
    DeviceNameFilter,
    DeviceSNMPVersionFilter,
    DeviceStatusFilter,
    DeviceTypeFilter,
    MonitorOnlyStatus,
} from './types';

export function decodeDeviceNameFilter(value: string): DeviceNameFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return {
        name: decodedValue,
        type: 'result',
    };
}

export function decodeDeviceTypeFilter(value: string): DeviceTypeFilter | null {
    const separator = '+';

    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    if (!decodedValue.includes(separator)) {
        return null;
    }

    if (decodedValue.split(separator).length !== 2) {
        return null;
    }

    const [id, name] = decodedValue.split(separator);

    if (id === '' || name === '') {
        return null;
    }

    return {
        id,
        name,
    };
}

export function decodeDeviceBatteryStatusFilter(value: string): DeviceBatteryStatusFilter | null {
    const decodedValue = decodeURIComponent(value);
    const status = DeviceBatteryStatusOptions.find(option => option.id === decodedValue);
    if (!status) {
        return null;
    }

    return status;
}

export function decodeDeviceMonitorOnlyStatusFilter(value: string): MonitorOnlyStatus | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    for (const status of Object.values(MonitorOnlyStatus)) {
        if (status === decodedValue) {
            return status;
        }
    }

    return null;
}

export function decodeDeviceSNMPVersionFilter(value: string): DeviceSNMPVersionFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const version = SNMPVersions.find(snmpVersion => snmpVersion.id === decodedValue);

    if (!version) {
        return null;
    }

    return version;
}

export function decodeDeviceStatusFilter(value: string): DeviceStatusFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (Object.values(DeviceHealthStatus).includes(decodedValue as DeviceHealthStatus)) {
        return {
            id: decodedValue as DeviceHealthStatus,
            name: decodedValue,
        };
    }

    return null;
}
