import React, { FC } from 'react';

import { CommonFilterProps } from 'filters/common';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { getUserPrefBaseUnit } from 'lib/units';

import { BatteryTypeCellReferenceTemperatureFilter } from '../types';
import { BatteryTypeFilterRange } from './BatteryTypeFilterRange';

export type BatteryTypeCellReferenceTemperatureFilterUIProps =
    CommonFilterProps<BatteryTypeCellReferenceTemperatureFilter | null>;

export const BatteryTypeCellReferenceTemperatureFilterUI: FC<BatteryTypeCellReferenceTemperatureFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const unitPreferences = useCurrentUserUnitsPref();

    return (
        <BatteryTypeFilterRange
            current={current}
            onClearOrBack={onClearOrBack}
            onClose={onClose}
            onSubmit={onSubmit}
            title={`Filter by Cell Reference Temperature (${getUserPrefBaseUnit(unitPreferences.temperature)})`}
            distributionType='CellReferenceTemperature'
            showBack={hasPrevious}
        />
    );
};
