import React, { FC } from 'react';

import { DevicePaneSkeleton } from '../../components/DevicePaneSkeleton';
import { DeviceACPowerSkeleton } from './DeviceACPowerSkeleton';

export const ACPowerSkeleton: FC = () => {
    return (
        <div className='grid grid-cols-2 gap-4'>
            <DevicePaneSkeleton>
                <DeviceACPowerSkeleton />
            </DevicePaneSkeleton>
            <DevicePaneSkeleton>
                <DeviceACPowerSkeleton />
            </DevicePaneSkeleton>
        </div>
    );
};
