import { AdminAccess, AuditAccess, ReadAccess, ReadWriteAccess, UserPermissions } from './schema';

export function hasAnyPermission(state: UserPermissions): boolean {
    return (
        state.assets !== ReadWriteAccess.None ||
        state.batteryHealthTasks !== ReadWriteAccess.None ||
        state.administration !== AdminAccess.None ||
        state.audit !== AuditAccess.None ||
        state.general !== ReadAccess.None
    );
}
