import React, { FC, ReactNode } from 'react';

import { PageHeading } from '@accesstel/pcm-ui';

import style from './style.module.css';

export interface BigButtonLayoutProps {
    /**
     * Title of the page, displayed at the top in the regular position
     */
    title: string;
    /**
     * The children of this component should be BigButton components.
     */
    children: ReactNode;
}

/**
 * The Big Button layout is used for launching points, such as the Reports page or Manage page.
 *
 * The children of this component should be BigButton components.
 */
export const BigButtonLayout: FC<BigButtonLayoutProps> = ({ title, children }) => {
    return (
        <div className='space-y-6'>
            <PageHeading value={title} />
            <div className={style.button_container}>{children}</div>
        </div>
    );
};
