import { AffectedFeedFilter, IncidentIsSignificantOnlyFilter, IncidentTypeFilter } from './types';

export function encodeIncidentTypeFilter(type: IncidentTypeFilter): string {
    return encodeURIComponent(type.id);
}

export function encodeIncidentAffectedFeedFilter(feed: AffectedFeedFilter): string {
    return encodeURIComponent(feed.id);
}

export function encodeIncidentIsSignificantOnlyFilter(isSignificant: IncidentIsSignificantOnlyFilter): string {
    return encodeURIComponent(isSignificant.value);
}
