import React, { FC, ReactElement, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import {
    ArrowRightCircleIcon,
    CalendarIcon,
    CircleCheckIcon,
    CircleCrossIcon,
    CircleHelpIcon,
    CircleMinusIcon,
    CirclePauseIcon,
    CirclePlayIcon,
    CircleStopIcon,
    ClockIcon,
    Link,
    Tooltip,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import humanizeDuration from 'humanize-duration';
import { getDateTimeFormat, getFormattedDateFromDate } from 'lib/dateFormatter';
import { Paths } from 'lib/routes';
import { formatBatteryTestName, getNiceStatusName } from 'lib/textFormatters';
import { SmallerLabelWithIcon } from 'views/tasks/battery-health/overview/components/SmallerLabelWithIcon';

import { BatteryTestRow_data$key, BatteryTestState } from './__generated__/BatteryTestRow_data.graphql';

export interface BatteryTestRowProps {
    batteryTestRow: BatteryTestRow_data$key;
}

export const BatteryTestRow: FC<BatteryTestRowProps> = ({ batteryTestRow }) => {
    const data = useFragment(Fragment, batteryTestRow);

    let commencedTime: Date | undefined;
    let durationText: string;
    if (data.commencedTime) {
        commencedTime = new Date(data.commencedTime);

        let finishDate: Date;
        if (data.completedTime) {
            finishDate = new Date(data.completedTime);
        } else {
            finishDate = new Date();
        }

        durationText = humanizeDuration(finishDate.getTime() - new Date(data.commencedTime).getTime(), {
            round: true,
            largest: 1,
            units: ['h', 'm', 's'],
        });
    } else if (data.state === 'Scheduled') {
        durationText = 'Scheduled';

        if (data.task?.schedule.time) {
            commencedTime = new Date(data.task.schedule.time);
        }
    } else {
        durationText = 'Unknown duration';
    }

    const name = data.name ?? formatBatteryTestName(data.cause);

    if (data.task) {
        return (
            <RowComponent
                link={`${Paths.ReportBatteriesByTestDevice}/${data.id}`}
                error={data.state === 'Failed'}
                icon={getIcon(data.task.overallState === 'Cancelled' ? 'Cancelled' : data.state)}
                title={name}
                status={getNiceStatusName(data.task.overallState === 'Cancelled' ? 'Cancelled' : data.state)}
                commencedTime={commencedTime}
                duration={durationText}
            />
        );
    } else {
        return (
            <RowComponent
                link={`${Paths.ReportBatteriesByTestDevice}/${data.id}`}
                error={data.state === 'Failed'}
                icon={getIcon(data.state)}
                title={name}
                status={getNiceStatusName(data.state)}
                commencedTime={data.commencedTime ? new Date(data.commencedTime) : undefined}
                duration={durationText}
                nameIsGenerated={!data.name}
            />
        );
    }
};

const Fragment = graphql`
    fragment BatteryTestRow_data on DeviceBatteryTestResults {
        id
        state
        name
        cause
        commencedTime
        completedTime
        state
        task {
            name
            overallState
            schedule {
                time
            }
        }
    }
`;

function getIcon(value: BatteryTestState | 'Cancelled'): ReactElement {
    switch (value) {
        case 'Aborted':
            return <CircleStopIcon />;
        case 'Failed':
            return <CircleCrossIcon />;
        case 'InProgress':
            return <CirclePlayIcon />;
        case 'Inconclusive':
            return <CircleHelpIcon />;
        case 'Passed':
            return <CircleCheckIcon />;
        case 'Scheduled':
            return <ArrowRightCircleIcon />;
        case 'SmartStart':
            return <CirclePauseIcon />;
        case 'Analyzing':
        case 'Finalizing':
        case 'Waiting':
            return <CircleMinusIcon />;
        case 'Cancelled':
            return <CircleMinusIcon />;
        default:
            return <CircleHelpIcon />;
    }
}

const RowComponent: FC<{
    link: string;
    error?: boolean;
    icon: ReactNode;
    title: string;
    status: string;
    commencedTime?: Date;
    duration: string;
    nameIsGenerated?: boolean;
}> = ({ link, error = false, icon, title, status, commencedTime, duration, nameIsGenerated = false }) => {
    let formattedTime: ReactNode;
    if (commencedTime) {
        formattedTime = (
            <>
                <span className='block 2xl:hidden'>{getFormattedDateFromDate(commencedTime)}</span>
                <span className='hidden 2xl:block'>{getDateTimeFormat(commencedTime)}</span>
            </>
        );
    } else {
        formattedTime = 'Unknown date';
    }

    return (
        <div className='flex flex-row p-1 bg-white shadow-sm'>
            <Link to={link} className='flex flex-row flex-grow items-stretch gap-x-2 w-full'>
                <div
                    className={`${
                        error ? 'text-coralRegular' : 'text-eggplantRegular'
                    } flex items-center m-2 w-6 flex-shrink-0`}
                >
                    {icon}
                </div>
                <div className='flex-grow flex-shrink flex flex-col justify-center items-stretch leading-none min-w-0'>
                    <Tooltip content={title} overflowOnly>
                        <div
                            className={classNames(
                                nameIsGenerated ? 'italic' : '',
                                'text-base',
                                'truncate',
                                'self-stretch'
                            )}
                        >
                            {title}
                        </div>
                    </Tooltip>
                    <div className={`${error ? 'text-coralRegular' : 'text-eggplantRegular'} text-lg font-bold -mb-1`}>
                        {status}
                    </div>
                </div>

                <div className='shrink-0 flex flex-col justify-center items-start leading-none space-y-2 h-full w-32 2xl:w-44'>
                    <SmallerLabelWithIcon
                        icon={<CalendarIcon />}
                        label={formattedTime}
                        title={commencedTime ? getDateTimeFormat(commencedTime) : 'Unknown date'}
                    />
                    <SmallerLabelWithIcon icon={<ClockIcon />} label={duration} />
                </div>
            </Link>
        </div>
    );
};
