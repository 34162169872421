import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { Button, FormikDropdownWithSearch, FormikGeneralSelect } from '@accesstel/pcm-ui';

import { Form, useFormikContext } from 'formik';

import {
    TimezoneOption,
    TimezonePreset,
    TimezonePresetLabels,
    describeTimezoneSimple,
    extractTimezoneGroups,
    getBrowserTimezone,
    getTimezoneOptions,
} from '../lib/timezone';
import { TimezoneSettingsFormValues } from '../schema';

export const TimezoneSettingsContent: FC = () => {
    const allTimezones = useMemo(() => getTimezoneOptions(), []);
    const timezoneGroups = useMemo(() => extractTimezoneGroups(allTimezones), [allTimezones]);

    const [timezoneSearch, setTimezoneSearch] = useState<string>('');

    const timezones = useMemo(() => {
        const searchString = timezoneSearch.toLowerCase();

        if (searchString === '') {
            return allTimezones;
        }

        const searchWords = searchString.split(' ');

        return allTimezones.filter(timezone => {
            for (const word of searchWords) {
                if (!timezone.keywords.includes(word)) {
                    return false;
                }
            }
            return true;
        });
    }, [allTimezones, timezoneSearch]);

    const { dirty, isSubmitting, isValid, setFieldValue, values } = useFormikContext<TimezoneSettingsFormValues>();

    const handlePresetChange = useCallback(
        (preset: TimezonePreset) => {
            if (preset === TimezonePreset.Manual) {
                return;
            }

            const timezone = getBrowserTimezone();
            setFieldValue('timezone', timezone);
        },
        [setFieldValue]
    );

    return (
        <Form>
            <div className='space-y-4 grid grid-cols-2 pt-6 gap-x-6 pr-6'>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='preset'
                        placeHolder='Preset'
                        light
                        variant='outlined'
                        items={[TimezonePreset.Automatic, TimezonePreset.Manual]}
                        renderItem={item => TimezonePresetLabels[item as TimezonePreset]}
                        onSelectItem={handlePresetChange}
                    />
                </div>
                <div className='col-start-1'>
                    <FormikDropdownWithSearch<TimezoneOption>
                        name='timezone'
                        placeHolder='Timezone'
                        light
                        variant='outlined'
                        items={timezones}
                        renderItem={renderTimezoneOption}
                        renderItemAsString={describeTimezoneSimple}
                        onSearch={setTimezoneSearch}
                        groupKey='region'
                        groups={timezoneGroups}
                        disabled={values.preset === TimezonePreset.Automatic}
                    />
                </div>
            </div>
            <div className='flex flex-row gap-4 justify-end'>
                <Button
                    type='reset'
                    variant='white'
                    buttonText='Reset'
                    disabled={isSubmitting || !dirty}
                    size='small'
                />
                <Button
                    type='submit'
                    variant='primary'
                    buttonText='Save'
                    disabled={isSubmitting || !isValid || !dirty}
                    processing={isSubmitting}
                    size='small'
                />
            </div>
        </Form>
    );
};

function renderTimezoneOption(timezone: TimezoneOption): ReactNode {
    return (
        <div className='flex flex-row justify-between'>
            <div>{timezone.label}</div>
            <div className='text-mauveLight'>{timezone.regionalName}</div>
        </div>
    );
}
