import React, { FC } from 'react';
import { useFragment } from 'react-relay';
import { generatePath, useLocation } from 'react-router-dom';

import { DeviceIcon, Link, Tooltip } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { Paths } from 'lib/routes';
import { renderTableStatusCell } from 'lib/table-columns';
import { formatBatteryTestName } from 'lib/textFormatters';
import { CardDateDisplay } from 'views/tasks/components';

import { BatteryTestResultCard_test$key } from './__generated__/BatteryTestResultCard_test.graphql';

interface BatteryTestResultCardProps {
    test: BatteryTestResultCard_test$key;
    background?: 'light' | 'dark';
}

export const BatteryTestResultCard: FC<BatteryTestResultCardProps> = ({ test, background = 'light' }) => {
    const location = useLocation();

    const { cause, commencedTime, completedTime, id, state, device, task, name } =
        useFragment<BatteryTestResultCard_test$key>(
            graphql`
                fragment BatteryTestResultCard_test on DeviceBatteryTestResults {
                    id
                    name
                    state
                    commencedTime
                    completedTime
                    cause
                    device {
                        id
                        name
                        site {
                            name
                            address {
                                state
                            }
                        }
                    }
                    task {
                        createdTime
                    }
                }
            `,
            test
        );

    let endTime: Date | undefined;

    console.assert(commencedTime || task?.createdTime, 'Expected commencedTime or task.createdTime to be defined');

    const startTime = new Date(commencedTime ?? task?.createdTime ?? new Date());
    if (state !== 'InProgress') {
        // The only reason there wont be an end time is that the test was scheduled and failed to start
        endTime = new Date(completedTime ?? task?.createdTime ?? new Date());
    }

    const niceCauseOrName = name ?? formatBatteryTestName(cause) ?? 'Unknown';

    // FIXME: This is a hack to get the correct path for the link. This can be done better with PCM-1885
    let url: string;
    if (location.pathname.includes(Paths.ReportBatteries)) {
        url = generatePath(Paths.ViewTestResultFromReport, { id });
    } else {
        url = generatePath(Paths.ViewExternalTestResults, { id });
    }

    return (
        <Link to={url}>
            <div
                className={classNames('p-5 font-normal text-eggplantRegular hover:shadow-lg transition-shadow h-full', {
                    'bg-white': background === 'light',
                    'bg-grayRegular': background === 'dark',
                })}
            >
                <div className='mb-4'>{renderTableStatusCell(state, undefined, 'lg')}</div>

                <Tooltip content={niceCauseOrName} overflowOnly>
                    <div className='text-lg mt-4 truncate'>{niceCauseOrName}</div>
                </Tooltip>
                <div className='flex gap-2'>
                    <div className='w-4 h-4 mt-1'>
                        <DeviceIcon />
                    </div>
                    <div className='items-baseline gap-1'>
                        <div className='flex'>{device.name}</div>
                        <div className='text-eggplantLight text-xs'>
                            {device.site.name}, {device.site.address.state}
                        </div>
                    </div>
                </div>

                <div className='mt-4'>
                    <CardDateDisplay startTime={startTime} endTime={endTime} />
                </div>
            </div>
        </Link>
    );
};
