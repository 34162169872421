import React, { FC } from 'react';

import classNames from 'classnames';

import style from './style.module.css';

interface SectionHeadingProps {
    title: string;
}

export const SectionHeading: FC<SectionHeadingProps> = ({ title }) => {
    return <div className={classNames(style.section_heading, 'text-eggplantRegular font-bold')}>{title}</div>;
};
