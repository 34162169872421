import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Button, HistoryItem, History as HistoryLine, ListView, Menu, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useUserPermissions } from 'lib/auth';
import { createHistoryItem } from 'lib/history';
import { MenuItemGroup } from 'lib/menu';
import { Paths } from 'lib/routes';

import { GatewayOverview_connectedDevices$key } from './__generated__/GatewayOverview_connectedDevices.graphql';
import { GatewayOverview_device$key } from './__generated__/GatewayOverview_device.graphql';
import { ConnectedDeviceRow, GatewayModuleDetails } from './components';

interface GatewayOverviewProps {
    gateway: GatewayOverview_device$key;
    connectedDevices: GatewayOverview_connectedDevices$key;
}

export const GatewayOverview: FC<GatewayOverviewProps> = ({ gateway, connectedDevices }) => {
    const { hasAssetsWrite, hasAssetsRead, hasTasksRead } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(GatewayFragment, gateway);
    const gatewayId = data.id;

    const connectedDevicesData = useFragment(GatewayConnectedDevicesFragment, connectedDevices);

    const historyItems: HistoryItem[] = data.activityLogs.data.map(log =>
        createHistoryItem(log, hasAssetsRead, hasTasksRead)
    );
    const navigateToHistoryPage = () => {
        navigate({
            pathname: Paths.SiteViewViewSiteDevicePage,
            params: {
                siteId: data.site.id,
                deviceId: gatewayId,
                page: 'history',
            },
        });
    };

    return (
        <>
            <div className='grid grid-cols-2 gap-2'>
                <div className='mb-2'>
                    <div className='text-xs'>
                        <div data-testid='module_name'>
                            <span className='font-light'>Model: </span>
                            <span className='font-normal'>{data.model ?? '-'}</span>
                        </div>
                        <div data-testid='gateway_ip'>
                            <span className='font-light'>IP address: </span>
                            {data.connectionSettings.addresses[0] ?? '-'}
                        </div>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className='float-right'>
                        <Menu
                            id={`device-menu-${gatewayId}`}
                            groups={[{ key: MenuItemGroup.Assets, title: MenuItemGroup.Assets }]}
                            menuItems={[
                                {
                                    name: hasAssetsWrite ? 'Edit gateway' : 'View gateway',
                                    disabled: !hasAssetsRead || !gateway,
                                    onClick: () => {
                                        if (!gateway) {
                                            return;
                                        }

                                        navigate({
                                            pathname: Paths.EditDevice,
                                            params: {
                                                id: gatewayId,
                                            },
                                        });
                                    },
                                    group: MenuItemGroup.Assets,
                                },
                            ]}
                            variant='small'
                        />
                    </div>

                    <GatewayModuleDetails gatewayId={gatewayId} />
                </div>
            </div>

            <ListView
                title='Connected Devices'
                view='list'
                contentClassName='h-96'
                scroll
                subtitle={`${connectedDevicesData.devices.length} ${
                    connectedDevicesData.devices.length === 1 ? 'device' : 'devices'
                }`}
            >
                {connectedDevicesData.devices.map((connectedDevice, idx) => (
                    <ConnectedDeviceRow idx={idx + 1} siteId={data.site.id} data={connectedDevice} />
                ))}

                {connectedDevicesData.devices.length === 0 && (
                    <>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        <div className='col-span-4 grid place-items-center space-y-4'>
                            <span>No connected devices</span>
                            <Button
                                buttonText={'Add device'}
                                onClick={() => navigate({ pathname: Paths.AddDevice, search: { site: data.site.id } })}
                                size='small'
                                variant={'primary'}
                                disabled={!hasAssetsWrite}
                                disabledMessage={'You do not have permission to add devices'}
                            />
                        </div>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    </>
                )}
            </ListView>

            <div className='pt-4'>
                <HistoryLine
                    title='History'
                    lineStyle='short'
                    itemGroups={[{ items: historyItems }]}
                    moreItems={data.activityLogs.hasMore}
                    loadMoreCallback={navigateToHistoryPage}
                />
            </div>
        </>
    );
};

const GatewayConnectedDevicesFragment = graphql`
    fragment GatewayOverview_connectedDevices on GatewayConnectedDevice {
        devices {
            id
            name
            type {
                displayName
                category
            }
        }
    }
`;

const GatewayFragment = graphql`
    fragment GatewayOverview_device on Device {
        id
        model
        connectionSettings {
            addresses
        }

        site {
            id
        }

        activityLogs(
            types: [
                DeviceAdd
                DeviceEdit
                AlertSeverityCritical
                AlertSeverityMajor
                GridEventStatusOffline
                GridEventStatusHigh
                BatteryTest
            ]
            limit: 5
        ) {
            hasMore
            total
            data {
                source
                type
                timestamp
                user {
                    username
                    name
                }
                changes {
                    field
                    oldValue
                    newValue
                }
                link {
                    __typename
                    ... on Device {
                        id
                        name
                    }
                    ... on Alert {
                        severity
                        category
                        message
                        isActive
                        device {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;
