import { boolean, number, object, string } from 'yup';

export interface ExportFormValues {
    filename: string;
    pageStart: number;
    pageEnd: number;
    allColumns: boolean;
}

export const ExportFormSchema = object().shape({
    filename: string().required('Required'),
    pageStart: number().required('Required').integer('Must be a whole number'),
    pageEnd: number().required('Required').integer('Must be a whole number'),
    allColumns: boolean().required('Required'),
});
