/**
 * @generated SignedSource<<da7986de1cdde5bb744e5b99c92f2df4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type dynamic_loadDeviceAttributeNamesQuery$variables = Record<PropertyKey, never>;
export type dynamic_loadDeviceAttributeNamesQuery$data = {
  readonly attributeNames: ReadonlyArray<string>;
};
export type dynamic_loadDeviceAttributeNamesQuery = {
  response: dynamic_loadDeviceAttributeNamesQuery$data;
  variables: dynamic_loadDeviceAttributeNamesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1000
      },
      {
        "kind": "Literal",
        "name": "only",
        "value": "Device"
      },
      {
        "kind": "Literal",
        "name": "search",
        "value": ""
      },
      {
        "kind": "Literal",
        "name": "status",
        "value": "Active"
      }
    ],
    "kind": "ScalarField",
    "name": "attributeNames",
    "storageKey": "attributeNames(limit:1000,only:\"Device\",search:\"\",status:\"Active\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dynamic_loadDeviceAttributeNamesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dynamic_loadDeviceAttributeNamesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6c0261312deacbe5d6c768541666fa74",
    "id": null,
    "metadata": {},
    "name": "dynamic_loadDeviceAttributeNamesQuery",
    "operationKind": "query",
    "text": "query dynamic_loadDeviceAttributeNamesQuery {\n  attributeNames(search: \"\", only: Device, limit: 1000, status: Active)\n}\n"
  }
};
})();

(node as any).hash = "fd97592411b70b5b861f0bd7b84cbfe7";

export default node;
