import { AnySchema, array, number, object, string } from 'yup';

import { DeviceType } from '../../lib/device-types';
import { DeviceReference } from '../../schema';
import {
    SnmpAuthAlgorithm,
    SnmpPrivAlgorithm,
    SnmpSecurityLevel,
    SnmpVersion,
} from './__generated__/useConnectionTestMutation.graphql';

export const SNMPValidationSchema = object({
    port: number()
        .typeError('Must be a number')
        .integer('Whole numbers only')
        .min(1, 'Must between 1 and 65535')
        .max(65535, 'Must between 1 and 65535')
        .default(161),
    version: string().oneOf(['V1', 'V2c', 'V3']).required('SNMP version is required'),

    // V1 and V2 settings
    readOnlyCommunity: string().when('version', {
        is: (version: string) => version === 'V1' || version === 'V2c',
        then: string().required('Read only community string required'),
    }),
    readWriteCommunity: string().when('version', {
        is: (version: string) => version === 'V1' || version === 'V2c',
        then: string().required('Read write community string required'),
    }),

    // V3 settings
    authPassphrase: string().when(['version', 'securityLevel'], {
        is: (version: string, securityLevel: string) =>
            (version === 'V3' && securityLevel === 'AuthPriv') || securityLevel === 'AuthNoPriv',
        then: string().required('Auth passphrase required when using Auth Priv or Auth No Priv security level'),
        otherwise: string().nullable(),
    }),
    authType: string().when('version', {
        is: 'V3',
        then: string().oneOf(['MD5', 'SHA1']).required(),
        otherwise: string().oneOf(['MD5', 'SHA1']),
    }),
    engineId: string().nullable(),
    privPassphrase: string().when(['version', 'securityLevel'], {
        is: (version: string, securityLevel: string) => version === 'V3' && securityLevel === 'AuthPriv',
        then: string().required('Priv passphrase required when using Auth Priv security level'),
        otherwise: string().nullable(),
    }),
    privType: string().when('version', {
        is: 'V3',
        then: string().oneOf(['AES', 'DES']).required(),
        otherwise: string().oneOf(['AES', 'DES']),
    }),
    securityLevel: string().when('version', {
        is: 'V3',
        then: string().required(),
    }),
    user: string().when('version', {
        is: 'V3',
        then: string().required('Username required'),
    }),
});

export const WebCredentialsSchema = object({
    username: string().required('Username is required'),
    password: string().required('Password is required'),
});

export const GatewayCredentialsSchema = object({
    gateway: object().test('gateway', 'Gateway is required', value => {
        if (!value) {
            return false;
        }

        return !!value.id;
    }),
    localId: string().required('Network element is required'),
});

export function getConnectionSettingsValidationSchema(type: string, deviceTypes: DeviceType[]) {
    const deviceType = deviceTypes.find(deviceType => deviceType.id === type);

    if (!deviceType) {
        return object();
    }

    const protocols = deviceType.connectivity.protocols;
    const protocolsSection = object().shape(
        protocols.reduce(
            (schema, protocol) => {
                const requiredErrorDescription = `${protocol.displayName} is required`;
                switch (protocol.type) {
                    case 'Snmp':
                        schema[protocol.id] = SNMPValidationSchema.required(requiredErrorDescription);
                        break;
                    case 'Basic':
                        schema[protocol.id] = WebCredentialsSchema.required(requiredErrorDescription);
                        break;
                    case 'Gateway':
                        schema[protocol.id] = GatewayCredentialsSchema.required(requiredErrorDescription);
                        break;
                }

                return schema;
            },
            {} as Record<string, AnySchema>
        )
    );

    const outputShape: Record<string, AnySchema> = {
        protocols: protocolsSection,
    };

    if (deviceType.connectivity.ipEnabled) {
        outputShape.addresses = array(string().trim().required('Address cannot be empty')).min(
            1,
            'At least one address must be provided'
        );
    }

    return object(outputShape);
}

export interface SnmpSettingsFormValues {
    protocolId: string | null;
    port: string;
    version: SnmpVersion;

    // V1 and V2 settings
    readOnlyCommunity: string;
    readWriteCommunity: string;

    // V3 settings
    authPassphrase: string;
    authType: SnmpAuthAlgorithm;
    engineId: string;
    privPassphrase: string;
    privType: SnmpPrivAlgorithm;
    securityLevel: SnmpSecurityLevel;
    user: string;
}

export interface BasicSettingsFormValues {
    protocolId: string | null;
    username: string;
    password: string;
}

export interface GatewaySettingsFormValues {
    protocolId: string | null;
    gateway: DeviceReference;
    localId: string;
}

type ProtocolFormValues = SnmpSettingsFormValues | BasicSettingsFormValues | GatewaySettingsFormValues;

export interface ConnectivitySettingsFormValues {
    addresses: string[];
    protocols: Record<string, ProtocolFormValues>;
}

export function createDefaultConnectivitySnmpSettingsValues(protocolId: string): NonNullable<SnmpSettingsFormValues> {
    return {
        protocolId,
        port: '161',
        version: 'V3',
        readOnlyCommunity: 'public',
        readWriteCommunity: 'private',
        user: '',
        authPassphrase: '',
        authType: 'MD5',
        engineId: '',
        privPassphrase: '',
        privType: 'AES',
        securityLevel: 'NoAuthNoPriv',
    };
}

export function createDefaultConnectivityBasicSettingsValues(protocolId: string): NonNullable<BasicSettingsFormValues> {
    return {
        protocolId,
        password: '',
        username: '',
    };
}

export function createDefaultConnectivityGatewaySettingsValues(
    protocolId: string
): NonNullable<GatewaySettingsFormValues> {
    return {
        protocolId,
        gateway: {
            id: '',
            displayName: '',
            siteName: '',
        },
        localId: '',
    };
}

export function createDefaultConnectivitySettingsValues(): ConnectivitySettingsFormValues {
    return {
        addresses: [],
        protocols: {},
    };
}

export function prepopulateProtocolSettings(settings: ConnectivitySettingsFormValues, deviceType: DeviceType) {
    const protocols = deviceType.connectivity.protocols;

    for (const protocol of protocols) {
        if (settings.protocols[protocol.id]) {
            continue;
        }

        switch (protocol.type) {
            case 'Snmp':
                settings.protocols[protocol.id] = createDefaultConnectivitySnmpSettingsValues(protocol.id);
                break;
            case 'Basic':
                settings.protocols[protocol.id] = createDefaultConnectivityBasicSettingsValues(protocol.id);
                break;
            case 'Gateway':
                settings.protocols[protocol.id] = createDefaultConnectivityGatewaySettingsValues(protocol.id);
                break;
        }
    }
}
