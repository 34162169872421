import React, { FC } from 'react';

import { BigButton } from '@accesstel/pcm-ui';

import { Paths } from 'lib/routes';

import { useDocumentTitle } from '../../components';
import { BigButtonLayout } from '../../layouts/BigButtonLayout';

// IMPORTANT: Preserve the order of the color palette so that each group can be distinguishable even when place side by side
export const colorPalette = [
    'bg-eggplantRegular',
    'bg-coralRegular',
    'bg-mauveRegular',
    'bg-mustardRegular',
    'bg-black',
    'bg-pineRegular',
];

export const AssetManagement: FC = () => {
    useDocumentTitle('Asset Management');

    return (
        <BigButtonLayout title='Manage'>
            <BigButton
                to={Paths.Devices}
                title='Devices'
                text='Devices are physical assets located on Sites. An example is a power controller with batteries.'
            />
            <BigButton to={Paths.Sites} title='Sites' text='Sites are physical locations which contain Devices.' />
            <BigButton
                to={Paths.BatteryTypes}
                title='Battery Types'
                text='Battery Types are different makes and models of batteries. Batteries are managed by Devices.'
            />
        </BigButtonLayout>
    );
};
