import { useCallback, useRef } from 'react';

type CallbackFunction<TArgs extends unknown[]> = (...args: TArgs) => void;

/**
 * Wraps a function in a debouncer to prevent rapid invocations of that function.
 * The returned function should be used in place of the callback.
 *
 * The wrapped function will be invoked no more frequently than `debounceMs`. If
 * multiple calls are made to the returned function within the `debounceMs` period,
 * then only the most recent call will be executed (after waiting for `timeousMs`
 * milliseconds).
 *
 * NOTE: If the returned function is continuously invoked with an interval less than
 * `debounceMs`, then the callback will NOT be invoked.
 * @param callback The function to invoke after debouncing has concluded.
 * @param debounceMs The time in milliseconds to wait for additional invocations
 * @returns The debounced callback.
 */
export function useDebouncer<TArgs extends unknown[]>(
    callback: CallbackFunction<TArgs>,
    debounceMs: number
): CallbackFunction<TArgs> {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleCall = useCallback(
        (...args: TArgs) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }

            timeoutRef.current = setTimeout(() => {
                callback(...args);
                timeoutRef.current = null;
            }, debounceMs);
        },
        [callback, debounceMs]
    );

    return handleCall;
}
