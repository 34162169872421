import React, { FC, useCallback, useEffect, useState } from 'react';
import { generatePath, useMatches } from 'react-router-dom';

import {
    ConfigurationIcon,
    FirmwareIcon,
    MagIcon,
    NavigationItem,
    OverviewIcon,
    PlusIcon,
    ReportsIcon,
    SitesIcon,
    TasksIcon,
    TopLevelNavigationItem,
    VerticalNavigation,
} from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';
import { useUserPermissions } from 'lib/auth';
import { isFeatureEnabled } from 'lib/featureFlag';
import { Paths } from 'lib/routes';
import { DefaultTimeRange } from 'views/reports/ac-power/settings';

import { RouteExtraData } from '../../lib/route-helpers';
import { PageHeader } from '../PageHeader';
import { GlobalSearchOverlay } from './search/GlobalSearchOverlay';
import style from './style.module.css';

const classNames = classNamesBind.bind(style);

interface ThemedPageProps {
    children: React.ReactNode;
}

export const ThemedPage: FC<ThemedPageProps> = ({ children }) => {
    const userPermissions = useUserPermissions();
    const { hasAdministration, hasAssetsRead, hasTasksRead, hasAuditRead } = userPermissions;

    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const matches = useMatches();
    const currentPage = matches[matches.length - 1];

    let enableFullWidth = false;
    if (currentPage.handle) {
        enableFullWidth = (currentPage.handle as RouteExtraData).fullWidth ?? false;
    }

    const onOpenSearch = () => {
        setIsSearchOpen(true);
    };

    const handleSearchClose = useCallback(() => {
        setIsSearchOpen(false);
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault();
                setIsSearchOpen(prev => !prev);
            } else if (event.key === 'Escape') {
                setIsSearchOpen(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isSearchOpen]);

    useEffect(() => {
        // NOTE: Whenever the page changes, reset the search state and close the search overlay
        setIsSearchOpen(false);
    }, [currentPage.pathname]);

    const primaryNavigation: TopLevelNavigationItem[] = [
        {
            title: 'Overview',
            to: '/',
            icon: <OverviewIcon />,
        },
        {
            title: 'Sites',
            to: Paths.PowerControllers,
            icon: <SitesIcon />,
        },
        {
            title: 'Firmware Status',
            to: '/status/firmware',
            icon: <FirmwareIcon />,
            displayed: isFeatureEnabled('firmware'),
        },
        {
            title: 'Reports',
            to: Paths.ReportsOverview,
            icon: <ReportsIcon />,
            children: [
                {
                    title: 'Insights',
                    to: Paths.ReportAlerts,
                },
                {
                    title: 'AC Power',
                    to: generatePath(Paths.ReportACPower, { interval: DefaultTimeRange }),
                    displayed: isFeatureEnabled('acpower_report'),
                },
                {
                    title: 'Batteries',
                    to: Paths.ReportBatteries,
                },
            ],
        },
        {
            title: 'Tasks',
            to: Paths.Tasks,
            icon: <TasksIcon />,
            displayed: hasTasksRead,
            children: [
                {
                    title: 'Battery Health Tests',
                    to: Paths.TestsOverview,
                },
                {
                    title: 'Generator Runs',
                    to: Paths.TasksGeneratorsOverview,
                    displayed: isFeatureEnabled('generator_run'),
                },
            ],
        },
    ];

    const secondaryNavigation: NavigationItem[] = [
        {
            title: 'Explore metrics',
            to: Paths.ExploreMetrics,
            icon: <MagIcon />,
        },
        {
            title: 'Manage',
            to: Paths.Assets,
            icon: <PlusIcon />,
            displayed: hasAssetsRead,
        },
        {
            title: 'Admin',
            to: Paths.Administration,
            icon: <ConfigurationIcon />,
            displayed: hasAdministration || hasAuditRead,
        },
    ];

    return (
        <div className={classNames('page_layout')}>
            <VerticalNavigation items={primaryNavigation} secondaryItems={secondaryNavigation} />
            <div className={classNames('container')}>
                <PageHeader isSearchOpen={isSearchOpen} onOpenSearch={onOpenSearch} />
                <div className={classNames('main_content_area', { full_width: enableFullWidth })}>{children}</div>
                {isSearchOpen && <GlobalSearchOverlay onClose={handleSearchClose} />}
            </div>
        </div>
    );
};
