import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ReportTile, ReportTileProps } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { DateTime, Duration } from 'luxon';

import { getSectionTimeDescription } from '../../../common';
import { TimeRange } from '../../../settings';
import { DeviceIncidentList } from '../components/DeviceIncidentList';
import { ACDeviceIncidents_acPower$key } from './__generated__/ACDeviceIncidents_acPower.graphql';

export interface ACDeviceIncidentsProps {
    deviceId: string;
    siteId: string;
    fragmentRef: ACDeviceIncidents_acPower$key;
    timeRange: TimeRange;
    significantIncidentThreshold: Duration;
}

export const ACDeviceIncidents: FC<ACDeviceIncidentsProps> = ({
    deviceId,
    siteId,
    fragmentRef,
    timeRange,
    significantIncidentThreshold,
}) => {
    const data = useFragment(Fragment, fragmentRef);
    const { incidentCount, sigIncidentCount, latestSigIncident } = data;

    const significantIncidentProps: Pick<ReportTileProps, 'content' | 'description' | 'link'> = {
        content: 'No incidents',
    };

    if (latestSigIncident.data.length > 0) {
        const incident = latestSigIncident.data[0];

        const startTime = DateTime.fromISO(incident.startTime);
        const timeSinceNow = startTime.diffNow().negate();

        significantIncidentProps.content = `${humanizeDuration(timeSinceNow.as('milliseconds'), {
            largest: 1,
            round: true,
        })} ago`;

        const durationDescription = humanizeDuration(Duration.fromISO(incident.duration).as('milliseconds'), {
            largest: 1,
            round: true,
        });

        switch (incident.worstStatus) {
            case 'Outage':
                significantIncidentProps.description = `${durationDescription} outage`;
                break;
            case 'UnderVoltage':
                significantIncidentProps.description = `${durationDescription} undervoltage`;
                break;
            case 'OverVoltage':
                significantIncidentProps.description = `${durationDescription} overvoltage`;
                break;
        }

        // TODO: Add in the link
    }

    const significantIncidentThresholdDescription = humanizeDuration(significantIncidentThreshold.as('milliseconds'), {
        largest: 1,
        round: true,
    });

    return (
        <div className='grid grid-cols-12 gap-x-4 col-span-12'>
            <div className='col-span-12 lg:col-span-4 font-bold text-xl mt-6'>
                Incidents <span className='font-light text-xs'>{getSectionTimeDescription(timeRange, 'device')}</span>
            </div>
            <p className='col-span-12 lg:col-span-4 mb-4 lg:mt-4 text-eggplantLight font-light lg:row-start-4 text-sm'>
                Significant incidents are defined as lasting longer than {significantIncidentThresholdDescription}. This
                includes outage, undervoltage, and overvoltage incidents.
            </p>
            <ReportTile
                title='Last significant incident'
                {...significantIncidentProps}
                tileClassName='col-span-12 lg:col-span-4 mb-4'
            />
            <ReportTile
                title='Significant incidents'
                content={sigIncidentCount.total}
                tileClassName='col-span-6 lg:col-span-2'
                description={<>&nbsp;</>}
            />
            <ReportTile
                title='All incidents'
                content={incidentCount.total}
                tileClassName='col-span-6 lg:col-span-2'
                description={<>&nbsp;</>}
            />

            <div className='col-span-12 lg:col-span-8 mt-6 row-span-5 lg:row-start-1 lg:col-start-5'>
                <DeviceIncidentList deviceId={deviceId} siteId={siteId} fragmentRef={data} />
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment ACDeviceIncidents_acPower on DeviceACPower @argumentDefinitions(startRange: { type: "DateRangeFilter" }) {
        latestSigIncident: events(
            filters: { isSignificant: { value: true }, startTime: $startRange }
            orderBy: { field: StartTime, dir: Desc }
        ) {
            data {
                startTime
                duration
                worstStatus
            }
        }
        sigIncidentCount: events(filters: { isSignificant: { value: true }, startTime: $startRange }) {
            total
        }
        incidentCount: events(filters: { startTime: $startRange }) {
            total
        }
        ...DeviceIncidentList_acPower @arguments(showSignificant: { value: true }, startRange: $startRange)
    }
`;
