import { DeviceTestResultTypeOptions } from './components';
import { DeviceTestResultTypeFilter } from './types';

export function decodeDeviceTestResultTypeFilter(value: string): DeviceTestResultTypeFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = DeviceTestResultTypeOptions.find(option => option === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}
