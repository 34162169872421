import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import { BatteryEmptyIcon, DescriptionIcon } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNamesBind from 'classnames/bind';
import { NotesSection } from 'components/NotesSection';
import { formatMinutesAsHoursAndMinutes } from 'lib/dateFormatter';
import { formatAsCauseSentence, formatBatteryType } from 'lib/textFormatters';
import { formatValueWithString } from 'lib/units';

import { BorderedSection } from '../../../../../components/BorderedSection';
import { formatAPIDate } from '../common';
import {
    TestParameterView_test$data,
    TestParameterView_test$key,
} from './__generated__/TestParameterView_test.graphql';
import style from './style.module.css';

const classNames = classNamesBind.bind(style);

interface TestParameterViewProps {
    test: TestParameterView_test$key;
}

export const TestParameterView: FC<TestParameterViewProps> = ({ test }) => {
    const result = useFragment(TestParametersFragment, test);

    return (
        <div className={classNames('test_parameter_view')}>
            <TestDetailsSection test={result} />
            <BatteryDetailsSection test={result} />
            <NotesSection editId={result.id} note={result.note} type='batteryTest' />
        </div>
    );
};

interface TestDetailsSectionProps {
    test: TestParameterView_test$data;
}

const TestDetailsSection: FC<TestDetailsSectionProps> = ({ test }) => {
    const schedule = test.task?.schedule;

    let repeatText: string;
    if (schedule && schedule.repeat !== 'Never') {
        repeatText = `Repeats ${schedule.repeat.toLocaleLowerCase()}`;
    } else {
        repeatText = 'Does not repeat';
    }

    let testDescription: ReactNode = '';
    if (test.task) {
        if (test.task.type === 'Capacity') {
            let minimumVoltage: number | undefined;
            test.task.settings.targets!.forEach(target => {
                if (minimumVoltage === undefined || target.endOfDischargeVoltage < minimumVoltage) {
                    minimumVoltage = target.endOfDischargeVoltage;
                }
            });

            testDescription = `Capacity test to ${formatValueWithString(minimumVoltage ?? '-', 'Vpc')}.`;
        } else if (test.task.type === 'Quick') {
            let reserveTimeThresholdDescription: string;
            if (test.device.battery.reserveTime != null) {
                const thresholdMinutes = (test.task.settings.reserveTime! / 100) * test.device.battery.reserveTime;
                reserveTimeThresholdDescription = `${thresholdMinutes} minutes`;
            } else {
                reserveTimeThresholdDescription = `${formatValueWithString(
                    test.task.settings.reserveTime ?? '-',
                    '%'
                )} of reserve time`;
            }

            testDescription = `Quick test running for ${reserveTimeThresholdDescription} with a ${formatValueWithString(
                test.task.settings.threshold ?? '-',
                'V'
            )} cutoff.`;
        } else if (test.task.type === 'Custom') {
            const settingsDescriptions: string[] = [];

            if (test.task.settings.maxDuration != null) {
                settingsDescriptions.push(`Up to ${test.task.settings.maxDuration} minutes runtime`);
            }

            if (test.task.settings.maxDischarged != null) {
                settingsDescriptions.push(
                    `At most ${formatValueWithString(test.task.settings.maxDischarged, 'Ah')} discharged`
                );
            }

            if (test.task.settings.endOfDischargeVoltage != null) {
                settingsDescriptions.push(
                    `Ending at ${formatValueWithString(test.task.settings.endOfDischargeVoltage, 'V')}`
                );
            }

            testDescription = (
                <>
                    Configured as a custom test:
                    <ul className={classNames('bullet_list')}>
                        {settingsDescriptions.map((description, i) => (
                            <li key={i}>{description}</li>
                        ))}
                    </ul>
                </>
            );
        }
    }

    return (
        <BorderedSection title='Description' icon={<DescriptionIcon />}>
            {test.task && (
                <>
                    <div>{testDescription}</div>
                    <div>
                        {test.state === 'Scheduled' ? 'Starting ' : 'It was scheduled for '}
                        {formatAPIDate(test.task.schedule.time)}.
                    </div>
                    <div>{repeatText}.</div>
                </>
            )}
            {!test.task && test.cause !== 'CompanionDischarge' && (
                <>
                    <p>A discharge that occurred outside of accata.</p>
                    <p>It was caused by {formatAsCauseSentence(test.cause)}.</p>
                </>
            )}
            {!test.task && test.cause === 'CompanionDischarge' && (
                <>
                    <p>No discharge occurred on {test.device.name}, however the other plane was discharging.</p>
                </>
            )}
        </BorderedSection>
    );
};

interface BatteryDetailsSectionProps {
    test: TestParameterView_test$data;
}

const BatteryDetailsSection: FC<BatteryDetailsSectionProps> = ({ test }) => {
    const batteryStrings = test.batteryStrings;

    let capacityDescription: string | undefined;
    if (test.originalCapacity != null) {
        capacityDescription = `${formatValueWithString(test.originalCapacity, 'Ah')} total capacity.`;
    }

    let reserveTimeDescription: string | undefined;
    if (test.device.battery.reserveTime != null) {
        reserveTimeDescription = `${formatMinutesAsHoursAndMinutes(
            test.device.battery.reserveTime
        )} configured reserve time.`;
    }

    let headerLine: string;
    if (capacityDescription && reserveTimeDescription) {
        headerLine = `${capacityDescription} ${reserveTimeDescription}`;
    } else if (capacityDescription) {
        headerLine = capacityDescription;
    } else if (reserveTimeDescription) {
        headerLine = reserveTimeDescription;
    } else {
        headerLine = `${batteryStrings.length} battery strings.`;
    }

    return (
        <BorderedSection title='Installed battery strings' icon={<BatteryEmptyIcon />}>
            <div>{headerLine}</div>
            <ul className={classNames('bullet_list')}>
                {test.batteryStrings.map((battery, i) => (
                    <li key={`battery-${i}`}>
                        {formatValueWithString(battery.originalCapacity ?? '-', 'Ah')} {battery.type.manufacturer}{' '}
                        {battery.type.model}{' '}
                        <span className='text-sm' style={{ color: '#808080' }}>
                            {formatBatteryType(battery.type.technology)}
                        </span>
                    </li>
                ))}
            </ul>
        </BorderedSection>
    );
};

const TestParametersFragment = graphql`
    fragment TestParameterView_test on DeviceBatteryTestResults {
        id
        state
        task {
            creator {
                name
                email
            }
            schedule {
                repeat
                time
            }
            type
            createdTime
            completedTime
            settings {
                ... on BatteryTestTypeCapacity {
                    targets {
                        endOfDischargeVoltage
                        batteryType {
                            id
                            manufacturer
                            model
                        }
                    }
                }
                ... on BatteryTestTypeQuick {
                    reserveTime
                    threshold
                }
                ... on BatteryTestTypeCustom {
                    endOfDischargeVoltage
                    maxDuration
                    maxDischarged
                }
            }
        }
        commencedTime
        completedTime
        abortedTime
        abortedBy {
            name
            email
        }
        batteryStrings {
            type {
                manufacturer
                model
                technology
            }
            string
            originalCapacity
        }
        batteryTypes {
            manufacturer
            model
            technology
        }
        cause
        device {
            name
            battery {
                reserveTime(unit: Minutes)
            }
        }
        originalCapacity(unit: AmpHour)
        note {
            content
        }
    }
`;
