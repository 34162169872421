import React, { ReactElement, ReactNode, useCallback, useRef, useState } from 'react';

import classNames from 'classnames';

import style from './style.module.css';

export interface SingleSelectionListProps<T> {
    items: T[];
    selectedItem: T | undefined;
    onSelect: (item: T) => void;
    renderItem: (item: T) => ReactNode;
}

export function SingleSelectionList<T>({
    items,
    selectedItem,
    onSelect,
    renderItem,
}: SingleSelectionListProps<T>): ReactElement {
    const [keyboardSelected, setKeyboardSelected] = useState<number | null>(null);
    const [keyboardUsed, setKeyboardUsed] = useState<boolean>(false);
    const listElement = useRef<HTMLUListElement>(null);

    const handleFocus = useCallback(() => {
        setKeyboardSelected(index => (index != null ? index : 0));
    }, []);

    const handleBlur = useCallback(() => {
        setKeyboardUsed(false);
    }, []);

    const handleSelect = useCallback(
        (item: T, _index: number) => {
            onSelect(item);
        },
        [onSelect]
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            setKeyboardUsed(true);
            switch (event.key) {
                case 'ArrowDown':
                    setKeyboardSelected(index => {
                        if (index == null) {
                            return 0;
                        }
                        if (index >= items.length - 1) {
                            return items.length - 1;
                        }
                        return index + 1;
                    });
                    break;
                case 'ArrowUp':
                    setKeyboardSelected(index => {
                        if (index == null) {
                            return 0;
                        }
                        if (index <= 0) {
                            return 0;
                        }
                        return index - 1;
                    });
                    break;
                case 'Home':
                    setKeyboardSelected(() => {
                        return 0;
                    });
                    break;
                case 'End':
                    setKeyboardSelected(() => {
                        return items.length - 1;
                    });
                    break;
                case 'Enter':
                    if (!event.ctrlKey && keyboardSelected != null) {
                        handleSelect(items[keyboardSelected], keyboardSelected);
                    }
                    break;
            }
            event.preventDefault();
        },
        [handleSelect, items, keyboardSelected]
    );

    return (
        <ul
            className={classNames(style.list, { [style.key_focus]: keyboardUsed })}
            ref={listElement}
            tabIndex={0}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            role='listbox'
        >
            {items.map((item, index) => (
                <li
                    key={index}
                    className={selectedItem === item ? 'bg-eggplantRegular text-white' : 'hover:bg-white'}
                    onClick={() => onSelect(item)}
                    role='option'
                    aria-selected={index === keyboardSelected ? true : undefined}
                >
                    {renderItem(item)}
                </li>
            ))}
        </ul>
    );
}
