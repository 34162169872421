import React, { FC, useCallback, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { Search as TableLayoutSearch } from 'layouts/TableLayout/components/Search';
import { Paths } from 'lib/routes';
import { ReportSearchResults, SearchGroups, renderSearchResult } from 'views/reports/batteries/search';

import { SearchQuery } from './__generated__/SearchQuery.graphql';

export const Search: FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const environment = useRelayEnvironment();
    const navigate = useExtendedNavigate();

    const handleSearch = useCallback(
        (input: string) => {
            setSearchTerm(input);
            return fetchQuery<SearchQuery>(
                environment,
                graphql`
                    query SearchQuery($search: String!, $pageSize: Int!) {
                        sites(
                            search: $search
                            pageSize: $pageSize
                            orderBy: { field: Name, dir: Asc }
                            onlyProvisioningStatuses: Active
                        ) {
                            data {
                                id
                                name
                                address {
                                    state
                                }
                            }
                        }
                        devices(search: $search, pageSize: $pageSize, orderBy: { field: Name, dir: Asc }) {
                            data {
                                id
                                name
                                site {
                                    id
                                    name
                                    address {
                                        state
                                    }
                                }
                            }
                        }
                    }
                `,
                { search: input, pageSize: 10 }
            )
                .toPromise()
                .then(results => {
                    const deviceResults: ReportSearchResults[] =
                        results?.devices.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.site.id,
                            siteName: result.site.name,
                            state: result.site.address.state,
                            type: 'device',
                        })) ?? [];

                    const siteResults: ReportSearchResults[] =
                        results?.sites.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.id,
                            siteName: result.name,
                            state: result.address.state,
                            type: 'site',
                        })) ?? [];

                    return deviceResults.concat(siteResults);
                });
        },
        [environment]
    );

    const handleDoSearch = useCallback(
        (_input: string, item?: ReportSearchResults | null) => {
            if (item) {
                let url: string;
                if (item.type === 'device') {
                    url = generatePath(Paths.ReportACPowerSiteViewDevice, { siteId: item.site, deviceId: item.id });
                } else {
                    url = generatePath(Paths.ReportACPowerSiteView, { siteId: item.site });
                }

                navigate(url);
            }
        },
        [navigate]
    );

    const onClear = useCallback(() => {
        setSearchTerm('');
    }, []);

    return (
        <TableLayoutSearch
            placeholder='Search by Site/Device'
            onSearch={handleSearch}
            onClear={onClear}
            onFilter={handleDoSearch}
            renderSearchResult={renderSearchResult}
            isFiltering={searchTerm.length > 0}
            renderSearchResultAsString={(item: ReportSearchResults) => item.name}
            additionalProps={{
                groups: SearchGroups,
                groupKey: 'type',
            }}
        />
    );
};
