import React, { Dispatch, SetStateAction, useRef } from 'react';

import { Minimap } from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';

import { AddColumn } from './components/AddColumn';
import { CompareColumnHeader } from './components/CompareColumnHeader';
import { DataRow } from './components/DataRow';
import { HeaderColumnHeader } from './components/HeaderColumnHeader';
import { HeaderSection } from './components/HeaderSection';
import style from './style.module.css';
import { CommonCompareLayoutProps, FieldDifferences, SectionExpandedState } from './types';

const classNames = classNamesBind.bind(style);

export interface CompareBodyProps<T> extends CommonCompareLayoutProps<T> {
    columnFitCount?: number;

    isComparing: boolean;
    setIsComparing: Dispatch<SetStateAction<boolean>>;

    sectionExpandedState: SectionExpandedState;
    setSectionExpandedState: Dispatch<SetStateAction<SectionExpandedState>>;

    differences: FieldDifferences;
}

export function CompareBody<T>({
    // Own props
    columnFitCount,
    isComparing,
    setIsComparing,
    sectionExpandedState,
    setSectionExpandedState,
    differences,
    // Common props
    data,
    sections,
    renderColumnHeader,
    addEnabled,
    addLabel = 'Add',
    addButtonEnabled,
    addButtonDisabledMessage,
    onRequestAdd,
    removeEnabled,
    removeLabel = 'Remove',
    onRequestRemove,
}: CompareBodyProps<T>) {
    const scrollableAreaRef = useRef<HTMLDivElement>(null);

    let rowCount = 1;
    for (const section of sections) {
        rowCount += section.fields.length + 1;
    }

    const prominentSectionStart = 2;
    const prominentSectionEnd = prominentSectionStart + sections[0].fields.length + 1;

    // If we have the same number columns as the maximum, there is a small amount of available
    // space for the add column, so we use a smaller add column to avoid scrolling
    const useSmallAddColumn = data.length === columnFitCount;

    return (
        <>
            <div className={classNames('grid_container')}>
                <div className={classNames('grid_header_column')} style={{ gridRowEnd: `span ${rowCount}` }}>
                    <HeaderColumnHeader
                        isHighlightingDifferences={isComparing}
                        onHighlightDifferencesChange={setIsComparing}
                    />
                    {sections.map((section, index) => (
                        <HeaderSection
                            key={index}
                            section={section}
                            prominent={index === 0}
                            differences={isComparing ? differences : undefined}
                            collapsed={!sectionExpandedState[section.title]}
                            onToggleCollapsed={collapsed =>
                                setSectionExpandedState(state => ({ ...state, [section.title]: !collapsed }))
                            }
                        />
                    ))}
                </div>
                <div
                    ref={scrollableAreaRef}
                    className={classNames('grid_scrollable_area')}
                    style={{
                        gridRowEnd: `span ${rowCount}`,
                        gridTemplateColumns: `repeat(${data.length}, var(--column-width)) ${
                            useSmallAddColumn ? '1fr' : 'var(--column-width)'
                        }`,
                    }}
                >
                    {data.map((item, index) => {
                        const renderInfo = renderColumnHeader(item);
                        return (
                            <CompareColumnHeader
                                key={index}
                                {...renderInfo}
                                removeEnabled={removeEnabled}
                                removeLabel={removeLabel}
                                onRemove={() => onRequestRemove?.(index, item)}
                            />
                        );
                    })}

                    {sections.map((section, index) => (
                        <DataRow
                            key={index}
                            section={section}
                            data={data}
                            differences={isComparing ? differences : undefined}
                            collapsed={!sectionExpandedState[section.title]}
                        />
                    ))}
                    {addEnabled && (
                        <AddColumn
                            column={data.length + 1}
                            rows={rowCount - 1}
                            small={useSmallAddColumn}
                            label={addLabel}
                            addButtonEnabled={addButtonEnabled}
                            addButtonDisabledMessage={addButtonDisabledMessage}
                            onClick={onRequestAdd}
                        />
                    )}
                </div>
                <div
                    className={classNames('prominant_row')}
                    style={{ gridRowStart: prominentSectionStart, gridRowEnd: prominentSectionEnd }}
                ></div>
            </div>
            <Minimap
                containerRef={scrollableAreaRef}
                columns={useSmallAddColumn || !addEnabled ? data.length : data.length + 1}
            />
        </>
    );
}
