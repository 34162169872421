import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { numberToLocaleString } from 'lib/numberFormatters';
import { formatValueWithUnit } from 'lib/units';

import { FuelStats_data$key } from './__generated__/FuelStats_data.graphql';

export interface FuelStatsProps {
    runReport: FuelStats_data$key;
}

export const FuelStats: FC<FuelStatsProps> = ({ runReport }) => {
    const data = useFragment<FuelStats_data$key>(FuelStatsFragment, runReport);
    const unitPreferences = useCurrentUserUnitsPref();

    return (
        <div data-testid='fuel-stats'>
            <div className='pb-4 font-semibold'>Fuel</div>
            <div className='flex justify-between w-1/2'>
                <FuelMetric
                    label='Consumed'
                    value={formatValueWithUnit(numberToLocaleString(data.fuelConsumed), unitPreferences.volume)}
                />
                <FuelMetric
                    label='Capacity'
                    value={formatValueWithUnit(
                        numberToLocaleString(data.generator.fuelTankMetrics.capacity),
                        unitPreferences.volume
                    )}
                />
                <FuelMetric
                    label='Refuelled'
                    value={formatValueWithUnit(numberToLocaleString(data.fuelRefuelled), unitPreferences.volume)}
                />
            </div>
        </div>
    );
};

const FuelStatsFragment = graphql`
    fragment FuelStats_data on GeneratorRunReport @argumentDefinitions(unitVolume: { type: "UnitVolume" }) {
        fuelConsumed(unit: $unitVolume)
        fuelRefuelled(unit: $unitVolume)
        generator {
            fuelTankMetrics {
                capacity(unit: $unitVolume)
            }
        }
    }
`;

const FuelMetric: FC<{ label: string; value: string | null }> = ({ label, value }) => {
    return (
        <div className='flex flex-col' data-testid='fuel-metric'>
            <div className='text-sm font-light'>{label}</div>
            <div className='text-lg font-semibold'>{value}</div>
        </div>
    );
};
