/**
 * @generated SignedSource<<bdf0957873ad2e56fc8872e89a58de48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverallPerformanceSection$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BatteriesGauge" | "FirmwareGauge" | "PowerControllersGauge">;
  readonly " $fragmentType": "OverallPerformanceSection";
};
export type OverallPerformanceSection$key = {
  readonly " $data"?: OverallPerformanceSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverallPerformanceSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverallPerformanceSection",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PowerControllersGauge"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BatteriesGauge"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FirmwareGauge"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ccbdf8502751db3467b90602eb6a2932";

export default node;
