import React, { FC } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { CommonFilterProps } from 'filters/common';
import { BatteryTestNameFilter, TaskBatteryTestNameFilterResult } from 'filters/task';
import { IEnvironment } from 'relay-runtime';

import { TestNameFilterUI_testNameSearchQuery } from './__generated__/TestNameFilterUI_testNameSearchQuery.graphql';

export interface TestNameFilterUIProps extends CommonFilterProps<BatteryTestNameFilter[]> {
    testIds?: string[];
}

export const TestNameFilterUI: FC<TestNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
    testIds,
}) => {
    const environment = useRelayEnvironment();

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by battery test'
            placeholder='Enter battery test name'
            renderItem={item => <TaskBatteryTestNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5, testIds)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

export async function doSearch(
    environment: IEnvironment,
    input: string,
    maxResults: number,
    testIds?: string[]
): Promise<BatteryTestNameFilter[]> {
    try {
        const results = await fetchQuery<TestNameFilterUI_testNameSearchQuery>(
            environment,
            graphql`
                query TestNameFilterUI_testNameSearchQuery($input: String!, $maxResults: Int!, $testIds: [String!]) {
                    batteryTestResults(search: $input, pageSize: $maxResults, ids: $testIds) {
                        data {
                            task {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
                testIds,
            }
        ).toPromise();

        const tasks = results?.batteryTestResults.data ?? [];

        const output: BatteryTestNameFilter[] = tasks
            .filter(task => task.task?.name)
            .map(task => ({
                type: 'result',
                id: task.task?.id,
                name: task.task!.name!,
            }));

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch {
        return [];
    }
}

function createWildcardFilter(input: string): BatteryTestNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
