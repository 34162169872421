import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { CustomDurationRangeFilter, PredefinedDurationRangeFilter } from 'components/FilterDurationSelect/common';
import { DateTime, Duration, Settings } from 'luxon';

const getShortDateFormatter = () =>
    Intl.DateTimeFormat(undefined, {
        dateStyle: 'short',
        timeZone: typeof Settings.defaultZone === 'string' ? Settings.defaultZone : Settings.defaultZone.name,
    });
const getShortTimeFormatter = () =>
    Intl.DateTimeFormat(undefined, {
        timeStyle: 'short',
        timeZone: typeof Settings.defaultZone === 'string' ? Settings.defaultZone : Settings.defaultZone.name,
    });
const getShortDateTimeFormatter = () =>
    Intl.DateTimeFormat(undefined, {
        dateStyle: 'short',
        timeStyle: 'short',
        timeZone: typeof Settings.defaultZone === 'string' ? Settings.defaultZone : Settings.defaultZone.name,
    });

export function formatDateFilter(value: DateRangeFilter): string {
    if (value.type === 'duration') {
        if (value.unit === 'minutes' || value.unit === 'hours') {
            if (DateTime.fromJSDate(value.range[0]).hasSame(DateTime.now(), 'day')) {
                return `After ${getShortTimeFormatter().format(value.range[0])}`;
            } else {
                return `After ${getShortDateTimeFormatter().format(value.range[0])}`;
            }
        } else {
            return `After ${getShortDateFormatter().format(value.range[0])}`;
        }
    }
    if (value.range[0].getFullYear() === 0) {
        return `Before ${getShortDateFormatter().format(value.range[1])}`;
    }

    return `${getShortDateFormatter().format(value.range[0])} to ${getShortDateFormatter().format(value.range[1])}`;
}

export function formatDurationFilter(value: CustomDurationRangeFilter | PredefinedDurationRangeFilter): string {
    if (value.type === 'predefined') {
        return value.preset;
    } else {
        const range = value.range;
        const start = `${Math.trunc(Duration.fromISO(range[0]).as('hours'))}h${Duration.fromISO(range[0]).minutes}m`;
        const end = `${Math.trunc(Duration.fromISO(range[1]).as('hours'))}h${Duration.fromISO(range[1]).minutes}m`;

        return `${start} to ${end}`;
    }
}
