import React, { FC, ReactNode } from 'react';

import { Button, CircleAlertIcon } from '@accesstel/pcm-ui';

import classNames from 'classnames';

interface AlertBannerAction {
    label: string;
    onClick?: () => void;
    to?: string;
}

export interface AlertBannerProps {
    title: string;
    message: ReactNode;
    action?: AlertBannerAction;
    background?: 'light' | 'dark';
}

export const AlertBanner: FC<AlertBannerProps> = ({ title, message, action, background = 'light' }) => {
    return (
        <div className='py-10'>
            <div
                className={classNames(
                    'p-4 w-2/3 text-center rounded-2xl mx-auto shadow-md',
                    background === 'light' ? 'bg-white' : 'bg-grayRegular'
                )}
            >
                <div className='flex justify-center mb-1'>
                    <div className='w-7 h-7 text-coralRegular'>
                        <CircleAlertIcon />
                    </div>
                </div>
                <div className='text-lg font-bold text-coralRegular'>{title}</div>
                <div className='text-sm font-normal pb-4'>{message}</div>
                {action && <Button buttonText={action.label} onClick={action.onClick} to={action.to} />}
            </div>
        </div>
    );
};
