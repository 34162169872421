import React, { FC, useCallback, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { TestResultTableColumn, testResultToFilterObject, useTestResultFilter } from 'filters/test-result';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { useCurrentUserUnitsPref, useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';

import { useDocumentTitle } from '../../../../components';
import { useTableQuery } from '../../../../lib/tables/table-query';
import { MaxTestDisplay } from '../settings';
import { CompareURLParamTests } from '../test-compare/settings';
import { TestSearchResult } from './TestSearchResult';
import { TestResultListAllIdsQuery } from './__generated__/TestResultListAllIdsQuery.graphql';
import {
    TestResultListQuery,
    TestResultOrdering,
    TestResultSortField,
} from './__generated__/TestResultListQuery.graphql';
import { TestResultListSearchQuery } from './__generated__/TestResultListSearchQuery.graphql';
import { exportBatteryTestResults } from './lib/test-result-export';
import { AllTableColumns, BaseTableColumns } from './settings';
import { TestResult } from './types';

const TableStorageKeyPrefix = 'external-discharge-table';

export const TestResultList: FC = () => {
    const { hasTasksWrite } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const unitPreferences = useCurrentUserUnitsPref();

    const pageTitle = 'External discharges';
    useDocumentTitle(pageTitle);

    const environment = useRelayEnvironment();
    const [tableState, dispatchTableState] = useTableReducer<TestResultTableColumn>({
        defaultSortColumn: TestResultTableColumn.StartTime,
        defaultSortDirection: SortDirection.Descending,
        allColumns: AllTableColumns,
        defaultVisibleColumns: BaseTableColumns.map(col => col.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useTestResultFilter();
    const filterObject = useMemo(() => testResultToFilterObject(filters), [filters]);

    const {
        data: props,
        retry,
        error,
        isFetching,
    } = useTableQuery<TestResultTableColumn, TestResultListQuery>(
        graphql`
            query TestResultListQuery(
                $deviceName: String = ""
                $page: Int = 1
                $pageSize: Int = 50
                $pageCount: Int
                $filters: TestResultFilter
                $orderBy: TestResultOrdering
                $withFailReason: Boolean = false
                $withCurrentMetrics: Boolean = false
                $withTemperatureMetrics: Boolean = false
                $withVoltageMetrics: Boolean = false
                $withDischargedMetrics: Boolean = false
                $withStateOfHealth: Boolean = false
                $withCapacity: Boolean = false
                $withReserveTime: Boolean = false
                $unitTemperature: UnitTemperature
            ) {
                unplannedTests(
                    page: $page
                    pageSize: $pageSize
                    pageCount: $pageCount
                    filters: $filters
                    orderBy: $orderBy
                    search: $deviceName
                ) {
                    total
                    data {
                        id
                        name
                        state
                        commencedTime
                        completedTime
                        batteryStrings {
                            string
                        }
                        batteryTypes {
                            minimumStateOfHealth @include(if: $withStateOfHealth)
                        }
                        device {
                            id
                            name
                            battery {
                                reserveTime(unit: Minutes) @include(if: $withReserveTime)
                            }
                        }
                        cause
                        failReason @include(if: $withFailReason)
                        averageCurrent @include(if: $withCurrentMetrics)
                        averageTemperature(unit: $unitTemperature) @include(if: $withTemperatureMetrics)
                        finalVoltage @include(if: $withVoltageMetrics)
                        discharged @include(if: $withDischargedMetrics)
                        estimatedStateOfHealth @include(if: $withStateOfHealth) {
                            ... on EstimatedValue {
                                value
                                type
                                confidenceInterval {
                                    lower
                                    upper
                                }
                            }
                            ... on MissingEstimatedValue {
                                reason
                                reasonDescription
                            }
                        }
                        estimatedCapacity @include(if: $withCapacity) {
                            ... on EstimatedValue {
                                value
                                type
                                confidenceInterval {
                                    lower
                                    upper
                                }
                            }
                            ... on MissingEstimatedValue {
                                reason
                                reasonDescription
                            }
                        }
                        estimatedReserveTime @include(if: $withReserveTime) {
                            ... on EstimatedValue {
                                value
                                type
                                confidenceInterval {
                                    lower
                                    upper
                                }
                            }
                            ... on MissingEstimatedValue {
                                reason
                                reasonDescription
                            }
                        }
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                testCount: unplannedTests {
                    total
                }
            }
        `,
        options => {
            const sortObject: TestResultOrdering = {
                field: options.orderBy as TestResultSortField,
                dir: options.orderDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
            };

            return {
                page: options.page,
                pageSize: options.pageSize,
                pageCount: options.pageCount,
                filters: testResultToFilterObject(filters),
                orderBy: sortObject,
                deviceName: options.search,
                withFailReason: options.visibleColumns.includes(TestResultTableColumn.FailReason),
                withCurrentMetrics: options.visibleColumns.includes(TestResultTableColumn.CurrentMetrics),
                withTemperatureMetrics: options.visibleColumns.includes(TestResultTableColumn.TemperatureMetrics),
                withVoltageMetrics: options.visibleColumns.includes(TestResultTableColumn.VoltageMetrics),
                withDischargedMetrics: options.visibleColumns.includes(TestResultTableColumn.DischargedMetrics),
                withStateOfHealth: options.visibleColumns.includes(TestResultTableColumn.EstimatedStateOfHealth),
                withCapacity: options.visibleColumns.includes(TestResultTableColumn.EstimatedCapacity),
                withReserveTime: options.visibleColumns.includes(TestResultTableColumn.EstimatedReserveTime),
                unitTemperature: unitPreferences.temperature,
            };
        },
        tableState
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<TestResultListSearchQuery>(environment, SearchQuery, {
                pageSize: 10,
                search: input,
            })
                .toPromise()
                .then(result => (result?.unplannedTests.data as TestResult[]) ?? []);
        },
        [environment]
    );

    return (
        <>
            <TableLayout
                title={pageTitle}
                columns={AllTableColumns}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={(props?.unplannedTests.data ?? null) as TestResult[]}
                getRowId={row => row.id}
                isProcessing={!!props && isFetching}
                page={props?.unplannedTests.pageInfo.page}
                pageCount={props?.unplannedTests.pageInfo.total}
                overallCount={props?.testCount.total}
                resultCount={props?.unplannedTests.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search results'
                onSearch={handleSearch}
                renderSearchResultAsString={item => item.name ?? item.id}
                renderSearchResult={(item: TestResult, context) => <TestSearchResult result={item} context={context} />}
                emptyMessage='There are no tests present'
                unit='Test'
                primaryAction={hasTasksWrite ? 'Schedule a test' : undefined}
                primaryActionLink={hasTasksWrite ? Paths.TestsScheduleTest : undefined}
                getItemLink={item => `${Paths.TestsUnplanned}/${item.id}`}
                exportEnabled
                exportFilename='external-discharges'
                exportFetchData={options =>
                    exportBatteryTestResults(options, filters, environment) as Promise<TestResult[]>
                }
                selection
                selectionFooterActions={[
                    {
                        buttonText: `Compare tests (${tableState.selectedItems.length}/${MaxTestDisplay})`,
                        disabled: tableState.selectedItems.length > MaxTestDisplay,
                        disabledMessage: `You can only select a maximum of ${MaxTestDisplay} tests for comparison`,
                        onClick: () => {
                            navigate({
                                pathname: Paths.TestsCompare,
                                search: {
                                    [CompareURLParamTests]: tableState.selectedItems.join(','),
                                },
                            });
                        },
                    },
                ]}
                selectionFooterSelectedItems={tableState.selectedItems}
                selectionFooterUnitOverride={`Test`}
                selectionFooterUnitPluralOverride={`Tests`}
                onRequestAllIds={() => getAllUnplannedTestIds(environment, filterObject)}
            />
        </>
    );
};

async function getAllUnplannedTestIds(
    environment: ReturnType<typeof useRelayEnvironment>,
    filters: ReturnType<typeof testResultToFilterObject>
): Promise<string[]> {
    return fetchQuery<TestResultListAllIdsQuery>(
        environment,
        graphql`
            query TestResultListAllIdsQuery($filters: TestResultFilter) {
                unplannedTests(filters: $filters) {
                    data {
                        id
                    }
                }
            }
        `,
        { filters }
    )
        .toPromise()
        .then(result => result?.unplannedTests.data.map(test => test.id) ?? []);
}

const SearchQuery = graphql`
    query TestResultListSearchQuery($search: String = "", $pageSize: Int!) {
        unplannedTests(search: $search, pageSize: $pageSize) {
            data {
                id
                device {
                    name
                }
                name
                cause
                commencedTime
            }
        }
    }
`;
