import React, { FC } from 'react';

import { FormArea, FormikTextField, FormikTextFieldWithSuggestion } from '@accesstel/pcm-ui';

import { useField } from 'formik';
import { getQualifiedName } from 'lib/namespaces';
import { SUGGESTIONS_LIMIT } from 'lib/provision';
import { SiteTypeSuggestion, useSuggestSiteType } from 'lib/suggestors';
import { Attributes } from 'views/manage/common/sub-forms/Attributes';

import manageStyle from '../../../style.module.css';

interface GeneralInformationProps {
    namespace?: string;
}

export const GeneralInformation: FC<GeneralInformationProps> = ({ namespace }) => {
    const [, typeField, typeFieldHelpers] = useField<string>(getQualifiedName('type', namespace));
    const [typeSuggestions] = useSuggestSiteType(typeField.value, SUGGESTIONS_LIMIT);

    return (
        <FormArea label='General Information' blurb='This is some general information that relates to the site.'>
            <div className={manageStyle.provisioning_content_grid}>
                <FormikTextField
                    name={getQualifiedName('name', namespace)}
                    required
                    placeHolder={'Site Name*'}
                    light
                    autoFocus
                    variant='outlined'
                    testId='site-name'
                />
                <div className='col-start-1'>
                    <FormikTextFieldWithSuggestion<SiteTypeSuggestion>
                        name={getQualifiedName('type', namespace)}
                        placeHolder={'Type*'}
                        required
                        light
                        renderSuggestions={(suggestion: SiteTypeSuggestion) =>
                            suggestion.isNew ? (
                                <div>
                                    {suggestion.displayName} <span className='font-light text-sm'>(New Type)</span>
                                </div>
                            ) : (
                                suggestion.displayName
                            )
                        }
                        suggestions={typeSuggestions?.map(suggestion => suggestion)}
                        onSuggestionClick={suggestion => {
                            typeFieldHelpers.setValue(suggestion.displayName);
                        }}
                        noResultsText={
                            <div>
                                {typeField.value} <span className='font-light text-sm'>(New Type)</span>
                            </div>
                        }
                        variant='outlined'
                        testId='site-type'
                    />
                </div>
                <div className={manageStyle.custom_attributes}>
                    <Attributes namespace={namespace} type='Site' />
                </div>
            </div>
        </FormArea>
    );
};
