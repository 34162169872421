import React, { FC, useCallback, useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { HighlightContext, SearchBox, createHighlightContext, useExtendedNavigate } from '@accesstel/pcm-ui';

import { logError } from 'lib/log';
import { Paths } from 'lib/routes';

import { queries_SearchQuery } from '../../__generated__/queries_SearchQuery.graphql';
import { searchBoxQuery } from '../../queries';
import { ReportSearchResults, SearchGroups, renderSearchResult } from '../../search';

export const ReportSearch: FC = () => {
    const [searchInput, setSearchInput] = useState<string>('');
    const [searchResults, setSearchResults] = useState<ReportSearchResults[]>();
    const [resultHighlightContext, setResultHighlightContext] = useState<HighlightContext | undefined>(undefined);
    const navigate = useExtendedNavigate();
    const environment = useRelayEnvironment();

    useEffect(() => {
        if (searchInput.length >= 2) {
            fetchQuery<queries_SearchQuery>(environment, searchBoxQuery, {
                search: searchInput,
                pageSize: 10,
            })
                .toPromise()
                .then(results => {
                    const context = createHighlightContext(searchInput);
                    setResultHighlightContext(context);

                    const deviceResults: ReportSearchResults[] =
                        results?.devices.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.site.id,
                            siteName: result.site.name,
                            state: result.site.address.state,
                            type: 'device',
                        })) ?? [];

                    const siteResults: ReportSearchResults[] =
                        results?.sites.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.id,
                            siteName: result.name,
                            state: result.address.state,
                            type: 'site',
                        })) ?? [];

                    setSearchResults(deviceResults.concat(siteResults));
                })
                .catch(error => {
                    logError('Failed to retrieve search results', error);
                    // TODO: Error state for suggestions box
                    setSearchResults([]);
                    setResultHighlightContext(undefined);
                });
        } else {
            setResultHighlightContext(undefined);
        }
    }, [searchInput, environment]);

    const doSearch = useCallback(() => {
        navigate({
            pathname: Paths.ReportBatterySiteList,
            search: {
                q: searchInput,
            },
        });
    }, [navigate, searchInput]);

    const doResultClick = useCallback(
        (item: ReportSearchResults) => {
            navigate({
                pathname: Paths.ReportBatteriesViewSite,
                params: { siteId: item.site },
                hash: item.type === 'device' ? item.id : undefined,
            });
        },
        [navigate]
    );

    return (
        <SearchBox
            autoFocus
            id='report-search'
            placeHolder='Search by Site/Device'
            value={searchInput}
            onChange={setSearchInput}
            renderResult={result => renderSearchResult(result, resultHighlightContext!)}
            searchResults={searchResults}
            moreResults={searchInput.length > 0}
            groups={SearchGroups}
            groupKey='type'
            onSubmit={doSearch}
            onResultClick={doResultClick}
        />
    );
};
