/**
 * @generated SignedSource<<34ded421fb083f6cfd51601f084a61a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurrentStatusDoughnut$data = {
  readonly batteryMetrics: {
    readonly statusBreakdown: ReadonlyArray<{
      readonly deviceCount: number;
      readonly status: BatteryStatus;
    }> | null;
  };
  readonly " $fragmentType": "CurrentStatusDoughnut";
};
export type CurrentStatusDoughnut$key = {
  readonly " $data"?: CurrentStatusDoughnut$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentStatusDoughnut">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentStatusDoughnut",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalBatteryMetrics",
      "kind": "LinkedField",
      "name": "batteryMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BatteryStatusBreakdown",
          "kind": "LinkedField",
          "name": "statusBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0ac71e201c9d137ba5bbbf7422f89dde";

export default node;
