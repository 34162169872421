import React, { FC, ReactElement, ReactNode } from 'react';

import {
    ArrowDownCircleIcon,
    ArrowRightCircleIcon,
    ArrowUpCircleIcon,
    CheckCircledOpenIcon,
    CircleAlertIcon,
    CircleCheckIcon,
    CircleCrossIcon,
    CircleDotsIcon,
    CircleHelpIcon,
    CircleMinusIcon,
    CirclePauseIcon,
    CirclePlayIcon,
    CircleStopIcon,
    Tooltip,
} from '@accesstel/pcm-ui';

import { IconWithStatus } from 'components';
import { ACPowerEventStatus } from 'views/reports/ac-power/device-incident-list/__generated__/ACPowerDeviceIncidentListTableQuery.graphql';
import { StatusCellValue } from 'views/tasks/battery-health/task-view/subviews/StatusList';
import {
    BatteryTaskState,
    BatteryTestState,
} from 'views/tasks/battery-health/task-view/subviews/__generated__/StatusList_task.graphql';

import { numberToLocaleString } from './numberFormatters';
import { DeviceHealth } from './tables/site/__generated__/requestSitesQuery.graphql';
import { getNiceFailReason, getNiceStatusName } from './textFormatters';

interface EmptyCellProps {
    text?: string;
}

/**
 *
 * @param text text to display in the empty cell
 * @default 'Not set'
 */
export const EmptyCell: FC<EmptyCellProps> = ({ text = 'Not set' }) => {
    return <div className='font-light italic text-eggplantRegular'>{text}</div>;
};

type ValueType = ReactNode | null | undefined;
type ValueCallback = () => ValueType;

export function renderUserConfigCell(valueOrFunction: ValueType | ValueCallback): ReactNode {
    let value: ValueType;

    if (typeof valueOrFunction === 'function') {
        value = valueOrFunction();
    } else {
        value = valueOrFunction;
    }

    if (!value) {
        return <EmptyCell />;
    }

    return value;
}

export function renderMetricCell(valueOrFunction: ValueType | ValueCallback): ReactNode {
    let value: ValueType;

    if (typeof valueOrFunction === 'function') {
        value = valueOrFunction();
    } else {
        value = valueOrFunction;
    }

    if (!value) {
        return <EmptyCell text='No value' />;
    }

    return value;
}

export function renderAlertDateCell(valueOrFunction: ValueType | ValueCallback): ReactNode {
    let value: ValueType;

    if (typeof valueOrFunction === 'function') {
        value = valueOrFunction();
    } else {
        value = valueOrFunction;
    }

    if (!value) {
        return <EmptyCell text='Active alert' />;
    }

    return value;
}

export function renderTableStatusCell(
    value: BatteryTaskState | BatteryTestState | 'Cancelled',
    test?: StatusCellValue,
    size: 'base' | 'lg' = 'base'
): ReactElement {
    switch (value) {
        case 'Aborted':
            return <IconWithStatus icon={<CircleStopIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'PartialFail':
        case 'Failed':
            return (
                <Tooltip content={test ? getNiceFailReason(test) : undefined}>
                    <div>
                        <IconWithStatus
                            icon={<CircleCrossIcon />}
                            label={getNiceStatusName(value)}
                            containerClasses='text-coralRegular'
                            variant={size}
                        />
                    </div>
                </Tooltip>
            );
        case 'InProgress':
            return <IconWithStatus icon={<CirclePlayIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'Inconclusive':
            return <IconWithStatus icon={<CircleHelpIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'PartialPass':
        case 'Passed':
            return (
                <IconWithStatus
                    icon={<CircleCheckIcon />}
                    label={getNiceStatusName(value)}
                    containerClasses='text-pineRegular'
                    variant={size}
                />
            );
        case 'Scheduled':
            return <IconWithStatus icon={<ArrowRightCircleIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'SmartStart':
            return <IconWithStatus icon={<CirclePauseIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'Analyzing':
        case 'Finalizing':
        case 'Waiting':
            return <IconWithStatus icon={<CircleMinusIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'Cancelled':
            return <IconWithStatus icon={<CircleMinusIcon />} label={getNiceStatusName(value)} variant={size} />;
        default:
            return <span>{getNiceStatusName(value)}</span>;
    }
}

export function renderDeviceHealthCell(value: DeviceHealth | 'Various', colorOverride?: string): ReactElement {
    switch (value) {
        case 'Healthy':
            return <IconWithStatus icon={<CheckCircledOpenIcon />} label={value} containerClasses='text-pineRegular' />;
        case 'Critical':
            return <IconWithStatus icon={<CircleAlertIcon />} label={value} containerClasses='text-coralRegular' />;
        case 'Degraded':
            return <IconWithStatus icon={<ArrowDownCircleIcon />} label={value} containerClasses='text-mauveRegular' />;
        case 'Offline':
            return <IconWithStatus icon={<CircleCrossIcon />} label={value} containerClasses='text-coralRegular' />;
        case 'Various':
            return (
                <IconWithStatus
                    icon={<CircleDotsIcon />}
                    label={value}
                    containerClasses={`${colorOverride} ?? 'text-eggplantRegular'`}
                />
            );
        default:
        case 'Unknown':
            return <IconWithStatus icon={<CircleHelpIcon />} label={value} containerClasses='text-eggplantRegular' />;
    }
}

export function renderDeviceIncidentStatusCell(
    value: ACPowerEventStatus | 'Various',
    colorOverride?: string
): ReactElement {
    switch (value) {
        case 'Outage':
            return <IconWithStatus icon={<CircleCrossIcon />} label='Outage' containerClasses='text-coralRegular' />;
        case 'UnderVoltage':
            return (
                <IconWithStatus
                    icon={<ArrowDownCircleIcon />}
                    label='Undervoltage'
                    containerClasses='text-mauveRegular'
                />
            );
        case 'OverVoltage':
            return (
                <IconWithStatus
                    icon={<ArrowUpCircleIcon />}
                    label='Overvoltage'
                    containerClasses='text-mustardRegular'
                />
            );
        case 'Various':
            return (
                <IconWithStatus
                    icon={<CircleDotsIcon />}
                    label={value}
                    containerClasses={`${colorOverride} ?? 'text-eggplantRegular'`}
                />
            );
        default:
        case '%future added value':
            return (
                <IconWithStatus icon={<CircleHelpIcon />} containerClasses='text-eggplantRegular' label={'Unknown'} />
            );
    }
}

export function renderFeedIncidentStatusCell(
    name: string,
    status: ACPowerEventStatus | 'Various',
    voltage: number | null
): ReactElement {
    if (voltage === null) {
        voltage = 0;
    }
    switch (status) {
        case 'Outage':
            return (
                <span className='w-8 h-6 text-center text-white bg-coralRegular m-1 px-2 rounded-lg'>
                    <Tooltip content={`${numberToLocaleString(voltage, 0)}V measured on Feed ${name}, Outage`}>
                        <span className='cursor'>F{name}</span>
                    </Tooltip>
                </span>
            );
        case 'UnderVoltage':
            return (
                <span className='w-8 h-6 text-center text-white bg-mauveRegular m-1 px-2 rounded-lg'>
                    <Tooltip content={`${numberToLocaleString(voltage, 0)}V measured on Feed ${name}, Undervoltage`}>
                        <span className='cursor'>F{name}</span>
                    </Tooltip>
                </span>
            );
        case 'OverVoltage':
            return (
                <span className='w-8 h-6 text-center text-white bg-mustardRegular m-1 px-2 rounded-lg'>
                    <Tooltip content={`${numberToLocaleString(voltage, 0)}V measured on Feed ${name}, Overvoltage`}>
                        <span className='cursor'>F{name}</span>
                    </Tooltip>
                </span>
            );
        case 'Various':
            return (
                <span className='w-8 h-6 text-center text-white bg-eggplantRegular m-1 px-2 rounded-lg'>
                    <Tooltip content={`${numberToLocaleString(voltage, 0)}V measured on Feed ${name}, ${status}`}>
                        <span className='cursor'>F{name}</span>
                    </Tooltip>
                </span>
            );
        case 'Normal':
            return (
                <span className='w-8 h-6 text-center text-white bg-pineRegular m-1 px-2 rounded-lg'>
                    <Tooltip content={`Feed ${name} online`}>
                        <span className='cursor'>F{name}</span>
                    </Tooltip>
                </span>
            );
        default:
        case '%future added value':
            return (
                <span className='w-8 h-6 text-white bg-pineRegular m-1 px-2 rounded-lg'>
                    <Tooltip content={` Unknown V measured on Feed Unknown, status Unknown`}>
                        <span className='cursor'>Unknown</span>
                    </Tooltip>
                </span>
            );
    }
}
