import { BatteryTypeErrorType } from 'views/manage/battery-type/add-edit/lib/__generated__/savingCreateBatteryTypeMutation.graphql';
import { SiteErrorType } from 'views/manage/site/add-edit/lib/__generated__/savingCreateSiteMutation.graphql';

import { formatBatteryType } from './textFormatters';

export const SUGGESTIONS_LIMIT = 6;

export enum SiteInfoField {
    Name = 'Name',
    Type = 'Type',
    Address = 'Address',
    State = 'State',
    Postcode = 'Postcode',
    Latitude = 'Latitude',
    Longitude = 'Longitude',
    Attributes = 'Attributes',
}

export enum BatteryTechnologyType {
    LeadAcidFlooded = 'LeadAcidFlooded',
    LeadAcidGel = 'LeadAcidGel',
    LeadAcidVrla = 'LeadAcidVRLA',
    LeadAcidAgm = 'LeadAcidAGM',
    LeadAcidOther = 'LeadAcidOther',
    LithiumIon = 'LithiumIon',
    LithiumPolymer = 'LithiumPolymer',
    LithiumIronPhosphate = 'LithiumIronPhosphate',
    NickelCadmium = 'NickelCadmium',
    NickelMetalHydride = 'NickelMetalHydride',
    Supercapacitor = 'Supercapacitor',
    LeadAcidTppl = 'LeadAcidTPPL',
    Other = 'Other',
}

export enum BatteryTypeInfoField {
    Manufacturer = 'Manufacturer',
    Model = 'Model',
    Technology = 'Technology',
    CellCapacity = 'CellCapacity',
    CellCapacityRating = 'CellCapacityRating',
    CellTemperature = 'CellTemperature',
    CellMaxVoltage = 'CellMaxVoltage',
    CellMinVoltage = 'CellMinVoltage',
    CellMinVoltageFloat = 'CellMinVoltageFloat',
    CellMaxVoltageFloat = 'CellMaxVoltageFloat',
    CellMinVoltageBoost = 'CellMinVoltageBoost',
    CellMaxVoltageBoost = 'CellMaxVoltageBoost',
    CellTypicalVoltage = 'CellTypicalVoltage',
    DischargeTable = 'DischargeTable',
    PeukertConstant = 'PeukertConstant',
    CellsPerBloc = 'CellsPerBloc',
    Attributes = 'Attributes',
}

export interface BatteryTechnologyTypeNameValue {
    name: string;
    value: BatteryTechnologyType;
}

export interface BatteryTypeProblem {
    table: number | null;
    row: number | null;
    type: BatteryTypeErrorType | string;
}

export const BatteryTechnologyTypeList: BatteryTechnologyTypeNameValue[] = [
    { name: formatBatteryType(BatteryTechnologyType.LeadAcidAgm), value: BatteryTechnologyType.LeadAcidAgm },
    { name: formatBatteryType(BatteryTechnologyType.LeadAcidFlooded), value: BatteryTechnologyType.LeadAcidFlooded },
    { name: formatBatteryType(BatteryTechnologyType.LeadAcidGel), value: BatteryTechnologyType.LeadAcidGel },
    { name: formatBatteryType(BatteryTechnologyType.LeadAcidOther), value: BatteryTechnologyType.LeadAcidOther },
    { name: formatBatteryType(BatteryTechnologyType.LeadAcidVrla), value: BatteryTechnologyType.LeadAcidVrla },
    {
        name: formatBatteryType(BatteryTechnologyType.LeadAcidTppl),
        value: BatteryTechnologyType.LeadAcidTppl,
    },
    { name: formatBatteryType(BatteryTechnologyType.LithiumIon), value: BatteryTechnologyType.LithiumIon },
    {
        name: formatBatteryType(BatteryTechnologyType.LithiumIronPhosphate),
        value: BatteryTechnologyType.LithiumIronPhosphate,
    },
    { name: formatBatteryType(BatteryTechnologyType.LithiumPolymer), value: BatteryTechnologyType.LithiumPolymer },
    { name: formatBatteryType(BatteryTechnologyType.NickelCadmium), value: BatteryTechnologyType.NickelCadmium },
    {
        name: formatBatteryType(BatteryTechnologyType.NickelMetalHydride),
        value: BatteryTechnologyType.NickelMetalHydride,
    },
    { name: formatBatteryType(BatteryTechnologyType.Supercapacitor), value: BatteryTechnologyType.Supercapacitor },
    { name: formatBatteryType(BatteryTechnologyType.Other), value: BatteryTechnologyType.Other },
];

export const StateList = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

export const describeSiteError = (errors: SiteErrorType[], field: SiteInfoField): string | undefined => {
    if (field === SiteInfoField.Name && errors.includes('DuplicateName')) {
        return 'There is already an existing site with this name';
    }

    if (field === SiteInfoField.Name && errors.includes('MissingName')) {
        return 'Site name is a required field';
    }

    if (field === SiteInfoField.State && errors.includes('MissingState')) {
        return 'Site state is a required field';
    }

    if (field === SiteInfoField.Longitude && errors.includes('InvalidLongitude')) {
        return 'Invalid longitude value or format';
    }

    if (field === SiteInfoField.Latitude && errors.includes('InvalidLatitude')) {
        return 'Invalid latitude value or format';
    }

    if (field === SiteInfoField.Postcode && errors.includes('InvalidPostcode')) {
        return 'Invalid postcode';
    }

    return undefined;
};

export const describeBatteryTypeError = (
    batteryErrors: BatteryTypeProblem[],
    field: BatteryTypeInfoField
): string | undefined => {
    const errors = batteryErrors.map(({ type }) => type);

    if (field === BatteryTypeInfoField.Manufacturer && errors.includes('manufacturer')) {
        return 'Manufacturer cannot be empty';
    }

    if (field === BatteryTypeInfoField.Model && errors.includes('model')) {
        return 'Model cannot be empty';
    }

    if (field === BatteryTypeInfoField.Technology && errors.includes('technology')) {
        return 'Battery technology cannot be empty';
    }

    if (field === BatteryTypeInfoField.CellCapacity && errors.includes('cellCapacity')) {
        return 'Cell capacity cannot be empty';
    }

    if (field === BatteryTypeInfoField.CellCapacityRating && errors.includes('cellCapacityRating')) {
        return 'Cell capacity rating cannot be empty';
    }

    if (field === BatteryTypeInfoField.CellTemperature && errors.includes('cellTemperature')) {
        return 'Cell reference temperature cannot be empty';
    }

    if (field === BatteryTypeInfoField.CellMinVoltage && errors.includes('cellMinVoltage')) {
        return 'This value must be less than the maximum voltage';
    }

    if (field === BatteryTypeInfoField.CellMaxVoltage && errors.includes('cellMaxVoltage')) {
        return 'This value must be greater than the minimum voltage';
    }

    if (field === BatteryTypeInfoField.CellTypicalVoltage && errors.includes('cellTypicalVoltage')) {
        return 'This value must be within the minimum and maximum range';
    }

    if (field === BatteryTypeInfoField.CellMinVoltageFloat && errors.includes('cellMinVoltageFloat')) {
        return 'This value must be within the minimum and maximum range';
    }

    if (field === BatteryTypeInfoField.CellMaxVoltageFloat && errors.includes('cellMaxVoltageFloat')) {
        return 'This value must be within the minimum and maximum range';
    }

    if (field === BatteryTypeInfoField.CellMinVoltageBoost && errors.includes('cellMinVoltageBoost')) {
        return 'This value must be within the minimum and maximum range';
    }

    if (field === BatteryTypeInfoField.CellMaxVoltageBoost && errors.includes('cellMaxVoltageBoost')) {
        return 'This value must be within the minimum and maximum range';
    }

    if (field === BatteryTypeInfoField.DischargeTable && errors.includes('dischargeTable')) {
        return 'The end voltage must be specified';
    }

    if (field === BatteryTypeInfoField.Model && errors.includes('DuplicateModelName')) {
        return 'There is already an existing battery model with this name';
    }

    if (field === BatteryTypeInfoField.CellCapacity && errors.includes('InvalidCellCapacity')) {
        return 'Invalid Cell Capacity';
    }

    if (field === BatteryTypeInfoField.CellCapacityRating && errors.includes('InvalidCellCapacityRating')) {
        return 'Invalid Cell Capacity Rating';
    }

    if (field === BatteryTypeInfoField.CellMaxVoltage && errors.includes('InvalidCellMaximumAllowedVoltage')) {
        return 'Invalid Cell Maximum Allowed Voltage';
    }

    if (field === BatteryTypeInfoField.CellMinVoltage && errors.includes('InvalidCellMinimumAllowedVoltage')) {
        return 'Invalid Cell Minimum Allowed Voltage';
    }

    if (field === BatteryTypeInfoField.CellMaxVoltageFloat && errors.includes('InvalidCellMaximumFloatVoltage')) {
        return 'Invalid Cell Maximum Float Voltage';
    }

    if (field === BatteryTypeInfoField.CellMinVoltageFloat && errors.includes('InvalidCellMinimumFloatVoltage')) {
        return 'Invalid Cell Minimum Float Voltage';
    }

    if (field === BatteryTypeInfoField.CellMaxVoltageBoost && errors.includes('InvalidCellMaximumBoostVoltage')) {
        return 'Invalid Cell Maximum Boost Voltage';
    }

    if (field === BatteryTypeInfoField.CellMinVoltageBoost && errors.includes('InvalidCellMinimumBoostVoltage')) {
        return 'Invalid Cell Minimum Boost Voltage';
    }

    if (field === BatteryTypeInfoField.CellTemperature && errors.includes('InvalidCellReferenceTemperature')) {
        return 'Invalid Cell Reference Temperature';
    }

    if (field === BatteryTypeInfoField.CellTypicalVoltage && errors.includes('InvalidCellTypicalVoltage')) {
        return 'Invalid Cell Typical Voltage';
    }

    if (field === BatteryTypeInfoField.PeukertConstant && errors.includes('InvalidPeukertsConstant')) {
        return "Invalid Peukert's constant";
    }

    if (field === BatteryTypeInfoField.CellsPerBloc && errors.includes('InvalidCellsPerBloc')) {
        return 'Invalid Cells per Bloc';
    }

    if (field === BatteryTypeInfoField.DischargeTable && errors.includes('InvalidTableDischargeTime')) {
        return 'Invalid Table Discharge Time';
    }

    if (field === BatteryTypeInfoField.DischargeTable && errors.includes('InvalidTableDischargeValue')) {
        return 'Invalid Table Discharge Value';
    }

    if (field === BatteryTypeInfoField.DischargeTable && errors.includes('InvalidTableEodv')) {
        return 'Invalid Table End of Discharge Voltage';
    }

    return undefined;
};
