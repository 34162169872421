import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import { createColumnHelper } from '@tanstack/react-table';
import graphql from 'babel-plugin-relay/macro';
import { numberToLocaleString } from 'lib/numberFormatters';
import { formatValueWithString, formatValueWithUnit } from 'lib/units';

import { BatteryTypeTableColumn, BatteryTypeTableColumnId } from '../../../filters/battery-type';
import { ColumnWithId } from '../../../layouts';
import { EmptyCell, renderUserConfigCell } from '../../table-columns';
import { formatBatteryType } from '../../textFormatters';
import { columns_batteryTypeAttributeColumnsQuery } from './__generated__/columns_batteryTypeAttributeColumnsQuery.graphql';
import { BatteryType } from './type';

const columnHelper = createColumnHelper<BatteryType>();

const ColumnDefinitions = [
    columnHelper.accessor('manufacturer', {
        id: BatteryTypeTableColumn.Manufacturer,
        header: 'Manufacturer',
        exportHeader: 'Manufacturer',
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '14rem',
        },
    }),
    columnHelper.accessor('model', {
        id: BatteryTypeTableColumn.Model,
        header: 'Model',
        exportHeader: 'Model',
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '14rem',
        },
    }),
    columnHelper.accessor('technology', {
        id: BatteryTypeTableColumn.Technology,
        header: 'Technology',
        exportHeader: 'Technology',
        cell: ({ cell }) => formatBatteryType(cell.getValue()),
        exportValue: ({ value }) => formatBatteryType(value),
        meta: {
            filterable: true,
            sortable: true,
        },
    }),
    columnHelper.accessor('cells.capacity', {
        id: BatteryTypeTableColumn.Capacity,
        header: 'Capacity',
        exportHeader: 'Capacity (Ah)',
        cell: ({ cell }) => formatValueWithString(cell.getValue(), 'Ah'),
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '11rem',
        },
    }),
    columnHelper.accessor('cells.capacityRating', {
        id: BatteryTypeTableColumn.CellCapacityRating,
        header: 'Capacity Rating',
        exportHeader: 'Capacity Rating (hours)',
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('cells.referenceTemperature', {
        id: BatteryTypeTableColumn.CellReferenceTemperature,
        header: 'Reference Temperature',
        exportHeader: 'Reference Temperature',
        cell: ({ getValue, table }) => {
            const value = getValue();

            if (value == null) {
                return <EmptyCell />;
            }

            return formatValueWithUnit(numberToLocaleString(value, 0), table.options.meta?.units?.temperature);
        },
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('cellsPerBloc', {
        id: BatteryTypeTableColumn.CellsPerBloc,
        header: 'Cells Per Bloc',
        exportHeader: 'Cells per bloc',
        cell: ({ cell }) => renderUserConfigCell(() => cell.getValue()),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('peukertsConstant', {
        id: BatteryTypeTableColumn.PeukertsConstant,
        header: 'Peukerts Constant',
        exportHeader: "Peukert's constant",
        cell: ({ cell }) => renderUserConfigCell(() => cell.getValue()),
        meta: {
            filterable: true,
        },
    }),
];

export interface SiteColumnsOptions {
    /**
     * If provided, the available columns will be limited to the provided list.
     */
    columns?: BatteryTypeTableColumnId[];

    /**
     * If true, the custom attributes will not be available as columns.
     */
    disableAttributeColumns?: boolean;
}

const DefaultTableColumns = [
    BatteryTypeTableColumn.Manufacturer,
    BatteryTypeTableColumn.Model,
    BatteryTypeTableColumn.Technology,
    BatteryTypeTableColumn.Capacity,
    BatteryTypeTableColumn.CellCapacityRating,
    BatteryTypeTableColumn.CellReferenceTemperature,
    BatteryTypeTableColumn.CellsPerBloc,
    BatteryTypeTableColumn.PeukertsConstant,
];

export function useBatteryTypeColumnDefinitions(
    options: SiteColumnsOptions = {}
): ColumnWithId<BatteryTypeTableColumnId, BatteryType>[] {
    const attributeColumns = useLazyLoadQuery<columns_batteryTypeAttributeColumnsQuery>(
        graphql`
            query columns_batteryTypeAttributeColumnsQuery($disableAttributeColumns: Boolean!) {
                attributeNames(search: "", only: Battery, limit: 1000, status: Active)
                    @skip(if: $disableAttributeColumns)
            }
        `,
        {
            disableAttributeColumns: options.disableAttributeColumns ?? false,
        },
        {
            fetchPolicy: 'store-or-network',
        }
    );

    let attributeColumnDefinitions: ColumnWithId<BatteryTypeTableColumnId, BatteryType>[] = [];

    if (!options.disableAttributeColumns && attributeColumns.attributeNames) {
        attributeColumnDefinitions = attributeColumns.attributeNames.map(name => {
            return columnHelper.display({
                id: `tag_${name}`,
                header: `Tag ${name}`,
                exportHeader: `Tag ${name}`,
                cell: ({ row }) => {
                    const batteryType = row.original;
                    return batteryType.attributes?.find(attribute => attribute.name === name)?.value;
                },
                meta: {
                    filterable: true,
                    sortable: false,
                },
            });
        }) as ColumnWithId<BatteryTypeTableColumnId, BatteryType>[];
    }

    const availableColumnIds = options.columns ?? DefaultTableColumns;

    const columns = availableColumnIds.map(columnId => {
        const column = ColumnDefinitions.find(column => column.id === columnId);

        console.assert(column, `No column definition found for column ID ${columnId}`);

        return column;
    }) as ColumnWithId<BatteryTypeTableColumnId, BatteryType>[];

    return columns.concat(attributeColumnDefinitions);
}
