import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { ActiveDevicesCount$key } from './__generated__/ActiveDevicesCount.graphql';

interface ActiveDevicesCountProps {
    valueSuffix: string;

    fragmentRef: ActiveDevicesCount$key;
}

export const ActiveDevicesCount: FC<ActiveDevicesCountProps> = ({ valueSuffix, fragmentRef }) => {
    const result = useFragment(Fragment, fragmentRef);
    const count = result.devices.total;

    return <PageHeading value={`${count.toLocaleString()} ${valueSuffix}`} subheading secondary />;
};

const Fragment = graphql`
    fragment ActiveDevicesCount on Query {
        devices {
            total
        }
    }
`;
