import React, { FC } from 'react';

import { ListView, LoadingMark } from '@accesstel/pcm-ui';

/**
 * This differs from a skeleton in that it is a loading indicator, not a placeholder
 */
export const LoadHistoryTilesLoading: FC = () => {
    return (
        <>
            <ListView title='Last 6 months' view='grid'>
                {/* This keeps the size consistent with its normal size */}
                <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                <div className='col-span-4 grid place-items-center'>
                    <div className='flex flex-row gap-2 items-center'>
                        <span>Loading</span>
                        <div className='w-4'>
                            <LoadingMark />
                        </div>
                    </div>
                </div>
                <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
            </ListView>
            <ListView title='Lifetime' view='grid'>
                {/* This keeps the size consistent with its normal size */}
                <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                <div className='col-span-4 grid place-items-center'>
                    <div className='flex flex-row gap-2 items-center'>
                        <span>Loading</span>
                        <div className='w-4'>
                            <LoadingMark />
                        </div>
                    </div>
                </div>
                <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
            </ListView>
        </>
    );
};
