import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData } from 'react-router-dom';

import { ACSiteReportLayoutContent } from './ACSiteReportLayoutContent';
import { ACSiteReportLayoutSkeleton } from './ACSiteReportLayoutSkeleton';
import { ACSiteReportLayoutContentQuery } from './__generated__/ACSiteReportLayoutContentQuery.graphql';

export const ACSiteReportLayout: FC = () => {
    const layoutQuery = useLoaderData() as PreloadedQuery<ACSiteReportLayoutContentQuery>;

    return (
        <>
            <Suspense fallback={<ACSiteReportLayoutSkeleton />}>
                <ACSiteReportLayoutContent preloadedQuery={layoutQuery} />
            </Suspense>
        </>
    );
};
