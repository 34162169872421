import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { RepeatIcon, UserCrossIcon, UserIcon2 } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { renderTableStatusCell } from 'lib/table-columns';
import { CardDateDisplay } from 'views/tasks/components';

import { TaskProgressBar } from '../../../components/TaskProgressBar';
import { SmallerLabelWithIcon } from '../SmallerLabelWithIcon';
import { CardLocationDisplay } from './CardLocationDisplay';
import { BatteryTestCardContent_task$key } from './__generated__/BatteryTestCardContent_task.graphql';

interface BatteryTestCardContentProps {
    task: BatteryTestCardContent_task$key;
    compact?: boolean;
    forceStatusDisplay?: boolean;
}

export const BatteryTestCardContent: FC<BatteryTestCardContentProps> = ({ task, compact, forceStatusDisplay }) => {
    const result = useFragment<BatteryTestCardContent_task$key>(CardContentFragment, task);

    const { name, overallState, testState, creator, devices, cancelledUser, abortedUser, schedule } = result;

    let startTime: Date;
    let endTime: Date | undefined;
    if (overallState === 'Scheduled') {
        startTime = new Date(schedule!.time);
    } else {
        startTime = new Date(result.commencedTime ?? result.createdTime);
        if (result.completedTime) {
            endTime = new Date(result.completedTime);
        }
    }

    const username = creator.name && creator.name.trim().length !== 0 ? creator.name : creator.email!;
    let userDescription: string;
    if (overallState === 'Scheduled') {
        userDescription = `Scheduled by ${username}`;
    } else {
        userDescription = `Initiated by ${username}`;
    }

    const wasCancelledOrAborted = overallState === 'Cancelled' || testState === 'Aborted';

    let stoppedByText: string;

    if (overallState === 'Completed') {
        stoppedByText = 'Aborted By';
    } else {
        stoppedByText = 'Cancelled By';
    }

    const user = abortedUser ?? cancelledUser;
    let stoppedUser: string;
    if (user) {
        stoppedUser = user.name && user.name.trim().length !== 0 ? user.name : user.email!;
    } else {
        stoppedUser = 'Unknown';
    }

    let repeatText: string;
    if (schedule && schedule.repeat !== 'Never') {
        repeatText = `Repeats ${schedule.repeat.toLocaleLowerCase()}`;
    } else {
        repeatText = 'Does not repeat';
    }

    const shouldDisplayProgressBar = overallState === 'Completed' || overallState === 'InProgress';

    return (
        <>
            {(!compact || forceStatusDisplay) && (
                <div className='mb-4'>
                    {renderTableStatusCell(
                        result.overallState === 'Cancelled' ? 'Cancelled' : result.testState!,
                        undefined,
                        'lg'
                    )}
                </div>
            )}
            <div className={classNames('text-eggplantRegular', { 'text-lg': !compact, 'text-base': compact })}>
                {name}
            </div>
            <CardLocationDisplay devices={devices.data} />

            <div className='mt-4 space-y-2'>
                <CardDateDisplay startTime={startTime} endTime={endTime} future={result.overallState === 'Scheduled'} />
                <SmallerLabelWithIcon icon={<RepeatIcon />} label={repeatText} />
            </div>
            {!compact && (
                <>
                    <div className='mt-4 flex flex-row gap-2 justify-between'>
                        <SmallerLabelWithIcon icon={<UserIcon2 />} label={username} title={userDescription} />
                        {wasCancelledOrAborted && (
                            <SmallerLabelWithIcon
                                icon={<UserCrossIcon />}
                                label={stoppedUser}
                                title={`${stoppedByText} ${stoppedUser}`}
                            />
                        )}
                    </div>
                    {shouldDisplayProgressBar && (
                        <div className='mt-8'>
                            <TaskProgressBar task={result} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

const CardContentFragment = graphql`
    fragment BatteryTestCardContent_task on Task {
        ... on BatteryTest {
            type
            testState
            abortedUser {
                email
                name
            }
            schedule {
                time
                repeat
            }
            ...TaskProgressBar_task
        }
        name
        devices {
            total
            data {
                site {
                    name
                }
            }
        }
        creator {
            name
            email
        }
        overallState
        createdTime
        commencedTime
        completedTime

        cancelledTime
        cancelledUser {
            email
            name
        }
    }
`;
