import React, { FC } from 'react';

interface TitleWithSubtitleProps {
    title: string;
    subtitle: string;
}

export const TitleWithSubtitle: FC<TitleWithSubtitleProps> = ({ title, subtitle }) => {
    return (
        <div>
            <span className='font-bold text-lg'>{title}</span>
            <span className='font-normal text-xs'> {subtitle}</span>
        </div>
    );
};
