/**
 * @generated SignedSource<<d80562b120c626df916b99fd656e6177>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GeneratorRunReportCause = "LocalOtherStart" | "PowerFailure" | "RemoteStart" | "Unknown" | "%future added value";
export type GeneratorRunReportState = "Analysing" | "Completed" | "Error" | "Finishing" | "Running" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PreviousRuns_data$data = {
  readonly previousReports: {
    readonly data: ReadonlyArray<{
      readonly cause: GeneratorRunReportCause;
      readonly generator: {
        readonly id: string;
        readonly name: string;
        readonly site: {
          readonly address: {
            readonly state: string;
          };
          readonly id: string;
          readonly name: string;
        };
      };
      readonly generatorStartTime: string;
      readonly generatorStopTime: string | null;
      readonly id: string;
      readonly name: string | null;
      readonly state: GeneratorRunReportState;
    }>;
  };
  readonly " $fragmentType": "PreviousRuns_data";
};
export type PreviousRuns_data$key = {
  readonly " $data"?: PreviousRuns_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PreviousRuns_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PreviousRuns_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "pageSize",
          "value": 3
        }
      ],
      "concreteType": "PaginatedGeneratorRunReport",
      "kind": "LinkedField",
      "name": "previousReports",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GeneratorRunReport",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cause",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Device",
              "kind": "LinkedField",
              "name": "generator",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Site",
                  "kind": "LinkedField",
                  "name": "site",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Address",
                      "kind": "LinkedField",
                      "name": "address",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "generatorStartTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "generatorStopTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "previousReports(pageSize:3)"
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};
})();

(node as any).hash = "4cfe358b0afaba824fa1da1b23a1057e";

export default node;
