import React, { FC, ReactNode, useCallback, useState } from 'react';

import { ArrowLeftIcon, Calendar, FilterContainer, TimeBase, TimeRangeFilter } from '@accesstel/pcm-ui';

import { DateTime } from 'luxon';

import { PredefinedSelections } from './PredefinedSelections';
import { DateRangeFilter, DefaultPresetRanges, PredefinedRange } from './common';

enum Mode {
    ChooseType,
    Duration,
    Dates,
}

const DefaultTimeRangeValue = 1;
const DefaultTimeRangeUnit: TimeBase = 'days';

export interface FilterDateSelectProps {
    title: string;
    current: DateRangeFilter | null;
    onClear: () => void;
    onSubmit?: (range: DateRangeFilter) => void;
    onClose?: () => void;
    showBack?: boolean;
    presets?: PredefinedRange[];
    defaultTimeValue?: number;
    defaultTimeUnit?: TimeBase;
    preventClear?: boolean;
}

export const FilterDateSelect: FC<FilterDateSelectProps> = ({
    title,
    current,
    onClear,
    onSubmit,
    onClose,
    showBack,
    presets = DefaultPresetRanges,
    defaultTimeValue = DefaultTimeRangeValue,
    defaultTimeUnit = DefaultTimeRangeUnit,
    preventClear,
}) => {
    const [range, setRange] = useState<DateRangeFilter | null>(current);
    const [mode, setMode] = useState<Mode>(() => {
        if (current) {
            switch (current.type) {
                case 'predefined':
                    return Mode.ChooseType;
                case 'duration':
                    return Mode.Duration;
                case 'custom':
                    return Mode.Dates;
            }
        }
        return Mode.ChooseType;
    });

    const handlePredefinedRange = useCallback((newRange: PredefinedRange) => {
        setRange({
            type: 'predefined',
            preset: newRange.title,
            range: newRange.asDateRange(),
        });
    }, []);

    const handleTimeRangeChange = useCallback((value: number, unit: TimeBase) => {
        setRange({
            type: 'duration',
            value,
            unit,
            range: [
                DateTime.now()
                    .minus({ [unit]: value })
                    .toJSDate(),
                new Date(),
            ],
        });
    }, []);

    const handleCalendarChange = useCallback((value: Date | [Date, Date]) => {
        if (!Array.isArray(value)) {
            // This is invalid. The component will only send an array back because we have selectRange on
            return;
        }

        setRange({
            type: 'custom',
            range: value,
        });
    }, []);

    const handleConfirmClick = useCallback(() => {
        if (!onSubmit || !range) {
            return;
        }

        onSubmit(range);
    }, [onSubmit, range]);

    const handleClearClick = useCallback(() => {
        if (mode !== Mode.ChooseType) {
            setMode(Mode.ChooseType);
            return;
        }
        onClear();
    }, [mode, onClear]);

    let customClearButton: ReactNode;
    if ((showBack && current === null) || mode !== Mode.ChooseType) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-coralRegular'>
                <ArrowLeftIcon />
            </div>
        );
    }

    let content: ReactNode;
    switch (mode) {
        case Mode.ChooseType: {
            let selected: PredefinedRange | null = null;
            if (range && range.type === 'predefined') {
                selected = presets.find(preset => preset.title === range.preset) ?? null;
            }

            content = (
                <PredefinedSelections
                    ranges={presets}
                    selected={selected}
                    onSelect={handlePredefinedRange}
                    onCustomDurationClick={() => {
                        setMode(Mode.Duration);
                        if (!range) {
                            // set this up so that you can just accept right away since there is a default value
                            handleTimeRangeChange(defaultTimeValue, defaultTimeUnit);
                        }
                    }}
                    onCustomDateClick={() => setMode(Mode.Dates)}
                />
            );
            break;
        }
        case Mode.Duration: {
            let lastTimeValue = defaultTimeValue;
            let lastTimeUnit = defaultTimeUnit;

            if (range && range.type === 'duration') {
                lastTimeValue = range.value;
                lastTimeUnit = range.unit;
            }
            content = (
                <TimeRangeFilter value={lastTimeValue} unit={lastTimeUnit} onChange={handleTimeRangeChange} autoFocus />
            );
            break;
        }
        case Mode.Dates: {
            content = (
                <Calendar
                    onChange={handleCalendarChange}
                    value={range?.range ?? null}
                    selectRange
                    variant='range'
                    size='stretch'
                />
            );
            break;
        }
    }

    return (
        <FilterContainer
            title={title}
            customButton={customClearButton}
            onClearClick={handleClearClick}
            onConfirmClick={handleConfirmClick}
            onClose={onClose}
            primaryContent={content}
            hideClearButton={(preventClear && !customClearButton && !showBack) || (!range && !showBack)}
            hideConfirmButton={!range && !current}
        />
    );
};
