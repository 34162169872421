import React, { FC, ReactNode } from 'react';

import { CircleAlertIcon, CircleCheckIcon, CircleHelpIcon, CircleMinusIcon, Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';

export type StatusType = 'good' | 'warning' | 'bad' | 'neutral' | 'unknown';

export const StatusColorMap: Record<StatusType, string> = {
    good: 'text-pineRegular',
    warning: 'text-mustardRegular',
    bad: 'text-coralRegular',
    neutral: 'text-eggplantRegular',
    unknown: 'text-eggplantRegular',
};

const StatusIconMap: Record<StatusType, ReactNode> = {
    good: <CircleCheckIcon />,
    warning: <CircleAlertIcon />,
    bad: <CircleAlertIcon />,
    neutral: <CircleMinusIcon />,
    unknown: <CircleHelpIcon />,
};

export interface StatusLabelProps {
    title: string;
    titleTooltip?: string;
    content: ReactNode;
    status: StatusType;
    secondary?: string;
}

export const StatusLabel: FC<StatusLabelProps> = ({ title, content, status, secondary, titleTooltip }) => {
    return (
        <div className='flex flex-col items-start font-normal'>
            <Tooltip content={titleTooltip}>
                <div className='text-sm text-eggplantLight cursor-help'>{title}</div>
            </Tooltip>
            <div className={classNames(StatusColorMap[status], 'flex text-2xl space-x-2 items-start py-1')}>
                <div className='h-9 w-9'>{StatusIconMap[status]}</div>
                <div>
                    <div>{content}</div>
                    {secondary && (
                        <Tooltip content={secondary}>
                            <div className='text-xs text-eggplantLight'>{secondary}</div>
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
};
