/**
 * @generated SignedSource<<b3cdac26d015fcbeed0555c578090c27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnitSettingsReloadQuery$variables = Record<PropertyKey, never>;
export type UnitSettingsReloadQuery$data = {
  readonly currentUser: {
    readonly preferences: {
      readonly " $fragmentSpreads": FragmentRefs<"UnitSettingsFragment">;
    };
  };
};
export type UnitSettingsReloadQuery = {
  response: UnitSettingsReloadQuery$data;
  variables: UnitSettingsReloadQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitSettingsReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UnitSettingsFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnitSettingsReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserUnitPreferences",
                "kind": "LinkedField",
                "name": "units",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "temperature",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pressure",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "volume",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "volumetricFlowRate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dfc29ba4b37eac4444443e96f9ddecc",
    "id": null,
    "metadata": {},
    "name": "UnitSettingsReloadQuery",
    "operationKind": "query",
    "text": "query UnitSettingsReloadQuery {\n  currentUser {\n    preferences {\n      ...UnitSettingsFragment\n    }\n    id\n  }\n}\n\nfragment UnitSettingsFragment on UserPreferences {\n  units {\n    temperature\n    pressure\n    volume\n    volumetricFlowRate\n  }\n}\n"
  }
};

(node as any).hash = "0052008cd2f6f84952c8e30c8c63f72a";

export default node;
