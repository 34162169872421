import React, { FC } from 'react';

import { FilterSingleSelect } from 'components/FilterSingleSelect';
import { CommonFilterProps } from 'filters/common';
import { intervalToHuman } from 'views/reports/ac-power/common';
import { DefaultTimeRanges, TimeRange } from 'views/reports/ac-power/settings';

export type ACPowerReliabilityTimeRangeFilterProps = CommonFilterProps<TimeRange | null>;

export const ACPowerReliabilityTimeRangeFilterUI: FC<ACPowerReliabilityTimeRangeFilterProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    const options: TimeRange[] = DefaultTimeRanges;

    const renderItem = (item: TimeRange) => intervalToHuman(item, { prefix: 'Last ' });

    return (
        <FilterSingleSelect
            options={options}
            active={current ?? undefined}
            title='Filter by AC Reliability Time Range'
            renderItem={renderItem}
            onClear={onClearOrBack}
            onSubmit={item => onSubmit(item)}
            onClose={onClose}
            showBack={true}
        />
    );
};
