/**
 * @generated SignedSource<<49a7aadf892ff0362b02207ec5f4e372>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteErrorType = "DuplicateName" | "EditNotAllowed" | "InvalidAddress" | "InvalidAttributeName" | "InvalidLatitude" | "InvalidLongitude" | "InvalidPostcode" | "InvalidSite" | "InvalidState" | "MissingAddress" | "MissingName" | "MissingState" | "MissingType" | "Noop" | "Skipped" | "%future added value";
export type SiteIn = {
  address: AddressIn;
  attributes?: ReadonlyArray<AttributeIn> | null;
  name: string;
  type: string;
};
export type AddressIn = {
  address: string;
  coordinates?: GpsCoordinatesIn | null;
  postcode?: string | null;
  state: string;
};
export type GpsCoordinatesIn = {
  latitude: string;
  longitude: string;
};
export type AttributeIn = {
  name: string;
  value: string;
};
export type savingCreateSiteMutation$variables = {
  site: SiteIn;
};
export type savingCreateSiteMutation$data = {
  readonly addSite: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<SiteErrorType>;
  } | null;
};
export type savingCreateSiteMutation = {
  response: savingCreateSiteMutation$data;
  variables: savingCreateSiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "site"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "site",
    "variableName": "site"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problems",
      "storageKey": null
    }
  ],
  "type": "SiteProblem",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "savingCreateSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addSite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "savingCreateSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dfa7276a69a02aa64aca2135829fb7b0",
    "id": null,
    "metadata": {},
    "name": "savingCreateSiteMutation",
    "operationKind": "mutation",
    "text": "mutation savingCreateSiteMutation(\n  $site: SiteIn!\n) {\n  addSite(site: $site) {\n    __typename\n    ... on Site {\n      id\n    }\n    ... on SiteProblem {\n      problems\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7506d6a1562dcd7bdb16f30af7ed380b";

export default node;
