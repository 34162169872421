import React, { FC, useMemo } from 'react';
import { useFragment } from 'react-relay';

import { LineChartSeries, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { NBSP } from 'constants/';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { numberToLocaleString } from 'lib/numberFormatters';
import { formatValueWithUnit, getUserPrefBaseUnit } from 'lib/units';
import { Duration } from 'luxon';

import { Events } from './Events';
import { FuelStats } from './FuelStats';
import { LineChartWithMetrics } from './LineChartWithMetrics';
import { LoadChart } from './LoadChart';
import { MetricsView } from './MetricsView';
import { VitalsTable } from './VitalsTable';
import {
    RunReportResultDisplay_data$data,
    RunReportResultDisplay_data$key,
} from './__generated__/RunReportResultDisplay_data.graphql';

export interface RunReportResultDisplayProps {
    runReport: RunReportResultDisplay_data$key;
}

export const RunReportResultDisplay: FC<RunReportResultDisplayProps> = ({ runReport }) => {
    const unitPreferences = useCurrentUserUnitsPref();
    const data = useFragment<RunReportResultDisplay_data$key>(RunReportResultDisplayFragment, runReport);

    const processedFuelConsumptionData = useMemo(
        () => processChartData(data.fuelConsumptionRateOverTime),
        [data.fuelConsumptionRateOverTime]
    );
    const fuelConsumptionSeries: LineChartSeries<Duration>[] = [
        {
            name: 'Fuel consumption rate',
            data: processedFuelConsumptionData,
            color: Theme.coralRegular,
        },
    ];

    const processedFuelLevelData = useMemo(() => processChartData(data.fuelLevelOverTime), [data.fuelLevelOverTime]);
    const fuelLevelSeries: LineChartSeries<Duration>[] = [
        {
            name: 'Fuel level',
            data: processedFuelLevelData,
            color: Theme.coralRegular,
        },
    ];

    return (
        <>
            <MetricsView runReport={data} />
            <LoadChart runReport={data} />
            <VitalsTable runReport={data} />
            <FuelStats runReport={data} />
            <LineChartWithMetrics
                title='Consumption rate'
                series={fuelConsumptionSeries}
                unit={`${NBSP}${getUserPrefBaseUnit(unitPreferences.volumetricFlowRate)}`}
                data={{
                    average: formatValueWithUnit(
                        numberToLocaleString(data.fuelConsumptionRate?.average ?? null, 1),
                        unitPreferences.volumetricFlowRate
                    ),
                    min: formatValueWithUnit(
                        numberToLocaleString(data.fuelConsumptionRate?.min ?? null, 1),
                        unitPreferences.volumetricFlowRate
                    ),
                    max: formatValueWithUnit(
                        numberToLocaleString(data.fuelConsumptionRate?.max ?? null, 1),
                        unitPreferences.volumetricFlowRate
                    ),
                    latest: formatValueWithUnit(
                        numberToLocaleString(data.fuelConsumptionRate?.latest ?? null, 1),
                        unitPreferences.volumetricFlowRate
                    ),
                }}
                type='consumption'
                state={data.state}
            />
            <LineChartWithMetrics
                title='Fuel level'
                series={fuelLevelSeries}
                unit='%'
                data={{
                    average: `${numberToLocaleString(data.fuelLevel?.average ?? null, 1)}%`,
                    min: `${numberToLocaleString(data.fuelLevel?.min ?? null, 1)}%`,
                    max: `${numberToLocaleString(data.fuelLevel?.max ?? null, 1)}%`,
                    latest: `${numberToLocaleString(data.fuelLevel?.latest ?? null, 1)}%`,
                }}
                yDomain={[0, 100]}
                type='fuelLevel'
                state={data.state}
            />
            <Events runReport={data} />
        </>
    );
};

const RunReportResultDisplayFragment = graphql`
    fragment RunReportResultDisplay_data on GeneratorRunReport
    @argumentDefinitions(
        chartPoints: { type: "Int" }
        unitVolume: { type: "UnitVolume!" }
        unitTemperature: { type: "UnitTemperature" }
        unitPressure: { type: "UnitPressure" }
        unitFlowRate: { type: "UnitFlowRate" }
    ) {
        id
        state
        fuelConsumptionRate(unit: $unitFlowRate) {
            min
            max
            average
            latest
        }
        fuelConsumptionRateOverTime(points: $chartPoints, unit: $unitFlowRate) {
            offset
            value
        }
        fuelLevel {
            min
            max
            average
            latest
        }
        fuelLevelOverTime(points: $chartPoints) {
            offset
            value
        }
        ...MetricsView_data @arguments(unitVolume: $unitVolume)
        ...LoadChart_info
        ...VitalsTable_info @arguments(unitTemperature: $unitTemperature, unitPressure: $unitPressure)
        ...FuelStats_data @arguments(unitVolume: $unitVolume)
        ...Events_data @arguments(unitVolume: $unitVolume)
    }
`;

type MetricOverTime = RunReportResultDisplay_data$data['fuelConsumptionRateOverTime' | 'fuelLevelOverTime'] | null;

function processChartData(data: MetricOverTime | null) {
    if (data) {
        const returnData = data
            .flatMap(point => {
                const key = Duration.fromISO(point.offset as string).set({ milliseconds: 0 });
                if (key.toMillis() >= 0) {
                    return {
                        key: key,
                        value: point.value,
                    };
                } else {
                    return [];
                }
            })
            .filter(point => point && point.value !== null);

        return returnData;
    } else {
        return [];
    }
}
