import { useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';

import graphql from 'babel-plugin-relay/macro';
import { logError } from 'lib/log';

import { DeviceFormValues, DualPlaneConfigurationType, createDefaultDeviceFormValues } from '../schema';
import { prefillLoadQuery } from './__generated__/prefillLoadQuery.graphql';

/**
 * This function is used to prefill the form with data from the server.
 *
 * Whether data is requested at all, and what data is requested, is determined by the
 * url search parameters. The following parameters are used:
 *
 * - `site`: The ID of the site to prefill the form with.
 * - `companion`: The ID of the companion device to prefill the form with.
 */
export function usePrefillData(): DeviceFormValues {
    const [params] = useSearchParams();

    const site = params.get('site');
    const companion = params.get('companion');

    const isPrefillNeeded = site || companion;

    const data = useLazyLoadQuery<prefillLoadQuery>(
        PrefillLoadQuery,
        {
            siteId: site ?? '',
            hasSite: !!site,
            companionDeviceId: companion ?? '',
            hasCompanion: !!companion,
        },
        {
            // Poor mans way to skip sending a request if no prefill is needed.
            fetchPolicy: isPrefillNeeded ? 'store-or-network' : 'store-only',
        }
    );

    const initialData = useMemo<DeviceFormValues>(() => {
        const initial = createDefaultDeviceFormValues();

        if (data.site) {
            initial.site = {
                type: 'all',
                id: data.site.id,
                displayName: data.site.name,
            };
        } else if (site) {
            logError('Cannot prefill device: Site not found.');
        }

        if (data.companionDevice) {
            // verify that it is not already a companion device to another device
            if (data.companionDevice.dualPlaneCompanion?.device) {
                logError('Cannot prefill device: Device is already a companion device.');
                return initial;
            }

            // verify that it's on the same site. Ignore if it's not.
            if (data.site && data.site.id !== data.companionDevice.site.id) {
                logError('Cannot prefill device: Device is not on the same site.');
                return initial;
            }

            initial.companionReference = {
                id: data.companionDevice.id,
                displayName: data.companionDevice.name,
                siteName: data.companionDevice.site.name,
            };

            // Not sure which one should be the default
            initial.companionConfiguration = DualPlaneConfigurationType.NPlusOne;

            if (!data.site) {
                initial.site = {
                    type: 'all',
                    id: data.companionDevice.site.id,
                    displayName: data.companionDevice.site.name,
                };
            }
        } else if (companion) {
            logError('Cannot prefill device: Companion device not found.');
        }

        return initial;
        // This is initial state, so we don't want to re-run this hook.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return initialData;
}

const PrefillLoadQuery = graphql`
    query prefillLoadQuery($siteId: ID!, $hasSite: Boolean!, $companionDeviceId: ID!, $hasCompanion: Boolean!) {
        site(id: $siteId) @include(if: $hasSite) {
            id
            name
        }
        companionDevice: device(id: $companionDeviceId) @include(if: $hasCompanion) {
            id
            name
            site {
                id
                name
            }
            dualPlaneCompanion {
                device {
                    id
                }
            }
        }
    }
`;
