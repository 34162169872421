import { commitMutation } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';

import { invitationsResendInviteMutation } from './__generated__/invitationsResendInviteMutation.graphql';

export async function resendUserInvitation(id: string, environment: IEnvironment) {
    return new Promise((resolve, reject) => {
        commitMutation<invitationsResendInviteMutation>(environment, {
            mutation: ResendUserInvitationMutation,
            variables: { id },
            onCompleted: (response, error) => {
                if (response.reinviteUser?.username) {
                    resolve(response.reinviteUser.username);
                }
                if (response.reinviteUser?.problems) {
                    reject(response.reinviteUser.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

const ResendUserInvitationMutation = graphql`
    mutation invitationsResendInviteMutation($id: String!) {
        reinviteUser(username: $id) {
            ... on User {
                username
            }
            ... on UserProblemResponse {
                problems {
                    type
                }
            }
        }
    }
`;
