/**
 * @generated SignedSource<<307cc1a12d1f862d40ff2ed3b5f7acde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemperatureChart_metrics$data = {
  readonly aggregatedTemperature: ReadonlyArray<{
    readonly average: number | null;
    readonly timestamp: string;
  }> | null;
  readonly currentCheck: ReadonlyArray<{
    readonly average: number | null;
  }> | null;
  readonly powerCheck: ReadonlyArray<{
    readonly average: number | null;
  }> | null;
  readonly voltageCheck: ReadonlyArray<{
    readonly average: number | null;
  }> | null;
  readonly " $fragmentType": "TemperatureChart_metrics";
};
export type TemperatureChart_metrics$key = {
  readonly " $data"?: TemperatureChart_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemperatureChart_metrics">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "average",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitTemperature"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemperatureChart_metrics",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "points",
          "value": 20
        },
        {
          "kind": "Variable",
          "name": "unit",
          "variableName": "unitTemperature"
        }
      ],
      "concreteType": "AggregatedTimestampMetric",
      "kind": "LinkedField",
      "name": "aggregatedTemperature",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "currentCheck",
      "args": null,
      "concreteType": "AggregatedTimestampMetric",
      "kind": "LinkedField",
      "name": "aggregatedCurrent",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "powerCheck",
      "args": null,
      "concreteType": "AggregatedTimestampMetric",
      "kind": "LinkedField",
      "name": "aggregatedPower",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "voltageCheck",
      "args": null,
      "concreteType": "AggregatedTimestampMetric",
      "kind": "LinkedField",
      "name": "aggregatedVoltage",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "497e155921cdd039276254bbbb6c59ad";

export default node;
