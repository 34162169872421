import React, { ReactElement, useMemo } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { FilterRange } from 'components/FilterRange';
import { RangeFilter } from 'filters/common';
import { useQuery } from 'lib/query-helpers';

import { useCurrentUserUnitsPref } from '../../../lib/auth';
import {
    BatteryTypeDistributionType,
    BatteryTypeFilterRange_batteryTypeDistributionQuery,
} from './__generated__/BatteryTypeFilterRange_batteryTypeDistributionQuery.graphql';

export interface BatteryTypeFilterRangeProps {
    title: string;
    distributionType: BatteryTypeDistributionType; // add other type to this as needed
    countLabel?: [string, string];
    range?: [number, number];
    current: RangeFilter | null | undefined;
    discrete?: boolean;
    showBack?: boolean;
    onClearOrBack: () => void;
    onClose: () => void;
    onSubmit: (items: RangeFilter | null) => void;
}

export function BatteryTypeFilterRange({
    title,
    distributionType,
    countLabel = ['Min', 'Max'],
    range,
    current,
    discrete,
    showBack,
    onClearOrBack,
    onClose,
    onSubmit,
}: BatteryTypeFilterRangeProps): ReactElement {
    const preferences = useCurrentUserUnitsPref();

    const { data } = useQuery<BatteryTypeFilterRange_batteryTypeDistributionQuery>(
        graphql`
            query BatteryTypeFilterRange_batteryTypeDistributionQuery(
                $type: BatteryTypeDistributionType!
                $units: UnitSettings!
            ) {
                batteryTypeDistribution(type: $type, units: $units) {
                    distribution {
                        key
                        value
                    }
                    minimum
                    maximum
                }
            }
        `,
        {
            type: distributionType,
            units: preferences,
        }
    );

    const distributionRange = useMemo<[number, number] | null>(() => {
        if (!data || data.batteryTypeDistribution.minimum == null || data.batteryTypeDistribution.maximum == null) {
            return null;
        }

        return [data.batteryTypeDistribution.minimum, data.batteryTypeDistribution.maximum];
    }, [data]);

    return (
        <FilterRange
            title={title}
            onSubmit={onSubmit}
            onClose={onClose}
            limit={range}
            current={current}
            discrete={discrete}
            showBack={showBack}
            onClearOrBack={onClearOrBack}
            countLabel={countLabel}
            distribution={data?.batteryTypeDistribution}
            distributionRange={distributionRange}
        />
    );
}
