import React, { FC } from 'react';

import { FormArea, FormikTextField } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

import manageStyle from '../../../style.module.css';

interface GPSCoordinatesProps {
    namespace?: string;
}

export const GPSCoordinates: FC<GPSCoordinatesProps> = ({ namespace }) => {
    return (
        <FormArea label='GPS Coordinates' blurb='Optional GPS Coordinates can be provided in this section.'>
            <div className={manageStyle.provisioning_content_grid_nomargin}>
                <div className='col-start-1'>
                    <FormikTextField
                        name={getQualifiedName('latitude', namespace)}
                        placeHolder={`Latitude (x.y \u00b0)`}
                        light
                        variant='outlined'
                        testId='latitude'
                    />
                </div>
                <div className='col-start-2'>
                    <FormikTextField
                        name={getQualifiedName('longitude', namespace)}
                        placeHolder={`Longitude (x.y \u00b0)`}
                        light
                        variant='outlined'
                        testId='longitude'
                    />
                </div>
            </div>
        </FormArea>
    );
};
