import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { DeviceTestResultTableColumn } from 'filters/device-test-result';
import { ColumnWithId } from 'layouts';
import { formatDurationAsHoursAndMinutes, getDateTimeFormat } from 'lib/dateFormatter';
import { EmptyCell, renderTableStatusCell } from 'lib/table-columns';
import { BatteryTestCauseMap, formatBatteryTestName } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import type {
    BatteryTestState,
    queries_batteryTestCardsQuery$data,
} from '../../__generated__/queries_batteryTestCardsQuery.graphql';

type Result = NonNullable<queries_batteryTestCardsQuery$data['device']>['tests']['data'][number];

function renderNameColumn(row: Result) {
    return row.task?.name ?? <div className='italic'>{formatBatteryTestName(row.cause)}</div>;
}
function renderStatusCell(value: BatteryTestState | 'Cancelled') {
    return renderTableStatusCell(value);
}

function renderRunTimeColumn(row: Result) {
    if (row.state === 'Scheduled') {
        return <EmptyCell />;
    }

    const from = DateTime.fromISO(row.commencedTime as string);
    const to = DateTime.fromISO(row.completedTime as string);

    if (to) {
        return formatDurationAsHoursAndMinutes(from, to);
    } else {
        return formatDurationAsHoursAndMinutes(from, DateTime.now());
    }
}

const columnHelper = createColumnHelper<Result>();
export const NameColumn = columnHelper.display({
    id: DeviceTestResultTableColumn.Name,
    header: 'Test Name',
    exportHeader: 'Test Name',
    cell: ({ row }) => renderNameColumn(row.original),
    exportValue: ({ row }) => row.task?.name ?? formatBatteryTestName(row.cause),
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '16rem',
    },
});

export const StateColumn = columnHelper.accessor('state', {
    id: DeviceTestResultTableColumn.State,
    header: 'Result',
    exportHeader: 'Result',
    cell: ({ cell }) => renderStatusCell(cell.getValue()),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const TypeColumn = columnHelper.accessor('task.type', {
    id: DeviceTestResultTableColumn.Type,
    header: 'Type',
    exportHeader: 'Type',
    cell: ({ cell }) => cell.getValue() ?? 'External',
    exportValue: ({ value }) => value ?? 'External',
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const RunTimeColumn = columnHelper.display({
    id: DeviceTestResultTableColumn.RunTime,
    header: 'Run Time',
    exportHeader: 'Run Time (min)',
    cell: ({ row }) => {
        return renderRunTimeColumn(row.original);
    },
    exportValue: ({ row }) => {
        const from = DateTime.fromISO(row.commencedTime as string);
        if (!from || !from.isValid) {
            return 0;
        }

        const to = DateTime.fromISO(row.completedTime as string);

        if (to) {
            return to.diff(from).as('minutes');
        } else {
            return DateTime.now().diff(from).as('minutes');
        }
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const StartTimeColumn = columnHelper.accessor('commencedTime', {
    id: DeviceTestResultTableColumn.StartTime,
    header: 'Date Created',
    exportHeader: 'Date Created',
    cell: ({ cell, row }) => {
        // handle schedulde test that has no commenced time yet
        if (cell.getValue() === null) {
            return row.original.task?.createdTime ? getDateTimeFormat(row.original.task.createdTime) : '';
        }

        return getDateTimeFormat(cell.getValue() as string);
    },
    exportValue: ({ value }) => new Date(value as string),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const DischargeCauseColumn = columnHelper.accessor('cause', {
    id: DeviceTestResultTableColumn.Cause,
    header: 'Discharge Cause',
    exportHeader: 'Discharge Cause',
    cell: ({ cell }) => BatteryTestCauseMap[cell.getValue()],
    exportValue: ({ value }) => BatteryTestCauseMap[value],
    meta: {
        filterable: true,
    },
});

export const BaseTableColumns = [NameColumn, StateColumn, TypeColumn, RunTimeColumn, StartTimeColumn] as ColumnWithId<
    DeviceTestResultTableColumn,
    Result
>[];

export const AllTableColumns = [...BaseTableColumns, DischargeCauseColumn] as ColumnWithId<
    DeviceTestResultTableColumn,
    Result
>[];
