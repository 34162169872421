const MinCardWidth = 22 * 16; // 22rem
const NavWidth = 260;
const GridGap = 1.875 * 16; // 1.875rem
const LayoutPadding = 8 * 16; // 8rem

export function getColumnCountForWindowSize(width: number): number {
    if (width < 1024) {
        return 1;
    } else if (width < 1540) {
        return 2;
    } else if (width < 1920) {
        return 3;
    } else {
        // Dynamic column count based on screen width

        // N * MinCardWidth + (N - 1) * GridGap + NavWidth + LayoutPadding = width

        const availableWidth = width - NavWidth - LayoutPadding;
        const columnCount = Math.floor((availableWidth + GridGap) / (MinCardWidth + GridGap));

        return columnCount;
    }
}

export function getSmallSectionLoadSize(columns: number): number {
    if (columns <= 2) {
        return 2;
    } else {
        return columns;
    }
}

export function getLargeSectionLoadSize(columns: number): number {
    if (columns <= 2) {
        return 4;
    } else {
        return columns * 2;
    }
}
