import React from 'react';

import { ACPowerStatus, DCPowerStatus } from './SiteOverviewContent';
import { ConnectedSVGPath, DisconnectedSVG, GraySVGPath } from './components';
import style from './style.module.css';

const GRID_ROW_GAP_PX = 48;

export function getACPowerSVGPath(
    acPowerState: ACPowerStatus | null,
    colWidth: number,
    colHeight: number,
    skeleton = false
) {
    const gridBoxCenterLeft = `0,${colHeight / 2}`;
    const gridBoxCenter = `${colWidth / 2},${colHeight / 2}`;
    const gridColumnCenter = `${colWidth / 2},${colHeight + GRID_ROW_GAP_PX + colHeight / 2}`;
    const gridBoxCenterRight = `${colWidth},${colHeight / 2}`;
    const generatorStart = `0,${colHeight + GRID_ROW_GAP_PX + colHeight / 2}`;

    const gridPath = `
        M${gridBoxCenterLeft}
        L${gridBoxCenter}
        L${gridColumnCenter}
        L${colWidth},${colHeight + GRID_ROW_GAP_PX + colHeight / 2}
    `;
    const generatorPath = `
        M${generatorStart}
        L${gridColumnCenter}
        L${gridBoxCenter}
        L${gridBoxCenterRight}
    `;

    if (skeleton) {
        return (
            <>
                <div className='row-start-1 col-start-2 row-span-2 relative'>
                    <GraySVGPath path={gridPath} />
                </div>
                <div className='row-start-2 col-start-2 row-span-2 relative'>
                    <GraySVGPath path={generatorPath} />
                </div>
            </>
        );
    }

    if (acPowerState === ACPowerStatus.GridPower) {
        return (
            <>
                <div className='row-start-1 col-start-2 row-span-2 relative'>
                    <ConnectedSVGPath
                        path={gridPath}
                        animationMaskClass={style.ani_mask_ac}
                        animationMaskId='mask_ac'
                    />
                </div>
                <div className='row-start-2 col-start-2 row-span-2'>
                    <GraySVGPath path={generatorPath} />
                </div>
            </>
        );
    } else if (acPowerState === ACPowerStatus.GeneratorPower) {
        return (
            <>
                <div className='row-start-1 col-start-2 row-span-2 relative'>
                    <GraySVGPath path={gridPath} />
                </div>
                <div className='row-start-2 col-start-2 row-span-2 relative'>
                    <ConnectedSVGPath
                        path={generatorPath}
                        animationMaskClass={style.ani_mask_ac}
                        animationMaskId='mask_ac'
                        reverseDirection
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className='row-start-1 col-start-2 row-span-2 relative'>
                    <GraySVGPath path={gridPath} />
                </div>
                <div className='row-start-2 col-start-2 row-span-2 relative'>
                    <GraySVGPath path={generatorPath} />
                </div>
                <div className='row-start-1 col-start-2 row-span-3 relative'>
                    <DisconnectedSVG
                        cx={colWidth * 0.75}
                        cy={colHeight * 1.5 + GRID_ROW_GAP_PX}
                        translate={`${colWidth * 0.75 - 12},${colHeight * 1.5 + GRID_ROW_GAP_PX - 12}`}
                    />
                </div>
            </>
        );
    }
}

export function getDCPowerSVGPath(dcPowerState: DCPowerStatus, colWidth: number, colHeight: number, skeleton = false) {
    const gridBoxCenterLeft = `0,${colHeight / 2}`;
    const gridBoxCenter = `${colWidth / 2},${colHeight / 2}`;
    const gridBoxCenterBottom = `${colWidth / 2},${colHeight + GRID_ROW_GAP_PX}`;
    const gridBoxCenterRight = `${colWidth},${colHeight / 2}`;

    const rectifierPath = `
        M${gridBoxCenterLeft}
        L${gridBoxCenter}
    `;
    const batteryPath = `
        M${gridBoxCenter}
        L${gridBoxCenterBottom}
    `;
    const loadPath = `
        M${gridBoxCenter}
        L${gridBoxCenterRight}
    `;
    const rectifierToLoadPath = `
        M${gridBoxCenterLeft}
        L${gridBoxCenterRight}
    `;
    const batteryToLoadPath = `
        M${gridBoxCenterBottom}
        L${gridBoxCenter}
        L${gridBoxCenterRight}
    `;
    const fullPath = `
        M${gridBoxCenterLeft}
        L${gridBoxCenterRight}
        M${gridBoxCenter}
        L${gridBoxCenterBottom}
    `;
    const batteryChargingOnlyPath = `
        M${gridBoxCenterLeft}
        L${gridBoxCenter}
        L${gridBoxCenterBottom}
    `;

    if (skeleton) {
        return (
            <>
                <div className='row-start-2 col-start-4 row-span-2 relative'>
                    <GraySVGPath path={fullPath} />
                </div>
            </>
        );
    }

    switch (dcPowerState) {
        case DCPowerStatus.RectifierPower:
            return (
                <>
                    <div className='row-start-2 col-start-4 row-span-2 relative'>
                        <GraySVGPath path={batteryPath} />
                    </div>

                    <div className='row-start-2 col-start-4 row-span-1 relative'>
                        <ConnectedSVGPath
                            path={rectifierToLoadPath}
                            animationMaskClass={style.ani_mask_dc}
                            animationMaskId='mask_dc'
                        />
                    </div>
                </>
            );
        case DCPowerStatus.BatteryPower:
            return (
                <>
                    <div className='row-start-2 col-start-4 row-span-1 relative'>
                        <GraySVGPath path={rectifierPath} />
                    </div>

                    <div className='row-start-2 col-start-4 row-span-2 relative'>
                        <ConnectedSVGPath
                            path={batteryToLoadPath}
                            animationMaskClass={style.ani_mask_dc}
                            animationMaskId='mask_dc'
                            reverseDirection
                        />
                    </div>
                </>
            );
        case DCPowerStatus.BatteryCharging:
            return (
                <>
                    <div className='row-start-2 col-start-4 row-span-2 relative'>
                        <ConnectedSVGPath
                            path={fullPath}
                            animationMaskClass={style.ani_mask_dc}
                            animationMaskId='mask_dc'
                        />
                    </div>
                </>
            );
        case DCPowerStatus.NoLoad:
            return (
                <div className='row-start-2 col-start-4 row-span-2 relative'>
                    <GraySVGPath path={fullPath} />
                    <div className='w-full h-full absolute top-0'>
                        <DisconnectedSVG
                            cx={colWidth * 0.75}
                            cy={colHeight * 0.5}
                            translate={`${colWidth * 0.75 - 12},${colHeight * 0.5 - 12}`}
                        />
                    </div>
                </div>
            );
        case DCPowerStatus.NoLoadBatteryCharging:
            return (
                <>
                    <div className='row-start-2 col-start-4 row-span-2 relative'>
                        <GraySVGPath path={loadPath} />
                        <div className='w-full h-full absolute top-0'>
                            <DisconnectedSVG
                                cx={colWidth * 0.75}
                                cy={colHeight * 0.5}
                                translate={`${colWidth * 0.75 - 12},${colHeight * 0.5 - 12}`}
                            />
                        </div>
                    </div>

                    <div className='row-start-2 col-start-4 row-span-2 relative'>
                        <ConnectedSVGPath
                            path={batteryChargingOnlyPath}
                            animationMaskClass={style.ani_mask_dc}
                            animationMaskId='mask_dc'
                        />
                    </div>
                </>
            );
        case DCPowerStatus.NoPowerControllers:
            return (
                <div className='row-start-2 col-start-4 row-span-2 relative'>
                    <GraySVGPath path={fullPath} />
                    <div className='w-full h-full absolute top-0'>
                        <DisconnectedSVG
                            cx={colWidth * 0.75}
                            cy={colHeight * 0.5}
                            translate={`${colWidth * 0.75 - 12},${colHeight * 0.5 - 12}`}
                        />
                    </div>
                </div>
            );
    }
}
