/**
 * @generated SignedSource<<c4a73630a4725e2b3eb8a7719dd4445e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BlocDataDisplay_test$data = {
  readonly abortedTime: string | null;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly coupDeFouet: {
    readonly dip: number;
    readonly float: number;
    readonly plateauOffset: string;
    readonly plateauVoltage: number;
    readonly troughOffset: string;
    readonly troughVoltage: number;
  } | null;
  readonly id: string;
  readonly state: BatteryTestState;
  readonly " $fragmentType": "BlocDataDisplay_test";
};
export type BlocDataDisplay_test$key = {
  readonly " $data"?: BlocDataDisplay_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlocDataDisplay_test">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlocDataDisplay_test",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryCoupDeFouet",
      "kind": "LinkedField",
      "name": "coupDeFouet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "float",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "troughVoltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "troughOffset",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plateauVoltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plateauOffset",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dip",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "6354dd17e5abf13bb2e2cfec5609fdc0";

export default node;
