import { useCallback, useMemo, useState } from 'react';

export interface SelectedDeviceInfo {
    companion?: string;
    testScheduled?: boolean;
}
export interface SelectedDeviceInfoWithId extends SelectedDeviceInfo {
    id: string;
}

export interface DeviceSelectionControls {
    setDeviceSelected(id: string, info: SelectedDeviceInfo): void;
    setDeviceUnselected(id: string): void;
    setSelectedDevices(devices: SelectedDeviceInfoWithId[], append?: boolean): void;
    setTotalDeviceCount(total: number): void;
    selectedDevices: Set<string>;
    areAllSelected: boolean;
}

export function useDeviceSelection(): DeviceSelectionControls {
    const [selectedDeviceMap, setSelectedDeviceMap] = useState(new Map<string, SelectedDeviceInfo>());
    const [totalDeviceCount, setTotalDeviceCount] = useState<number | null>(null);

    const selectedDevices = useMemo(() => new Set(selectedDeviceMap.keys()), [selectedDeviceMap]);

    const setDeviceSelected = useCallback((id: string, info: SelectedDeviceInfo) => {
        setSelectedDeviceMap(previousSelected => {
            const newSelected = new Map(previousSelected);

            if (selectDevice(id, info, newSelected)) {
                return newSelected;
            } else {
                return previousSelected;
            }
        });
    }, []);

    const setBulkDeviceSelected = useCallback((newDevices: { id: string; info: SelectedDeviceInfo }[]) => {
        setSelectedDeviceMap(previousSelected => {
            const newSelected = new Map(previousSelected);

            newDevices.forEach(device => newSelected.set(device.id, device.info));

            return newSelected;
        });
    }, []);

    const setSelectedDevices = useCallback(
        (devices: SelectedDeviceInfoWithId[], append = false) => {
            if (append) {
                const tempDevices: { id: string; info: SelectedDeviceInfo }[] = [];

                for (const device of devices) {
                    if (!selectedDeviceMap.has(device.id)) {
                        tempDevices.push({ id: device.id, info: device });
                    }
                }

                setBulkDeviceSelected(tempDevices);
            } else {
                const newSelected = new Map<string, SelectedDeviceInfo>();
                for (const device of devices) {
                    selectDevice(device.id, device, newSelected);
                }
                setSelectedDeviceMap(newSelected);
            }
        },
        [selectedDeviceMap, setBulkDeviceSelected]
    );

    const setDeviceUnselected = useCallback((id: string) => {
        setSelectedDeviceMap(previousSelected => {
            const newSelected = new Map(previousSelected);

            const info = previousSelected.get(id);
            if (newSelected.delete(id)) {
                // make sure that companion is also unselected if present
                if (info!.companion && (info!.testScheduled === undefined || info!.testScheduled)) {
                    newSelected.delete(info!.companion);
                }

                return newSelected;
            } else {
                return previousSelected;
            }
        });
    }, []);

    return {
        setSelectedDevices,
        setDeviceSelected,
        setDeviceUnselected,
        setTotalDeviceCount,
        selectedDevices,
        areAllSelected: selectedDevices.size === totalDeviceCount,
    };
}

function selectDevice(
    id: string,
    info: SelectedDeviceInfo,
    selectedDeviceMap: Map<string, SelectedDeviceInfo>
): boolean {
    if (selectedDeviceMap.set(id, info)) {
        // make sure that companion is also selected if present
        if (info.companion && (info.testScheduled === undefined || info.testScheduled)) {
            selectedDeviceMap.set(info.companion, { companion: id });
        }

        return true;
    } else {
        return false;
    }
}
