import { BarDataType } from '@accesstel/pcm-ui';

/**
 * Removes the readonly markers from a type.
 * This is useful if you need to pass a readonly type into a function.
 * Note: This is not a deep mutable type, this will only remove the surface
 * level readonly modifiers.
 */
export type Mutable<T> = {
    -readonly [key in keyof T]: T[key];
};

export const StateStyleMap: Record<string, Pick<BarDataType, 'bgClass'>> = {
    'smart-start': {
        bgClass: 'bg-pineLight',
    },
    aborted: {
        bgClass: 'bg-black',
    },
    failed: {
        bgClass: 'bg-coralRegular',
    },
    passed: {
        bgClass: 'bg-pineRegular',
    },
    'in-progress': {
        bgClass: 'bg-eggplantRegular',
    },
    finalizing: {
        bgClass: 'bg-eggplantExtraLight',
    },
    analyzing: {
        bgClass: 'bg-eggplantExtraLight',
    },
    waiting: {
        bgClass: 'bg-coralLight',
    },
    scheduled: {
        bgClass: 'bg-coralLight',
    },
    completed: {
        bgClass: 'bg-mauveRegular',
    },
    inconclusive: {
        bgClass: 'bg-eggplantLight',
    },
};

export type TestStatus =
    | 'Aborted'
    | 'InProgress'
    | 'Failed'
    | 'Passed'
    | 'Scheduled'
    | 'SmartStart'
    | 'Waiting'
    | 'Inconclusive'
    | 'Analyzing'
    | 'Finalizing'
    | '%future added value';
