import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import { createColumnHelper } from '@tanstack/react-table';
import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { formatMinutesAsHoursAndMinutes } from 'lib/dateFormatter';
import { numberToLocaleString } from 'lib/numberFormatters';
import { EmptyCell, renderDeviceHealthCell, renderMetricCell, renderUserConfigCell } from 'lib/table-columns';
import { getNiceSNMPVersion } from 'lib/textFormatters';
import { formatValueWithString, formatValueWithUnit } from 'lib/units';
import { Duration } from 'luxon';

import { DeviceTableColumn, DeviceTableColumnId } from '../../../filters/device/types';
import { ColumnWithId } from '../../../layouts';
import { columns_deviceAttributeColumnsQuery } from './__generated__/columns_deviceAttributeColumnsQuery.graphql';
import { Device } from './type';

const columnHelper = createColumnHelper<Device>();

const ColumnDefinitions = [
    columnHelper.accessor('name', {
        id: DeviceTableColumn.Name,
        header: 'Name',
        exportHeader: 'Name',
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '16rem',
        },
    }),
    columnHelper.accessor('type.displayName', {
        id: DeviceTableColumn.Type,
        header: 'Type',
        exportHeader: 'Type',
        meta: {
            filterable: true,
            sortable: true,
        },
    }),
    columnHelper.accessor('site.name', {
        id: DeviceTableColumn.Site,
        header: 'Site',
        exportHeader: 'Site Name',
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '12rem',
        },
    }),
    columnHelper.accessor('site.address.state', {
        id: DeviceTableColumn.State,
        header: 'State',
        exportHeader: 'State',
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '8.5rem',
        },
    }),
    columnHelper.accessor('connectionSettings.protocols', {
        id: DeviceTableColumn.SnmpVersion,
        header: 'SNMP Version',
        exportHeader: 'SNMP Version',
        cell: ({ cell }) =>
            renderUserConfigCell(() => {
                if (!cell.getValue() || !cell.getValue()[0]?.version) {
                    return;
                }

                return getNiceSNMPVersion(cell.getValue()[0].version!);
            }),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('health', {
        id: DeviceTableColumn.DeviceStatus,
        header: 'Status',
        exportHeader: 'Device Status',
        cell: ({ cell }) => {
            const value = cell.getValue();
            return renderDeviceHealthCell(value ?? 'Unknown');
        },
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('battery.metrics.latestStatus', {
        id: DeviceTableColumn.BatteryStatus,
        header: 'Battery Status',
        exportHeader: 'Battery Status',
        cell: ({ cell }) => batteryStatusToString(cell.getValue() ?? null),
        exportValue: ({ value }) => batteryStatusToString(value ?? null),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('monitorOnly', {
        id: DeviceTableColumn.MonitorOnly,
        header: 'Monitor Only',
        exportHeader: 'Monitor Only',
        cell: ({ cell }) => (cell.getValue() ? 'Yes' : 'No'),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('battery.strings.count', {
        id: DeviceTableColumn.BatteryStringCount,
        header: 'Battery String Count',
        exportHeader: 'Battery String Count',
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('battery.reserveTime', {
        id: DeviceTableColumn.BatteryReserveTime,
        header: 'Battery Reserve Time',
        exportHeader: 'Battery Reserve Time (min)',
        cell: ({ cell }) =>
            renderUserConfigCell(() => {
                if (!cell.getValue()) {
                    return;
                }

                return formatMinutesAsHoursAndMinutes(cell.getValue() as number);
            }),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('battery.metrics.latestStateOfHealth', {
        id: DeviceTableColumn.BatteryStateOfHealth,
        header: 'Battery State Of Health',
        exportHeader: 'Battery SoH',
        cell: ({ cell }) =>
            renderMetricCell(() => {
                if (!cell.getValue()) {
                    return;
                }

                return formatValueWithString(cell.getValue() as number, '%');
            }),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('battery.metrics.latestTemperature', {
        id: DeviceTableColumn.BatteryTemperature,
        header: 'Battery Temperature',
        exportHeader: 'Battery Temperature',
        cell: ({ cell, table }) =>
            renderMetricCell(() => {
                const value = cell.getValue();
                if (!value) {
                    return;
                }

                return formatValueWithUnit(numberToLocaleString(value, 1), table.options.meta?.units?.temperature);
            }),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('battery.metrics.latestRemaingCapacity', {
        id: DeviceTableColumn.BatteryCapacityRemaining,
        header: 'Battery Remaining Capacity',
        exportHeader: 'Battery Remaining Capacity (Ah)',
        cell: ({ cell }) =>
            renderMetricCell(() => {
                if (!cell.getValue()) {
                    return;
                }

                return formatValueWithString(cell.getValue() as number, 'Ah');
            }),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('battery.metrics.latestTotalEnergy', {
        id: DeviceTableColumn.BatteryEnergyTotal,
        header: 'Battery Total Energy',
        exportHeader: 'Battery Total Energy (kWh)',
        cell: ({ cell }) =>
            renderMetricCell(() => {
                if (!cell.getValue()) {
                    return;
                }

                return formatValueWithString(numberToLocaleString(cell.getValue() as number), 'kWh');
            }),
        meta: {
            filterable: true,
        },
    }),
    columnHelper.accessor('acPower.reliability.incidentCount', {
        id: DeviceTableColumn.IncidentCount,
        header: 'Incident Count',
        exportHeader: 'Incident Count',
        cell: ({ cell }) => {
            const value = cell.getValue();
            if (value === 0) {
                return <EmptyCell text='0' />;
            }

            return `${value}`;
        },
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '10rem',
        },
    }),
    columnHelper.accessor('acPower.reliability.mttr.current', {
        id: DeviceTableColumn.Mttr,
        header: 'MTTR',
        exportHeader: 'MTTR (min)',
        cell: ({ cell }) => {
            const value = cell.getValue();
            if (value === null) {
                return <EmptyCell text='No Incidents' />;
            }

            return humanizeDuration(Duration.fromObject({ minutes: value }).as('milliseconds'), {
                largest: 2,
                round: true,
            });
        },
        meta: {
            filterable: true,
            sortable: true,
        },
    }),
    columnHelper.accessor('acPower.reliability.mtbf.current', {
        id: DeviceTableColumn.Mtbf,
        header: 'MTBF',
        exportHeader: 'MTBF (min)',
        cell: ({ cell }) => {
            const value = cell.getValue();
            if (value === null) {
                return <EmptyCell text='No Incidents' />;
            }

            return humanizeDuration(Duration.fromObject({ minutes: value }).as('milliseconds'), {
                largest: 2,
                round: true,
            });
        },
        meta: {
            filterable: true,
            sortable: true,
        },
    }),
    columnHelper.accessor('acPower.reliability.totalOutageDuration.current', {
        id: DeviceTableColumn.OutageDurationSum,
        header: 'Total Time Offline',
        exportHeader: 'Total Time Offline (min)',
        cell: ({ cell }) => {
            const value = cell.getValue();

            if (value === null || value === 0) {
                return <EmptyCell text='No outages' />;
            }

            return humanizeDuration(Duration.fromObject({ minutes: value }).as('milliseconds'), {
                largest: 2,
                round: true,
            });
        },
        meta: {
            filterable: true,
            sortable: true,
            maxWidth: '12.5rem',
        },
    }),
    columnHelper.accessor('acPower.reliability.percentile.current', {
        id: DeviceTableColumn.ACReliability,
        header: 'Reliability Rank',
        exportHeader: 'AC Reliability Rank',
        cell: ({ cell }) => {
            const value = cell.getValue();

            if (value == null) {
                return <EmptyCell text='Not graded' />;
            }

            return `${value}`;
        },
        meta: {
            sortable: true,
            filterable: true,
            maxWidth: '12rem',
        },
    }),
];

export interface SiteColumnsOptions {
    /**
     * If provided, the available columns will be limited to the provided list.
     */
    columns?: DeviceTableColumnId[];

    /**
     * If true, the custom attributes will not be available as columns.
     */
    disableAttributeColumns?: boolean;
}

const DefaultTableColumns = [
    DeviceTableColumn.Name,
    DeviceTableColumn.Type,
    DeviceTableColumn.Site,
    DeviceTableColumn.State,
    DeviceTableColumn.SnmpVersion,
    DeviceTableColumn.DeviceStatus,
    DeviceTableColumn.BatteryStatus,
    DeviceTableColumn.MonitorOnly,
    DeviceTableColumn.BatteryStringCount,
    DeviceTableColumn.BatteryReserveTime,
    DeviceTableColumn.BatteryStateOfHealth,
    DeviceTableColumn.BatteryTemperature,
    DeviceTableColumn.BatteryCapacityRemaining,
    DeviceTableColumn.BatteryEnergyTotal,
    // TODO: Maybe add the other columns here?
];

export function useDeviceColumnDefinitions(
    options: SiteColumnsOptions = {}
): ColumnWithId<DeviceTableColumnId, Device>[] {
    const attributeColumns = useLazyLoadQuery<columns_deviceAttributeColumnsQuery>(
        graphql`
            query columns_deviceAttributeColumnsQuery($disableAttributeColumns: Boolean!) {
                attributeNames(search: "", only: Device, limit: 1000, status: Active)
                    @skip(if: $disableAttributeColumns)
            }
        `,
        {
            disableAttributeColumns: options.disableAttributeColumns ?? false,
        },
        {
            fetchPolicy: 'store-or-network',
        }
    );

    let attributeColumnDefinitions: ColumnWithId<DeviceTableColumnId, Device>[] = [];

    if (!options.disableAttributeColumns && attributeColumns.attributeNames) {
        attributeColumnDefinitions = attributeColumns.attributeNames.map(name => {
            return columnHelper.display({
                id: `tag_${name}`,
                header: `Tag ${name}`,
                exportHeader: `Tag ${name}`,
                cell: ({ row }) => {
                    const device = row.original;
                    return device.attributes?.find(attribute => attribute.name === name)?.value;
                },
                meta: {
                    filterable: true,
                    sortable: false,
                },
            });
        }) as ColumnWithId<DeviceTableColumnId, Device>[];
    }

    const availableColumnIds = options.columns ?? DefaultTableColumns;

    const columns = availableColumnIds.map(columnId => {
        const column = ColumnDefinitions.find(column => column.id === columnId);

        console.assert(column, `No column definition found for column ID ${columnId}`);

        return column;
    }) as ColumnWithId<DeviceTableColumnId, Device>[];

    return columns.concat(attributeColumnDefinitions);
}
