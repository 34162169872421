import React, { FC } from 'react';

interface DisconnectedSVGProps {
    cx: number;
    cy: number;
    translate: string;
}

export const DisconnectedSVG: FC<DisconnectedSVGProps> = ({ cx, cy, translate }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' className='w-full h-full' data-testid='disconnected-svg'>
            <circle
                r={16}
                cx={cx}
                cy={cy}
                fill='currentColor'
                stroke='white'
                strokeWidth={4}
                className='text-coralRegular'
            />
            <g
                fill='none'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                transform={`translate(${translate})`}
            >
                <line x1='18' y1='6' x2='6' y2='18' />
                <line x1='6' y1='6' x2='18' y2='18' />
            </g>
        </svg>
    );
};
