import React, { FC, useCallback } from 'react';

import { Button, FormikTextField, LogoStackedNormalExtended, useExtendedNavigate, useToast } from '@accesstel/pcm-ui';

import { captureException, captureMessage } from '@sentry/react';
import { Form, Formik, FormikHelpers } from 'formik';

import { useDocumentTitle } from '../../../components';
import { AuthResultType, confirmPasswordReset } from '../../../lib/auth';
import { logError } from '../../../lib/log';
import { Paths } from '../../../lib/routes';
import { DefaultResetFormValues, ResetFormValidationSchema, ResetFormValues } from './schema';

export const ResetPasswordView: FC = () => {
    const navigate = useExtendedNavigate();
    const { show } = useToast();
    useDocumentTitle('Reset Password');

    const doResetPassword = useCallback(
        async (
            values: ResetFormValues,
            { setSubmitting, setFieldError, submitForm }: FormikHelpers<ResetFormValues>
        ) => {
            setSubmitting(true);

            try {
                const [result, message] = await confirmPasswordReset(values.username, values.code, values.newPassword);

                switch (result) {
                    case AuthResultType.Success:
                        show({
                            text: 'Password reset successfully',
                            variant: 'info',
                        });
                        navigate(Paths.Dashboard);
                        break;
                    case AuthResultType.FailureOther:
                        captureMessage(`Abnormal login result ${message}`);
                        logError('Unexpected error: ', message);

                        setFieldError('commonError', 'An unexpected problem occurred. Please try again later');
                        break;
                    case AuthResultType.FailureBadAuth:
                        setFieldError('newPassword', message);
                        break;
                    case AuthResultType.FailureBadCode:
                        setFieldError('code', message);
                        break;
                    case AuthResultType.RateLimitExceeded:
                        setFieldError('commonError', 'Too many attempts recently, please try again later.');
                        break;
                }
            } catch (error) {
                logError('Error resetting password. ', error);
                setFieldError('commonError', 'Unable to reset password.');
                show({
                    text: 'An unexpected problem occurred.',
                    variant: 'error',
                    actions: [
                        {
                            title: 'Retry',
                            onClick: () => {
                                setSubmitting(false);
                                submitForm().catch(error => {
                                    captureException(error);
                                });
                            },
                        },
                    ],
                });
            }
        },
        [navigate, show]
    );

    return (
        <>
            <div className='reset-password-view'>
                <div className='login-layout'>
                    <div className='login-layout-col-logo'>
                        <img src={LogoStackedNormalExtended} alt='accesstel logo' width={280} height={280} />
                    </div>
                    <div className='login-layout-col-form'>
                        <Formik
                            initialValues={DefaultResetFormValues}
                            validationSchema={ResetFormValidationSchema}
                            onSubmit={doResetPassword}
                        >
                            {({ isSubmitting, errors }) => (
                                <Form className='space-y-8'>
                                    <div>
                                        <h1 className='text-3xl font-bold'>Reset password</h1>
                                        <div className='space-y-2'>
                                            <p className='mb-6'>
                                                Please provide your account's username, the verification code you were
                                                emailed, and your new password.
                                            </p>
                                            <p>
                                                Your new password needs at least 8 characters with a mix of numbers,
                                                uppercase &amp; lowercase letters.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col items-stretch text-black'>
                                        <FormikTextField
                                            name='username'
                                            placeHolder='Username*'
                                            type='text'
                                            required
                                            autoFocus
                                            id='username'
                                            light
                                        />
                                    </div>
                                    <div className='flex flex-col items-stretch text-black'>
                                        <FormikTextField
                                            name='code'
                                            placeHolder='Verification code*'
                                            type='text'
                                            required
                                            light
                                        />
                                    </div>
                                    <div className='flex flex-col items-stretch text-black'>
                                        <FormikTextField
                                            name='newPassword'
                                            placeHolder='New password*'
                                            type='password'
                                            required
                                            id='password'
                                            light
                                        />
                                    </div>
                                    <div className='flex flex-col items-stretch text-black'>
                                        <FormikTextField
                                            name='confirmPassword'
                                            placeHolder='Confirm new password*'
                                            type='password'
                                            required
                                            id='confirm-password'
                                            light
                                        />
                                    </div>
                                    {errors.commonError && (
                                        <div className='bg-coralExtraLight border-coralRegular border-l-2 p-4 text-sm'>
                                            {errors.commonError}
                                        </div>
                                    )}
                                    <div>
                                        <Button
                                            buttonClasses='flex-grow'
                                            type='submit'
                                            buttonText='Reset Password'
                                            variant='primary'
                                            size='large'
                                            processing={isSubmitting}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};
