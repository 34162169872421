import { CompareSectionDefinition, FieldDifferences } from '../types';

/**
 * Find differences between the data in each column.
 *
 * @param data The data to compare
 * @param sections The definitions of each field
 * @returns A record of differences, where the key is the field id in the form of `sectionTitle.fieldTitle`
 *          and the value is an array of booleans, where each boolean represents if the column has differences.
 */
export function findDifferences<T>(data: T[], sections: CompareSectionDefinition<T>[]): FieldDifferences {
    const differences: FieldDifferences = {};

    for (const section of sections) {
        for (const field of section.fields) {
            if (!field.comparable) {
                continue;
            }

            const columnValues = data.map(column => {
                if (field.renderForCompare) {
                    return field.renderForCompare(column);
                }

                const maybeValue = field.render(column);

                if (typeof maybeValue === 'string') {
                    return maybeValue;
                }

                if (typeof maybeValue === 'number') {
                    return maybeValue.toString();
                }

                return '';
            });

            const uniqueValues = Array.from(new Set(columnValues));
            if (uniqueValues.length > 1) {
                const id = `${section.title}.${field.title}`;

                if (data.length < 3) {
                    // Too few columns to use common values
                    differences[id] = columnValues.map(() => true);
                    continue;
                }

                // Only highlight the changed columns to reduce visual noise
                // Column are only considered different if they are not the most common value
                const valueCounts = columnValues.reduce<Record<string, number>>((counts, value) => {
                    counts[value] = (counts[value] ?? 0) + 1;
                    return counts;
                }, {});

                let mostCommonValue: string | undefined;
                let mostCommonValueCount = 0;
                for (const [value, count] of Object.entries(valueCounts)) {
                    if (count > mostCommonValueCount) {
                        mostCommonValue = value;
                        mostCommonValueCount = count;
                    }
                }

                // If there is no clear most common value, highlight all columns
                let isTieForMostCommon = false;
                for (const [value, count] of Object.entries(valueCounts)) {
                    if (value === mostCommonValue) {
                        continue;
                    }

                    if (count === mostCommonValueCount) {
                        isTieForMostCommon = true;
                        break;
                    }
                }

                if (mostCommonValueCount <= 1 || isTieForMostCommon) {
                    // All columns have different values
                    differences[id] = columnValues.map(() => true);
                } else {
                    // Only highlight the columns that are different from the most common value
                    differences[id] = columnValues.map(value => value !== mostCommonValue);
                }
            }
        }
    }

    return differences;
}
