import React from 'react';

import { DeviceNameFilterUI } from 'filters/battery-test';
import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import { decodeDeviceNameFilter, encodeDeviceNameFilter } from 'filters/device';
import { DeviceBatteryDateFilterUI } from 'filters/device/components/DeviceBatteryDateFilterUI';
import { decodeBatteryTestRuntimeFilter, encodeBatteryTestRuntimeFilter } from 'filters/task';
import { formatRangeWithString, formatRangeWithUnit } from 'lib/units';

import {
    FilterDefinition,
    FilterValueMap,
    decodeDateRangeFilter,
    decodeRangeFilter,
    encodeDateRangeFilter,
    encodeRangeFilter,
} from '../common';
import {
    TestResultBatteryStringFilterUI,
    TestResultDurationFilterUI,
    TestResultFailReasonFilterUI,
    TestResultStateFilterUI,
} from './components';
import { TestResultCauseFilterUI } from './components/TestResultCauseFilterUI';
import { TestResultEstimatedReserveTimeFilterUI } from './components/TestResultEstimatedRuntimeFilterUI';
import { TestResultFilterRange } from './components/TestResultFilterRange';
import { TestResultMetricsFilterUI } from './components/TestResultMetricsFilterUI';
import { decodeTestResultCauseFilter, decodeTestResultFailReasonFilter, decodeTestResultStateFilter } from './decode';
import { encodeTestResultCauseFilter, encodeTestResultFailReasonFilter, encodeTestResultStateFilter } from './encode';
import {
    TestResultBatteryStringCountFilter,
    TestResultCauseFilter,
    TestResultDeviceNameFilter,
    TestResultDurationFilter,
    TestResultEstimatedCapacityFilter,
    TestResultEstimatedReserveTimeFilter,
    TestResultEstimatedStateOfHealthFilter,
    TestResultFailReasonFilter,
    TestResultMetricsFilter,
    TestResultStartTimeFilter,
    TestResultStateFilter,
    TestResultTableColumn,
} from './types';

export type TestResultColumnFilterMap = {
    [TestResultTableColumn.DeviceName]: TestResultDeviceNameFilter[];
    [TestResultTableColumn.State]: TestResultStateFilter[];
    [TestResultTableColumn.Cause]: TestResultCauseFilter[];
    [TestResultTableColumn.RunTime]: TestResultDurationFilter | null;
    [TestResultTableColumn.StartTime]: TestResultStartTimeFilter | null;
    [TestResultTableColumn.FailReason]: TestResultFailReasonFilter[];
    [TestResultTableColumn.BatteryStringCount]: TestResultBatteryStringCountFilter | null;
    [TestResultTableColumn.CurrentMetrics]: TestResultMetricsFilter | null;
    [TestResultTableColumn.VoltageMetrics]: TestResultMetricsFilter | null;
    [TestResultTableColumn.TemperatureMetrics]: TestResultMetricsFilter | null;
    [TestResultTableColumn.DischargedMetrics]: TestResultMetricsFilter | null;
    [TestResultTableColumn.EstimatedStateOfHealth]: TestResultEstimatedStateOfHealthFilter | null;
    [TestResultTableColumn.EstimatedCapacity]: TestResultEstimatedCapacityFilter | null;
    [TestResultTableColumn.EstimatedReserveTime]: TestResultEstimatedReserveTimeFilter | null;
    [TestResultTableColumn.Name]: null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type TestResultExtraFilterMap = {};

export type TestResultAllFilterMap = TestResultColumnFilterMap & TestResultExtraFilterMap;

export const DefaultValues: FilterValueMap<TestResultColumnFilterMap> = {
    [TestResultTableColumn.DeviceName]: [],
    [TestResultTableColumn.State]: [],
    [TestResultTableColumn.Cause]: [],
    [TestResultTableColumn.RunTime]: null,
    [TestResultTableColumn.StartTime]: null,
    [TestResultTableColumn.FailReason]: [],
    [TestResultTableColumn.BatteryStringCount]: null,
    [TestResultTableColumn.CurrentMetrics]: null,
    [TestResultTableColumn.VoltageMetrics]: null,
    [TestResultTableColumn.TemperatureMetrics]: null,
    [TestResultTableColumn.DischargedMetrics]: null,
    [TestResultTableColumn.EstimatedStateOfHealth]: null,
    [TestResultTableColumn.EstimatedCapacity]: null,
    [TestResultTableColumn.EstimatedReserveTime]: null,
    [TestResultTableColumn.Name]: null,
};

export const StaticTestResultFilterDefinitions: FilterDefinition<TestResultTableColumn>[] = [
    {
        id: TestResultTableColumn.DeviceName,
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        column: TestResultTableColumn.DeviceName,
        component: () => DeviceNameFilterUI,
        describeValue: (value: TestResultDeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: TestResultTableColumn.State,
        type: 'multi',
        name: 'Result',
        category: 'Result',
        column: TestResultTableColumn.State,
        component: () => TestResultStateFilterUI,
        describeValue: (value: TestResultStateFilter) => value.name,
        encodeValue: encodeTestResultStateFilter,
        decodeValue: decodeTestResultStateFilter,
    },
    {
        id: TestResultTableColumn.RunTime,
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: TestResultTableColumn.RunTime,
        component: () => TestResultDurationFilterUI,
        describeValue: (value: TestResultDurationFilter) => formatDurationFilter(value),
        encodeValue: encodeBatteryTestRuntimeFilter,
        decodeValue: decodeBatteryTestRuntimeFilter,
    },
    {
        id: TestResultTableColumn.StartTime,
        type: 'single',
        name: 'Start Time',
        category: 'Start Time',
        column: TestResultTableColumn.StartTime,
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Created' }),
        describeValue: (value: TestResultStartTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: TestResultTableColumn.Cause,
        type: 'multi',
        name: 'Discharge Cause',
        category: 'Discharge Cause',
        column: TestResultTableColumn.Cause,
        component: () => TestResultCauseFilterUI,
        describeValue: (value: TestResultCauseFilter) => value.name,
        encodeValue: encodeTestResultCauseFilter,
        decodeValue: decodeTestResultCauseFilter,
    },
    {
        id: TestResultTableColumn.FailReason,
        type: 'multi',
        name: 'Fail Reason',
        category: 'Fail Reason',
        column: TestResultTableColumn.FailReason,
        component: () => TestResultFailReasonFilterUI,
        describeValue: (value: TestResultFailReasonFilter) => value.name,
        encodeValue: encodeTestResultFailReasonFilter,
        decodeValue: decodeTestResultFailReasonFilter,
    },
    {
        id: TestResultTableColumn.CurrentMetrics,
        type: 'single',
        name: 'Average Current',
        category: 'Average Current',
        column: TestResultTableColumn.CurrentMetrics,
        component: () => val => <TestResultMetricsFilterUI type='CurrentMetrics' {...val} />,
        describeValue: (value: TestResultMetricsFilter) => formatRangeWithString(value.min, value.max, 'A'),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestResultTableColumn.VoltageMetrics,
        type: 'single',
        name: 'End Voltage',
        category: 'End Voltage',
        column: TestResultTableColumn.VoltageMetrics,
        component: () => val => <TestResultMetricsFilterUI type='VoltageMetrics' {...val} />,
        describeValue: (value: TestResultMetricsFilter) => formatRangeWithString(value.min, value.max, 'V'),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestResultTableColumn.TemperatureMetrics,
        type: 'single',
        name: 'Average Temperature',
        category: 'Average Temperature',
        column: TestResultTableColumn.TemperatureMetrics,
        component: () => val => <TestResultMetricsFilterUI type='TemperatureMetrics' {...val} />,
        describeValue: (value: TestResultMetricsFilter, meta) =>
            formatRangeWithUnit(value.min, value.max, meta?.units?.temperature),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestResultTableColumn.DischargedMetrics,
        type: 'single',
        name: 'Total Discharged',
        category: 'Total Discharged',
        column: TestResultTableColumn.DischargedMetrics,
        component: () => val => <TestResultMetricsFilterUI type='DischargedMetrics' {...val} />,
        describeValue: (value: TestResultMetricsFilter) => formatRangeWithString(value.min, value.max, 'Ah'),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestResultTableColumn.BatteryStringCount,
        type: 'single',
        name: 'Battery String Count',
        category: 'Battery String Count',
        column: TestResultTableColumn.BatteryStringCount,
        component: () => TestResultBatteryStringFilterUI,
        describeValue: (value: TestResultBatteryStringCountFilter) => `${value.min} to ${value.max}`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestResultTableColumn.EstimatedStateOfHealth,
        type: 'single',
        name: 'Estimated State of Health',
        category: 'Estimated State of Health',
        column: TestResultTableColumn.EstimatedStateOfHealth,
        component: () => val => (
            <TestResultFilterRange
                title='Filter by estimated state of health'
                distributionType='EstimatedStateOfHealth'
                {...val}
            />
        ),
        describeValue: (value: TestResultEstimatedStateOfHealthFilter) =>
            formatRangeWithString(value.min, value.max, '%'),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestResultTableColumn.EstimatedCapacity,
        type: 'single',
        name: 'Estimated Capacity',
        category: 'Estimated Capacity',
        column: TestResultTableColumn.EstimatedCapacity,
        component: () => val => (
            <TestResultFilterRange title='Filter by estimated capacity' distributionType='EstimatedCapacity' {...val} />
        ),
        describeValue: (value: TestResultEstimatedCapacityFilter) => formatRangeWithString(value.min, value.max, 'Ah'),
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
    {
        id: TestResultTableColumn.EstimatedReserveTime,
        type: 'single',
        name: 'Estimated Reserve Time',
        category: 'Estimated Reserve Time',
        column: TestResultTableColumn.EstimatedReserveTime,
        component: () => TestResultEstimatedReserveTimeFilterUI,
        describeValue: (value: TestResultEstimatedReserveTimeFilter) => formatDurationFilter(value),
        encodeValue: encodeBatteryTestRuntimeFilter,
        decodeValue: decodeBatteryTestRuntimeFilter,
    },
];
