/**
 * @generated SignedSource<<f1d5579c7bda41e0ea1429de719e2406>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BooleanFilter = {
  value: boolean;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type IncidentListQuery$variables = {
  showSignificant?: BooleanFilter | null;
  siteId: string;
  startRange?: DateRangeFilter | null;
};
export type IncidentListQuery$data = {
  readonly site: {
    readonly acPower: {
      readonly " $fragmentSpreads": FragmentRefs<"IncidentList_acPower">;
    };
  } | null;
};
export type IncidentListQuery = {
  response: IncidentListQuery$data;
  variables: IncidentListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showSignificant"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRange"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "IncidentListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteACPower",
            "kind": "LinkedField",
            "name": "acPower",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "showSignificant",
                    "variableName": "showSignificant"
                  },
                  {
                    "kind": "Variable",
                    "name": "startRange",
                    "variableName": "startRange"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "IncidentList_acPower"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "IncidentListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteACPower",
            "kind": "LinkedField",
            "name": "acPower",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "isSignificant",
                        "variableName": "showSignificant"
                      },
                      {
                        "kind": "Variable",
                        "name": "startTime",
                        "variableName": "startRange"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "filters"
                  },
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": {
                      "dir": "Desc",
                      "field": "StartTime"
                    }
                  },
                  {
                    "kind": "Literal",
                    "name": "pageSize",
                    "value": 5
                  }
                ],
                "concreteType": "PaginatedSiteACPowerEvent",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteACPowerEvent",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "worstStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "voltage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "affectedAllFeeds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ACPowerEventFeed",
                        "kind": "LinkedField",
                        "name": "affectedFeeds",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "affectedAllDevices",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db936be79b784a363815b392fd394915",
    "id": null,
    "metadata": {},
    "name": "IncidentListQuery",
    "operationKind": "query",
    "text": "query IncidentListQuery(\n  $siteId: ID!\n  $showSignificant: BooleanFilter\n  $startRange: DateRangeFilter\n) {\n  site(id: $siteId) {\n    acPower {\n      ...IncidentList_acPower_2g0nl5\n    }\n    id\n  }\n}\n\nfragment IncidentList_acPower_2g0nl5 on SiteACPower {\n  events(filters: {isSignificant: $showSignificant, startTime: $startRange}, orderBy: {field: StartTime, dir: Desc}, pageSize: 5) {\n    data {\n      ...IncidentRow_event\n      id\n    }\n  }\n}\n\nfragment IncidentRow_event on SiteACPowerEvent {\n  startTime\n  duration\n  worstStatus\n  voltage\n  affectedAllFeeds\n  affectedFeeds {\n    id\n    label\n  }\n  affectedAllDevices\n}\n"
  }
};
})();

(node as any).hash = "d4039882b7fcecb2654be73923868eaa";

export default node;
