/**
 * @generated SignedSource<<62448618d0f86f39c948faa9703c6b3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ActivityLogSource = "Alert" | "AuditLog" | "GridEvent" | "%future added value";
export type ActivityLogType = "AlertSeverityCritical" | "AlertSeverityIndeterminate" | "AlertSeverityMajor" | "AlertSeverityMinor" | "AlertSeverityWarning" | "BatteryTest" | "DeviceAdd" | "DeviceEdit" | "DeviceRemove" | "GridEventStatusHigh" | "GridEventStatusLow" | "GridEventStatusOffline" | "%future added value";
export type PagedActivityLogQuery$variables = {
  id: string;
  limit: number;
};
export type PagedActivityLogQuery$data = {
  readonly activityLogs: {
    readonly data: ReadonlyArray<{
      readonly description: string;
      readonly link: {
        readonly __typename: "BatteryTest";
        readonly id: string;
        readonly taskName: string | null;
      } | {
        readonly __typename: "BatteryType";
        readonly id: string;
        readonly manufacturer: string;
        readonly model: string;
      } | {
        readonly __typename: "Device";
        readonly id: string;
        readonly name: string;
      } | {
        readonly __typename: "DeviceBatteryTestResults";
        readonly id: string;
        readonly task: {
          readonly id: string;
          readonly name: string | null;
        } | null;
      } | {
        readonly __typename: "Site";
        readonly id: string;
        readonly name: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
      readonly source: ActivityLogSource;
      readonly timestamp: string;
      readonly type: ActivityLogType;
      readonly user: {
        readonly email: string | null;
        readonly name: string | null;
      } | null;
    }>;
    readonly hasMore: boolean;
    readonly total: number;
  };
};
export type PagedActivityLogQuery = {
  response: PagedActivityLogQuery$data;
  variables: PagedActivityLogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "device",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasMore",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v11/*: any*/),
  (v8/*: any*/)
],
v13 = {
  "kind": "InlineFragment",
  "selections": (v12/*: any*/),
  "type": "Site",
  "abstractKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    }
  ],
  "type": "BatteryType",
  "abstractKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": (v12/*: any*/),
  "type": "Device",
  "abstractKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/),
    {
      "alias": "taskName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
},
v18 = [
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PagedActivityLogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedActivityLogs",
        "kind": "LinkedField",
        "name": "activityLogs",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityLog",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "link",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PagedActivityLogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedActivityLogs",
        "kind": "LinkedField",
        "name": "activityLogs",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityLog",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "link",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v18/*: any*/),
                    "type": "ACPowerEvent",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v18/*: any*/),
                    "type": "Alert",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68f5852ac58bf0adad0d2aac0e84b597",
    "id": null,
    "metadata": {},
    "name": "PagedActivityLogQuery",
    "operationKind": "query",
    "text": "query PagedActivityLogQuery(\n  $id: ID!\n  $limit: Int!\n) {\n  activityLogs(device: $id, limit: $limit) {\n    total\n    hasMore\n    data {\n      source\n      description\n      timestamp\n      type\n      user {\n        name\n        email\n      }\n      link {\n        __typename\n        ... on Site {\n          id\n          name\n        }\n        ... on BatteryType {\n          id\n          manufacturer\n          model\n        }\n        ... on Device {\n          id\n          name\n        }\n        ... on DeviceBatteryTestResults {\n          id\n          task {\n            id\n            name\n          }\n        }\n        ... on BatteryTest {\n          id\n          taskName: name\n        }\n        ... on ACPowerEvent {\n          id\n        }\n        ... on Alert {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "174b14077a6931c36977f8e746f3134f";

export default node;
