/**
 * @generated SignedSource<<91f3a65a8b21247665ed846100fa8f8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GatewayInventoryErrorType = "GatewayInvalid" | "GatewayNotFound" | "GatewayUnreachable" | "%future added value";
export type NeSelectorSearchQuery$variables = {
  deviceType: string;
  gatewayId: string;
};
export type NeSelectorSearchQuery$data = {
  readonly gatewayInventory: {
    readonly devices?: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly error?: GatewayInventoryErrorType;
  } | null;
};
export type NeSelectorSearchQuery = {
  response: NeSelectorSearchQuery$data;
  variables: NeSelectorSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gatewayId"
},
v2 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "deviceTypes.0",
        "variableName": "deviceType"
      }
    ],
    "kind": "ListValue",
    "name": "deviceTypes"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "gatewayId"
  },
  {
    "kind": "Literal",
    "name": "includeDisabled",
    "value": true
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "GatewayInventoryError",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceInventoryResult",
      "kind": "LinkedField",
      "name": "devices",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GatewayInventoryResult",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NeSelectorSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "gatewayInventory",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NeSelectorSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "gatewayInventory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e31a5a55944fc3f551f3c0894a1f71dc",
    "id": null,
    "metadata": {},
    "name": "NeSelectorSearchQuery",
    "operationKind": "query",
    "text": "query NeSelectorSearchQuery(\n  $gatewayId: ID!\n  $deviceType: ID!\n) {\n  gatewayInventory(id: $gatewayId, deviceTypes: [$deviceType], includeDisabled: true) {\n    __typename\n    ... on GatewayInventoryError {\n      error\n    }\n    ... on GatewayInventoryResult {\n      devices {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "135794e223fd1d9b0d004848d318c963";

export default node;
