import { HighlightContext, ResultItem, createHighlightContext, isRelevant } from '@accesstel/pcm-ui';

/**
 * Combines the results from a search API and a route search into a single list.
 *
 * The route results are inserted into the search results based on their relevance to the search query.
 * @param searchQuery The search query
 * @param searchResults The results from the search API
 * @param routeResults The results from the route search
 * @returns A combined list of results with the route results intermixed with the search results
 */
export function combineResults(
    searchQuery: string,
    searchResults: ResultItem[],
    routeResults: ResultItem[]
): ResultItem[] {
    const allResults = [...searchResults];

    // Precalculate scores for each result to avoid recalculating them multiple times
    const scores: number[] = [];
    for (const result of allResults) {
        scores.push(getScoreOfResult(result, createHighlightContext(searchQuery)));
    }

    const context = createHighlightContext(searchQuery);

    // Combine search results by inserting the route results at the appropriate position.
    // It is very important that we don't re-order the API search results as they are scored
    // with more advanced logic than the route results.
    // The route result scoring is best-effort to provide slightly more relevant results.
    for (const routeResult of routeResults) {
        const routeScore = getScoreOfResult(routeResult, context);

        let didAdd = false;

        for (let i = 0; i < allResults.length; i++) {
            const searchScore = scores[i];

            if (routeScore > searchScore) {
                allResults.splice(i, 0, routeResult);
                scores.splice(i, 0, routeScore);
                didAdd = true;
                break;
            }
        }

        if (!didAdd) {
            allResults.push(routeResult);
            scores.push(routeScore);
        }
    }

    return allResults;
}

function getScoreOfResult(result: ResultItem, context: HighlightContext): number {
    let score = 0;

    if (isRelevant(result.primaryText, context)) {
        score = simpleScore(context.query, result.primaryText);
    }

    if (isRelevant(result.secondaryText, context)) {
        score = Math.max(score, simpleScore(context.query, result.secondaryText));
    }

    return score;
}

/**
 * A simple scoring function to determine how well a query matches a text
 * @param query The query text
 * @param text The text being searched
 * @returns A value between 0 and 1 representing the similarity between the query and text
 */
function simpleScore(query: string, text: string): number {
    const queryWords = query.toLowerCase().split(/\b\s+/);
    const textWords = text.toLowerCase().split(/\b\s+/);

    if (queryWords.length === 0 || textWords.length === 0) {
        return 0;
    }

    // Check for exact match ignoring case and whitespace
    const joinedQuery = queryWords.join(' ');
    const joinedText = textWords.join(' ');

    if (joinedQuery === joinedText) {
        return 1;
    }

    // Score based on length of query vs text
    const queryLength = query.length;
    const textLength = text.length;

    const lengthForComparison = Math.max(queryLength, textLength);

    const lengthDifference = Math.abs(queryLength - textLength);
    const lengthScore = 1 - lengthDifference / lengthForComparison;

    return lengthScore;
}
