import { array, date, number, object, string } from 'yup';

export function getBatteryStringValidationSchema(addNewBatteryType: boolean) {
    return object({
        cellsPerString: number()
            .typeError('Must be a number')
            .required('Must have at least one cell')
            .min(1, 'Must have at least one cell'),
        installDate: date().nullable().required('Must provide an install date'),
        manufactureDate: date().nullable(),
        type: addNewBatteryType
            ? string().nullable()
            : object().required('A battery type must be provided').typeError('Battery Make and Model must be provided'),
    });
}

export function getBatterySettingsValidationSchema(addNewBatteryType: boolean) {
    return object({
        strings: array(getBatteryStringValidationSchema(addNewBatteryType)),
        minimumAllowedVoltage: number().typeError('Must be a number').nullable(),
        maximumAllowedVoltage: number().typeError('Must be a number').nullable(),
        quickTestCheckPercent: number().typeError('Must be a number').nullable(),
        quickTestFailThreshold: number().typeError('Must be a number').nullable(),
        reserveTime: number().typeError('Must be a number').nullable(),
    });
}

export interface BatteryTypeReference {
    id: string;
    displayName: string;
    manufacturer: string;
    model: string;
}

export interface DeviceBatteryString {
    id: string | null;
    wasEdited?: boolean;
    cellsPerString: string;
    installDate: Date | null;
    manufactureDate: Date | null;
    type: BatteryTypeReference | null;
}

export interface DeviceBatterySettings {
    maximumAllowedVoltage: string;
    minimumAllowedVoltage: string;
    quickTestCheckPercent: string;
    quickTestFailThreshold: string;
    reserveTime: string;
    strings: DeviceBatteryString[];
    removedStrings: string[];
}

export function createDefaultBatterySettings(): DeviceBatterySettings {
    return {
        strings: [],
        removedStrings: [],
        maximumAllowedVoltage: '',
        minimumAllowedVoltage: '',
        quickTestCheckPercent: '',
        quickTestFailThreshold: '',
        reserveTime: '',
    };
}

export function createDefaultBatteryString(): DeviceBatteryString {
    return {
        id: null,
        wasEdited: false,
        cellsPerString: '',
        type: null,
        installDate: new Date(),
        manufactureDate: null,
    };
}
