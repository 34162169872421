import React, { ReactElement, ReactNode, useCallback, useRef, useState } from 'react';

import classNames from 'classnames';
import isEqual from 'lodash.isequal';

import style from './style.module.css';

export interface SelectionListProps<T> {
    items: T[];
    selectedItems: T[];
    emptyListMessage?: string;
    onSelect: (item: T, index: number) => void;
    renderItem: (item: T) => ReactNode;
    renderItemSimple: (item: T) => string;
}

export function SelectionList<T>({
    items,
    selectedItems,
    emptyListMessage = 'No Results Found',
    onSelect,
    renderItem,
    renderItemSimple,
}: SelectionListProps<T>): ReactElement {
    const [keyboardSelected, setKeyboardSelected] = useState<number | null>(null);
    const [keyboardUsed, setKeyboardUsed] = useState<boolean>(false);
    const typeAhead = useRef<string>('');
    const typeAheadTimeout = useRef<NodeJS.Timeout | null>(null);
    const listElement = useRef<HTMLUListElement>(null);

    const handleFocus = useCallback(() => {
        setKeyboardSelected(index => (index != null ? index : 0));
    }, []);

    const handleBlur = useCallback(() => {
        setKeyboardUsed(false);
    }, []);

    const handleSelect = useCallback(
        (item: T, index: number) => {
            onSelect(item, index);
            typeAhead.current = '';
        },
        [onSelect]
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            setKeyboardUsed(true);
            switch (event.key) {
                case 'ArrowDown':
                    setKeyboardSelected(index => {
                        if (index == null) {
                            return 0;
                        }
                        if (index >= items.length - 1) {
                            return items.length - 1;
                        }
                        return index + 1;
                    });
                    break;
                case 'ArrowUp':
                    setKeyboardSelected(index => {
                        if (index == null) {
                            return 0;
                        }
                        if (index <= 0) {
                            return 0;
                        }
                        return index - 1;
                    });
                    break;
                case 'Home':
                    setKeyboardSelected(() => {
                        return 0;
                    });
                    break;
                case 'End':
                    setKeyboardSelected(() => {
                        return items.length - 1;
                    });
                    break;
                case 'Enter':
                    if (!event.ctrlKey && keyboardSelected != null) {
                        handleSelect(items[keyboardSelected], keyboardSelected);
                    }
                    break;
                default:
                    if (event.key.length === 1) {
                        typeAhead.current += event.key;
                        const index = items.findIndex(item =>
                            lookAheadMatch(renderItemSimple(item), typeAhead.current)
                        );
                        if (index >= 0) {
                            setKeyboardSelected(index);
                        }
                        // allow the type-ahead text to clear after a moment of inactivity
                        if (typeAheadTimeout.current) {
                            clearTimeout(typeAheadTimeout.current);
                        }
                        typeAheadTimeout.current = setTimeout(() => {
                            typeAhead.current = '';
                            typeAheadTimeout.current = null;
                        }, 1000);
                    } else {
                        typeAhead.current = '';
                    }
                    return;
            }
            event.preventDefault();
        },
        [handleSelect, items, keyboardSelected, renderItemSimple]
    );

    return (
        <ul
            className={classNames(style.list, { [style.key_focus]: keyboardUsed })}
            ref={listElement}
            tabIndex={0}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            role='listbox'
        >
            {items.map((item, index) => {
                const isSelectedItem = selectedItems.some(selectedItem => isEqual(selectedItem, item));

                return (
                    <li
                        key={index}
                        onClick={() => handleSelect(item, index)}
                        role='option'
                        aria-selected={index === keyboardSelected ? true : undefined}
                        className={classNames(isSelectedItem ? style.selected_item : '', 'text-wrap')}
                        data-testid={isSelectedItem ? 'selectedItem' : 'unselectedItem'}
                    >
                        {renderItem(item)}
                    </li>
                );
            })}

            {items.length === 0 && (
                <li role='option' aria-selected={undefined}>
                    {emptyListMessage}
                </li>
            )}
        </ul>
    );
}

function lookAheadMatch(input: string, match: string): boolean {
    const inputSanitised = input.toLowerCase().replaceAll(/\s/g, '');
    const matchSanitised = match.toLowerCase().replaceAll(/\s/g, '');
    return inputSanitised.startsWith(matchSanitised);
}
