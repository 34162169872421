import React, { FC } from 'react';

import { FormArea, FormikTextField } from '@accesstel/pcm-ui';

export const GeneralSection: FC = () => {
    return (
        <FormArea label='General'>
            <FormikTextField name='name' required placeHolder='Name*' light autoFocus />
            <FormikTextField name='email' required placeHolder='Email*' light type='email' />
        </FormArea>
    );
};
