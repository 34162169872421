import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { CheckCircledOpenIcon, Link, ListRow, ListView, Tooltip } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import { FilterValueMap } from 'filters/common';
import { SiteAllFilterMap, StaticSiteFilterDefinitions } from 'filters/site/settings';
import humanizeDuration from 'humanize-duration';
import { Paths } from 'lib/routes';
import { encodeFilterParameters } from 'lib/table-filter';
import { createSortParams } from 'lib/table-sort';

import { SiteExtraFilters, SiteTableColumn, SiteTableColumnId } from '../../../../../filters/site';
import { SortDirection } from '../../../../../layouts';
import { intervalToHuman } from '../../common';
import { TimeRange, TimeRangeSearchParameter } from '../../settings';
import { LeastReliableSites$data, LeastReliableSites$key } from './__generated__/LeastReliableSites.graphql';

export interface LeastReliableSitesProps {
    fragmentRef: LeastReliableSites$key;
    interval: TimeRange;
}

export const LeastReliableSites: FC<LeastReliableSitesProps> = ({ fragmentRef, interval }) => {
    const data = useFragment(Fragment, fragmentRef);

    return (
        <div className='col-span-12 lg:col-span-4'>
            <ListView
                listDisplay='grid'
                title='Least reliable sites'
                subtitle={intervalToHuman(interval, {
                    lifetimeOverride: 'for all time',
                    prefix: 'for the last ',
                })}
                view='list'
                contentClassName='auto-rows-fr grid-rows-5'
            >
                {data.data.map((rowData, index) => (
                    <ReliabilityRow
                        index={index}
                        rowData={rowData}
                        key={`least-reliable-site-row-${index}`}
                        interval={interval}
                    />
                ))}

                {data.data.length === 0 && (
                    <div className='h-16 row-start-3 flex justify-center items-center'>
                        <IconWithStatus
                            icon={<CheckCircledOpenIcon />}
                            label='No incidents found'
                            iconClasses='text-pineRegular'
                        />
                    </div>
                )}
            </ListView>
            <Link
                to={{
                    pathname: Paths.ReportACPowerSiteList,
                    search: {
                        ...createSortParams(SiteTableColumn.ACReliability, SortDirection.Ascending),
                        ...encodeFilterParameters<SiteTableColumnId, FilterValueMap<SiteAllFilterMap>>(
                            {
                                [SiteExtraFilters.ACReliabilityTimeRange]: interval,
                            },
                            StaticSiteFilterDefinitions
                        ),
                    },
                }}
                className='text-coralRegular float-right cursor-pointer'
            >
                See more
            </Link>
        </div>
    );
};

const Fragment = graphql`
    fragment LeastReliableSites on PaginatedSites {
        data {
            id
            name
            acPower {
                reliability(timeRange: $timeRange, unit: Seconds) {
                    mttr {
                        current
                    }
                    mtbf {
                        current
                    }
                }
            }
        }
    }
`;

interface ReliabilityRowProps {
    index: number;
    rowData: LeastReliableSites$data['data'][number];
    interval: TimeRange;
}

const ReliabilityRow: FC<ReliabilityRowProps> = ({ index, rowData, interval }) => {
    const contentBody = (
        <div
            className='grid grid-cols-2 grid-rows-2 lg:grid-rows-3 xl:grid-rows-2 gap-x-4 w-full'
            data-testid='least-reliable-site-row'
        >
            <Tooltip content={rowData.name} overflowOnly>
                <div className='row-span-1 col-span-2 font-bold text-xl truncate'>{rowData.name}</div>
            </Tooltip>

            <div className='row-start-2 col-span-1 lg:col-span-2 xl:col-span-1 text-base'>
                <Tooltip content='Mean Time Between Failures'>
                    <span className='font-light'>
                        <>MTBF </>
                        <span className='font-bold'>
                            {rowData.acPower.reliability.mtbf.current
                                ? humanizeDuration(rowData.acPower.reliability.mtbf.current * 1000, {
                                      units: ['y', 'mo', 'd', 'h', 'm'],
                                      round: true,
                                      largest: 1,
                                  })
                                : '-'}
                        </span>
                    </span>
                </Tooltip>
            </div>

            <div className='row-start-2 lg:row-start-3 xl:row-start-2 col-span-1 lg:col-span-2 xl:col-span-1 text-base'>
                <Tooltip content='Mean Time To Recovery'>
                    <span className='font-light'>
                        MTTR{' '}
                        <span className='font-bold'>
                            {rowData.acPower.reliability.mttr.current
                                ? humanizeDuration(rowData.acPower.reliability.mttr.current * 1000, {
                                      units: ['y', 'mo', 'd', 'h', 'm'],
                                      round: true,
                                      largest: 1,
                                  })
                                : '-'}
                        </span>
                    </span>
                </Tooltip>
            </div>
        </div>
    );

    return (
        <ListRow
            label={`${index + 1}`}
            content={contentBody}
            link={{
                pathname: Paths.ReportACPowerSiteView,
                params: { siteId: rowData.id },
                search: {
                    [TimeRangeSearchParameter]: interval,
                },
            }}
        />
    );
};
