import { TestResultFailReasonOptions, TestResultStateOptions } from './components';
import { TestResultCauseOptions } from './components/TestResultCauseFilterUI';
import { TestResultCauseFilter, TestResultFailReasonFilter, TestResultStateFilter } from './types';

export function decodeTestResultStateFilter(value: string): TestResultStateFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = TestResultStateOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }
    return match;
}

export function decodeTestResultCauseFilter(value: string): TestResultCauseFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = TestResultCauseOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }
    return match;
}

export function decodeTestResultFailReasonFilter(value: string): TestResultFailReasonFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = TestResultFailReasonOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }
    return match;
}
