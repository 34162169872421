import { Environment, commitMutation } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import {
    BatteryTypeRemoveResponse,
    deleteBatteryTypeMutation,
} from './__generated__/deleteBatteryTypeMutation.graphql';

export function deleteBatteryTypes(
    batteryTypeIds: string[],
    environment: Environment
): Promise<BatteryTypeRemoveResponse> {
    return new Promise((resolve, reject) => {
        commitMutation<deleteBatteryTypeMutation>(environment, {
            mutation: DeleteMutation,
            variables: {
                ids: batteryTypeIds,
            },
            onError: () => {
                reject();
            },
            onCompleted: (response, err) => {
                if (err) {
                    reject();
                }

                if (response) {
                    resolve(response.removeBatteryTypes);
                }
            },
        });
    });
}

const DeleteMutation = graphql`
    mutation deleteBatteryTypeMutation($ids: [ID!]!) {
        removeBatteryTypes(ids: $ids)
    }
`;
