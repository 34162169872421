import React, { FC, useCallback, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { BarDataType, LabelFormatterType, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { FilterActionType } from 'filters/common';
import {
    DeviceExtraFilters,
    DeviceTableColumn,
    DeviceTableColumnId,
    deviceToFilterObject,
    useDeviceFilter,
} from 'filters/device';
import { TableActionType, TableLayout, useTableReducer } from 'layouts';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { IEnvironment } from 'relay-runtime';
import { DefaultTimeRange } from 'views/reports/ac-power/settings';

import { useConfirmationModal } from '../../../lib/confirmation';
import { Device, useDeviceColumnDefinitions } from '../../../lib/tables/device';
import { useDeviceTableQuery } from '../../../lib/tables/device/request';
import { colorPalette } from '../AssetManagement';
import { queries_GetAssetsDistributionQuery } from '../__generated__/queries_GetAssetsDistributionQuery.graphql';
import { getAssetsDistribution } from '../queries';
import { DevicesAllIdsQuery } from './__generated__/DevicesAllIdsQuery.graphql';
import { DevicesSearchQuery, DevicesSearchQuery$data } from './__generated__/DevicesSearchQuery.graphql';
import { deleteDevices, validateDevicesForDelete } from './lib/delete';
import { BaseTableColumns } from './settings';

type DeviceSearchResult = DevicesSearchQuery$data['devices']['data'][number];

const TableStorageKeyPrefix = 'device-table';

export const ManageDevices: FC = () => {
    const { hasAssetsWrite } = useUserPermissions();
    const environment = useRelayEnvironment();

    const [showModal, modalComponent] = useConfirmationModal();
    const { show: showToast } = useToast();

    const columnDefinitions = useDeviceColumnDefinitions();

    const [tableState, dispatchTableState] = useTableReducer<DeviceTableColumnId>({
        defaultSortColumn: DeviceTableColumn.Name,
        allColumns: columnDefinitions,
        defaultVisibleColumns: BaseTableColumns,
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useDeviceFilter({
        extraFilters: {
            [DeviceExtraFilters.ACReliabilityTimeRange]: DefaultTimeRange,
        },
    });
    const filterObject = useMemo(() => deviceToFilterObject(filters), [filters]);

    const { data: props, error, retry, isFetching, fetchTable } = useDeviceTableQuery(tableState, filters);

    const { data: distributionProps } = useQuery<queries_GetAssetsDistributionQuery>(
        getAssetsDistribution,
        { type: 'DeviceType' },
        { fetchPolicy: 'network-only' }
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<DevicesSearchQuery>(
                environment,
                graphql`
                    query DevicesSearchQuery($name: String = "", $pageSize: Int!) {
                        devices(search: $name, pageSize: $pageSize) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                { name: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => (result?.devices.data as DeviceSearchResult[]) ?? []);
        },
        [environment]
    );

    const distributionData: BarDataType[] =
        distributionProps?.assetDistribution?.distribution.map((group, i) => {
            let bgColor = undefined;
            if (colorPalette.length > i) {
                bgColor = colorPalette[i];
            }

            return {
                id: group.key,
                label: group.displayName ?? group.key,
                value: group.value,
                bgClass: bgColor,
            };
        }) ?? [];

    const labelFormatter: LabelFormatterType<BarDataType> = useCallback((label, data) => {
        if (label === null) {
            return '';
        }
        return `${data.value} ${label.toUpperCase()}`;
    }, []);

    const onSegmentClick = (id: string) => {
        dispatchFilters({
            type: FilterActionType.Apply,
            column: DeviceTableColumn.Type,
            value: [{ name: distributionData.find(d => d.id === id)!.label, id }],
        });
    };

    const handleDelete = useCallback(() => {
        async function requestDelete() {
            let title: string;
            let content: string;
            let successMessage: string;

            const problems = await validateDevicesForDelete(tableState.selectedItems, environment);

            if (problems.length === 1) {
                title = problems[0].title;
                content = problems[0].description;
            } else if (problems.length > 1) {
                title = 'Unable to delete device';
                content = 'The selected device(s) cannot be deleted';
            } else if (tableState.selectedItems.length === 1) {
                title = 'Delete Device?';
                content = 'Are you sure you want to delete the selected device?';
                successMessage = 'Deleted device';
            } else {
                title = 'Delete Devices?';
                content = 'Are you sure you want to delete the selected devices?';
                successMessage = `Deleted ${tableState.selectedItems.length} devices`;
            }

            showModal({
                title,
                content,
                buttons:
                    problems.length > 0
                        ? [
                              {
                                  id: 'cancel',
                                  label: 'Close',
                                  variant: 'white',
                              },
                          ]
                        : [
                              {
                                  id: 'confirm',
                                  label: 'Delete',
                                  variant: 'primary',
                              },
                              {
                                  id: 'cancel',
                                  label: 'Cancel',
                                  variant: 'white',
                              },
                          ],
                onResult: async result => {
                    if (result === 'cancel') {
                        return;
                    }

                    let ok = false;
                    try {
                        const result = await deleteDevices(tableState.selectedItems, environment);
                        switch (result) {
                            case 'Success':
                                showToast({
                                    text: successMessage,
                                    variant: 'info',
                                });
                                ok = true;
                                break;
                            case 'PartialSuccess':
                                showToast({
                                    text: `Unable to delete some devices`,
                                    variant: 'error',
                                });
                                ok = true;
                                break;
                            default:
                            case 'UnknownDevice':
                                showToast({
                                    text: `Unknown device`,
                                    variant: 'error',
                                });
                                break;
                        }
                    } catch (error) {
                        captureException(error, scope => {
                            scope.setTag('Function', 'Delete devices');
                            scope.setExtra('device IDs', tableState.selectedItems);
                            return scope;
                        });
                        showToast({
                            text: `Error deleting devices`,
                            variant: 'error',
                        });
                    }

                    if (ok) {
                        dispatchTableState({ type: TableActionType.SetSelection, selection: [] });
                    }

                    // Refresh the table
                    setTimeout(() => {
                        // FIXME: this delay is present as elasticsearch isn't waiting for the refresh on delete
                        retry();
                    }, 1000);
                },
            });
        }

        requestDelete().catch(error => {
            captureException(error, scope => {
                scope.setTag('Function', 'Validate devices for delete');
                scope.setExtra('device IDs', tableState.selectedItems);
                return scope;
            });
        });
    }, [dispatchTableState, environment, retry, showModal, showToast, tableState.selectedItems]);

    return (
        <>
            <TableLayout
                title='Device Management'
                columns={columnDefinitions}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={props?.devices.data ?? null}
                getRowId={row => row.id}
                isProcessing={!!props && isFetching}
                page={props?.devices.pageInfo.page}
                pageCount={props?.devices.pageInfo.total}
                overallCount={props?.overallDevices.total}
                resultCount={props?.devices.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Device'
                onSearch={handleSearch}
                renderSearchResultAsString={(item: DeviceSearchResult) => item.name}
                emptyMessage='There are no devices present'
                unit='Device'
                primaryAction={hasAssetsWrite ? 'Add new device' : undefined}
                primaryActionLink={hasAssetsWrite ? Paths.AddDevice : undefined}
                selection={hasAssetsWrite}
                onRequestAllIds={() => getAllDeviceIds(environment, filterObject)}
                getItemLink={getDeviceLink}
                selectionFooterActions={[
                    {
                        buttonText: `Delete selected ${tableState.selectedItems.length > 1 ? 'devices' : 'device'}`,
                        onClick: handleDelete,
                    },
                ]}
                barChartData={{
                    data: distributionData,
                    emptyLabel: 'No devices added',
                    labelFormatter,
                    onSegmentClick,
                }}
                exportEnabled
                exportFilename='devices'
                exportFetchData={options =>
                    fetchTable(options).then(results => results.flatMap(result => result.devices.data))
                }
            />
            {modalComponent}
        </>
    );
};

function getAllDeviceIds(environment: IEnvironment, filters: Record<string, unknown>): Promise<string[]> {
    const getAllDeviceIdsQuery = graphql`
        query DevicesAllIdsQuery($filters: DeviceFilter) {
            devices(onlyProvisioningStatuses: Active, pageSize: 10000, filters: $filters) {
                data {
                    id
                }
            }
        }
    `;

    return fetchQuery<DevicesAllIdsQuery>(environment, getAllDeviceIdsQuery, { filters })
        .toPromise()
        .then(data => data?.devices.data.map(device => device.id) ?? []);
}

function getDeviceLink(device: Device): string {
    return generatePath(Paths.EditDevice, { id: device.id });
}
