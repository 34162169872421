import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { AlertSeverity } from 'filters/alert';

import { ActivityLogType } from '../__generated__/HistoryContentQuery.graphql';
import { HistoryCategory, SelectCategoriesPaneProps } from './SelectCategoryPane';

type InsightSeverityPaneProps = SelectCategoriesPaneProps;

export const InsightSeverityPane: FC<InsightSeverityPaneProps> = ({
    current,
    onClearOrBack,
    onSubmit,
    hasPrevious,
    onClose,
    clearButton,
}) => {
    return (
        <MultipleSelect<AlertSeverity>
            title='Insight Severity'
            variant='standard'
            options={[
                AlertSeverity.Critical,
                AlertSeverity.Major,
                AlertSeverity.Minor,
                AlertSeverity.Warning,
                AlertSeverity.Indeterminate,
            ]}
            active={
                current
                    ? ((current as ActivityLogType[])
                          .map(humanizeInsightEvent)
                          .filter(type => type != null) as AlertSeverity[])
                    : []
            }
            renderItem={event => event}
            renderItemSimple={event => event}
            onSubmit={events =>
                onSubmit(
                    events.map(convertInsightEvent).filter(event => event != null) as ActivityLogType[],
                    HistoryCategory.Insight
                )
            }
            onClear={() => {
                onClearOrBack();
            }}
            showBack={hasPrevious}
            onClose={onClose}
            clearButton={clearButton}
        />
    );
};

function convertInsightEvent(severity: AlertSeverity): ActivityLogType | null {
    if (severity === AlertSeverity.Critical) {
        return 'AlertSeverityCritical';
    }
    if (severity === AlertSeverity.Major) {
        return 'AlertSeverityMajor';
    }
    if (severity === AlertSeverity.Minor) {
        return 'AlertSeverityMinor';
    }
    if (severity === AlertSeverity.Warning) {
        return 'AlertSeverityWarning';
    }
    if (severity === AlertSeverity.Indeterminate) {
        return 'AlertSeverityIndeterminate';
    }

    return null;
}

export function humanizeInsightEvent(event: ActivityLogType): AlertSeverity | null {
    if (event === 'AlertSeverityCritical') {
        return AlertSeverity.Critical;
    }
    if (event === 'AlertSeverityMajor') {
        return AlertSeverity.Major;
    }
    if (event === 'AlertSeverityMinor') {
        return AlertSeverity.Minor;
    }
    if (event === 'AlertSeverityWarning') {
        return AlertSeverity.Warning;
    }
    if (event === 'AlertSeverityIndeterminate') {
        return AlertSeverity.Indeterminate;
    }

    return null;
}
