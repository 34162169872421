import React, { FC } from 'react';

import { DevicePaneSkeleton } from '../../components/DevicePaneSkeleton';
import { DeviceLoadSkeleton } from './DeviceLoadSkeleton';

export const LoadSkeleton: FC = () => {
    return (
        <div className='grid grid-cols-2 gap-4'>
            <DevicePaneSkeleton>
                <DeviceLoadSkeleton />
            </DevicePaneSkeleton>
            <DevicePaneSkeleton>
                <DeviceLoadSkeleton />
            </DevicePaneSkeleton>
        </div>
    );
};
