import React, { FC } from 'react';

import { FilterContainer } from '@accesstel/pcm-ui';

import { Formik, FormikErrors } from 'formik';

import { ExportForm } from './ExportForm';
import { ExportFormSchema, ExportFormValues } from './form';

export interface ExportTablePaneProps {
    onExport: (filename: string, pageStart: number, pageEnd: number, allColumns: boolean) => void;
    onClose: () => void;

    defaultFilename: string;
    pages: number;
    currentPage: number;
}

export const ExportTablePane: FC<ExportTablePaneProps> = ({
    onExport,
    onClose,
    defaultFilename,
    pages,
    currentPage,
}) => {
    return (
        <Formik
            initialValues={
                {
                    filename: defaultFilename,
                    pageStart: 1,
                    pageEnd: pages,
                    allColumns: false,
                } as ExportFormValues
            }
            validationSchema={ExportFormSchema}
            validate={values => {
                const errors: FormikErrors<ExportFormValues> = {};

                if (values.pageStart > values.pageEnd) {
                    errors.pageStart = 'Page start must be less than page end';
                }

                if (values.pageStart < 1) {
                    errors.pageStart = 'Page start must be greater than 0';
                }

                if (values.pageEnd > pages) {
                    errors.pageEnd = `Page end must be less than or equal to ${pages}`;
                }

                return errors;
            }}
            onSubmit={values => {
                onExport(values.filename, values.pageStart, values.pageEnd, values.allColumns);
                onClose();
            }}
        >
            {({ submitForm }) => (
                <FilterContainer
                    title='Export'
                    hideClearButton
                    onClose={onClose}
                    onConfirmClick={submitForm}
                    primaryContent={<ExportForm pages={pages} currentPage={currentPage} />}
                />
            )}
        </Formik>
    );
};
