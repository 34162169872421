import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { TimeRangeFilter } from 'components/FilterTimeRangeSelect/common';
import { DateTime } from 'luxon';

import { AttributeFilter, RangeFilter } from './types';

/**
 *
 * @param range a string that represents range
 * @returns RangeFilter if the decode is successful, null otherwise
 */
export function decodeRangeFilter(value: string): RangeFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const pattern = /^\d+(\.\d+)?-\d+(\.\d+)?$/; // NOTE: int/float range separated by '-'
    if (!pattern.test(decodedValue)) {
        return null;
    }

    if (decodedValue.split('-').length !== 2) {
        return null;
    }

    const [minString, maxString] = decodedValue.split('-');

    if (!isFinite(Number(minString)) || !isFinite(Number(maxString))) {
        return null;
    }

    const min = Number(minString);
    const max = Number(maxString);

    if (min > max) {
        return null;
    }

    return {
        min,
        max,
    };
}

export function decodeTimeRangeFilter(value: string): TimeRangeFilter | null {
    const range = decodeRangeFilter(value);

    if (!range) {
        return null;
    }

    // FIXME: Currently only support custom time range
    return {
        type: 'custom',
        range,
    };
}

export function decodeDateRangeFilter(value: string): DateRangeFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    if (decodedValue.split('_').length !== 2) {
        return null;
    }

    const [dateFromString, dateToString] = decodedValue.split('_');

    const dateFrom = DateTime.fromISO(dateFromString);
    const dateTo = DateTime.fromISO(dateToString);

    if (!dateFrom.isValid || !dateTo.isValid) {
        return null;
    }

    if (dateFrom > dateTo) {
        return null;
    }

    // FIXME: Currently only support custom time range
    return {
        type: 'custom',
        range: [dateFrom.toJSDate(), dateTo.toJSDate()],
    };
}

export function decodeCustomAttributeFilter(value: string): AttributeFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return { value: decodedValue };
}
