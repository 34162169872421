import { object, string } from 'yup';

export const UserValidationSchema = object({
    name: string().trim().required('A name must be provided'),
    email: string().trim().required('A email address must be provided'),
    permissions: object({
        general: string().oneOf(['None', 'ReadOnly']).required(),
        assets: string().oneOf(['None', 'ReadOnly', 'ReadWrite']).required(),
        batteryHealthTasks: string().oneOf(['None', 'ReadOnly', 'ReadWrite']).required(),
        administration: string().oneOf(['None', 'Admin']).required(),
        audit: string().oneOf(['None', 'Audit']).required(),
    }),
});
