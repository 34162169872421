import { useCallback, useEffect, useState } from 'react';
import { fetchQuery, useFragment, useRelayEnvironment } from 'react-relay';
import { KeyType, KeyTypeData } from 'react-relay/relay-hooks/helpers';

import isEqual from 'lodash.isequal';
import { CacheConfig, FetchQueryFetchPolicy, GraphQLTaggedNode, OperationType } from 'relay-runtime';

export interface FetchOptions {
    fetchPolicy?: FetchQueryFetchPolicy;
    networkCacheConfig?: CacheConfig;
    skip?: boolean;
}

export interface RenderProps<T extends OperationType> {
    error: Error | null;
    data: T['response'] | null | undefined;
    retry: (cacheConfigOverride?: CacheConfig, options?: FetchOptions) => void;
    isFetching: boolean;
}

/**
 * NOTE: This is a drop in replacement to the useQuery hook from relay-hooks,
 * which should actually work with React 18
 * @param gqlQuery
 * @param variables
 * @param options
 * @returns
 */
export function useQuery<TOperationType extends OperationType = OperationType>(
    gqlQuery: GraphQLTaggedNode,
    variables: TOperationType['variables'],
    options?: FetchOptions
): RenderProps<TOperationType> {
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<TOperationType['response'] | null | undefined>(undefined);
    const [isFetching, setIsFetching] = useState(false);
    const environment = useRelayEnvironment();
    const [safeVariables, setSafeVariables] = useState<TOperationType['variables']>(variables);

    const retry = useCallback(
        (cacheConfigOverride?: CacheConfig, overrideOptions?: FetchOptions) => {
            let fetchOptions: FetchOptions = {
                ...options,
            };

            if (cacheConfigOverride) {
                fetchOptions.networkCacheConfig = cacheConfigOverride;
            }

            if (overrideOptions) {
                fetchOptions = {
                    ...fetchOptions,
                    ...overrideOptions,
                };
            }

            setError(null);
            setIsFetching(true);

            fetchQuery(environment, gqlQuery, safeVariables, fetchOptions).subscribe({
                error: (error: unknown) => {
                    if (error instanceof Error) {
                        setError(error);
                    } else {
                        setError(new Error(String(error)));
                    }
                    setData(null);
                },
                next: data => {
                    setData(data);
                    setError(null);
                },
                complete: () => {
                    setIsFetching(false);
                },
            });
        },
        [options, environment, gqlQuery, safeVariables]
    );

    // Update variables if they changed deeply
    useEffect(() => {
        if (!isEqual(variables, safeVariables)) {
            setSafeVariables(variables);
        }
    }, [safeVariables, variables]);

    // Re-request the data if variables or the query changed
    useEffect(() => {
        if (options?.skip) {
            return;
        }

        retry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gqlQuery, safeVariables, options?.skip]);

    return {
        data,
        error,
        retry,
        isFetching,
    };
}

type ReloadFunction<TOperationType extends OperationType> = (
    variables: TOperationType['variables'],
    options?: FetchOptions
) => Promise<void>;

/**
 * This hook allows for fragments to be used while allowing new data to be fetched.
 * This is useful if you want to pre-load a fragment, but allow changes to the
 * variables in fragments, or be updated with new data.
 *
 * @param fragmentInput The fragment to use
 * @param initialFragmentRef The fragment reference to use
 * @param fragmentReloadQuery The query to use to fetch new data
 * @param fragmentSelector A function to select the new fragment reference from the query data.
 *
 * @returns A tuple containing the fragment data and a reload function
 */
export function useReloadableFragment<TKey extends KeyType, TOperationType extends OperationType = OperationType>(
    fragmentInput: GraphQLTaggedNode,
    initialFragmentRef: TKey,
    fragmentReloadQuery: GraphQLTaggedNode,
    fragmentSelector: (data: TOperationType['response']) => TKey | undefined
): [KeyTypeData<TKey>, ReloadFunction<TOperationType>] {
    const environment = useRelayEnvironment();
    const [fragmentHost, setFragmentHost] = useState<TKey>(initialFragmentRef);
    const fragmentData = useFragment(fragmentInput, fragmentHost);

    const reload = useCallback(
        (variables: TOperationType['variables'], options?: FetchOptions) => {
            return new Promise<void>((resolve, reject) => {
                fetchQuery(environment, fragmentReloadQuery, variables, options).subscribe({
                    next: data => {
                        const fragmentHost = fragmentSelector(data);
                        if (fragmentHost) {
                            setFragmentHost(fragmentHost);
                        }
                        resolve();
                    },
                    error: (error: unknown) => {
                        reject(error);
                    },
                });
            });
        },
        [environment, fragmentReloadQuery, fragmentSelector]
    );

    return [fragmentData, reload];
}
