import { Duration } from 'luxon';

import { CauseOptions } from './components/CauseFilterUI';
import { StateOptions } from './components/StateFilterUI';
import {
    GeneratorRunReportCauseFilter,
    GeneratorRunReportStateFilter,
    GeneratorRunReportTotalRunTimeFilter,
    TextFilterFilterBase,
} from './types';

export function decodeStateFilter(value: string): GeneratorRunReportStateFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = StateOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}
export function decodeCauseFilter(value: string): GeneratorRunReportCauseFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = CauseOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeRuntimeFilter(value: string): GeneratorRunReportTotalRunTimeFilter | null {
    const separator = '-';

    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    if (!decodedValue.includes(separator)) {
        return null;
    }

    if (decodedValue.split(separator).length !== 2) {
        return null;
    }

    const [durationStartString, durationEndString] = decodedValue.split(separator);

    if (!Duration.fromISO(durationStartString).isValid || !Duration.fromISO(durationEndString).isValid) {
        return null;
    }

    const durationStart = Duration.fromISO(durationStartString);
    const durationEnd = Duration.fromISO(durationEndString);

    if (durationStart > durationEnd) {
        return null;
    }

    return {
        type: 'custom',
        range: [durationStart.toISO(), durationEnd.toISO()],
    };
}

export function decodeTextFilter(value: string): TextFilterFilterBase | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    if (decodedValue.endsWith('*')) {
        return {
            type: 'special',
            value: decodedValue.slice(0, -1),
            wildcard: true,
        };
    }

    return {
        type: 'result',
        value: decodedValue,
    };
}
