import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { MenuItem, useExtendedNavigate, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { useUserPermissions } from 'lib/auth';
import { MenuItemGroup } from 'lib/menu';
import { Paths } from 'lib/routes';
import { useTimeRangeFromSearch } from 'views/reports/ac-power/common';
import { TimeRangeSearchParameter } from 'views/reports/ac-power/settings';

import { SiteViewPagePath } from '../../../sites/paths';
import { CompareURLParamTests } from '../test-compare/settings';
import { ViewBatteryTestResultCardAbortTestsMutation } from './__generated__/ViewBatteryTestResultCardAbortTestsMutation.graphql';
import { ViewBatteryTestResultCardCancelTestsMutation } from './__generated__/ViewBatteryTestResultCardCancelTestsMutation.graphql';
import { ViewBatteryTestResultCardIgnoreMutation } from './__generated__/ViewBatteryTestResultCardIgnoreMutation.graphql';
import { ViewBatteryTestResultCardReanalyseMutation } from './__generated__/ViewBatteryTestResultCardReanalyseMutation.graphql';
import { ViewBatteryTestResultCard_test$key } from './__generated__/ViewBatteryTestResultCard_test.graphql';
import { TestParameterView } from './components/TestParameterView';
import { TestParameterViewCombined } from './components/TestParameterViewCombined';
import { TestResultHeader } from './components/TestResultHeader';
import { DualPlaneResult } from './dual-plane';
import { Exporter } from './lib/test-exporter';
import { TestResultDisplay } from './single-plane';
import style from './style.module.css';

const AllowReanalyse = !!process.env.REACT_APP_FEATURE_REANALYSE;

interface Device {
    id: string;
    name: string;
}

export interface ViewBatteryTestResultCardProps {
    test: ViewBatteryTestResultCard_test$key;
    isCombinedView: boolean;
}

export const ViewBatteryTestResultCard: FC<ViewBatteryTestResultCardProps> = ({ test, isCombinedView }) => {
    const { show } = useToast();
    const navigate = useExtendedNavigate();
    const environment = useRelayEnvironment();
    const { hasAssetsRead, hasAssetsWrite, hasTasksRead, hasTasksWrite } = useUserPermissions();

    const exporter = useRef<Exporter | null>(null);

    const timeRange = useTimeRangeFromSearch();

    const [showTestDetails, setShowTestDetails] = useState(false);

    const props = useFragment<ViewBatteryTestResultCard_test$key>(ViewBatteryTestResultCardFragment, test);
    const isDualPlaneDevice = props.companion?.device != null;

    const devices = useMemo<Device[]>(() => {
        const devices: Device[] = [];

        devices.push({
            id: props.device.id,
            name: props.device.name,
        });

        if (isDualPlaneDevice) {
            devices.push({
                id: props.companion.device.id,
                name: props.companion.device.name,
            });
        }

        devices.sort((a, b) => a.name.localeCompare(b.name));

        return devices;
    }, [
        isDualPlaneDevice,
        props.companion?.device.id,
        props.companion?.device.name,
        props.device.id,
        props.device.name,
    ]);

    const [reanalyse] = useMutation<ViewBatteryTestResultCardReanalyseMutation>(graphql`
        mutation ViewBatteryTestResultCardReanalyseMutation($id: ID!) {
            reanalyseTest(id: $id)
        }
    `);

    const [ignore] = useMutation<ViewBatteryTestResultCardIgnoreMutation>(graphql`
        mutation ViewBatteryTestResultCardIgnoreMutation($deviceID: ID!) {
            ignoreUnplannedBatteryTest(device: $deviceID)
        }
    `);

    const [abortTests] = useMutation<ViewBatteryTestResultCardAbortTestsMutation>(graphql`
        mutation ViewBatteryTestResultCardAbortTestsMutation($testIDs: [ID!]!) {
            abortBatteryTestTests(ids: $testIDs)
        }
    `);

    const [cancelTests] = useMutation<ViewBatteryTestResultCardCancelTestsMutation>(graphql`
        mutation ViewBatteryTestResultCardCancelTestsMutation($testIDs: [ID!]!) {
            cancelBatteryTestTests(ids: $testIDs)
        }
    `);

    const doAbortTests = useCallback(() => {
        const testIds = [props.id];
        const isDualPlaneDevice = props.companion?.device != null;
        if (isCombinedView || isDualPlaneDevice) {
            const companionTestId = props.companion?.test.id;
            if (companionTestId) {
                testIds.push(companionTestId);
            }
        }
        abortTests({
            variables: { testIDs: testIds },
            onError(error) {
                captureException(error, scope => {
                    scope.setExtra('Tests', testIds.toString());
                    scope.setTag('Function', 'abortBatteryTestTests');

                    return scope;
                });
            },
            onCompleted(response, errors) {
                if (errors || !response) {
                    show({
                        text: 'Failed to abort test',
                        variant: 'error',
                    });
                } else {
                    show({
                        text: 'Test aborted',
                        variant: 'info',
                    });
                }
            },
        });
    }, [abortTests, isCombinedView, props.companion, props.id, show]);

    const doCancelTests = useCallback(() => {
        const testIds = [props.id];
        const isDualPlaneDevice = props.companion?.device != null;
        if (isCombinedView || isDualPlaneDevice) {
            const companionTestId = props.companion?.test.id;
            if (companionTestId) {
                testIds.push(companionTestId);
            }
        }

        cancelTests({
            variables: { testIDs: testIds },
            onError(error) {
                captureException(error, scope => {
                    scope.setExtra('Tests', testIds.toString());
                    scope.setTag('Function', 'cancelBatteryTestTests');

                    return scope;
                });
            },
            onCompleted(response, errors) {
                if (errors || !response) {
                    show({
                        text: 'Failed to cancel test',
                        variant: 'error',
                    });
                } else {
                    show({
                        text: 'Test cancelled',
                        variant: 'info',
                    });
                    navigate({ pathname: Paths.ViewTaskDetails, params: { id: props.task!.id } }, { replace: true });
                }
            },
        });
    }, [cancelTests, isCombinedView, navigate, props.companion, props.id, props.task, show]);

    const testState = props.state;
    const isComplete =
        testState === 'Passed' || testState === 'Failed' || testState === 'Inconclusive' || testState === 'Aborted';

    const otherTestState = props.companion?.test.state;
    const canReanalyseOther =
        !otherTestState ||
        otherTestState === 'Passed' ||
        otherTestState === 'Failed' ||
        otherTestState === 'Inconclusive' ||
        otherTestState === 'Aborted';

    const doExport = useCallback(() => {
        if (!props.id) {
            return;
        }

        if (!isComplete) {
            return;
        }

        if (exporter.current?.running) {
            return;
        }

        exporter.current = new Exporter(props.id, show, environment, isCombinedView);
        exporter.current.begin();
    }, [props.id, isComplete, show, environment, isCombinedView]);

    const doReanalyze = useCallback(
        (both?: boolean) => {
            if (!props.id) {
                return;
            }

            if (!isComplete) {
                return;
            }

            if (both) {
                if (!props.companion?.test?.id) {
                    return;
                }

                if (!canReanalyseOther) {
                    return;
                }
            }

            reanalyse({
                variables: { id: props.id },
                onCompleted({ reanalyseTest }) {
                    switch (reanalyseTest) {
                        case 'InvalidTest':
                            console.assert(false, 'Test was not found despite viewing test.');
                            break;
                        case 'TestStillRunning':
                            show({
                                text: 'Cannot analyse the test while it is running.',
                                variant: 'error',
                            });
                            break;
                        case 'TestError':
                            show({
                                text: 'Cannot analyse a test with errors.',
                                variant: 'error',
                            });
                            break;
                        case 'Processing':
                        case '%future added value':
                            show({
                                text: 'Test will be reanalysed',
                                variant: 'info',
                            });
                            break;
                    }
                },
                onError(error) {
                    captureException(error);
                },
            });

            if (both) {
                reanalyse({
                    variables: { id: props.companion!.test.id },
                    onCompleted({ reanalyseTest }) {
                        switch (reanalyseTest) {
                            case 'InvalidTest':
                                console.assert(false, 'Test was not found despite viewing test.');
                                break;
                            case 'TestStillRunning':
                                show({
                                    text: 'Cannot analyse the test while it is running.',
                                    variant: 'error',
                                });
                                break;
                            case 'TestError':
                                show({
                                    text: 'Cannot analyse a test with errors.',
                                    variant: 'error',
                                });
                                break;
                            case 'Processing':
                            case '%future added value':
                                break;
                        }
                    },
                    onError(error) {
                        captureException(error);
                    },
                });
            }
        },
        [props.id, props.companion, isComplete, reanalyse, canReanalyseOther, show]
    );

    const canExport = isComplete;

    const menuItems = useMemo<MenuItem[]>(() => {
        const items: MenuItem[] = [];

        items.push({
            name: 'Export metrics',
            onClick: doExport,
            disabled: !canExport,
        });

        if (isCombinedView) {
            items.push({
                name: 'Compare tests',
                onClick: () =>
                    navigate({
                        pathname: Paths.TestsCompare,
                        search: {
                            [CompareURLParamTests]: `${props.id},${props.companion!.test.id}`,
                        },
                    }),
            });

            // Add edit device items
            for (const device of devices) {
                items.push({
                    name: hasAssetsWrite ? `Edit ${device.name}` : `View ${device.name}`,
                    onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: device.id } }),
                    disabled: !hasAssetsRead,
                    group: MenuItemGroup.Assets,
                });
            }
            items.push({
                name: 'AC power report',
                onClick: () =>
                    navigate({
                        pathname: Paths.ReportACPowerSiteView,
                        params: { siteId: props.device.site.id },
                    }),
                group: MenuItemGroup.Reports,
            });
            items.push({
                name: 'AC incidents',
                onClick: () =>
                    navigate({
                        pathname: Paths.ReportACPowerSiteIncidentList,
                        params: { siteId: props.device.site.id },
                        search: {
                            [TimeRangeSearchParameter]: timeRange,
                        },
                    }),
                group: MenuItemGroup.Reports,
            });
        } else {
            items.push({
                name: 'Compare test',
                onClick: () =>
                    navigate({
                        pathname: Paths.TestsCompare,
                        search: {
                            [CompareURLParamTests]: props.id,
                        },
                    }),
            });

            // Add edit device items
            items.push({
                name: hasAssetsWrite ? 'Edit device' : 'View device',
                onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: props.device.id } }),
                disabled: !hasAssetsRead,
                group: MenuItemGroup.Assets,
            });
            items.push({
                name: 'AC power report',
                onClick: () =>
                    navigate({
                        pathname: Paths.ReportACPowerSiteViewDevice,
                        params: { siteId: props.device.site.id, deviceId: props.device.id },
                    }),
                group: MenuItemGroup.Reports,
            });
            items.push({
                name: 'AC power incidents',
                onClick: () =>
                    navigate({
                        pathname: Paths.ReportACPowerDeviceIncidentList,
                        params: { siteId: props.device.site.id, deviceId: props.device.id },
                        search: {
                            [TimeRangeSearchParameter]: timeRange,
                        },
                    }),
                group: MenuItemGroup.Reports,
            });
        }

        items.push({
            name: hasAssetsWrite ? 'Edit site' : 'View site',
            onClick: () => navigate({ pathname: Paths.EditSite, params: { id: props.device.site.id } }),
            disabled: !hasAssetsRead,
            group: MenuItemGroup.Assets,
        });

        items.push({
            name: 'View current state',
            onClick: () =>
                navigate({
                    pathname: Paths.SiteViewViewSiteDevicePage,
                    params: {
                        siteId: props.device.site.id,
                        deviceId: props.device.id,
                        page: SiteViewPagePath.Batteries,
                    },
                }),
        });

        if (AllowReanalyse && hasTasksRead) {
            if (isCombinedView) {
                items.push({
                    name: 'Reanalyse both',
                    onClick: () => doReanalyze(true),
                    disabled: !isComplete || !canReanalyseOther,
                });
            } else {
                items.push({
                    name: 'Reanalyse',
                    onClick: doReanalyze,
                    disabled: !isComplete || !canReanalyseOther,
                });
            }
        }

        if (!isCombinedView && !props.task && hasTasksWrite) {
            items.push({
                name: 'Ignore',
                onClick: () => {
                    ignore({
                        variables: { deviceID: props.device.id },
                        onError(error) {
                            captureException(error, scope => {
                                scope.setTag('Device', props.device.id);
                                scope.setTag('Function', 'ignoreUnplannedBatteryTest');
                                scope.setExtra('Test', props.id);

                                return scope;
                            });
                        },
                        onCompleted(response, errors) {
                            if (errors || !response) {
                                show({
                                    text: 'Failed to ignore test',
                                    variant: 'error',
                                });
                            } else {
                                show({
                                    text: 'Test ignored',
                                    variant: 'info',
                                });
                            }
                        },
                    });
                },
                disabled: props.state !== 'InProgress',
            });
        }

        const canAbortOrCancel = props.state === 'Scheduled' || props.state === 'InProgress';
        if (props.task && hasTasksWrite && canAbortOrCancel) {
            const isScheduled = props.state === 'Scheduled';
            const unit = isDualPlaneDevice ? 'Tests' : 'Test';
            items.push({
                name: isScheduled ? `Cancel ${unit}` : `Abort ${unit}`,
                onClick: isScheduled ? doCancelTests : doAbortTests,
            });
        }
        return items;
    }, [
        doExport,
        canExport,
        isCombinedView,
        hasAssetsWrite,
        hasAssetsRead,
        hasTasksRead,
        props.task,
        props.state,
        props.id,
        props.companion,
        props.device.site.id,
        props.device.id,
        hasTasksWrite,
        navigate,
        devices,
        timeRange,
        isComplete,
        canReanalyseOther,
        doReanalyze,
        ignore,
        show,
        isDualPlaneDevice,
        doCancelTests,
        doAbortTests,
    ]);

    return (
        <div className={classNames('bg-white', style.main_area)}>
            <div className={classNames('pt-8')}>
                <TestResultHeader
                    menuItems={menuItems}
                    test={props}
                    otherTest={isCombinedView ? props.companion?.test : null}
                />
                {showTestDetails &&
                    (isCombinedView ? (
                        <TestParameterViewCombined planeA={props} planeB={props.companion!.test} />
                    ) : (
                        <TestParameterView test={props} />
                    ))}
                <div className='flex justify-end px-8 font-normal hover:underline'>
                    <button
                        className='flex items-center space-x-2 text-sm'
                        onClick={() => setShowTestDetails(!showTestDetails)}
                    >
                        {showTestDetails ? 'Hide' : 'Show'} Details
                    </button>
                </div>
                <div className={style.header_horizontal_rule}></div>
            </div>
            <div className='p-8 space-y-8'>
                {isCombinedView ? (
                    <DualPlaneResult mainTest={props!} otherTest={props.companion!.test!} />
                ) : (
                    <TestResultDisplay test={props!} />
                )}
            </div>
        </div>
    );
};

const ViewBatteryTestResultCardFragment = graphql`
    fragment ViewBatteryTestResultCard_test on DeviceBatteryTestResults
    @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
        id
        task {
            id
            name
        }
        commencedTime
        completedTime
        abortedTime
        state
        ...TestParameterView_test
        ...TestParameterViewCombined_test
        ...MetricsView_test @arguments(unitTemperature: $unitTemperature)
        ...DataDisplay_test
        ...BlocHealthTable_test
        cause
        ...TestResultDisplay_test @arguments(unitTemperature: $unitTemperature)
        ...DualPlaneResult_test @arguments(unitTemperature: $unitTemperature)
        ...TestResultHeader_test

        device {
            id
            name
            type {
                displayName
            }
            site {
                id
                name
                address {
                    address
                    state
                }
            }
        }
        companion {
            device {
                id
                name
            }

            test {
                id
                state
                ...DualPlaneResult_test @arguments(unitTemperature: $unitTemperature)
                ...TestResultHeader_test
                ...TestParameterViewCombined_test
            }
        }
    }
`;
