/**
 * @generated SignedSource<<3b9cdfac013c87e4e91a266261c74166>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LoadChartQuery$variables = {
  begin?: string | null;
  end?: string | null;
  id: string;
  interval?: string | null;
};
export type LoadChartQuery$data = {
  readonly generatorRunReport: {
    readonly aggregatedSiteLoad: ReadonlyArray<{
      readonly offset: string;
      readonly timestamp: string;
      readonly value: number | null;
    }> | null;
  } | null;
};
export type LoadChartQuery = {
  response: LoadChartQuery$data;
  variables: LoadChartQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "begin",
      "variableName": "begin"
    },
    {
      "kind": "Variable",
      "name": "end",
      "variableName": "end"
    },
    {
      "kind": "Variable",
      "name": "interval",
      "variableName": "interval"
    },
    {
      "kind": "Literal",
      "name": "unit",
      "value": "Amps"
    }
  ],
  "concreteType": "TimestampMetricWithOffset",
  "kind": "LinkedField",
  "name": "aggregatedSiteLoad",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "GeneratorRunReport",
        "kind": "LinkedField",
        "name": "generatorRunReport",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "GeneratorRunReport",
        "kind": "LinkedField",
        "name": "generatorRunReport",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0af174dc7930b6bfafe9a823363f8390",
    "id": null,
    "metadata": {},
    "name": "LoadChartQuery",
    "operationKind": "query",
    "text": "query LoadChartQuery(\n  $id: ID!\n  $interval: Duration\n  $begin: Duration\n  $end: Duration\n) {\n  generatorRunReport(id: $id) {\n    aggregatedSiteLoad(unit: Amps, interval: $interval, begin: $begin, end: $end) {\n      timestamp\n      offset\n      value\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd304bc51662a41543d08308ac475dce";

export default node;
