/**
 * @generated SignedSource<<8e97c3eb63f5bcf53cef7c2299e4e02b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GeneratorReportSerialNumberFilterUI_searchQuery$variables = {
  input: string;
  maxResults: number;
};
export type GeneratorReportSerialNumberFilterUI_searchQuery$data = {
  readonly generatorRunReports: {
    readonly data: ReadonlyArray<{
      readonly generatorInfo: {
        readonly serialNumber: string | null;
      };
      readonly id: string;
    }>;
  };
};
export type GeneratorReportSerialNumberFilterUI_searchQuery = {
  response: GeneratorReportSerialNumberFilterUI_searchQuery$data;
  variables: GeneratorReportSerialNumberFilterUI_searchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxResults"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "value",
                "variableName": "input"
              }
            ],
            "kind": "ObjectValue",
            "name": "serialNumber"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "maxResults"
      }
    ],
    "concreteType": "PaginatedGeneratorRunReport",
    "kind": "LinkedField",
    "name": "generatorRunReports",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneratorRunReport",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneratorRunReportGeneratorInfo",
            "kind": "LinkedField",
            "name": "generatorInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serialNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneratorReportSerialNumberFilterUI_searchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneratorReportSerialNumberFilterUI_searchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "25417d1089c8b110fdf70531fb0b0ae0",
    "id": null,
    "metadata": {},
    "name": "GeneratorReportSerialNumberFilterUI_searchQuery",
    "operationKind": "query",
    "text": "query GeneratorReportSerialNumberFilterUI_searchQuery(\n  $input: String!\n  $maxResults: Int!\n) {\n  generatorRunReports(filters: {serialNumber: {value: $input}}, pageSize: $maxResults) {\n    data {\n      id\n      generatorInfo {\n        serialNumber\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28bf3dfbb6c966c6b2c624b8187be364";

export default node;
