import React, { FC } from 'react';

import { DeviceACPower_device$data, DeviceHealth } from '../__generated__/DeviceACPower_device.graphql';
import { FeedTile } from './FeedTile';

interface FeedDisplayProps {
    feeds: DeviceACPower_device$data['acPower']['feeds']['data'];
    health: DeviceHealth | null;
    deviceId: string;
    hiddenFeeds: string[];
    toggleFeedVisibility: (feedId: string) => void;
}

export const FeedDisplay: FC<FeedDisplayProps> = ({ feeds, health, deviceId, hiddenFeeds, toggleFeedVisibility }) => {
    if (health !== 'Offline') {
        return (
            <>
                {feeds.map(feed => (
                    <FeedTile
                        key={feed.label}
                        feed={feed}
                        hidden={hiddenFeeds.includes(feed.label)}
                        onToggleHidden={toggleFeedVisibility}
                        deviceId={deviceId}
                    />
                ))}
                {feeds.length === 0 && (
                    <>
                        {/* This keeps the size consistent with displays with feeds */}
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        <div className='col-span-4 grid place-items-center'>
                            <div className=''>
                                <span>Feed information is unavailable</span>
                            </div>
                        </div>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    </>
                )}
            </>
        );
    } else {
        return (
            <>
                <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                <div className='col-span-4 grid place-items-center text-center'>
                    <span>Feed information is unavailable when device is offline</span>
                </div>
                <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
            </>
        );
    }
};
