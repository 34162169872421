import get from 'lodash.get';
import { DateTime, Duration } from 'luxon';

import { exportAsCsv } from '../../lib/csv-export';
import { ColumnWithId } from './TableLayout';

type RowType = string[];
function extractToRowData<DataType>(data: readonly DataType[], columns: ColumnWithId<string, DataType>[]): RowType[] {
    return data.map(item => {
        return columns.map(column => {
            let value: unknown;
            if ('accessorKey' in column) {
                value = get(item, column.accessorKey);
            } else if ('accessorFn' in column) {
                value = column.accessorFn(item, 0);
            }

            if (column.exportValue) {
                value = column.exportValue({
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    value: value as any,
                    row: item,
                });
            }

            if (value == null) {
                return '';
            }

            if (DateTime.isDateTime(value)) {
                // Use excel's suitable date time format
                return value.toFormat('yyyy-MM-dd HH:mm:ss');
            }
            if (value instanceof Date) {
                // Use excel's suitable date time format
                return DateTime.fromJSDate(value).toFormat('yyyy-MM-dd HH:mm:ss');
            }

            if (Duration.isDuration(value)) {
                // Use excel's suitable date time format
                return value.toFormat('hh:mm:ss');
            }

            if (typeof value === 'boolean') {
                return value ? 'Yes' : 'No';
            }

            if (typeof value !== 'string') {
                value = JSON.stringify(value);
            }

            return value as string;
        });
    });
}

export function exportDataToCsv<DataType>(
    filename: string,
    data: readonly DataType[],
    columns: ColumnWithId<string, DataType>[]
): void {
    const rows = extractToRowData(data, columns);

    const headers = columns.map(column => {
        if (column.exportHeader) {
            if (typeof column.exportHeader === 'string') {
                return column.exportHeader;
            } else {
                return column.exportHeader();
            }
        }

        const header = column.header;

        if (typeof header === 'string') {
            return header;
        }

        // Function headers not supported
        return '';
    });

    exportAsCsv(filename, headers, rows);
}
