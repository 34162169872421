import {
    ACPowerIncidentAffectedFeedOptions,
    ACPowerIncidentIsSignificantOnlyFilterOptions,
    IncidentTypeFilterOptions,
} from './components';
import { AffectedFeedFilter, IncidentIsSignificantOnlyFilter, IncidentTypeFilter } from './types';

export function decodeIncidentTypeFilter(value: string): IncidentTypeFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = IncidentTypeFilterOptions.find(option => option.id === value);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeIncidentAffectedFeedFilter(value: string): AffectedFeedFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const feedId = Number(decodedValue);
    if (!Number.isFinite(feedId)) {
        return null;
    }

    const match = ACPowerIncidentAffectedFeedOptions.find(option => option.id === feedId);
    if (!match) {
        return null;
    }

    return match;
}

export function decodeIncidentIsSignificantOnlyFilter(value: string): IncidentIsSignificantOnlyFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    const match = ACPowerIncidentIsSignificantOnlyFilterOptions.find(option => `${option.value}` === value);
    if (!match) {
        return null;
    }

    return match;
}
