import React, { FC, useEffect, useState } from 'react';
import { Environment, PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery } from 'react-relay';

import {
    Button,
    ExtendedNavigateFunction,
    Menu,
    PageHeading,
    ThemedLink,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNamesBind from 'classnames/bind';
import { useUserPermissions } from 'lib/auth';
import { Breakpoint, useBreakpoint } from 'lib/breakpoints';
import { Paths } from 'lib/routes';

import { ActiveDevicesCount } from '../../../../components';
import { useDebouncer } from '../../../../lib/debounce';
import { getGlobalEnvironment } from '../../../../lib/environment';
import { getColumnCountForWindowSize, getLargeSectionLoadSize, getSmallSectionLoadSize } from '../../lib/grid-layout';
import style from '../../lib/overview-layout.module.css';
import { BatteryHealthTaskOverviewContentQuery } from './__generated__/BatteryHealthTaskOverviewContentQuery.graphql';
import { CompletedTests, ExternalDischarges, InProgressTestsAndDischarges, ScheduledTests } from './sections';

const classNames = classNamesBind.bind(style);

export interface BatteryHealthTaskOverviewContentProps {
    preloadedQuery: PreloadedQuery<BatteryHealthTaskOverviewContentQuery>;
    reload: (smallSize: number, largeSize: number) => void;
}

export const BatteryHealthTaskOverviewContent: FC<BatteryHealthTaskOverviewContentProps> = ({
    preloadedQuery,
    reload,
}) => {
    const { hasTasksWrite } = useUserPermissions();
    const breakpoint = useBreakpoint();
    const navigate = useExtendedNavigate();

    const data = usePreloadedQuery(ContentQuery, preloadedQuery);

    const [smallSectionSize, setSmallSectionSize] = useState(() =>
        getSmallSectionLoadSize(getColumnCountForWindowSize(window.innerWidth))
    );
    const [largeSectionSize, setLargeSectionSize] = useState(() =>
        getLargeSectionLoadSize(getColumnCountForWindowSize(window.innerWidth))
    );

    const reloadWithDebounce = useDebouncer(reload, 500);

    useEffect(() => {
        const handleWindowResize = () => {
            const visibleColumns = getColumnCountForWindowSize(window.innerWidth);

            const newSmallSize = getSmallSectionLoadSize(visibleColumns);
            const newLargeSize = getLargeSectionLoadSize(visibleColumns);

            let needsReload = false;

            setSmallSectionSize(oldSmallSize => {
                if (newSmallSize > oldSmallSize) {
                    needsReload = true;
                }
                return newSmallSize;
            });
            setLargeSectionSize(oldLargeSize => {
                if (newLargeSize > oldLargeSize) {
                    needsReload = true;
                }
                return newLargeSize;
            });

            if (needsReload) {
                // Wait until the user has stopped resizing the window
                reloadWithDebounce(newSmallSize, newLargeSize);
            }
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [reloadWithDebounce]);

    return (
        <div className='space-y-6'>
            <div>
                <div className='flex justify-between items-center'>
                    <PageHeading value='Battery Health Tests' />
                    {getActions(breakpoint, navigate, hasTasksWrite)}
                </div>
                <ActiveDevicesCount valueSuffix='Active Devices' fragmentRef={data} />

                <div className={classNames('card_layout_container')}>
                    <ScheduledTests tasks={data.scheduledTasks} maxItems={smallSectionSize} />

                    <InProgressTestsAndDischarges
                        tasks={data.inprogressTasks}
                        externalDischarges={data.inprogressTests}
                    />
                    <CompletedTests tasks={data.completedTasks} maxItems={largeSectionSize} />
                    <ExternalDischarges tasks={data.unplannedTests} maxItems={smallSectionSize} />
                    <div className='text-eggplantRegular font-normal'>
                        <ThemedLink to={Paths.TestsCancelledAborted}>See cancelled tests</ThemedLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ContentQuery = graphql`
    query BatteryHealthTaskOverviewContentQuery($smallSectionSize: Int!, $largeSectionSize: Int!) {
        scheduledTasks: tasks(
            pageSize: $smallSectionSize
            type: BatteryTest
            state: "Scheduled"
            orderBy: { field: Date, dir: Asc }
        ) {
            ...ScheduledTests_tasks
        }
        inprogressTasks: tasks(type: BatteryTest, state: "InProgress", orderBy: { field: Date, dir: Asc }) {
            ...InProgressTestsAndDischarges_tasks
        }
        inprogressTests: unplannedTests(orderBy: { field: StartTime, dir: Desc }, filters: { state: InProgress }) {
            ...InProgressTestsAndDischarges_tests
        }
        completedTasks: tasks(
            pageSize: $largeSectionSize
            type: BatteryTest
            state: ["Completed", "Aborted"]
            orderBy: { field: Date, dir: Desc }
        ) {
            ...CompletedTests_tasks
        }
        unplannedTests(pageSize: $smallSectionSize, orderBy: { field: StartTime, dir: Desc }) {
            ...ExternalDischarges_tasks
        }
        ...ActiveDevicesCount
    }
`;

export function loadBatteryTasksQuery(environment?: Environment) {
    const columnCount = getColumnCountForWindowSize(window.innerWidth);
    const smallSectionSize = getSmallSectionLoadSize(columnCount);
    const largeSectionSize = getLargeSectionLoadSize(columnCount);

    return loadQuery(
        environment ?? getGlobalEnvironment(),
        ContentQuery,
        {
            smallSectionSize: smallSectionSize,
            largeSectionSize: largeSectionSize,
        },
        { fetchPolicy: 'store-and-network' }
    );
}

export function fetchBatteryTasksQuery(environment: Environment) {
    const columnCount = getColumnCountForWindowSize(window.innerWidth);
    const smallSectionSize = getSmallSectionLoadSize(columnCount);
    const largeSectionSize = getLargeSectionLoadSize(columnCount);

    return fetchQuery(
        environment,
        ContentQuery,
        {
            smallSectionSize: smallSectionSize,
            largeSectionSize: largeSectionSize,
        },
        { fetchPolicy: 'network-only' }
    );
}

function getActions(breakpoint: Breakpoint, navigate: ExtendedNavigateFunction, hasTasksWrite: boolean) {
    switch (breakpoint) {
        case Breakpoint.ExtraSmall:
        case Breakpoint.Small:
        case Breakpoint.Medium:
            return (
                <Menu
                    id='breakpoint-menu'
                    menuItems={[
                        {
                            name: 'Compare tests',
                            onClick: () => navigate({ pathname: Paths.TestsCompare }),
                        },
                        {
                            name: 'Schedule a test',
                            onClick: () => navigate({ pathname: Paths.TestsScheduleTest }),
                            disabled: !hasTasksWrite,
                        },
                    ]}
                />
            );
        default:
            return (
                <div className='flex space-x-2'>
                    <Button buttonText='Compare tests' size='small' to={Paths.TestsCompare} variant='white' />
                    {hasTasksWrite && <Button buttonText='Schedule a test' size='small' to={Paths.TestsScheduleTest} />}
                </div>
            );
    }
}
