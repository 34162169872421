import React, { FC, ReactNode, useState } from 'react';

import { Menu, MenuItem } from '@accesstel/pcm-ui';

import style from './style.module.css';

export interface ExpandableCardHeaderProps {
    title: string;
    subtitle?: string;
    statusIcon?: ReactNode;

    menuItems?: MenuItem[];

    /**
     * Content that is always displayed below the title
     */
    mainContent?: ReactNode;

    /**
     * Content that is hidden by default.
     * Displayed when the user clicks the "Show Details" button.
     */
    detailContent?: ReactNode;

    className?: string;
}

export const ExpandableCardHeader: FC<ExpandableCardHeaderProps> = ({
    title,
    subtitle,
    statusIcon,
    menuItems,
    mainContent,
    detailContent,
    className,
}) => {
    const [isExpanded, setExpanded] = useState(false);

    const uniqueMenuGroups = new Set<string>(menuItems?.filter(item => item.group).map(item => item.group as string));
    const groupDefinition = Array.from(uniqueMenuGroups).map(group => ({
        key: group,
        title: group,
    }));

    return (
        <div className={className}>
            <div>
                <div className='flex flex-row justify-between items-start relative'>
                    <div>
                        <div className='font-normal text-3xl'>{title}</div>
                        <div className='font-light'>{subtitle}</div>
                    </div>
                    <div className='flex flex-row space-x-2'>
                        {statusIcon}
                        {menuItems && <Menu id='card-menu' groups={groupDefinition} menuItems={menuItems} />}
                    </div>
                </div>
                {mainContent && <div className='flex gap-6 mt-4'>{mainContent}</div>}
            </div>
            {detailContent && (
                <>
                    {isExpanded && detailContent}
                    <div className='flex justify-end font-normal'>
                        <button
                            className='flex items-center space-x-2 text-sm hover:underline'
                            onClick={() => setExpanded(!isExpanded)}
                        >
                            {isExpanded ? 'Hide' : 'Show'} Details
                        </button>
                    </div>
                </>
            )}
            {!detailContent && <>&nbsp;</>}

            <div className={style.header_horizontal_rule}></div>
        </div>
    );
};
