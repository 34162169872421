import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { AlertSeverity, AlertSeverityFilter } from '../types';

export type AlertSeverityFilterUIProps = CommonFilterProps<AlertSeverityFilter[]>;

export const AlertSeverityOptions: AlertSeverityFilter[] = [
    { id: AlertSeverity.Critical, value: 'Critical' },
    { id: AlertSeverity.Major, value: 'Major' },
    { id: AlertSeverity.Minor, value: 'Minor' },
    { id: AlertSeverity.Warning, value: 'Warning' },
    { id: AlertSeverity.Indeterminate, value: 'Indeterminate' },
];

export const AlertSeverityFilterUI: FC<AlertSeverityFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    return (
        <MultipleSelect
            options={AlertSeverityOptions}
            active={current}
            title='Filter by severity'
            renderItem={item => item.value}
            renderItemSimple={item => item.value}
            areItemsEqual={(itemA, itemB) => itemA.value === itemB.value}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
