import React, { FC } from 'react';

import { FilterSingleSelect } from 'components/FilterSingleSelect';
import { CommonFilterProps } from 'filters/common';

import { IncidentIsSignificantOnlyFilter } from '../types';

export type ACPowerIncidentIsSignificantOnlyFilterUIProps = CommonFilterProps<IncidentIsSignificantOnlyFilter>;

export const ACPowerIncidentIsSignificantOnlyFilterOptions: IncidentIsSignificantOnlyFilter[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

export const ACPowerIncidentIsSignificantOnlyFilterUI: FC<ACPowerIncidentIsSignificantOnlyFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    return (
        <FilterSingleSelect
            options={ACPowerIncidentIsSignificantOnlyFilterOptions}
            active={current ?? undefined}
            title='Filter by is significant'
            renderItem={item => item.label}
            onClear={onClearOrBack}
            onSubmit={item => onSubmit(item)}
            onClose={onClose}
            showBack={true}
        />
    );
};
