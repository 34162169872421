/**
 * @generated SignedSource<<acfc4260c7549cc18ccc9f013f3adf10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AttributeLocation = "Battery" | "Device" | "Site" | "%future added value";
export type ProvisioningStatus = "Active" | "Deleted" | "%future added value";
export type attributeNameSuggestQuery$variables = {
  limit?: number | null;
  name: string;
  only?: ReadonlyArray<AttributeLocation> | null;
  status?: ReadonlyArray<ProvisioningStatus> | null;
};
export type attributeNameSuggestQuery$data = {
  readonly attributeNames: ReadonlyArray<string>;
};
export type attributeNameSuggestQuery = {
  response: attributeNameSuggestQuery$data;
  variables: attributeNameSuggestQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "only"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "only",
        "variableName": "only"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "kind": "ScalarField",
    "name": "attributeNames",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "attributeNameSuggestQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "attributeNameSuggestQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b2e1eb5162cf93b88b1c4ab5f088c053",
    "id": null,
    "metadata": {},
    "name": "attributeNameSuggestQuery",
    "operationKind": "query",
    "text": "query attributeNameSuggestQuery(\n  $name: String!\n  $only: [AttributeLocation!]\n  $status: [ProvisioningStatus!]\n  $limit: Int\n) {\n  attributeNames(search: $name, only: $only, status: $status, limit: $limit)\n}\n"
  }
};
})();

(node as any).hash = "ed3b1fee5799aa88adfadbbaed4f5368";

export default node;
