import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { BarChart, CartesianChartDataType, ChartArea, Domain, LoadableContentArea, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { numberToLocaleString } from 'lib/numberFormatters';

import { ChartAspectRatio } from '../../settings';
import { BatteryTimeRemainingHisto_timeRemainingHistogram$key } from './__generated__/BatteryTimeRemainingHisto_timeRemainingHistogram.graphql';
import style from './common.module.css';

export interface BatteryTimeRemainingHistoProps {
    fragmentRef: BatteryTimeRemainingHisto_timeRemainingHistogram$key | null;
}

export const BatteryTimeRemainingHisto: FC<BatteryTimeRemainingHistoProps> = ({ fragmentRef }) => {
    const data = useFragment(Fragment, fragmentRef);

    return (
        <ChartArea title='Battery time remaining across network' className={style.chart}>
            <LoadableContentArea
                data={data}
                className={style.doughnut}
                render={result => {
                    const chartData: CartesianChartDataType<string>[] = result.buckets.map(bucket => ({
                        key: `${numberToLocaleString(bucket.rangeLow, 0)} hour${bucket.rangeLow !== 1 ? 's' : ''}`,
                        tooltip: `${numberToLocaleString(bucket.rangeLow, 1)} to ${numberToLocaleString(
                            bucket.rangeHigh,
                            1
                        )} Hours`,
                        value: bucket.count,
                    }));

                    let maxIndexWithValue = 0;
                    chartData.forEach((data, index) => {
                        if (data.value !== 0) {
                            maxIndexWithValue = index;
                        }
                    });

                    const filteredChartData = maxIndexWithValue === 0 ? [] : chartData.slice(0, maxIndexWithValue + 1);

                    const maxValue =
                        filteredChartData.length > 0
                            ? filteredChartData.reduce((prev, curr) => {
                                  if (curr.value != null) {
                                      return prev.value != null && prev.value > curr.value ? prev : curr;
                                  } else {
                                      return prev;
                                  }
                              })?.value
                            : 10;
                    const yDomain: Domain<number> | undefined = maxValue && maxValue < 11 ? [0, 10] : undefined;

                    return (
                        <BarChart
                            series={
                                filteredChartData.length
                                    ? [
                                          {
                                              id: 'tr',
                                              name: 'Time Remaining',
                                              color: Theme.eggplantExtraLight,
                                              data: filteredChartData,
                                          },
                                      ]
                                    : []
                            }
                            yTicks={11}
                            yDomain={yDomain}
                            formatTooltipValue={value => `${value} devices`}
                            aspectRatio={ChartAspectRatio}
                        />
                    );
                }}
            />
        </ChartArea>
    );
};

export const Fragment = graphql`
    fragment BatteryTimeRemainingHisto_timeRemainingHistogram on Histogram {
        buckets {
            rangeLow
            rangeHigh
            count
        }
        countMin
        countMax
        rangeLow
        rangeHigh
    }
`;
