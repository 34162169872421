import React, { FC } from 'react';

import { DevicePaneSkeleton } from '../../components/DevicePaneSkeleton';
import { DeviceOverviewSkeleton } from './DeviceOverviewSkeleton';

export const OverviewSkeleton: FC = () => {
    return (
        <div className='grid grid-cols-2 gap-4'>
            <DevicePaneSkeleton>
                <DeviceOverviewSkeleton />
            </DevicePaneSkeleton>
            <DevicePaneSkeleton>
                <DeviceOverviewSkeleton />
            </DevicePaneSkeleton>
        </div>
    );
};
