/**
 * @generated SignedSource<<407301edb7fd1e0a3711f38f0f1a32ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
export type SiteViewLayoutContentQuery$variables = {
  id: string;
};
export type SiteViewLayoutContentQuery$data = {
  readonly site: {
    readonly address: {
      readonly address: string | null;
      readonly state: string;
    };
    readonly devices: {
      readonly data: ReadonlyArray<{
        readonly dualPlaneCompanion: {
          readonly device: {
            readonly id: string;
            readonly name: string;
          } | null;
        } | null;
        readonly id: string;
        readonly name: string;
        readonly type: {
          readonly category: DeviceCategory;
        };
      }>;
    };
    readonly name: string;
  } | null;
};
export type SiteViewLayoutContentQuery = {
  response: SiteViewLayoutContentQuery$data;
  variables: SiteViewLayoutContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "category": [
        "PowerController",
        "Generator",
        "Gateway"
      ]
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DualPlaneCompanion",
  "kind": "LinkedField",
  "name": "dualPlaneCompanion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteViewLayoutContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "devices(filters:{\"category\":[\"PowerController\",\"Generator\",\"Gateway\"]})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteViewLayoutContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "devices(filters:{\"category\":[\"PowerController\",\"Generator\",\"Gateway\"]})"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14363e3ef880abf694a0ad6c02a9250b",
    "id": null,
    "metadata": {},
    "name": "SiteViewLayoutContentQuery",
    "operationKind": "query",
    "text": "query SiteViewLayoutContentQuery(\n  $id: ID!\n) {\n  site(id: $id) {\n    name\n    address {\n      address\n      state\n    }\n    devices(filters: {category: [PowerController, Generator, Gateway]}) {\n      data {\n        id\n        name\n        type {\n          category\n          id\n        }\n        dualPlaneCompanion {\n          device {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e959fc1b7211052319bddb47d0ac257e";

export default node;
