import { BatteryTestDeviceStatusOptions } from './components';
import { BatteryTestDeviceStatusFilter } from './types';

export function decodeBatteryTestDeviceStatusFilter(value: string): BatteryTestDeviceStatusFilter | null {
    const decodedValue = decodeURIComponent(value);
    const match = BatteryTestDeviceStatusOptions.find(option => option.id === decodedValue);
    if (!match) {
        return null;
    }

    return match;
}
