import React, { FC } from 'react';

import { FormArea, FormikTextField } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

import manageStyle from '../../../style.module.css';

interface CellVoltageProps {
    namespace?: string;
}

export const CellVoltage: FC<CellVoltageProps> = ({ namespace }) => {
    return (
        <FormArea
            label='Cell Voltage Information'
            blurb='This section is for voltage informaion relating to the cells.'
        >
            <div className={manageStyle.provisioning_content_grid}>
                <div className={manageStyle.strings_subgrid_container}>
                    <div className='col-start-1'>
                        <FormikTextField
                            name={getQualifiedName('cells.minimumAllowedVoltage', namespace)}
                            type='text'
                            placeHolder={'Min Voltage'}
                            light
                            variant='outlined'
                            testId='min-voltage'
                        />
                    </div>
                    <div className='col-start-2'>
                        <FormikTextField
                            name={getQualifiedName('cells.maximumAllowedVoltage', namespace)}
                            type='text'
                            placeHolder={'Max Voltage'}
                            light
                            variant='outlined'
                            testId='max-voltage'
                        />
                    </div>
                </div>

                <div className='col-start-1'>
                    <FormikTextField
                        name={getQualifiedName('cells.minimumFloatVoltage', namespace)}
                        type='text'
                        placeHolder={'Min Float Voltage'}
                        light
                        variant='outlined'
                        testId='min-float-voltage'
                    />
                </div>
                <div className='col-start-2'>
                    <FormikTextField
                        name={getQualifiedName('cells.maximumFloatVoltage', namespace)}
                        type='text'
                        placeHolder={'Max Float Voltage'}
                        light
                        variant='outlined'
                        testId='max-float-voltage'
                    />
                </div>

                <div className='col-start-1'>
                    <FormikTextField
                        name={getQualifiedName('cells.minimumBoostVoltage', namespace)}
                        type='text'
                        placeHolder={'Min Boost Voltage'}
                        light
                        variant='outlined'
                        testId='min-boost voltage'
                    />
                </div>
                <div className='col-start-2'>
                    <FormikTextField
                        name={getQualifiedName('cells.maximumBoostVoltage', namespace)}
                        type='text'
                        placeHolder={'Max Boost Voltage'}
                        light
                        variant='outlined'
                        testId='max-boost voltage'
                    />
                </div>

                <div className='col-start-1'>
                    <FormikTextField
                        name={getQualifiedName('cells.typicalVoltage', namespace)}
                        type='text'
                        placeHolder={'Typical Cell Voltage'}
                        light
                        variant='outlined'
                        testId='cell-voltage-typical'
                    />
                </div>
            </div>
        </FormArea>
    );
};
