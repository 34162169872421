/**
 * @generated SignedSource<<6ad1421614642eeb183d8da696c39be6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceTypeFilterUI_deviceTypeQuery$variables = Record<PropertyKey, never>;
export type DeviceTypeFilterUI_deviceTypeQuery$data = {
  readonly deviceTypes: {
    readonly data: ReadonlyArray<{
      readonly displayName: string;
      readonly id: string;
    }>;
  };
};
export type DeviceTypeFilterUI_deviceTypeQuery = {
  response: DeviceTypeFilterUI_deviceTypeQuery$data;
  variables: DeviceTypeFilterUI_deviceTypeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PaginatedDeviceTypes",
    "kind": "LinkedField",
    "name": "deviceTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceType",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceTypeFilterUI_deviceTypeQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeviceTypeFilterUI_deviceTypeQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8852fb53af13469ddb159986141a6263",
    "id": null,
    "metadata": {},
    "name": "DeviceTypeFilterUI_deviceTypeQuery",
    "operationKind": "query",
    "text": "query DeviceTypeFilterUI_deviceTypeQuery {\n  deviceTypes {\n    data {\n      id\n      displayName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3204183456d2a70092d2d98f14591e53";

export default node;
