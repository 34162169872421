import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import {
    BarChart,
    ChartMarker,
    ExtendedNavigateFunction,
    Theme,
    ThemedBarSeriesType,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterValueMap } from 'filters/common';
import { SiteTableColumn, SiteTableColumnId } from 'filters/site';
import { SiteAllFilterMap, StaticSiteFilterDefinitions } from 'filters/site/settings';
import { Paths } from 'lib/routes';
import { encodeFilterParameters } from 'lib/table-filter';

import { intervalToHuman } from '../../common';
import { TitleWithSubtitle } from '../../components';
import { TimeRange } from '../../settings';
import { ReliabilityChart$data, ReliabilityChart$key } from './__generated__/ReliabilityChart.graphql';

interface ReliabilityChartProps {
    fragmentRef: ReliabilityChart$key;
    interval: TimeRange;
}

export const ReliabilityChart: FC<ReliabilityChartProps> = ({ fragmentRef, interval }) => {
    const data = useFragment(Fragment, fragmentRef);
    const navigate = useExtendedNavigate();

    let alertType: 'empty-good' | undefined;
    let alertMessage;
    let alertTitle;

    if (data.siteACReliabilityDistribution?.distribution.length === 0) {
        alertType = 'empty-good';
        alertMessage = '';
        alertTitle = 'No incidents found';
    }

    const series = convertChartData(data.siteACReliabilityDistribution);

    return (
        <div className='col-span-full'>
            <TitleWithSubtitle
                title='Reliability'
                subtitle={intervalToHuman(interval, {
                    lifetimeOverride: 'for all time',
                    prefix: 'for the last ',
                })}
            />
            <BarChart
                series={series}
                aspectRatio={3}
                formatStartXTick={() =>
                    data.siteACReliabilityDistribution?.distribution.length === 1
                        ? 'Similar reliability'
                        : 'Least reliable'
                }
                formatXTick={() => ''}
                formatEndXTick={() => 'Most reliable'}
                formatTooltipLabel={() => ''}
                formatTooltipContent={values => {
                    const totalSites = values.reduce((previous, current) => previous + (current.value ?? 0), 0);

                    if (totalSites === 1) {
                        return '1 site';
                    } else {
                        return `${totalSites} sites`;
                    }
                }}
                markers={getMarker(data.siteACReliabilityDistribution)}
                alertType={alertType}
                alertMessage={alertMessage}
                alertTitle={alertTitle}
                variant='histogram'
                showLegendInTooltip={false}
                allStacked
                onBarClick={payload => onBarClick(payload, data.siteACReliabilityDistribution, navigate)}
            />
        </div>
    );
};
const Fragment = graphql`
    fragment ReliabilityChart on Query {
        siteACReliabilityDistribution(timeRange: $timeRange) {
            distribution {
                key
                count
                percentileStart
                percentileEnd
            }
        }
    }
`;

function onBarClick(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
    data: ReliabilityChart$data['siteACReliabilityDistribution'],
    navigate: ExtendedNavigateFunction
): void {
    if ('key' in payload) {
        const key = payload.key;

        const matchingDistribution = data?.distribution.find(dist => dist.key === key);

        if (matchingDistribution) {
            const { percentileStart, percentileEnd } = matchingDistribution;

            navigate({
                pathname: Paths.ReportACPowerSiteList,
                search: {
                    ...encodeFilterParameters<SiteTableColumnId, FilterValueMap<SiteAllFilterMap>>(
                        {
                            [SiteTableColumn.ACReliability]: {
                                min: percentileStart,
                                max: percentileEnd,
                            },
                        },
                        StaticSiteFilterDefinitions
                    ),
                },
            });
        }
    }
}

function convertChartData(data: ReliabilityChart$data['siteACReliabilityDistribution']): ThemedBarSeriesType<number>[] {
    const distribution = data?.distribution ?? [];

    return [
        {
            id: 'rel',
            name: 'Reliability',
            color: Theme.eggplantLight,
            data: distribution.map(item => {
                if (item.percentileStart <= 20 && item.percentileEnd !== 100) {
                    return {
                        key: item.key,
                        value: 0,
                    };
                }

                return {
                    key: item.key,
                    value: item.count,
                };
            }),
        },
        {
            id: 'wr',
            name: 'Worst Reliability',
            color: Theme.coralRegular,
            data: distribution.map(item => {
                if (item.percentileStart > 20 || item.percentileEnd === 100) {
                    return {
                        key: item.key,
                        value: 0,
                    };
                }
                return {
                    key: item.key,
                    value: item.count,
                };
            }),
        },
    ];
}

function getMarker(data: ReliabilityChart$data['siteACReliabilityDistribution']): ChartMarker<number>[] {
    const markers: ChartMarker<number>[] = [];

    if (data) {
        let startIndex: number | undefined = undefined;

        data.distribution.forEach((item, index) => {
            if (item.percentileStart <= 20 && item.percentileEnd !== 100) {
                startIndex = index;
            }
        });

        if (startIndex) {
            markers.push({
                type: 'area-vertical',
                color: Theme.coralLight,
                x1: 0,
                x2: data.distribution[startIndex].key,
                below: true,
                labelOffset: 32,
                label: 'Worst 25%',
                labelPosition: 'insideTop',
            });
        }
    }

    return markers;
}
