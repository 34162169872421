/**
 * @generated SignedSource<<06bc00164217927b85dc6fa77dbae5ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoltageChart_metrics$data = {
  readonly aggregatedVoltage: ReadonlyArray<{
    readonly average: number | null;
    readonly timestamp: string;
  }> | null;
  readonly " $fragmentType": "VoltageChart_metrics";
};
export type VoltageChart_metrics$key = {
  readonly " $data"?: VoltageChart_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoltageChart_metrics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoltageChart_metrics",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "points",
          "value": 20
        }
      ],
      "concreteType": "AggregatedTimestampMetric",
      "kind": "LinkedField",
      "name": "aggregatedVoltage",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "average",
          "storageKey": null
        }
      ],
      "storageKey": "aggregatedVoltage(points:20)"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "4e3cf5c88b824f0bb501243883049d0f";

export default node;
