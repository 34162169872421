/**
 * @generated SignedSource<<98e4cd78754dbd0c3f7515b3a586c055>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VitalsTable_info$data = {
  readonly coolantPressure: {
    readonly average: number | null;
    readonly max: number | null;
    readonly min: number | null;
  } | null;
  readonly coolantPressureOverTime: ReadonlyArray<{
    readonly offset: string;
    readonly value: number | null;
  }> | null;
  readonly coolantTemperature: {
    readonly average: number | null;
    readonly max: number | null;
    readonly min: number | null;
  } | null;
  readonly coolantTemperatureOverTime: ReadonlyArray<{
    readonly offset: string;
    readonly value: number | null;
  }> | null;
  readonly generator: {
    readonly id: string;
  };
  readonly generatorStartTime: string;
  readonly generatorStopTime: string | null;
  readonly oilPressure: {
    readonly average: number | null;
    readonly max: number | null;
    readonly min: number | null;
  } | null;
  readonly oilPressureOverTime: ReadonlyArray<{
    readonly offset: string;
    readonly value: number | null;
  }> | null;
  readonly oilTemperature: {
    readonly average: number | null;
    readonly max: number | null;
    readonly min: number | null;
  } | null;
  readonly oilTemperatureOverTime: ReadonlyArray<{
    readonly offset: string;
    readonly value: number | null;
  }> | null;
  readonly rpm: {
    readonly average: number | null;
    readonly max: number | null;
    readonly min: number | null;
  } | null;
  readonly rpmOverTime: ReadonlyArray<{
    readonly offset: string;
    readonly value: number | null;
  }> | null;
  readonly " $fragmentType": "VitalsTable_info";
};
export type VitalsTable_info$key = {
  readonly " $data"?: VitalsTable_info$data;
  readonly " $fragmentSpreads": FragmentRefs<"VitalsTable_info">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "unit",
  "variableName": "unitPressure"
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "min",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "max",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "average",
    "storageKey": null
  }
],
v3 = {
  "kind": "Variable",
  "name": "unit",
  "variableName": "unitTemperature"
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "kind": "Literal",
  "name": "points",
  "value": 10
},
v6 = [
  (v5/*: any*/),
  (v0/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "offset",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v8 = [
  (v5/*: any*/),
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitPressure"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitTemperature"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "VitalsTable_info",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "generator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generatorStartTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generatorStopTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "ReportValueStats",
      "kind": "LinkedField",
      "name": "oilPressure",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "concreteType": "ReportValueStats",
      "kind": "LinkedField",
      "name": "oilTemperature",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "ReportValueStats",
      "kind": "LinkedField",
      "name": "coolantPressure",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "concreteType": "ReportValueStats",
      "kind": "LinkedField",
      "name": "coolantTemperature",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportValueStats",
      "kind": "LinkedField",
      "name": "rpm",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v6/*: any*/),
      "concreteType": "TimestampMetricWithOffset",
      "kind": "LinkedField",
      "name": "oilPressureOverTime",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v8/*: any*/),
      "concreteType": "TimestampMetricWithOffset",
      "kind": "LinkedField",
      "name": "oilTemperatureOverTime",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v6/*: any*/),
      "concreteType": "TimestampMetricWithOffset",
      "kind": "LinkedField",
      "name": "coolantPressureOverTime",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v8/*: any*/),
      "concreteType": "TimestampMetricWithOffset",
      "kind": "LinkedField",
      "name": "coolantTemperatureOverTime",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v5/*: any*/)
      ],
      "concreteType": "TimestampMetricWithOffset",
      "kind": "LinkedField",
      "name": "rpmOverTime",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": "rpmOverTime(points:10)"
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};
})();

(node as any).hash = "908b2ee5e99ab99741d3f65eeab5ec08";

export default node;
