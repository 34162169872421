import React, { FC, useCallback, useEffect, useState } from 'react';
import { useFragment, useQueryLoader } from 'react-relay';

import { Link } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNamesBind from 'classnames/bind';
import { Paths } from 'lib/routes';

import { BatteryTestCardContent } from './BatteryTestCardContent';
import { BatteryTestCardExpansion } from './BatteryTestCardExpansion';
import { ContentQuery } from './BatteryTestCardExpansionContent';
import { BatteryTestCardExpansionContentLoadQuery } from './__generated__/BatteryTestCardExpansionContentLoadQuery.graphql';
import { BatteryTestCard_data$key } from './__generated__/BatteryTestCard_data.graphql';
import style from './card.module.css';

const classNames = classNamesBind.bind(style);

interface BatteryTestCardProps {
    task: BatteryTestCard_data$key;
    compact?: boolean;
    forceStatusDisplay?: boolean;
    background?: 'light' | 'dark';
}

export const BatteryTestCard: FC<BatteryTestCardProps> = ({
    task,
    compact,
    forceStatusDisplay,
    background = 'light',
}) => {
    const [isHovered, setIsHovered] = useState(false);

    const result = useFragment<BatteryTestCard_data$key>(
        graphql`
            fragment BatteryTestCard_data on Task {
                id
                ...BatteryTestCardContent_task
            }
        `,
        task
    );

    const { id } = result;

    const [contentQueryRef, loadQuery, disposeQuery] =
        useQueryLoader<BatteryTestCardExpansionContentLoadQuery>(ContentQuery);

    // Cleanup the query when the component unmounts
    useEffect(() => {
        return () => {
            disposeQuery();
        };
    }, [disposeQuery]);

    const handleMouseEnter = useCallback(() => {
        loadQuery({ id });
        setIsHovered(true);
    }, [id, loadQuery]);

    return (
        <Link to={`${Paths.TestsDetailsView}/${id}`}>
            <div className='relative h-full' onMouseEnter={handleMouseEnter} onMouseLeave={() => setIsHovered(false)}>
                <div
                    className={classNames('h-full p-5 font-normal', {
                        'text-eggplantRegular': !compact,
                        'text-eggplantLight': compact,
                        'text-sm': compact,
                        'bg-white': background === 'light',
                        'bg-grayRegular': background === 'dark',
                    })}
                >
                    <BatteryTestCardContent task={result} compact={compact} forceStatusDisplay={forceStatusDisplay} />
                </div>
                {isHovered && (
                    <div
                        className={classNames('card_expanded', 'p-5 font-normal text-eggplantRegular shadow-2xl', {
                            'bg-white': background === 'light',
                            'bg-grayRegular': background === 'dark',
                        })}
                    >
                        <BatteryTestCardContent
                            task={result}
                            compact={compact}
                            forceStatusDisplay={forceStatusDisplay}
                        />
                        <BatteryTestCardExpansion preloadedQuery={contentQueryRef} />
                    </div>
                )}
            </div>
        </Link>
    );
};
