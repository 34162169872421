/**
 * @generated SignedSource<<2fb895e90040f6244c65af65499e08e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTypeRemoveResponse = "PartialSuccess" | "Success" | "UnknownBatteryType" | "%future added value";
export type deleteBatteryTypeMutation$variables = {
  ids: ReadonlyArray<string>;
};
export type deleteBatteryTypeMutation$data = {
  readonly removeBatteryTypes: BatteryTypeRemoveResponse;
};
export type deleteBatteryTypeMutation = {
  response: deleteBatteryTypeMutation$data;
  variables: deleteBatteryTypeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "kind": "ScalarField",
    "name": "removeBatteryTypes",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteBatteryTypeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteBatteryTypeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ebc2ca99ee8b49ebeaa05ecc884f883",
    "id": null,
    "metadata": {},
    "name": "deleteBatteryTypeMutation",
    "operationKind": "mutation",
    "text": "mutation deleteBatteryTypeMutation(\n  $ids: [ID!]!\n) {\n  removeBatteryTypes(ids: $ids)\n}\n"
  }
};
})();

(node as any).hash = "df30a1be969fcad076d63f4bd04d0c1f";

export default node;
