import { Duration } from 'luxon';

import { ActivityLogType } from './sub-views/history/__generated__/HistoryContentQuery.graphql';

export const LiveDataRefreshInterval = 5000; // 5 seconds

export const DefaultTimeRange = Duration.fromObject({ days: 1 });
export const DefaultHistorySize = 20;
export const DefaultHistoryStep = 10;

export const DefaultHistoryTypes: ActivityLogType[] = [
    // inventory
    'DeviceAdd',
    'DeviceEdit',

    // insights
    'AlertSeverityCritical',
    'AlertSeverityMajor',

    // grid-events
    'GridEventStatusOffline',
    'GridEventStatusHigh',

    // battery test
    'BatteryTest',
];
