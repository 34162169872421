import React, { FC, ReactNode } from 'react';

import { Button, Menu, MenuItem, NavigateTo, PageHeading } from '@accesstel/pcm-ui';

import classNames from 'classnames';

export interface ProvisionPageLayoutProps {
    title: string;

    primaryAction?: string;
    primaryActionLink?: NavigateTo;
    primaryActionClick?: () => void;
    primaryActionProcessing?: boolean;

    secondaryActions?: MenuItem[];

    children: ReactNode;
}

export const ProvisionPageLayout: FC<ProvisionPageLayoutProps> = ({
    title,
    primaryAction,
    primaryActionLink,
    primaryActionClick,
    primaryActionProcessing,
    secondaryActions,
    children,
}) => {
    const hasSecondaryActions = secondaryActions && secondaryActions.length > 0;

    return (
        <div className='space-y-6'>
            <div>
                <div>
                    {hasSecondaryActions && (
                        <div className=''>
                            <Menu id='provision-page-layout' menuItems={secondaryActions} />
                        </div>
                    )}
                    <div className={classNames('flex justify-between items-center', { 'pt-6': !hasSecondaryActions })}>
                        <PageHeading value={title} />
                        {primaryAction && (primaryActionLink || primaryActionClick) && (
                            <Button
                                buttonText={primaryAction!}
                                size='small'
                                to={primaryActionLink}
                                onClick={primaryActionClick}
                                processing={primaryActionProcessing}
                            />
                        )}
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};
