import React, { Dispatch, FC, useCallback, useEffect } from 'react';
import { commitMutation, useFragment, useRelayEnvironment } from 'react-relay';

import { FooterAction, useExtendedNavigate, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { BatteryTestFilterAction, BatteryTestFilterState, BatteryTestTableColumn } from 'filters/battery-test';
import { BaseColumns, BatteryTestColumnFilterMap } from 'filters/battery-test/settings';
import { TableAction, TableActionType, TableState } from 'layouts';
import { CoreTableLayout } from 'layouts/TableLayout/CoreTableLayout';
import { useConfirmationModal } from 'lib/confirmation';
import { logError } from 'lib/log';
import { Paths } from 'lib/routes';
import { renderTableStatusCell } from 'lib/table-columns';

import { useDeviceSelection } from '../../schedule/device-selection';
import { MaxTestDisplay } from '../../settings';
import { CompareURLParamTests } from '../../test-compare/settings';
import { StatusListAbortTestsMutation } from './__generated__/StatusListAbortTestsMutation.graphql';
import { StatusListCancelTestsMutation } from './__generated__/StatusListCancelTestsMutation.graphql';
import { StatusList_task$data, StatusList_task$key } from './__generated__/StatusList_task.graphql';

interface StatusCellProps {
    test: StatusCellValue;
}

export const StatusCell: FC<StatusCellProps> = ({ test }) => {
    if (test === null) {
        return renderTableStatusCell('Cancelled');
    }

    return renderTableStatusCell(test.state, test);
};

type CellData = StatusList_task$data['devices']['data'];
export type StatusCellValue = CellData[0]['test'];
export type Device = StatusList_task$data['devices']['data'][number];

export interface StatusListProps {
    task: StatusList_task$key;
    hasError?: boolean;
    retry: () => void;
    isFetching?: boolean;
    filters: BatteryTestFilterState;
    dispatchFilters: Dispatch<BatteryTestFilterAction>;
    tableState: TableState<BatteryTestTableColumn>;
    dispatchTableState: Dispatch<TableAction<BatteryTestTableColumn>>;
}

export const StatusList: FC<StatusListProps> = ({
    task,
    hasError,
    retry,
    isFetching,
    filters,
    dispatchFilters,
    tableState,
    dispatchTableState,
}) => {
    const environment = useRelayEnvironment();
    const { show } = useToast();
    const [showModal, modalComponent] = useConfirmationModal();

    const results = useFragment(StatusListFragment, task);
    const { selectedDevices, setDeviceSelected, setDeviceUnselected, setSelectedDevices, setTotalDeviceCount } =
        useDeviceSelection();
    const navigate = useExtendedNavigate();

    const overallState = results?.overallState;
    const isScheduled = overallState === 'Scheduled';
    const isInProgress = overallState === 'InProgress';
    const isCancelled = overallState === 'Cancelled';
    const canAbortOrCancel = isScheduled || isInProgress;

    const devices = results?.devices.data;
    const pageInfo = {
        hasNext: results?.devices.pageInfo.hasNext ?? false,
        hasPrevious: results?.devices.pageInfo.hasPrevious ?? false,
        page: results?.devices.pageInfo.page ?? 1,
        total: results?.devices.pageInfo.total ?? 1,
    };

    const handleCancel = useCallback(
        (testIds: string[]) => {
            showModal({
                title: `Cancel Test${testIds.length === 1 ? '' : 's'}`,
                content: `Are you sure you want to cancel ${testIds.length === 1 ? 'this test' : 'these tests'}?`,
                buttons: [
                    {
                        id: 'confirm',
                        label: `Cancel Test${testIds.length === 1 ? '' : 's'}`,
                        variant: 'primary',
                    },
                    {
                        id: 'close',
                        label: 'Close',
                        variant: 'white',
                    },
                ],
                onResult: async result => {
                    if (result === 'close') {
                        return;
                    }

                    commitMutation<StatusListCancelTestsMutation>(environment, {
                        mutation: CancelTestsMutation,
                        variables: { ids: testIds },
                        onError: err => {
                            logError('Unable to cancel selected tests', err);
                            captureException(err, scope => {
                                scope.setExtra('Tests', testIds.toString());
                                scope.setTag('Function', 'cancelTests');
                                return scope;
                            });
                            show({ text: 'Unable to cancel selected tests', variant: 'error' });
                        },
                        onCompleted: (response, err) => {
                            if (response.cancelBatteryTestTests === true) {
                                show({ text: `${testIds.length} tests cancelled` });
                                retry();
                            }

                            if (response.cancelBatteryTestTests === false || err) {
                                show({ text: 'Unable to cancel selected tests', variant: 'error' });
                            }
                        },
                    });
                },
            });
        },
        [environment, retry, show, showModal]
    );

    const handleAbort = useCallback(
        (testIds: string[]) => {
            showModal({
                title: `Abort Test${testIds.length === 1 ? '' : 's'}`,
                // content: `Are you sure you want to abort ${testIds.length === 1 ? 'this test' : 'these tests'}?`,
                content: (
                    <div className='text-center'>
                        <div>Are you sure you want to abort {testIds.length === 1 ? 'this test' : 'these tests'}?</div>
                        <div className='text-coralRegular'>
                            Please note that if a test in the selection has a companion discharge, it will also be
                            aborted.
                        </div>
                    </div>
                ),
                buttons: [
                    {
                        id: 'confirm',
                        label: `Abort Test${testIds.length === 1 ? '' : 's'}`,
                        variant: 'primary',
                    },
                    {
                        id: 'close',
                        label: 'Close',
                        variant: 'white',
                    },
                ],
                onResult: async result => {
                    if (result === 'close') {
                        return;
                    }

                    commitMutation<StatusListAbortTestsMutation>(environment, {
                        mutation: AbortTestsMutation,
                        variables: { ids: testIds },
                        onError: err => {
                            logError('Unable to abort selected tests', err);
                            captureException(err, scope => {
                                scope.setExtra('Tests', testIds.toString());
                                scope.setTag('Function', 'abortTests');
                                return scope;
                            });
                            show({ text: 'Unable to abort selected tests', variant: 'error' });
                        },
                        onCompleted: (response, err) => {
                            if (response.abortBatteryTestTests === true) {
                                show({ text: `${testIds.length} tests aborted` });
                                retry();
                            }

                            if (response.abortBatteryTestTests === false || err) {
                                show({ text: 'Unable to abort selected tests', variant: 'error' });
                            }
                        },
                    });
                },
            });
        },
        [environment, retry, show, showModal]
    );

    const onSelectNone = useCallback(() => {
        setSelectedDevices([]);
        setTotalDeviceCount(0);
    }, [setSelectedDevices, setTotalDeviceCount]);

    const onUpdateSelection = useCallback(
        (deviceIds: string[]) => {
            // Newly selected
            for (const deviceId of deviceIds) {
                if (selectedDevices.has(deviceId)) {
                    continue;
                }

                // New selection
                const device = (devices ?? []).find(device => device.id === deviceId);
                if (device) {
                    setDeviceSelected(deviceId, {
                        companion: device.dualPlaneCompanion?.device?.id,
                        testScheduled: isScheduled,
                    });
                }
            }

            // Cleared selections
            const newSelection = new Set(deviceIds);
            selectedDevices.forEach(deviceId => {
                if (newSelection.has(deviceId)) {
                    return;
                }

                // Cleared selected
                setDeviceUnselected(deviceId);
            });
            dispatchTableState({ type: TableActionType.SetSelection, selection: Array.from(selectedDevices.keys()) });
        },
        [isScheduled, devices, dispatchTableState, selectedDevices, setDeviceSelected, setDeviceUnselected]
    );

    useEffect(() => {
        dispatchTableState({ type: TableActionType.SetSelection, selection: Array.from(selectedDevices.keys()) });
    }, [dispatchTableState, selectedDevices]);

    const getAllDevicesPromise = () => {
        const allDevices = devices.map(device => device.id);
        return allDevices;
    };

    const selectionFooterActions: FooterAction[] = [];
    if (canAbortOrCancel) {
        selectionFooterActions.push({
            buttonText: `${isScheduled ? 'Cancel' : 'Abort'} selected`,
            onClick: () => {
                const selectedDevices = devices.filter(device => tableState.selectedItems.includes(device.id));
                const testIds = selectedDevices.map(device => device.test!.id);

                // check if we need to select the companion test.id and add it to the testIds array
                selectedDevices.forEach(device => {
                    if (device.dualPlaneCompanion?.device?.id) {
                        const companionTest = devices.find(d => d.id === device.dualPlaneCompanion?.device?.id)?.test
                            ?.id;
                        if (companionTest && !testIds.includes(companionTest)) {
                            testIds.push(companionTest);
                        }
                    }
                });

                if (isScheduled) {
                    handleCancel(testIds);
                } else {
                    handleAbort(testIds);
                }
            },
            disabled: tableState.selectedItems.length === 0,
            disabledMessage: 'No devices selected',
        });
    }

    if (!isScheduled && !isCancelled) {
        // Scheduled & Cancelled tests cannot be compared since there are no metrics (technically no test results for these)
        const selectedDevicesCount = tableState.selectedItems.length;
        const testIds = devices
            .filter(device => tableState.selectedItems.includes(device.id))
            .map(device => device.test?.id)
            .filter(test => test !== undefined);

        selectionFooterActions.push({
            buttonText: `Compare selected (${selectedDevicesCount}/${MaxTestDisplay})`,
            disabled: tableState.selectedItems.length > MaxTestDisplay,
            disabledMessage: `You can only select a maximum of ${MaxTestDisplay} tests for comparison`,
            onClick: () => {
                navigate({
                    pathname: Paths.TestsCompare,
                    search: {
                        [CompareURLParamTests]: testIds.join(','),
                    },
                });
            },
        });
    }

    return (
        <>
            <div className='font-bold text-eggplantRegular text-3xl pb-8'>Test Status</div>
            <CoreTableLayout<BatteryTestTableColumn, Device, Device, BatteryTestColumnFilterMap>
                columns={BaseColumns}
                data={devices}
                getRowId={row => row.id}
                page={pageInfo.page}
                pageCount={pageInfo.total}
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                emptyMessage='There are no devices present'
                hasError={hasError}
                clickBehaviour={overallState !== 'Cancelled' ? 'navigate' : 'none'}
                isProcessing={isFetching}
                tableVariant='white'
                getItemLink={
                    overallState !== 'Cancelled'
                        ? (row: Device) => `${Paths.TestsDetailsView}/${results.id}/${row.id}`
                        : undefined
                }
                selection
                selectionFooterActions={selectionFooterActions}
                selectionFooterSelectedItems={tableState.selectedItems}
                selectionFooterUnitOverride={'Device'}
                selectionFooterUnitPluralOverride={'Devices'}
                onRequestAllIds={getAllDevicesPromise}
                tableOnSelectRowCallback={onUpdateSelection}
                tableOnUnselectAllCallback={onSelectNone}
            />
            {modalComponent}
        </>
    );
};

const AbortTestsMutation = graphql`
    mutation StatusListAbortTestsMutation($ids: [ID!]!) {
        abortBatteryTestTests(ids: $ids)
    }
`;

const CancelTestsMutation = graphql`
    mutation StatusListCancelTestsMutation($ids: [ID!]!) {
        cancelBatteryTestTests(ids: $ids)
    }
`;

const StatusListFragment = graphql`
    fragment StatusList_task on BatteryTest {
        id
        overallState
        testState
        completedTime
        abortedTime
        cancelledTime
        devices(filters: $filters, deviceFilters: $deviceFilters, orderBy: $orderBy, page: $page) {
            total
            pageInfo {
                page
                size
                total
                hasNext
                hasPrevious
            }
            data {
                id
                site {
                    name
                    address {
                        state
                    }
                }
                name
                test(id: $id) {
                    id
                    state
                    failReason
                    finalSoH
                }
                dualPlaneCompanion {
                    device {
                        id
                    }
                }
            }
        }
    }
`;
