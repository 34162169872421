import React, { FC, KeyboardEvent } from 'react';
import { useMutation } from 'react-relay';

import { Button, Modal, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Field, Form, Formik } from 'formik';
import { logError } from 'lib/log';

interface FormValues {
    notes: string;
}

export interface NoteEditDialogProps {
    editId: string;
    initialNotes?: string;
    open?: boolean;
    type: 'batteryTest' | 'generatorRun';
    onClose: () => void;
}

export const NoteEditDialog: FC<NoteEditDialogProps> = ({ editId, type, initialNotes, open, onClose }) => {
    const { show } = useToast();

    const mutation =
        type === 'batteryTest'
            ? graphql`
                  mutation NoteEditDialogBatteryTestMutation($id: ID!, $notes: String) {
                      editBatteryTestNote(id: $id, note: $notes)
                  }
              `
            : graphql`
                  mutation NoteEditDialogGeneratorRunMutation($id: ID!, $notes: String) {
                      editGeneratorRunReportNote(id: $id, note: $notes)
                  }
              `;

    const [updateTestNote, isSubmitting] = useMutation(mutation);

    return (
        <Modal open={!!open} onHide={onClose} className='w-dialog-lg font-normal'>
            {open && (
                <Formik
                    initialValues={
                        {
                            notes: initialNotes ?? '',
                        } as FormValues
                    }
                    onSubmit={values => {
                        let note: string | undefined;
                        if (values.notes.trim() !== '') {
                            note = values.notes;
                        }

                        updateTestNote({
                            variables: {
                                id: editId,
                                notes: note,
                            },
                            onCompleted: () => {
                                onClose();
                            },
                            onError(error) {
                                captureException(error, scope => {
                                    scope.setTag('Component', 'NoteEditDialog');
                                    scope.setTag('Function', 'saveNote');
                                    scope.setExtra('editId', editId);
                                    return scope;
                                });
                                logError('Unable to save note', error);
                                show({
                                    text: 'Failed to save note. Try again later.',
                                    variant: 'error',
                                });
                            },
                            updater: store => {
                                const test = store.get(editId);
                                if (!test) {
                                    return;
                                }

                                let record = test.getLinkedRecord('note');

                                if (note == null) {
                                    if (record) {
                                        const id = record.getDataID();
                                        store.delete(id);
                                    }

                                    return;
                                }

                                if (!record) {
                                    record = store.create(`client:${editId}:note`, 'BatteryTestNote');
                                    test.setLinkedRecord(record, 'note');
                                }

                                record.setValue(note, 'content');
                                // request that something else retrieves the real value
                                record.invalidateRecord();
                            },
                        });
                    }}
                >
                    {({ submitForm }) => (
                        <Form className='space-y-4'>
                            <div>
                                <p className='text-2xl font-normal'>Edit notes for this discharge</p>
                                <p className='text-xs text-eggplantLight'>Notes can be seen by everybody.</p>
                            </div>
                            <Field
                                name='notes'
                                as='textarea'
                                className='w-full h-64 border-eggplantExtraLight border rounded-md p-2 min-h-16 max-h-96'
                                placeholder='Write your own notes here'
                                autoFocus
                                onKeyDown={(event: KeyboardEvent) => {
                                    if (event.key === 'Enter' && event.ctrlKey) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        submitForm();
                                    }
                                }}
                            />
                            <div className='flex flex-row gap-4'>
                                <Button buttonText='Save changes' type='submit' processing={isSubmitting} />
                                <Button
                                    buttonText='Discard changes'
                                    onClick={onClose}
                                    variant='secondary'
                                    type='button'
                                    disabled={isSubmitting}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};
