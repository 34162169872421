import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { FormikTextFieldWithSuggestion } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useField, useFormikContext } from 'formik';

import { DeviceFormValues } from '../../../schema';
import { GatewaySettingsFormValues } from '../schema';
import { NeSelectorSearchQuery } from './__generated__/NeSelectorSearchQuery.graphql';

export interface NeSelectorProps {
    name: string;
}

export const NeSelector: FC<NeSelectorProps> = ({ name }) => {
    const [, , fieldHelper] = useField(name);

    const environment = useRelayEnvironment();
    const { values } = useFormikContext<DeviceFormValues>();

    const [networkElements, setNetworkElements] = useState<string[]>([]);
    const [noResultText, setNoResultText] = useState<string>('No network elements found');

    const gatewayId = useMemo(
        () =>
            Object.keys(values.settings.protocols).includes('gateway')
                ? (values.settings.protocols['gateway'] as GatewaySettingsFormValues).gateway.id
                : '',
        [values.settings.protocols]
    );

    const getNetworkElements = useCallback(() => {
        if (!gatewayId || !values.type) {
            return;
        }

        fetchQuery<NeSelectorSearchQuery>(environment, SearchQuery, {
            gatewayId,
            deviceType: values.type,
        }).subscribe({
            next(value) {
                if (value.gatewayInventory?.error) {
                    setNetworkElements([]);
                    setNoResultText('Error retrieving network elements');
                } else {
                    const networkElements = value.gatewayInventory?.devices?.map(device => device.id) ?? [];
                    setNetworkElements(networkElements);

                    if (networkElements.length === 0) {
                        setNoResultText('No network elements found');
                    }

                    if (networkElements.length === 1) {
                        fieldHelper.setValue(networkElements[0]);
                    }
                }
            },
            error(error: unknown) {
                captureException(error);
                setNetworkElements([]);
                // TODO: Give more context to the user. Hinting on checking gateway connection settings.
                // Currently, it needs to be short to be fit in the suggestion area.
                setNoResultText('Unable to retrieve network elements');
            },
        });
    }, [environment, fieldHelper, gatewayId, values.type]);

    useEffect(() => {
        getNetworkElements();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gatewayId, values.type]);

    return (
        <FormikTextFieldWithSuggestion<string>
            name={name}
            placeHolder='Network Element*'
            required
            light
            variant='outlined'
            testId='gateway-local-id'
            renderSuggestions={(suggestion: string) => suggestion}
            suggestions={networkElements}
            noResultsText={<span className='font-light text-sm'>{noResultText}</span>}
            onSuggestionClick={suggestion => {
                fieldHelper.setValue(suggestion);
            }}
            onInputChange={value => fieldHelper.setValue(value)}
        />
    );
};

const SearchQuery = graphql`
    query NeSelectorSearchQuery($gatewayId: ID!, $deviceType: ID!) {
        gatewayInventory(id: $gatewayId, deviceTypes: [$deviceType], includeDisabled: true) {
            ... on GatewayInventoryError {
                error
            }
            ... on GatewayInventoryResult {
                devices {
                    id
                }
            }
        }
    }
`;
