import React, { FC, ReactNode, useCallback, useState } from 'react';

import { ArrowLeftIcon, FilterContainer } from '@accesstel/pcm-ui';

import { CommonFilterProps } from 'filters/common';
import { Duration } from 'luxon';

import { CustomDuration } from './CustomDuration';
import { PredefinedSelections } from './PredefinedSelections';
import { PredefinedDuration, RunTimeFilter } from './common';
import { DefaultPresetRanges } from './presets';

enum Mode {
    ChooseType,
    Durations,
}

export interface FilterDurationSelectProps extends CommonFilterProps<RunTimeFilter> {
    title: string;
    presets?: PredefinedDuration[];
}

export const FilterDurationSelect: FC<FilterDurationSelectProps> = ({
    title,
    current,
    onClearOrBack,
    onSubmit,
    onClose,
    hasPrevious,
    presets = DefaultPresetRanges,
}) => {
    const [range, setRange] = useState<RunTimeFilter | null>(current ?? null);
    const [mode, setMode] = useState<Mode>(() => {
        if (current) {
            switch (current.type) {
                case 'predefined':
                    return Mode.ChooseType;
                case 'custom':
                    return Mode.Durations;
            }
        }
        return Mode.ChooseType;
    });

    const handlePredefinedRange = useCallback((newRange: PredefinedDuration) => {
        setRange({
            type: 'predefined',
            preset: newRange.title,
            range: newRange.asDurationRange(),
        });
    }, []);

    const handleConfirmClick = useCallback(() => {
        if (!onSubmit || !range) {
            return;
        }

        if (Duration.fromISO(range.range[0]).as('milliseconds') > Duration.fromISO(range.range[1]).as('milliseconds')) {
            onSubmit({
                type: 'custom',
                range: [range.range[1], range.range[0]],
            });
            return;
        }

        onSubmit(range);
    }, [onSubmit, range]);

    const handleClearClick = useCallback(() => {
        if (mode !== Mode.ChooseType) {
            setMode(Mode.ChooseType);
            return;
        }
        onClearOrBack();
    }, [mode, onClearOrBack]);

    let customClearButton: ReactNode;
    if (hasPrevious && current == null) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-coralRegular'>
                <ArrowLeftIcon />
            </div>
        );
    }

    let content: ReactNode;
    switch (mode) {
        case Mode.ChooseType: {
            let selected: PredefinedDuration | null = null;
            if (range && range.type === 'predefined') {
                selected = presets.find(preset => preset.title === range.preset) ?? null;
            }

            content = (
                <PredefinedSelections
                    ranges={presets}
                    selected={selected}
                    onSelect={handlePredefinedRange}
                    onCustomDurationClick={() => setMode(Mode.Durations)}
                />
            );
            break;
        }
        case Mode.Durations: {
            content = <CustomDuration value={range} onChange={setRange} />;
            break;
        }
    }

    return (
        <FilterContainer
            title={title}
            customButton={customClearButton}
            onClearClick={handleClearClick}
            onConfirmClick={handleConfirmClick}
            onClose={onClose}
            primaryContent={content}
            hideClearButton={!range && !hasPrevious}
            hideConfirmButton={!range && !current}
        />
    );
};
