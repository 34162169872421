import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';

import {
    GroupDefinition,
    HighlightContext,
    SearchBox,
    SearchBoxProps,
    createHighlightContext,
    useToast,
} from '@accesstel/pcm-ui';

export interface SearchResultWithGroups<T> {
    results: T[];
    groups: GroupDefinition[];
}

export interface SearchProps<T> {
    /**
     * The initial search input to display in the box.
     * This does not cause a search, or update the search results.
     * This field also cannot be changed, it is only used on first render
     */
    initialValue?: string;
    onSearch: (input: string) => Promise<T[] | SearchResultWithGroups<T>>;
    onFilter: (input: string, item?: T | null) => void;
    onClear: () => void;

    isFiltering?: boolean;

    renderSearchResult: (item: T, query: HighlightContext) => ReactNode;
    renderSearchResultAsString: (item: T) => string;

    placeholder: string;

    /**
     * Any additional search box properties to pass through.
     * Some properties will be overridden by this search component.
     */
    additionalProps?: Partial<SearchBoxProps<T>>;
}

export function Search<T>({
    initialValue,
    onSearch,
    onFilter,
    onClear,
    isFiltering,
    renderSearchResult,
    renderSearchResultAsString,
    placeholder,
    additionalProps,
}: SearchProps<T>): ReactElement {
    const [searchInput, setSearchInput] = useState(initialValue ?? '');
    const [searchResults, setSearchResults] = useState<T[] | undefined>(undefined);
    const [resultHighlightContext, setResultHighlightContext] = useState<HighlightContext | undefined>(undefined);
    const [searchGroups, setSearchGroups] = useState<GroupDefinition[] | undefined>(undefined);

    const { show } = useToast();

    useEffect(() => {
        if (searchInput && searchInput.length >= 2) {
            onSearch(searchInput)
                .then(result => {
                    const context = createHighlightContext(searchInput);
                    setResultHighlightContext(context);
                    if (Array.isArray(result)) {
                        setSearchResults(result);
                        setSearchGroups(undefined);
                    } else {
                        setSearchResults(result.results);
                        setSearchGroups(result.groups);
                    }
                })
                .catch(() => {
                    show({
                        text: 'Unable to show search results',
                        variant: 'error',
                    });
                    setResultHighlightContext(undefined);
                    setSearchResults(undefined);
                    setSearchGroups(undefined);
                });
        } else {
            setResultHighlightContext(undefined);
            setSearchResults(undefined);
            setSearchGroups(undefined);
        }
    }, [searchInput, onSearch, show]);

    const handleReset = useCallback(() => {
        setSearchInput('');
        onClear();
    }, [onClear]);

    return (
        <SearchBox
            {...additionalProps}
            autoFocus
            id='table-search'
            placeHolder={placeholder}
            value={searchInput}
            onChange={setSearchInput}
            onSubmit={onFilter}
            onResultClick={result => {
                const simpleResult = renderSearchResultAsString(result);
                setSearchInput(simpleResult);
                onFilter(simpleResult, result);
            }}
            searchResults={searchResults}
            renderResult={result => renderSearchResult(result, resultHighlightContext!)}
            moreResults={searchInput.length > 0}
            showReset={isFiltering}
            onReset={handleReset}
            groups={searchGroups ?? additionalProps?.groups}
        />
    );
}
