import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { AttributeFilter, AttributeFilterUI, FilterDefinition } from 'filters/common';

import { dynamic_loadBatteryTypeAttributeNamesQuery } from './__generated__/dynamic_loadBatteryTypeAttributeNamesQuery.graphql';
import { BatteryTypeTableColumnId } from './types';

export function useDynamicFilters(): FilterDefinition<BatteryTypeTableColumnId>[] {
    const data = useLazyLoadQuery<dynamic_loadBatteryTypeAttributeNamesQuery>(
        graphql`
            query dynamic_loadBatteryTypeAttributeNamesQuery {
                attributeNames(search: "", only: Battery, limit: 1000, status: Active)
            }
        `,
        {},
        {
            fetchPolicy: 'store-or-network',
        }
    );

    return (
        data?.attributeNames.map(name => ({
            id: `tag_${name}`,
            column: `tag_${name}`,
            type: 'multi',
            name: `Tag ${name}`,
            category: 'Attribute',
            attributeName: name,
            component: () => props => <AttributeFilterUI {...props} attribute={name} type='Battery' />,
            describeValue: (value: AttributeFilter) => value.value,
            encodeValue: () => '', // NO-OP
            decodeValue: () => null, // NO-OP
        })) ?? []
    );
}
