import React, { FC, useMemo } from 'react';
import { fetchQuery } from 'react-relay';

import { ArrowDownIcon, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { IncidentTableColumn, incidentToFilterObject, useIncidentFilter } from 'filters/incidents';
import humanizeDuration from 'humanize-duration';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { exportAsCsv } from 'lib/csv-export';
import { getGlobalEnvironment } from 'lib/environment';
import { useQuery } from 'lib/query-helpers';
import { capitalize } from 'lib/textFormatters';
import { Duration } from 'luxon';

import { DeviceIncidentExpandedRow } from '../components/DeviceIncidentExpandedRow';
import { IncidentTableAllColumns, IncidentTableBaseColumns } from '../settings';
import { ACPowerIncidentListExportQuery } from './__generated__/ACPowerIncidentListExportQuery.graphql';
import {
    ACPowerEventOrdering,
    ACPowerEventSortField,
    ACPowerIncidentListQuery,
    ACPowerIncidentListQuery$data,
    ACPowerIncidentListQuery$variables,
} from './__generated__/ACPowerIncidentListQuery.graphql';

export type Incidents = ACPowerIncidentListQuery$data['acEvents']['data'][number];

const TableStorageKeyPrefix = 'ac-power-incident-table';

export const ACPowerIncidentList: FC = () => {
    const environment = getGlobalEnvironment();
    const { show } = useToast();

    useDocumentTitle('Reports - AC Power - Incident List');

    const [filters, dispatchFilters] = useIncidentFilter();
    const filterObject = useMemo(() => incidentToFilterObject(filters), [filters]);

    const [tableState, dispatchTableState] = useTableReducer<IncidentTableColumn>({
        defaultSortColumn: IncidentTableColumn.IncidentStartTime,
        defaultSortDirection: SortDirection.Ascending,
        allColumns: IncidentTableAllColumns,
        defaultVisibleColumns: IncidentTableBaseColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const sortObject: ACPowerEventOrdering = {
        field: tableState.sortColumn as ACPowerEventSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: ACPowerIncidentListQuery$variables = {
        page: tableState.page,
        filter: {
            ...filterObject,
        },
        orderBy: sortObject,
    };

    const {
        data: props,
        error,
        retry,
        isFetching,
    } = useQuery<ACPowerIncidentListQuery>(
        graphql`
            query ACPowerIncidentListQuery($page: Int!, $filter: ACPowerEventFilter, $orderBy: ACPowerEventOrdering) {
                acEvents(page: $page, orderBy: $orderBy, filters: $filter) {
                    total
                    pageInfo {
                        page
                        total
                        hasNext
                        hasPrevious
                    }
                    data {
                        id
                        worstStatus
                        duration
                        startTime
                        endTime
                        device {
                            name
                            site {
                                name
                                address {
                                    state
                                }
                            }
                        }
                        affectedFeeds {
                            id
                            label
                            status
                            voltage
                        }
                        ...DeviceIncidentExpandedRow_event
                    }
                }
                overallIncidents: acEvents(filters: $filter) {
                    total
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const exportIncidents = async () => {
        show({ text: 'Exporting...', variant: 'info' });
        const exportVariables = {
            filter: { ...filterObject },
        };

        try {
            const results = await fetchQuery<ACPowerIncidentListExportQuery>(
                environment,
                IncidentsQuery,
                exportVariables
            ).toPromise();

            if (!results) {
                show({ text: 'Failed to export', variant: 'error' });
                return;
            }

            const customHeader = [
                'Start Time',
                'Device ID',
                'Device Name',
                'Site ID',
                'Site Name',
                'Duration (Minutes)',
                'Incident Type',
                `Affected Feed IDs`,
                `Affected Feed Statuses`,
                `Affected Feed Voltages`,
            ];

            const rows = results!.acEvents.data.map(incident => {
                const duration = humanizeDuration(Duration.fromISO(incident.duration).as('milliseconds'), {
                    largest: 1,
                    round: true,
                    units: ['h', 'm'],
                });
                const affectedFeedName = incident.affectedFeeds.map(feed => feed.label);
                const affectedFeedStatus = incident.affectedFeeds.map(feed => capitalize(feed.status));
                const affectedFeedVoltage = incident.affectedFeeds.map(feed => feed.voltage);

                const row = [
                    `"${incident.startTime}"`,
                    `"${incident.device.id}"`,
                    `"${incident.device.name}"`,
                    `"${incident.device.site.id}"`,
                    `"${incident.device.site.name}"`,
                    `"${duration}"`,
                    `"${capitalize(incident.worstStatus)}"`,
                    `"${affectedFeedName}"`,
                    `"${affectedFeedStatus}"`,
                    `"${affectedFeedVoltage ?? ''}"`,
                ];

                return row;
            });

            exportAsCsv('ac-power-incidents.csv', customHeader, rows);

            show({ text: 'Exported successfully', variant: 'info' });
        } catch (error) {
            captureException(error, scope => {
                scope.setExtra('variables', exportVariables);
                scope.setTag('Component', 'ACPowerDeviceIncidentList');
                scope.setTag('Functionality', 'Exporting');
                return scope;
            });
            show({ text: 'Failed to export', variant: 'error' });
        }
    };

    return (
        <TableLayout
            title='AC Power Incidents'
            data={props?.acEvents.data ?? null}
            columns={IncidentTableBaseColumns}
            allowEditingColumns
            dispatchFilterState={dispatchFilters}
            dispatchTableState={dispatchTableState}
            filterState={filters}
            getRowId={(incident: Incidents) => incident.id}
            renderSearchResultAsString={() => ''}
            searchPlaceholder=''
            tableState={tableState}
            isProcessing={!!props && isFetching}
            page={props?.acEvents?.pageInfo?.page}
            pageCount={props?.acEvents?.pageInfo?.total}
            overallCount={props?.overallIncidents.total}
            resultCount={props?.acEvents?.total}
            hasError={!!error}
            onRetry={retry}
            emptyMessage='There are no incidents to display.'
            unit='Incident'
            additionalActions={[
                {
                    buttonText: 'Export',
                    buttonIcon: <ArrowDownIcon />,
                    onClick: () => exportIncidents(),
                },
            ]}
            rowExpansionComponent={row => (
                <DeviceIncidentExpandedRow
                    row={row.original}
                    site={{
                        name: row.original.device.site.name,
                        state: row.original.device.site.address.state,
                    }}
                />
            )}
        />
    );
};

const IncidentsQuery = graphql`
    query ACPowerIncidentListExportQuery($filter: ACPowerEventFilter) {
        acEvents(pageSize: 10000, filters: $filter) {
            total
            data {
                id
                worstStatus
                duration
                startTime
                endTime
                device {
                    id
                    name
                    site {
                        id
                        name
                    }
                }
                affectedFeeds {
                    id
                    label
                    status
                    voltage
                }
            }
        }
    }
`;
