import React, { FC } from 'react';

import { PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { isFeatureEnabled } from 'lib/featureFlag';
import { useQuery } from 'lib/query-helpers';

import { useDocumentTitle } from '../../components';
import { useCurrentUser } from '../../lib/auth';
import { DashboardQuery } from './__generated__/DashboardQuery.graphql';
import style from './style.module.css';
import {
    BatteryReportsSection,
    CountsSection,
    FirmwareSection,
    OverallPerformanceSection,
    PowerControllersSection,
} from './subviews';

export const Dashboard: FC = () => {
    const currentUser = useCurrentUser();
    useDocumentTitle('Overview');

    const {
        data: props,
        error,
        retry,
    } = useQuery<DashboardQuery>(
        graphql`
            query DashboardQuery {
                ...ActiveDevices
                ...OverallPerformanceSection
                ...PowerControllersSection
                ...BatteryReportsSection
            }
        `,
        {}
    );

    return (
        <div className='space-y-6'>
            <PageHeading value={`Welcome ${currentUser?.name.split(' ')[0]}`} />

            <CountsSection data={props ?? null} error={!!error} retry={retry} />

            <OverallPerformanceSection data={props ?? null} error={!!error} retry={retry} />

            <div className={style.horizontal_line_spacing}>
                <div className='horizontal-line' />
            </div>

            <PowerControllersSection data={props ?? null} error={!!error} retry={retry} />

            <div className={style.horizontal_line_spacing}>
                <div className='horizontal-line' />
            </div>

            {isFeatureEnabled('firmware') && (
                <>
                    <FirmwareSection />

                    <div className={style.horizontal_line_spacing}>
                        <div className='horizontal-line' />
                    </div>
                </>
            )}

            <BatteryReportsSection data={props ?? null} error={!!error} retry={retry} />

            <div className={style.horizontal_line_spacing}>
                <div className='horizontal-line' />
            </div>

            {/* Tasks overview */}
        </div>
    );
};
