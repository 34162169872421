import React, { FC, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { Button, InfoTip, PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { SelectionWithTestIds } from 'components/SelectTestPane';
import {
    BatteryTestResultsColumn,
    batteryTestResultToFilterObject,
    useTestResultFilter,
} from 'filters/battery-test-results-model';
import { SortDirection, useTableReducer } from 'layouts';
import { CoreTableLayout } from 'layouts/TableLayout/CoreTableLayout';
import { useQuery } from 'lib/query-helpers';
import { IEnvironment } from 'relay-runtime';

import {
    TestSelectModalContentAllIdsQuery,
    TestSelectModalContentAllIdsQuery$variables,
} from './__generated__/TestSelectModalContentAllIdsQuery.graphql';
import {
    TestResultOrdering,
    TestResultSortField,
    TestSelectModalContentQuery,
    TestSelectModalContentQuery$data,
    TestSelectModalContentQuery$variables,
} from './__generated__/TestSelectModalContentQuery.graphql';
import { MultiDeviceTestsBaseTableColumns, SingleDeviceTestsBaseTableColumns } from './settings';
import style from './style.module.css';

type BatteryTestResults = TestSelectModalContentQuery$data['batteryTestResults']['data'][number];

interface TestSelectModalProps {
    /**
     * A unique prefix key that will be used to store the table state in local storage.
     * It acts as the table unique ID to the page that is using this component.
     */
    tableStorageKeyPrefix: string;
    testIds: string[];
    isTestSelectModalOpen: boolean;
    setIsCompareModalOpen: (value: boolean) => void;
    setSelectedTestsCompare: (ids: SelectionWithTestIds | null) => void;
    /**
     * If limit is set, the user will not be able to proceed if the number of selected tests is greater than the limit.
     * An additional subheading will be displayed to indicate the limit.
     */
    limit?: number;
    /**
     * This number will be added on top of the table selected items count.
     * This can be useful if there is another test that is not part of the table that will always be selected
     */
    additionalCount?: number;
    /**
     * This message will be displayed as a tooltip on the subheading (that indicates the limit of selected tests)
     */
    extraMessage?: string;
    /**
     * If true, the device column will be included in the table, replacing the Type column
     * @default false
     */
    includeDeviceColumn?: boolean;

    initialSelection?: string[];
}

export const TestSelectModalContent: FC<TestSelectModalProps> = ({
    tableStorageKeyPrefix,
    testIds,
    isTestSelectModalOpen,
    setIsCompareModalOpen,
    setSelectedTestsCompare,
    limit,
    additionalCount = 0,
    extraMessage,
    includeDeviceColumn = false,
    initialSelection = [],
}) => {
    const environment = useRelayEnvironment();

    const baseColumns = includeDeviceColumn ? MultiDeviceTestsBaseTableColumns : SingleDeviceTestsBaseTableColumns;

    const [tableState, dispatchTableState] = useTableReducer<BatteryTestResultsColumn>({
        defaultSortColumn: BatteryTestResultsColumn.StartTime,
        defaultSortDirection: SortDirection.Descending,
        allColumns: baseColumns,
        defaultVisibleColumns: baseColumns.map(column => column.id),
        storageKeyPrefix: tableStorageKeyPrefix,
        initialSelection: initialSelection,

        disableUrlSortSync: true,
    });

    const [filters, dispatchFilters] = useTestResultFilter(testIds);
    const filterObject = useMemo(() => batteryTestResultToFilterObject(filters), [filters]);

    const sortObject: TestResultOrdering = {
        field: tableState.sortColumn as TestResultSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: TestSelectModalContentQuery$variables = {
        ids: testIds,
        page: tableState.page,
        orderBy: sortObject,
        filters: filterObject,
    };

    const {
        data: props,
        error,
        retry,
        isFetching,
    } = useQuery<TestSelectModalContentQuery>(
        graphql`
            query TestSelectModalContentQuery(
                $page: Int = 1
                $ids: [String!]
                $orderBy: TestResultOrdering
                $filters: TestResultFilter
            ) {
                batteryTestResults(
                    ids: $ids
                    page: $page
                    pageSize: 10
                    state: [Passed, Failed, Aborted, Inconclusive, InProgress, Finalizing, Analyzing]
                    orderBy: $orderBy
                    filters: $filters
                ) {
                    data {
                        id
                        name
                        commencedTime
                        completedTime
                        cause
                        device {
                            name
                        }
                        task {
                            name
                            type
                        }
                        state
                    }
                    pageInfo {
                        page
                        total
                        hasNext
                        hasPrevious
                    }
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
            skip: !isTestSelectModalOpen,
        }
    );

    const onCompareClicked = () => {
        setSelectedTestsCompare({
            ids: tableState.selectedItems,
            text: 'Custom',
        });
        setIsCompareModalOpen(false);
    };

    const onCancelClicked = () => {
        setSelectedTestsCompare(null);
        setIsCompareModalOpen(false);
    };

    const pageInfo = {
        hasNext: props?.batteryTestResults.pageInfo.hasNext ?? false,
        hasPrevious: props?.batteryTestResults.pageInfo.hasPrevious ?? false,
        page: props?.batteryTestResults.pageInfo.page ?? 1,
        total: props?.batteryTestResults.pageInfo.total ?? 1,
    };

    const actualSelectedCount = tableState.selectedItems.length + additionalCount;

    let errorMessage;
    if (typeof limit === 'number' && actualSelectedCount > limit) {
        errorMessage = `You can only select a maximum of ${limit} tests`;
    } else if (tableState.selectedItems.length === 0) {
        errorMessage = 'Select a test for comparison.';
    }

    return (
        <div className={style.container}>
            <div>
                <PageHeading value='Select tests to compare' />
                {typeof limit === 'number' && (
                    <div className='flex flex-row'>
                        <PageHeading
                            subheading
                            secondary={tableState.selectedItems.length > 0 && actualSelectedCount <= limit}
                            value={`${actualSelectedCount} out of ${limit} tests selected`}
                        />
                        {extraMessage && <InfoTip message={extraMessage} />}
                    </div>
                )}
                <CoreTableLayout
                    tableSize='compact'
                    data={props?.batteryTestResults.data as BatteryTestResults[]}
                    columns={baseColumns}
                    getRowId={row => row.id}
                    filterState={filters}
                    dispatchFilterState={dispatchFilters}
                    tableState={tableState}
                    dispatchTableState={dispatchTableState}
                    selection
                    onRequestAllIds={() => getAllTestIds(environment, testIds, filterObject)}
                    tableVariant='white'
                    clickBehaviour='select'
                    hasError={!!error}
                    onRetry={retry}
                    isProcessing={!!props && isFetching}
                    emptyMessage='There are no tests present'
                    page={pageInfo.page}
                    pageCount={pageInfo.total}
                />
            </div>
            <div className='min-h-28 pb-6 flex flex-col justify-end'>
                {errorMessage && <span className='text-coralRegular text-sm mt-2'>{errorMessage}</span>}
                <div className='space-x-2 pt-4'>
                    <Button
                        buttonText='Compare selected'
                        onClick={onCompareClicked}
                        disabled={
                            tableState.selectedItems.length === 0 ||
                            (typeof limit === 'number' && actualSelectedCount > limit)
                        }
                    />
                    <Button buttonText='Cancel' variant='gray' onClick={onCancelClicked} />
                </div>
            </div>
        </div>
    );
};

function getAllTestIds(
    environment: IEnvironment,
    testIds: string[],
    filters: Record<string, unknown>
): Promise<string[]> {
    const getAllTestIdsQuery = graphql`
        query TestSelectModalContentAllIdsQuery($ids: [String!], $filters: TestResultFilter) {
            batteryTestResults(ids: $ids, pageSize: 10000, filters: $filters) {
                data {
                    id
                }
            }
        }
    `;

    const variables: TestSelectModalContentAllIdsQuery$variables = {
        ids: testIds,
        filters: filters,
    };

    return fetchQuery<TestSelectModalContentAllIdsQuery>(environment, getAllTestIdsQuery, variables)
        .toPromise()
        .then(data => data?.batteryTestResults.data.map(test => test.id) ?? []);
}
