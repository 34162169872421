import React, { FC, useCallback } from 'react';

import { Button, FormikGeneralSelect } from '@accesstel/pcm-ui';

import { Form, useFormikContext } from 'formik';

import {
    PresetOptions,
    UnitPreset,
    UnitPresetLabels,
    UnitPressureOptions,
    UnitTemperatureOptions,
    UnitVolumeOptions,
    UnitVolumetricFlowRateOptions,
    convertOptionsToSelectOptions,
    renderOption,
} from '../lib/units';
import { UnitSettingsFormValues } from '../schema';

export const UnitSettingsContent: FC = () => {
    const { dirty, isSubmitting, isValid, setValues, setFieldValue } = useFormikContext<UnitSettingsFormValues>();

    const handlePresetChange = useCallback(
        (preset: UnitPreset) => {
            if (preset === UnitPreset.Custom) {
                return;
            }

            // Apply the preset
            const presetValues = PresetOptions[preset];
            const newValues: UnitSettingsFormValues = {
                ...presetValues,
                preset,
            };

            setValues(newValues);
        },
        [setValues]
    );

    const handleUnitSelect = useCallback(() => {
        // Any unit change should set the preset to custom
        setFieldValue('preset', UnitPreset.Custom);
    }, [setFieldValue]);

    return (
        <Form>
            <div className='space-y-4 grid grid-cols-2 pt-6 gap-x-6 pr-6'>
                <FormikGeneralSelect
                    name='preset'
                    placeHolder='Preset'
                    light
                    variant='outlined'
                    items={[UnitPreset.Metric, UnitPreset.Imperial, UnitPreset.Custom]}
                    renderItem={item => UnitPresetLabels[item as UnitPreset]}
                    onSelectItem={handlePresetChange}
                />

                <div className='col-span-2 font-bold'>Units</div>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='temperature'
                        placeHolder='Temperature'
                        light
                        variant='outlined'
                        items={convertOptionsToSelectOptions(UnitTemperatureOptions)}
                        renderItem={item => renderOption(item, UnitTemperatureOptions)}
                        onSelectItem={handleUnitSelect}
                    />
                </div>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='pressure'
                        placeHolder='Pressure'
                        light
                        variant='outlined'
                        items={convertOptionsToSelectOptions(UnitPressureOptions)}
                        renderItem={item => renderOption(item, UnitPressureOptions)}
                        onSelectItem={handleUnitSelect}
                    />
                </div>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='volume'
                        placeHolder='Volume'
                        light
                        variant='outlined'
                        items={convertOptionsToSelectOptions(UnitVolumeOptions)}
                        renderItem={item => renderOption(item, UnitVolumeOptions)}
                        onSelectItem={handleUnitSelect}
                    />
                </div>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='volumetricFlowRate'
                        placeHolder='Volumetric Flow Rate'
                        light
                        variant='outlined'
                        items={convertOptionsToSelectOptions(UnitVolumetricFlowRateOptions)}
                        renderItem={item => renderOption(item, UnitVolumetricFlowRateOptions)}
                        onSelectItem={handleUnitSelect}
                    />
                </div>
            </div>
            <div className='flex flex-row gap-4 justify-end'>
                <Button
                    type='reset'
                    variant='white'
                    buttonText='Reset'
                    disabled={isSubmitting || !dirty}
                    size='small'
                />
                <Button
                    type='submit'
                    variant='primary'
                    buttonText='Save'
                    disabled={isSubmitting || !isValid || !dirty}
                    processing={isSubmitting}
                    size='small'
                />
            </div>
        </Form>
    );
};
