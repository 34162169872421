import React from 'react';

import { TestNameFilterUI } from 'components/TestSelectModalContent/components';
import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import { DeviceNameFilter, DeviceNameFilterUI, decodeDeviceNameFilter, encodeDeviceNameFilter } from 'filters/device';
import {
    DeviceTestResultRunTimeFilter,
    DeviceTestResultStartTimeFilter,
    DeviceTestResultStateFilter,
    DeviceTestResultTypeFilter,
    DeviceTestResultTypeFilterUI,
} from 'filters/device-test-result';
import { describeBatteryTestType } from 'filters/device-test-result/helpers';
import {
    TaskDateFilterUI,
    TaskRunTimeFilterUI,
    decodeBatteryTestNameFilter,
    decodeBatteryTestRuntimeFilter,
    decodeBatteryTestTypeFilter,
    encodeBatteryTestNameFilter,
    encodeBatteryTestRuntimeFilter,
    encodeBatteryTestTypeFilter,
} from 'filters/task';
import { TestResultStateFilterUI, decodeTestResultStateFilter, encodeTestResultStateFilter } from 'filters/test-result';

import { FilterDefinition, FilterValueMap, decodeDateRangeFilter, encodeDateRangeFilter } from '../common';
import { BatteryTestResultNameFilter, BatteryTestResultsColumn } from './types';

export type BatteryTestResultColumnFilterMap = {
    [BatteryTestResultsColumn.StartTime]: DeviceTestResultStartTimeFilter | null;
    [BatteryTestResultsColumn.TaskName]: BatteryTestResultNameFilter[];
    [BatteryTestResultsColumn.RunTime]: DeviceTestResultRunTimeFilter | null;
    [BatteryTestResultsColumn.State]: DeviceTestResultStateFilter[];
    [BatteryTestResultsColumn.Type]: DeviceTestResultTypeFilter[];
    [BatteryTestResultsColumn.DeviceName]: DeviceNameFilter[];
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type BatteryTestResultExtraFilterMap = {};

export type BatteryTestResultAllFilterMap = BatteryTestResultColumnFilterMap & BatteryTestResultExtraFilterMap;

export const DefaultValues: FilterValueMap<BatteryTestResultColumnFilterMap> = {
    [BatteryTestResultsColumn.StartTime]: null,
    [BatteryTestResultsColumn.TaskName]: [],
    [BatteryTestResultsColumn.RunTime]: null,
    [BatteryTestResultsColumn.State]: [],
    [BatteryTestResultsColumn.Type]: [],
    [BatteryTestResultsColumn.DeviceName]: [],
};

export const DefaultExtraValues: FilterValueMap<BatteryTestResultExtraFilterMap> = {};

export const StaticBatteryTestResultFilterDefinitions: FilterDefinition<BatteryTestResultsColumn>[] = [
    {
        id: BatteryTestResultsColumn.TaskName,
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: BatteryTestResultsColumn.TaskName,
        component: meta => props => <TestNameFilterUI {...props} testIds={(meta as { testIds: string[] }).testIds} />,
        describeValue: (value: BatteryTestResultNameFilter) => value.name,
        encodeValue: encodeBatteryTestNameFilter,
        decodeValue: decodeBatteryTestNameFilter,
    },
    {
        id: BatteryTestResultsColumn.Type,
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: BatteryTestResultsColumn.Type,
        component: () => DeviceTestResultTypeFilterUI,
        describeValue: describeBatteryTestType,
        encodeValue: encodeBatteryTestTypeFilter,
        decodeValue: decodeBatteryTestTypeFilter,
    },
    {
        id: BatteryTestResultsColumn.State,
        type: 'multi',
        name: 'Result',
        category: 'Result',
        column: BatteryTestResultsColumn.State,
        component: () => TestResultStateFilterUI,
        describeValue: (value: DeviceTestResultStateFilter) => value.name,
        encodeValue: encodeTestResultStateFilter,
        decodeValue: decodeTestResultStateFilter,
    },
    {
        id: BatteryTestResultsColumn.StartTime,
        type: 'single',
        name: 'Date',
        category: 'Date',
        column: BatteryTestResultsColumn.StartTime,
        component: () => TaskDateFilterUI,
        describeValue: (value: DeviceTestResultStartTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: BatteryTestResultsColumn.RunTime,
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: BatteryTestResultsColumn.RunTime,
        component: () => TaskRunTimeFilterUI,
        describeValue: formatDurationFilter,
        encodeValue: encodeBatteryTestRuntimeFilter,
        decodeValue: decodeBatteryTestRuntimeFilter,
    },
    {
        id: BatteryTestResultsColumn.DeviceName,
        type: 'multi',
        name: 'Device',
        category: 'Device',
        column: BatteryTestResultsColumn.DeviceName,
        component: () => DeviceNameFilterUI,
        describeValue: (value: DeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
];
