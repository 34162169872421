import graphql from 'babel-plugin-relay/macro';

export const getDeviceTypesQuery = graphql`
    query queries_GetDeviceTypesQuery {
        deviceTypes {
            data {
                id
                category
                displayName
            }
        }
    }
`;

export const getFirmwareVersionsQuery = graphql`
    query queries_GetFirmwareVersionsQuery {
        firmwareVersions {
            data {
                id
                name
            }
        }
    }
`;

export const getSiteNamesQuery = graphql`
    query queries_GetSiteNamesQuery($search: String!, $limit: Int!) {
        sites(search: $search, page: 1, pageSize: $limit) {
            data {
                id
                name
            }
        }
    }
`;

export const getBatteryMakeModel = graphql`
    query queries_GetBatteryMakeModelQuery($search: String, $limit: Int!) {
        batteryTypes(search: $search, page: 1, pageSize: $limit) {
            data {
                id
                manufacturer
                model
            }
        }
    }
`;

export const getDevicesByNameQuery = graphql`
    query queries_GetDevicesByNameQuery($name: String, $limit: Int!, $siteId: [ID!]) {
        devices(search: $name, page: 1, pageSize: $limit, filters: { siteId: $siteId }) {
            data {
                id
                name
                site {
                    name
                }
            }
        }
    }
`;

export const getAssetsDistribution = graphql`
    query queries_GetAssetsDistributionQuery($type: AssetDistributionType!) {
        assetDistribution(type: $type) {
            distribution {
                key
                displayName
                value
            }
        }
    }
`;
