import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, useFragment, useRelayEnvironment } from 'react-relay';

import { Domain, MenuItemType, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { TimeseriesOffsetSeries } from 'lib/dateChart';
import { useZoomableData } from 'lib/line-chart-zoom';
import { DateTime, Duration } from 'luxon';
import { MetricLine } from 'views/tasks/battery-health/components/MetricsLine';
import { ChartRefreshInterval, ChartTickCount } from 'views/tasks/battery-health/settings';
import { getDisplayInterval } from 'views/tasks/battery-health/test-result-view/common';
import { MenuOptions } from 'views/tasks/battery-health/test-result-view/lib/common';

import { LoadChartQuery } from './__generated__/LoadChartQuery.graphql';
import { LoadChart_info$key } from './__generated__/LoadChart_info.graphql';

const DefaultChartXDomain: Domain<Duration> = ['dataMin', 'dataMax'];
const InitialLiveXBuffer = Duration.fromObject({ minutes: 15 });
const DefaultXMinTicks = Math.round(InitialLiveXBuffer.as('minutes')) + 1;

interface TimeseriesOffsetPoint {
    timestamp: DateTime;
    offset: Duration;
    value: number;
}

type MetricsData = [TimeseriesOffsetPoint[] | null];

interface AggregatedDataPoint {
    readonly timestamp: unknown;
    readonly offset: unknown;
    readonly value: number | null;
    readonly stdDeviation?: number | null;
}

export interface LoadChartProps {
    runReport: LoadChart_info$key;
}

export const LoadChart: FC<LoadChartProps> = ({ runReport }) => {
    const environment = useRelayEnvironment();
    const [selectedMenuOption, setSelectedMenuOption] = useState<string | undefined>(MenuOptions.ViewAll);
    const infoData = useFragment<LoadChart_info$key>(LoadChartFragment, runReport);

    const startTime = infoData.generatorStartTime;
    const endTime = infoData.generatorStopTime;
    let isLiveUpdating = false;

    if (infoData.state !== 'Completed' && infoData.state !== 'Error') {
        isLiveUpdating = true;
    }

    const fetchData = useCallback(
        (interval: Duration | null, begin: Duration | null, end: Duration | null) => {
            if (!interval && startTime) {
                if (!endTime) {
                    interval = getDisplayInterval(DateTime.fromISO(startTime), DateTime.now(), ChartTickCount);
                } else {
                    interval = getDisplayInterval(
                        DateTime.fromISO(startTime),
                        DateTime.fromISO(endTime),
                        ChartTickCount
                    );
                }
            }

            return fetchQuery<LoadChartQuery>(environment, LoadChartDataQuery, {
                id: infoData.id,
                begin: begin?.toISO(),
                end: end?.toISO(),
                interval: interval?.toISO(),
            }).toPromise();
        },
        [startTime, environment, infoData.id, endTime]
    );

    const zoomMenuOptions: MenuItemType[] = [
        {
            id: MenuOptions.ViewAll,
            name: 'All',
            onClick() {
                doZoom(null);
                setSelectedMenuOption(MenuOptions.ViewAll);
            },
        },
    ];

    const { chartDomain, doZoom, baseData, hasError, reloadData, zoomData } = useZoomableData(
        DefaultChartXDomain,
        (_, zoomed) => {
            if (!zoomed) {
                setSelectedMenuOption(MenuOptions.ViewAll);
                return;
            }

            setSelectedMenuOption(undefined);
        },
        fetchData,
        {
            averageTickCount: ChartTickCount,
        }
    );

    useEffect(() => {
        if (isLiveUpdating) {
            const handle = setInterval(reloadData, ChartRefreshInterval);
            return () => {
                clearInterval(handle);
            };
        }
    }, [isLiveUpdating, reloadData]);

    const [data] = useMemo<[MetricsData]>(() => {
        if (baseData?.generatorRunReport) {
            const baseMetrics: MetricsData = [
                toTimeSeriesOffsetData(baseData.generatorRunReport.aggregatedSiteLoad as AggregatedDataPoint[]),
            ];

            if (zoomData?.generatorRunReport) {
                return [baseMetrics];
            } else {
                return [baseMetrics];
            }
        } else {
            return [[null]];
        }
    }, [baseData, zoomData]);

    const loadData = data[0];

    const loadSeries: TimeseriesOffsetSeries[] = [
        {
            name: 'Load',
            color: Theme.coralRegular,
            points: loadData ?? [],
        },
    ];

    let overrideChartDomain: Domain<Duration> = chartDomain;
    let minXTicks = 0;
    if (zoomData == null && baseData != null) {
        const chartData = baseData.generatorRunReport?.aggregatedSiteLoad;

        if (chartData && chartData.length > 0) {
            const currentDuration = Duration.fromISO(chartData[chartData.length - 1].offset as string);
            if (currentDuration < InitialLiveXBuffer) {
                overrideChartDomain = [chartDomain[0], InitialLiveXBuffer];
                minXTicks = DefaultXMinTicks;
            }
        } else {
            overrideChartDomain = [chartDomain[0], InitialLiveXBuffer];
            minXTicks = DefaultXMinTicks;
        }
    }

    return (
        <MetricLine
            type='load'
            data={loadData}
            series={loadSeries}
            error={hasError}
            onRetry={reloadData}
            xDomain={overrideChartDomain}
            xMinTicks={minXTicks}
            onZoom={doZoom}
            menuSelected={selectedMenuOption}
            menuItems={zoomMenuOptions}
            menuPlaceholder='Zoom'
        />
    );
};

const LoadChartFragment = graphql`
    fragment LoadChart_info on GeneratorRunReport {
        id
        state
        generatorStartTime
        generatorStopTime
    }
`;

const LoadChartDataQuery = graphql`
    query LoadChartQuery($id: ID!, $interval: Duration, $begin: Duration, $end: Duration) {
        generatorRunReport(id: $id) {
            aggregatedSiteLoad(unit: Amps, interval: $interval, begin: $begin, end: $end) {
                timestamp
                offset
                value
            }
        }
    }
`;

function toTimeSeriesOffsetData(points: AggregatedDataPoint[] | null | undefined): TimeseriesOffsetPoint[] | null {
    if (!points) {
        return null;
    }

    return points.flatMap(point => {
        if (point.value !== null) {
            return [
                {
                    timestamp: DateTime.fromISO(point.timestamp as string),
                    offset: Duration.fromISO(point.offset as string),
                    value: point.value,
                },
            ];
        } else {
            return [];
        }
    });
}
