import React from 'react';
import { RouteObject } from 'react-router-dom';

import { AuthenticatedSection } from 'components/AuthenticatedSection';
import { getBatteryTypeBreadCrumb, getDeviceBreadcrumb, getSiteBreadcrumb } from 'lib/breadcrumb';
import { RouteExtraData } from 'lib/route-helpers';

import { AssetManagement } from './AssetManagement';
import { AddBatteryTypeForm, EditBatteryTypeForm, ManageBatteryTypes } from './battery-type';
import { AddDeviceForm, EditDeviceForm, ManageDevices } from './device';
import { AddSiteForm, EditSiteForm, ManageSites } from './site';

const Routes: RouteObject[] = [
    {
        path: 'manage',
        element: <AuthenticatedSection assetsRead />,
        handle: {
            title: 'Manage',
            altTitle: 'Manage Assets',
            description: 'Manage your sites, devices, and battery types',
            permissions: { assetsRead: true },
        } as RouteExtraData,
        children: [
            {
                index: true,
                element: <AssetManagement />,
            },
            {
                path: 'sites',
                handle: {
                    title: 'Sites',
                    altTitle: 'Manage Sites',
                    description: 'Manage your sites',
                } as RouteExtraData,
                children: [
                    {
                        index: true,
                        element: <ManageSites />,
                        handle: {
                            title: 'Sites',
                            altTitle: 'Manage Sites',
                            description: 'Manage your sites',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'add',
                        element: <AuthenticatedSection assetsWrite />,
                        handle: {
                            title: 'Add Site',
                            altTitle: 'Add Site',
                            description: 'Register a new site',
                            permissions: { assetsWrite: true },
                        } as RouteExtraData,
                        children: [
                            {
                                index: true,
                                element: <AddSiteForm />,
                            },
                        ],
                    },
                    {
                        path: ':id',
                        element: <EditSiteForm />,
                        handle: {
                            title: params => getSiteBreadcrumb(params.id!, true),
                            altTitle: 'Edit Site',
                            permissions: { assetsWrite: true },
                        } as RouteExtraData<{ id: string }>,
                    },
                ],
            },
            {
                path: 'devices',
                handle: {
                    title: 'Devices',
                    altTitle: 'Manage Devices',
                    description: 'Manage your devices',
                } as RouteExtraData,
                children: [
                    {
                        index: true,
                        element: <ManageDevices />,
                        handle: {
                            title: 'Devices',
                            altTitle: 'Manage Devices',
                            description: 'Manage your devices',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'add',
                        element: <AuthenticatedSection assetsWrite />,
                        handle: {
                            title: 'Add Device',
                            altTitle: 'Add Device',
                            description: 'Register a new device',
                            permissions: { assetsWrite: true },
                        } as RouteExtraData,
                        children: [
                            {
                                index: true,
                                element: <AddDeviceForm />,
                            },
                        ],
                    },
                    {
                        path: ':id',
                        element: <EditDeviceForm />,
                        handle: {
                            title: params => getDeviceBreadcrumb(params.id!),
                            altTitle: 'Edit Device',
                            permissions: { assetsWrite: true },
                        } as RouteExtraData<{ id: string }>,
                    },
                ],
            },
            {
                path: 'batterytypes',
                handle: {
                    title: 'Battery Types',
                    altTitle: 'Manage Battery Types',
                    description: 'Manage your battery types',
                } as RouteExtraData,
                children: [
                    {
                        index: true,
                        element: <ManageBatteryTypes />,
                        handle: {
                            title: 'Battery Types',
                            altTitle: 'Manage Battery Types',
                            description: 'Manage your battery types',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'add',
                        element: <AuthenticatedSection assetsWrite />,
                        handle: {
                            title: 'Add Battery Type',
                            altTitle: 'Add Battery Type',
                            description: 'Register a new battery type for devices to use',
                            permissions: { assetsWrite: true },
                        } as RouteExtraData,
                        children: [
                            {
                                index: true,
                                element: <AddBatteryTypeForm />,
                            },
                        ],
                    },
                    {
                        path: ':id',
                        element: <EditBatteryTypeForm />,
                        handle: {
                            title: params => getBatteryTypeBreadCrumb(params.id!),
                            altTitle: 'Edit Battery Type',
                            permissions: { assetsWrite: true },
                        } as RouteExtraData<{ id: string }>,
                    },
                ],
            },
        ],
    },
];

export default Routes;
