/**
 * @generated SignedSource<<c1f09d33b86c7a48bdcb01716f0a0261>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeviceACPower_device$data = {
  readonly acPower: {
    readonly feeds: {
      readonly data: ReadonlyArray<{
        readonly id: string;
        readonly label: string;
        readonly metrics: {
          readonly voltage: {
            readonly values: ReadonlyArray<{
              readonly timestamp: string;
              readonly value: number | null;
            }>;
          } | null;
        };
        readonly " $fragmentSpreads": FragmentRefs<"FeedTile_data">;
      }>;
    };
    readonly threshold: {
      readonly outage: number;
      readonly overVoltage: number;
      readonly underVoltage: number;
    };
    readonly uptime: {
      readonly percentage: number | null;
    };
  };
  readonly health: DeviceHealth | null;
  readonly id: string;
  readonly " $fragmentType": "DeviceACPower_device";
};
export type DeviceACPower_device$key = {
  readonly " $data"?: DeviceACPower_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceACPower_device">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "begin"
    },
    {
      "kind": "RootArgument",
      "name": "end"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceACPower_device",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "health",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceACPower",
      "kind": "LinkedField",
      "name": "acPower",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "from",
              "variableName": "begin"
            },
            {
              "kind": "Variable",
              "name": "to",
              "variableName": "end"
            }
          ],
          "concreteType": "ACUptime",
          "kind": "LinkedField",
          "name": "uptime",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ACPowerThreshold",
          "kind": "LinkedField",
          "name": "threshold",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "outage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "underVoltage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "overVoltage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaginatedACFeeds",
          "kind": "LinkedField",
          "name": "feeds",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ACFeed",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ACFeedMetrics",
                  "kind": "LinkedField",
                  "name": "metrics",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "begin",
                          "variableName": "begin"
                        },
                        {
                          "kind": "Variable",
                          "name": "end",
                          "variableName": "end"
                        },
                        {
                          "kind": "Literal",
                          "name": "interval",
                          "value": "PT15M"
                        }
                      ],
                      "concreteType": "TimeSeriesMetrics",
                      "kind": "LinkedField",
                      "name": "voltage",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TimestampMetric",
                          "kind": "LinkedField",
                          "name": "values",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "timestamp",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "value",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FeedTile_data"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "add3edbea351c5fb0ebc0dcc44de683e";

export default node;
