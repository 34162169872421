import React, { FC, useRef, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useMatches } from 'react-router-dom';

import { GlobalSearch, ResultItem } from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';

import { useUserPermissions } from '../../../lib/auth';
import { useDebouncer } from '../../../lib/debounce';
import { useOutsideClick, useReadableRoutes, useScrollLock } from '../../../lib/hook';
import style from '../style.module.css';
import { doGlobalSearch } from './api-search';
import { combineResults } from './result-combiner';
import { getRelevantRouteItems } from './route-search';

const classNames = classNamesBind.bind(style);

export interface GlobalSearchOverlayProps {
    onClose: () => void;
}

export const GlobalSearchOverlay: FC<GlobalSearchOverlayProps> = ({ onClose }) => {
    const userPermissions = useUserPermissions();
    const routes = useReadableRoutes();
    const environment = useRelayEnvironment();

    const searchRef = useRef<HTMLDivElement | null>(null);
    const [search, setSearch] = useState('');

    const [searchResults, setSearchResults] = useState<ResultItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const matches = useMatches();
    const currentPage = matches[matches.length - 1];

    useScrollLock(true);
    useOutsideClick(true, searchRef, onClose);

    const onClearClick = () => {
        setSearch('');
        setSearchResults([]);
    };

    const debouncedSearch = useDebouncer((value: string, onCompleted: (results: ResultItem[]) => void) => {
        if (value.trim() === '') {
            onCompleted([]);
            return;
        }

        doGlobalSearch(environment, value, userPermissions, onCompleted);
    }, 300);

    const onSearchChange = (value: string) => {
        setSearch(value);

        setIsLoading(true);
        debouncedSearch(value, searchResults => {
            const relatedRoutes = getRelevantRouteItems(value, currentPage.pathname, routes);
            setSearchResults(combineResults(value, searchResults, relatedRoutes));
            setIsLoading(false);
        });
    };

    return (
        <div className={classNames('search_overlay')}>
            <div
                ref={searchRef}
                className={classNames('global_search')}
                onClick={e => e.stopPropagation()} // This is required to prevent the X click from closing the search
            >
                <GlobalSearch
                    search={search}
                    results={searchResults}
                    onClearClick={onClearClick}
                    onSearchChange={onSearchChange}
                    autoFocus
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};
