import React, { FC } from 'react';

import { FormikCheckbox, FormikTextField } from '@accesstel/pcm-ui';

import { Form, useFormikContext } from 'formik';

export interface ExportFormProps {
    pages: number;
    currentPage: number;
}

export const ExportForm: FC<ExportFormProps> = ({ pages, currentPage }) => {
    const { setFieldValue } = useFormikContext();
    return (
        <Form className='px-4 mt-2'>
            <FormikTextField name='filename' placeHolder='Filename' light />

            <div className='grid grid-cols-2 gap-6 mt-4'>
                <FormikTextField name='pageStart' placeHolder='Page Start' type='number' light />
                <FormikTextField name='pageEnd' placeHolder='Page End' type='number' light />
            </div>
            <div className='flex flex-row font-normal text-xs mt-2 gap-2'>
                <button
                    type='button'
                    className='text-coralRegular hover:underline'
                    onClick={() => {
                        setFieldValue('pageStart', 1);
                        setFieldValue('pageEnd', pages);
                    }}
                >
                    Select all
                </button>
                <button
                    type='button'
                    className='text-coralRegular hover:underline'
                    onClick={() => {
                        setFieldValue('pageStart', currentPage);
                        setFieldValue('pageEnd', currentPage);
                    }}
                >
                    Select current
                </button>
            </div>
            <div className='mt-2'>
                <FormikCheckbox name='allColumns' placeHolder='Export all columns' variant='white' />
            </div>
        </Form>
    );
};
