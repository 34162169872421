import React from 'react';
import { Environment, fetchQuery } from 'react-relay';
import { generatePath } from 'react-router-dom';

import {
    BatteryChargingIcon,
    BatteryEmptyIcon,
    DeviceIcon,
    GeneratorIcon,
    HighlightContext,
    PenIcon,
    ReportsIcon,
    ResultItem,
    ResultItemAction,
    SiteIcon,
    createHighlightContext,
    isRelevant,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { UserPermissions } from '../../../lib/auth';
import { getDateTimeFormat } from '../../../lib/dateFormatter';
import { Paths } from '../../../lib/routes';
import { apiSearchQuery } from './__generated__/apiSearchQuery.graphql';

export function doGlobalSearch(
    environment: Environment,
    query: string,
    userPermissions: UserPermissions,
    onCompleted: (results: ResultItem[]) => void
): void {
    const searchResults: ResultItem[] = [];
    fetchQuery<apiSearchQuery>(environment, SearchQuery, { search: query }).subscribe({
        next: response => {
            if (response?.search) {
                for (const item of response.search) {
                    const result = convertResult(item, userPermissions, query);

                    if (result) {
                        searchResults.push(result);
                    }
                }
            }
        },
        complete: () => {
            onCompleted(searchResults);
        },
    });
}

function convertResult(
    item: apiSearchQuery['response']['search'][number],
    permissions: UserPermissions,
    query: string
): ResultItem | undefined {
    let title = '';
    let subtitle = '';
    let path = '';
    let icon = null;
    let action: ResultItemAction | undefined;

    const { hasAssetsWrite } = permissions;

    switch (item.__typename) {
        case 'Device': {
            title = item.name ? item.name : 'Unnamed Device';
            subtitle = item.site ? item.site.name : '';

            const connectionAddresses = item.connectionSettings ? [...item.connectionSettings.addresses] : [];
            const context: HighlightContext = createHighlightContext(query);
            const matchedAddress = connectionAddresses.find(address => isRelevant(address, context));
            if (matchedAddress) {
                subtitle = matchedAddress; // Matched address have a higher priority than site name
            }

            icon = <DeviceIcon />;
            if (item.site) {
                path = generatePath(Paths.SiteViewViewSiteDevice, { siteId: item.site.id, deviceId: item.id });
            }
            if (hasAssetsWrite) {
                action = {
                    icon: <PenIcon />,
                    to: generatePath(Paths.EditDevice, { id: item.id }),
                    tooltip: 'Edit Device',
                };
            }
            break;
        }
        case 'Site':
            title = item.name ? item.name : 'Unnamed Site';
            if (item.address?.address) {
                subtitle += `${item.address.address}, ${item.address.state}`;
            }

            path = generatePath(Paths.SiteViewViewSiteOverview, { siteId: item.id });
            icon = <SiteIcon />;

            if (hasAssetsWrite) {
                action = {
                    icon: <PenIcon />,
                    to: generatePath(Paths.EditSite, { id: item.id }),
                    tooltip: 'Edit Site',
                };
            }
            break;
        case 'BatteryType':
            title = item.model ? item.model : 'Unnamed Model';
            subtitle = item.manufacturer ? item.manufacturer : 'Unknown Manufacturer';
            path = generatePath(Paths.EditBatteryType, { id: item.id });
            icon = <BatteryEmptyIcon />;

            if (hasAssetsWrite) {
                // FIXME: Is it better or confusing to have the same path?
                action = {
                    icon: <PenIcon />,
                    to: generatePath(Paths.EditBatteryType, { id: item.id }),
                    tooltip: 'Edit Battery Type',
                };
            }
            break;
        case 'Task':
        case 'BatteryTest':
            title = item.optName ? item.optName : 'Battery Test';
            subtitle = item.startTime ? getDateTimeFormat(item.startTime) : 'Battery Test'; // Fallback either be scheduled or cancelled
            path = generatePath(Paths.ViewTaskDetails, { id: item.id });
            icon = <BatteryChargingIcon />;
            break;
        case 'DeviceBatteryTestResults':
            title = item.optName ? item.optName : 'Battery Test Result';
            subtitle = item.startTime ? getDateTimeFormat(item.startTime) : 'Battery Test Result';
            icon = <ReportsIcon />;

            if (item.task && item.device) {
                path = generatePath(Paths.ViewTaskDeviceResults, { taskId: item.task.id, deviceId: item.device.id });
            } else {
                path = generatePath(Paths.ViewExternalTestResults, { id: item.id });
            }
            break;
        case 'GeneratorRunReport':
            title = item.optName ? item.optName : 'Generator Run Report';
            subtitle = 'Generator Run Report';
            path = generatePath(Paths.TasksGeneratorsDetails, { id: item.id });
            icon = <GeneratorIcon />;
            break;
        default:
            return undefined;
    }

    return {
        primaryText: title,
        secondaryText: subtitle,
        to: path,
        icon,
        action,
    };
}

const SearchQuery = graphql`
    query apiSearchQuery($search: String!) {
        search(input: $search) {
            __typename
            ... on Device {
                id
                name
                connectionSettings {
                    addresses
                }
                site {
                    id
                    name
                }
            }
            ... on Site {
                id
                name
                address {
                    address
                    state
                }
            }
            ... on BatteryType {
                id
                model
                manufacturer
            }
            ... on Task {
                id
                optName: name
                ... on BatteryTest {
                    startTime
                }
            }
            ... on BatteryTest {
                id
                optName: name
                startTime
            }
            ... on DeviceBatteryTestResults {
                task {
                    id
                }
                device {
                    id
                }
                optName: name
                startTime: commencedTime
            }
            ... on GeneratorRunReport {
                id
                optName: name
            }
        }
    }
`;
