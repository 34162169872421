import React, { FC, ReactNode } from 'react';

import { PlaceholderArea } from '@accesstel/pcm-ui';

import style from '../style.module.css';

interface DomainContainerProps {
    title: string;
    icon: ReactNode;
    unsupported?: boolean;
    unsupportedMessage?: string;
    unsupportedButtonText?: string;
    unsupportedButtonOnClick?: () => void;
    children?: ReactNode;
}

export const DomainContainer: FC<DomainContainerProps> = ({
    title,
    icon,
    unsupported = false,
    unsupportedMessage = 'Unsupported',
    unsupportedButtonText = 'Disabled',
    unsupportedButtonOnClick = () => null,
    children,
}) => {
    if (unsupported) {
        return (
            <PlaceholderArea
                message={unsupportedMessage}
                buttonText={unsupportedButtonText}
                onClick={unsupportedButtonOnClick}
            />
        );
    } else {
        return (
            <div className='bg-grayRegular px-4 pb-1 h-full flex flex-col'>
                <div className={style.header}>
                    <div className={style.icon}>{icon}</div>
                    <div className={style.title_text}>{title}</div>
                </div>
                <div className='flex-grow h-full flex items-center'>{children}</div>
            </div>
        );
    }
};
