/**
 * @generated SignedSource<<e34e5fcccd3c0d3dbcbca817e86c5031>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ManageUsersSearchQuery$variables = {
  pageSize: number;
  search?: string | null;
};
export type ManageUsersSearchQuery$data = {
  readonly users: {
    readonly data: ReadonlyArray<{
      readonly email: string | null;
      readonly name: string | null;
      readonly username: string;
    }>;
  };
};
export type ManageUsersSearchQuery = {
  response: ManageUsersSearchQuery$data;
  variables: ManageUsersSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "PaginatedUsers",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageUsersSearchQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ManageUsersSearchQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "286656e1ec610f695b642ccf2712a9f5",
    "id": null,
    "metadata": {},
    "name": "ManageUsersSearchQuery",
    "operationKind": "query",
    "text": "query ManageUsersSearchQuery(\n  $search: String = \"\"\n  $pageSize: Int!\n) {\n  users(search: $search, pageSize: $pageSize) {\n    data {\n      username\n      name\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f365149d2cc6b2d78bd68bf570710759";

export default node;
