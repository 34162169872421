import React from 'react';

import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import {
    TaskBatteryTestNameFilterUI,
    TaskDateFilterUI,
    TaskRunTimeFilterUI,
    decodeBatteryTestNameFilter,
    decodeBatteryTestRuntimeFilter,
    encodeBatteryTestNameFilter,
    encodeBatteryTestRuntimeFilter,
} from 'filters/task';
import {
    TestResultCauseFilter,
    TestResultStateFilterUI,
    decodeTestResultCauseFilter,
    decodeTestResultStateFilter,
    encodeTestResultCauseFilter,
    encodeTestResultStateFilter,
} from 'filters/test-result';
import { TestResultCauseFilterUI } from 'filters/test-result/components/TestResultCauseFilterUI';

import { FilterDefinition, FilterValueMap, decodeDateRangeFilter, encodeDateRangeFilter } from '../common';
import { DeviceTestResultTypeFilterUI } from './components';
import { decodeDeviceTestResultTypeFilter } from './decode';
import { encodeDeviceTestResultTypeFilter } from './encode';
import { describeBatteryTestType } from './helpers';
import {
    DeviceTestResultNameFilter,
    DeviceTestResultRunTimeFilter,
    DeviceTestResultStartTimeFilter,
    DeviceTestResultStateFilter,
    DeviceTestResultTableColumn,
    DeviceTestResultTypeFilter,
} from './types';

export type DeviceTestResultColumnFilterMap = {
    [DeviceTestResultTableColumn.Name]: DeviceTestResultNameFilter[];
    [DeviceTestResultTableColumn.State]: DeviceTestResultStateFilter[];
    [DeviceTestResultTableColumn.Type]: DeviceTestResultTypeFilter[];
    [DeviceTestResultTableColumn.RunTime]: DeviceTestResultRunTimeFilter | null;
    [DeviceTestResultTableColumn.StartTime]: DeviceTestResultStartTimeFilter | null;
    [DeviceTestResultTableColumn.Cause]: TestResultCauseFilter[];
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type DeviceTestResultExtraFilterMap = {};

export type DeviceTestResultAllFilterMap = DeviceTestResultColumnFilterMap & DeviceTestResultExtraFilterMap;

export const DefaultValues: FilterValueMap<DeviceTestResultColumnFilterMap> = {
    [DeviceTestResultTableColumn.Name]: [],
    [DeviceTestResultTableColumn.State]: [],
    [DeviceTestResultTableColumn.Type]: [],
    [DeviceTestResultTableColumn.RunTime]: null,
    [DeviceTestResultTableColumn.StartTime]: null,
    [DeviceTestResultTableColumn.Cause]: [],
};

export const DefaultExtraValues: FilterValueMap<DeviceTestResultExtraFilterMap> = {};

export const StaticDeviceTestResultFilterDefinitions: FilterDefinition<DeviceTestResultTableColumn>[] = [
    {
        id: DeviceTestResultTableColumn.Name,
        type: 'multi',
        name: 'Task Name',
        category: 'Task Name',
        column: DeviceTestResultTableColumn.Name,
        component: meta => props => (
            <TaskBatteryTestNameFilterUI {...props} deviceIds={[(meta as { deviceId: string }).deviceId]} />
        ),
        describeValue: (value: DeviceTestResultNameFilter) => value.name,
        encodeValue: encodeBatteryTestNameFilter,
        decodeValue: decodeBatteryTestNameFilter,
    },
    {
        id: DeviceTestResultTableColumn.State,
        type: 'multi',
        name: 'Result',
        category: 'Result',
        column: DeviceTestResultTableColumn.State,
        component: () => TestResultStateFilterUI,
        describeValue: (value: DeviceTestResultStateFilter) => value.name,
        encodeValue: encodeTestResultStateFilter,
        decodeValue: decodeTestResultStateFilter,
    },
    {
        id: DeviceTestResultTableColumn.Type,
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: DeviceTestResultTableColumn.Type,
        component: () => DeviceTestResultTypeFilterUI,
        describeValue: describeBatteryTestType,
        encodeValue: encodeDeviceTestResultTypeFilter,
        decodeValue: decodeDeviceTestResultTypeFilter,
    },
    {
        id: DeviceTestResultTableColumn.RunTime,
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: DeviceTestResultTableColumn.RunTime,
        component: () => TaskRunTimeFilterUI,
        describeValue: formatDurationFilter,
        encodeValue: encodeBatteryTestRuntimeFilter,
        decodeValue: decodeBatteryTestRuntimeFilter,
    },
    {
        id: DeviceTestResultTableColumn.StartTime,
        type: 'single',
        name: 'Start Time',
        category: 'Start Time',
        column: DeviceTestResultTableColumn.StartTime,
        component: () => TaskDateFilterUI,
        describeValue: (value: DeviceTestResultStartTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: DeviceTestResultTableColumn.Cause,
        type: 'multi',
        name: 'Discharge Cause',
        category: 'Discharge Cause',
        column: DeviceTestResultTableColumn.Cause,
        component: () => TestResultCauseFilterUI,
        describeValue: (value: TestResultCauseFilter) => value.name,
        encodeValue: encodeTestResultCauseFilter,
        decodeValue: decodeTestResultCauseFilter,
    },
];
