import React, { FC, ReactNode } from 'react';

import { Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';
import humanizeDuration from 'humanize-duration';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { renderDeviceHealthCell } from 'lib/table-columns';
import { DateTime } from 'luxon';

import { DeviceHealth } from '../sub-views/overview/__generated__/OverviewContentQuery.graphql';

export interface DevicePaneProps {
    title: ReactNode;
    subtitle?: ReactNode;

    children: ReactNode;
    health?: DeviceHealth | null;
    lastUpdate?: string | null;
    lastOnline?: string | null;
    combinedView?: boolean;
}

export const DevicePane: FC<DevicePaneProps> = ({
    title,
    subtitle,
    children,
    health = null,
    lastUpdate,
    lastOnline,
    combinedView = false,
}) => {
    let lastUpdatedTooltip: string;
    let lastUpdatedDescription: string;
    let lastDescriptionText: string;

    if (health === 'Offline') {
        lastDescriptionText = 'Last online';

        if (lastOnline) {
            lastUpdatedTooltip = getDateTimeFormat(lastOnline);
            const lastOnlineDate = DateTime.fromISO(lastOnline);
            const timeSinceLastOnline = lastOnlineDate.diffNow().negate();

            const timeSinceLastOnlineInSeconds = Math.round(timeSinceLastOnline.as('seconds'));

            lastUpdatedDescription = `${humanizeDuration(timeSinceLastOnlineInSeconds * 1000, { largest: 1 })} ago`;
        } else {
            lastUpdatedTooltip = 'This device has never been online';
            lastUpdatedDescription = 'never';
        }
    } else {
        lastDescriptionText = 'Last collected';

        if (lastUpdate) {
            lastUpdatedTooltip = getDateTimeFormat(lastUpdate);
            const lastUpdatedDate = DateTime.fromISO(lastUpdate);
            const timeSinceLastUpdate = lastUpdatedDate.diffNow().negate();

            const timeSinceLastUpdateInSeconds = Math.round(timeSinceLastUpdate.as('seconds'));

            lastUpdatedDescription = `${humanizeDuration(timeSinceLastUpdateInSeconds * 1000, { largest: 1 })} ago`;
        } else {
            lastUpdatedTooltip = 'This device has never been collected';
            lastUpdatedDescription = 'never';
        }
    }

    const StatusBlinker: FC<{ error: boolean }> = ({ error }) => {
        return (
            <span className='relative flex h-3 w-3'>
                <span
                    className={classNames(
                        error ? 'bg-coralRegular' : 'bg-pineRegular',
                        'animate-ping absolute inline-flex h-full w-full rounded-full opacity-50'
                    )}
                ></span>
                <span
                    className={classNames(
                        error ? 'bg-coralRegular' : 'bg-pineRegular',
                        'relative inline-flex rounded-full h-3 w-3 bg-coralRegular'
                    )}
                ></span>
            </span>
        );
    };

    return (
        <div className='flex flex-col items-stretch'>
            <div className='px-4 pb-1'>
                <div className='flex flex-row justify-between items-center'>
                    <div className='font-bold text-3xl truncate'>{title}</div>
                    {!combinedView ? renderDeviceHealthCell(health ?? 'Unknown') : <></>}
                </div>
                <div className='flex flex-row justify-between items-center'>
                    <div className='font-light text-sm whitespace-nowrap'>{subtitle}</div>
                    {!combinedView ? (
                        <div className='flex flex-row space-x-2 items-center'>
                            <Tooltip content={lastUpdatedTooltip}>
                                <div className='text-xs text-eggplantLight'>
                                    <span>{lastDescriptionText}</span> <span>{lastUpdatedDescription}</span>
                                </div>
                            </Tooltip>
                            <StatusBlinker error={health === 'Offline'} />
                        </div>
                    ) : (
                        <div className='pb-4'></div>
                    )}
                </div>
            </div>
            <div className='flex-grow bg-white p-5'>{children}</div>
        </div>
    );
};
