import { Disposable, Environment, commitMutation, fetchQuery } from 'react-relay';

import { ToastOptions } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { downloadExportFile } from 'lib/download';

import { runReportExporterMutation } from './__generated__/runReportExporterMutation.graphql';
import { runReportExporterStatusQuery } from './__generated__/runReportExporterStatusQuery.graphql';

export class Exporter {
    private intervalTask: NodeJS.Timeout | null = null;
    private isRunning = true;
    private jobId: string | null = null;
    private finishCallback: (() => void) | null = null;
    private runningDisposable: Disposable | null = null;

    constructor(
        private runReportId: string,
        private showToast: (options: ToastOptions) => void,
        private environment: Environment
    ) {}

    get running(): boolean {
        return this.isRunning;
    }

    cancel() {
        if (this.intervalTask != null) {
            clearTimeout(this.intervalTask);
        }

        this.finish();

        if (this.runningDisposable) {
            this.runningDisposable.dispose();
            this.runningDisposable = null;
        }

        this.isRunning = false;
    }

    begin() {
        this.runningDisposable = commitMutation<runReportExporterMutation>(this.environment, {
            mutation: ExportMutation,
            variables: {
                id: this.runReportId,
            },
            onCompleted: response => {
                this.runningDisposable = null;
                if (response.exportResult?.id) {
                    const promise = new Promise<void>(resolve => {
                        this.finishCallback = resolve;
                    });

                    this.startWatching(response.exportResult.id, promise);
                }
            },
            onError: error => {
                this.runningDisposable = null;
                captureException(error, scope => {
                    scope.setTag('Function', 'Export');
                    scope.setTag('Run Report', this.runReportId);
                    return scope;
                });
                this.showToast({
                    text: 'Unable to export, something went wrong.',
                    variant: 'error',
                });
            },
        });
    }

    private startWatching(id: string, finishedPromise: Promise<void>) {
        this.jobId = id;
        this.intervalTask = setTimeout(() => this.check(), 400);

        this.showToast({
            text: 'Exporting generator run report result',
            variant: 'info',
            actions: [
                {
                    title: 'Cancel',
                    onClick: () => {
                        this.cancel();
                    },
                },
            ],
            displayUntil: finishedPromise,
        });
    }
    private check() {
        if (!this.jobId) {
            return;
        }

        fetchQuery<runReportExporterStatusQuery>(
            this.environment,
            graphql`
                query runReportExporterStatusQuery($id: ID!) {
                    exportJob(id: $id) {
                        status
                        downloadUrl
                    }
                }
            `,
            { id: this.jobId }
        )
            .toPromise()
            .then(this.handleJobStatus.bind(this));
        this.intervalTask = setTimeout(() => this.check(), 400);
    }

    private finish() {
        this.finishCallback?.();
        this.finishCallback = null;
    }

    private handleJobStatus(result?: runReportExporterStatusQuery['response']) {
        if (!result?.exportJob) {
            return;
        }

        if (!this.running) {
            // just in case it was cancelled between when the query was sent and this response
            return;
        }

        const job = result.exportJob;

        if (job.status === 'Failed') {
            this.finish();
            this.showToast({
                text: 'Export did not succeed. Something went wrong.',
                variant: 'error',
            });
            this.cancel();
            return;
        }

        if (job.status === 'Succeeded') {
            console.assert(job.downloadUrl, 'Expected download URL available');
            if (!job.downloadUrl) {
                return;
            }
            this.finish();

            this.showToast({
                text: 'Export finished',
                variant: 'info',
            });

            downloadExportFile(job.downloadUrl);

            this.cancel();
            return;
        }
    }
}

const ExportMutation = graphql`
    mutation runReportExporterMutation($id: ID!) {
        exportResult: exportGeneratorRunReport(id: $id) {
            ... on ExportJob {
                id
            }
            ... on ExportJobProblemResponse {
                problems
            }
        }
    }
`;
