import React, { useCallback } from 'react';

import { Button, FormikTextField, LogoStackedNormalExtended } from '@accesstel/pcm-ui';

import { captureMessage } from '@sentry/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { logError } from 'lib/log';

import { AuthResultType, confirmPasswordReset } from '../../../lib/auth';
import { DefaultResetFormValues, ResetFormValidationSchema, ResetFormValues } from './schema';

export interface ResetForgottenPasswordStageProps {
    username: string;
    onResetComplete: () => void;
}

export function ResetForgottenPasswordStage({
    username,
    onResetComplete,
}: ResetForgottenPasswordStageProps): JSX.Element {
    const doResetPassword = useCallback(
        async (values: ResetFormValues, { setSubmitting, setFieldError }: FormikHelpers<ResetFormValues>) => {
            setSubmitting(true);

            try {
                const [result, message] = await confirmPasswordReset(username, values.code, values.newPassword);

                switch (result) {
                    case AuthResultType.Success:
                        onResetComplete();
                        break;
                    case AuthResultType.FailureOther:
                        captureMessage(`Abnormal login result ${message}`);
                        logError('Unexpected error: ', message);

                        setFieldError('confirmPassword', 'Unable to log you in.');
                        break;
                    case AuthResultType.FailureBadAuth:
                        setFieldError('newPassword', 'Password does not match the requirements');
                        break;
                    case AuthResultType.FailureBadCode:
                        setFieldError('code', message);
                        break;
                }
            } catch (error) {
                logError('Error logging in. ', error);
                setFieldError('confirmPassword', 'Unable to log you in.');
            }
        },
        [onResetComplete, username]
    );

    return (
        <>
            <div className='reset-password-view'>
                <div className='login-layout'>
                    <div className='login-layout-col-logo'>
                        <img src={LogoStackedNormalExtended} alt='accesstel logo' width={280} height={280} />
                    </div>
                    <div className='login-layout-col-form'>
                        <Formik
                            initialValues={DefaultResetFormValues}
                            validationSchema={ResetFormValidationSchema}
                            onSubmit={doResetPassword}
                        >
                            {({ isSubmitting }) => (
                                <Form className='space-y-8'>
                                    <div>
                                        <h1 className='text-3xl font-bold'>Reset password</h1>
                                        <div className='space-y-2'>
                                            <p>You have requested to reset your password</p>
                                            <p>
                                                Use at least 8 characters with a mix of numbers, uppercase &amp;
                                                lowercase letters.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col items-stretch text-black'>
                                        <FormikTextField
                                            name='code'
                                            placeHolder='Verification code*'
                                            type='text'
                                            required
                                            autoFocus
                                            light
                                        />
                                    </div>
                                    <div className='flex flex-col items-stretch text-black'>
                                        <FormikTextField
                                            name='newPassword'
                                            placeHolder='New password*'
                                            type='password'
                                            required
                                            id='password'
                                            light
                                        />
                                    </div>
                                    <div className='flex flex-col items-stretch text-black'>
                                        <FormikTextField
                                            name='confirmPassword'
                                            placeHolder='Confirm new password*'
                                            type='password'
                                            required
                                            id='confirm-password'
                                            light
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            buttonClasses='flex-grow'
                                            type='submit'
                                            buttonText='Reset Password'
                                            variant='primary'
                                            size='large'
                                            processing={isSubmitting}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
}
