import { TimeRange } from 'views/reports/ac-power/settings';

export function decodeTimeRange(range: string): TimeRange | null {
    const decodedValue = decodeURIComponent(range);
    if (decodedValue === '') {
        return null;
    }

    if (Object.values(TimeRange).includes(decodedValue as TimeRange)) {
        return decodedValue as TimeRange;
    }

    return null;
}
