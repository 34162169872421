/**
 * @generated SignedSource<<ccfe684183083529635d57a9a4a514f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CountsSection$data = {
  readonly siteAcEventStatusDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly count: number;
      readonly key: ACPowerEventStatus;
    }>;
  };
  readonly totalSites: {
    readonly total: number;
  };
  readonly " $fragmentType": "CountsSection";
};
export type CountsSection$key = {
  readonly " $data"?: CountsSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"CountsSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "begin"
    },
    {
      "kind": "RootArgument",
      "name": "end"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CountsSection",
  "selections": [
    {
      "alias": "totalSites",
      "args": null,
      "concreteType": "PaginatedSites",
      "kind": "LinkedField",
      "name": "sites",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "begin",
          "variableName": "begin"
        },
        {
          "kind": "Variable",
          "name": "end",
          "variableName": "end"
        }
      ],
      "concreteType": "SiteACEventStatusDistribution",
      "kind": "LinkedField",
      "name": "siteAcEventStatusDistribution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteACEventStatusDistributionCount",
          "kind": "LinkedField",
          "name": "distribution",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "72564051f424dda7b6f34581c48cdf84";

export default node;
