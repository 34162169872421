import React, { FC } from 'react';

import { FormArea, FormikGeneralSelect } from '@accesstel/pcm-ui';

import { useFormikContext } from 'formik';

import { hasAnyPermission } from '../helpers';
import { AdminAccess, AuditAccess, ReadAccess, ReadWriteAccess, UserFormValues } from '../schema';

export const PermissionsSection: FC = () => {
    const { values } = useFormikContext<UserFormValues>();
    let generalError: string | undefined;
    if (hasAnyPermission(values.permissions) && values.permissions.general === 'None') {
        generalError = 'This will be set to Read Only because the user has other permissions.';
    }

    return (
        <FormArea label='Permissions'>
            <FormikGeneralSelect
                name='permissions.general'
                placeHolder='General Access'
                items={Object.values(ReadAccess)}
                renderItem={renderAuthLevelRead}
                light
            />
            <div>{generalError}</div>
            <FormikGeneralSelect
                name='permissions.assets'
                placeHolder='Asset Management'
                items={Object.values(ReadWriteAccess)}
                renderItem={renderAuthLevelWrite}
                light
            />
            <FormikGeneralSelect
                name='permissions.batteryHealthTasks'
                placeHolder='Battery Health Tasks'
                items={Object.values(ReadWriteAccess)}
                renderItem={renderAuthLevelWrite}
                light
            />
            <FormikGeneralSelect
                name='permissions.administration'
                placeHolder='Administration Access'
                items={Object.values(AdminAccess)}
                renderItem={renderAuthLevelAdmin}
                light
            />
            <FormikGeneralSelect
                name='permissions.audit'
                placeHolder='Audit Access'
                items={Object.values(AuditAccess)}
                renderItem={renderAuthLevelAudit}
                light
            />
        </FormArea>
    );
};

function renderAuthLevelRead(level: ReadAccess): string {
    switch (level) {
        case 'None':
            return 'No Access';
        case 'ReadOnly':
            return 'Read Only';
        default:
            return 'Unknown';
    }
}

function renderAuthLevelAdmin(level: AdminAccess): string {
    switch (level) {
        case 'None':
            return 'No Access';
        case 'Admin':
            return 'Admin Access';
        default:
            return 'Unknown';
    }
}

function renderAuthLevelAudit(level: AuditAccess): string {
    switch (level) {
        case 'None':
            return 'No Access';
        case 'Audit':
            return 'Audit Access';
        default:
            return 'Unknown';
    }
}

function renderAuthLevelWrite(level: ReadWriteAccess): string {
    switch (level) {
        case 'None':
            return 'No Access';
        case 'ReadOnly':
            return 'Read Only';
        case 'ReadWrite':
            return 'Read and Write';
        default:
            return 'Unknown';
    }
}
