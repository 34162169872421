import React, { FC } from 'react';

import { FilterTimeRangeSelect } from 'components/FilterTimeRangeSelect';
import { PredefinedTimeRange, TimeRangeFilter } from 'components/FilterTimeRangeSelect/common';
import { CommonFilterProps } from 'filters/common';
import { Duration } from 'luxon';

const Presets: PredefinedTimeRange[] = [
    {
        title: 'Less than 20 minutes',
        asTimeRange: () => ({
            min: 0,
            max: Duration.fromObject({ minutes: 20 }).as('minutes'),
        }),
    },
    {
        title: 'Less than 1 hour',
        asTimeRange: () => ({
            min: 0,
            max: Duration.fromObject({ hours: 1 }).as('minutes'),
        }),
    },
    {
        title: 'More than 1 hour',
        asTimeRange: () => ({
            min: Duration.fromObject({ hours: 1 }).as('minutes'),
            max: Duration.fromObject({ years: 10 }).as('minutes'),
        }),
    },
];

export type ACPowerMTTRRangeFilterUIProps = CommonFilterProps<TimeRangeFilter>;

export const ACPowerMTTRRangeFilterUI: FC<ACPowerMTTRRangeFilterUIProps> = ({
    current,
    onClearOrBack,
    onSubmit,
    onClose,
    hasPrevious,
}) => {
    return (
        <FilterTimeRangeSelect
            title='Filter by MTTR'
            presets={Presets}
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            hasPrevious={hasPrevious}
            onClearOrBack={onClearOrBack}
        />
    );
};
