import React, { FC, useMemo } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { Button, Modal, PageHeading } from '@accesstel/pcm-ui';

import { DeviceTableColumn, DeviceTableColumnId, deviceToFilterObject, useDeviceFilter } from 'filters/device';
import { SortDirection, useTableReducer } from 'layouts';
import { CoreTableLayout } from 'layouts/TableLayout/CoreTableLayout';
import { BaseTableColumns } from 'views/manage/device/settings';

import { useDeviceColumnDefinitions, useDeviceTableQuery } from '../../lib/tables/device';
import { fetchAllDeviceIds } from './lib/select-all';
import style from './style.module.css';

export interface DeviceSelectionModalProps {
    selectedDevices: string[];
    onConfirm: (selection: string[]) => void;

    open?: boolean;
    onClose: () => void;
}

export const DeviceSelectionModal: FC<DeviceSelectionModalProps> = ({ selectedDevices, onConfirm, open, onClose }) => {
    const environment = useRelayEnvironment();

    const columnDefinitions = useDeviceColumnDefinitions();

    const [tableState, dispatchTableState] = useTableReducer<DeviceTableColumnId>({
        defaultSortColumn: DeviceTableColumn.Name,
        defaultSortDirection: SortDirection.Ascending,
        allColumns: columnDefinitions,
        defaultVisibleColumns: BaseTableColumns,
        storageKeyPrefix: 'device-selection-table',
        initialSelection: selectedDevices,
        defaultPageSize: 10,

        disableUrlSortSync: true,
    });

    const [filters, dispatchFilters] = useDeviceFilter();

    const { data, error, isFetching, retry } = useDeviceTableQuery(tableState, filters, { skip: !open });

    const filterObject = useMemo(() => deviceToFilterObject(filters), [filters]);

    return (
        <Modal bgColor='bg-white' className={style.modal} open={!!open} onHide={onClose} closeButton>
            {data && (
                <div>
                    <div>
                        <PageHeading value='Select devices' />
                        <CoreTableLayout
                            tableSize='compact'
                            data={data.devices.data}
                            columns={columnDefinitions}
                            getRowId={row => row.id}
                            filterState={filters}
                            dispatchFilterState={dispatchFilters}
                            tableState={tableState}
                            dispatchTableState={dispatchTableState}
                            selection
                            onRequestAllIds={() => fetchAllDeviceIds(environment, filterObject)}
                            tableVariant='white'
                            clickBehaviour='select'
                            hasError={!!error}
                            onRetry={retry}
                            isProcessing={isFetching}
                            emptyMessage='There are no devices present'
                            page={tableState.page}
                            pageCount={data.devices.pageInfo.total}
                            allowEditingColumns
                        />
                    </div>
                    <div className='space-x-2 pt-4'>
                        <Button
                            buttonText='Select devices'
                            onClick={() => {
                                onConfirm(tableState.selectedItems);
                                onClose();
                            }}
                            disabled={tableState.selectedItems.length === 0}
                        />
                        <Button buttonText='Cancel' variant='gray' onClick={onClose} />
                    </div>
                </div>
            )}
        </Modal>
    );
};
