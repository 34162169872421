import React, { FC } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { IEnvironment } from 'relay-runtime';

import { CommonFilterProps } from '../../common/types';
import { SiteNameFilter } from '../types';
import { SiteNameFilterResult } from './SiteNameFilterResult';
import { SiteNameFilterUI_siteNameSearchQuery } from './__generated__/SiteNameFilterUI_siteNameSearchQuery.graphql';

export type SiteNameFilterUIProps = CommonFilterProps<SiteNameFilter[]>;

export const SiteNameFilterUI: FC<SiteNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();
    return (
        <FilterSearchSelect
            active={current}
            title='Filter by site'
            placeholder='Enter site name'
            renderItem={item => <SiteNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

export async function doSearch(
    environment: IEnvironment,
    input: string,
    maxResults: number
): Promise<SiteNameFilter[]> {
    try {
        const results = await fetchQuery<SiteNameFilterUI_siteNameSearchQuery>(
            environment,
            graphql`
                query SiteNameFilterUI_siteNameSearchQuery($input: String!, $maxResults: Int!) {
                    sites(filters: { name: [{ value: $input }] }, pageSize: $maxResults) {
                        data {
                            id
                            name
                            address {
                                state
                            }
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const sites = results?.sites.data ?? [];

        const output = sites.map<SiteNameFilter>(site => ({
            type: 'result',
            id: site.id,
            name: site.name,
            state: site.address.state,
        }));

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch {
        return [];
    }
}

function createWildcardFilter(input: string): SiteNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
