/**
 * @generated SignedSource<<9bf965eade7152a19bc6a295c43dd763>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type ACPowerContentQuery$variables = {
  begin: string;
  end: string;
  id: string;
};
export type ACPowerContentQuery$data = {
  readonly device: {
    readonly dualPlaneCompanion: {
      readonly device: {
        readonly health: DeviceHealth | null;
        readonly id: string;
        readonly lastOnline: string | null;
        readonly lastUpdate: string | null;
        readonly name: string;
        readonly type: {
          readonly displayName: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"DeviceACPower_device">;
      } | null;
    } | null;
    readonly health: DeviceHealth | null;
    readonly id: string;
    readonly lastOnline: string | null;
    readonly lastUpdate: string | null;
    readonly name: string;
    readonly type: {
      readonly displayName: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DeviceACPower_device">;
  } | null;
};
export type ACPowerContentQuery = {
  response: ACPowerContentQuery$data;
  variables: ACPowerContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "health",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastOnline",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceType",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DeviceACPower_device"
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceType",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceACPower",
  "kind": "LinkedField",
  "name": "acPower",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "from",
          "variableName": "begin"
        },
        {
          "kind": "Variable",
          "name": "to",
          "variableName": "end"
        }
      ],
      "concreteType": "ACUptime",
      "kind": "LinkedField",
      "name": "uptime",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ACPowerThreshold",
      "kind": "LinkedField",
      "name": "threshold",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "underVoltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overVoltage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedACFeeds",
      "kind": "LinkedField",
      "name": "feeds",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ACFeed",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ACFeedMetrics",
              "kind": "LinkedField",
              "name": "metrics",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "begin",
                      "variableName": "begin"
                    },
                    {
                      "kind": "Variable",
                      "name": "end",
                      "variableName": "end"
                    },
                    {
                      "kind": "Literal",
                      "name": "interval",
                      "value": "PT15M"
                    }
                  ],
                  "concreteType": "TimeSeriesMetrics",
                  "kind": "LinkedField",
                  "name": "voltage",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TimestampMetric",
                      "kind": "LinkedField",
                      "name": "values",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "timestamp",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "value",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "latestVoltage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ACPowerContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ACPowerContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e599bbc9f5fbc085b098def67a28d216",
    "id": null,
    "metadata": {},
    "name": "ACPowerContentQuery",
    "operationKind": "query",
    "text": "query ACPowerContentQuery(\n  $id: ID!\n  $begin: Timestamp!\n  $end: Timestamp!\n) {\n  device(id: $id) {\n    id\n    name\n    health\n    lastUpdate\n    lastOnline\n    type {\n      displayName\n      id\n    }\n    ...DeviceACPower_device\n    dualPlaneCompanion {\n      device {\n        id\n        name\n        health\n        lastUpdate\n        lastOnline\n        type {\n          displayName\n          id\n        }\n        ...DeviceACPower_device\n      }\n    }\n  }\n}\n\nfragment DeviceACPower_device on Device {\n  id\n  health\n  acPower {\n    uptime(from: $begin, to: $end) {\n      percentage\n    }\n    threshold {\n      outage\n      underVoltage\n      overVoltage\n    }\n    feeds {\n      data {\n        id\n        label\n        metrics {\n          voltage(begin: $begin, end: $end, interval: \"PT15M\") {\n            values {\n              timestamp\n              value\n            }\n          }\n        }\n        ...FeedTile_data\n      }\n    }\n  }\n}\n\nfragment FeedTile_data on ACFeed {\n  label\n  status\n  metrics {\n    latestVoltage\n  }\n}\n"
  }
};
})();

(node as any).hash = "0239cbe1f21bdf6405dea0057dee2e16";

export default node;
