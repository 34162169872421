import React, { FC, useState } from 'react';

import { Duration } from 'luxon';

import style from './style.module.css';

export interface DurationInputProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
}

export const DurationInput: FC<DurationInputProps> = ({ label, value, onChange }) => {
    const [hours, setHours] = useState(Math.trunc(Duration.fromISO(value).as('hours')).toString());
    const [minutes, setMinutes] = useState(Duration.fromISO(value).minutes.toString());

    const handleChangeHours = (input: string) => {
        const currentMinutes = Duration.fromISO(value).minutes;
        const convertedHours: number = Number(input) > 99 ? 99 : Math.trunc(Number(input));
        setHours(convertedHours.toString());
        onChange(Duration.fromObject({ minutes: currentMinutes, hours: convertedHours }).toISO());
    };

    const handleChangeMinutes = (input: string) => {
        const currentHours = Math.trunc(Duration.fromISO(value).as('hours'));
        const convertedMinutes: number = Number(input) > 59 ? 59 : Math.trunc(Number(input));
        setMinutes(convertedMinutes.toString());
        onChange(Duration.fromObject({ hours: currentHours, minutes: convertedMinutes }).toISO());
    };

    return (
        <div className='flex flex-row justify-between text-eggplantRegular font-light py-4 px-2'>
            <div>
                <label className={style.duration_label}>{label}</label>
                <div className='flex flex-row'>
                    <input
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                        className={style.duration_field}
                        id={`duration-input-hrs-${label}`}
                        type='text'
                        value={hours}
                        onChange={e => {
                            if (Number(e.target.value) >= 0 || e.target.value === '') {
                                setHours(e.target.value);
                            }
                        }}
                        onFocus={() => {
                            if (hours === '0') {
                                setHours('');
                            }
                        }}
                        onBlur={e => handleChangeHours(e.target.value)}
                        required
                    />
                    <div className='pr-4'>hrs</div>
                    <input
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                        className={style.duration_field}
                        id={`duration-input-mins-${label}`}
                        type='text'
                        value={minutes}
                        onChange={e => {
                            if (Number(e.target.value) >= 0 || e.target.value === '') {
                                setMinutes(e.target.value);
                            }
                        }}
                        onFocus={() => {
                            if (minutes === '0') {
                                setMinutes('');
                            }
                        }}
                        onBlur={e => handleChangeMinutes(e.target.value)}
                        required
                    />
                    <div>min</div>
                </div>
            </div>
        </div>
    );
};
