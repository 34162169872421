import React, { FC } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { CommonFilterProps } from 'filters/common';
import { IEnvironment } from 'relay-runtime';

import { AlertCategoryFilter } from '../types';
import { AlertCategoryFilterResult } from './AlertCategoryFilterResult';
import { AlertCategoryFilterUI_categorySearchQuery } from './__generated__/AlertCategoryFilterUI_categorySearchQuery.graphql';

export type AlertCategoryFilterUIProps = CommonFilterProps<AlertCategoryFilter[]>;

export const AlertCategoryFilterUI: FC<AlertCategoryFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by category'
            placeholder='Enter category'
            renderItem={item => <AlertCategoryFilterResult filter={item} />}
            renderItemSimple={item => item.value}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

async function doSearch(environment: IEnvironment, input: string, maxResults: number): Promise<AlertCategoryFilter[]> {
    try {
        const results = await fetchQuery<AlertCategoryFilterUI_categorySearchQuery>(
            environment,
            graphql`
                query AlertCategoryFilterUI_categorySearchQuery($input: String!, $maxResults: Int!) {
                    alerts(onlyActiveAlerts: false, filters: { category: { value: $input } }, pageSize: $maxResults) {
                        data {
                            id
                            category
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const alerts = results?.alerts.data ?? [];

        const output: AlertCategoryFilter[] = [];
        alerts.forEach(alert => {
            const category: AlertCategoryFilter = {
                type: 'result',
                id: alert.id,
                value: alert.category,
            };

            output.push(category);
        });

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        const uniqueAlerts = output.filter(
            (item, index, self) => index === self.findIndex(t => t.value === item.value)
        );

        return uniqueAlerts;
    } catch {
        return [];
    }
}

function createWildcardFilter(input: string): AlertCategoryFilter {
    return {
        type: 'special',
        value: input.trim(),
        wildcard: true,
    };
}
