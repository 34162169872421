/**
 * @generated SignedSource<<21f331534418807653a096d1d7754fab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UnitFlowRate = "LiterPerHour" | "USGallonPerHour" | "%future added value";
export type UnitPressure = "Bar" | "KiloPascal" | "PSI" | "Pascal" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type UnitVolume = "Liter" | "USGallon" | "%future added value";
export type authUserPreferencesQuery$variables = Record<PropertyKey, never>;
export type authUserPreferencesQuery$data = {
  readonly currentUser: {
    readonly preferences: {
      readonly timezone: string | null;
      readonly units: {
        readonly pressure: UnitPressure;
        readonly temperature: UnitTemperature;
        readonly volume: UnitVolume;
        readonly volumetricFlowRate: UnitFlowRate;
      };
    };
  };
};
export type authUserPreferencesQuery = {
  response: authUserPreferencesQuery$data;
  variables: authUserPreferencesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserUnitPreferences",
      "kind": "LinkedField",
      "name": "units",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pressure",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "temperature",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volume",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volumetricFlowRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "authUserPreferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "authUserPreferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "598db769ceab587f63b7f532632a0115",
    "id": null,
    "metadata": {},
    "name": "authUserPreferencesQuery",
    "operationKind": "query",
    "text": "query authUserPreferencesQuery {\n  currentUser {\n    preferences {\n      timezone\n      units {\n        pressure\n        temperature\n        volume\n        volumetricFlowRate\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3284bc95a516a55d001b527c9ca3442b";

export default node;
