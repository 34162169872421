import { BatteryTestNameFilter } from 'filters/task';

// IMPORTANT: Value has to be unique and cannot have spaces
export enum BatteryTestResultsColumn {
    TaskName = 'TaskName',
    Type = 'Type',
    State = 'State',
    StartTime = 'StartTime',
    RunTime = 'RunTime',
    DeviceName = 'DeviceName',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum BatteryTestResultsExtraFilters {}

export type BatteryTestResultNameFilter = BatteryTestNameFilter;
