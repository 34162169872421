import React, { FC } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { CommonFilterProps } from 'filters/common';
import { SiteNameFilterResult } from 'filters/site/components/SiteNameFilterResult';
import { IEnvironment } from 'relay-runtime';

import { AlertSiteNameFilter } from '../types';
import { AlertSiteNameFilterUI_siteNameSearchQuery } from './__generated__/AlertSiteNameFilterUI_siteNameSearchQuery.graphql';

export type AlertSiteNameFilterUIProps = CommonFilterProps<AlertSiteNameFilter[]>;

export const AlertSiteNameFilterUI: FC<AlertSiteNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by site'
            placeholder='Enter site name'
            renderItem={item => <SiteNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

async function doSearch(environment: IEnvironment, input: string, maxResults: number): Promise<AlertSiteNameFilter[]> {
    try {
        const results = await fetchQuery<AlertSiteNameFilterUI_siteNameSearchQuery>(
            environment,
            graphql`
                query AlertSiteNameFilterUI_siteNameSearchQuery($input: String!, $maxResults: Int!) {
                    alerts(onlyActiveAlerts: false, filters: { siteName: { value: $input } }, pageSize: $maxResults) {
                        data {
                            id
                            site {
                                id
                                name
                                address {
                                    state
                                }
                            }
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const alerts = results?.alerts.data ?? [];

        const output: AlertSiteNameFilter[] = [];
        alerts.forEach(alert => {
            const site: AlertSiteNameFilter = {
                type: 'result',
                id: alert.site.id,
                name: alert.site.name,
                state: alert.site.address.state,
            };

            output.push(site);
        });

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        const uniqueSites = output.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));

        return uniqueSites;
    } catch {
        return [];
    }
}

function createWildcardFilter(input: string): AlertSiteNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
