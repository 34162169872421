import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import { DeviceIcon, DualPlaneIcon, PowerIcon, ThemedLink, Tooltip } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';

import { BorderedSection } from '../../../../../../components/BorderedSection';
import { TimeRange, TimeRangeSearchParameter } from '../../../settings';
import { HeaderDetailContent_device$key } from './__generated__/HeaderDetailContent_device.graphql';

export interface HeaderDetailContentProps {
    fragmentRef: HeaderDetailContent_device$key;
    timeRange: TimeRange;
}

export const HeaderDetailContent: FC<HeaderDetailContentProps> = ({ fragmentRef, timeRange }) => {
    const { type, dualPlaneCompanion, acPower } = useFragment(Fragment, fragmentRef);

    let systemType: ReactNode;
    if (dualPlaneCompanion?.device) {
        systemType = (
            <span className='items-center'>
                <span>Dual Plane</span>

                <div className='h-4 w-4 ml-1 inline-block align-middle'>
                    <DualPlaneIcon />
                </div>
            </span>
        );
    } else {
        systemType = <span>Single Plane</span>;
    }

    return (
        <>
            <BorderedSection title='Device information' icon={<DeviceIcon />}>
                <div>
                    <span className='font-bold'>Type: </span>
                    <span>{type.displayName}</span>
                </div>
                <div>
                    <span className='font-bold'>Configuration: </span>
                    {systemType}
                </div>
                {dualPlaneCompanion?.device && (
                    <div>
                        <span className='font-bold'>Companion: </span>
                        <ThemedLink
                            to={{
                                pathname: '../' + dualPlaneCompanion.device.id,
                                search: {
                                    [TimeRangeSearchParameter]: timeRange,
                                },
                            }}
                        >
                            {dualPlaneCompanion.device.name}
                        </ThemedLink>
                    </div>
                )}
            </BorderedSection>
            <BorderedSection title='AC Configuration' icon={<PowerIcon />}>
                <div className='mb-1'>
                    <span className='font-bold'>Incoming feeds: </span>
                    <span>{acPower.feeds.total}</span>
                </div>
                <div className='flex flex-rows gap-1 mb-2'>
                    {acPower.feeds.data.map(feed => {
                        let color: string;
                        let niceStatus: string;

                        switch (feed.status) {
                            case 'Healthy':
                                color = 'bg-pineRegular';
                                niceStatus = 'Healthy';
                                break;
                            case 'Degraded':
                                color = 'bg-mustardRegular';
                                niceStatus = 'Degraded';
                                break;
                            case 'Offline':
                                color = 'bg-coralRegular';
                                niceStatus = 'Offline';
                                break;
                            default:
                                color = 'bg-eggplantRegular';
                                niceStatus = 'Unknown';
                                break;
                        }

                        return (
                            <Tooltip content={`Feed ${feed.label} ${niceStatus}`}>
                                <div className={classNames('rounded-full text-xs text-white px-1', color)}>
                                    F{feed.label}
                                </div>
                            </Tooltip>
                        );
                    })}
                </div>
                <div className='text-sm text-eggplantLight font-light'>
                    NOTE: Which feed corresponds to which phase is not available to accata.
                </div>
            </BorderedSection>
        </>
    );
};

const Fragment = graphql`
    fragment HeaderDetailContent_device on Device {
        type {
            displayName
        }

        dualPlaneCompanion {
            device {
                id
                name
            }
            configuration
        }

        acPower {
            feeds {
                total

                data {
                    id
                    label
                    status
                }
            }
        }
    }
`;
