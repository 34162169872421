import React, { FC } from 'react';

import { FormikGeneralSelect } from '@accesstel/pcm-ui';

import { useField } from 'formik';
import { getQualifiedName } from 'lib/namespaces';
import { getNiceSNMPVersion } from 'lib/textFormatters';

import { SettingsV1V2c, SettingsV3 } from './components';

export interface SNMPSettingsProps {
    namespace?: string;
}

type SNMPVersion = 'V1' | 'V2c' | 'V3';

const dropdownItems: SNMPVersion[] = ['V1', 'V2c', 'V3'];

export const SNMPSettings: FC<SNMPSettingsProps> = ({ namespace }) => {
    const [, versionMeta] = useField(getQualifiedName('version', namespace));

    return (
        <>
            <div className='col-start-1'>
                <FormikGeneralSelect<SNMPVersion>
                    name={getQualifiedName('version', namespace)}
                    placeHolder='SNMP Version'
                    items={dropdownItems}
                    renderItem={item => getNiceSNMPVersion(item)}
                    light
                    variant='outlined'
                    testId='snmp-version-dropdown'
                />
            </div>
            {versionMeta.value === 'V3' && <SettingsV3 namespace={namespace} />}
            {versionMeta.value !== 'V3' && <SettingsV1V2c namespace={namespace} />}
        </>
    );
};
