import { Dispatch } from 'react';

import { AttributeFilter, FilterAction, FilterState, useFilterReducer } from '../common';
import { useDynamicFilters } from './dynamic';
import { DefaultExtraFilters, DefaultValues, SiteColumnFilterMap, StaticSiteFilterDefinitions } from './settings';
import { SiteDeviceBatteries, SiteExtraFilters, SiteNameFilter, SiteTableColumnId } from './types';

export type SiteFilterState = FilterState<SiteTableColumnId, SiteColumnFilterMap>;
export type SiteFilterAction = FilterAction<SiteColumnFilterMap, SiteTableColumnId>;

/**
 *
 * @param options 'extraFilters' can be passed in to override the default values for the extra filters.
 *
 */
export function useSiteFilter(options?: {
    extraFilters?: Partial<Record<SiteExtraFilters, unknown>>;
}): [SiteFilterState, Dispatch<SiteFilterAction>] {
    const extraFilters = {
        ...DefaultExtraFilters,
        ...(options?.extraFilters ?? {}),
    };

    const dynamicFilterDefinitions = useDynamicFilters();

    const [state, updateState] = useFilterReducer<
        SiteTableColumnId,
        SiteColumnFilterMap,
        SiteFilterState,
        SiteFilterAction
    >('site-filter', DefaultValues, [...StaticSiteFilterDefinitions, ...dynamicFilterDefinitions], extraFilters);

    return [state, updateState];
}

export function siteToFilterObject(filters: SiteFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        Type: type,
        Name: name,
        State: state,
        Postcode: postcode,
        DeviceCount: deviceCount,
        BatteryStatus: batteryStatus,
        BatteryCapacity: batteryCapacity,
        BatteryTotalTimeRemaining: batteryTotalTimeRemaining,
        DeviceStatus: deviceStatus,
        ACIncidentCount: acIncidentCount,
        ACReliability: acReliability,
        ACMttr: acMttr,
        ACMtbf: acMtbf,
        ACOutageDurationSum: acOutageDurationSum,
    } = filters.columnValues;

    if (name.length > 0) {
        output.name = name.map(filter => ({ value: filter.name }));
    }

    if (type.length > 0) {
        output.type = type.map(filter => ({ value: filter.id }));
    }

    if (state.length > 0) {
        output.state = state.map(filter => ({ value: filter.state }));
    }

    if (deviceCount) {
        output.deviceCount = deviceCount;
    }

    if (batteryStatus.length > 0) {
        output.batteryStatus = batteryStatus.map(filter => ({ value: filter.id }));
    }

    if (batteryCapacity) {
        output.batteryCapacity = batteryCapacity;
    }

    if (batteryTotalTimeRemaining) {
        output.batteryTotalTimeRemaining = batteryTotalTimeRemaining;
    }

    if (postcode.length > 0) {
        output.postcode = postcode.map(p => ({ value: p.value }));
    }

    if (deviceStatus.length > 0) {
        output.deviceHealth = deviceStatus.map(filter => ({ value: filter.id }));
    }

    if (acReliability) {
        output.acReliabilityPercentile = acReliability;
    }

    if (acIncidentCount) {
        output.acIncidentCount = acIncidentCount;
    }

    if (acMttr) {
        output.acMttr = acMttr.range;
    }

    if (acMtbf) {
        output.acMtbf = acMtbf.range;
    }

    if (acOutageDurationSum) {
        output.acOutageDurationSum = acOutageDurationSum.range;
    }

    let attributeFilters: unknown[] | undefined;

    for (const [definitionId, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition) {
            continue;
        }

        switch (definition.id) {
            case SiteExtraFilters.DeviceName: {
                const filterObjects = values as SiteNameFilter[];
                if (filterObjects.length > 0) {
                    output.deviceName = filterObjects.map(value => ({ value: value.name }));
                }
                break;
            }
            case SiteExtraFilters.DeviceWithBatteries: {
                const deviceBatteries = values as SiteDeviceBatteries;
                if (deviceBatteries !== null) {
                    output.deviceBatteries = deviceBatteries;
                }
                break;
            }
        }

        if (definition.category === 'Attribute') {
            const filterObjects = values as AttributeFilter[];
            if (filterObjects.length > 0) {
                if (!attributeFilters) {
                    attributeFilters = [];
                }
                attributeFilters.push({
                    name: definition.attributeName,
                    filters: filterObjects,
                });
            }
        }
    }

    for (const [definitionId, value] of Object.entries(filters.columnValues)) {
        const definition = filters.filterDefinitions.find(definition => definition.id === definitionId);
        if (!definition) {
            continue;
        }

        if (definition.category === 'Attribute') {
            const filterObjects = value as AttributeFilter[];
            if (filterObjects.length > 0) {
                if (!attributeFilters) {
                    attributeFilters = [];
                }
                attributeFilters.push({
                    name: definition.attributeName,
                    filters: filterObjects,
                });
            }
        }
    }

    if (attributeFilters && attributeFilters.length > 0) {
        output.attributes = attributeFilters;
    }

    return output;
}
