import React, { FC } from 'react';

import { ChevronDownIcon, Tooltip } from '@accesstel/pcm-ui';

import classNames from 'classnames';

export interface SectionTitleProps {
    title: string;
    collapsed?: boolean;

    onToggle?: (collapsed: boolean) => void;

    /**
     * Turns off the ability to collapse the section
     */
    noCollapse?: boolean;
}

export const SectionTitle: FC<SectionTitleProps> = ({ title, collapsed, onToggle, noCollapse }) => {
    if (noCollapse) {
        return <div className='font-semibold px-4 pt-4'>{title}</div>;
    }

    return (
        <div className='font-semibold pt-4 px-4 flex'>
            <Tooltip content={collapsed ? 'Show section' : 'Hide section'}>
                <div
                    className='flex flex-row gap-1 items-center cursor-pointer select-none'
                    onClick={() => onToggle?.(!collapsed)}
                    role='button'
                    tabIndex={0}
                    onKeyUp={event => {
                        if (event.key === 'Enter' || event.key === ' ') {
                            onToggle?.(!collapsed);
                        }
                    }}
                >
                    {title}
                    <div className={classNames('w-4 h-4 transition-transform', { '-rotate-90': collapsed })}>
                        <ChevronDownIcon />
                    </div>
                </div>
            </Tooltip>
        </div>
    );
};
