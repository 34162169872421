import { Environment, commitMutation, fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { SiteRemoveResponse, deleteSitesMutation } from './__generated__/deleteSitesMutation.graphql';
import { deleteValidationQuery } from './__generated__/deleteValidationQuery.graphql';

export function deleteSites(siteIds: string[], environment: Environment): Promise<SiteRemoveResponse> {
    return new Promise((resolve, reject) => {
        commitMutation<deleteSitesMutation>(environment, {
            mutation: DeleteMutation,
            variables: {
                ids: siteIds,
            },
            onError: () => {
                reject();
            },
            onCompleted: (response, err) => {
                if (err) {
                    reject();
                }

                if (response) {
                    resolve(response.removeSites);
                }
            },
        });
    });
}

interface SiteValidationResult {
    id: string;
    name: string;
    empty: boolean;
}

export async function validateSitesForDelete(
    siteIds: string[],
    environment: Environment
): Promise<SiteValidationResult[]> {
    const queryResult = await fetchQuery<deleteValidationQuery>(environment, ValidationQuery, {
        ids: siteIds,
    }).toPromise();

    const siteResults = new Map<string, SiteValidationResult>();

    // Default all sites, incase they are not returned from the query
    for (const site of siteIds) {
        siteResults.set(site, {
            id: site,
            name: '',
            empty: false,
        });
    }

    if (!queryResult?.sites) {
        return Array.from(siteResults.values());
    }

    for (const site of queryResult.sites.data) {
        siteResults.set(site.id, {
            id: site.id,
            name: site.name,
            empty: site.devices.total === 0,
        });
    }

    return Array.from(siteResults.values());
}

const DeleteMutation = graphql`
    mutation deleteSitesMutation($ids: [ID!]!) {
        removeSites(ids: $ids)
    }
`;

const ValidationQuery = graphql`
    query deleteValidationQuery($ids: [ID!]!) {
        sites(filters: { ids: $ids }) {
            data {
                id
                name
                devices {
                    total
                }
            }
        }
    }
`;
