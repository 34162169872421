import { commitMutation } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';

import { passwordResetPasswordMutation } from './__generated__/passwordResetPasswordMutation.graphql';

export async function resetUserPassword(id: string, environment: IEnvironment) {
    return new Promise((resolve, reject) => {
        commitMutation<passwordResetPasswordMutation>(environment, {
            mutation: ResetUserPasswordMutation,
            variables: { id },
            onCompleted: (response, error) => {
                if (response.resetUserPassword?.username) {
                    resolve(response.resetUserPassword.username);
                }
                if (response.resetUserPassword?.problems) {
                    reject(response.resetUserPassword.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

const ResetUserPasswordMutation = graphql`
    mutation passwordResetPasswordMutation($id: String!) {
        resetUserPassword(username: $id) {
            ... on User {
                username
            }
            ... on UserProblemResponse {
                problems {
                    type
                }
            }
        }
    }
`;
