import React, { FC, Suspense } from 'react';
import { useFragment } from 'react-relay';

import { DateLineChart, LineChartSeries, SkeletonChart, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { DateTime } from 'luxon';

import { PowerChart_metrics$key } from './__generated__/PowerChart_metrics.graphql';

interface PowerChartProps {
    fragmentRef: PowerChart_metrics$key;
}

export const PowerChart: FC<PowerChartProps> = ({ fragmentRef }) => {
    const data = useFragment<PowerChart_metrics$key>(Fragment, fragmentRef);

    const chartData: LineChartSeries<Date>[] = [
        {
            name: 'Power',
            color: Theme.coralRegular,
            data:
                data.aggregatedPower?.flatMap(dataPoint => {
                    if (dataPoint.average) {
                        return {
                            key: DateTime.fromISO(dataPoint.timestamp).toJSDate(),
                            value: -dataPoint.average,
                        };
                    } else {
                        return [];
                    }
                }) ?? [],
        },
    ];

    return (
        <Suspense fallback={<SkeletonChart aspectRatio='1.7' stretch hideText />}>
            <DateLineChart
                series={chartData}
                hideTooltip={true}
                sizeVariant='small'
                aspectRatio={1.7}
                formatYTick={(value, index, tickCount) => {
                    if (index === 0) {
                        return Number(value).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        });
                    }

                    if (index === tickCount - 1) {
                        return Number(value).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        });
                    }

                    return '';
                }}
            />
        </Suspense>
    );
};

const Fragment = graphql`
    fragment PowerChart_metrics on DeviceBatteryTestResults {
        aggregatedPower(points: 20) {
            timestamp
            average
        }
    }
`;
