/**
 * @generated SignedSource<<d04a2048f9eb9e30ec3a4a6cb7e2d5f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type IncidentRow_event$data = {
  readonly affectedAllDevices: boolean;
  readonly affectedAllFeeds: boolean;
  readonly affectedFeeds: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
  }>;
  readonly duration: string;
  readonly startTime: string;
  readonly voltage: number | null;
  readonly worstStatus: ACPowerEventStatus;
  readonly " $fragmentType": "IncidentRow_event";
};
export type IncidentRow_event$key = {
  readonly " $data"?: IncidentRow_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"IncidentRow_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IncidentRow_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worstStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affectedAllFeeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ACPowerEventFeed",
      "kind": "LinkedField",
      "name": "affectedFeeds",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affectedAllDevices",
      "storageKey": null
    }
  ],
  "type": "SiteACPowerEvent",
  "abstractKey": null
};

(node as any).hash = "c85804a9f5e5767c4cac8dbbbe4c8ca6";

export default node;
