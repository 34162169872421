/**
 * @generated SignedSource<<07364bf8f50722ba85df5073b6e69a99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteDeviceNameFilterUI_deviceNameSearchQuery$variables = {
  input: string;
  maxResults: number;
};
export type SiteDeviceNameFilterUI_deviceNameSearchQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly address: {
        readonly state: string;
      };
      readonly devices: {
        readonly data: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }>;
      };
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type SiteDeviceNameFilterUI_deviceNameSearchQuery = {
  response: SiteDeviceNameFilterUI_deviceNameSearchQuery$data;
  variables: SiteDeviceNameFilterUI_deviceNameSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxResults"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "value",
                    "variableName": "input"
                  }
                ],
                "kind": "ObjectValue",
                "name": "deviceName.0"
              }
            ],
            "kind": "ListValue",
            "name": "deviceName"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "maxResults"
      }
    ],
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteDeviceNameFilterUI_deviceNameSearchQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteDeviceNameFilterUI_deviceNameSearchQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4ad7a7fee2e09db708f9fe90c683cdd0",
    "id": null,
    "metadata": {},
    "name": "SiteDeviceNameFilterUI_deviceNameSearchQuery",
    "operationKind": "query",
    "text": "query SiteDeviceNameFilterUI_deviceNameSearchQuery(\n  $input: String!\n  $maxResults: Int!\n) {\n  sites(filters: {deviceName: [{value: $input}]}, pageSize: $maxResults) {\n    data {\n      id\n      name\n      address {\n        state\n      }\n      devices {\n        data {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e890c0899856c19921e4c6a561afd04";

export default node;
