import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import {
    DeviceTypeFilter,
    DeviceTypeFilterUI,
    decodeDeviceNameFilter,
    decodeDeviceTypeFilter,
    encodeDeviceNameFilter,
    encodeDeviceTypeFilter,
} from 'filters/device';
import {
    SiteStateFilterUI,
    decodeSiteNameFilter,
    decodeSiteStateFilter,
    encodeSiteNameFilter,
    encodeSiteStateFilter,
} from 'filters/site';
import { ColumnWithId } from 'layouts';
import { Device, StatusCell } from 'views/tasks/battery-health/task-view/subviews/StatusList';

import { FilterDefinition, FilterValueMap } from '../common';
import { DeviceNameFilterUI, DeviceStatusFilterUI, SiteNameFilterUI } from './components';
import { decodeBatteryTestDeviceStatusFilter } from './decode';
import { encodeBatteryTestDeviceStatusFilter } from './encode';
import {
    BatteryTestDeviceNameFilter,
    BatteryTestDeviceSiteNameFilter,
    BatteryTestDeviceSiteStateFilter,
    BatteryTestDeviceStatusFilter,
    BatteryTestExtraFilters,
    BatteryTestTableColumn,
} from './types';

export type BatteryTestColumnFilterMap = {
    [BatteryTestTableColumn.Name]: BatteryTestDeviceNameFilter[];
    [BatteryTestTableColumn.Site]: BatteryTestDeviceSiteNameFilter[];
    [BatteryTestTableColumn.State]: BatteryTestDeviceSiteStateFilter[];
    [BatteryTestTableColumn.Status]: BatteryTestDeviceStatusFilter[];
};

export type BatteryTestExtraFilterMap = {
    [BatteryTestExtraFilters.DeviceType]: DeviceTypeFilter[];
};

export type BatteryTestAllFilterMap = BatteryTestColumnFilterMap & BatteryTestExtraFilterMap;

export const DefaultValues: FilterValueMap<BatteryTestColumnFilterMap> = {
    [BatteryTestTableColumn.Name]: [],
    [BatteryTestTableColumn.Site]: [],
    [BatteryTestTableColumn.State]: [],
    [BatteryTestTableColumn.Status]: [],
};

export const DefaultExtraFilters: FilterValueMap<BatteryTestExtraFilterMap> = {
    [BatteryTestExtraFilters.DeviceType]: [],
};

export const StaticBatteryTestFilterDefinitions: FilterDefinition<BatteryTestTableColumn>[] = [
    {
        id: BatteryTestTableColumn.Site,
        type: 'multi',
        name: 'Site',
        category: 'Site',
        column: BatteryTestTableColumn.Site,
        component: () => SiteNameFilterUI,
        describeValue: (value: BatteryTestDeviceSiteNameFilter) => value.name,
        encodeValue: encodeSiteNameFilter,
        decodeValue: decodeSiteNameFilter,
    },
    {
        id: BatteryTestTableColumn.State,
        type: 'multi',
        name: 'State',
        category: 'State',
        column: BatteryTestTableColumn.State,
        component: () => SiteStateFilterUI,
        describeValue: (value: BatteryTestDeviceSiteStateFilter) => value.state,
        encodeValue: encodeSiteStateFilter,
        decodeValue: decodeSiteStateFilter,
    },
    {
        id: BatteryTestTableColumn.Name,
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        column: BatteryTestTableColumn.Name,
        component: () => DeviceNameFilterUI,
        describeValue: (value: BatteryTestDeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: BatteryTestTableColumn.Status,
        type: 'multi',
        name: 'Status',
        category: 'Status',
        column: BatteryTestTableColumn.Status,
        component: () => DeviceStatusFilterUI,
        describeValue: (value: BatteryTestDeviceStatusFilter) => value.name,
        encodeValue: encodeBatteryTestDeviceStatusFilter,
        decodeValue: decodeBatteryTestDeviceStatusFilter,
    },
    {
        id: BatteryTestExtraFilters.DeviceType,
        type: 'multi',
        name: 'Device Type',
        category: 'Device Type',
        component: () => DeviceTypeFilterUI,
        describeValue: (value: DeviceTypeFilter) => value.name,
        encodeValue: encodeDeviceTypeFilter,
        decodeValue: decodeDeviceTypeFilter,
    },
];

const columnHelper = createColumnHelper<Device>();

const SiteColumn = columnHelper.accessor('site.name', {
    id: BatteryTestTableColumn.Site,
    header: 'Site',
    exportHeader: 'Site',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '16rem',
    },
});
const StateColumn = columnHelper.accessor('site.address.state', {
    id: BatteryTestTableColumn.State,
    header: 'State',
    exportHeader: 'State',
    meta: {
        filterable: true,
        sortable: true,
    },
});
const DeviceNameColumn = columnHelper.accessor('name', {
    id: BatteryTestTableColumn.Name,
    header: 'Device',
    exportHeader: 'Device Name',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '15rem',
    },
});
const StatusColumn = columnHelper.accessor('test', {
    id: BatteryTestTableColumn.Status,
    header: 'Status',
    exportHeader: 'Test Status',
    cell: ({ cell }) => <StatusCell test={cell.getValue()} />,
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const BaseColumns = [SiteColumn, StateColumn, DeviceNameColumn, StatusColumn] as ColumnWithId<
    BatteryTestTableColumn,
    Device
>[];
