import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { StatusMetric } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { DefinitionTooltip } from '../../common';
import { ACPowerEventStatus, CountsSection$data, CountsSection$key } from './__generated__/CountsSection.graphql';

export interface CountsSectionProps {
    fragmentRef: CountsSection$key;
}

export const CountsSection: FC<CountsSectionProps> = ({ fragmentRef }) => {
    const data = useFragment(Fragment, fragmentRef);

    const convertedData = convertDataToMetricDisplay(data.siteAcEventStatusDistribution.distribution);

    return (
        <>
            <div className='col-span-6 lg:col-span-3'>
                <StatusMetric
                    title='Sites'
                    content={data.totalSites.total}
                    status='neutral'
                    showIcon={false}
                    titleTooltip={DefinitionTooltip.TotalSites}
                />
            </div>
            <div className='col-span-6 lg:col-span-3'>
                <StatusMetric
                    title='Outages'
                    content={getMetricText(convertedData.Outage)}
                    status='neutral'
                    showIcon={false}
                    titleTooltip={DefinitionTooltip.Outages}
                />
            </div>
            <div className='col-span-6 lg:col-span-3'>
                <StatusMetric
                    title='Undervoltages'
                    content={getMetricText(convertedData.UnderVoltage)}
                    status='neutral'
                    showIcon={false}
                    titleTooltip={DefinitionTooltip.Undervoltages}
                />
            </div>
            <div className='col-span-6 lg:col-span-3'>
                <StatusMetric
                    title='Overvoltages'
                    content={getMetricText(convertedData.OverVoltage)}
                    status='neutral'
                    showIcon={false}
                    titleTooltip={DefinitionTooltip.Overvoltages}
                />
            </div>
        </>
    );
};

const Fragment = graphql`
    fragment CountsSection on Query {
        totalSites: sites {
            total
        }
        siteAcEventStatusDistribution(begin: $begin, end: $end) {
            distribution {
                key
                count
            }
        }
    }
`;

type ConvertedMetricData = {
    [acPowerEventStatus in ACPowerEventStatus]: number | undefined;
};

function convertDataToMetricDisplay(
    data: CountsSection$data['siteAcEventStatusDistribution']['distribution']
): ConvertedMetricData {
    return data.reduce((acc, curr) => {
        return Object.assign(acc, { [curr.key]: curr.count });
    }, {}) as ConvertedMetricData;
}

function getMetricText(metric: number | undefined): string {
    if (metric === 1) {
        return '1 incident';
    } else {
        return `${metric ?? 0} incidents`;
    }
}
