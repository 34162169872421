import React, { FC, Suspense, useCallback, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { ViewBatteryTestResultContent } from './ViewBatteryTestResultContent';
import { ViewBatteryTestResultSkeleton } from './ViewBatteryTestResultSkeleton';
import { ViewBatteryTestResultDirectQuery } from './__generated__/ViewBatteryTestResultDirectQuery.graphql';

const LoadTestByIdQuery = graphql`
    query ViewBatteryTestResultDirectQuery($id: ID!, $unitTemperature: UnitTemperature) {
        batteryTestResult(id: $id) {
            id
            state
            completedTime
            abortedTime
            ...ViewBatteryTestResultContent_test @arguments(unitTemperature: $unitTemperature)
        }
    }
`;

export interface RouteParams extends Record<string, string | undefined> {
    id?: string;
}

interface ViewBatteryTestResultDirectProps {
    fromReports?: boolean;
}

export const ViewBatteryTestResultDirect: FC<ViewBatteryTestResultDirectProps> = ({ fromReports }) => {
    const { id } = useParams<RouteParams>();
    const userPreferences = useCurrentUserUnitsPref();

    useDocumentTitle('Battery test result');

    const [queryRef, loadQuery, dispose] = useQueryLoader<ViewBatteryTestResultDirectQuery>(LoadTestByIdQuery);

    const load = useCallback(() => {
        loadQuery({ id: id ?? '', unitTemperature: userPreferences.temperature }, { fetchPolicy: 'store-and-network' });
    }, [loadQuery, id, userPreferences.temperature]);

    useEffect(() => {
        load();
        return () => {
            dispose();
        };
    }, [dispose, load]);

    return (
        <>
            {!queryRef && <ViewBatteryTestResultSkeleton />}
            {queryRef && (
                <Suspense fallback={<ViewBatteryTestResultSkeleton />}>
                    <ContentWrapper queryRef={queryRef} fromReports={fromReports} />
                </Suspense>
            )}
        </>
    );
};

interface ContentWrapperProps {
    queryRef: PreloadedQuery<ViewBatteryTestResultDirectQuery>;
    fromReports?: boolean;
}

const ContentWrapper: FC<ContentWrapperProps> = ({ queryRef, fromReports }) => {
    const props = usePreloadedQuery<ViewBatteryTestResultDirectQuery>(LoadTestByIdQuery, queryRef);

    if (!props.batteryTestResult) {
        return <ErrorNotFound />;
    }

    const testId = props.batteryTestResult.id;

    return (
        <ViewBatteryTestResultContent
            key={testId}
            testId={testId}
            test={props.batteryTestResult}
            view={fromReports ? 'reports' : 'external'}
        />
    );
};
