import React, { FC, ReactNode, useState } from 'react';

import { FileTextIcon } from '@accesstel/pcm-ui';

import { BorderedSection } from 'components/BorderedSection';
import { useUserPermissions } from 'lib/auth';

import { NoteEditDialog } from './NoteEditDialog';

type Note = {
    content: string;
};

export interface NotesSectionProps {
    note: Note | null;
    type: 'batteryTest' | 'generatorRun';
    editId: string;
}

export const NotesSection: FC<NotesSectionProps> = ({ note, type, editId }) => {
    const [isEditingNote, setIsEditingNote] = useState(false);
    const { hasTasksWrite } = useUserPermissions();

    let action: ReactNode;

    if (note && hasTasksWrite) {
        action = (
            <button
                className='text-coralRegular hover:underline'
                onClick={() => {
                    setIsEditingNote(true);
                }}
            >
                Edit
            </button>
        );
    }

    let noteText = '';
    if (note) {
        noteText = note.content;

        // Collapse lines that are just whitespace
        noteText = noteText.replace(/^[ \t]+$/gm, '');

        // Collapse multiple newlines into one
        noteText = noteText.replace(/\n\n+/g, '\n');

        // Collapse leading and trailing newlines
        noteText = noteText.replace(/^\n+|\n+$/g, '');
    }

    return (
        <>
            <BorderedSection title='Notes' icon={<FileTextIcon />} className='col-span-2' action={action}>
                {!note && (
                    <>
                        <span className='italic'>No notes have been added. </span>
                        {hasTasksWrite && (
                            <button
                                className='text-coralRegular hover:underline'
                                onClick={() => {
                                    setIsEditingNote(true);
                                }}
                            >
                                Add a note
                            </button>
                        )}
                    </>
                )}
                {note && (
                    <div className='whitespace-pre-line max-h-32 overflow-y-auto overflow-x-hidden'>{noteText}</div>
                )}
            </BorderedSection>
            <NoteEditDialog
                editId={editId}
                type={type}
                initialNotes={note?.content}
                open={isEditingNote}
                onClose={() => setIsEditingNote(false)}
            />
        </>
    );
};
