import { AttributeTableColumn } from 'filters/common';
import { IntRangeFilter } from 'lib/__generated__/queries_getAllDeviceIdsQuery.graphql';

// IMPORTANT: Value has to be unique and cannot have spaces
export enum BatteryTypeTableColumn {
    Manufacturer = 'Manufacturer',
    Model = 'Model',
    Capacity = 'Capacity',
    Technology = 'Technology',
    CellCapacityRating = 'CapacityRating',
    CellReferenceTemperature = 'ReferenceTemperature',
    CellsPerBloc = 'CellsPerBloc',
    PeukertsConstant = 'PeukertsConstant',
}

export type BatteryTypeTableColumnId = BatteryTypeTableColumn | AttributeTableColumn;

// IMPORTANT: Value has to be unique and cannot have spaces
export enum BatteryTypeExtraFilters {}

export interface BatteryTypeManModelFilter {
    name: string;
    type: 'result' | 'special';
    wildcard?: boolean;
}

export enum BatteryTechnology {
    LeadAcidFlooded = 'LeadAcidFlooded',
    LeadAcidGel = 'LeadAcidGel',
    LeadAcidVRLA = 'LeadAcidVRLA',
    LeadAcidAGM = 'LeadAcidAGM',
    LeadAcidTPPL = 'LeadAcidTPPL',
    LithiumIon = 'LithiumIon',
    LithiumPolymer = 'LithiumPolymer',
    LithiumIronPhosphate = 'LithiumIronPhosphate',
    NickelCadmium = 'NickelCadmium',
    NickelMetalHydride = 'NickelMetalHydride',
    Supercapacitor = 'Supercapacitor',
    LeadAcidOther = 'LeadAcidOther',
    Other = 'Other',
}

export interface BatteryTypeTechnologyFilter {
    id: BatteryTechnology;
    name: string;
}

export type BatteryTypeCellCapacityRatingFilter = IntRangeFilter;
export type BatteryTypeCellReferenceTemperatureFilter = IntRangeFilter;
export type BatteryTypeCellsPerBlocFilter = IntRangeFilter;
export type BatteryTypePeukertsConstantFilter = IntRangeFilter;
