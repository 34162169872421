import React, { FC } from 'react';

import { FormikGeneralSelect, FormikTextField } from '@accesstel/pcm-ui';

import { useField } from 'formik';
import { getQualifiedName } from 'lib/namespaces';
import {
    SnmpAuthAlgorithm,
    SnmpPrivAlgorithm,
    SnmpSecurityLevel,
} from 'views/manage/__generated__/mutations_CreateDeviceMutation.graphql';

export interface SettingsV3Props {
    namespace?: string;
}

const SecurityLevels: SnmpSecurityLevel[] = ['NoAuthNoPriv', 'AuthNoPriv', 'AuthPriv'];

const AuthTypes: SnmpAuthAlgorithm[] = ['MD5', 'SHA1'];

const PrivTypes: SnmpPrivAlgorithm[] = ['AES', 'DES'];

export const SettingsV3: FC<SettingsV3Props> = ({ namespace }) => {
    const [, securityLevelField] = useField<SnmpSecurityLevel>(getQualifiedName('securityLevel', namespace));

    const securityLevel = securityLevelField.value;

    return (
        <>
            <div className='col-start-1'>
                <FormikTextField
                    name={getQualifiedName('engineId', namespace)}
                    placeHolder='Engine ID'
                    light
                    variant='outlined'
                    testId='engine-id'
                />
            </div>
            <div className='col-start-1'>
                <FormikTextField
                    name={getQualifiedName('user', namespace)}
                    placeHolder='User*'
                    light
                    required
                    variant='outlined'
                    testId='snmp-user'
                />
            </div>
            <div className='col-start-1'>
                <FormikTextField
                    name={getQualifiedName('port', namespace)}
                    placeHolder='Port*'
                    maxLength={5}
                    light
                    required
                    id='snmp-port'
                    variant='outlined'
                    testId='snmp-port'
                />
            </div>

            <div className='col-start-1'>
                <FormikGeneralSelect<SnmpSecurityLevel>
                    name={getQualifiedName('securityLevel', namespace)}
                    placeHolder='Security Level'
                    items={SecurityLevels}
                    renderItem={item => item}
                    light
                    variant='outlined'
                    testId='security-level'
                />
            </div>

            {(securityLevel === 'AuthPriv' || securityLevel === 'AuthNoPriv') && (
                <>
                    <div className='col-start-1'>
                        <FormikGeneralSelect<SnmpAuthAlgorithm>
                            name={getQualifiedName('authType', namespace)}
                            placeHolder='AuthType'
                            items={AuthTypes}
                            renderItem={item => item}
                            light
                            variant='outlined'
                            testId='authtype'
                        />
                    </div>
                    <FormikTextField
                        name={getQualifiedName('authPassphrase', namespace)}
                        placeHolder='AuthPassword*'
                        type='password'
                        light
                        required
                        variant='outlined'
                        testId='authpassword'
                    />
                </>
            )}

            {securityLevel === 'AuthPriv' && (
                <>
                    <div className='col-start-1'>
                        <FormikGeneralSelect<SnmpPrivAlgorithm>
                            name={getQualifiedName('privType', namespace)}
                            placeHolder='PrivType'
                            items={PrivTypes}
                            renderItem={item => item}
                            light
                            variant='outlined'
                            testId='privtype'
                        />
                    </div>
                    <FormikTextField
                        name={getQualifiedName('privPassphrase', namespace)}
                        placeHolder='PrivPassword*'
                        type='password'
                        light
                        required
                        variant='outlined'
                        testId='privpassword'
                    />
                </>
            )}
        </>
    );
};
