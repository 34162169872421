import { DeviceNameFilter } from 'filters/device';
import { SiteNameFilter, SiteStateFilter } from 'filters/site';

// IMPORTANT: Value has to be unique and cannot have spaces
export enum BatteryTestTableColumn {
    Name = 'Name',
    Site = 'Site',
    State = 'State',
    Status = 'Status',
}

// IMPORTANT: Value has to be unique and cannot have spaces
export enum BatteryTestExtraFilters {
    DeviceType = 'DeviceType',
}
export interface BatteryTestDeviceStatusFilter {
    id:
        | 'aborted'
        | 'in-progress'
        | 'failed'
        | 'passed'
        | 'scheduled'
        | 'smart-start'
        | 'waiting'
        | 'inconclusive'
        | 'finalizing'
        | 'analyzing'
        | 'completed';
    name: string;
}

export type BatteryTestDeviceSiteNameFilter = SiteNameFilter;
export type BatteryTestDeviceSiteStateFilter = SiteStateFilter;
export type BatteryTestDeviceNameFilter = DeviceNameFilter;
