/**
 * @generated SignedSource<<5013e36008ce1f26498e666644d088d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneratorRunReportCard_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RunParameterView_data" | "RunReportHeader_data" | "RunReportResultDisplay_data">;
  readonly " $fragmentType": "GeneratorRunReportCard_data";
};
export type GeneratorRunReportCard_data$key = {
  readonly " $data"?: GeneratorRunReportCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneratorRunReportCard_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "unitVolume",
  "variableName": "unitVolume"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chartPoints"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitFlowRate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitPressure"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitTemperature"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "unitVolume"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneratorRunReportCard_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RunReportHeader_data"
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "RunParameterView_data"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "chartPoints",
          "variableName": "chartPoints"
        },
        {
          "kind": "Variable",
          "name": "unitFlowRate",
          "variableName": "unitFlowRate"
        },
        {
          "kind": "Variable",
          "name": "unitPressure",
          "variableName": "unitPressure"
        },
        {
          "kind": "Variable",
          "name": "unitTemperature",
          "variableName": "unitTemperature"
        },
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "RunReportResultDisplay_data"
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};
})();

(node as any).hash = "9515aecf43e614a81b637852eb6f38e8";

export default node;
