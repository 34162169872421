import React, { FC } from 'react';

import { FormArea, FormikCheckbox } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

export interface MonitorOnlyModeProps {
    namespace?: string;
}

export const MonitorOnlyMode: FC<MonitorOnlyModeProps> = ({ namespace }) => {
    return (
        <FormArea
            label='Monitor Only Mode'
            blurb='Enable Monitor Only mode for this device. Control actions, such as initiating battery health tests, are not possible.'
        >
            <div className='col-start-1 flex h-full items-center'>
                <FormikCheckbox
                    name={getQualifiedName('monitorOnly', namespace)}
                    placeHolder='Enable Monitor Only Mode'
                    variant='white'
                />
            </div>
        </FormArea>
    );
};
