import React from 'react';
import { FC } from 'react';

import { FilterContainer, FormikTextField } from '@accesstel/pcm-ui';

import { Form, Formik } from 'formik';
import { object, string } from 'yup';

interface ExportFormValues {
    filename: string;
}

const ExportFormSchema = object().shape({
    filename: string().required('Required'),
});

export interface ExportPaneProps {
    onExport: (filename: string) => void;
    onClose: () => void;

    defaultFilename: string;
}

export const ExportPane: FC<ExportPaneProps> = ({ onExport, onClose, defaultFilename }) => {
    return (
        <Formik
            initialValues={
                {
                    filename: defaultFilename,
                } as ExportFormValues
            }
            validationSchema={ExportFormSchema}
            onSubmit={values => {
                onExport(values.filename);
                onClose();
            }}
        >
            {({ submitForm }) => (
                <FilterContainer
                    title='Export'
                    hideClearButton
                    onClose={onClose}
                    primaryContent={
                        <Form className='px-4 py-2 mt-2'>
                            <FormikTextField name='filename' placeHolder='Filename' light />
                        </Form>
                    }
                    onConfirmClick={submitForm}
                />
            )}
        </Formik>
    );
};
