import humanizeDuration from 'humanize-duration';
import { DateTime, Duration, Settings } from 'luxon';

const getIntlDateFormatter = () =>
    new Intl.DateTimeFormat(undefined, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        timeZone: typeof Settings.defaultZone === 'string' ? Settings.defaultZone : Settings.defaultZone.name,
    });
const getIntlShortDateFormatter = () =>
    new Intl.DateTimeFormat(undefined, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        timeZone: typeof Settings.defaultZone === 'string' ? Settings.defaultZone : Settings.defaultZone.name,
    });

const getIntlTimeFormatter = () =>
    new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
        timeZone: typeof Settings.defaultZone === 'string' ? Settings.defaultZone : Settings.defaultZone.name,
    });

export interface DateTimeFormatOptions {
    fullMonth?: boolean;
}

/**
 *
 * Format the date and time using the Intl API, handling the locale automatically
 *
 * Example with fullMonth:
 * ```
 * getDateTimeFormat('2022-06-06T22:00:00.981Z', { fullMonth: true });
 * // returns: '06 June 2022, 22.00'
 * ```
 *
 * Example without fullMonth (default):
 * ```
 * getDateTimeFormat('2022-06-06T22:00:00.981Z');
 * // returns: '06 Jun 2022, 22.00'
 * ```
 */
export function getDateTimeFormat(value: string | Date, options?: DateTimeFormatOptions): string {
    let date: DateTime;
    if (typeof value == 'string') {
        date = DateTime.fromISO(value);
    } else {
        date = DateTime.fromJSDate(value);
    }

    if (!date.isValid) {
        return 'Invalid date';
    }

    const formattedDate = getFormattedDateFromDate(date.toJSDate(), options);
    const formattedTime = getFormattedTimeFromDate(date.toJSDate());

    // Ensure that 'at' separator is not used, only use ',' separator as per the design
    return `${formattedDate}, ${formattedTime}`;
}

/**
 *
 * Format the date using the Intl API, handling the locale automatically
 *
 * Example with fullMonth:
 * ```
 * getFormattedDateFromDate('2022-06-06T22:00:00.981Z', { fullMonth: true });
 * // returns: '06 June 2022'
 * ```
 *
 * Example without fullMonth (default):
 * ```
 * getFormattedDateFromDate('2022-06-06T22:00:00.981Z');
 * // returns: '06 Jun 2022'
 * ```
 */
export function getFormattedDateFromDate(value: string | Date, options?: DateTimeFormatOptions): string {
    let date: DateTime;
    if (typeof value == 'string') {
        date = DateTime.fromISO(value);
    } else {
        date = DateTime.fromJSDate(value);
    }

    return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        day: '2-digit',
        month: options?.fullMonth ? 'long' : 'short',
        timeZone: typeof Settings.defaultZone === 'string' ? Settings.defaultZone : Settings.defaultZone.name,
    }).format(date.toJSDate());
}

/**
 * Format the time (hour and minute).
 *
 * Example:
 * ```
 * getFormattedTimeFromDate('2022-06-06T22:00:00.981Z');
 * // returns: '22:00'
 *
 * getFormattedTimeFromDate(new Date('2022-06-06T08:00:00.981Z'));
 * // returns: '08:00'
 * ```
 */
export function getFormattedTimeFromDate(value: string | Date): string {
    let date: DateTime;
    if (typeof value == 'string') {
        date = DateTime.fromISO(value);
    } else {
        date = DateTime.fromJSDate(value);
    }

    return getIntlTimeFormatter().format(date.toJSDate());
}

/**
 * Format a date using the Intl API
 * @param value The ISO string or Date object to format
 * @param format The format to use
 * @returns The formatted date string
 *
 * Example with short-date:
 * ```
 * formatDateIntl('2022-06-06T22:00:00.981Z', 'short-date');
 * // returns: '6/6/2022'
 *
 * formatDateIntl(new Date('2022-06-06T22:00:00.981Z'), 'date');
 * // returns: '6 Jun 2022'
 */
export function formatDateIntl(value: string | Date, format: 'short-date' | 'date'): string {
    let date: Date;
    if (typeof value == 'string') {
        date = new Date(value);
    } else {
        date = value;
    }

    if (format === 'short-date') {
        return getIntlShortDateFormatter().format(date);
    } else if (format === 'date') {
        return getIntlDateFormatter().format(date);
    } else {
        return 'Invalid format';
    }
}

/**
 *
 * @param durationDiff in milliseconds
 * @returns a formatted string of the duration in the PAST, otherwise null (not used for future duration)
 */
export function getHumanizedTextFromDuration(durationDiff: number): string | null {
    if (durationDiff > 0) {
        return null;
    }

    if (Duration.fromMillis(Math.abs(durationDiff)).as('months') < 1) {
        return 'less than a month ago';
    }

    return `${humanizeDuration(durationDiff, { units: ['y', 'mo'], round: true })} ago`;
}

/**
 * This function is meant to be used in the correct order, returning 0 minutes if the 'from' is bigger than the 'to'.
 * Otherwise, will return a string in the format 'x hour[s] y minute[s]', omitting any zero values.
 *
 * Example with correct ordering:
 * ```
 * from: '2022-06-06T04:00:00.981Z (in luxon.DateTime format)'
 * to: '2022-06-06T05:50:00.981Z (in luxon.DateTime format)'
 * returns: '1 hour 50 minutes'
 * ```
 *
 * Example with incorrect ordering:
 * ```
 * from: '2022-06-06T05:50:00.981Z (in luxon.DateTime format)'
 * to: '2022-06-06T04:00:00.981Z (in luxon.DateTime format)'
 * returns: '0 minutes'
 * ```
 * */
export function formatDurationAsHoursAndMinutes(from: DateTime, to: DateTime): string {
    // must be to.diff(from) so that duration is positive
    const calculatedDuration = to.diff(from).as('minutes');
    return formatMinutesAsHoursAndMinutes(calculatedDuration);
}

export function formatMinutesAsHoursAndMinutes(rawMinutes: number, short?: boolean): string {
    const hours = Math.floor(rawMinutes / 60);
    const minutes = Math.floor(rawMinutes % 60);

    const durationSegment = [];
    if (short) {
        if (hours > 0) {
            durationSegment.push(`${hours}h`);
        }

        if (minutes > 0) {
            durationSegment.push(`${minutes}m`);
        }
    } else {
        if (hours === 1) {
            durationSegment.push('1 hour');
        } else if (hours > 1) {
            durationSegment.push(`${Math.floor(hours)} hours`);
        }

        if (minutes >= 1 && minutes < 2) {
            durationSegment.push('1 minute');
        } else if (minutes > 1) {
            durationSegment.push(`${Math.floor(minutes)} minutes`);
        }
    }

    // when it is 0 hours and 0 minutes
    if (durationSegment.length === 0) {
        if (short) {
            return '0 min';
        } else {
            return '0 minutes';
        }
    }

    return durationSegment.join(' ');
}

export const getHumanizedTextBetweenDates = (date1: string, date2: string) => {
    const dateTime1 = DateTime.fromISO(date1);
    const dateTime2 = DateTime.fromISO(date2);
    const msDiff = dateTime2.diff(dateTime1);

    return humanizeDuration(msDiff.as('milliseconds'), { round: true, largest: 2 });
};
