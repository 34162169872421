/**
 * @generated SignedSource<<d12adc4767beecc57d99cb1a8efac311>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TrendState = "Down" | "Flat" | "Up" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurrentSparkline_battery$data = {
  readonly current: {
    readonly trend: {
      readonly state: TrendState;
    } | null;
    readonly values: ReadonlyArray<{
      readonly timestamp: string;
      readonly value: number | null;
    }>;
  } | null;
  readonly latestCurrent: number | null;
  readonly " $fragmentType": "CurrentSparkline_battery";
};
export type CurrentSparkline_battery$key = {
  readonly " $data"?: CurrentSparkline_battery$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentSparkline_battery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentSparkline_battery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestCurrent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeSeriesMetrics",
      "kind": "LinkedField",
      "name": "current",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimestampMetric",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Trend",
          "kind": "LinkedField",
          "name": "trend",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryMetrics",
  "abstractKey": null
};

(node as any).hash = "1babc8db5f7efd3b91be64a9864373d2";

export default node;
