import { StateList } from 'lib/provision';

import {
    DeviceHealthStatus,
    SiteDeviceBatteries,
    SiteDeviceStatusFilter,
    SiteNameFilter,
    SitePostcodeFilter,
    SiteStateFilter,
    SiteTypeFilter,
} from './types';

export function decodeSiteTypeFilter(value: string): SiteTypeFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return {
        name: decodedValue,
        id: decodedValue,
    };
}

export function decodeSiteNameFilter(value: string): SiteNameFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    return {
        name: decodedValue,
        type: 'result',
    };
}

export function decodeSiteStateFilter(value: string): SiteStateFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (StateList.includes(decodedValue)) {
        return {
            state: decodedValue,
        };
    }

    return null;
}

export function decodeSitePostcodeFilter(value: string): SitePostcodeFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue === '') {
        return null;
    }

    if (!isFinite(Number(decodedValue))) {
        return null;
    }

    if (Number(decodedValue) < 0) {
        return null;
    }

    if (decodedValue.length !== 4) {
        return null;
    }

    return {
        id: decodedValue,
        value: decodedValue,
    };
}

export function decodeSiteDeviceBatteries(value: string): SiteDeviceBatteries | null {
    const decodedValue = decodeURIComponent(value);
    if (Object.values(SiteDeviceBatteries).includes(decodedValue as SiteDeviceBatteries)) {
        return decodedValue as SiteDeviceBatteries;
    }

    return null;
}

export function decodeSiteDeviceStatusFilter(value: string): SiteDeviceStatusFilter | null {
    const decodedValue = decodeURIComponent(value);
    if (Object.values(DeviceHealthStatus).includes(decodedValue as DeviceHealthStatus)) {
        return {
            id: value as DeviceHealthStatus,
            displayText: decodedValue,
        };
    }

    return null;
}
